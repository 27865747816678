<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Issues/Overview">{{'general.issues' | translate }}</a>
            </li>
            <li class="active">
                <strong>{{'menu.issuedetails' | translate }}</strong>
            </li>

            <span class="pull-right"><a href="https://360locate.com/docs/fleet-health/issue-detection/" target="_blank">
                    <i class="fa fa-question"></i></a></span>

        </ol>
    </div>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="">
        <div class="col-lg-5 col-md-12" *ngIf="!mapFullWith">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-memory"></i> {{ 'general.deviceDetails' | translate }}
                    </h5>
                </div>
                <div class="ibox-content slick" style="position:relative">
                    <ngx-loading [show]="loadingDevice"></ngx-loading>
                    <table style="width:100%" class="table table-hover nowrap dtr-inline no-footer table-fixed">
                        <tbody>
                            <tr>
                                <td style="width:40%" class="elipses_overflow">
                                    {{'general.device' | translate }}
                                </td>
                                <td style="width:60%">
                                    <a class="secondary link_bolder"
                                        href="/#/DeviceDetails/Index/{{issue?.deviceId}}">{{
                                        issue?.deviceName }}</a>
                                </td>
                            </tr>
                            <tr *ngIf="permissions['HasDeviceTypes']">
                                <td style="width:40%" class="elipses_overflow">
                                    {{'general.devicetype' | translate }}
                                </td>
                                <td style="width:60%">
                                    <a class="secondary link_bolder"
                                        href="/#/DeviceTypeDetails/Index/{{device?.modelId}}">{{
                                        device?.modelName }}</a>
                                    <img *ngIf="device?.modelThumb"
                                        style="margin-left: 10px; margin-top: -8px;margin-bottom: -8px; height:30px;width: 30px "
                                        src="data:image/jpg;base64,{{ device?.modelThumb }}" />
                                </td>
                            </tr>
                            <tr *ngIf="permissions['HasDeviceTypes']">
                                <td style="width:40%" class="elipses_overflow">
                                    {{'general.manufacturer' | translate }}
                                </td>
                                <td style="width:60%">
                                    {{ device?.manufacturerName }}
                                </td>
                            </tr>
                            <tr>
                                <td style="width:40%" class="elipses_overflow">
                                    {{ 'general.isActiveCalculating' | translate}}
                                </td>
                                <td style="width:60%">
                                    <i (click)="changeIssueCalculation($event)" style="font-size: 1.3em;"
                                        class="fas fa-fw fa-2x"
                                        [class.fa-toggle-on]="device?.hasIssueCalculationEnabled"
                                        [class.fa-toggle-off]="!device?.hasIssueCalculationEnabled"></i>
                                </td>
                            </tr>
                            <tr>
                                <td style="width:40%" class="elipses_overflow">
                                    {{ ('general.calculateIssueType' | translate).replace('{0}',
                                    issue?.issueTypeDescription)}}
                                </td>
                                <td style="width:60%">
                                    <i (click)="changeIssueTypeCalculation($event)" style="font-size: 1.3em;"
                                        class="fas fa-fw fa-2x" [class.fa-toggle-on]="setting?.isActive"
                                        [class.fa-toggle-off]="!setting?.isActive">
                                    </i>
                                </td>
                            </tr>
                            <tr>
                                <td style="width:40%" class="elipses_overflow">
                                    {{ 'general.sourceOfThreshold' | translate }}
                                </td>
                                <td style="width:60%">
                                    {{ ('enums.settingsLevel.' + hasIssueTypeCalculationSource) | translate }} {{
                                    'general.level' | translate }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fa fa-car-crash"></i> {{'menu.issuedetails' | translate }}
                    </h5>
                </div>
                <div class="ibox-content slick" style="padding:7px;position:relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <table style="width:100%" class="table table-hover nowrap dtr-inline no-footer table-fixed">
                        <tbody>

                            <tr>
                                <td style="width:40%" class="elipses_overflow">
                                    {{'general.date' | translate }}
                                </td>
                                <td style="width:60%" title="{{ issue?.issueDate | amDateFormat: 'LLLL Z' }}">
                                    {{ issue?.issueDate | amDateFormat: 'LLL' }}
                                </td>
                            </tr>
                            <tr *ngIf="issue?.closedDate">
                                <td style="width:40%" class="elipses_overflow">
                                    {{'general.closedDate' | translate }}
                                </td>
                                <td style="width:60%">
                                    {{ issue?.closedDate }}
                                </td>
                            </tr>
                            <tr>
                                <td style="width:40%" class="elipses_overflow">
                                    {{'general.status' | translate }}
                                </td>
                                <td style="width:60%">
                                    {{ issue?.statusDescription }}
                                </td>
                            </tr>
                            <tr>
                                <td style="width:40%">
                                    {{ 'general.customer' | translate}}
                                </td>
                                <td style="width:60%">
                                    <a class="secondary link_bolder"
                                        href="/#/CustomerDetails/Index/{{device?.customerId}}">{{
                                        device?.customerName
                                        }}
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td style="width:40%" class="elipses_overflow">
                                    {{ 'general.platform' | translate}}
                                </td>
                                <td style="width:60%">
                                    <a class="secondary link_bolder"
                                        href="/#/PlatformDetails/Index/{{device?.accountPlatformId}}">{{
                                        device?.platformName }} - {{ device?.accountPlatformName }}</a>
                                </td>
                            </tr>
                            <tr>
                                <td style="width:40%" class="elipses_overflow">
                                    {{'general.issuetype' | translate }}
                                </td>
                                <td style="width:60%">
                                    {{ issue?.issueTypeDescription }}

                                    <span [ngSwitch]="issue?.issueType">
                                        <a *ngSwitchCase="29"
                                            href="https://360locate.com/docs/fleet-health/issue-detection/issue-type-time-outlier/"
                                            target="_blank"><i class="fa fa-question"></i></a>
                                        <a *ngSwitchCase="7"
                                            href="https://360locate.com/docs/fleet-health/issue-detection/issue-type-poor-gps-reception/"
                                            target="_blank"><i class="fa fa-question"></i></a>
                                        <a *ngSwitchCase="28"
                                            href="https://360locate.com/docs/fleet-health/issue-detection/issue-type-battery-power-too-low/"
                                            target="_blank"><i class="fa fa-question"></i></a>
                                        <a *ngSwitchCase="32"
                                            href="https://360locate.com/docs/fleet-health/issue-detection/issue-type-backup-battery-power-too-low/"
                                            target="_blank"><i class="fa fa-question"></i></a>
                                        <a *ngSwitchCase="4"
                                            href="https://360locate.com/docs/fleet-health/issue-detection/issue-type-too-many-power-loss-events/"
                                            target="_blank"><i class="fa fa-question"></i></a>
                                        <a *ngSwitchCase="6"
                                            href="https://360locate.com/docs/fleet-health/issue-detection/issue-type-loose-connection-or-ignition-wire/"
                                            target="_blank"><i class="fa fa-question"></i></a>
                                        <a *ngSwitchCase="33"
                                            href="https://360locate.com/docs/fleet-health/issue-detection/issue-type-missing-driver/"
                                            target="_blank"><i class="fa fa-question"></i></a>
                                        <a *ngSwitchCase="34"
                                            href="https://360locate.com/docs/fleet-health/issue-detection/issue-type-gps-drift/"
                                            target="_blank"><i class="fa fa-question"></i></a>
                                        <a *ngSwitchCase="35"
                                            href="https://360locate.com/docs/fleet-health/issue-detection/issue-type-delayed-messages/"
                                            target="_blank"><i class="fa fa-question"></i></a>
                                        <!--default case when there are no matches -->
                                        <!-- <a *ngSwitchDefault href="https://360locate.com/docs/fleet-health/issue-detection/"
                                            target="_blank"><i class="fa fa-question"></i></a> -->
                                    </span>
                                </td>
                            </tr>
                            <tr *ngIf="issue?.sensorName">
                                <td style="width:40%" class="elipses_overflow">
                                    {{'general.sensorName' | translate }}
                                </td>
                                <td style="width:60%">
                                    {{ issue?.sensorName }}
                                </td>
                            </tr>
                            <tr *ngIf="issue?.issueTypeUom != 0">
                                <td style="width:40%" [class.text-danger]="outdatedThreshold" class="elipses_overflow">
                                    {{ 'general.thresholdSettingsDuringCalculation' | translate }}
                                    <span *ngIf="outdatedThreshold">
                                        <i class="fa fa-fw fa-info"
                                            tooltip="{{ 'general.settingsUsedModified' | translate }} {{ outdatedUpdate | date: 'L' }}"></i>
                                    </span>
                                </td>
                                <td style="width:60%" [class.text-danger]="outdatedThreshold">
                                    <ul style="margin-bottom: 0; padding-left: 20px">
                                        <li *ngIf="thresholdHasBounds == false">{{
                                            'general.threshold' | translate }}: {{ thresholdLower }}
                                        </li>
                                        <span *ngIf="thresholdHasBounds == true && issue.issueType !== 7">
                                            <li>{{ 'general.thresholdUpper' | translate }}: {{ thresholdUpper }}</li>
                                            <li>{{ 'general.thresholdLower' | translate }}: {{ thresholdLower }}</li>
                                        </span>
                                        <span *ngIf="thresholdHasBounds == true && issue.issueType === 7">
                                            <li>{{ 'general.threshold' | translate }}: {{ thresholdUpper }}</li>
                                            <li>{{ 'general.threshold' | translate }}: {{ thresholdLower }}</li>
                                        </span>
                                        <li *ngIf="issue?.hasDurationThreshold">{{ 'general.duration' | translate }}:
                                            {{ duration?.humanize() }}
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr *ngIf="issue?.issueTypeUom == 0 && issue?.hasDurationThreshold">
                                <td style="width:40%" [class.text-danger]="outdatedThreshold" class="elipses_overflow">
                                    {{ 'general.thresholdSettingsDuringCalculation' | translate }}
                                </td>
                                <td style="width:60%" [class.text-danger]="outdatedThreshold">
                                    <ul style="margin-bottom: 0; padding-left: 20px">
                                        <li>{{ 'general.duration' | translate }}: {{ duration?.humanize() }}
                                            <span *ngIf="outdatedThreshold">
                                                <i class="fa fa-fw fa-info"
                                                    tooltip="{{ 'general.settingsUsedModified' | translate }} {{ outdatedUpdate | date: 'L' }}"></i>
                                            </span>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr
                                *ngIf="(violationDuration != null || violationThresholdLower != null || violationThresholdUpper != null)">
                                <td style="width:40%" class="elipses_overflow">
                                    {{ 'general.violationThresholds' | translate }}
                                </td>
                                <td style="width:60%">
                                    <ul style="margin-bottom: 0; padding-left: 20px">
                                        <li *ngIf="violationThresholdLower != null">
                                            {{ 'general.threshold' | translate }}: {{ violationThresholdLower }}
                                        </li>
                                        <li *ngIf="violationThresholdUpper != null">
                                            {{ 'general.thresholdUpper' | translate }}: {{ violationThresholdUpper }}
                                        </li>
                                        <li *ngIf="violationDuration != null">
                                            {{ 'general.duration' | translate }}: {{ violationDuration.humanize() }}
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td style="width:40%" class="elipses_overflow">
                                    {{'general.resolution' | translate }}
                                </td>
                                <td style="width:60%">
                                    {{ issue?.resolutionDescription }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fa fa-list-alt"></i> {{'menu.issuesummary' | translate }}
                    </h5>
                </div>
                <div class="ibox-content slick" style="padding:7px;position:relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <span *ngIf="!issue.hasTelemetrySummary">
                        <table style="width:100%" class="table table-hover nowrap dtr-inline no-footer">
                            <tbody>

                                <tr>
                                    <td style="width:100%">
                                        {{ 'general.nodatafound' | translate }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </span>
                    <table *ngIf="issue.hasTelemetrySummary" style="width:100%"
                        class="table table-hover nowrap dtr-inline no-footer table-fixed">
                        <tbody>
                            <tr>
                                <td style="width:40%" class="elipses_overflow">
                                    {{ 'general.severity' | translate }}
                                </td>
                                <td style="width:60%">
                                    {{ ('enums.severity.' + issue?.severity) | translate }}
                                </td>
                            </tr>
                            <tr>
                                <td style="width:40%" class="elipses_overflow">
                                    {{ 'general.hasMotion' | translate }}
                                </td>
                                <td style="width:60%">
                                    {{ issue?.hasMotion ? ('general.yes' | translate) : ('general.no' | translate) }}
                                </td>
                            </tr>
                            <!-- <tr> -->
                            <!-- <td style="width:40%"> -->
                            <!-- {{ 'general.eventCount' | translate }} -->
                            <!-- </td> -->
                            <!-- <td style="width:60%"> -->
                            <!-- - -->
                            <!-- </td> -->
                            <!-- </tr> -->
                            <tr>
                                <td style="width:40%" class="elipses_overflow">
                                    {{ 'general.ignitionCount' | translate }}
                                </td>
                                <td style="width:60%">
                                    {{ issue?.ignitionCount }}
                                </td>
                            </tr>
                            <tr>
                                <td style="width:40%" class="elipses_overflow">
                                    {{ 'general.locationCount' | translate }}
                                </td>
                                <td style="width:60%">
                                    {{ issue?.messageCount }}
                                </td>
                            </tr>
                            <tr>
                                <td style="width:40%" class="elipses_overflow">
                                    {{ 'general.locationCountWithoutGpsFix' | translate }}
                                </td>
                                <td style="width:60%">
                                    {{ issue?.noGpsFixCount }}
                                </td>
                            </tr>
                            <tr>
                                <td style="width:40%" class="elipses_overflow">
                                    {{ 'general.distance' | translate }}
                                </td>
                                <td style="width:60%">
                                    {{ issue?.distanceInKm }} km
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div [ngClass]="{'col-lg-12': mapFullWith, 'col-lg-7 col-md-12': !mapFullWith}">

            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fa fa-map"></i> {{'issuedetails.location' | translate }}
                    </h5>
                    <span class="pull-right">
                        <i (click)="reRenderMap()" style=" font-size: 1.3em;padding-right: 25px" class="fa fa-fw fa-2x"
                            [ngClass]="{'fa-times-circle': mapFullWith, 'fa-expand-arrows-alt': !mapFullWith}"></i>
                    </span>
                </div>
                <div class="ibox-content slick" style="position:relative">
                    <ngx-loading [show]="loadingMapData"></ngx-loading>
                    <div *ngIf="noDataFound" class="alert alert-warning" style="margin-bottom: 0px;">
                        <strong>Warning!</strong> No location data found
                    </div>
                    <div *ngIf="!noDataFound" style="height:300px;" leaflet [leafletOptions]="options"
                        (leafletMapReady)="onMapReady($event)">
                    </div>
                </div>
            </div>

            <fh-tagging [objectId]="issue.id" [objectType]="tagType">
            </fh-tagging>

            <fh-fh-issue-details-chart
                *ngIf="(geoJsonData != undefined) && (map != undefined) && (device != null) && issue.issueType !== 3"
                [map]="map" [data]="geoJsonData" [deviceSensors]="device.sensors" [issue]="issue">
            </fh-fh-issue-details-chart>

            <fh-schedule-details *ngIf="permissions['hasSchedule']" [issue]="issue" (onUpdated)="updateSchedule($event)"
                [renderDateTime]="renderDateTime"></fh-schedule-details>

            <fh-advice-details *ngIf="permissions['hasSchedule']" [device]="device" [issue]="issue"
                (onUpdated)="updateSchedule($event)"></fh-advice-details>

        </div>
    </div>
</div>