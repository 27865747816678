import { Component, Input, OnInit } from '@angular/core';
import { FormMode } from 'app/common/enums';
import { ControlContainer, NgForm } from '@angular/forms';
import { AssetType } from 'app/models/assetType.model';


@Component({
    selector: 'fh-asset-type-details',
    templateUrl: 'assetTypeDetails.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class AssetTypeDetailsComponent implements OnInit {

    @Input() assetType: AssetType;
    @Input() formMode: FormMode = FormMode.read;

    constructor() {
    }

    ngOnInit(): void {
    }

}

