import { CustomerService } from './../../services/customers/customers.service';
import { Customer } from 'app/models/customer.model';
import { Component, OnDestroy, OnInit, ChangeDetectorRef, ViewChild, AfterViewInit, } from '@angular/core';
import { DeviceService } from '../../services/device/device.service';
import { IssueService } from '../../services/issues/issues.service';
import { Issue } from 'app/models/issue.model';
import * as moment from 'moment';
import { Subject } from 'rxjs/Subject';

import { Chart } from 'angular-highcharts';

import { DeviceTypeService } from 'app/services/devicetypes/devicetypes.service';
import { IssueTypeService } from 'app/services/issuetypes/issuetypes.service';
import { DeviceType } from 'app/models/devicetype.model';
import { IssueType } from 'app/models/issuetype.model';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { HttpHeaders } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { TranslatorService } from '../../services/common/translator.service';
import { ActivatedRoute } from '@angular/router';

import { BsDatepickerConfig, BsLocaleService, BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import { trigger, transition, animate, style, state, group } from '@angular/animations'

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
import { Device } from 'app/models/device.model';
import { IssueTypeEnums } from '../../common/enums';
import { createSearch, getGridLanguages, getGridButtons, getDropdownSettings, getTranslatedDropdownSettings, getDefaultDpConfig, mapMultiDropdown, setTableStars, setSelection, setSelectionRows } from 'app/common/gridhelper';
import { Observable } from 'rxjs/Observable';
import { getDate } from 'date-fns';
import { DataTable360Directive } from 'app/modules/360grid';
window['moment'] = Moment;
mTZ()

@Component({
  selector: 'fh-devices',
  templateUrl: 'devices.template.html',
  styleUrls: ['devices.template.css'],
  animations: [
    trigger('slideInOut', [
      state('in', style({ height: '*', opacity: 0.5 })),
      transition(':leave', [
        style({ height: '*', opacity: 1 }),
        group([
          animate(300, style({ height: 0 })),
          animate('200ms ease-in-out', style({ 'opacity': '0.5' })),
          animate('200ms ease-in', style({ transform: 'translateY(-100%)' }))
        ])
      ]),
      transition(':enter', [
        style({ height: '0', opacity: 0.5 }),
        group([
          animate(300, style({ height: '*' })),
          style({ transform: 'translateY(-100%)' }),
          animate('400ms ease-in-out', style({ 'opacity': '1' })),
          animate('200ms ease-in', style({ transform: 'translateY(0%)' }))
        ])
      ])
    ])
  ]
})
export class DevicesViewComponent implements OnInit, OnDestroy {
  tableIds = [];
  tableIdsSelection = [];

  token: string;
  countrySelect = false;

  @ViewChild(DataTable360Directive)
  datatableElement: DataTable360Directive;
  dtTrigger = new Subject();
  gridSelction = [];
  showSelection = false;

  dropdown_IssueType = '';
  dropdown_DeviceType = '';

  dtOptions = {};
  loading = false;

  min: number;
  max: number;

  showOnlyFlagged = false;
  showOnlyActive = true;

  issueTypes: IssueType[];
  deviceTypes: DeviceType[];

  dropdown_CustomerName = '';
  languageLoaded: boolean;
  timezoneIana: string;
  dropdown_CustomerNameSettings: any;
  selectedCustomers = [];

  dropdownSettingsDeviceTypes: any;
  selectedDeviceTypes: any[] = [];

  customersMultiselect: any[] = [];
  deviceTypesMultiselect: any[] = [];

  showFilter = true;

  constructorName = this.constructor.name;

  searchTerm: any;

  eventsSet = false;


  enableSelecting = true;
  enablePresets = true;

  constructor(private localeService: BsLocaleService, private route: ActivatedRoute, private translatorService: TranslatorService, private translateService: TranslateService, private cd: ChangeDetectorRef, private issueTypeService: IssueTypeService, private customerService: CustomerService, private authenticationService: AuthenticationService, private deviceService: DeviceService, private deviceTypeService: DeviceTypeService) {
    const that = this;
    this.loading = true;

    this.token = this.authenticationService.getAuthToken();
    this.timezoneIana = this.authenticationService.getTimeZoneIana()

    // Setup dropdown boxes
    this.dropdownSettingsDeviceTypes = getDropdownSettings();
    this.dropdown_CustomerNameSettings = getDropdownSettings();

    this.translateService.get('general.date').subscribe(value => {
      this.dropdownSettingsDeviceTypes = getTranslatedDropdownSettings(this.translateService, 'allDeviceTypes', true);
      this.dropdown_CustomerNameSettings = getTranslatedDropdownSettings(this.translateService, 'allCustomers', false);
    });

    // Get all the date for dropdown boxes
    Observable.forkJoin(
      this.issueTypeService.getIssueTypes(true),
      this.deviceTypeService.getDeviceTypes(true, false, true),
      this.customerService.getCustomers(true),
      this.translateService.get('general.date')
    ).subscribe(
      data => {
        this.issueTypes = data[0];
        this.deviceTypes = data[1];

        this.deviceTypesMultiselect = [];
        this.deviceTypes.forEach(deviceType => {
          this.deviceTypesMultiselect.push({ 'id': deviceType.id, 'itemName': deviceType.name, 'category': deviceType.manufacturerName });
        });

        this.customersMultiselect = mapMultiDropdown(data[2].filter(x => x.count > 0));

        this.languageLoaded = true;
        this.loading = false;

        this.initGrid();
      },
      err => console.error(err)
    );
  }

  ngOnInit(): void {
  }

  onPresetLoaded(preset) {

    // this.resetSelection();

    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      // Load settings
      localStorage.setItem('Columns_' + this.constructor.name, preset.json);
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  // Check custom filters from grid save
  checkFilters() {
    console.log('Checking filters');
    // Set custom filters from grid save
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {

      const selectedCustomers = dtInstance.column('customerName:name').search().split(',');
      this.selectedCustomers = this.customersMultiselect.filter(x => selectedCustomers.indexOf(x.itemName.toString()) !== -1);

      const selectedDeviceTypes = dtInstance.column('deviceTypeId:name').search().split(',');
      this.selectedDeviceTypes = this.deviceTypesMultiselect.filter(x => selectedDeviceTypes.indexOf(x.id.toString()) !== -1);

      this.showOnlyFlagged = dtInstance.column('isFlagged:name').search() === 'true';
      this.showOnlyActive = dtInstance.column('isActive:name').search() === 'true';
    });

    // make sure all is updated
    // this.fireFilter(1);
  }

  // Check route to apply filters
  checkRoute() {
    // Set issuetype from get
    this.route.queryParams.subscribe(params => {
      const issueType = params['IssueType'];
      if (issueType) {
        this.dropdown_IssueType = issueType;
      }
    });
  }

  // Fire custom filters to update grid and call server again
  fireFilter(event): void {
    if (event != null) {
      console.log('Fire update');
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {

        // Search for the devicetype
        if (this.selectedDeviceTypes !== []) {
          const selectedDeviceTypesString = this.selectedDeviceTypes.map((i) => i.id, []).join(',');
          dtInstance.column('deviceTypeId:name').search(selectedDeviceTypesString);
        } else {
          dtInstance.column('deviceTypeId:name').search('');
        }

        // Search for the customer
        if (this.selectedCustomers !== []) {
          const selectedCustomersString = this.selectedCustomers.map((i) => i.itemName, []).join(',');
          dtInstance.column('customerName:name').search(selectedCustomersString);
        } else {
          dtInstance.column('customerName:name').search('');
        }

        if (this.showOnlyFlagged === true) {
          dtInstance.column('isFlagged:name').search('true');
        } else {
          dtInstance.column('isFlagged:name').search('');
        }

        if (this.showOnlyActive === true) {
          dtInstance.column('isActive:name').search('true');
        } else {
          dtInstance.column('isActive:name').search('');
        }

        // Fire the request
        dtInstance.draw();
      });
    }
  }

  // Clean the datatrigger
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  seachChanged(value) {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.search(value).draw();
    });
  }

  initGrid(): void {

    const that = this;

    $.fn['dataTable'].ext.search.pop();

    this.loading = true;

    const commonExportOptions = {
      modifier: {
        page: 'all',
        search: 'none'
      },
      columns: ':visible[tabindex]'
    };

    this.dtOptions = {
      buttons: getGridButtons(commonExportOptions, 'device_overview', this.translateService.instant('menu.deviceoverview')),
      pagingType: 'simple_numbers',
      serverSide: true,
      processing: false,
      searchDelay: 2000,
      deferRender: true,
      scrollX: true,
      colReorder: { fixedColumnsLeft: 2 },
      deferLoading: 0,
      stateSave: true,
      stateSaveCallback: function (settings, data) {
        localStorage.setItem('Columns_' + that.constructor.name, JSON.stringify(data));
      },
      stateLoadCallback: function () {
        try {
          const columnSettings = JSON.parse(localStorage.getItem('Columns_' + that.constructor.name));
          if (columnSettings && columnSettings.time) {
            columnSettings.time = (new Date()).getTime();
          }
          that.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
          return columnSettings;
        } catch (e) {
          console.log(e);
        }
      },
      order: [[2, 'asc']],
      ajax: {
        beforeSend: () => {
          $('.dataTables_info').html(this.translateService.instant('grid.loadingData'));
        },
        url: that.deviceService.getPagingUrl(),
        data: (d) => {
          return d;
        },
        dataSrc: function (json) {
          // if selection and returning keys
          if (that.tableIdsSelection && that.tableIdsSelection.length === 0) {
            console.log('Set keys tableIdsSelection');
            that.tableIdsSelection = json.keys;
          }
          return json.data;
        },
        type: 'POST',
        headers: {
          'Authorization': 'Bearer ' + that.token
        }
      },
      initComplete: function (settings, json) {
        that.checkRoute();
        that.checkFilters();
        that.setEvents();
        that.drawFilterRow();
        that.loading = false;
      },
      colVis: {
        restore: this.translateService.instant('general.restore'),
        showAll: this.translateService.instant('general.showAll'),
        showNone: this.translateService.instant('general.hideAll'),
        exclude: ['id', 'deviceTypeId']
      },
      columns: [
        {
          name: 'id',
          data: 'id',
          orderable: false,
          title: '',
          width: '20',
          render: function (data, type, row) {
            return '<a class=\'btn btn-primary btn-outline btn-grid\' href=\'/#/DeviceDetails/Index/' + data + '\'><i class=\'fas fa-fw fa-info\'></i> <span class="hidden-sm hidden-xs">Details</span></a>';
          }
        },
        {
          name: 'name',
          data: 'name',
          title: this.translateService.instant('general.name')
        },
        {
          name: 'unitId',
          data: 'unitId',
          title: this.translateService.instant('general.unitId'),
          visible: true
        },
        {
          name: 'lastCommunication',
          data: 'lastCommunication',
          title: this.translateService.instant('general.lastCommunication'),
          render: function (data, type, row) {
            const date = Moment.utc(data)['tz'](that.timezoneIana);
            return date.format('lll')
          }
        },
        {
          name: 'note',
          data: 'note',
          title: this.translateService.instant('general.note'),
          visible: false
        },
        {
          name: 'deviceTypeId',
          data: 'deviceTypeId',
          title: this.translateService.instant('general.devicetype'),
          visible: false
        },
        {
          name: 'brand',
          data: 'brand',
          title: this.translateService.instant('general.assetBrand'),
          visible: false
        },
        {
          name: 'model',
          data: 'model',
          title: this.translateService.instant('general.assetModel'),
          visible: false
        },
        {
          name: 'deviceTypeName',
          data: 'deviceTypeName',
          title: this.translateService.instant('general.devicetype')
        },
        {
          name: 'manufactorerName',
          data: 'manufactorerName',
          title: this.translateService.instant('general.manufacturer'),
          visible: false
        },
        {
          name: 'customerName',
          data: 'customerName',
          title: this.translateService.instant('general.customer')
        },
        {
          name: 'isFlagged',
          data: 'isFlagged',
          title: this.translateService.instant('general.isFlagged'),
          visible: true,
          width: '40',
          responsivePriority: 1,
          render: function (data, type, row) {
            if (!that.tableIds) {
              that.tableIds = [];
            }
            if (that.tableIds.indexOf(row.id) === -1) {
              that.tableIds.push(row.id);
            }
            return '<i id="star_' + row.id + '" class="fa-fw fa-2x fa-star ' + (data === true ? 'fas' : 'far') + '" style="cursor: pointer; font-size: 1.3em;"></i>';
          }
        },
        {
          name: 'isActive',
          data: 'isActive',
          title: this.translateService.instant('general.isActive'),
          visible: true,
          width: '40',
          render: function (data, type, row) {
            return data === true ? '<i class="fa-fw fa-2x fa-check-square far" style=" font-size: 1.3em;"></i>' : '<i class="fa-fw fa-2x fa-square far" style=" font-size: 1.3em;"></i>';
          }
        },
        {
          name: 'firmware',
          data: 'firmware',
          title: this.translateService.instant('general.firmware')
        }],
      pageLength: 10,
      lengthMenu: [[10, 25, 50, -1], [10, 25, 50, this.translateService.instant('general.all')]],
      language: getGridLanguages(this.translateService),
      drawCallback: (data) => {
        setTableStars(that);
        setSelection(that);
      },
      rowCallback: (row, data) => {
        setSelectionRows(that, row, data, that.gridSelction);
      }
    };
  }

  // Set events for datatable to listen on
  setEvents() {
    const that = this;
    if (this.eventsSet === false) {
      this.eventsSet = true;
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.on('search.dt', function () {
          // that.resetSelection();
        });

        dtInstance.on('column-reorder', function (event) {
          // that.drawFilterRow();
        });

        dtInstance.on('draw.dt', function () {
          // console.log('Table redrawn');
        });
      });
    }
  }

  // Create dynamic filters
  drawFilterRow(): void {
    const dynamicFilterContainer = $('#dynamicFilters');
    const excludingColumns = [this.translateService.instant('general.isFlagged'), this.translateService.instant('general.isActive'), this.translateService.instant('general.customer'), this.translateService.instant('general.devicetype')];

    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      createSearch(dtInstance, dynamicFilterContainer, excludingColumns);
      dtInstance.draw();
    });
  }

  // Change flag for device
  changeFlag(id: string, isFlagged: boolean): void {
    this.deviceService.getDeviceById(id).subscribe(device => {
      device.isFlagged = isFlagged;
      this.deviceService.saveFlagged(device).subscribe(() => { });
    });
  }
}
