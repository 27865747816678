import { Directive, Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'fh-wizard',
    styleUrls: ['wizard.template.css'],
    templateUrl: 'wizard.template.html',
})
export class WizardComponent {
    @Input() step = 1;
    @Output() finish = new EventEmitter();
    @Output() stepChange = new EventEmitter();
    public stepCount = 0;
    public steps = [];
    public progressWidth = 0 + '%';

    public isOnFinalStep = () => this.step === this.stepCount;
    public isOnFirstStep = () => this.step === 1;

    public addStep(title, icon, disabled, validate) {
        const newSteps = this.stepCount + 1;
        this.steps.push({ id: newSteps, title: title, icon: icon, disabled: disabled, validate: validate });
        this.stepCount = newSteps;
        return newSteps;
    }

    handleNext() {
        this.stepChange.emit(this.step + 1)
    }

    handlePrevious() {
        this.stepChange.emit(this.step - 1)
    }

    handleFinish() {
        this.finish.emit(this.step + 1)
    }

    checkDisabled() {
        const thestep = this.steps.filter(x => x.id === this.step)[0];

        const percentage = (this.step - 1) / (this.steps.length - 1);
        const addpercentage = 0 + (percentage * 100);
        this.progressWidth = addpercentage + '%';

        if (thestep.validate) {
            const validateForm = thestep.validate;
            if (validateForm !== undefined && !validateForm.valid) {
                return true;
            } else {
                return false;
            }
        }

        return false;
    }
}
