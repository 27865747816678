<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Asset/Overview">{{'general.asset' | translate }}</a>
            </li>
            <li>
                <a href="/#/AssetDetails/Index/{{asset?.id}}">{{asset?.name}}</a>
            </li>
            <li class="active">
                <strong>{{'general.messages' | translate }}</strong>
            </li>
        </ol>
    </div>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="">

        <div class="col-lg-12">
            <fh-error [error]="error" [success]="success"></fh-error>

            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-filter"></i> {{'general.filter' | translate }}</h5>
                </div>
                <div class="ibox-content slick" style="position: relative">
                    <table style="width:100%" class="table table-hover nowrap dtr-inline no-footer">
                        <tbody>
                            <tr>
                                <td style="width:50%">
                                    {{ 'general.date' | translate}}
                                </td>
                                <td colspan="2" style="width:50%">
                                    <input [(ngModel)]="daterangepickerModel" (onHidden)="dateChanged($event)"
                                        name="from" type="text" autocomplete="off" class="form-control table-inline"
                                        [bsConfig]="dpConfig" bsDaterangepicker>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="ibox-footer">
                    <button (click)="dateChanged(1)" class="btn btn-primary">
                        <i class="fa fa-fw fa-sync-alt"></i>
                        {{'general.refresh' | translate }}</button>
                </div>
            </div>

            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fa fa-list-ul"></i> {{ 'general.messages' | translate }}
                        <!-- /#/DriverDetails/Index/{{driver?.id}} -->
                    </h5>
                </div>
                <div class="ibox-content slick" style="position: relative" *ngIf="languageLoaded">
                    <table style="width:100%; min-height:50px; padding-bottom: 0px;" datatable [dtOptions]="dtOptions"
                        class="table table-hover nowrap dataTable dtr-inline">
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>