import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { FormMode } from 'app/common/enums';
import { Asset } from 'app/models/asset.model';
import { ControlContainer, NgForm } from '@angular/forms';
import { assetTypeOptions } from 'app/common/globals';


@Component({
    selector: 'fh-asset-type-property-editor',
    templateUrl: 'assetTypePropertyEditor.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class AssetTypePropertiesComponent implements OnInit, OnChanges {

    @Input() assetTypeGroups: any;
    @Input() formMode: FormMode = FormMode.read;
    assetTypeOptions = assetTypeOptions;
    objectKeys = Object.keys;
    insertId = 0;
    isInsertmode = false;

    constructor() {
    }

    ngOnInit(): void {
    }

    ngOnChanges(): void {
    }


    getOptionsOnValue(searchId) {

        const temp = this.assetTypeOptions.filter(x => x.id.toString() === searchId.toString());
        const returnobject = temp.length > 0 ? temp[0].options : '';
        return returnobject;
    }

    addMain() {
        if (!this.assetTypeGroups[this.insertId]) {
            this.assetTypeGroups[this.insertId] = [];
        }
    }

    addSub(key) {
        this.assetTypeGroups[key].push({ 'Id': '0', 'Type': key, 'Value': '' });
    }

    removeMain(property) {
        if (this.assetTypeGroups[property] != null) {
            this.assetTypeGroups.splice(property, 1);
        }
    }

    removeSub(key, property) {

        for (let i = this.assetTypeGroups[key].length - 1; i >= 0; --i) {
            if (this.assetTypeGroups[key][i].Id === property.Id) {
                this.assetTypeGroups[key].splice(i, 1);
            }
        }
    }
}

