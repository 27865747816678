import { Severity, SettingsLevel } from './../../common/enums';
import { IssueTypeSettings } from './../../models/calculationsettings.model';
import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { PlatformService } from '../../services/platform/platform.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IssueType } from '../../models/issuetype.model';

@Component({
    selector: 'fh-calculation-settings-overview-table',
    templateUrl: 'calculationSettingsOverviewTable.template.html'
})
export class CalculationSettingsOverViewTableComponent {
  @Input() settings: any[];
  @Input() title: string;
  @Input() issueTypes: IssueType[];

  constructor() {
  }
}
