<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Devices/Overview">{{'general.devices' | translate }}</a>
            </li>
            <li>
                <a href="/#/DeviceDetails/Index/{{device?.id}}">{{device?.name}}</a>
            </li>
            <li class="active">
                <strong>{{'menu.details' | translate }}</strong>
            </li>
        </ol>
    </div>
</div>

<div class="wrapper wrapper-content">

    <div class="row" *ngIf="device?.isNoteWarning">
        <div class="col-lg-12">
            <div class="alert alert-danger shadow">
                <strong>Warning!</strong> {{device?.note}}
            </div>
        </div>
    </div>

    <div class="row" data-equalizer="">
        <div class="col-lg-6">
            <fh-error [error]="error" [success]="success"></fh-error>
            <form #userForm="ngForm" #formDir="ngForm">
                <div class="ibox float-e-margins" style="position:relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <div class="ibox-title">
                        <h5 style="font-weight:bold">
                            <i class="fas fa-fw fa-memory"></i> {{ device?.name }}
                        </h5>

                        <span class="pull-right">
                            <i (click)="changeFlagged($event)" style=" font-size: 1.3em;" class="fa-fw fa-2x fa-star"
                                [ngClass]="{'fas': device?.isFlagged, 'far': !device?.isFlagged}"></i>
                        </span>
                    </div>
                    <fh-device-details [device]="device" [formMode]="formMode"></fh-device-details>
                    <fh-save-footer *ngIf="permissions['HasWasl']" [valid]="!userForm.invalid" (onSave)="onSave()"
                        (setFormMode)="setFormMode($event)" (onDelete)="onDelete()"
                        [allowDelete]="permissions['HasWasl']" [formMode]="formMode"></fh-save-footer>
                </div>
            </form>

            <fh-schedule-details *ngIf="permissions['HasScheduleView']" [device]="device"
                (onUpdated)="updateSchedule($event)" [renderDateTime]="renderDateTime"></fh-schedule-details>

            <fh-advice-details *ngIf="permissions['HasScheduleView']" [device]="device"
                (onUpdated)="updateSchedule($event)"></fh-advice-details>

            <div style="position: relative">
                <ngx-loading [show]="loadingIssueCount"></ngx-loading>
                <tabset [justified]="true">
                    <tab *ngIf="permissions['HasIssues']">
                        <ng-template tabHeading>
                            <i class="fas fa-fw fa-chart-bar"></i> {{'dashboard.issuecount' | translate }}
                        </ng-template>
                        <div class="ibox-content" style="height:214px; border:0px" [chart]="chartIssueCount"></div>
                    </tab>
                    <tab>
                        <ng-template tabHeading>
                            <i class="fas fa-fw fa-chart-bar"></i> {{'general.messages' | translate }}
                        </ng-template>
                        <div class="ibox-content" style="height:214px; border:0px" [chart]="chartLocationCount"></div>
                    </tab>
                    <tab>
                        <ng-template tabHeading>
                            <i class="fas fa-fw fa-chart-bar"></i> {{'general.distance' | translate }}
                        </ng-template>
                        <div class="ibox-content" style="height:214px; border:0px" [chart]="chartDistanceCount"></div>
                    </tab>
                </tabset>
                <div style="clear:both"></div>
            </div>

            <!-- <fh-device-details-nearby [device]="device"></fh-device-details-nearby> -->

        </div>
        <div class="col-lg-6">
            <div class="ibox float-e-margins" *ngIf="permissions['HasIssues']">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-chart-pie"></i> {{'general.kpis' | translate }}</h5>
                    <iboxtools></iboxtools>
                </div>
                <div class="ibox-content slick" style="position:relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <div
                        style="width:50%; float:left; padding: 18px; margin:0px; background-color: #f9f9f9; border: 2px solid #fff">
                        <h1 class="no-margins font-bold"
                            title="{{ oneWeek | amDateFormat: 'll' }} - {{ yesterday | amDateFormat: 'll' }}">{{
                            locationsLastWeekCount?.toLocaleString() }}</h1>
                        <span class="label pull-right"
                            title="{{ oneWeek | amDateFormat: 'll' }} - {{ yesterday | amDateFormat: 'll' }} : {{ locationsLastWeekCountOld }}"
                            [ngClass]="{'label-success': locationsLastWeekCountPercentage > 0, 'label-danger': locationsLastWeekCountPercentage < 0}">
                            {{ checkPositive(locationsLastWeekCountPercentage) }}
                        </span>
                        <small>{{'general.messages' | translate }} {{'dates.lastweek' | translate }}</small>
                    </div>
                    <div
                        style="width:50%; float:left; padding: 18px; margin:0px; background-color: #f9f9f9; border: 2px solid #fff">
                        <h1 class="no-margins font-bold"
                            title="{{ oneWeek | amDateFormat: 'll' }} - {{ yesterday | amDateFormat: 'll' }}">{{
                            issuesLastWeekCount?.toLocaleString() }}</h1>
                        <span class="label pull-right"
                            title="{{ twoWeeks | amDateFormat: 'll' }} - {{ oneWeek | amDateFormat: 'll' }} : {{ issuesLastWeekCountOld }}"
                            [ngClass]="{'label-success': issuesLastWeekCountPercentage > 0, 'label-danger': issuesLastWeekCountPercentage < 0}">
                            {{ checkPositive(issuesLastWeekCountPercentage) }}
                        </span>
                        <small>{{'general.issues' | translate }} {{'dates.lastweek' | translate }}</small>
                    </div>
                    <div
                        style="width:50%; float:left; padding: 18px; margin:0px; background-color: #f9f9f9; border: 2px solid #fff">
                        <h1 class="no-margins font-bold"
                            title="{{ oneMonth | amDateFormat: 'll' }} - {{ yesterday | amDateFormat: 'll' }}">{{
                            locationsLastMonthCount?.toLocaleString() }}</h1>
                        <span class="label pull-right"
                            title="{{ twoMonths | amDateFormat: 'll' }} - {{ oneMonth | amDateFormat: 'll' }} : {{ locationsLastMonthCountOld }}"
                            [ngClass]="{'label-success': locationsLastMonthCountPercentage > 0, 'label-danger': locationsLastMonthCountPercentage < 0}">
                            {{ checkPositive(locationsLastMonthCountPercentage) }}
                        </span>
                        <small>{{'general.messages' | translate }} {{'dates.lastmonth' | translate }}</small>
                    </div>
                    <div
                        style="width:50%; float:left; padding: 18px; margin:0px; background-color: #f9f9f9; border: 2px solid #fff">
                        <h1 class="no-margins font-bold"
                            title="{{ oneMonth | amDateFormat: 'll' }} - {{ yesterday | amDateFormat: 'll' }}">{{
                            issuesLastMonthCount?.toLocaleString() }}</h1>
                        <span class="label pull-right"
                            title="{{ twoMonths | amDateFormat: 'll' }} - {{ oneMonth | amDateFormat: 'll' }} : {{ issuesLastMonthCountOld }}"
                            [ngClass]="{'label-success': issuesLastMonthCountPercentage > 0, 'label-danger': issuesLastMonthCountPercentage < 0}">
                            {{ checkPositive(issuesLastMonthCountPercentage) }}
                        </span>
                        <small>{{'general.issues' | translate }} {{'dates.lastmonth' | translate }}</small>
                    </div>
                    <div style="clear:both"></div>
                </div>
            </div>

            <fh-tagging [objectId]="device.id" [objectType]="tagType">
            </fh-tagging>

            <div class="ibox float-e-margins" *ngIf="permissions['HasIssues']">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-chart-area"></i> {{'devicedetails.issuetimeline' | translate }}</h5>
                </div>
                <div class="ibox-content slick" style="position:relative">
                    <ngx-loading [show]="loadingIssues"></ngx-loading>
                    <div class="ibox-content" style="position: relative " *ngIf="!chartColumnRangeEnabled">
                        {{'general.nodatafound' | translate }}
                    </div>
                    <div *ngIf="chartColumnRangeEnabled" style="height:183px" [chart]="chartColumnRange">
                    </div>
                </div>
            </div>

            <div class="ibox float-e-margins">
                <div class="ibox-title ">
                    <h5>
                        <i class="fa fa-fw fa-sticky-note"></i> {{'general.note' | translate }}</h5>
                </div>
                <div style="position:relative">
                    <ngx-loading [show]="loadingNote"></ngx-loading>
                    <form novalidate #f="ngForm" style="width:100%" (ngSubmit)="saveNote()">
                        <div class="ibox-content">
                            <textarea name="note" class="form-control noresize stickypad"
                                style="height:100px; width:100%; resize: none" id="encCss" required
                                [(ngModel)]="device.note"></textarea>
                        </div>
                        <div class="ibox-footer">
                            <button class="btn btn-primary" style="min-width:120px" [disabled]="!f.dirty">
                                <i class="fa fa-fw fa-sticky-note"></i> {{'general.save' | translate }}</button>
                            <label style="padding-top: 6px;padding-left: 12px;">
                                <input type="checkbox" name="alertmode" [(ngModel)]="device.isNoteWarning"> {{'general.isalert'
                                | translate }}</label>
                            <div style="clear:both"></div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="ibox float-e-margins " style="position:relative">
                <div class="ibox-title ">
                    <h5>
                        <i class="fa fa-map "></i> {{'devicedetails.lastknown' | translate }}</h5>
                </div>
                <div class="ibox-content slick " style="height: 300px; ">
                    <div style="height: 100%; " leaflet [leafletOptions]="options"
                        (leafletMapReady)="onMapReady($event)">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>