<div *ngIf="error" class="alert alert-warning shadow">
    <i class="fas fa-fw fa-times pull-right" style="cursor: pointer;" (click)="clearError()"></i>
    <span *ngIf="error && error?.statusText">
        <p style="font-size:15px"><strong>{{ error?.statusText }}</strong></p>
    </span>
    <span *ngIf="error && error?.error">
        <p style="margin:0">- {{ error?.error }}</p>
    </span>
    <!-- <span *ngIf="error && error?.message">
        <p>- {{ error?.message }}</p>
    </span>
    <span *ngIf="error && error?.name">
        <p>- {{ error?.name }}</p>
    </span> -->
    <span *ngIf="error && error.json">
        <prettyjson [obj]="error.json"></prettyjson>
    </span>

    <span *ngIf="error && !error?.statusText"><strong>{{ error }}</strong></span>
</div>

<div *ngIf="success" class="alert alert-success shadow">
    <i class="fas fa-fw fa-times pull-right" style="cursor: pointer;" (click)="clearError()"></i>
    <span *ngIf="success && success?.statusText">
        <p style="font-size:15px"><strong>{{ success?.statusText }}</strong></p>
    </span>
    <span *ngIf="success && success?.success">
        <p style="margin:0">{{ success?.success }}</p>
    </span>
    <span *ngIf="success && success.json">
        <prettyjson [obj]="success.json"></prettyjson>
    </span>
    <span *ngIf="success && !success.statusText">
        <p style="margin:0"><strong>{{ success }}</strong></p>
    </span>
</div>