<div class="ibox float-e-margins">
    <div class="ibox-title">
        <h5>
            <i class="fas fa-fw fa-star"></i> {{'general.advice' | translate }}</h5>
    </div>
    <div class="ibox-footer" style="position:relative">
        <ngx-loading [show]="loading"></ngx-loading>
        <form novalidate #f="ngForm" style="width:100%" (ngSubmit)="addSchedules()">
            <div class="col-md-6 col-lg-3" style="padding-left: 0">
                <div class="form-group">
                    <select style="width:100%" name="action" class="form-control" required [(ngModel)]="model.workflow">
                        <option value=''>{{ 'general.chooseWorkflow' | translate}}</option>
                        <option *ngFor="let workflow of workflows" [value]="workflow.id">{{ workflow.name }}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-6 col-lg-3" style="padding-left: 0">
                <div class="form-group input-group date">
                    <span class="input-group-addon">
                        <i class="fa fa-calendar"></i>
                    </span>
                    <input type="text" name="date" style="width:100%" class="form-control" #dp="bsDatepicker" bsDatepicker [(bsValue)]="bsValue"
                        [bsConfig]="dpConfig" required [(ngModel)]="model.date">
                </div>
            </div>
            <div class="col-md-6 col-lg-3" style="padding-left: 0">
                <div class="form-group">
                    <select name="user" style="width:100%;" name="owner" class="form-control" required [(ngModel)]="model.user">
                        <option value=''>{{ 'general.chooseUser' | translate}}</option>
                        <option *ngFor="let user of users" [value]="user.id">{{ user.name }}
                    </select>
                </div>
            </div>
            <div class="col-md-6 col-lg-3" style="padding-left: 0">
                <button style="float:left; padding-left:10px; width:100%" class="btn btn-primary" type="submit" [disabled]="!f.valid">
                    <i class="fas fa-fw fa-calendar-plus"></i> {{'schedule.addtoschedule' | translate }}</button>
            </div>
            <div style="clear:both"></div>
        </form>
    </div>
</div>