export interface ILatLng {
    latitude: number;
    longitude: number;
}

export class Location implements ILatLng {
    speedInKph: number;

    constructor(public latitude: number, public longitude: number) { }
}
