<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Accounts/Overview">{{'general.accounts' | translate }}</a>
            </li>
            <li class="active">
                <strong>{{'menu.add' | translate }}</strong>
            </li>
        </ol>
    </div>
</div>

<div class="wrapper wrapper-content">

    <div class="row" data-equalizer="">

        <form class="" #userForm="ngForm" action="#">
            <div *ngIf="hasError" class="col-lg-12">
                <fh-error [autoRemove]="false" [error]="hasError"></fh-error>
            </div>
            <div class="col-lg-5">
                <div class="ibox float-e-margins">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-file"></i> {{'general.account' | translate }}
                        </h5>
                    </div>

                    <div class="ibox-content slick" style="position:relative">
                        <ngx-loading [show]="loading"></ngx-loading>

                        <fh-account-details [account]="accountModel" [formMode]="formMode"></fh-account-details>
                    </div>
                    <fh-save-footer
                        [valid]="(!userForm.invalid && (hasValidPlatform() || optionalAccountPlatform == true))"
                        [allowDelete]="false" (onInsert)="createAccount()" [formMode]="formMode">
                    </fh-save-footer>
                </div>
            </div>
            <div class="col-lg-7">
                <div class="ibox float-e-margins">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-users"></i> {{'menu.addUser' | translate }}
                        </h5>
                    </div>
                    <fh-user-details [user]="accountModel.userRequest" [formMode]="formMode"></fh-user-details>
                </div>

                <fh-accountplatform-control #accountplatforms [insertOnly]=true [formMode]="formMode"
                    [accountPlatformRequest]="accountModel.accountPlatformRequest"></fh-accountplatform-control>

            </div>
        </form>
    </div>
</div>