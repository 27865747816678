import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch';

import { AuthenticationService } from '../authentication/authentication.service';
import { Driver, AssetDriver, WaslDriver } from '../../models/driver.model';
import { LoggingService } from '../logging/logging.service';
import { fixDate } from '../common/functions.service';

@Injectable()
export class DriverService {
    url = '';
    inventoryUrl = '';
    Drivers: Driver[] = [];

    constructor(private http: HttpClient, private loggingService: LoggingService, private authenticationService: AuthenticationService) {
        this.url = this.authenticationService.getWebserviceURL('driver');
        this.inventoryUrl = this.authenticationService.getInventoryURL('driver');
    }

    getPagingUrl() {
        return this.url + 'PagedDrivers';
    }

    getDrivers(): Observable<Driver[]> {
        console.log('getting drivers from service');
        return this.http.get(this.url, { headers: this.authenticationService.headers })
            .map((data) => {
                return this.parseResponse(data);
            })
            .catch(this.handleError);
    }


    getDriverById(id: string): Observable<Driver> {
        console.log('Fetch driver by id ' + id);
        return this.http.get(this.url + id, { headers: this.authenticationService.headers })
            .map((data) => {
                return this.parseReponseDetails(data);
            })
            .catch(this.handleError);
    }

    saveDriver(driver: Driver): Observable<any> {
        console.log('save driver', driver);

        driver.dateOfBirth = fixDate(driver.dateOfBirth);

        return this.http.post(this.inventoryUrl, driver, { headers: this.authenticationService.headers })
            .catch(this.handleError);
    }

    updateDriver(driver: Driver): Observable<any> {
        console.log('update driver', driver);

        driver.dateOfBirth = fixDate(driver.dateOfBirth);

        return this.http.put(this.inventoryUrl, driver, { headers: this.authenticationService.headers })
            .catch(this.handleError);
    }

    resetCache(): Observable<boolean> {
        return this.http.get(this.url + 'ResetCache', { headers: this.authenticationService.headers })
            .map((data: any) => {
                return data;
            })
            .catch(this.handleError);
    }

    getDriversByDeviceId(id: string): Observable<Driver[]> {
        console.log('Fetch drivers by id ' + id);
        return this.http.get(this.url + 'Device/' + id, { headers: this.authenticationService.headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getDriversByAssetId(id: string): Observable<Driver[]> {
        console.log('Fetch drivers by id ' + id);
        return this.http.get(this.url + 'Asset/' + id, { headers: this.authenticationService.headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    private handleError(error: Response) {
        console.log(error);
        return Observable.throw(error);
    }

    parseResponse(json: any): Driver[] {
        this.loggingService.log(this.constructor.name, 'Retrieved ' + json.length + ' Drivers.');

        const ident = 1;
        const drivers: Driver[] = [];

        json.forEach(item => {

            const driver = this.parseReponseDetails(item);
            drivers.push(driver);
        });

        return drivers;
    }

    parseReponseDetails(item) {
        const driver = new Driver();
        driver.id = item.id;
        driver.name = item.name;
        driver.referenceId = item.referenceId;
        driver.code = item.code;
        driver.dateOfBirth = new Date(item.dateOfBirth);
        driver.phone = item.phone;
        driver.accountPlatformId = item.accountPlatformId;


        if (item.assetDriverDto != null) {
            const assetDriver = item.assetDriverDto;
            driver.assetDriver = new AssetDriver();
            driver.assetDriver.id = assetDriver.assetDriverId;
            driver.assetDriver.startDate = assetDriver.startDate;
            driver.assetDriver.endDate = assetDriver.endDate;
            driver.assetDriver.type = assetDriver.type;

            driver.isActive = (assetDriver.endDate == null || new Date(assetDriver.endDate) > new Date());
        }

        if (item.properties) {
            if (item.properties.wasl) {
                const wasl = new WaslDriver();
                wasl.referenceKey = item.properties.wasl.referenceKey;
                wasl.identityNumber = item.properties.wasl.identityNumber;
                wasl.dateOfBirthGregorian = item.properties.wasl.dateOfBirthGregorian;
                wasl.mobileNumber = item.properties.wasl.mobileNumber;

                driver.properties.wasl = wasl;
            }
        }

        return driver;
    }
}
