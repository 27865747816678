<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Issues/Overview">{{'general.issues' | translate }}</a>
            </li>
            <li class="active">
                <a href="/#/DeviceTypes/Charts">{{'menu.charts' | translate }}</a>
            </li>
        </ol>
       <fh-filter-toggler style="margin-left: auto;" [constructorName]="constructorName"
            (filterChanged)="showFilter = $event"></fh-filter-toggler>
    </div>
</div>

<div class="wrapper wrapper-content">
    <div class="row">
        <div class="col-lg-12" *ngIf="showFilter" [@slideInOut]>
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-filter"></i> {{'general.filter' | translate }}</h5>
                </div>
                <div class="ibox-content" style="position: relative">
                    <div class="col-sm-6">
                        <div class="form-group input-group" style="margin-bottom: 5px;">
                            <span class="input-group-addon">
                                <i class="fa fa-fw fa-calendar"></i>
                            </span>
                            <input placeholder="Daterangepicker" style="width:100%" type="text" [bsConfig]="dpConfig"
                                class="form-control" bsDaterangepicker [(ngModel)]="daterangepickerModel"
                                (ngModelChange)="dateChanged($event)">
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group input-group" style="margin-bottom: 5px;">
                            <span class="input-group-addon">
                                <i class="fa fa-fw fa-filter"></i>
                            </span>
                            <select name="source" id="sourceSelector" style="width:100%" class="form-control"
                                [(ngModel)]="selectSource" (change)="fireFilter()">
                                <option value='devicetype'>{{'general.devicetype' | translate }}</option>
                                <option value='manufacturer'>{{'general.manufacturer' | translate }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-6">
                    </div>

                    <div id="export-assets"></div>
                    <div style="clear:both"></div>
                </div>
            </div>
        </div>
        <div class="col-lg-12">
            <div style="position: relative">
                <ngx-loading [show]="loading"></ngx-loading>
                <tabset [justified]="false">
                    <tab>
                        <ng-template tabHeading>
                            <i class="fas fa-fw fa-satellite-dish"></i> Percentage issues per devicetype
                        </ng-template>
                        <div class="ibox-content" style="height:600px; border:0px" [chart]="chart3"></div>
                    </tab>
                    <tab>
                        <ng-template tabHeading>
                            <i class="fas fa-fw fa-satellite-dish"></i> Absolute issues per devicetype
                        </ng-template>
                        <div class="ibox-content" style="height:600px; border:0px" [chart]="chart1">
                        </div>
                    </tab>
                    <tab>
                        <ng-template tabHeading>
                            <i class="fas fa-fw fa-satellite-dish"></i> Percentage of devicetype issues
                        </ng-template>
                        <div class="ibox-content" style="height:600px; border:0px" [chart]="chart2">
                        </div>
                    </tab>
                </tabset>
            </div>
        </div>
    </div>
</div>