import { Device } from './device.model';
import { User } from './user.model';

export class Schedule {

    public issueType: any;
    public userName: any;
    public deviceName: any;
    public deviceId: any;
    public id: number;
    public device: Device;
    public date: Date;
    public modifiedDate: any;
    public createdDate: any;
    public dateProcessed: Date;
    public userId: string;
    public actionName: string;
    public actionId: number;
    public workflowName: any;
    issueId: any;
    workflowId: any;
}
