import { Component, Input } from '@angular/core';
import { Asset } from 'app/models/asset.model';
import { AssetTypeService } from 'app/services/asset/assetTypes.service';
import { AssetType } from 'app/models/assetType.model';
import { ControlContainer, NgForm } from '@angular/forms';
import { FormMode } from 'app/common/enums';

@Component({
    selector: 'fh-assettype-selector',
    templateUrl: 'assetTypeSelector.template.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class AssetTypeSelectorComponent {

    @Input() asset: Asset;
    @Input() assetClass = 1;
    @Input() formMode: FormMode = FormMode.read;

    loadingAssetTypes: boolean;
    assetTypes: AssetType[] = [];
    selectedAssetType: any;
    assetTypeGroups: Record<string, any[]>;
    objectKeys = Object.keys;

    constructor(private assetTypeService: AssetTypeService) {
        this.loadingAssetTypes = true;
        this.assetTypeService.getAssetTypes().subscribe(assetTypes => {
            this.assetTypes = assetTypes.filter(x => x.assetClass === this.assetClass);
            this.loadingAssetTypes = false;
        });
    }

    groupBy<T extends any, K extends keyof T>(array: T[], key: K | ((obj: T) => string)): Record<string, T[]> {
        const keyFn = key instanceof Function ? key : (obj: T) => obj[key];

        return array.reduce(
            (objectsByKeyValue, obj) => {
                const value = keyFn(obj)
                objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
                return objectsByKeyValue
            },
            {} as Record<string, T[]>
        )
    }

    selectAssetType(event) {
        if (event.value === -1) {
            this.selectedAssetType = null;
        } else {
            this.assetTypeService.getAssetTypeById(event.value).subscribe(result => {
                this.selectedAssetType = result;

                this.assetTypeGroups = this.groupBy(this.selectedAssetType.properties, obj => obj.Type);
            });
        }
    }

}
