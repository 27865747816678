<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Devices/Overview">{{'general.devices' | translate }}</a>
            </li>
            <li class="active">
                <a href="/#/Devices/Overview">{{'menu.add' | translate }}</a>
            </li>
        </ol>
    </div>
</div>

<div class="wrapper wrapper-content">
    <div class="row">
        <div class="col-lg-12">
            <fh-error [error]="error" [success]="success"></fh-error>
            <form #userForm="ngForm" #formDir="ngForm">
                <div class="ibox float-e-margins" style="position:relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-memory"></i> {{'general.device' | translate }}</h5>
                    </div>
                    <fh-device-details [device]="device" [formMode]="formMode"></fh-device-details>
                    <fh-save-footer [valid]="!userForm.invalid" (onInsert)="onInsert()" [formMode]="formMode">
                    </fh-save-footer>
                </div>
            </form>
        </div>
    </div>
</div>