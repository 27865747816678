import { Component, OnInit, AfterViewInit } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Subject } from 'rxjs/Subject';
import { IssueService } from '../../services/issues/issues.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Issue } from 'app/models/issue.model';
import { AuditLogService } from '../../services/auditlog/auditlog.service';
import { AuditLog } from '../../models/auditlog.model';

@Component({
    selector: 'fh-issue-log',
    templateUrl: 'issueLog.template.html',
    styleUrls: ['issueLog.template.css']
})
export class IssueLogViewComponent implements OnInit {
    sub;
    issue: Issue;
    issueLog: AuditLog[];
    loading = false;

    constructor(private http: HttpClient, private auditLogService: AuditLogService, private issueService: IssueService, private route: ActivatedRoute, private router: Router) {
        this.issue = null;
        this.issueLog = [];
    }

    ngOnInit() {
        this.loading = true;
        this.issue = new Issue;
        this.issue.id = '';
        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.auditLogService.getAuditLogByIssue(id).subscribe(issueLog => {
                this.issueLog = issueLog;
                this.loading = false;
            });
        });
    }
}
