<div class="alert shadow" [ngClass]="messageStatus" *ngIf="isMessage" (click)="isMessage = undefined">
    {{ updateMessage }}
</div>


<div class="ibox float-e-margins" style="position:relative">
    <ngx-loading [show]="loading"></ngx-loading>
    <div class="ibox-title">
        <h5 style="font-weight:bold">
            <i class="fas fa-fw fa-user"></i> {{ user?.name }}
        </h5>
    </div>
    <form #userForm="ngForm" #formDir="ngForm">
        <fh-user-details [user]="user" [formMode]="formMode"></fh-user-details>
        <div class=" ibox-footer">
            <button class="btn btn-primary" *ngIf="permissions['IsAdministrator']" (click)="impersonate()">
                <i class="fa fa-fw fa-user-secret"></i></button>

            <button class="btn btn-primary" (click)="changeMode(2)" *ngIf="formMode == 1">
                <i class="fa fa-fw fa-edit"></i> {{'general.edit' | translate }}</button>

            <button class="btn btn-primary" (click)="save()" *ngIf="formMode == 2 || formMode == 3">
                <i class="fa fa-fw fa-save"></i> {{'general.save' | translate }}</button>

            <button class="btn btn-danger" s *ngIf="formMode == 1" (click)="showModal()">
                <i class="fa fa-fw fa-trash"></i> {{'general.delete' | translate }}</button>

            <button class="btn btn-danger" *ngIf="formMode == 1" (click)="resetPassword()">
                <i class="fa fa-fw fa-key"></i> {{'general.resetPassword' | translate }}</button>

            <button class="btn" (click)="changeMode(1)" *ngIf="formMode == 2 || formMode == 3">
                <i class="fa fa-fw fa-ban"></i> {{'general.cancel' | translate }}</button>
            <div style="clear: both"></div>
        </div>
    </form>
</div>

<fh-delete-modal #deleteModal (onDelete)="deleteDevice()"></fh-delete-modal>