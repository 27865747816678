<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="">{{'general.settings' | translate }}</a>
            </li>
            <li class="active">
                <strong>{{'menu.settingsgeneral' | translate}}</strong>
            </li>
        </ol>
    </div>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="">
        <div class="col-lg-6">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-satellite-dish"></i> {{'general.settings' | translate}}
                    </h5>
                </div>
                <div class="ibox-content">
                    <div class="">
                        <div class="col-md-5">
                            <div class="form-group label-floating is-empty">
                                <label class="control-label">Company (disabled)</label>
                                <input type="text" class="form-control" disabled="">
                                <span class="material-input"></span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group label-floating is-empty">
                                <label class="control-label">Username</label>
                                <input type="text" class="form-control">
                                <span class="material-input"></span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group label-floating is-empty">
                                <label class="control-label">Email address</label>
                                <input type="email" class="form-control">
                                <span class="material-input"></span>
                            </div>
                        </div>
                    </div>
                    <div class="">
                        <div class="col-md-6">
                            <div class="form-group label-floating is-empty">
                                <label class="control-label">Fist Name</label>
                                <input type="text" class="form-control">
                                <span class="material-input"></span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group label-floating is-empty">
                                <label class="control-label">Last Name</label>
                                <input type="text" class="form-control">
                                <span class="material-input"></span>
                            </div>
                        </div>
                    </div>
                    <div class="">
                        <div class="col-md-12">
                            <div class="form-group label-floating is-empty">
                                <label class="control-label">Adress</label>
                                <input type="text" class="form-control">
                                <span class="material-input"></span>
                            </div>
                        </div>
                    </div>
                    <div class="">
                        <div class="col-md-4">
                            <div class="form-group label-floating is-empty">
                                <label class="control-label">City</label>
                                <input type="text" class="form-control">
                                <span class="material-input"></span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group label-floating is-empty">
                                <label class="control-label">Country</label>
                                <input type="text" class="form-control">
                                <span class="material-input"></span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group label-floating is-empty">
                                <label class="control-label">Postal Code</label>
                                <input type="text" class="form-control">
                                <span class="material-input"></span>
                            </div>
                        </div>
                    </div>
                    <div class="">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>About Me</label>
                                <div class="form-group label-floating is-empty">
                                    <label class="control-label"> Write here about yourself.</label>
                                    <textarea class="form-control noresize" style="width:100%; height: 150px"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="submit" class="btn btn-primary pull-right">
                        <i class="fas fa-fw fa-save"></i> Update Profile</button>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fa fa-satellite-dish"></i> Change password
                    </h5>
                </div>
                <div class="ibox-content">
                    <div class="">
                        <div class="col-md-12">
                            <div class="form-group label-floating is-empty">
                                <label class="control-label">Old password</label>
                                <input type="text" class="form-control">
                                <span class="material-input"></span>
                            </div>
                        </div>
                    </div>
                    <div class="">
                        <div class="col-md-12">
                            <div class="form-group label-floating is-empty">
                                <label class="control-label">New password</label>
                                <input type="text" class="form-control">
                                <span class="material-input"></span>
                            </div>
                        </div>
                    </div>
                    <div class="">
                        <div class="col-md-12">
                            <div class="form-group label-floating is-empty">
                                <label class="control-label">Confirm password</label>
                                <input type="text" class="form-control">
                                <span class="material-input"></span>
                            </div>
                        </div>
                    </div>
                    <!-- Button -->
                    <button id="submit" name="submit" class="btn btn-primary">
                        <i class="fas fa-fw fa-save"></i> Save Password</button>
                    <div style="clear:both"></div>
                </div>
            </div>
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fa fa-satellite-dish"></i> System settings
                    </h5>
                </div>
                <div class="ibox-content">
                    <table class="table table-hover nowrap dtr-inline no-footer">
                        <tbody>
                            <tr>
                                <td>
                                    <div>
                                        <input type="checkbox" name="optionsCheckboxes" checked="">
                                        <span class="checkbox-material">
                                            <span class="check"></span>
                                        </span>
                                    </div>
                                </td>
                                <td>Sign contract for</td>
                                <td class="td-actions text-right">
                                    <button type="button" rel="tooltip" title="" class="btn btn-primary btn-grid" data-original-title="Edit Task">
                                        <i class="material-icons">edit</i>
                                    </button>
                                    <button type="button" rel="tooltip" title="" class="btn btn-danger btn-grid" data-original-title="Remove">
                                        <i class="material-icons">close</i>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>
                                        <input type="checkbox" name="optionsCheckboxes">
                                        <span class="checkbox-material">
                                            <span class="check"></span>
                                        </span>
                                    </div>
                                </td>
                                <td>Lines From Great Russian Literature?</td>
                                <td class="td-actions text-right">
                                    <button type="button" rel="tooltip" title="" class="btn btn-primary btn-grid" data-original-title="Edit Task">
                                        <i class="material-icons">edit</i>
                                    </button>
                                    <button type="button" rel="tooltip" title="" class="btn btn-danger btn-grid" data-original-title="Remove">
                                        <i class="material-icons">close</i>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>
                                        <input type="checkbox" name="optionsCheckboxes">
                                        <span class="checkbox-material">
                                            <span class="check"></span>
                                        </span>
                                    </div>
                                </td>
                                <td>Flooded: One year later, assessing what was lost
                                </td>
                                <td class="td-actions text-right">
                                    <button type="button" rel="tooltip" title="" class="btn btn-primary btn-grid" data-original-title="Edit Task">
                                        <i class="material-icons">edit</i>
                                    </button>
                                    <button type="button" rel="tooltip" title="" class="btn btn-danger btn-grid" data-original-title="Remove">
                                        <i class="material-icons">close</i>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>
                                        <input type="checkbox" name="optionsCheckboxes" checked="">
                                        <span class="checkbox-material">
                                            <span class="check"></span>
                                        </span>
                                    </div>
                                </td>
                                <td>Create 4 Invisible User Experiences</td>
                                <td class="td-actions text-right">
                                    <button type="button" rel="tooltip" title="" class="btn btn-primary btn-grid" data-original-title="Edit Task">
                                        <i class="material-icons">edit</i>
                                    </button>
                                    <button type="button" rel="tooltip" title="" class="btn btn-danger btn-grid" data-original-title="Remove">
                                        <i class="material-icons">close</i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>