import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AssetService } from '../../services/asset/asset.service';
import { TagObjectType } from 'app/models/tag.model';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { FormMode } from 'app/common/enums';
import { Asset } from 'app/models/asset.model';
import { InventorySensorType } from 'app/models/sensorType.model';
import { SensorsTypesService } from 'app/services/sensors/sensorTypes.service';

@Component({
    selector: 'fh-fh-sensor-type-details',
    templateUrl: 'sensorTypesDetails.template.html'
})
export class SensorTypeDetailsViewComponent implements OnInit {
    sub: any;
    sensorType = new InventorySensorType();
    device: any;
    tagType = TagObjectType.Asset.valueOf();

    loadingDevice = false;
    loading = false;
    error: any;
    success: any;
    formMode = FormMode.read;
    options;
    marker;

    permissions: {};
    trailer: Asset;
    calibrationPoints: { rawValue: number; actualValue: number; }[];

    constructor(private authentication: AuthenticationService, private assetService: AssetService, private sensorTypeService: SensorsTypesService, private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.permissions = this.authentication.permissions;

        this.loadingDevice = true;
        this.loading = true;

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            const sensorTypes = this.sensorTypeService.getSensorTypeById(id).subscribe(result => {
                this.sensorType = result;
                this.loading = false;
            });
        });
    }

    // Form

    onDelete() {
        this.error = 'Deleting is not implemented';
    }

    onSave() {
        this.loading = true;

        this.sensorTypeService.updateSensorType(this.sensorType).subscribe(result => {

            this.error = '';
            this.success = {
                statusText: 'Success',
                success: 'SensorType has been saved'
            };

            this.loading = false;
        }, error => {
            this.error = error
            this.loading = false;
        });
    }

    setFormMode(mode) {
        this.formMode = mode;
    }
}
