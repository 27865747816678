import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';

import { DeviceService } from '../../services/device/device.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Device } from 'app/models/device.model';
import { FhChartService } from '../../services/charts/charts.service';
import { Map } from 'leaflet';
import { UserService } from '../../services/users/user.service';
import { User, AppUser } from '../../models/user.model';
import { PermissionsBitmask } from 'app/common/enums';
import { arrayFromMask } from 'app/services/common/functions.service';
import { UserRoleService } from 'app/services/users/userRole.service';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { getMapProviders, createMapOptions } from 'app/common/leafletGlobals';

declare var L;

@Component({
    selector: 'fh-user-details-view',
    templateUrl: 'userDetails.template.html',
    providers: [FhChartService]
})

export class UserDetailsViewComponent implements OnInit {

    map: Map;
    options;
    layersControl;
    selectedRow: any;
    selected: any;
    theTrips = [];
    timeoutHandler;
    chartFuel: any;
    sensors;
    sub;
    user: AppUser = new AppUser();
    loading = false;
    loadingSensors = false;

    theSaveBitmask;

    permissions: { name: string; value: any; isEnabled: boolean; }[];
    userRole: any;
    permissionBitmask: any;
    filteredPermissions: any;
    loadingRoles: boolean;
    displayPermissions: any;
    isImpersonated = false;
    maps: { 'mapTiler': any; 'Google': any; 'Hybrid': any; };

    constructor(private userRoleService: UserRoleService, private route: ActivatedRoute, private router: Router, private userService: UserService, private authenticationService: AuthenticationService) {
        this.user = null;
        this.sensors = [];
        this.theTrips = [];
        this.permissions = Object.keys(PermissionsBitmask)
            .filter(k => typeof PermissionsBitmask[k] === 'number')
            .map(n => ({ name: n, value: PermissionsBitmask[n], isEnabled: false }))
    }

    ngOnInit() {
        this.displayPermissions = this.authenticationService.permissions;
        this.isImpersonated = this.authenticationService.getIsImpersonated();

        this.user = new AppUser;
        this.loading = true;

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];
            this.userService.getUserById(id).subscribe(user => {
                this.user = user;
                this.loading = false;
                this.loadingRoles = true;

                this.userRoleService.getUserRoleById(user.userRoleId).subscribe(userRole => {
                    this.userRole = userRole;
                    this.loading = false;
                    this.loadingRoles = false;

                    this.permissions = arrayFromMask(userRole.permissions, this.permissions);
                    this.filteredPermissions = this.permissions.filter(x => x.isEnabled === true);

                    if (this.userRole == null) {
                        this.router.navigate(['/Users/Overview'])
                    }
                });

                if (this.user == null) {
                    this.router.navigate(['/Users/Overview'])
                }
            });
        });

        this.initMap()
    }

    roleChanged(event) {
        console.log('Role changed' + this.user.userRoleId);

        this.updateRole();
    }

    updateRole() {
        this.loadingRoles = true;
        this.userRoleService.getUserRoleById(this.user.userRoleId).subscribe(userRole => {
            this.userRole = userRole;
            this.loading = false;

            this.permissions = arrayFromMask(userRole.permissions, this.permissions);
            this.filteredPermissions = this.permissions.filter(x => x.isEnabled === true);

            this.loadingRoles = false;
        });
    }

    // Leaflet
    initMap() {
        this.maps = getMapProviders(L);
          const initMaps = [];
        initMaps.push(Object.values(this.maps)[0]);
        this.options = createMapOptions(L, initMaps);
    }

    onMapReady(map: Map) {
        this.map = map;

        const overlayMaps = {};

        L.control.layers(this.maps, overlayMaps, { position: 'topleft' }).addTo(map);

        this.map.invalidateSize();
    }
}
