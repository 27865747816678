import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';

@Injectable()
export class EventService {

  // Observable assetCount source
  private assetCount = new Subject<number>();

  // Observable assetCount stream
  assetCount$ = this.assetCount.asObservable();

  // Whitelabel
  private whitelabel = new Subject<string>();
  onWhitelabelChance = this.whitelabel.asObservable();

  setAssetsUpdated(bool) {
    this.assetCount.next(bool);
  }

  setWhitelabel(whitelabel) {
    this.whitelabel.next(whitelabel);
  }
}
