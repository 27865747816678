import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DeviceSensor, SensorUser, SensorDevice } from 'app/models/sensor.model';
import { DeviceSensorsService } from 'app/services/sensors/deviceSensors.service';

@Component({
  selector: 'fh-sensor-details',
  templateUrl: 'sensorDetails.template.html',
  providers: []
})
export class SensorDetailsComponent implements OnChanges {
  @Input() sensor: DeviceSensor;
  @Input() sensorUsers: SensorUser[];
  @Input() showSensorUsers: any;
  filteredSensors: DeviceSensor[] = [];
  loading: boolean;
  hideIrrelevant = true;
  // Pagination
  currentPage = 1;
  smallnumPages = 0;
  ascending = false;
  sortingFor = '';

  sensorDevices: SensorDevice[];

  constructor(private sensorService: DeviceSensorsService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (Object.keys(changes).indexOf('showSensorUsers') !== -1) {
      this.setPage(1);
      setTimeout(() => {
        $('.modal .ibox').css('min-height', $('.modal .ibox').height() + 14);
      }, 10);
    }
  }

  close(): void {
    this.showSensorUsers.show = false;
    $('.modal .ibox').removeAttr('style');
  }

  getSensorDevices(sensorUser: SensorUser): void {
    this.loading = true;
    this.sensorService.getSensorDevices(this.sensor.name, this.sensor.parameter, this.sensor.deviceSensorTypeId, this.sensor.isCustomDeviceSensorTypeId, sensorUser.deviceTypeId, sensorUser.manufacturer, sensorUser.customerId)
      .subscribe((result: SensorDevice[]) => {
        this.loading = false;
        this.sensorDevices = result;
      });
  }

  setPage(pageNo: number): void {
    this.currentPage = pageNo;
  }
}
