<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/">{{'general.settings' | translate }}</a>
            </li>
            <li class="active">
                <strong>{{'workflow.header' | translate}}</strong>
            </li>
        </ol>
    </div>
</div>


<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="">
        <div class="col-lg-4">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-road"></i> {{'menu.settingsworkflow' | translate}}
                    </h5>
                </div>
                <div class="ibox-content">
                    <div class="form-group">
                        <select name="workflows" class="form-control table-inline" [(ngModel)]="selectedWorkflowId"
                            (ngModelChange)="changeWorkflow($event)">
                            <option value='0'>Select workflow</option>
                            <option *ngFor="let workflow of workflows" [value]="workflow.id">{{workflow.name}}</option>
                        </select>
                    </div>
                    <div *ngIf="selectedWorkflowId == '0'" class="form-group">
                        <input type="text" class="form-control table-inline" placeholder="Workflow name" [(ngModel)]="workflowName">
                    </div>

                    <input *ngIf="selectedWorkflow" type="text" class="form-control table-inline" placeholder="Days"
                        [(ngModel)]="selectedWorkflow.days">
                </div>
                <div class="ibox-footer">
                    <button id="submit " name="submit " class="btn btn-primary " [disabled]="selectedWorkflowId == '0' && workflowName == ''">
                        <i class="fas fa-fw fa-save "></i> Save</button>
                    <button id="submit " (click)="createNewWorkflow()" name="new " class="btn btn-primary ">
                        <i class="fas fa-fw fa-calendar-plus "></i> New</button>
                </div>
            </div>

            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-cog"></i> {{'workflow.actionstochoose' | translate}}
                    </h5>
                    <span class="pull-right">
                        <i (click)="addActionMode = !addActionMode" class="fas" [class.fa-toggle-on]="addActionMode"
                            [class.fa-toggle-off]="!addActionMode"></i>
                    </span>
                </div>
                <div class="ibox-content" *ngIf="addActionMode">
                    <input class="form-control table-inline" type="text" [(ngModel)]="actionName">
                </div>
                <div class="ibox-content" *ngIf="addActionMode">
                    <button id="submit " (click)="addAction()" name="submit " style="width:100%" class="btn btn-primary ">
                        <i class="fas fa-fw fa-save "></i> Add</button>
                </div>
                <div class="ibox-content">
                    <bs-sortable [(ngModel)]="actionsToChoose" fieldName="name" itemClass="sortable-item"
                        itemActiveClass="sortable-item-active" placeholderItem="Drag here" placeholderClass="placeholderStyle"
                        wrapperClass="sortable-wrapper"></bs-sortable>
                </div>
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-cog"></i> {{'workflow.actionsinworkflow' | translate}}
                    </h5>
                </div>
                <div class="ibox-content">
                    <bs-sortable [(ngModel)]="selectedWorkflow.workflowActions" fieldName="name" itemClass="sortable-item"
                        itemActiveClass="sortable-item-active" placeholderItem="Drag here" placeholderClass="sortable-item"
                        wrapperClass="sortable-wrapper"></bs-sortable>
                    <div style="clear: both"></div>
                </div>
            </div>
        </div>
        <div class="col-lg-8">

            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-cog"></i> {{'workflow.actionsafterselected' | translate}}
                    </h5>
                </div>
                <div class="ibox-content">
                    <div *ngIf="!selectedWorkflow || selectedWorkflow.workflowActions.length == 0">
                        No actions in workflow
                    </div>
                    <div class="col-lg-6">
                        <span class="selectable-item" [ngClass]="{'selected': actionInWorkflow == selectedAction}"
                            [class.custom]="actionInWorkflow.isCustom" (click)="selectAction(actionInWorkflow)" *ngFor="let actionInWorkflow of selectedWorkflow.workflowActions">
                            {{ actionInWorkflow.name }}
                        </span>
                    </div>
                    <div class="col-lg-6" style="position: relative">
                        <ngx-loading [show]="loadingNextActions"></ngx-loading>
                        <span class="selectable-item" *ngFor="let actionInWorkflow of nextActions">
                            <input type="checkbox" style="margin-top: 0;" [(ngModel)]="actionInWorkflow.isSelected"
                                (ngModelChange)="saveNextActions(actionInWorkflow)" [disabled]="!selectedAction || selectedAction.isEndAction">
                            {{ actionInWorkflow.name }}
                        </span>
                    </div>
                    <div style="clear:both"></div>
                </div>
            </div>

            <div class="ibox float-e-margins" *ngIf="selectedAction">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-cog"></i> {{'workflow.actiondetails' | translate}} "{{
                        selectedAction?.name }}"
                    </h5>
                </div>
                <div class="ibox-content slick">
                    <table style="width:100%" class="table table-hover nowrap dtr-inline no-footer">
                        <tbody>
                            <tr>
                                <td style="width: 25%">
                                    {{ 'workflow.startworkflow' | translate}}
                                </td>
                                <td style="width: 25%">
                                    <input type="checkbox" [(ngModel)]="selectedAction.isStartAction">
                                </td>
                                <td style="width: 25%">
                                    {{ 'workflow.endworkflow' | translate}}
                                </td>
                                <td style="width: 25%">
                                    <input type="checkbox" [(ngModel)]="selectedAction.isEndAction">
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'workflow.days' | translate}}
                                </td>
                                <td>
                                    <input class="form-control table-inline" type="text" [(ngModel)]="selectedAction.days">
                                </td>
                                <td>
                                    {{ 'workflow.allowAutoProcess' | translate}}
                                </td>
                                <td>
                                    <input type="checkbox" [(ngModel)]="selectedAction.allowAutoProcess">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="ibox float-e-margins" *ngIf="selectedAction">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-mail-bulk"></i> {{'workflow.correspondence' | translate}}
                    </h5>
                </div>
                <div class="ibox-content slick">
                    <table style="width:100%" class="table table-hover nowrap dtr-inline no-footer">
                        <tbody>
                            <tr>
                                <td style="width: 25%">
                                    {{ 'workflow.hasCorrespondence' | translate}}
                                </td>
                                <td style="width: 25%">
                                    <input type="checkbox" [(ngModel)]="selectedAction.hasCorrespondence">
                                </td>
                                <td style="width: 25%">
                                    {{ 'workflow.userlevel' | translate}}
                                </td>
                                <td style="width: 25%">
                                    <input type="text" class="form-control table-inline">
                                </td>
                            </tr>
                            <tr>
                                <td style="width: 25%">
                                    {{ 'workflow.method' | translate}}
                                </td>
                                <td style="width: 25%">
                                    <input type="text" class="form-control table-inline">
                                </td>
                                <td style="width: 25%">
                                    {{ 'workflow.user' | translate}}
                                </td>
                                <td style="width: 25%">
                                    <input type="text" class="form-control table-inline">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>