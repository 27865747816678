import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Subject } from 'rxjs/Subject';
import { DeviceService } from '../../services/device/device.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceType } from 'app/models/devicetype.model';
import { IssueService } from 'app/services/issues/issues.service';
import { Device } from 'app/models/device.model';
import { CustomerService } from 'app/services/customers/customers.service';
import { IssueTypeService } from 'app/services/issuetypes/issuetypes.service';
import { IssueType } from 'app/models/issuetype.model';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import { Customer } from '../../models/customer.model';
import { AssetService } from 'app/services/asset/asset.service';
import { getGridLanguages } from 'app/common/gridhelper';


@Component({
    selector: 'fh-customer-assets',
    templateUrl: 'customerAssets.template.html'
})
export class CustomerAssetsViewComponent implements OnInit {
    @ViewChild(DataTableDirective)
    datatableElement: DataTableDirective;

    loading = false;

    issueTypes: IssueType[];
    deviceTypes: DeviceType[];

    sub;
    dtOptions = {};
    devices: Device[];
    customer: Customer;
    languageLoaded: boolean;
    searchTerm: any;

    constructor(private translateService: TranslateService, private http: HttpClient, private assetService: AssetService, private issueTypeService: IssueTypeService, private customerService: CustomerService, private route: ActivatedRoute, private router: Router) {

        issueTypeService.getIssueTypes().subscribe(issueTypes => {
            this.issueTypes = issueTypes;
        });

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];
            this.customerService.getCustomerById(id).subscribe(customer => {
                this.customer = customer;
            });
        });
    }

    editMode(isEditMode) {
        const toolbox = document.getElementById('grid-toolbox');

        console.log(isEditMode);
        if (isEditMode) {
            toolbox.classList.remove('hidden');
        } else {
            toolbox.classList.add('hidden');
        }
    }

    seachChanged(value) {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.search(value).draw();
        });
    }

    ngOnInit(): void {
        this.translateService.get('general.date').subscribe(value => {
            this.languageLoaded = true; this.initGrid();
        });
    }

    initGrid(): void {
        const that = this;
        this.loading = true;

        this.dtOptions = {
            pagingType: 'full_numbers',
            serverSide: false,
            processing: false,
            scrollX: true,
            colReorder: { fixedColumnsLeft: 1 },
            stateSave: true,
            stateSaveCallback: function (settings, data) {
                localStorage.setItem('Columns_' + that.constructor.name, JSON.stringify(data));
            },
            stateLoadCallback: function () {
                try {
                    const columnSettings = JSON.parse(localStorage.getItem('Columns_' + that.constructor.name));
                    that.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
                    return columnSettings;
                } catch (e) {
                    console.log(e);
                }
            },
            ajax: (dataTablesParameters: any, callback) => {
                $('.dataTables_info').html(this.translateService.instant('grid.loadingData'));
                this.sub = this.route.params.subscribe(params => {
                    const id = params['id'];

                    this.assetService.getAssetsByCustomerId(id).subscribe(resp => {
                        callback({
                            recordsTotal: resp.length,
                            recordsFiltered: resp.length,
                            data: resp
                        });
                    });
                });
            },
            initComplete: function (settings, json) {
                that.loading = false;
            },
            colVis: {
                restore: this.translateService.instant('general.restore'),
                showAll: this.translateService.instant('general.showAll'),
                showNone: this.translateService.instant('general.hideAll'),
                exclude: ['id']
            },
            columns: [{
                data: 'id',
                orderable: false,
                title: '',
                width: '20',
                render: function (data, type, row) {
                    return '<a class=\'btn btn-primary btn-outline btn-grid\' href=\'/#/DeviceDetails/Index/' + data + '\'><i class=\'fas fa-fw fa-info\'></i> <span class="hidden-sm hidden-xs">Details</span></a>';
                }
            },
            {
                data: 'name',
                title: this.translateService.instant('general.name'),
            },
            {
                data: 'unitId',
                title: this.translateService.instant('general.unitId'),
            },
            {
                data: 'lastCommunication',
                width: '80',
                title: this.translateService.instant('general.lastCommunication'),
                render: function (data, type, row) {
                    if (type === 'display') {
                        return new Date(data).toLocaleDateString();
                    }
                    return data;
                }
            }],
            pageLength: 10,
            language: getGridLanguages(this.translateService),
        };
    }
}
