<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Trailers/Overview">{{'general.trailers' | translate }}</a>
            </li>
            <li>
                <a href="/#/Trailers/Index/{{trailer?.id}}">{{trailer?.name}}</a>
            </li>
            <li class="active">
                <strong>{{'general.trailerdetails' | translate }}</strong>
            </li>
        </ol>
    </div>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="" *ngIf="error || success">
        <div class="col-lg-12">
            <fh-error [error]="error" [success]="success"></fh-error>
        </div>
    </div>

    <div class="row" data-equalizer="">
        <div class="col-lg-6">

            <form #userForm="ngForm" #formDir="ngForm">
                <div class="ibox float-e-margins" style="position:relative">
                    <ngx-loading [show]="loadingTrailer"></ngx-loading>
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-truck-loading"></i> {{'general.trailer' | translate }}</h5>
                    </div>
                    <fh-asset-details [asset]="trailer" [formMode]="formMode"></fh-asset-details>
                    <fh-save-footer [valid]="!userForm.invalid" (onSave)="onSave()" (onDelete)="onDelete()"
                        [allowDelete]="permissions['HasWasl']" (setFormMode)="setFormMode($event)"
                        [formMode]="formMode">
                    </fh-save-footer>
                </div>
            </form>
        </div>
        <div class="col-lg-6">

            <fh-assettype-module [asset]="trailer" [loading]="loadingTrailer"></fh-assettype-module>

            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fa fa-calculator"></i> {{'general.calibration' | translate }}
                    </h5>
                </div>
                <div class="ibox-content" style="position:relative">
                    Implicit from assettype
                </div>
                <div class="ibox-footer">
                    <button class="btn btn-primary"><i class="fa fa-fw fa-edit"></i>
                        {{'general.custom' | translate }}</button>
                </div>
            </div>

            <fh-tagging [objectId]="trailer?.id" [objectType]="tagType">
            </fh-tagging>

        </div>
    </div>
</div>