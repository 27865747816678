
import { Injectable, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';

declare var L;
declare var HeatmapOverlay;
declare var PruneCluster;
declare var PruneClusterForLeaflet;

@Injectable()
export class MapService {

    setPruneCluster(leafletView) {

        const that = this;

        leafletView.BuildLeafletClusterIcon = function (cluster) {
            const e = new L.Icon.MarkerCluster();
            e.stats = cluster.stats;
            e.population = cluster.population;
            return e;
        };

        const colors = ['#ff4b00', '#bac900', '#EC1813', '#55BCBE', '#D2204C', '#FF0000', '#ada59a', '#3e647e'];
        const pi2 = Math.PI * 2;

        L.Icon.MarkerCluster = L.Icon.extend({
            options: {
                iconSize: new L.Point(44, 44),
                className: 'prunecluster leaflet-markercluster-icon'
            },
            createIcon: function () {
                // based on L.Icon.Canvas from shramov/leaflet-plugins (BSD licence)
                const e = document.createElement('canvas');
                this._setIconStyles(e, 'icon');
                const s = this.options.iconSize;
                if (L.Browser.retina) {
                    e.width = s.x + s.x;
                    e.height = s.y + s.y;
                } else {
                    e.width = s.x;
                    e.height = s.y;
                }
                // this.draw(e.getContext('2d'), s.x, s.y);
                this.draw(e.getContext('2d'), e.width, e.height);
                return e;
            },
            createShadow: function () {
                return null;
            },
            draw: function (canvas, width, height) {

                const lol = 0;

                let start = 0;
                for (let i = 0, l = colors.length; i < l; ++i) {

                    const size = this.stats[i] / this.population;


                    if (size > 0) {
                        canvas.beginPath();
                        canvas.moveTo(22, 22);
                        canvas.fillStyle = colors[i];
                        let from = start + 0.14,
                            // tslint:disable-next-line:prefer-const
                            to = start + size * pi2;

                        if (to < from) {
                            from = start;
                        }
                        canvas.arc(22, 22, 22, from, to);

                        start = start + size * pi2;
                        canvas.lineTo(22, 22);
                        canvas.fill();
                        canvas.closePath();
                    }
                }

                canvas.beginPath();
                canvas.fillStyle = 'white';
                canvas.arc(22, 22, 18, 0, Math.PI * 2);
                canvas.fill();
                canvas.closePath();

                canvas.fillStyle = '#555';
                canvas.textAlign = 'center';
                canvas.textBaseline = 'middle';
                canvas.font = 'bold 12px sans-serif';

                canvas.fillText(this.population, 22, 22, 40);
            }
        });

        leafletView.PrepareLeafletMarker = function (theMarker, data, category) {
            // parse data to icon
            theMarker.setIcon(that.createIcon());

            if (theMarker.getPopup()) {
                theMarker.setPopupContent(data.title + ' - ' + category);
            } else {
                theMarker.bindPopup(data.title + ' - ' + category);
            }
        };

        return true;
    }

    createIcon() {
        return L.icon({
            iconSize: [25, 41],
            iconAnchor: [13, 41],
            iconUrl: 'assets/marker-icon.png',
            shadowUrl: 'assets/marker-shadow.png'
        });
    }
}
