<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
        <ol class="breadcrumb">
            <li>
                <a href="/#/"><i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li class="active">
                <a href="/#/Schedule">{{'schedule.schedule' | translate }}</a>
            </li>
        </ol>
    </div>
</div>

<div class="wrapper wrapper-content">

    <div class="row">
        <div class="col-lg-12">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-calendar"></i> {{'schedule.schedule' | translate }}</h5>
                </div>
                <div class="ibox-content">
                    <ng-template #modalContent bsModal #autoShownModal="bs-modal" let-close="close">
                        <div class="modal-header">
                            <h4 class="modal-title pull-left">
                                <i class="fas fa-fw fa-calendar"></i> {{'schedule.schedule' | translate }}</h4>
                            <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div>
                                Action:
                                <pre>{{ modalData?.action }}</pre>
                            </div>
                            <div>
                                Event:
                                <pre>{{ modalData?.event | json }}</pre>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-outline-secondary" (click)="hideModal()">OK</button>
                        </div>
                    </ng-template>

                    <div class="">
                        <div class="col-md-4">
                            <div class="btn-group">
                                <div class="btn btn-primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="activeDayIsOpen = false">
                                    Previous
                                </div>
                                <div class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate">
                                    Today
                                </div>
                                <div class="btn btn-primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="activeDayIsOpen = false">
                                    Next
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 text-center">
                            <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
                        </div>
                        <div class="col-md-4 text-right">
                            <div class="btn-group">
                                <div class="btn btn-primary" (click)="view = 'month'" [class.active]="view === 'month'">
                                    Month
                                </div>
                                <div class="btn btn-primary" (click)="view = 'week'" [class.active]="view === 'week'">
                                    Week
                                </div>
                                <div class="btn btn-primary" (click)="view = 'day'" [class.active]="view === 'day'">
                                    Day
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div [ngSwitch]="view">
                        <mwl-calendar-month-view *ngSwitchCase="'month'" [viewDate]="viewDate" [events]="events" [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen"
                            (dayClicked)="dayClicked($event.day)" (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
                        </mwl-calendar-month-view>
                        <mwl-calendar-week-view *ngSwitchCase="'week'" [viewDate]="viewDate" [events]="events" [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)"
                            (eventTimesChanged)="eventTimesChanged($event)">
                        </mwl-calendar-week-view>
                        <mwl-calendar-day-view *ngSwitchCase="'day'" [viewDate]="viewDate" [events]="events" [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)"
                            (eventTimesChanged)="eventTimesChanged($event)">
                        </mwl-calendar-day-view>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>