import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fh-reporting',
  templateUrl: 'reporting.template.html'
})
export class ReportingViewComponent implements OnInit {

  public reports = [
    { 'name': 'Issue overview' },
    { 'name': 'Fleet health' },
    { 'name': 'Location count' },
    { 'name': 'Device replacements' },
    { 'name': 'Comparison report' }
  ];

  constructor() { }
  // SSRS https://github.com/tycomo/ngx-ssrs-reportviewer


  ngOnInit(): void {
  }
}
