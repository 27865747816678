import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { throwError } from 'rxjs/internal/observable/throwError';

import { AuthenticationService } from '../authentication/authentication.service';
import { LoggingService } from '../logging/logging.service';
import { TranslatorService } from '../common/translator.service';
import { InventorySensorType } from 'app/models/sensorType.model';

@Injectable()
export class SensorsTypesService {
  token: string;
  timestamp;
  url = '';

  constructor(private http: HttpClient, private loggingService: LoggingService, private translatorService: TranslatorService, private authenticationService: AuthenticationService) {
    this.url = authenticationService.getInventoryURL('sensorType');
    this.token = authenticationService.getAuthToken();
  }

  getSensorTypes(): Observable<InventorySensorType[]> {
    this.token = this.authenticationService.getAuthToken();
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

    console.log('getting sensorTypes from service');
    return this.http.get(this.url, { headers: headers })
      .map((data) => {
        const parsedResponse = this.parseResponse(data);
        return parsedResponse;
      })
      .catch(this.handleError);
  }

  getSensorTypeById(id: string): Observable<InventorySensorType> {

    this.token = this.authenticationService.getAuthToken();
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

    console.log('Fetch sensorType by id ' + id);
    return this.http.get(this.url + id, { headers: headers })
      .map((data) => {
        const parsedResponse = this.parseReponseDetails(data);
        return parsedResponse;
      })
      .catch(this.handleError);
  }

  saveSensorType(assetType: InventorySensorType): Observable<any> {
    console.log('save assetType');
    return this.http.post(this.url, assetType, { headers: this.authenticationService.headers })
      .catch(this.handleError);
  }


  updateSensorType(assetType: InventorySensorType): Observable<any> {
    console.log('save assetType');
    return this.http.put(this.url, assetType, { headers: this.authenticationService.headers })
      .catch(this.handleError);
  }

  private handleError(error: Response) {
    return throwError(error);
  }

  parseResponse(json: any): InventorySensorType[] {

    this.loggingService.log(this.constructor.name, 'Retrieved ' + json.length + ' sensortypes.');

    const ident = 1;
    const sensorTypes: InventorySensorType[] = [];

    // this.loggingService.log(json);
    json.forEach(item => {
      //   this.loggingService.log(this.constructor().name + " - New", item);
      const sensor = this.parseReponseDetails(item);
      sensorTypes.push(sensor);
    });

    this.timestamp = new Date().getTime();
    return sensorTypes;
  }

  parseReponseDetails(item): InventorySensorType {

    const sensorType = new InventorySensorType();
    sensorType.id = item.id;
    sensorType.model = item.model;
    sensorType.brand = item.brand;
    sensorType.deviceSensorTypeName = item.deviceSensorTypeName;
    sensorType.deviceSensorTypeId = item.deviceSensorTypeId;
    sensorType.calibrationPoints = item.calibrationPoints;
    sensorType.conversionFormula = item.conversionFormula;

    return sensorType;
  }
}
