import { NgModule } from '@angular/core';
import { WizardComponent } from '../../modules/wizard/wizard';
import { WizardStepComponent } from '../../modules/wizard/wizard-step';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule
    ],
    declarations: [
        WizardComponent,
        WizardStepComponent,
    ],
    exports: [
        WizardComponent,
        WizardStepComponent,
        TranslateModule
    ]
})
export class WizardModule {

}
