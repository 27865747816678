import { Component, OnInit, NgZone, ChangeDetectionStrategy } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { tileLayer, latLng, circle, polygon, Map, marker, icon, TileLayer } from 'leaflet';
import { Issue } from 'app/models/issue.model';
import { IssueService } from '../../services/issues/issues.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MapService } from '../../services/common/map.service';
import * as L from 'leaflet';
import '../../../../node_modules/leaflet-easybutton/src/easy-button.js';
import { getMapProviders, createMapOptions } from 'app/common/leafletGlobals';

declare var HeatmapOverlay;
declare var PruneCluster;
declare var PruneClusterForLeaflet;

@Component({
    selector: 'fh-issues-map',
    templateUrl: 'issuesMap.template.html',
    styleUrls: ['issuesMap.template.css'],
    providers: [MapService],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('slideInOut', [
            state('in', style({
                transform: 'translate3d(0, 0, 0)'
            })),
            state('out', style({
                transform: 'translate3d(calc(100% - 20px), 0, 0)'
            })),
            transition('in => out', animate('400ms ease-in-out')),
            transition('out => in', animate('400ms ease-in-out'))
        ]),
    ]
})
export class IssuesMapViewComponent implements OnInit {

    leafletView: any;
    selectedIssue: Issue;
    deviceId: string;
    loadingSidebar = false;
    googleMaps: TileLayer;
    googleHybrid: TileLayer;
    loading = false;
    markers: any;
    streets: any;
    osm: any;
    cities: any;
    map: Map;
    options;
    issues: Issue[];
    menuState = 'out';

    mapData = {
        data: []
    };

    heatmapLayer = new HeatmapOverlay({
        radius: 15,
        maxOpacity: 0.8,
        scaleRadius: false,
        useLocalExtrema: false,
        latField: 'lat',
        lngField: 'lng',
        valueField: 'count'
    });
    maps: { 'mapTiler': any; 'Google': any; 'Hybrid': any; };

    constructor(private theMapService: MapService, private zone: NgZone, private http: HttpClient, private issueService: IssueService) {
        this.initMap();
    }

    toggleMenu() {
        // 1-line if statement that toggles the value:
        this.menuState = this.menuState === 'out' ? 'in' : 'out';
    }

    selectId(issueId: string): any {
        this.loadingSidebar = true;
        const that = this;

        this.issueService.getIssueById(issueId).subscribe(
            issue => {
                this.selectedIssue = issue;
                that.loadingSidebar = false;
            }
        );
    }

    ngOnInit() {
    }

    // Leaflet
    initMap() {
        this.maps = getMapProviders(L);
          const initMaps = [];
        initMaps.push(Object.values(this.maps)[0]);
        this.options = createMapOptions(L, initMaps);

        const that = this;
        setTimeout(function () {
            that.setPruneCluster(); // Marker clluster version
            that.fillDevices();
        }, 100);
    }

    onMapReady(map: Map) {
        this.map = map;
        const that = this;

        const baseMaps = {
            'Osm': this.osm,
            'Google maps': this.googleMaps,
            'Google hybrid': this.googleHybrid
        };

        const overlayMaps = {
            'Prunecluster': this.leafletView,
            'Heatmap': this.heatmapLayer,
            // 'Markers': this.markers
        };

        this.map.on('click', () => {
            this.zone.run(() => {
                this.menuState = 'out';
            });
        });

        L.control.layers(baseMaps, overlayMaps, { position: 'topleft' }).addTo(map);

        // Easybuttona

        L.easyButton({
            id: 'fit map button',
            states: [{
                stateName: 'add-markers',
                icon: 'fa-expand-arrows-alt',
                title: 'Fit map',
                onClick: function (control) {
                    that.centerMap();
                }
            }]
        }).addTo(this.map);

        const heatmapToggle = L.easyButton({
            id: 'animated-heatmap-toggle',
            states: [{
                stateName: 'remove-markers',
                title: 'Remove heatmap',
                icon: 'fa-undo',
                onClick: function (control) {
                    that.map.removeLayer(that.heatmapLayer);
                    control.state('add-markers');
                }
            }, {
                stateName: 'add-markers',
                icon: 'fa-map',
                title: 'Show heatmap',
                onClick: function (control) {
                    that.map.addLayer(that.heatmapLayer);
                    control.state('remove-markers');
                }
            }]
        });
        heatmapToggle.addTo(this.map);

        const animatedToggle = L.easyButton({
            id: 'animated-marker-toggle',
            states: [{
                stateName: 'add-markers',
                icon: 'fa-map-marker',
                title: 'Show markers',
                onClick: function (control) {
                    that.map.addLayer(that.leafletView);
                    control.state('remove-markers');
                }
            }, {
                stateName: 'remove-markers',
                title: 'Remove markers',
                icon: 'fa-undo',
                onClick: function (control) {
                    that.map.removeLayer(that.leafletView);
                    control.state('add-markers');
                }
            }]
        });
        animatedToggle.addTo(this.map);

        this.loading = false;

        // End easybutton
    }

    createIcon() {
        return L.icon({
            iconSize: [25, 41],
            iconAnchor: [13, 41],
            iconUrl: 'assets/marker-icon.png',
            shadowUrl: 'assets/marker-shadow.png'
        });
    }

    setPruneCluster() {

        const that = this;
        this.theMapService.setPruneCluster(this.leafletView);

        this.leafletView.PrepareLeafletMarker = function (theMarker, data, category) {
            // parse data to icon
            theMarker.setIcon(that.createIcon());

            if (theMarker.getPopup()) {
                theMarker.setPopupContent(data.title + ' - ' + category);
            } else {
                theMarker.bindPopup(data.title + ' - ' + category);
            }

            theMarker.on('click', () => {
                that.zone.run(() => {
                    that.menuState = 'in';
                    that.selectId(data.issue.id);
                });

            });
        };
    }



    fillDevices() {
        const that = this;
        this.issueService.getIssuesWithLocation().subscribe(issues => {
            this.issues = issues;
            this.issues.forEach(issue => {
                if (issue.position && issue.position.latitude !== 0) {

                    // Prune cluster

                    const theMarker = new PruneCluster.Marker(issue.position.latitude, issue.position.longitude, { title: issue.id });

                    theMarker.category = Math.ceil(Math.random() * 6).toString();
                    theMarker.data.popup = issue.id;
                    theMarker.data.issue = issue;

                    this.leafletView.RegisterMarker(theMarker);

                    // Heatmap
                    this.mapData.data.push({
                        lat: issue.position.latitude,
                        lng: issue.position.longitude,
                        count: 1
                    });

                    // Normal marker
                    const theNormalMarker = marker([issue.position.latitude, issue.position.longitude], {
                        icon: icon({
                            iconSize: [25, 41],
                            iconAnchor: [13, 41],
                            iconUrl: 'assets/marker-icon.png',
                            shadowUrl: 'assets/marker-shadow.png'
                        })
                    }).bindPopup(issue.id).on('click', () => {
                        this.zone.run(() => {
                            this.menuState = 'in';
                            this.selectId(issue.id);
                        });
                    });

                    this.markers.addLayer(theNormalMarker);
                }
            });

            this.heatmapLayer.setData(this.mapData);
            this.loading = false;

            this.centerMap();

        });
    }

    centerMap() {
        const bounds = this.leafletView.Cluster.ComputeGlobalBounds();
        if (bounds) {
            this.map.fitBounds(new L.LatLngBounds(new L.LatLng(bounds.minLat, bounds.maxLng), new L.LatLng(bounds.maxLat, bounds.minLng)), { padding: [50, 50] });
        }
    }
}
