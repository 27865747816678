export class Driver {

    public id: string;
    public name: string;

    public firstName: string;
    public lastName: string;
    public email: string;

    public referenceId: any;
    public code: any;
    public phone: any;
    public dateOfBirth: Date = new Date();
    public licence: any;
    public assetDriver: AssetDriver;

    public accountPlatformId: string;

    public language: any;

    public properties = new Properties();
    public isActive = false;
}

export class Properties {
    public wasl = new WaslDriver();
}

export class WaslDriver {
    public referenceKey: string;
    public identityNumber: any;
    public dateOfBirthGregorian: any;
    public mobileNumber: any;
}



export class AssetDriver {
    public startDate: any;
    public endDate: any;
    public type: any;
    public id: any;
}

