import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch';

import { AuthenticationService } from '../authentication/authentication.service';
import { CalculationSettings, IssueTypeSettings } from '../../models/calculationsettings.model';
import { SettingsLevel } from '../../common/enums';
import { throwError } from 'rxjs/internal/observable/throwError';

@Injectable()
export class CalculationSettingsService {
    token: string;
    timestamp;
    url = '';

    constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
        this.url = authenticationService.getWebserviceURL('issuecalculatorsetting');
        this.token = authenticationService.getAuthToken();
    }

    getCalculationSettings(level: SettingsLevel, id: string): Observable<CalculationSettings> {

        this.token =  this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });
        let extra = '';
        switch (level) {
            case 5:
                extra = '?deviceId=' + id;
                break;
            case 3:
                extra = '?customerId=' + id;
                break;
            case 4:
                extra = '?deviceTypeId=' + id;
                break;
            case 2:
                extra = '?accountPlatformId=' + id;
                break;
            case 1:
                extra = '?accountId=' + id;
                break;
            default:
                break;
        }

        console.log('Fetch calculation settings by level ' + level + ' and id ' + id);
        return this.http.get(this.url + extra, { headers: headers })
            .map((data) => {
                const settings = new CalculationSettings;
                settings.id = id;
                settings.level = level;
                settings.issueTypeSettings = <IssueTypeSettings[]>data
                return settings;
            })
            .catch(this.handleError);
    }

    getAllCalculationSettings(): Observable<IssueTypeSettings[]> {
        this.token =  this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });
        const extra = 'All';

        console.log('Fetch all calculation settings ');
        return this.http.get(this.url + extra, { headers: headers })
            .map((data) => {
                return <IssueTypeSettings[]>data
            })
            .catch(this.handleError);
    }

    saveCalculationSettings(settings: CalculationSettings, id: string): Observable<Object> {

        const postBody = {};
        switch (settings.level) {
            case 1:
                if (id !== '-') {
                    console.log(id);
                    postBody['accountId'] = id;
                }
                break;
            case 2:
                postBody['accountPlatformId'] = id;
                break;
            case 4:
                postBody['deviceTypeId'] = id;
                break;
            case 3:
                postBody['customerId'] = id;
                break;
            case 5:
                postBody['deviceId'] = id;
                break;
            default:
                break;
        }
        postBody['issueSettings'] = settings.issueTypeSettings;
        const body = JSON.stringify(postBody);

        this.token =  this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });
        console.log('Post calculation settings by level ' + settings.level + ' and id ' + id + ' and active ');
        return this.http.post(this.url, body, { headers: headers })
            .map(result => {
                return result;
            }).catch(this.handleError);
    }

    deleteCalculationSettings(settings: CalculationSettings, id: string): Observable<Object> {
        const deleteBody = {};
        switch (settings.level) {
            case 1:
                if (id !== '-') {
                    console.log(id);
                    deleteBody['accountId'] = id;
                }
                break;
            case 2:
                deleteBody['accountPlatformId'] = id;
                break;
            case 4:
                deleteBody['deviceTypeId'] = id;
                break;
            case 3:
                deleteBody['customerId'] = id;
                break;
            case 5:
                deleteBody['deviceId'] = id;
                break;
            default:
                break;
        }
        deleteBody['issueSettings'] = settings.issueTypeSettings;
        const body = JSON.stringify(deleteBody);

        this.token =  this.authenticationService.getAuthToken();
        const header = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });
        const httpOptions = {
            headers: header, body: body
        };

        console.log('Delete calculation settings by level ' + settings.level + ' and id ' + id);
        return this.http.delete(this.url, httpOptions)
            .map(result => {
                return result;
            }).catch(this.handleError);
    }


    private handleError(error: Response) {
        return throwError(error);
    }
}
