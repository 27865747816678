<fh-error [error]="error" [success]="success"></fh-error>

<div class="ibox float-e-margins">
    <div class="ibox-title">
        <h5>
            <i class="fas fa-fw fa-file"></i> {{'general.accountPlatforms' | translate }}
        </h5>
    </div>
    <div class="ibox-content slick" style="padding:7px;position:relative; overflow: auto;">
        <ngx-loading [show]="loading"></ngx-loading>
        <table style="width:100%" class="table table-hover nowrap dtr-inline no-footer">
            <thead>
                <tr>
                    <th>
                        {{ 'general.type' | translate}}
                    </th>
                    <th>
                        {{ 'general.name' | translate}}
                    </th>
                    <th>
                        {{ 'general.userNameEmail' | translate}}
                    </th>
                    <th>
                        {{ 'general.password' | translate}}
                    </th>
                    <th>
                        {{ 'general.token' | translate}}
                    </th>
                    <th>
                        {{ 'general.url' | translate}}
                    </th>
                    <th style="width:120px">
                        &nbsp;
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let accountPlatform of accountPlatforms; let i = index">
                    <td>
                        {{ accountPlatform.platformName }}
                    </td>
                    <td>
                        {{ accountPlatform.name }}
                    </td>
                    <!-- <td>
                    {{accountPlatform.timezoneIana}}
                </td> -->
                    <td style="position: relative;
                height: 38px;
                min-height: 38px;" [tooltip]="accountPlatform.token">
                        <span style="position: absolute;
                    padding: 0rem 0.55rem;
                    left: 0;
                    right: 0;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;">{{accountPlatform.token}}</span>
                    </td>
                    <td>
                        {{accountPlatform.url}}
                    </td>
                    <td></td>
                    <td></td>
                    <td style="white-space: nowrap;">
                        <a class='btn btn-primary btn-grid' (click)="OpenEditAccountPlatform(accountPlatform)"><i
                                class='fas fa-fw fa-edit'></i></a>

                        <a class='btn btn-danger btn-grid' (click)="deleteAccountPlatform(accountPlatform.id)"><i
                                class='fas fa-fw fa-times'></i></a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="ibox-footer">
        <button class="btn btn-primary" (click)="formMode = 2" *ngIf="formMode == 1">
            <i class="fa fa-fw fa-plus"></i> {{'general.add' | translate }}</button>

        <button class="btn btn-danger" *ngIf="formMode != 1 && insertOnly == false" (click)="formMode = 1">
            <i class="fa fa-fw fa-times"></i> {{'general.cancel' | translate }}</button>
    </div>
</div>

<!-- Adding accountplatform -->
<div class="ibox float-e-margins" *ngIf="formMode == 2">
    <div class="ibox-title">
        <h5>
            <i class="fas fa-fw fa-file"></i> {{'general.accountPlatforms' | translate }}
        </h5>
    </div>
    <div class="ibox-content slick" style="position:relative;  overflow: auto;">
        <ngx-loading [show]="testing"></ngx-loading>
        <table style="width:100%" class="table table-hover nowrap dtr-inline no-footer">
            <thead>
                <tr>
                    <th>
                        {{ 'general.type' | translate}}
                    </th>
                    <th>
                        {{ 'general.name' | translate}}
                    </th>
                    <th>
                        {{ 'general.userNameEmail' | translate}}
                    </th>
                    <th>
                        {{ 'general.password' | translate}}
                    </th>
                    <th>
                        {{ 'general.token' | translate}}
                    </th>
                    <th>
                        {{ 'general.url' | translate}}
                    </th>
                    <th>
                        &nbsp;
                    </th>
                    <th>
                        &nbsp;
                    </th>
                    <th>
                        &nbsp;
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let accountPlatform of accountPlatformRequest; index as i">
                    <td>
                        <select (ngModelChange)="setPlatformUrl($event, i)" name="accountPlatform[{{i}}].platformType"
                            #platformType="ngModel" [(ngModel)]="accountPlatform.platformType" style="width:100%;"
                            id="owner" class="form-control table-inline">
                            <option [ngValue]="undefined" disabled>
                                {{'general.selectAccountPlatforms' | translate }}</option>
                            <option *ngFor="let platforms of platforms | orderBy:'name'" [ngValue]="platforms.id">
                                {{ platforms.name }}</option>
                        </select>
                    </td>
                    <td>
                        <span>
                            <input type="text" name="accountPlatform[{{i}}].platformName"
                                [disabled]="(!accountPlatform.platformType) || accountPlatform.platformType === 6"
                                [(ngModel)]="accountPlatform.platformName" class="form-control table-inline"></span>
                    </td>
                    <td>
                        <span>
                            <input type="text" name="accountPlatform[{{i}}].username"
                                [disabled]="accountPlatform.platformType !== 2" [(ngModel)]="accountPlatform.username"
                                class="form-control table-inline"></span>
                    </td>
                    <td>
                        <span>
                            <input type="text" name="accountPlatform[{{i}}].password"
                                [disabled]="accountPlatform.platformType !== 2" [(ngModel)]="accountPlatform.password"
                                class="form-control table-inline"></span>
                    </td>
                    <td>
                        <span>
                            <input type="text" name="accountPlatform[{{i}}].token"
                                [disabled]="(!accountPlatform.platformType) || accountPlatform.platformType === 6"
                                [(ngModel)]="accountPlatform.token" class="form-control table-inline"></span>
                    </td>
                    <td>
                        <span>
                            <input type="text" name="accountPlatform[{{i}}].url"
                                [disabled]="(!accountPlatform.platformType) || accountPlatform.platformType === 6"
                                [placeholder]="placeholderUrls(accountPlatform.platformType)"
                                [(ngModel)]="accountPlatform.url" class="form-control table-inline"></span>
                    </td>
                    <td></td>
                    <td></td>
                    <td>
                        <span style="display: inline-flex; align-items: center;">
                            <i (click)="removeAccountPlatform(i)" container="body" placement="auto"
                                tooltip="Remove platform" style="color: red; vertical-align: middle;"
                                class="fas fa-fw fa-times fa-lg"></i>

                            <ng-container *ngIf="isValidated(accountPlatform) as resultString; else checkPlatform">
                                <ng-container *ngIf="resultString == platformValidation.Valid; else invalidPlatform"
                                    tooltip="Tokens are validated">
                                    <i style="color: seagreen; vertical-align: middle;"
                                        class="fas fa-fw fa-check-circle fa-lg"></i>&nbsp;
                                </ng-container>
                                <ng-template #invalidPlatform>
                                    <i container="body" placement="auto" tooltip="Validation of Token and/or Url failed"
                                        style="color: indianred; vertical-align: middle;"
                                        class="fas fa-fw fa-exclamation-circle fa-lg"></i>&nbsp;
                                </ng-template>
                            </ng-container>
                            <ng-template #checkPlatform>
                                <i container="body" placement="auto" tooltip="Please validate token first"
                                    style="color: orange; vertical-align: middle;"
                                    class="fas fa-fw fa-exclamation-triangle fa-lg"></i>&nbsp;
                            </ng-template>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="ibox-footer">
        <button class="btn btn-primary" *ngIf="formMode == 2" (click)="addAccountPlatform()">
            <i class="fa fa-fw fa-plus"></i> {{'general.addAccountPlatform' | translate }}</button>

        <button class="btn btn-primary" *ngIf="formMode == 2" (click)="testTokens()"
            [disabled]="!checkTokenValidation(accountPlatformRequest)">
            <i class="fa fa-fw fa-bolt"></i> {{'general.testTokens' | translate }}</button>

        <button [disabled]="!anyValidated()" *ngIf="formMode == 2 && insertOnly == false" class="btn btn-primary"
            (click)="createAccountPlatforms()">
            <i class="fa fa-fw fa-save"></i> {{'general.save' | translate }}</button>

        <div style="clear:both"></div>
    </div>
</div>

<!-- Delete modal -->
<fh-delete-modal #deleteModal (onDelete)="onAccountPlatformDelete($event)"></fh-delete-modal>

<!-- Edit modal -->
<div *ngIf="isEditModalShown" [config]="{ show: true }" (onHidden)="hideEditModal()" bsModal #editModal="bs-modal"
    class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left"><i class="fa fa-file"></i>
                    {{'general.editAccountPlatform' | translate }}
                </h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="hideEditModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <table style="width:100%" class="table table-hover nowrap dtr-inline no-footer">
                    <ngx-loading [show]="testingEdit"></ngx-loading>
                    <tbody>
                        <tr>
                            <td> {{ 'general.name' | translate}}</td>
                            <td>
                                <span>
                                    <input type="text" name="editAccountPlatformPlatformName"
                                        [disabled]="editAccountPlatform.platformType === 6"
                                        [(ngModel)]="editAccountPlatform.name"
                                        class="form-control table-inline"></span>
                            </td>
                        </tr>
                        <tr>
                            <td> {{ 'general.userNameEmail' | translate}}</td>
                            <td>
                                <span>
                                    <input type="text" name="editAccountPlatform.username"
                                        [disabled]="editAccountPlatform.platformType !== 2"
                                        [(ngModel)]="editAccountPlatform.username"
                                        class="form-control table-inline"></span>
                            </td>
                        </tr>
                        <tr>
                            <td> {{ 'general.password' | translate}}</td>
                            <td>
                                <span>
                                    <input type="text" name="editAccountPlatformPassword"
                                        [disabled]="editAccountPlatform.platformType !== 2"
                                        [(ngModel)]="editAccountPlatform.password"
                                        class="form-control table-inline"></span>
                            </td>
                        </tr>
                        <tr>
                            <td> {{ 'general.token' | translate}}</td>
                            <td>
                                <span>
                                    <input type="text" name="editAccountPlatformTtoken"
                                        [disabled]=" editAccountPlatform.platformType === 6"
                                        [(ngModel)]="editAccountPlatform.token"
                                        class="form-control table-inline"></span>
                            </td>
                        </tr>
                        <tr>
                            <td> {{ 'general.url' | translate}}</td>
                            <td>
                                <span>
                                    <input type="text" name="editAccountPlatform.url"
                                        [disabled]="editAccountPlatform.platformType === 6"
                                        [placeholder]="placeholderUrls(editAccountPlatform.platformType)"
                                        [(ngModel)]="editAccountPlatform.url" class="form-control table-inline"></span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary" (click)="testEditToken(editAccountPlatform)"> <i class="fa fa-fw fa-bolt"></i>
                    {{'general.testToken' | translate }}</button>

                <button [disabled]="!(isValidated(editAccountPlatform) == true)" class="btn btn-primary" (click)="updateAccountPlatform(editAccountPlatform)"> <i
                        class="fa fa-fw fa-save"></i> {{'general.save' | translate }}</button>

                <button type="button" class="btn btn-danger" (click)="declineEdit()"><i class="fa fa-times"></i> {{'general.cancel' | translate
                        }}</button>
            </div>
        </div>
    </div>
</div>