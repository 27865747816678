import { Component, ViewChild, Output, OnChanges, Input } from '@angular/core';
import { BsModalRef, ModalDirective } from 'ngx-bootstrap';
import { EventEmitter } from '@angular/core';


@Component({
    selector: 'fh-delete-modal',
    templateUrl: 'deleteModal.template.html',
})
export class DeleteModalComponent implements OnChanges {

    @Output() onDelete = new EventEmitter();
    @Input() message = 'general.warningDelete';

    @ViewChild('autoShownModal') autoShownModal: ModalDirective;
    modalRef: BsModalRef;
    isModalShown = false;

    activeId;

    constructor() {
    }

    ngOnChanges(): void {
    }

    // Model
    public showModal(id): void {
        this.activeId = id;
        this.isModalShown = true;
    }

    public hideModal(): void {
        this.autoShownModal.hide();
    }

    delete() {
        this.onDelete.emit(this.activeId);
    }

    decline(): void {
        this.autoShownModal.hide();
    }

    onHidden(): void {
        this.isModalShown = false;
    }
}
