<div class="row">
  <nav class="navbar navbar-static-top" role="navigation" style="margin-bottom: 0">
    <div class="navbar-header">
      <a class="minimalize-styl-2 btn" (click)="toggleNavigation()">
        <i class="fa fa-bars"></i>
      </a>


      <!-- <input type="text" (blur)="searchTerm$.next('')" (keyup)="searchTerm$.next($event.target.value)" class="form-control" style="width:100%; height:40px;"
            placeholder="{{'topnav.searchforsomething' | translate }}" name="q"> -->
      <form class="navbar-form-custom pull-right" role="search" style="padding:10px;">
        <div class="input-group seach-background" style="width:300px;">
          <input [typeahead]="autoComplete$" [(ngModel)]="asyncSelected" [ngModelOptions]="{standalone: true}"
            (typeaheadLoading)="changeTypeaheadLoading($event)" (typeaheadOnSelect)="typeaheadOnSelect($event)"
            typeaheadWaitMs="1000" typeaheadGroupField="groupname" [typeaheadOptionsLimit]="7"
            typeaheadOptionField="name" placeholder="{{'topnav.searchforsomething' | translate }}" class="form-control">

          <div class="input-group-btn">
            <button class="btn btn-default" style="border: none; background: none; color:#fff;" type="submit">
              <span *ngIf="typeaheadLoading">
                <i class="fa fa-spinner fa-spin fa-hourglass-start"></i>
              </span>
              <span *ngIf="!typeaheadLoading">
                <i class="fa fa-search"></i>
              </span>
            </button>
          </div>
        </div>
      </form>
    </div>

    <ul class="nav navbar-top-links navbar-right">
      <li>
        <a target="_blank" (click)="goToOverview()">
          <i class="fas fa-fw fa-globe" title="Go to Fleet Overview"></i></a>
      </li>
      <!-- <li>
          <fh-lang-selector></fh-lang-selector>
      </li> -->
      <li dropdown>
        <a aria-expanded="false" role="button" dropdownToggle>
          <i class="fa fa-question"></i>
          <span class="caret"></span>
        </a>
        <ul role="menu" *dropdownMenu class="dropdown-menu">
          <!-- <li>
            <a href="/#/Help">
              <i class="fas fa-fw fa-question"></i> {{'general.help' | translate }}</a>
          </li> -->
          <li>
            <a href="https://360locate.com/docs/fleet-health/getting-started/" target="_blank">
              <i class="fas fa-fw fa-question"></i> {{'general.help' | translate }}</a>
          </li>
          <li>
            <a href="/#/About">
              <i class="fas fa-fw fa-info"></i> {{'general.about' | translate }}</a>
          </li>
        </ul>
      </li>
      <li dropdown>
        <a aria-expanded="false" role="button" dropdownToggle>
          <ng-container *ngIf="isImpersonated; else normalUser">
              <i class="hidden-md hidden-lg hidden-xl fa fa-user-secret"></i>
              <span class="hidden-sm hidden-xs" style="color:rgba(255, 177, 177, 1)">
                <i class="fa fa-user-secret"></i> {{id}}
              </span>
          </ng-container>
          <ng-template #normalUser>
              <i class="hidden-md hidden-lg hidden-xl fa fa-user"></i>
              <span class="hidden-sm hidden-xs">
                <i class="fa fa-user"></i> {{id}}
              </span>
          </ng-template>
          <span class="caret"></span>
        </a>
        <ul role="menu" *dropdownMenu class="dropdown-menu">
          <li *ngIf="isImpersonated" style="background-color:rgba(146, 0, 0, 0.05)">
            <a (click)="stopImpersonation()">
              <i class="fas fa-fw fa-user-secret"></i> {{'general.stopImpersonation' | translate }}</a>
          </li>
          <li>
            <a href="/#/Settings/Profile">
              <i class="fas fa-fw fa-user"></i> {{'general.profile' | translate }}</a>
          </li>
          <li>
            <a href="#" (click)="logout()">
              <i class="fas fa-fw fa-sign-out-alt"></i> {{'general.logout' | translate }}</a>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</div>