import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { throwError } from 'rxjs/internal/observable/throwError';

import * as moment from 'moment';

import { AuthenticationService } from '../authentication/authentication.service';
import { Dashboard, LocationCount, AdminDashboard } from '../../models/dashboard.model';
import { DashboardNotification } from '../../models/notification.model';
import { Location } from '../../models/location.model';
import { LoggingService } from '../logging/logging.service';
import { TranslatorService } from '../common/translator.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class DashboardService {
    token: string;
    timestamp;
    url = '';

    constructor(private translate: TranslateService, private translatorService: TranslatorService, private http: HttpClient, private loggingService: LoggingService, private authenticationService: AuthenticationService) {
        this.url = authenticationService.getWebserviceURL('dashboard');
        this.token = authenticationService.getAuthToken();
    }

    getNotification(): Observable<DashboardNotification[]> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting notification from service');
        return this.http.get(this.authenticationService.getWebserviceURL('account') + 'Notification', { headers: headers })
            .map((data) => {
                if (data) {
                    const parsedResponse = this.parseNotifications(data);
                    return parsedResponse;
                }
            })
            .catch(this.handleError);
    }

    parseNotifications(json: any): DashboardNotification[] {

        this.loggingService.log(this.constructor.name, 'Retrieved ' + json.length + ' notifications.');

        const notifications: DashboardNotification[] = [];
        json.forEach(item => {
            const notification = this.parseNotificationResponse(item);
            notifications.push(notification);
        });
        return notifications;
    }

    getDashboard(start, end, accountPlatform?, customerId?): Observable<Dashboard> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        const body = {
            accountPlatformIds: accountPlatform,
            customerIds: customerId,
        }

        console.log('getting dashboard from service from ' + start + ' to ' + end);
        return this.http.post(this.url
            + '?start=' + Math.round(start / 1000)
            + '&end=' + Math.round(end / 1000),
            JSON.stringify(body),
            { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getAdminDashboard(): Observable<AdminDashboard> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting admin dashboard from service');
        return this.http.get(this.url + 'Administrator', { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseAdminResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getDashboardIssuesPerIssueType(start, end, accountPlatform?, customerId?): Observable<any> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        const body = {
            accountPlatformIds: accountPlatform,
            customerIds: customerId,
        }

        console.log('getting dashboard from service per issuetype');
        return this.http.post(this.url + 'IssuesPerIssueType'
            + '?start=' + Math.round(start / 1000)
            + '&end=' + Math.round(end / 1000),
            JSON.stringify(body),
            { headers: headers })
            .map((data: any) => {
                return Object.keys(data).map(k => [this.translatorService.translate(k, 'enums.issuetypes'), data[k]]);
            })
            .catch(this.handleError);
    }


    getDashboardIssuesPerCustomer(start, end): Observable<any> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting dashboard from service per customer');
        return this.http.get(this.url + 'IssuesPerCustomer?start=' + Math.round(start / 1000) + '&end=' + Math.round(end / 1000), { headers: headers })
            .map((data: any) => {
                return Object.keys(data).map(k => [k, data[k]]);
            })
            .catch(this.handleError);
    }

    getDashboardIssuesPerDeviceType(start, end, accountPlatform?, customerId?): Observable<any> {
        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        const body = {
            accountPlatformIds: accountPlatform,
            customerIds: customerId,
        }

        console.log('getting dashboard from service per devicetype');
        return this.http.post(this.url + 'IssuesPerDeviceType'
            + '?start=' + Math.round(start / 1000)
            + '&end=' + Math.round(end / 1000),
            JSON.stringify(body),
            { headers: headers })
            .map((data: any) => {
                return Object.keys(data).map(k => [k, data[k]]);
            })
            .catch(this.handleError);
    }

    getDashboardMessageCount(start, end, accountPlatform?, customerId?): Observable<any> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        const body = {
            accountPlatformIds: accountPlatform,
            customerIds: customerId,
        }

        console.log('getting dashboard locationCount from service per devicetype from ' + start + ' to ' + end);
        return this.http.post(this.url + 'GetMessageCountByDay'
            + '?start=' + Math.round(start / 1000)
            + '&end=' + Math.round(end / 1000),
            JSON.stringify(body),
            { headers: headers })
            .map((data: any) => {
                const returnobject = Object.keys(data).map(k => [(parseFloat(k) * 1000), data[k].item1, data[k].item2]);
                return returnobject;
            })
            .catch(this.handleError);
    }

    getDashboardIssueCount(start, end, accountPlatform?, customerId?): Observable<any> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        const body = {
            accountPlatformIds: accountPlatform,
            customerIds: customerId,
        }

        console.log('getting dashboard locationCount from service per devicetype from ' + start + ' to ' + end);
        return this.http.post(this.url + 'GetIssueCountByDay'
            + '?start=' + Math.round(start / 1000)
            + '&end=' + Math.round(end / 1000),
            JSON.stringify(body),
            { headers: headers })
            .map((data: any) => {
                return Object.keys(data).map(k => [(parseFloat(k) * 1000), data[k].issueCount]);
            })
            .catch(this.handleError);
    }


    private handleError(error: Response) {
        return throwError(error);
    }


    parseAdminResponse(json: any): AdminDashboard {

        this.loggingService.log(this.constructor.name, 'Retrieved ' + json.length + ' dashboard.');

        const dashboard = new AdminDashboard();

        dashboard.issueCount = json.issueCount;
        dashboard.accountsWithoutIssues = json.accountsWithoutIssues;
        dashboard.activeAccounts = json.activeAccounts;
        dashboard.activeJobCount = json.activeJobCount;
        dashboard.activeUsers = json.activeUsers;
        dashboard.historyJobCount = json.historyJobCount;

        return dashboard;
    }

    parseResponse(json: any): Dashboard {

        this.loggingService.log(this.constructor.name, 'Retrieved ' + json.length + ' dashboard.');

        const dashboard = new Dashboard();
        dashboard.deviceCount = json.deviceCount;
        dashboard.allDeviceCount = json.allDeviceCount;
        dashboard.assetCount = json.assetCount;
        dashboard.trailerCount = json.trailerCount;
        dashboard.issueCount = json.issueCount;
        dashboard.deviceWithIssues = json.deviceWithIssuesCount;
        dashboard.deviceWithIssuesMax = json.deviceWithIssuesCountMax;
        dashboard.deviceTypesCount = json.deviceTypesCount;

        dashboard.assetTypeCount = json.assetTypeCount;
        dashboard.assetTypesUsedCount = json.assetTypesUsedCount;
        dashboard.driverCount = json.driverCount;

        dashboard.deviceCountLastPeriod = json.deviceCountLastPeriod;
        dashboard.issueCountLastPeriod = json.issueCountLastPeriod;
        dashboard.deviceWithIssuesLastPeriod = json.deviceWithIssuesCountLastPeriod;
        dashboard.deviceTypesCountLastPeriod = json.deviceTypesCountLastPeriod;

        dashboard.worstDevices = json.worstDevices;

        dashboard.healthyCount = 100 - Math.round(json.deviceWithIssuesCount > 0 ? (json.deviceWithIssuesCount / json.deviceCount) * 100 : 0);
        dashboard.healthyCountLastPeriod = 100 - Math.round(json.deviceWithIssuesCountLastPeriod > 0 ? (json.deviceWithIssuesCountLastPeriod / json.deviceCountLastPeriod) * 100 : 0);
        this.timestamp = new Date().getTime();
        return dashboard;
    }

    parseNotificationResponse(json: any): DashboardNotification {

        const notification = new DashboardNotification();
        notification.message = json.message;
        notification.code = json.code;
        notification.type = json.type;
        notification.data1 = json.data1;
        notification.data2 = json.data2;

        return notification;
    }
}
