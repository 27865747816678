<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/AssetTypes/Overview">{{'general.assetType' | translate }}</a>
            </li>
            <li>
                <a href="/#/AssetTypes/Index/{{assetType?.id}}">{{assetType?.name}}</a>
            </li>
            <li class="active">
                <strong>{{'general.assetTypeDetails' | translate }}</strong>
            </li>
        </ol>
    </div>
</div>


<form #userForm="ngForm" #formDir="ngForm">
    <div class="wrapper wrapper-content">
        <div class="row" data-equalizer="">
            <div class="col-lg-6">
                <fh-error [error]="error" [success]="success"></fh-error>


                <div class="ibox float-e-margins" style="position:relative">
                    <ngx-loading [show]="loadingAssetType"></ngx-loading>
                    <div class="ibox-title">
                        <h5>
                            <i class="fa fa-object-group"></i> {{'general.assetType' | translate }}
                        </h5>
                    </div>
                    <fh-asset-type-details [assetType]="assetType" [formMode]="formMode"></fh-asset-type-details>
                    <fh-save-footer [showButtons]="assetType.isEditable" [showButtonsNotification]="notEditableNotification" [valid]="!userForm.invalid"
                        (onSave)="onSave()" (onDelete)="onDelete()" (setFormMode)="setFormMode($event)"
                        [formMode]="formMode">
                    </fh-save-footer>
                </div>

                <fh-tagging [objectId]="assetType?.id" [objectType]="tagType">
                </fh-tagging>
            </div>
            <div class="col-lg-6">
                <fh-asset-type-property-editor [formMode]="formMode" [assetTypeGroups]="assetTypeGroups">
                </fh-asset-type-property-editor>
            </div>
        </div>
    </div>
</form>