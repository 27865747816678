import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DeviceService } from '../../services/device/device.service';
import { TagObjectType } from 'app/models/tag.model';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { AssetTypeService } from 'app/services/asset/assetTypes.service';
import { FormMode } from 'app/common/enums';
import { AssetService } from 'app/services/asset/asset.service';
import { AssetSensor } from 'app/models/sensor.model';
import { SensorsTypesService } from 'app/services/sensors/sensorTypes.service';
import { InventorySensorType } from 'app/models/sensorType.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { DeleteModalComponent } from '../shared/usercontrols/deleteModal.component';
import { DeviceSensorsService } from 'app/services/sensors/deviceSensors.service';
import { SensorService } from 'app/services/sensors/sensors.service';

declare var L;

@Component({
    selector: 'fh-asset-sensors',
    templateUrl: 'sensors.template.html'
})
export class AssetCalibrationViewComponent implements OnInit {
    @ViewChild('deleteModal') deleteModal: DeleteModalComponent;

    config = {
        backdrop: true,
        ignoreBackdropClick: true
    };

    modalRef: BsModalRef;
    sub: any;
    asset: any;

    formMode = FormMode.add;
    formModeCalibration = FormMode.read;

    loadingCalculation = false;
    loadingAsset = false;
    error: any;

    options;
    marker;

    permissions: {};

    testValue = 0;
    loadingTest = false;
    errorTest;
    successTest;
    calibrationPoints = [];

    success;

    sensorsList = [];
    loading = false;
    sensorTypes: InventorySensorType[];

    sensorTypesWeight: InventorySensorType[];
    sensorTypesTemperature: InventorySensorType[];
    sensorTypesHumidity: InventorySensorType[];

    weightSensorTypeId;
    weightSensorPositionId;
    sensorValues: any;
    allowEdit: boolean;
    editSensor: any;

    constructor(private modalService: BsModalService, private authentication: AuthenticationService, private assetService: AssetService, private sensorService: SensorService, private sensorTypesService: SensorsTypesService, private route: ActivatedRoute) {
        this.loading = true;

    }

    ngOnInit() {
        this.permissions = this.authentication.permissions;

        this.loadingCalculation = true;
        this.loadingAsset = true;

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            const assetFromService = this.assetService.getAssetById(id).subscribe(asset => {
                this.asset = asset;
                this.loadingCalculation = false;
                this.loadingAsset = false;

                this.sensorTypesService.getSensorTypes().subscribe(resp => {
                    this.sensorTypes = resp;

                    // this.sensorTypesWeight = this.sensorTypes.filter(x => x.deviceSensorTypeId === 18);
                    // this.sensorTypesTemperature = this.sensorTypes.filter(x => x.deviceSensorTypeId === 15);
                    // this.sensorTypesHumidity = this.sensorTypes.filter(x => x.deviceSensorTypeId === 27);

                    this.loading = false;

                    this.getSensors();
                });
            });
        });
    }

    getSensors() {
        this.loading = true;
        this.sensorService.getSensorsOnAsset(this.asset.id, this.sensorTypes).subscribe(resut => {
            this.loading = false;
            this.sensorsList = resut;
        }, error => {
            this.error = error;
        });
    }

    getAssetInfo() {
        this.assetService.getAssetById(this.asset.id).subscribe(asset => {
            this.asset = asset;
            this.loadingCalculation = false;
            this.loadingAsset = false;
        });
    }

    showDeleteModal(sensor) {
        this.deleteModal.showModal(sensor);
    }

    deleteSensor(sensor) {
        this.deleteModal.hideModal();
        this.loading = true;
        this.sensorService.deleteSensor(sensor).subscribe(result => {
            this.getSensors();
        }, error => {
            this.error = error;
            this.getSensors();
        });
    }

    onSave(sensor) {
        this.sensorService.saveSensor(sensor).subscribe(result => {
            this.getSensors();
        }, error => {
            this.error = error;
            this.getSensors();
        });
    }

    addSensor(sensorType, sensorPosition) {
        const sensor = new AssetSensor();

        sensor.assetId = this.asset.id;
        sensor.calibrationPoints = [];
        sensor.sensorTypeId = sensorType.value;
        sensor.sensorType = this.sensorTypes.find(x => x.id === sensor.sensorTypeId);
        sensor.sensorPosition = sensorPosition.value;

        this.loading = true;
        this.sensorService.addSensor(sensor).subscribe(result => {
            this.getSensors();
        }, error => {
            this.error = error;
            this.getSensors();
        });
    }

    openModal(allowEdit = false, template: TemplateRef<any>, sensor) {

        this.editSensor = sensor;

        // When overwriting a new calibration... copy values from current sensor calibration
        if (allowEdit && (sensor.calibrationPoints == null || sensor.calibrationPoints.length === 0)) {
            console.log('setting overwrite calibrationpoints from original sensor ', sensor.sensorType.calibrationPoints);

            sensor.sensorType.calibrationPoints.forEach(x => {
                this.editSensor.calibrationPoints.push(x)
            });
            this.success = 'Sensor values are copied from original calibration';
        } else {
            this.success = '';
        }

        // Set edit and form mode
        this.allowEdit = allowEdit;

        if (allowEdit === false) {
            this.formModeCalibration = FormMode.read;
            this.sensorValues = sensor.sensorType.calibrationPoints;
        } else {
            this.formModeCalibration = FormMode.edit;
            this.sensorValues = sensor.calibrationPoints;
        }

        this.modalRef = this.modalService.show(template, this.config);
    }

    setFormModeCalibration(mode) {
        this.formModeCalibration = mode;

        if (this.formModeCalibration === FormMode.read) {

        }
    }
}
