<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
        <ol class="breadcrumb">
            <li>
                <a href="/#/"><i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="">{{'general.settings' | translate }}</a>
            </li>
            <li class="active">
                <strong>{{'menu.settingstheme' | translate}}</strong>
            </li>
        </ol>
    </div>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="">
        <div class="col-lg-6">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-satellite-dish"></i>{{'menu.settingstheme' | translate}}
                    </h5>
                </div>
                <div class="ibox-content">
                    <div class="form-group">
                        <div class="col-md-4">Select theme</div>
                        <div class="col-md-8">
                            <select class="form-control">
                                <option>Custom</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-md-4" for="facebook ">Primary color</div>
                        <div class="col-md-8 ">
                            <input [(colorPicker)]="color1" [style.background]="color1" class="form-control input-md" style="width:100%" />
                        </div>
                    </div>
                    <div class="form-group ">
                        <div class="col-md-4" for="twitter ">Secondary color</div>
                        <div class="col-md-8 ">
                            <input [(colorPicker)]="color2" [style.background]="color2" class="form-control input-md" style="width:100%" />
                        </div>
                    </div>
                    <div class="form-group ">
                        <div class="col-md-4" for="linkedin ">Logo</div>
                        <div class="col-md-8 ">
                            <input id="logo1 " name="logo1 " type="text " placeholder=" " class="form-control input-md" required=" " style="width:100%"
                            />
                        </div>
                    </div>
                    <div class="form-group ">
                        <div class="col-md-4" for="pinterest ">Chart color 1</div>
                        <div class="col-md-8 ">
                            <input [(colorPicker)]="chColor1" [style.background]="chColor1" class="form-control input-md" style="width:100%" />
                        </div>
                    </div>
                    <div class="form-group ">
                        <div class="col-md-4" for="instagram ">Chart color 2</div>
                        <div class="col-md-8 ">
                            <input [(colorPicker)]="chColor2" [style.background]="chColor2" class="form-control input-md" style="width:100%" />
                        </div>
                    </div>
                    <div class="form-group ">
                        <div class="col-md-4" for="instagram ">Chart color 3</div>
                        <div class="col-md-8 ">
                            <input [(colorPicker)]="chColor3" [style.background]="chColor3" class="form-control input-md" style="width:100%" />
                        </div>
                    </div>
                    <div class="form-group ">
                        <div class="col-md-4" for="instagram ">Text color</div>
                        <div class="col-md-8 ">
                            <input [(colorPicker)]="textColor" [style.background]="textColor" class="form-control input-md" style="width:100%" />
                        </div>
                    </div>
                    <!-- Button -->
                    <button id="submit " name="submit " class="btn btn-primary ">
                        <i class="fas fa-fw fa-save "></i> Save</button>
                    <div style="clear:both "></div>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-satellite-dish"></i> Preview
                    </h5>
                </div>
                <div class="ibox-content slick" style="height:362px">
                    <table style="width:100%; height:100%;">
                        <tr>
                            <td colspan="2" style="text-align: center; padding:20px; color:#fff" [ngStyle]="{'backgroundColor': color1 }">Header</td>
                        </tr>
                        <tr>
                            <td style="width:100px; padding:20px; color:#fff" [ngStyle]="{'backgroundColor': color1 }">Sidebar</td>
                            <td style="width:100%;height:100%; padding:20px" [ngStyle]="{'color': textColor }">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam iaculis quam ac vehicula tristique.
                                Vestibulum molestie velit et magna viverra hendrerit. Maecenas a magna dapibus purus dapibus
                                lacinia at eu nulla. Phasellus eu sodales odio.

                                <br/>
                                <br/>

                                <div class="button" style="float:left; width:100px; padding:10px; color:#fff;margin-right:10px" [ngStyle]="{'backgroundColor': color2 }">Color 1</div>
                                <div class="button" style="float:left;width:100px; padding:10px; color:#fff" [ngStyle]="{'backgroundColor': color1 }">Color 2</div>

                                <br/>
                                <br/>
                                <br/>

                                <div class="button" style="float:left; width:100px; padding:10px; color:#fff;margin-right:10px" [ngStyle]="{'backgroundColor': chColor1 }">Chart 1</div>
                                <div class="button" style="float:left; width:100px; padding:10px; color:#fff;margin-right:10px" [ngStyle]="{'backgroundColor': chColor2 }">Chart 2</div>
                                <div class="button" style="float:left;width:100px; padding:10px; color:#fff" [ngStyle]="{'backgroundColor': chColor3 }">Chart 3</div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>