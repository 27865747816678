import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';
import { LocationStrategy, HashLocationStrategy, PathLocationStrategy, APP_BASE_HREF } from '@angular/common';
import { AuthGuard } from './auth-guard';
import { AuthenticationService } from './services/authentication/authentication.service';
import { AuditLogService } from './services/auditlog/auditlog.service';
import { UserService } from './services/users/user.service';
import { DeviceService } from './services/device/device.service';
import { DashboardService } from './services/dashboard/dashboard.service';
import { ResolutionTypeService } from './services/resolutiontypes/resolutiontypes.service';
import { DeviceTypeService } from './services/devicetypes/devicetypes.service';
import { IssueTypeService } from './services/issuetypes/issuetypes.service';
import { IssueService } from './services/issues/issues.service';
import { ScheduleService } from './services/schedule/schedule.service';
import { LoggingService } from './services/logging/logging.service';
import { ErrorLogService } from './services/logging/error-log.service';
import { CalculationSettingsService } from './services/calculationsettings/calculationsettings.service';
import { ROUTES } from './app.routes';
import { AppComponent } from './app.component';

// App views
import { AppviewsModule } from './views/appviews.module';

// App modules/components
import { SharedLayoutsModule } from './views/shared/sharedlayouts.module';

import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';

import { CookieService } from 'ngx-cookie-service';
import { LoggingErrorHandlerProviders, LoggingErrorHandlerOptions } from './services/logging/logging-error-handler';
import { ColorService } from './services/common/color.service';

// Calendar
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

// Translation module
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MissingTranslationHandler } from '@ngx-translate/core';
import { CustomMissingTranslationHandler } from 'app/common/missingTransLationHandler';

// Chart module
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as Highcharts from 'highcharts';
import * as boostCanvas from 'highcharts/modules/boost-canvas';
import * as boost from 'highcharts/modules/boost.src';
import * as drilldown from 'highcharts/modules/drilldown.src';
import * as serieslabel from 'highcharts/modules/series-label.src';
import * as overlappingdatalabels from 'highcharts/modules/overlapping-datalabels.src';
import * as exporting from 'highcharts/modules/exporting.src';
import * as heatmap from 'highcharts/modules/heatmap.src.js';
import * as noDataToDisplay from 'highcharts/modules/no-data-to-display.src';
import * as offlineExporting from 'highcharts/modules/offline-exporting.src';
import * as solidgauge from 'highcharts/modules/solid-gauge.src';

// Notifications
import { ToastrModule } from 'ngx-toastr';
import { NotificationsModule, NotificationsService } from './modules/notification'

// Modules
import { ICheckModule } from './modules/icheck/icheck.module';
import { WizardModule } from './modules/wizard/wizard.module';

// Export functions
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

import './common/rxjs-operators';
import { TranslatorService } from './services/common/translator.service';
import { TripService } from './services/trip/trip.service';
import { PlatformService } from './services/platform/platform.service';
import { CustomerService } from './services/customers/customers.service';
import { AccountService } from './services/accounts/accounts.service';
import { AccountPlatformService } from './services/platform/accountPlatform.service';
import { UserRoleService } from './services/users/userRole.service';
import { WorkflowService } from './services/workflows/workflows.service';
import { CacheService } from './services/common/cache.service';
import { DeviceSensorsService } from './services/sensors/deviceSensors.service';
import { TagService } from './services/tag/tag.service';
import { InfoService } from './services/info/info.service';
import { EventService } from './services/events/events.service';
import { environment } from 'environments/environment';
import { JobsService } from './services/jobs/jobs.service';
import { AssetService } from './services/asset/asset.service';
import { DriverService } from './services/driver/driver.service';
import { AssetTypeService } from './services/asset/assetTypes.service';
import { WaslService } from './services/wasl/wasl.service';
import { SensorsTypesService } from './services/sensors/sensorTypes.service';
import { SensorService } from './services/sensors/sensors.service';
import { GridService } from './services/grid/grid.service';
// todo : https://www.intertech.com/Blog/angular-4-tutorial-handling-refresh-token-with-new-httpinterceptor/


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    HttpClientJsonpModule,
    SharedLayoutsModule,
    AppviewsModule,
    ChartModule,
    WizardModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    ICheckModule.forRoot(),
    RouterModule.forRoot(ROUTES),
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.rectangleBounce,
      backdropBackgroundColour: 'rgba(255,255,255,0.7)',
      backdropBorderRadius: '4px',
      primaryColour: '#DC8139',
      secondaryColour: '#DC8139',
      tertiaryColour: '#DC8139'
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: CustomMissingTranslationHandler },
    }),
    HttpClientModule,
    NotificationsModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
  ],
  providers: [
    ErrorLogService,
    LoggingErrorHandlerProviders,
    {
      provide: LoggingErrorHandlerOptions,
      useValue: {
        rethrowError: false,
        unwrapError: false
      }
    },
    AuthGuard,
    TranslatorService,
    AuthenticationService,
    AuditLogService,
    DeviceService,
    GridService,
    DeviceTypeService,
    ResolutionTypeService,
    PlatformService,
    AssetTypeService,
    AssetService,
    AccountPlatformService,
    EventService,
    DashboardService,
    JobsService,
    IssueTypeService,
    DriverService,
    WaslService,
    IssueService,
    ScheduleService,
    CustomerService,
    WorkflowService,
    TripService,
    UserService,
    UserRoleService,
    AccountService,
    CookieService,
    LoggingService,
    ErrorLogService,
    DeviceSensorsService,
    SensorService,
    SensorsTypesService,
    TagService,
    CacheService,
    NotificationsService,
    ColorService,
    InfoService,
    CalculationSettingsService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HIGHCHARTS_MODULES,
      useFactory: () => [
        more, solidgauge, boostCanvas, boost, overlappingdatalabels, drilldown //  exporting, heatmap, noDataToDisplay, offlineExporting, serieslabel
      ]
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
