import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import * as moment from 'moment';
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch';
import { throwError } from 'rxjs/internal/observable/throwError';


import { AuthenticationService } from '../authentication/authentication.service';
import { LoggingService } from '../logging/logging.service';

export class Trip {
    beginLongitude: any;
    endLatitude: any;
    endLongitude: any;
    beginLatitude: any;
    locations: any;
    segmentDistanceInKilometers: any;
    boundingDistanceInMetres: any;
    endCity: any;
    endLabel: any;
    endDateTime: any;
    beginCity: any;
    beginLabel: any;
    beginDateTime: any;
    driverName: any;
    driverId: any;
    deviceId: any;
    assetId: any;
    id: any;
}

@Injectable()
export class TripService {
    token: string;
    timestamp;
    urlFleetOverview: string;

    constructor(private http: HttpClient, private loggingService: LoggingService, private authenticationService: AuthenticationService) {
        this.urlFleetOverview = authenticationService.getFleetOverviewUrl('');
        this.token = authenticationService.getAuthToken();
    }

    getTripsForAsset(assetId: string, start, end): Observable<Trip[]> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting schedule from service');
        return this.http.get(this.urlFleetOverview + 'Asset/' + assetId + '/Trip/' + Math.round(start / 1000) + '/' + Math.round(end / 1000) + '/True/True', { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getTripsForDevice(deviceId: string, start, end): Observable<Trip[]> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting trips from service');
        return this.http.get('./assets/data/trips.json')
            .map((data) => {
                return this.parseResponse(data);
            })
            .catch(this.handleError);
    }


    getTripDetails(tripId: string, assetId: string, start, end): Observable<Trip> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting schedule from service');
        return this.http.get(this.urlFleetOverview + '/Trip/' + tripId + '/True/' + assetId + '/' + Math.round(start / 1000) + '/' + Math.round(end / 1000) + '/True/True', { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseTripDetails(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getMessageUrl(deviceId: string, start, end) {
        return this.urlFleetOverview + 'Device/' + deviceId + '/Locations?start=' + Math.round(start / 1000) + '&end=' + Math.round(end / 1000);
    }

    getMessagesForDevice(deviceId: string, start, end): Observable<any> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting schedule from service');
        return this.http.get(this.urlFleetOverview + 'Device/' + deviceId + '/Locations?start=' + Math.round(start / 1000) + '&end=' + Math.round(end / 1000), { headers: headers })
            .map((data) => {
                const parsedResponse = data;
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    parseResponse(json: any): Trip[] {

        this.loggingService.log(this.constructor.name, 'Retrieved ' + json.length + ' schedules.');

        const ident = 1;
        const trips: Trip[] = [];

        json.forEach(item => {

            const trip = this.parseTripDetails(item);

            trips.push(trip);
        });

        this.timestamp = new Date().getTime();
        return trips;
    }

    parseTripDetails(item) {
        const trip = new Trip();
        trip.id = item.Id;
        trip.assetId = item.AssetId;
        trip.deviceId = item.DeviceId;
        trip.driverId = item.DriverId;
        trip.driverName = item.DriverName;

        trip.beginDateTime = item.BeginDateTime;
        trip.beginLabel = item.BeginLabel;
        trip.beginCity = item.BeginCity;
        trip.beginLatitude = item.BeginLatitude;
        trip.beginLongitude = item.BeginLongitud;

        trip.endDateTime = item.EndDateTime;
        trip.endLabel = item.EndLabel;
        trip.endCity = item.EndCity;
        trip.endLatitude = item.EndLatitude;
        trip.endLongitude = item.EndLongitud;

        trip.boundingDistanceInMetres = item.BoundingDistanceInMetres;
        trip.segmentDistanceInKilometers = item.SegmentDistanceInKilometers;

        trip.locations = item.Locations;

        return trip;
    }

    private handleError(error: Response) {
        return throwError(error);
    }
}
