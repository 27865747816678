import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch';
import { of } from 'rxjs/observable/of';
import { throwError } from 'rxjs/internal/observable/throwError';

import { AuthenticationService } from '../authentication/authentication.service';
import { LoggingService } from '../logging/logging.service';
import { AccountPlatform } from '../../models/platform.model';

@Injectable()
export class AccountPlatformService {

    token: string;
    timestamp;
    url = '';
    AccountPlatforms: AccountPlatform[] = [];
    urlInventory: string;
    urlInventoryBase: string;

    constructor(private http: HttpClient, private loggingService: LoggingService, private authenticationService: AuthenticationService) {
        this.url = authenticationService.getWebserviceURL('accountPlatform');
        this.urlInventory = authenticationService.getInventoryURL('accountPlatform');
        this.urlInventoryBase = authenticationService.getInventoryURL('');
        this.token = authenticationService.getAuthToken();
    }

    getAccountPlatforms(setDefinate = true, onlyFromMemmory = false): Observable<AccountPlatform[]> {

        if (setDefinate !== true && this.AccountPlatforms.length !== 0) {
            return of(this.AccountPlatforms);
        }

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting accountplatforms from service');
        return this.http.get(this.urlInventory, { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                if (setDefinate) {
                    console.log('setting AccountPlatforms');
                    this.AccountPlatforms = parsedResponse;
                }
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getAccountPlatformById(id: string): Observable<AccountPlatform> {
        return this.getAccountPlatforms(true).map(platforms => {
            console.log('get AccountPlatforms');
            const platform = platforms.filter(x => x.id === id)[0];
            return platform;
        });
    }

    updateToken(accountId: string, token: string): Observable<any> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        return this.http.post(this.url + 'UpdateToken', { accountId, token }, { headers: headers })
            .map((data) => {
                const parsedResponse = data;
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    checkTokenForAccountPlatform(accountPlatformId: string): Observable<any> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        return this.http.get(this.url + accountPlatformId + '/TestToken', { headers: headers })
            .map((data) => {
                const parsedResponse = data;
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    checkToken(platformType: number, token: string, url: string): Observable<any> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        return this.http.get(this.url + 'TestToken/' + platformType + '/' + btoa(token) + '/' + btoa(url), { headers: headers })
            .map((data) => {
                const parsedResponse = data;
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    createNewAccountPlatform(accountId: string, name: string, username: string, password: string, platformType: number, token: string, url: string): Observable<any> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        const ap = new AccountPlatform();
        ap.accountId = accountId;
        ap.name = name;
        ap.username = username;
        ap.password = password;
        ap.platformType = platformType;
        ap.token = token;
        ap.url = url;

        return this.http.post(this.urlInventory, ap, { headers: headers })
            .map((data) => ({
                completed: true,
                data: data
            }))
            .catch((error) => of({
                completed: false,
                data: error
            }));
    }

    updateAccountPlatform(accountPlatform: AccountPlatform): Observable<any> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        return this.http.put(this.urlInventory, accountPlatform, { headers: headers })
            .map((data) => ({
                completed: true,
                data: data
            }))
            .catch((error) => of({
                completed: false,
                data: error
            }));
    }

    deleteAccountPlatform(id: any): Observable<any> {
        return this.http.delete(this.urlInventory + id, { headers: this.authenticationService.headers })
            .catch(this.handleError);
    }

    saveNewToken(accountPlatformId: string, token: string, url: string, name: string): Observable<any> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        const ap = new AccountPlatform();
        ap.id = accountPlatformId;
        ap.token = token;
        ap.url = url;
        ap.name = name;

        return this.http.put(this.urlInventory, ap, { headers: headers })
            .map((data) => {
                const parsedResponse = data;
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getAccountPlatformByAccountId(id: string): Observable<AccountPlatform[]> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting accountplatforms from service');
        return this.http.get(this.urlInventoryBase + 'Account/' + id + '/AccountPlatform', { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                this.AccountPlatforms = parsedResponse;
                return parsedResponse;
            })
            .catch(this.handleError);
    }


    private handleError(error: Response) {
        console.log(error);
        return throwError(error);
    }

    parseResponse(json: any): AccountPlatform[] {

        this.loggingService.log(this.constructor.name, 'Retrieved ' + json.length + ' AccountPlatforms.');

        const ident = 1;
        const platforms: AccountPlatform[] = [];

        json.forEach(item => {

            const platform = this.parseReponseDetails(item);

            platforms.push(platform);
        });

        this.timestamp = new Date().getTime();
        return platforms;
    }

    parseReponseDetails(item) {
        //   this.loggingService.log(this.constructor().name + " - New", item);
        const accountPlatform = new AccountPlatform();
        accountPlatform.id = item.id;
        accountPlatform.name = item.name;
        accountPlatform.platformId = item.platformId;
        accountPlatform.platformName = item.platformName;
        accountPlatform.platformType = item.platformType;
        accountPlatform.timezoneIana = item.timezoneIana;
        accountPlatform.deviceCount = item.deviceCount;
        accountPlatform.token = item.token;
        accountPlatform.url = item.url;

        return accountPlatform;
    }
}
