<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
        <ol class="breadcrumb">
            <li>
                <a href="/#/"><i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Devices/Overview">{{'general.devices' | translate }}</a>
            </li>
            <li class="active">
                <strong>{{'menu.devicesettings' | translate }}</strong>
            </li>
        </ol>
    </div>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="">
        <div class="col-lg-6">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-cog"></i> {{'menu.devicesettings' | translate }}
                    </h5>
                </div>
                <div class="ibox-content slick">
                    <table style="width: 100%" class="table table-hover nowrap dtr-inline no-footer">
                        <thead>
                            <tr>
                                <th>
                                    Naam
                                </th>
                                <th>
                                    Waarde
                                </th>
                                <th>
                                    Inv
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="col-lg-4">
                                    Input 1
                                </td>
                                <td class="col-lg-7">
                                    <select id="input1" class="select2 form-input-inline form-control table-inline">

                                    </select>
                                </td>
                                <td class="col-lg-1">
                                    <input type="checkbox" value="" name="" class="i-checks" />
                                </td>
                            </tr>
                            <tr>
                                <td class="col-lg-4">
                                    Input 2
                                </td>
                                <td class="col-lg-7">
                                    <select id="input2" class="select2 form-input-inline form-control table-inline">

                                    </select>
                                </td>
                                <td class="col-lg-1">
                                    <input type="checkbox" value="" name="" class="i-checks" />
                                </td>
                            </tr>
                            <tr>
                                <td class="col-lg-4">
                                    Input 3
                                </td>
                                <td class="col-lg-7">
                                    <select id="input3" class="select2 form-input-inline form-control table-inline">

                                    </select>
                                </td>
                                <td class="col-lg-1">
                                    <input type="checkbox" value="" name="" class="i-checks" />
                                </td>
                            </tr>
                            <tr>
                                <td class="col-lg-4">
                                    Input 4
                                </td>
                                <td class="col-lg-7">
                                    <select id="input4" class="select2 form-input-inline form-control table-inline">

                                    </select>
                                </td>
                                <td class="col-lg-1">
                                    <input type="checkbox" value="" name="" class="i-checks" />
                                </td>
                            </tr>
                            <tr>
                                <td class="col-lg-4">
                                    Input 5
                                </td>
                                <td class="col-lg-7">
                                    <select id="input5" class="select2 form-input-inline form-control table-inline">

                                    </select>
                                </td>
                                <td class="col-lg-1">
                                    <input type="checkbox" value="" name="" class="i-checks" />
                                </td>
                            </tr>
                            <tr>
                                <td class="col-lg-4">
                                    Input 6
                                </td>
                                <td class="col-lg-7">
                                    <select id="input6" class="select2 form-input-inline form-control table-inline">

                                    </select>
                                </td>
                                <td class="col-lg-1">
                                    <input type="checkbox" value="" name="" class="i-checks" />
                                </td>
                            </tr>
                            <tr>
                                <td class="col-lg-4">
                                    A1
                                </td>
                                <td class="col-lg-8" colspan="2">
                                    <select id="analog1" class="select2 form-input-inline form-control table-inline">

                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td class="col-lg-4">
                                    A2
                                </td>
                                <td class="col-lg-8" colspan="2">
                                    <select id="analog2" class="select2 form-input-inline form-control table-inline">

                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td class="col-lg-4">
                                    ExternalPower
                                </td>
                                <td class="col-lg-8" colspan="2">
                                    <select id="externalpower"
                                        class="select2 form-input-inline form-control table-inline">

                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td class="col-lg-4">
                                    Ignition
                                </td>
                                <td class="col-lg-8" colspan="2">
                                    <select id="ignition1" class="select2 form-input-inline form-control table-inline">

                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="ibox-footer">
                    <button class="btn btn-primary" data-style="zoom-in">
                        <i class="fas fa-fw fa-save"></i> Save</button>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-external-link-square-alt"></i> {{'devicedetails.outputs' | translate }}
                    </h5>
                </div>
                <div class="ibox-content">

                    <table style="width: 100%" class="table table-hover nowrap dtr-inline no-footer">
                        <thead>
                            <tr>
                                <th>
                                    Naam
                                </th>
                                <th>
                                    Waarde
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="col-lg-4">
                                    Output 1
                                </td>
                                <td class="col-lg-8">
                                    <select id="output1" class="select2 form-input-inline form-control table-inline">

                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td class="col-lg-4">
                                    Output 2
                                </td>
                                <td class="col-lg-8">
                                    <select id="output2" class="select2 form-input-inline form-control table-inline">

                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td class="col-lg-4">
                                    Output 3
                                </td>
                                <td class="col-lg-8">
                                    <select id="output3" class="select2 form-input-inline form-control table-inline">

                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td class="col-lg-4">
                                    Output 4
                                </td>
                                <td class="col-lg-8">
                                    <select id="output4" class="select2 form-input-inline form-control table-inline">

                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-toggle-off"></i> {{'devicedetails.auxiliary' | translate }}
                    </h5>
                </div>
                <div class="ibox-content">
                    <table style="width: 100%" class="table table-hover nowrap dtr-inline no-footer">
                        <thead>
                            <tr>
                                <th>
                                    Naam
                                </th>
                                <th>
                                    Waarde
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="col-lg-4">
                                    AuxilaryAttachments
                                </td>
                                <td class="col-lg-8">
                                    <select class="select2_multi form-control table-inline" multiple="multiple">
                                        <option value="@deviceAuxiliary">@deviceAuxiliary</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td class="col-lg-4">
                                    RfidReaderInverted
                                </td>
                                <td class="col-lg-8">
                                    <input type="checkbox" value="" name="" class="i-checks" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>