import { Component, Input, OnInit } from '@angular/core';
import { FormMode } from 'app/common/enums';
import { trigger, style, animate, transition } from '@angular/animations';
import { AddressDetails } from 'app/models/platform.model';

@Component({
    selector: 'fh-address-details',
    templateUrl: 'addressDetails.component.html',
    animations: [
        trigger(
            'enterAnimation', [
            transition(':enter', [
                // css styles at start of transition
                style({ opacity: 0 }),

                // animation and styles at end of transition
                animate('0.5s', style({ opacity: 1 }))
            ]),
        ]
        )
    ],
})
export class AddressDetailsComponent implements OnInit {

    @Input() address: AddressDetails;
    @Input() formMode: FormMode = FormMode.read;

    constructor() {
    }

    ngOnInit(): void {
    }

}

