<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Devices/Overview">{{'general.devices' | translate }}</a>
            </li>
            <li>
                <a href="/#/DeviceDetails/Index/{{device?.id}}">{{device?.name}}</a>
            </li>
            <li class="active">
                <strong>{{'menu.devicelocations' | translate }}</strong>
            </li>
        </ol>
    </div>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="">
        <div class="col-lg-6">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <span class="pull-left">
                        <h5>
                            <i class="fas fa-fw fa-location-arrow"></i> {{'menu.devicelocations' | translate }}
                        </h5>
                    </span>
                    <span class="pull-right">
                        <i class="fa fa-spinner fa-spin fa-fw"></i>
                    </span>
                </div>
                <div class="ibox-content">
                    <div class="card card-block card-header mb-3">The selected page no: {{currentPage}}/{{smallnumPages}}</div>
                    <div class="activity-stream" [@enterAnimation] *ngFor="let item of locations | orderBy : 'date':true | slice:((currentPage - 1) * 10): ((currentPage - 1) * 10) + 10">
                        <div class="stream">
                            <div class="stream-badge">
                                <i class="fa fa-location-arrow" [style.color]="item.iconColor" [style.background]="item.iconBackGround"></i>
                            </div>
                            <div class="stream-panel">
                                <div class="stream-info">
                                    <span class="date">{{item.date}}</span>
                                </div>
                                {{item.location.latitude}} - {{item.location.longitude}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ibox-footer">
                    <pagination [totalItems]="totalItems" [(ngModel)]="currentPage" (pageChanged)="pageChanged($event)" (numPages)="smallnumPages = $event"></pagination>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="ibox float-e-margins ">
                <div class="ibox-title ">
                    <h5>
                        <i class="fa fa-map "></i> {{'general.location' | translate }}</h5>
                </div>
                <div class="ibox-content slick " style="height: 300px; ">
                    <div style="height: 100%; " leaflet [leafletOptions]="options " [leafletLayersControl]="layersControl" (leafletMapReady)="onMapReady($event) ">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>