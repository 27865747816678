import { Component, OnDestroy, OnInit, ChangeDetectorRef, ViewChild, } from '@angular/core';

import { IssueType } from 'app/models/issuetype.model';
import { DataTableDirective } from 'angular-datatables';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { AssetService } from '../../services/asset/asset.service';
import { ActivatedRoute } from '@angular/router';
import { getGridLanguages } from 'app/common/gridhelper';


@Component({
  selector: 'fh-wasl-registration-status',
  templateUrl: 'status.template.html'
})
export class WaslRegistrationStatusViewComponent implements OnInit, OnDestroy {
  token: string;
  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  dropdown_IssueType = '';

  dtOptions = {};

  min: number;
  max: number;

  issueTypes: IssueType[];
  loading = false;
  languageLoaded: boolean;
  permissions: {};
  searchTerm: any;

  constructor(private route: ActivatedRoute, private translateService: TranslateService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private assetService: AssetService) {

    this.token = this.authenticationService.getAuthToken();
  }

  editMode(isEditMode) {
    const toolbox = document.getElementById('grid-toolbox');

    console.log(isEditMode);
    if (isEditMode) {
      toolbox.classList.remove('hidden');
    } else {
      toolbox.classList.add('hidden');
    }
  }

  seachChanged(value) {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.search(value).draw();
    });
  }

  checkFilters(): void {
    // Set issuetype from get
    this.route.params.subscribe(params => {
      const search = params['id'];
      if (search) {
        console.log('search changed ' + search);
        this.seachChanged(search);
      }
    });

    this.fireFilter();
  }

  fireFilter(): void {
    console.log('fire update');
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {

      // Search for the devicetype

      // Search for the issuetype
      if (this.dropdown_IssueType !== '') {
        dtInstance.column(6).search(this.dropdown_IssueType);
      }

      // Fire the request
      dtInstance.draw();
    });
  }

  ngOnDestroy(): void {
    // We remove the last function in the global ext search array so we do not add the fn each time the component is drawn
    // /!\ This is not the ideal solution as other components may add other search function in this array, so be careful when
    // handling this global variable
    $.fn['dataTable'].ext.search.pop();

    // Had to reset the array...
    $.fn['dataTable'].ext.search = [];
  }


  ngOnInit(): void {
    this.permissions = this.authenticationService.permissions;

    this.translateService.get('general.date').subscribe(value => {
      this.languageLoaded = true;
      this.initGrid();
    });
  }

  initGrid(): void {
    const that = this;

    $.fn['dataTable'].ext.search.pop();

    this.loading = true;

    const commonExportOptions = {
      modifier: {
        page: 'all',
        search: 'none'
      },
      // columns: ':visible[tabindex]'
    };
    const fileName = 'device_overview';
    const header = this.translateService.instant('menu.deviceoverview');

    this.dtOptions = {
      pagingType: 'simple_numbers',
      serverSide: false,
      deferRender: true,
      processing: false,
      scrollX: true,
      colReorder: {fixedColumnsLeft: 1},
      stateSave: true,
      stateSaveCallback: function (settings, data) {
        localStorage.setItem('Columns_' + that.constructor.name, JSON.stringify(data));
      },
      stateLoadCallback: function () {
        try {
          const columnSettings = JSON.parse(localStorage.getItem('Columns_' + that.constructor.name));
          that.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
          return columnSettings;
        } catch (e) {
          console.log(e);
        }
      },
      order: [[1, 'asc']],
      ajax: (dataTablesParameters: any, callback) => {
        $('.dataTables_info').html(this.translateService.instant('grid.loadingData'));

        this.assetService.getWaslAssetStatus().subscribe(resp => {
          callback({
            recordsTotal: resp.length,
            recordsFiltered: resp.length,
            data: resp
          });
        });
      },
      initComplete: function (settings, json) {
        that.loading = false;
        that.checkFilters();
      },
      colVis: {
        restore: this.translateService.instant('general.restore'),
        showAll: this.translateService.instant('general.showAll'),
        showNone: this.translateService.instant('general.hideAll'),
      },
      columns: [
        {
          name: 'id',
          data: 'id',
          orderable: false,
          title: '',
          width: '20',
          render: function (data, type, row) {
            return '<a class=\'btn btn-primary btn-outline btn-grid\' href=\'/#/TrailerDetails/Index/' + data + '\'><i class=\'fas fa-fw fa-info\'></i> <span class="hidden-sm hidden-xs">Details</span></a>';
          }
        },
        {
          name: 'name',
          data: 'assetName',
          title: this.translateService.instant('general.name')
        }, {
          name: 'assetProperties',
          data: 'assetProperties',
          title: this.translateService.instant('general.assetProperties'),
          render: function (data, type, row) {

            if (data === undefined) {
              return '-';
            }
            if (data.wasl === undefined) {
              return '-';
            }
            if (data.wasl.referenceKey === undefined) {
              return '-';
            }
            if (data.wasl.referenceKey === 'false') {
              return '-';
            }

            return '<i class="fa fa-fw fa-check-square" title="' + data.wasl.referenceKey + '"></i>';
          },
        }, {
          name: 'assetPropertiesExport',
          data: 'assetProperties',
          title: this.translateService.instant('general.assetProperties') + ' ' + this.translateService.instant('general.export'),
          render: function (data, type, row) {

            if (data === undefined) {
              return '-';
            }
            if (data.wasl === undefined) {
              return '-';
            }
            if (data.wasl.referenceKey === undefined) {
              return '-';
            }
            if (data.wasl.referenceKey === 'false') {
              return '-';
            }

            return data.wasl.referenceKey;
          },
          visible: false
        }, {
          name: 'tag',
          data: 'tag',
          title: this.translateService.instant('general.tag')
        }, {
          name: 'driverName',
          data: 'driverName',
          title: this.translateService.instant('general.driver')
        }, {
          name: 'driverProperties',
          data: 'driverProperties',
          title: this.translateService.instant('general.driverProperties'),
          render: function (data, type, row) {
            if (data === undefined) {
              return '-';
            }
            if (data.wasl === undefined) {
              return '-';
            }
            if (data.wasl.referenceKey === undefined) {
              return '-';
            }
            if (data.wasl.referenceKey === 'false') {
              return '-';
            }
            return '<i class="fa fa-fw fa-check-square" title="' + data.wasl.referenceKey + '"></i>';
          },
        }, {
          name: 'driverPropertiesExport',
          data: 'driverProperties',
          title: this.translateService.instant('general.driverProperties') + ' ' + this.translateService.instant('general.export'),
          render: function (data, type, row) {
            if (data === undefined) {
              return '-';
            }
            if (data.wasl === undefined) {
              return '-';
            }
            if (data.wasl.referenceKey === undefined) {
              return '-';
            }
            if (data.wasl.referenceKey === 'false') {
              return '-';
            }
            return data.wasl.referenceKey;
          },
          visible: false
        }, {
          name: 'trailerName',
          data: 'trailerName',
          title: this.translateService.instant('general.trailer')
        }, {
          name: 'trailerProperties',
          data: 'trailerProperties',
          title: this.translateService.instant('general.trailerProperties'),
          render: function (data, type, row) {
            if (data === undefined) {
              return '-';
            }
            if (data.wasl === undefined) {
              return '-';
            }
            if (data.wasl.referenceKey === undefined) {
              return '-';
            }
            if (data.wasl.referenceKey === 'false') {
              return '-';
            }
            return '<i class="fa fa-fw fa-check-square" title="' + data.wasl.referenceKey + '"></i>';
          },
        }, {
          name: 'trailerPropertiesExport',
          data: 'trailerProperties',
          title: this.translateService.instant('general.trailerProperties') + ' ' + this.translateService.instant('general.export'),
          render: function (data, type, row) {
            if (data === undefined) {
              return '-';
            }
            if (data.wasl === undefined) {
              return '-';
            }
            if (data.wasl.referenceKey === undefined) {
              return '-';
            }
            if (data.wasl.referenceKey === 'false') {
              return '-';
            }
            return data.wasl.referenceKey;
          },
          visible: false
        },
      ],
      pageLength: 10,
      lengthMenu: [[10, 25, 50, -1], [10, 25, 50, this.translateService.instant('general.all')]],
      language: getGridLanguages(this.translateService),
    };
  }
}
