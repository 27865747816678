import { Component, OnInit, AfterViewInit } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { AccountPlatformService } from '../../services/platform/accountPlatform.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Platform } from 'app/models/platform.model';

@Component({
    selector: 'fh-platform-calculation-settings',
    templateUrl: 'platformCalculationSettings.template.html'
})
export class PlatformCalculationSettingsViewComponent implements OnInit {
    sub;
    platform: Platform;

    constructor(private http: HttpClient, private accountPlatformService: AccountPlatformService, private route: ActivatedRoute, private router: Router) {
        this.platform = null;
    }

    ngOnInit() {
        this.platform = new Platform;
        this.platform.id = ''

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];
            this.accountPlatformService.getAccountPlatformById(id).subscribe(platform => {
                this.platform = platform;

                if (this.platform == null) {
                    this.router.navigate(['/Platforms/Overview'])
                }
            });
        });
    }
}
