<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li class="active">
                <strong>{{'general.profile' | translate}}</strong>
            </li>
        </ol>
    </div>
</div>

<div class="wrapper wrapper-content">

    <div class="row" *ngIf="isSaved">
        <div class="col-lg-12">
            <div ([ngModel])="updateMessage" class="alert alert-success shadow">
                {{ updateMessage }}
            </div>
        </div>
    </div>

    <div class="row" data-equalizer="">
        <div class="col-lg-6">

            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-user"></i> {{'general.profile' | translate}}
                    </h5>
                </div>
                <div class="ibox-content slick" style="position: relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <table class="table table-hover nowrap dtr-inline no-footer" style="width:100%; margin-bottom:0px ">
                        <tbody>
                            <tr>
                                <td style="width:50%">
                                    {{ 'general.userNameEmail' | translate}}
                                </td>
                                <td style="width:50%">
                                    {{ user?.name }}
                                </td>
                            </tr>
                            <tr>
                                <td style="width:50%">
                                    {{ 'general.firstName' | translate}}
                                </td>
                                <td style="width:50%">
                                    <input type="text" class="form-control table-inline" [(ngModel)]="user.firstName">
                                </td>
                            </tr>
                            <tr>
                                <td style="width:50%">
                                    {{ 'general.lastName' | translate}}
                                </td>
                                <td style="width:50%">
                                    <input type="text" class="form-control table-inline" [(ngModel)]="user.lastName">
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'general.whitelabel' | translate}}
                                </td>
                                <td>
                                    <select [(ngModel)]="user.whitelabel" class="form-control table-inline" #whitelabel
                                        style="width:100%">
                                        <option *ngFor="let whitelabel of whitelabels" value={{whitelabel.value}}>
                                            {{whitelabel.name}}
                                        </option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'general.language' | translate}}
                                </td>
                                <td>
                                    <select [(ngModel)]="currentLang" class="form-control table-inline" #lang
                                        style="width:100%" (change)="onChange($event, lang.value)">
                                        <option *ngFor="let language of languages" value={{language.name}}>
                                            {{language.name}}
                                        </option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td> {{ 'general.translateremark' | translate}}</td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'dates.timezone' | translate}}
                                </td>
                                <td>
                                    <ng2-timezone-picker-v1 [(timezone)]="user.timezoneIana" (timezoneValueChange)="user.timezoneIana = $event"></ng2-timezone-picker-v1>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="ibox-footer">
                    <button id="submit" name="submit" class="btn btn-primary" (click)="save()">
                        <i class="fas fa-fw fa-save"></i> {{ 'general.saveChanges' | translate}}</button>
                </div>
            </div>

            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-database"></i> {{'general.account' | translate}}
                    </h5>
                </div>
                <div class="ibox-content slick" style="position: relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <table class="table table-hover nowrap dtr-inline no-footer" style="width:100%; margin-bottom:0px ">
                        <tbody>
                            <tr>
                                <td style="width:50%">
                                    {{ 'general.name' | translate}}
                                </td>
                                <td style="width:50%">
                                    {{ account?.name }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'general.createdDate' | translate}}
                                </td>
                                <td>
                                    {{ account?.createdDate | amDateFormat: 'll' }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'general.identifier' | translate}}
                                </td>
                                <td>
                                    {{ account?.identifier }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'general.limitHistoryInDays' | translate}}
                                </td>
                                <td>
                                    {{ account?.limitHistoryInDays }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fa fa-satellite-dish"></i> {{ 'general.changePassword' | translate}}
                    </h5>
                </div>
                <div class="ibox-content slick">
                    <table class="table table-hover nowrap dtr-inline no-footer" style="width:100%; margin-bottom:0px ">
                        <tbody>
                            <tr>
                                <td style="width:50%">
                                    {{ 'general.oldPassword' | translate}}
                                </td>
                                <td style="width:50%">
                                    <input type="text" type="password" [(ngModel)]="oldPassword"
                                        class="form-control table-inline">
                                </td>
                            </tr>
                            <tr>
                                <td style="width:50%">
                                    {{ 'general.newPassword' | translate}}
                                </td>
                                <td style="width:50%">
                                    <input type="text" type="password" [(ngModel)]="newPassword"
                                        class="form-control table-inline">
                                </td>
                            </tr>
                            <tr>
                                <td style="width:50%">
                                    {{ 'general.confirmPassword' | translate}}
                                </td>
                                <td style="width:50%">
                                    <input type="text" type="password" [(ngModel)]="confirmPassword"
                                        class="form-control table-inline">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="ibox-footer">
                    <!-- Button -->
                    <button id="submit" name="submit" class="btn btn-primary" (click)="savePassword()">
                        <i class="fas fa-fw fa-save"></i> {{ 'general.saveChanges' | translate}}</button>
                </div>
            </div>
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fa fa-envelope"></i> Email report
                    </h5>
                </div>
                <div class="ibox-content slick">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <table class="table table-hover nowrap dtr-inline no-footer" style="width:100%; margin-bottom:0px ">
                        <tbody>
                            <tr>
                                <td>
                                    {{ 'report.email' | translate }}
                                </td>
                                <td>
                                    <input type="email" class="form-control table-inline" [(ngModel)]="user.email">
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'report.period' | translate }}
                                </td>
                                <td>
                                    <select [(ngModel)]="chosenPeriod" class="form-control table-inline"
                                        style="width:100%" (ngModelChange)="createCronTab(chosenPeriod)">
                                        <option *ngFor="let period of periods" [value]="period">
                                            {{ period | titlecase }}
                                        </option>
                                    </select>
                                </td>
                            </tr>
                            <tr *ngIf="chosenPeriod === periods[1]">
                                <td>
                                    {{ 'dates.day' | translate }} {{ 'dates.ofThe' | translate }}
                                    {{ 'dates.week' | translate | lowercase }}
                                </td>
                                <td>
                                    <select [(ngModel)]="chosenDayOfTheWeek" class="form-control table-inline"
                                        style="width:100%" (ngModelChange)="createCronTab(chosenPeriod)">
                                        <option *ngFor="let day of daysInTheWeek" [value]="day">
                                            {{ day }}
                                        </option>
                                    </select>
                                </td>
                            </tr>
                            <tr *ngIf="chosenPeriod === periods[2] || chosenPeriod === periods[3]">
                                <td>
                                    {{ 'dates.day' | translate }} {{ 'dates.ofThe' | translate }}
                                    {{ 'dates.month' | translate }}
                                </td>
                                <td>
                                    <select [(ngModel)]="chosenDayOfTheMonth" class="form-control table-inline"
                                        style="width:100%" (ngModelChange)="createCronTab(chosenPeriod)">
                                        <option *ngFor="let day of days" [value]="day">
                                            {{ day }}
                                        </option>
                                    </select>
                                </td>
                            </tr>
                            <tr *ngIf="chosenPeriod === periods[3]">
                                <td>
                                    {{ 'report.quarters' | translate }}
                                </td>
                                <td>
                                    <select [(ngModel)]="chosenQuarter" class="form-control table-inline"
                                        style="width:100%" (ngModelChange)="createCronTab(chosenPeriod)">
                                        <option *ngFor="let quarter of quarters" [value]="quarter">
                                            {{ quarter }}
                                        </option>
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="ibox-footer">
                    <button id="submit" name="submit" class="btn btn-primary" (click)="save()">
                        <i class="fas fa-fw fa-save"></i> {{ 'general.saveChanges' | translate }}</button>
                </div>
            </div>
        </div>
    </div>
</div>