<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Assets/Overview">{{'general.asset' | translate }}</a>
            </li>
            <li>
                <a href="/#/AssetDetails/Index/{{asset?.id}}">{{asset?.name}}</a>
            </li>
            <li class="active">
                <strong>{{'general.assetDetails' | translate }}</strong>
            </li>
        </ol>
    </div>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="">

        <div class="col-lg-12">
            <fh-error [error]="error" [success]="success"></fh-error>

            <div class="ibox float-e-margins" style="position:relative">
                <ngx-loading [show]="loadingTrailers"></ngx-loading>
                <div class="ibox-title">
                    <h5>
                        <i class="fa fa-truck-loading"></i> {{'general.trailers' | translate }}
                    </h5>
                </div>
                <form #userForm="ngForm" #formDir="ngForm">
                    <div class="ibox-content slick" style="position:relative" *ngIf="linkItem.id">
                        <table style="width:100%" class="table table-hover nowrap dtr-inline no-footer">
                            <tbody>
                                <tr>
                                    <td style="width:50%">
                                        {{ 'general.trailer' | translate}}
                                    </td>
                                    <td colspan="2" style="width:50%">
                                        {{linkItem.name}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="ibox-footer">
                        <button class="btn btn-primary" *ngIf="!linkItem.id" (click)="showAddPopup()"><i
                                class="fas fa-fw fa-search"></i>
                            {{'general.search' | translate }}</button>

                        <button class="btn btn-primary" *ngIf="linkItem.id" (click)="saveNew()"><i
                                class="fas fa-fw fa-save"></i>
                            {{'general.assign' | translate }}</button>

                        <button class="btn btn-danger" *ngIf="linkItem.id" (click)="linkItem.id = null"><i
                                class="fas fa-fw fa-times"></i>
                            {{'general.cancel' | translate }}</button>
                    </div>
                </form>
            </div>
        </div>

        <div class="col-lg-6" *ngFor="let trailer of trailers | orderBy : 'assetLinkStartDate':true">
            <div class="ibox float-e-margins" [class.inActive]="!trailer.isActive">
                <div class="ibox-title">
                    <h5>
                        <i class="fa fa-truck-loading"></i> {{ trailer.name }}
                        <!-- /#/TrailerDetails/Index/{{trailer?.id}} -->
                    </h5>
                    <span class="pull-right" *ngIf="trailer">
                        <i [routerLink]="['/TrailerDetails/Index/', trailer.id ]"
                            style=" font-size: 1.3em; cursor: pointer;"
                            class="fas fa-fw fa-2x fa-arrow-circle-right"></i>
                    </span>
                </div>
                <div class="ibox-content slick" style="position:relative">
                    <table style="width:100%" class="table table-hover nowrap dtr-inline no-footer">
                        <tbody>
                            <tr>
                                <td>
                                    {{ 'general.type' | translate}}
                                </td>
                                <td>
                                    {{ trailer.type }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'general.identificationTag' | translate}}
                                </td>
                                <td>
                                    {{ trailer.identificationTag }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'general.assetLinkType' | translate}}
                                </td>
                                <td>
                                    {{ ('enums.assetLinkType.' +
                                    trailer.assetLinkType) | translate }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'general.brand' | translate}}
                                </td>
                                <td>
                                    {{ trailer.brand }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'general.model' | translate}}
                                </td>
                                <td>
                                    {{ trailer.model }}
                                </td>
                            </tr>
                            <tr *ngIf="trailer.assetLinkStartDate">
                                <td>
                                    {{ 'general.startDate' | translate}}
                                </td>
                                <td>
                                    {{ trailer.assetLinkStartDate | amDateFormat: 'LLL'  }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'general.endDate' | translate}}
                                </td>
                                <td>
                                    {{ trailer.assetLinkEndDate  | amDateFormat: 'LLL' }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="ibox-footer">
                    <button class="btn btn-danger" [disabled]="!trailer.isActive"
                        (click)="showModal(trailer?.assetLinkId)"><i class="fas fa-fw fa-trash-alt"></i>
                        {{'general.unlink' | translate }}</button>
                </div>
            </div>
        </div>
    </div>
</div>

<fh-trailer-chooser #trailerChooser (onSelect)="onSelect($event)"></fh-trailer-chooser>

<fh-delete-modal #deleteModal (onDelete)="deleteAssetTrailer($event)"></fh-delete-modal>