<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/AssetTypes/Overview">{{'general.assetType' | translate }}</a>
            </li>
            <li>
                <a href="/#/AssetTypes/Index/{{assetType?.id}}">{{assetType?.name}}</a>
            </li>
            <li class="active">
                <strong>{{'general.assetTypeDetails' | translate }}</strong>
            </li>
        </ol>
    </div>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="">
        <div class="col-lg-6">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fa fa-object-group"></i> {{'general.assetType' | translate }}
                    </h5>
                </div>
                <div class="ibox-content slick" style="position:relative">
                    <ngx-loading [show]="loadingAssetType"></ngx-loading>

                    <table *ngIf="assetType" style="width:100%" class="table table-hover nowrap dtr-inline no-footer">
                        <tbody>
                            <tr>
                                <td style="width:50%">
                                    {{ 'general.name' | translate}}
                                </td>
                                <td style="width:50%">
                                    {{ assetType?.name }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>


        </div>
        <form #userForm="ngForm" #formDir="ngForm">
            <div class="col-lg-6">
                <fh-error [error]="errorTest" [success]="successTest"></fh-error>

                <div class="ibox float-e-margins">
                    <div class="ibox-title">
                        <h5>
                            <i class="fa fa-object-group"></i> {{'general.tester' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content slick" style="position:relative">
                        <ngx-loading [show]="loadingTest"></ngx-loading>

                        <table *ngIf="assetType" style="width:100%"
                            class="table table-hover nowrap dtr-inline no-footer">
                            <tbody>
                                <tr>
                                    <td style="width:50%">
                                        {{ 'general.rawValue' | translate}}
                                    </td>
                                    <td style="width:50%">
                                        <input type="text" numberOnly name="value" class="form-control table-inline"
                                            [(ngModel)]="testValue">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="ibox-footer">
                        <button class="btn btn-primary" (click)="testCalibration()"><i
                                class="fa fa-fw fa-edit"></i>{{'general.test' | translate }}</button>
                    </div>
                </div>

                <div class="ibox float-e-margins">
                    <div class="ibox-title">
                        <h5>
                            <i class="fa fa-calculator"></i> {{'general.calibration' | translate }}
                        </h5>
                    </div>
                    <fh-calibration-details [calibrationPoints]="calibrationPoints" [formMode]="formMode">
                    </fh-calibration-details>
                    <fh-save-footer [allowDelete]="true" [valid]="!userForm.invalid" (onSave)="onSave()"
                        (setFormMode)="setFormMode($event)" [formMode]="formMode">
                    </fh-save-footer>
                </div>
            </div>
        </form>
    </div>
</div>