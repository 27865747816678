<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Accounts/Overview">{{'general.accounts' | translate }}</a>
            </li>
            <li>
                <a href="/#/AccountDetails/Index/{{account?.id}}">{{account?.name}}</a>
            </li>
            <li class="active">
                <strong>{{'menu.details' | translate }}</strong>
            </li>
        </ol>
    </div>
</div>

<div class="wrapper wrapper-content">

    <fh-error *ngFor="let error of hasError" [autoRemove]="false" [error]="error"></fh-error>
    <fh-error *ngFor="let success of hasSuccess" [autoRemove]="false" [success]="success"></fh-error>

    <div class="row" data-equalizer="">
        <div class="col-lg-4">

            <fh-error [autoRemove]="false" [error]="error" [success]="success"></fh-error>

            <form class="" #userForm="ngForm" action="#">
                <div class="ibox float-e-margins">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-database"></i> {{'general.accountDetails' | translate }}
                        </h5>
                    </div>

                    <div class="ibox-content slick" style="position:relative">
                        <ngx-loading [show]="loading"></ngx-loading>

                        <fh-account-details [account]="account" [formMode]="formMode"></fh-account-details>
                    </div>
                    <fh-save-footer [valid]="!userForm.invalid" [allowDelete]="true" (onSave)="onSave()"
                        (onDelete)="deleteAccount()" (setFormMode)="setFormMode($event)" [formMode]="formMode">
                    </fh-save-footer>
                </div>
            </form>
        </div>
        <div class=" col-lg-8">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-users"></i> {{'general.users' | translate }}
                    </h5>
                </div>
                <div class="ibox-content slick" style="position: relative; min-height:72px">
                    <ngx-loading [show]="loadingUsers"></ngx-loading>
                    <table style="width:100%" class="table table-hover nowrap dtr-inline no-footer">
                        <thead>
                            <tr>
                                <th style="width:0px">
                                </th>
                                <th style="width:100%">
                                    {{ 'general.name' | translate}}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let user of users; let i = index">
                                <td>
                                    <a class='btn btn-primary btn-outline btn-grid'
                                        href='/#/UserDetails/Index/{{user.id}}'>
                                        <i class='fas fa-fw fa-info'></i>
                                        <span class="hidden-sm hidden-xs">{{'general.details' | translate }}</span>
                                    </a>
                                </td>
                                <td>
                                    {{ user.name }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <form #userForm="ngForm" #formDir="ngForm">
                <fh-accountplatform-control [loading]="loadingAccountPlatforms" [accountPlatforms]="accountPlatforms" (onUpdate)="updateAccountPlatforms($event)"
                    (onSubmit)="createAccountPlatforms($event)" [formMode]="formModeAccountPlatforms">
                </fh-accountplatform-control>
            </form>
        </div>
    </div>
</div>
<fh-delete-modal #deleteModal (onDelete)="onAccountDelete($event)"></fh-delete-modal>