
export class Tag {

  public name: any;
  public type: any;
  public metrics: any;
  public parameter: any;
  public deviceSensorTypeId: any;
  public sensorCount: any;
  public isChanged = false;
  public isCustomDeviceSensorTypeId = false;
  public deviceSensorType: any;
}

export class TagSearch {
  public id: string;
  public name: string;
  public objectId: string;
  public objectType: TagObjectType;
  public objectName: string;
}

export class TagUsage {
  public id: string;
  public name: string;
  public useCount: number;
    nameWithCount: string;
}

export class TagPost {
  public name: string;
  public objectId: string;
  public objectType: TagObjectType;
}

export enum TagObjectType {
    Unknown = 0,
    Device = 1,
    Asset = 2,
    Driver = 3,
    User = 4,
    Sensor = 5,
    Customer = 6,
    AssetType = 7,
    Issue = 10
}
