import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Asset } from 'app/models/asset.model';
import { AssetTypeChooserComponent } from 'app/views/shared/usercontrols/assetTypeChooser.component';
import { FormMode } from 'app/common/enums';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
    selector: 'fh-assettype-module',
    templateUrl: 'assetTypeModule.template.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class AssetTypeModuleComponent {
    @Input() asset: Asset;
    @Input() loading: false;
    @Input() formMode: FormMode = FormMode.read;

    @Output() onSelect = new EventEmitter();

    @ViewChild('assetTypeChooser') assetTypeChooser: AssetTypeChooserComponent;

    constructor() {
    }

    updateAssetType(event) {
        this.onSelect.emit(event);
    }

    showChooser() {
        this.assetTypeChooser.showModal();
    }
}
