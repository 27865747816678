import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AssetService } from '../../services/asset/asset.service';
import { DeviceService } from '../../services/device/device.service';
import { tileLayer, latLng, circle, polygon, Map, marker, icon } from 'leaflet';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { FormMode } from 'app/common/enums';
import { Device } from 'app/models/device.model';
import { DeleteModalComponent } from '../shared/usercontrols/deleteModal.component';
import { DeviceChooserComponent } from './deviceChooser.component';
import { AssetDevice } from 'app/models/asset.model';
import { BsDaterangepickerConfig, BsDatepickerConfig } from 'ngx-bootstrap';

declare var L;

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
window['moment'] = Moment;
mTZ()

@Component({
    selector: 'fh-fh-asset-device-details',
    templateUrl: 'assetDeviceDetails.template.html'
})
export class AssetDeviceDetailsViewComponent implements OnInit {
    sub: any;
    asset: any;
    device: any;

    @ViewChild('deleteModal') deleteModal: DeleteModalComponent;
    @ViewChild('deviceChooser') deviceChooser: DeviceChooserComponent;

    formMode = FormMode.read;

    loadingDevice = false;
    loadingAsset = false;
    error: any;

    map: Map;
   defaultMap;

    options;
    marker;
    permissions: {};

    showHistory = false;

    unitHistory = [{
        name: 'Device 1',
        startDate: new Date(),
        endDate: new Date()
    }, {
        name: 'Device 2',
        startDate: new Date(),
        endDate: new Date()
    }, {
        name: 'Device 3',
        startDate: new Date(),
        endDate: new Date()
    }]

    linkItem: any = { id: null, to: null, from: new Date() };

    success: { statusText: string; success: string; };

    assetDeviceHistory: AssetDevice[];

    fromTime = new Date();
    toTime = undefined;

    // Datepicker
    public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
    loadingAssetDevices = false;


    constructor(private authentication: AuthenticationService, private assetService: AssetService, private deviceService: DeviceService, private route: ActivatedRoute) {
        this.dpConfig.containerClass = 'theme-default'; // or whatever color
        this.dpConfig.dateInputFormat = 'll';
        this.dpConfig.isAnimated = true;
    }

    ngOnInit() {
        this.permissions = this.authentication.permissions;

        this.loadingDevice = true;
        this.loadingAsset = true;

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            const assetFromService = this.assetService.getAssetById(id).subscribe(asset => {
                this.asset = asset;
                this.loadingAsset = false;

                if (asset.deviceId == null) {
                    this.loadingDevice = false;
                } else {
                    this.loadDevice();
                }

                this.loadAssetDevices();
            });


        });
    }

    loadAssetDevices() {
        this.loadingAssetDevices = true;
        const assetHistoryFromService = this.assetService.getAssetDevicesById(this.asset.id).subscribe(data => {
            this.assetDeviceHistory = data;
            this.loadingAssetDevices = false;
        });
    }

    loadDevice() {
        if (this.asset.deviceId != null) {
            const deviceFromService = this.deviceService.getDeviceById(this.asset.deviceId).subscribe(device => {
                this.device = device;

                this.loadingDevice = false;
            }, error => {
                this.error = error
                this.loadingDevice = false;
            });
        }
    }

    showAddPopup() {
        this.deviceChooser.showModal();
    }

    onSelect(event) {
        this.linkItem.id = event.id;
        this.linkItem.name = event.name;
    }

    onToDate(event) {
        console.log(event, 'todateset');

        if (this.toTime == null && event !== null) {
            this.toTime = new Date();
        }
    }

    resetToDate() {
        this.linkItem.to = null;
        this.toTime = null;
    }

    saveNew() {

        this.loadingDevice = true;

        const fromTime = Moment(this.linkItem.from)
            .hours(Moment(this.fromTime).hours())
            .minutes(Moment(this.fromTime).minutes())
            .seconds(0)
            .toDate();

        let toTime = null;
        if (this.linkItem.to) {

            toTime = Moment(this.linkItem.to)
                .hours(Moment(this.toTime).hours())
                .minutes(Moment(this.toTime).minutes())
                .seconds(0)
                .toDate();

        }

        this.assetService.linkDevice(this.asset.id, this.linkItem.id, fromTime, toTime).subscribe(result => {
            this.device = null;
            this.loadingDevice = false;

            this.success = {
                statusText: 'Success',
                success: 'Device is successfull unlinked from asset'
            };

            this.loadDevice();
            this.loadAssetDevices();

            this.error = null;

        }, error => {
            this.error = error
            this.loadingDevice = false;
        });
    }


    unlinkDevice(deviceId) {
        this.deleteModal.showModal(deviceId);
    }

    onDeviceUnlink(event) {
        this.deleteModal.hideModal();

        this.loadingDevice = true;
        this.asset.deviceId = null;

        this.assetService.unLinkDevice(this.asset.id).subscribe(result => {
            this.device = null;
            this.loadingDevice = false;

            this.success = {
                statusText: 'Success',
                success: 'Device is successfull unlinked from asset'
            };

            this.loadDevice();
            this.loadAssetDevices();

            this.error = null;

        }, error => {
            this.error = error
            this.loadingDevice = false;
        });
    }
}
