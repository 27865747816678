import { Component, Inject } from '@angular/core';
import { NgModule, HostListener } from '@angular/core'
import { Http } from '@angular/http';
import { Router, ActivatedRoute } from '@angular/router';
import { appDate, appVersion } from '../../app.version'
import { FormsModule } from '@angular/forms';

import { AuthenticationService } from '../../services/authentication/authentication.service';
import { UserService } from '../../services/users/user.service';
import { TweenMax } from 'gsap';

import { AuthenticateResult } from '../../models/authenticateresult.model';
import { TranslateService } from '@ngx-translate/core';
import { AuthGuard } from '../../auth-guard';
import { environment } from 'environments/environment';
import { isUndefined } from 'util';

import { BsLocaleService } from 'ngx-bootstrap';
import { ColorService } from 'app/services/common/color.service';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
window['moment'] = Moment;
mTZ()

@Component({
    templateUrl: './login.template.html',
    styleUrls: ['./login.template.css'],
})
export class LoginComponent {

    appVersion: string;
    UserName = '';
    Password = '';
    error: string;
    data: string;
    returnUrl;
    subscription;
    param = null;
    redirect = '';
    loading = false;
    errorLoggedIn = false;
    errorExtended: string;
    ssoEnabled = false;

    constructor(private localeService: BsLocaleService, private translate: TranslateService, private colorService: ColorService, private route: ActivatedRoute, private router: Router, private http: Http, private authenticationService: AuthenticationService, private userService: UserService, private authGuard: AuthGuard) {
        const that = this;
        this.ssoEnabled = environment.SSOEnabled;

        const isWaslDomain = window.location.href.indexOf('wasl') !== -1;

        const loginImage = isWaslDomain ? this.colorService.getLoginImage('wasl') : this.colorService.getLoginImage('360');

        const css = '.site_login_logo { display: inline-flex; width: ' + loginImage.width + 'px; height: ' + loginImage.height + 'px; background: url(data:image/png;base64,' + loginImage.url + ')!important;} ';

        const head = document.getElementsByTagName('head')[0];
        const style = document.createElement('style');
        style.type = 'text/css';
        style.appendChild(document.createTextNode(css));

        head.appendChild(style);

        this.subscription = route.queryParams.subscribe((queryParam: any) => {
            that.param = queryParam['prev'];
            that.redirect = queryParam['redirect'];
        });

        this.appVersion = environment.version;
    }

    onMouseMove(event) {

        const e = event;
        // Mouse Move 3d Effect

        const perX = (e.clientX / $(window).width()) - 0.5;
        const perY = (e.clientY / $(window).height()) - 0.5;
        // TweenMax.to('.effect-3d-element', 0.4, { rotationY: 5 * perX, rotationX: 5 * perY, transformPerspective: 1000, transformOrigin: 'center' })
    }

    clicked(event) {
        console.log('Clicked login -> Authenticating');
        this.loading = true;

        this.authenticationService
            .performLogin(this.UserName, this.Password)
            .subscribe((result) => {
                this.loading = false;
                if (result !== null) {
                    this.loginCompleted(result);
                } else {
                    this.error = ('Error while logging in');
                }

            }, (error) => {
                this.errorLoggedIn = true;
                console.log(error);
                if (error.status === 401) {
                    this.error = ('Error while logging in');
                } else {
                    this.error = ('Unable to connect to api');
                }

                this.errorExtended = error.message + ' : ' + error.statusText;
                this.loading = false;
            });
    }

    private logError(err) {
        console.error('There was an error: ' + err);
    }

    private loginCompleted(authenticateResult: AuthenticateResult) {
        this.loading = true;

        if (this.translate.currentLang !== authenticateResult.user.language) {
            console.log('Use user language ' + authenticateResult.user.language);
            this.translate.use(authenticateResult.user.language);
            Moment.locale(authenticateResult.user.language);
            this.localeService.use(authenticateResult.user.language);
        }

        const newUrl = this.redirect;
        this.redirect ? this.router.navigate([newUrl]) : this.router.navigate(['/']);
        /*
                this.userService.getUser()
                    .subscribe((result) => {
                        if (result) {
                            console.log('2 loginCompleted ' + this.param);
                            this.router.navigate(['/']);
                        }
                    }, (error) => {
                        this.error = error;
                        console.log(error);
                        setTimeout(() => {
                            this.router.navigate(['/']);
                        }, 3000);
                    });
                    */
    }
}
