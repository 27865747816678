<div class="ibox-content slick">
    <table style="width:100%" class="table table-hover nowrap dtr-inline no-footer">
        <tbody *ngIf="!assetType">
            <tr>
                <td colspan="4">
                    {{ 'general.noAssetType' | translate}}
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="assetType">
            <tr>
                <td style="width:50%">
                    {{ 'general.name' | translate}}
                    <span class="error" *ngIf="name.invalid && formMode != 1">*</span>
                </td>
                <td style="width:50%">
                    <input type="text" [readonly]="formMode == 1" name="name" class="form-control table-inline"
                        [(ngModel)]="assetType.name" required #name="ngModel">
                </td>
            </tr>
            <tr>
                <td>
                    {{ 'general.class' | translate}}
                    <span class="error" *ngIf="assetClass.invalid && formMode != 1">*</span>
                </td>
                <td>
                    <select [disabled]="formMode == 1" [(ngModel)]="assetType.assetClass" name="assetClass"
                        class="form-control table-inline" #assetClass="ngModel">
                        <option [ngValue]="1">{{ 'enums.assetClass.1' | translate}}</option>
                        <option [ngValue]="2">{{ 'enums.assetClass.2'| translate}}</option>
                    </select>
                </td>
            </tr>
            <tr>
                <td>
                    {{ 'general.category' | translate}}
                    <span class="error" *ngIf="category.invalid && formMode != 1">*</span>
                </td>
                <td>
                    <select [disabled]="formMode == 1" [(ngModel)]="assetType.category" name="category"
                        class="form-control table-inline" #category="ngModel">
                        <option [ngValue]="0">{{ 'enums.assetCategory.0' | translate}}</option>
                        <option [ngValue]="1">{{ 'enums.assetCategory.1' | translate}}</option>
                        <option [ngValue]="2">{{ 'enums.assetCategory.2' | translate}}</option>
                        <option [ngValue]="3">{{ 'enums.assetCategory.3' | translate}}</option>
                        <option [ngValue]="4">{{ 'enums.assetCategory.4' | translate}}</option>
                        <option [ngValue]="5">{{ 'enums.assetCategory.5' | translate}}</option>
                        <option [ngValue]="6">{{ 'enums.assetCategory.6' | translate}}</option>
                    </select>
                </td>
            </tr>
            <tr>
                <td>
                    {{ 'general.referenceId' | translate}}
                </td>
                <td>
                    <input type="text" [readonly]="formMode == 1" name="referenceId" class="form-control table-inline"
                        [(ngModel)]="assetType.referenceId" #referenceId="ngModel">
                </td>
            </tr>
            <tr>
                <td>
                    {{ 'general.brand' | translate}}
                    <span class="error" *ngIf="brand.invalid && formMode != 1">*</span>
                </td>
                <td>
                    <input type="text" [readonly]="formMode == 1" name="brand" class="form-control table-inline"
                        required [(ngModel)]="assetType.brand" #brand="ngModel">
                </td>
            </tr>
            <tr>
                <td>
                    {{ 'general.model' | translate}}
                    <span class="error" *ngIf="model.invalid && formMode != 1">*</span>
                </td>
                <td>
                    <input type="text" [readonly]="formMode == 1" name="model" class="form-control table-inline"
                        required [(ngModel)]="assetType.model" #model="ngModel">
                </td>
            </tr>
            <tr>
                <td style="width:50%">
                    {{ 'general.serie' | translate}}
                    <!-- <span class="error" *ngIf="brand.invalid && formMode != 1">*</span> -->
                </td>
                <td colspan="2" style="width:50%">
                    <input type="text" [readonly]="formMode == 1" name="serie" class="form-control table-inline"
                        [(ngModel)]="assetType.serie" #serie="ngModel">
                </td>
            </tr>
            <tr>
                <td style="width:50%">
                    {{ 'general.trim' | translate}}
                    <!-- <span class="error" *ngIf="brand.invalid && formMode != 1">*</span> -->
                </td>
                <td colspan="2" style="width:50%">
                    <input type="text" [readonly]="formMode == 1" name="trim" class="form-control table-inline"
                        [(ngModel)]="assetType.trim" #trim="ngModel">
                </td>
            </tr>
        </tbody>
    </table>
</div>