import { Component, OnDestroy, OnInit, ChangeDetectorRef, ViewEncapsulation, ChangeDetectionStrategy, } from '@angular/core';
import { Issue } from 'app/models/issue.model';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';

import { TranslatorService } from 'app/services/common/translator.service';
import { TranslateService } from '@ngx-translate/core';
import { Chart } from 'angular-highcharts';

import { DeviceTypeService } from 'app/services/devicetypes/devicetypes.service';
import { IssueTypeService } from 'app/services/issuetypes/issuetypes.service';
import { DeviceType } from 'app/models/devicetype.model';
import { IssueType } from 'app/models/issuetype.model';
import { DashboardService } from '../../services/dashboard/dashboard.service';
import { FhChartService } from '../../services/charts/charts.service';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { WorstDevices } from '../../models/dashboard.model';
import { ToastrService } from '../../../../node_modules/ngx-toastr';
import { Router, ActivatedRoute } from '../../../../node_modules/@angular/router';
import { BsLocaleService, BsDaterangepickerConfig } from 'ngx-bootstrap';
import { ColorService } from 'app/services/common/color.service';

import { DashboardNotification } from 'app/models/notification.model';
import { AccountService } from 'app/services/accounts/accounts.service';
import { AccountPlatformService } from 'app/services/platform/accountPlatform.service';

import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { CustomerService } from 'app/services/customers/customers.service';

import { trigger, transition, animate, style, state, group } from '@angular/animations'
// Timezone
import * as moment from 'moment-timezone';
import { TagService } from 'app/services/tag/tag.service';
import { TagUsage } from 'app/models/tag.model';
import { getTranslatedDropdownSettings, mapMultiDropdown, getDropdownSettings } from 'app/common/gridhelper';

@Component({
  selector: 'fh-dashboard',
  templateUrl: 'dashboard.template.html',
  styleUrls: ['dashboard.template.css'],
  providers: [FhChartService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('slideInOut', [
      state('in', style({ height: '*', opacity: 0.5 })),
      transition(':leave', [
        style({ height: '*', opacity: 1 }),
        group([
          animate(300, style({ height: 0 })),
          animate('200ms ease-in-out', style({ 'opacity': '0.5' })),
          animate('200ms ease-in', style({ transform: 'translateY(-100%)' }))
        ])
      ]),
      transition(':enter', [
        style({ height: '0', opacity: 0.5 }),
        group([
          animate(300, style({ height: '*' })),
          style({ transform: 'translateY(-100%)' }),
          animate('400ms ease-in-out', style({ 'opacity': '1' })),
          animate('200ms ease-in', style({ transform: 'translateY(0%)' }))
        ])
      ])
    ])
  ]
})
export class DashboardComponent implements OnDestroy, OnInit {

  chartPieIssueTypes: Chart;
  chartDistance: Chart;
  loadingIssuesPerType: boolean;
  loadingMessageCount: boolean;
  worstDevices: WorstDevices[] = [];
  today: number;
  start: number;
  daysForDayshboard: number;

  loadingPerDeviceChart: boolean;
  loadingPerIssueChart: boolean;
  loadingIssueCount: Boolean;
  issueTypesResult = [];
  deviceTypes: DeviceType[];
  issueTypes: IssueType[];
  progress = [];
  progress2 = [];

  deviceCountHistory = 0;
  healthyCountHistory = 0;
  issueCountHistory = 0;
  deviceTypesCountHistory = 0;

  healthyCountHistoryPercentage = 0;
  issueCountHistoryPercentage = 0;
  deviceCountHistoryPercentage = 0;
  deviceTypesCountHistoryPercentage = 0;

  issueTypesEnum;

  public nav: any;
  loadingIssues = false;
  deviceTypesCount = 0;
  issueCount = 0;
  healthyCount = 0;
  issues: Issue[];

  // Daterange
  public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
  daterangepickerModel: Date[];
  maxDate = new Date();

  private issueCountObservable = new Subject<number>();
  issueCountObservable$ = this.issueCountObservable.asObservable();

  loadingDevices = false;
  loadingCustomers = false;

  deviceCount = 0;

  chart1;
  chart2;
  chart3;
  chart4;

  chartHealthy;
  chartLocationType;
  deviceWithIssues = 0;
  deviceWithIssuesLastPeriod = 0;
  deviceWithIssuesHistory = 0;
  deviceWithIssuesPercentage = 0;
  deviceWithIssuesHistoryPercentage = 0;
  deviceWithIssuesMax: any;

  healthyDateSelection = 1;
  adminIssueCount = 0;
  adminAccountsWithoutIssues = 0;
  adminActiveAccounts = 0;
  adminActiveJobCount = 0;
  adminActiveUsers = 0;
  adminHistoryJobCount = 0;
  assetCount = 0;
  trailerCount = 0;
  driverCount = 0;
  allDeviceCount = 0;
  assetTypeCount = 0;
  assetTypesUsedCount = 0;
  healthyShowPie = true;

  issueTypesDateSelection = '1';
  issueTypesShowPie = true;
  customerResult: any[];
  dateDisplay: any;
  timezoneIana: string;
  IssuesPerTypeData: any;
  notifications: DashboardNotification[];
  permissions: {};
  isImpersonated: boolean;



  loadingAccounts: boolean;
  chartAccountUse: Chart;
  chartAccountWithoutIssues: Chart;

  selectedCustomers = [];
  selectedAccountPlatforms = [];

  dropdown_AccountPlatformsSettings: any;
  dropdown_CustomerNameSettings: any;

  accountPlatforms = [];
  customers = [];

  tags: TagUsage[];
  chartTags: Chart;

  showFilter = true;
  constructorName = this.constructor.name;

  public constructor(private router: Router,
    private accountService: AccountService,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private colors: ColorService,
    private tagService: TagService,
    private translateService: TranslateService,
    private translatorService: TranslatorService,
    private authentication: AuthenticationService,
    private chartService: FhChartService,
    private accountPlatformService: AccountPlatformService,
    private customerService: CustomerService,
    private cd: ChangeDetectorRef,
    private issueTypeService: IssueTypeService,
    private dashboardService: DashboardService) {
    this.timezoneIana = this.authenticationService.getTimeZoneIana()
  }

  ngOnInit() {

    this.loadingDevices = true;
    this.loadingIssues = true;
    this.loadingPerDeviceChart = true
    this.loadingPerIssueChart = true
    this.loadingIssueCount = true;
    this.loadingIssuesPerType = true;
    this.loadingCustomers = true;

    const that = this;

    this.tagService.getTagsWithUsage().subscribe(result => {
      this.tags = result;

      // let success = 3;
      // let noSuccess = 2;

      // const s = this.tags.filter(x => x.name === 'Wasl communication successfull');
      // const ns = this.tags.filter(x => x.name === 'Wasl communication unsuccessfull');

      // success = (s && s[0]) ? s[0].useCount : 0;
      // noSuccess = (ns && ns[0]) ? ns[0].useCount : 0;

      // let theData = [{
      //   name: this.translatorService.translate('dashboard.waslSuccessTitle'),
      //   innerSize: '60%',
      //   data: [{
      //     name: this.translatorService.translate('dashboard.waslSuccess'),
      //     y: success,
      //     sliced: false,
      //     selected: false,
      //     color: this.colors.theme.overwrite ? this.colors.theme.chartPositive : '#5BB862',
      //     events: {
      //       click: function (event) {
      //         const point = event.point as any;
      //         console.log(event);
      //         location.href = '/#/Tags/Wasl%20communication%20successfull';
      //       }
      //     }
      //   }, {
      //     name: this.translatorService.translate('dashboard.waslNoSuccess'),
      //     y: noSuccess,
      //     color: this.colors.theme.overwrite ? this.colors.theme.chartNegative : '#D8504F',
      //     events: {
      //       click: function (event) {
      //         const point = event.point as any;
      //         console.log(event);
      //         location.href = '/#/Tags/Wasl%20communication%20unsuccessfull';
      //       }
      //     }
      //   }]
      // }];

      const data = [];
      this.tags.forEach(tag => {

        data.push({
          name: tag.nameWithCount,
          y: tag.useCount,
          sliced: false,
          selected: false,
          events: {
            click: function (event) {
              that.router.navigate(['/Wasl/RegistrationStatus/' + tag.name]);
            }
          }
        });
      });

      const theData = [{
        name: this.translatorService.translate('dashboard.waslSuccessTitle'),
        innerSize: '60%',
        data: data
      }];

      this.chartTags = this.chartService.generateDonutChart(theData, '');
    });

    this.dropdown_AccountPlatformsSettings = getDropdownSettings();
    this.dropdown_CustomerNameSettings = getDropdownSettings();

    this.isImpersonated = this.authentication.getIsImpersonated();
    this.permissions = this.authentication.permissions;

    // Days in this period
    this.daysForDayshboard = 1;
    this.dateDisplay = this.translateService.instant('dates.today');
    this.today = moment().add(0, 'days').toDate().getTime();
    this.start = moment().add(0 - this.daysForDayshboard, 'days').toDate().getTime();

    this.translateService.get('general.date').subscribe(value => {

      this.dropdown_CustomerNameSettings = getTranslatedDropdownSettings(this.translateService, 'allCustomers', false);
      this.dropdown_AccountPlatformsSettings = getTranslatedDropdownSettings(this.translateService, 'allPlatforms', false);

      switch (this.route.snapshot.url.length > 1 && this.route.snapshot.url[1].path) {
        case 'Month':
          this.daysForDayshboard = 31;
          this.dateDisplay = this.translateService.instant('dates.lastmonth');
          break;

        case 'Default':
          this.daysForDayshboard = 7;
          this.dateDisplay = this.translateService.instant('dates.lastweek');
          break;

        case 'Day':
          this.daysForDayshboard = 1;
          this.dateDisplay = this.translateService.instant('dates.yesterday');
          break;

        case 'Week':
          this.daysForDayshboard = 7;
          this.dateDisplay = this.translateService.instant('dates.lastweek');
          break;

        case 'Yesterday':
          this.daysForDayshboard = 2;
          this.dateDisplay = this.translateService.instant('dates.yesterday');
          break;

        case 'Today':
          this.daysForDayshboard = 1;
          this.dateDisplay = this.translateService.instant('dates.today');
          break;

        default:
          this.daysForDayshboard = 1;
          this.dateDisplay = this.translateService.instant('dates.yesterday');
          break;
      }

      const today = moment().tz(this.authenticationService.getTimeZoneIana()).endOf('day').add(-1, 'day').toDate();
      this.today = today.getTime();

      const start = moment().tz(this.authenticationService.getTimeZoneIana()).startOf('day').add(0 - this.daysForDayshboard, 'days').toDate();
      this.start = start.getTime();

      this.daterangepickerModel = [start, today];

      this.dpConfig.containerClass = 'theme-default'; // or whatever color
      this.dpConfig.dateInputFormat = 'll';
      // range picker
      this.dpConfig.rangeSeparator = ' - ';
      this.dpConfig.rangeInputFormat = 'll';

      forkJoin(this.accountPlatformService.getAccountPlatforms(), this.customerService.getCustomers())
        .subscribe(this.loadAccountPlatform);

    });
  }

  clearSelectedCustomers() {
    this.selectedCustomers = [];
    this.fireFilter();
  }

  clearSelectedAccountPlatforms() {
    this.selectedAccountPlatforms = [];
    this.fireFilter();
  }

  dateChanged(event) {
    this.fireFilter();
  }

  fireFilter() {

    const accountPlatforms = this.selectedAccountPlatforms.map(item => item.id);
    const customerIds = this.selectedCustomers.map(item => item.id);

    this.fetchData(accountPlatforms, customerIds);
  }

  loadAccountPlatform = ([accountPlatforms, customers]) => {

    this.customers = mapMultiDropdown(customers);
    this.accountPlatforms = mapMultiDropdown(accountPlatforms);
    // '968f42ab-b1df-486a-9879-01e4ee9f7247'
    this.fetchData([], []);
  };

  fetchData(accountPlatformId, customerId): any {

    this.start = this.daterangepickerModel[0].getTime();
    this.today = this.daterangepickerModel[1].getTime();

    this.loadingDevices = true;
    this.loadingIssues = true;
    this.loadingPerDeviceChart = true
    this.loadingPerIssueChart = true
    this.loadingIssueCount = true;
    this.loadingIssuesPerType = true;
    this.loadingCustomers = true;

    if (this.permissions['IsAdministrator']) {
      // Fetch administrator dashboard
      const theCurrentCount = this.dashboardService.getAdminDashboard()
        .subscribe((response) => {

          this.adminIssueCount = response.issueCount;
          this.adminAccountsWithoutIssues = response.accountsWithoutIssues;
          this.adminActiveAccounts = response.activeAccounts;
          this.adminActiveJobCount = response.activeJobCount;
          this.adminActiveUsers = response.activeUsers;
          this.adminHistoryJobCount = response.historyJobCount;

          this.loadingDevices = false;
          this.cd.markForCheck();
        });

      this.loadingAccounts = true;
      this.accountService.getAccountsWithUseHistory().subscribe(resp => {
        this.loadingAccounts = false;

        this.generateChartAccountUse(resp);
        this.generateChartAccountWithoutIssues(resp);
      });

    } else {
      // Fetch user dashboard
      const theCurrentCount = this.dashboardService.getDashboard(this.start, this.today, accountPlatformId, customerId)
        .subscribe((response) => {

          this.deviceCount = response.deviceCount;
          this.allDeviceCount = response.allDeviceCount;
          this.assetCount = response.assetCount;
          this.trailerCount = response.trailerCount;
          this.issueCount = response.issueCount;
          this.healthyCount = response.healthyCount;
          this.deviceTypesCount = response.deviceTypesCount;
          this.worstDevices = response.worstDevices;

          this.driverCount = response.driverCount;
          this.assetTypeCount = response.assetTypeCount;
          this.assetTypesUsedCount = response.assetTypesUsedCount

          this.deviceCountHistory = response.deviceCountLastPeriod;
          this.issueCountHistory = response.issueCountLastPeriod;
          this.healthyCountHistory = response.healthyCountLastPeriod;
          this.deviceTypesCountHistory = response.deviceTypesCountLastPeriod;

          this.deviceWithIssues = response.deviceWithIssues;
          this.deviceWithIssuesMax = response.deviceWithIssuesMax;
          this.deviceWithIssuesHistory = response.deviceWithIssuesLastPeriod;

          this.deviceCountHistoryPercentage = this.deviceCountHistory > 0 ? Math.round(((this.deviceCount / this.deviceCountHistory) - 1) * 100) : 0;
          this.issueCountHistoryPercentage = this.issueCountHistory > 0 ? Math.round(((this.issueCount / this.issueCountHistory) - 1) * 100) : 0;
          this.healthyCountHistoryPercentage = this.healthyCountHistory > 0 ? Math.round(((this.healthyCount / this.healthyCountHistory) - 1) * 100) : 0;
          this.deviceTypesCountHistoryPercentage = this.deviceTypesCountHistory > 0 ? Math.round(((this.deviceTypesCount / this.deviceTypesCountHistory) - 1) * 100) : 0;
          this.deviceWithIssuesHistoryPercentage = this.deviceWithIssuesHistory > 0 ? Math.round(((this.deviceWithIssues / this.deviceWithIssuesHistory) - 1) * 100) : 0;

          this.loadingDevices = false;
          this.loadingIssues = false;

          // this.toastr.warning('There are new issues since your last visit, ' + this.deviceWithIssues + ' of these issues are critical. Click here to see them '
          //   , 'New issues', {
          //     progressBar: true,
          //     timeOut: 30000
          //   }).onTap.subscribe(x => {
          //     this.router.navigate(['/Issues/Devices']);
          //   });

          // Set donut chart

          this.generateLocationHealty(this.healthyDateSelection);

          this.cd.markForCheck();
        });

      this.dashboardService.getDashboardIssuesPerIssueType(this.start, this.today, accountPlatformId, customerId).subscribe(result => {
        this.IssuesPerTypeData = result;
        this.loadingIssuesPerType = false;
        this.generateIssuePerIssueTypeCharts(result, this.issueTypesDateSelection);
        this.generateIssueTypesChartBig(result);
        this.issueTypesResult = this.orderByIssueCount(result);

        this.cd.markForCheck();
      });

      this.dashboardService.getDashboardIssuesPerCustomer(this.start, this.today).subscribe(result => {
        this.loadingCustomers = false;
        this.customerResult = this.orderByIssueCount(result);

        this.cd.markForCheck();
      });


      this.dashboardService.getDashboardIssuesPerDeviceType(this.start, this.today, accountPlatformId, customerId).subscribe(result => {
        this.generateIssuePerDeviceTypeCharts(result);
        this.cd.markForCheck();
      });
      const locationsEnd = moment().tz(this.authenticationService.getTimeZoneIana()).endOf('day').add(-1, 'day').toDate().getTime()
      const locationsStart = moment().tz(this.authenticationService.getTimeZoneIana()).startOf('day').add(-1, 'month').toDate().getTime();

      this.loadingMessageCount = true;
      this.cd.markForCheck();

      this.dashboardService.getDashboardMessageCount(locationsStart, locationsEnd, accountPlatformId, customerId).subscribe(result => {
        this.generateLocationCount(result);
        this.generateDistanceCount(result);
        this.loadingMessageCount = false;
        this.cd.markForCheck();
      });

      this.loadingIssueCount = true;
      this.dashboardService.getDashboardIssueCount(locationsStart, locationsEnd, accountPlatformId, customerId).subscribe(result => {
        this.generateIssueCount(result);
        this.loadingIssueCount = false;
        this.cd.markForCheck();
      });

      this.dashboardService.getNotification().subscribe(result => {
        this.notifications = result;
        this.cd.markForCheck();
      });

      this.generateLocationType();
      this.cd.markForCheck();
    }
  }

  checkPositive(theNumber) {
    if (theNumber === Infinity) {
      return '^';
    }
    if (theNumber > 0) {
      return '+' + theNumber + '%';
    } else {
      return theNumber.toString() + '%';
    }
  }

  // Sorting

  orderBy(array: any[], prop: string) {
    return array.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
  }

  orderByIssueCount(array: any[]) {
    return array.sort((a, b) => a[1].issueCount > b[1].issueCount ? 1 : a[1].issueCount === b[1].issueCount ? 0 : -1);
  }

  // Charts

  reDrawHealthy() {
    this.generateLocationHealty(this.healthyDateSelection);
  }

  reDrawIssueTypes() {
    this.generateIssuePerIssueTypeCharts(this.IssuesPerTypeData, this.issueTypesDateSelection);
  }

  generateLocationHealty(dateSelectionHealthy) {

    const healthy = this.healthyCount;
    if (this.healthyShowPie) {

      const theData = [{
        name: this.translatorService.translate('dashboard.healthyfleetcharttitle'),
        innerSize: '60%',
        data: [{
          name: this.translatorService.translate('dashboard.healthy'),
          y: healthy,
          sliced: false,
          selected: false,
          color: this.colors.theme.overwrite ? this.colors.theme.chartPositive : '#5BB862',
        }, {
          name: this.translatorService.translate('dashboard.unhealthy'),
          y: 100 - healthy,
          color: this.colors.theme.overwrite ? this.colors.theme.chartNegative : '#D8504F',
        }]
      }];

      this.chartHealthy = this.chartService.generateDonutChart(theData, '%');
    } else {

      const theData = [{
        name: this.translatorService.translate('dashboard.healthyfleetcharttitle'),
        innerSize: '60%',
        data: [{
          name: this.translatorService.translate('dashboard.healthy'),
          y: healthy,
          color: this.colors.theme.overwrite ? this.colors.theme.chartPositive : '#5BB862',
        }, {
          name: this.translatorService.translate('dashboard.unhealthy'),
          y: 100 - healthy,
          color: this.colors.theme.overwrite ? this.colors.theme.chartNegative : '#D8504F',
        }]
      }];

      this.chartHealthy = this.chartService.generateStackedChart(theData, '%');
    }
  }

  generateChartAccountUse(data) {

    const accounts = [];
    const accountData = [];
    const theData = [];

    this.loadingAccounts = false;

    data.forEach(value => {
      if (accounts.indexOf(value.name) === -1) {
        accounts.push(value.name);
        accountData[value.name] = [];
      }
    });

    // tslint:disable-next-line:forin
    data.forEach(value => {
      accountData[value.name].push([new Date(value.date).getTime(), value.issueCount]);
    });

    for (const account of accounts) {

      theData.push({
        name: account,
        data: accountData[account],
        type: 'column',
        yAxis: 0,
      });
    };

    const yAxis = [{
      title: {
        text: 'Issues'
      }
    }];

    this.chartAccountUse = this.chartService.generateStackedChartDashboard(theData, yAxis);
    this.cd.markForCheck();
  }

  generateChartAccountWithoutIssues(data) {
    const accounts = [];
    const accountData = [];
    const theData = [];

    this.loadingAccounts = false;

    this.accountService.getAccounts().subscribe(
      resultData => {

        const accountsData = resultData.filter(x => x.status !== 0);

        accountsData.forEach(account => {
          if (accounts.indexOf(account.name) === -1) {
            accounts.push(account.name);
            accountData[account.name] = [];
          }
        });

        // tslint:disable-next-line:forin

        const dateEnd = moment().add(-1, 'days').startOf('day').toDate().getTime();
        const dateStart = moment().add(-35, 'days').startOf('day').toDate().getTime();

        let loop = new Date(dateStart);
        while (loop <= dateEnd) {

          accountsData.forEach(account => {
            // tslint:disable-next-line:triple-equals
            if (data.filter(x => x.name === account.name && new Date(x.date).toLocaleDateString() == loop.toLocaleDateString()).length === 0) {
              accountData[account.name].push([loop.getTime(), 1]);
            }
          });

          const newDate = loop.setDate(loop.getDate() + 1);
          loop = new Date(newDate);
        }

        for (const account of accounts) {

          theData.push({
            name: account,
            data: accountData[account],
            type: 'column',
            yAxis: 0,
          });
        };

        const yAxis = [{
          title: {
            text: 'Issues'
          }
        }];

        this.chartAccountWithoutIssues = this.chartService.generateStackedChartDashboardNoIssues(theData, yAxis);
        this.cd.markForCheck();
      }
    );
  }

  generateIssuePerIssueTypeCharts(issueTypes, issueTypesDateSelection) {

    if (this.issueTypesShowPie) {
      const theRealData2 = [];

      this.orderByIssueCount(issueTypes).reverse().slice(0, 4).forEach(issueType => {
        theRealData2.push([issueType[0], issueType[1].issueCount]);
      });

      const theData2 = [{ name: 'Issues', data: theRealData2 }]
      this.chartPieIssueTypes = this.chartService.generateDonutChart(theData2, '');
    } else {
      const theRealData2 = [];

      this.orderByIssueCount(issueTypes).reverse().slice(0, 4).forEach(issueType => {
        theRealData2.push([issueType[0], issueType[1].issueCount]);
      });

      const theData2 = [{ name: 'Issues', data: theRealData2 }]
      this.chartPieIssueTypes = this.chartService.generateColumnChart(theData2, '');
    }
  }

  generateIssueTypesChartBig(issueTypes) {
    this.issueTypeService.getIssueTypes().subscribe(defaultIssueTypes => {
      const categoryData = [];
      const drilldownData = [];
      issueTypes.forEach(issueType => {
        const issueTypeVar = defaultIssueTypes.find(x => x.id === issueType[1].issueId);
        const issueTypeCategory = issueTypeVar != null ? issueTypeVar.category : 'Undefined'

        if (!categoryData.find(x => x.name === issueTypeCategory)) {
          const categoryObject = {
            name: issueTypeCategory,
            y: issueType[1].issueCount,
            drilldown: issueTypeCategory
          }
          const drilldownObject = {
            id: issueTypeCategory,
            data: [[issueType[0], issueType[1].issueCount]]
          }
          categoryData.push(categoryObject);
          drilldownData.push(drilldownObject);
        } else {
          categoryData.find(x => x.name === issueTypeCategory).y += issueType[1].issueCount;
          drilldownData.find(x => x.id === issueTypeCategory).data.push([issueType[0], issueType[1].issueCount]);
        }
      });
      const categoryChartData = [{ name: 'Issue Categories', data: categoryData }]
      const drilldownChartData = { series: drilldownData }
      this.chart1 = this.chartService.generateColumnChart(categoryChartData, drilldownChartData);
      this.loadingPerIssueChart = false;
      this.cd.markForCheck();
      console.log('End analising chart');
    });
  }



  generateLocationType() {
    const theData = [{
      name: 'Location type',
      innerSize: '60%',
      data: [{
        name: 'Ignition off',
        y: 61.41,
        sliced: false,
        selected: false
      }, {
        name: 'Ignition on',
        y: 11.84
      }, {
        name: 'Events',
        y: 10.85
      }]
    }];

    this.chartLocationType = this.chartService.generateDonutChart(theData, '');
  }

  generateLocationCount(result) {
    const series1 = [];
    const series2 = [];

    // tslint:disable-next-line:forin
    result.forEach(value => {
      series1.push([value[0], value[1]]);
      series2.push([value[0], value[2]]);
    });

    const theData = [{
      name: 'Messages',
      data: series1,
      type: 'areaspline',
      yAxis: 0,
    }];

    const yAxis = [{
      title: {
        text: this.translateService.instant('general.messages')
      }
    }];

    this.chart3 = this.chartService.generateSplineArea(theData, yAxis, null, null, this.translateService.instant('general.messages'));
  }

  generateDistanceCount(result) {
    const series1 = [];
    const series2 = [];

    // tslint:disable-next-line:forin
    result.forEach(value => {
      series1.push([value[0], value[1]]);
      series2.push([value[0], value[2]]);
    });

    const theData = [{
      name: 'Distance',
      data: series2,
      type: 'areaspline',
      yAxis: 0,
    }];

    const yAxis = [{
      title: {
        text: this.translateService.instant('general.kmDriven'),
      }
    }];

    this.chartDistance = this.chartService.generateSplineArea(theData, yAxis, null, null, this.translateService.instant('general.kmDriven'));
  }


  generateIssueCount(result) {
    const theData = [{
      name: 'IssueCount',
      data: result,
      point: {
        events: {
          click: function () {
            const toMillisecond = (value = 0) => value * 1000;
            const endOfDay = this.x + toMillisecond(24 * 60 * 60);
            location.href = '/#/Issues/Overview?DateStart=' + this.x + '&DateEnd=' + endOfDay;
          }
        }
      }
    }];

    const yAxis = [{
      title: {
        text: this.translateService.instant('general.issues')
      }
    }];

    this.chart4 = this.chartService.generateSplineArea(theData, yAxis);
  }

  generateIssuePerDeviceTypeCharts(deviceTypes) {

    const handleDuplicates = (devices, newDevice) => {
      const i = devices.findIndex(id => id[0] === newDevice[0]);
      if (i >= 0) {
        devices[i][1] += newDevice[1];
        return [newDevice[1], devices];
      }
      return [newDevice[1], [...devices, newDevice]];
    }

    const issuesPerManufacturer = deviceTypes.map(id => id[1]).reduce((graphData, device) => {

      const i = graphData.findIndex(id => id.name === device.manufactorer);
      const deviceObject = [device.deviceType, device.issueCount];
      if (i >= 0) {
        const [count, devices] = handleDuplicates(graphData[i].data, deviceObject);
        graphData[i].data = devices;
        graphData[i].y += count;
        return graphData;
      }

      const _id = device.manufactorer.toLowerCase().replace(/\s/g, '');
      return [...graphData, { name: device.manufactorer, y: device.issueCount, id: _id, drilldown: _id, data: [deviceObject] }];
    }, []).sort((a, b) => a.name !== b.name ? a.name < b.name ? -1 : 1 : 0);

    const theSeries = [{ name: 'Issues', data: issuesPerManufacturer }];
    const theDrilldownData = { series: issuesPerManufacturer }

    this.chart2 = this.chartService.generateColumnChart(theSeries, theDrilldownData);

    this.loadingPerDeviceChart = false;
    console.log('End analising chart');
  }

  public ngOnDestroy(): any {
  }
}
