

import { Injectable } from '@angular/core';
import { LoggingService } from '../logging/logging.service';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../authentication/authentication.service';

import { DeviceTypeService } from 'app/services/devicetypes/devicetypes.service';
import { IssueTypeService } from 'app/services/issuetypes/issuetypes.service';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from '../device/device.service';
import { IssueService } from '../issues/issues.service';
import { PlatformService } from '../platform/platform.service';
import { AccountPlatformService } from '../platform/accountPlatform.service';

@Injectable()
export class CacheService {

    constructor(translate: TranslateService, private authenticationService: AuthenticationService , private accountPlatformService: AccountPlatformService, private platformService: PlatformService, private deviceService: DeviceService, private issueService: IssueService, private deviceTypeService: DeviceTypeService, private issueTypeService: IssueTypeService) {
    }

    clearCache() {
        this.issueTypeService.IssueTypes = [];
        this.deviceTypeService.DeviceTypes = [];
        this.platformService.Platforms = [];
        this.accountPlatformService.AccountPlatforms = [];
        this.deviceService.Devices = [];
        this.authenticationService.permissions = [];
    }
}
