<div class="ibox-content slick" style="position:relative">
    <table style="width:100%" class="table table-hover nowrap dtr-inline no-footer">
        <tbody *ngIf="!asset">
            <tr>
                <td colspan="4">
                    {{ 'general.noAsset' | translate}}
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="asset">
            <tr>
                <td style="width:50%">
                    {{ 'general.name' | translate}}
                    <span class="error" *ngIf="name.invalid && formMode != 1">*</span>
                </td>
                <td colspan="2" style="width:50%">
                    <input type="text" [readonly]="formMode == 1" name="name" class="form-control table-inline"
                        [(ngModel)]="asset.name" required minlength="3" #name="ngModel">
                </td>
            </tr>
            <tr *ngIf="asset?.assetClass == 2">
                <td style="width:50%">
                    {{ 'general.identificationTag' | translate}}
                </td>
                <td colspan="2" style="width:50%">
                    <input type="text" [readonly]="formMode == 1" name="identificationTag" class="form-control table-inline"
                        [(ngModel)]="asset.identificationTag" #identificationTag="ngModel">
                </td>
            </tr>
            <tr *ngIf="formMode == 1">
                <td style="width:50%">
                    {{ 'general.unitId' | translate}}
                </td>
                <td colspan="2" style="width:50%">
                    <input type="text" [readonly]="true" name="unitId" class="form-control table-inline"
                        [(ngModel)]="asset.unitId">
                </td>
            </tr>
            <tr>
                <td style="width:50%">
                    {{ 'general.code' | translate}}
                    <span class="error" *ngIf="code.invalid && formMode != 1">*</span>
                </td>
                <td colspan="2" style="width:50%">
                    <input type="text" [readonly]="formMode == 1" name="vin" class="form-control table-inline"
                        [(ngModel)]="asset.code" #code="ngModel">
                </td>
            </tr>
            <tr>
                <td style="width:50%">
                    {{ 'general.color' | translate}}
                    <span class="error" *ngIf="color.invalid && formMode != 1">*</span>
                </td>
                <td colspan="2" style="width:50%">
                    <input type="text" [readonly]="formMode == 1" name="color" class="form-control table-inline"
                        [(ngModel)]="asset.color" #color="ngModel">
                </td>
            </tr>
            <tr>
                <td style="width:50%">
                    {{ 'general.plate' | translate}}
                    <span class="error" *ngIf="plateNumber.invalid && formMode != 1">*</span>
                </td>
                <td colspan="2" style="width:50%">
                    <input type="text" [readonly]="formMode == 1" name="plate" class="form-control table-inline"
                        [(ngModel)]="asset.plateNumber" required #plateNumber="ngModel">
                </td>
            </tr>
            <tr>
                <td style="width:50%">
                    {{ 'general.year' | translate}}
                    <span class="error" *ngIf="year.invalid && formMode != 1">*</span>
                </td>
                <td colspan="2" style="width:50%">
                    <input type="text" [readonly]="formMode == 1" name="year" class="form-control table-inline"
                        [(ngModel)]="asset.year" #year="ngModel">
                </td>
            </tr>
            <tr>
                <td style="width:50%">
                    {{ 'general.vin' | translate}}
                    <span class="error" *ngIf="vin.invalid && formMode != 1">*</span>
                </td>
                <td colspan="2" style="width:50%">
                    <input type="text" [readonly]="formMode == 1" name="vin" class="form-control table-inline"
                        [(ngModel)]="asset.vin" #vin="ngModel">
                </td>
            </tr>
        </tbody>
    </table>
</div>