<div class="ibox-content slick" style="position:relative">
    <form #userForm="ngForm" #formDir="ngForm">
        <table style="width:100%" class="table table-hover nowrap dtr-inline no-footer">
            <thead>
                <tr>
                    <th>{{ 'general.rawValue' | translate }}</th>
                    <th>{{ 'general.actualValue' | translate }}</th>
                    <th style="width:0px; padding-top: 18px;">
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr style="background-color: #f5f5f5;" *ngIf="formMode != 1">
                    <td style="width:auto;">
                        <input name="rawValue" [readonly]="formMode == 1" [(ngModel)]="rawValue" numberOnly type="text"
                            class="form-control table-inline" required #rawValueProp="ngModel">
                    </td>
                    <td style="width:auto">
                        <input name="actualValue" [readonly]="formMode == 1" [(ngModel)]="actualValue" numberOnly
                            type="text" class="form-control table-inline" required #actualValueProp="ngModel">

                    </td>
                    <td style="width:0px; padding-top: 18px;">
                        <button class="btn btn-primary pull-right btn-grid" [disabled]="userForm.invalid"
                            *ngIf="formMode != 1" (click)="addRow(rawValueProp, actualValueProp)"><i
                                class="fa fa-plus"></i></button>
                    </td>
                </tr>
                <tr *ngFor="let calibration of calibrationPoints | orderBy : 'rawValue':false">
                    <td style=" width:auto;">
                        <input type="text" [readonly]="1" class="form-control table-inline"
                            [ngModelOptions]="{standalone: true}" numberOnly [(ngModel)]="calibration.rawValue">
                    </td>
                    <td style="width:auto">
                        <input type="text" [readonly]="1" class="form-control table-inline"
                            [ngModelOptions]="{standalone: true}" numberOnly [(ngModel)]="calibration.actualValue">

                    </td>
                    <td style="width:0px; padding-top: 18px;">
                        <button class="btn btn-primary pull-right btn-grid" (click)="removeRow(calibration)"
                            *ngIf="formMode != 1"><i class="fa fa-trash"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </form>
</div>