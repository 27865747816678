<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/">{{'general.settings' | translate }}</a>
            </li>
            <li class="active">
                <strong>{{'menu.calculationSettingsOverview' | translate }}</strong>
            </li>

            <span class="pull-right"><a href="https://360locate.com/docs/fleet-health/calculation-settings/" target="_blank">
                <i class="fa fa-question"></i></a></span>
        </ol>
    </div>
</div>

<div class="wrapper wrapper-content">
    <div class="row">
        <div class="col-lg-12">
            <div *ngIf='settings.length == 0' class="ibox float-e-margins">
                <!-- Title -->
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-sliders-h"></i> {{ 'menu.devicesettings' | translate }}
                    </h5>
                </div>
                <!-- Content -->
                <div class="ibox-content" style="position: relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    {{ 'grid.infoempty' | translate }}
                </div>
            </div>

            <fh-calculation-settings-overview-table title="general.generalSettings" [settings]="generalSettings"></fh-calculation-settings-overview-table>
            <fh-calculation-settings-overview-table title="general.accountSettings" [settings]="accountSettings"></fh-calculation-settings-overview-table>
            <fh-calculation-settings-overview-table title="general.platformSettings" [settings]="platformSettings"></fh-calculation-settings-overview-table>
            <fh-calculation-settings-overview-table title="general.deviceTypeSettings" [settings]="deviceTypeSettings"></fh-calculation-settings-overview-table>
            <fh-calculation-settings-overview-table title="general.customerSettings" [settings]="customerSettings"></fh-calculation-settings-overview-table>
            <fh-calculation-settings-overview-table title="general.deviceSettings" [settings]="deviceSettings"></fh-calculation-settings-overview-table>

        </div>
    </div>
</div>