import { Component, OnDestroy, OnInit, } from '@angular/core';
import { TagService } from 'app/services/tag/tag.service';
import { TagUsage } from 'app/models/tag.model';
import { Router } from '@angular/router';

@Component({
    selector: 'fh-tags',
    templateUrl: 'tags.component.html'
})
export class TagsViewComponent implements OnDestroy, OnInit {
    tags: TagUsage[] = [];
    loading = false;

    constructor(private tagService: TagService, private router: Router) {
    }

    ngOnDestroy(): void {
    }

    ngOnInit(): void {
        this.loading = true;
        this.tagService.getTagsWithUsage().subscribe(result => {
            this.tags = result;
            this.loading = false;
        });
    }

    onSelected(event) {
        console.log('Item selected and navigating: ' + event.name);

        this.router.navigate(['/Tags/' + event.name]);
    }
}
