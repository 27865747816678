import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AssetService } from '../../services/asset/asset.service';
import { DeviceService } from '../../services/device/device.service';
import { TagObjectType } from 'app/models/tag.model';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { FormMode } from 'app/common/enums';
import { Asset } from 'app/models/asset.model';

@Component({
    selector: 'fh-fh-trailer-details',
    templateUrl: 'trailerDetails.template.html'
})
export class TrailerDetailsViewComponent implements OnInit {
    sub: any;
    trailer: Asset = new Asset();
    device: any;
    tagType = TagObjectType.Asset.valueOf();

    loadingDevice = false;
    loadingTrailer = false;
    error: any;
    success: any;
    formMode = FormMode.read;
    options;
    marker;

    permissions: {};

    constructor(private authentication: AuthenticationService, private assetService: AssetService, private deviceService: DeviceService, private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.permissions = this.authentication.permissions;

        this.loadingDevice = true;
        this.loadingTrailer = true;

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            const assetFromService = this.assetService.getAssetById(id).subscribe(asset => {
                this.trailer = asset;
                this.loadingTrailer = false;

                if (this.trailer.deviceId) {

                    const deviceFromService = this.deviceService.getDeviceById(this.trailer.deviceId).subscribe(device => {
                        this.device = device;

                        this.loadingDevice = false;
                    }, error => {
                        this.error = error
                        this.loadingDevice = false;
                    });
                }
            });
        });
    }

    getAssetInfo() {
        this.loadingTrailer = true;

        const assetFromService = this.assetService.getAssetById(this.trailer.id).subscribe(asset => {
            this.trailer = asset;
            this.loadingTrailer = false;
        });
    }

    // Form

    onDelete() {
        this.error = 'Deleting is not implemented';
    }

    onSave() {
        this.loadingTrailer = true;

        this.assetService.updateAsset(this.trailer).subscribe(result => {
            console.log(result);
            this.loadingTrailer = false;
        }, error => {
            this.error = error
            this.loadingTrailer = false;
        });
    }

    setFormMode(mode) {
        this.formMode = mode;
    }

}
