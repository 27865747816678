
export class Manufacturer {

    public id: string;
    public code: string;
    public createdDate: Date;
    public modifiedDate: Date;
    public name: string;
    public referenceId: string;
}
