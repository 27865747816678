import { Component, Input, OnInit } from '@angular/core';
import { FormMode } from 'app/common/enums';
import { ControlContainer, NgForm } from '@angular/forms';
import { Driver } from 'app/models/driver.model';


@Component({
    selector: 'fh-driver-wasl-details',
    templateUrl: 'waslDriverDetails.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class WaslDriverDetailsComponent implements OnInit {

    @Input() driver: Driver;
    @Input() formMode: FormMode = FormMode.read;

    constructor() {
    }

    ngOnInit(): void {
    }

}

