<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Issues/Overview">{{'general.issues' | translate }}</a>
            </li>
            <li class="active">
                <a href="/#/Issues/Overview">{{'menu.issueoverview' | translate }}</a>
            </li>
        </ol>
       <fh-filter-toggler style="margin-left: auto;" [constructorName]="constructorName"
            (filterChanged)="showFilter = $event"></fh-filter-toggler>
    </div>
</div>

<div class="wrapper wrapper-content">

    <!--
    <div class="row">
        <div class="col-lg-12">
            <div class="alert alert-danger shadow">
                <strong>Danger!</strong> Indicates a dangerous or potentially negative action.
            </div>
        </div>
    </div>
-->
    <div class="row">
        <div class="col-lg-12" *ngIf="showFilter" [@slideInOut]>
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-filter"></i> {{'general.filter' | translate }}</h5>
                </div>
                <div class="ibox-content" style="position: relative">
                    <div class="col-sm-6">
                        <div class="form-group input-group" style="margin-bottom: 5px;">
                            <span class="input-group-addon">
                                <i class="fa fa-fw fa-calendar"></i>
                            </span>
                            <input placeholder="Daterangepicker" style="width:100%" placeholder="Datepicker" type="text"
                                [bsConfig]="dpConfig" class="form-control" bsDaterangepicker [minDate]="minDate"
                                [(ngModel)]="daterangepickerModel" (onHidden)="dateChanged($event)">
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group input-group" style="margin-bottom: 5px;">
                            <span class="input-group-addon">
                                <i class="fas fa-fw fa-user-tie"></i>
                            </span>
                            <angular2-multiselect [data]="customersMultiselect"
                                [settings]="dropdown_CustomerNameSettings" [(ngModel)]="selectedCustomers"
                                (onGroupSelect)="fireFilter($event)" (onGroupDeSelect)="fireFilter($event)"
                                (onSelect)="fireFilter($event)" (onDeSelect)="fireFilter($event)"
                                (onSelectAll)="fireFilter($event)" (onDeSelectAll)="fireFilter($event)">
                            </angular2-multiselect>
                            <span class="input-group-addon" *ngIf="selectedCustomers.length > 0"
                                (click)="clearSelectedCustomers()">
                                <i class="fas fa-fw fa-ban"></i>
                            </span>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group input-group" style="margin-bottom: 5px;">
                            <span class="input-group-addon">
                                <i class="fas fa-fw fa-memory"></i>
                            </span>
                            <angular2-multiselect [data]="devicesMultiselect" [(ngModel)]="selectedDeviceItems"
                                [settings]="dropdownSettings" (onSelect)="fireFilter($event)"
                                (onDeSelect)="fireFilter($event)">
                                <c-item>
                                    <ng-template let-item="item">
                                        <label style="color: #333;min-width: 150px;">{{item.itemName}}</label>
                                    </ng-template>
                                </c-item>
                            </angular2-multiselect>
                            <span class="input-group-addon" *ngIf="selectedDeviceItems.length > 0"
                                (click)="clearSelectedDevice()">
                                <i class="fas fa-fw fa-ban"></i>
                            </span>
                            <!-- <fh-l-select2 [(ngModel)]="dropdown_DeviceId" [data]="devices" [options]="options"
                                [disabled]="false" (valueChange)="fireFilter($event)"></fh-l-select2> -->
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group input-group" style="margin-bottom: 5px;">
                            <span class="input-group-addon">
                                <i class="fa fa-fw fa-satellite-dish"></i>
                            </span>
                            <angular2-multiselect [data]="deviceTypesMultiselect"
                                [settings]="dropdownSettingsDeviceTypes" [(ngModel)]="selectedDeviceTypes"
                                (onGroupSelect)="fireFilter($event)" (onGroupDeSelect)="fireFilter($event)"
                                (onSelect)="fireFilter($event)" (onDeSelect)="fireFilter($event)"
                                (onSelectAll)="fireFilter($event)" (onDeSelectAll)="fireFilter($event)">
                            </angular2-multiselect>
                            <span class="input-group-addon" *ngIf="selectedDeviceTypes.length > 0"
                                (click)="clearSelectedDeviceTypes()">
                                <i class="fas fa-fw fa-ban"></i>
                            </span>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group input-group" style="margin-bottom: 5px;">
                            <span class="input-group-addon">
                                <i class="fa fa-fw fa-fire"></i>
                            </span>
                            <select name="status" id="severitySelectBox" style="width:100%" class="form-control"
                                [(ngModel)]="dropdown_Severity" (change)="fireFilter($event)">
                                <option value=''>{{'general.allSeverities' | translate }}</option>
                                <option *ngFor="let severity of severities" [value]="severity.value">{{
                                    severity.translated }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group input-group" style="margin-bottom: 5px;">
                            <span class="input-group-addon">
                                <i class="fa fa-fw fa-car-crash"></i>
                            </span>
                            <angular2-multiselect [data]="issueTypesMultiselect" [(ngModel)]="selectedIssueTypes"
                                [settings]="dropdownIssueTypesSettings" (onSelect)="fireFilter($event)"
                                (onDeSelect)="fireFilter($event)" (onSelectAll)="fireFilter($event)"
                                (onGroupSelect)="fireFilter($event)" (onGroupDeSelect)="fireFilter($event)"
                                (onDeSelectAll)="fireFilter($event)">
                                <c-item>
                                    <ng-template let-item="item">
                                        <label style="color: #333;min-width: 150px;">{{item.itemName}}</label>
                                    </ng-template>
                                </c-item>
                            </angular2-multiselect>
                            <span class="input-group-addon hand" *ngIf="selectedIssueTypes.length > 0"
                                (click)="clearSelectedIssueTypes()">
                                <i class="fas fa-fw fa-ban"></i>
                            </span>
                        </div>
                    </div>
                    <div style="clear:both"></div>
                </div>
            </div>
        </div>

        <div class="col-md-12">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-car-crash"></i> {{'general.issues' | translate }}</h5>
                    <iboxseach [searchTerm]="searchTerm" (seachChanged)="seachChanged($event)"></iboxseach>
                </div>
                <div class="ibox-content slick" style="position: relative" *ngIf="languageLoaded">
                    <table id="dataTable" style="width:100%; min-height:50px;" datatable [dtOptions]="dtOptions"
                        class="table table-hover nowrap margin-bottom-0 dataTable dtr-inline">
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>