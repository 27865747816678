import { Component, Input, TemplateRef, ViewChild, Output, OnChanges } from '@angular/core';
import { FormMode, languages } from 'app/common/enums';
import { trigger, style, animate, transition } from '@angular/animations';
import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap';
import { EventEmitter } from '@angular/core';
import { DeviceService } from '../../../services/device/device.service';
import { UserRoleService } from '../../../services/users/userRole.service';
import { UserRole } from '../../../models/user.model';
import { UserService } from '../../../services/users/user.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { Router } from '@angular/router';
import { DeleteModalComponent } from './deleteModal.component';

@Component({
    selector: 'fh-user-details-editor',
    templateUrl: 'userEditor.template.html',
    animations: [
        trigger(
            'enterAnimation', [
            transition(':enter', [
                // css styles at start of transition
                style({ opacity: 0 }),

                // animation and styles at end of transition
                animate('0.5s', style({ opacity: 1 }))
            ]),
        ]
        )
    ],
})
export class UserEditorComponent implements OnChanges {

    @Input() user;
    @Input() loading;
    @Output() onDeleted = new EventEmitter();
    @Output() onRoleChanged = new EventEmitter();
    @ViewChild('deleteModal') deleteModal: DeleteModalComponent;

    messageStatus = 'alert-success';

    formMode: FormMode = FormMode.read;
    userRoles: UserRole[];
    languages: { name: string; path: any; }[];
    isMessage: boolean;
    updateMessage: string;
    permissions: {};
    activeUserId: string;
    error: any;

    constructor(private router: Router, private translateServe: TranslateService, private userRoleService: UserRoleService, private userService: UserService, private authenticationService: AuthenticationService) {
        this.permissions = this.authenticationService.permissions;
        this.activeUserId = this.authenticationService.getUserId();

        this.userRoleService.getUserRoles().subscribe(userRoles => {
            this.userRoles = userRoles;
        });

        this.languages = Object.keys(languages)
            .filter(k => typeof languages[k] === 'string')
            .map(n => ({ name: n, path: languages[n] }));
    }

    ngOnChanges(): void {
    }

    changeMode(formMode: FormMode) {
        this.formMode = formMode;
    }

    resetPassword() {
        this.userService.resetPassword(this.user.id).subscribe(result => {

            this.changeMode(FormMode.read);

            if (result === true) {
                this.isMessage = true;
                this.messageStatus = 'alert-success';
                this.updateMessage = this.translateServe.instant('login.passwordResetSucces');
            } else {
                this.isMessage = true;
                this.messageStatus = 'alert-warning';
                this.updateMessage = this.translateServe.instant('login.passwordResetFailed');
            }
        });
    }

    impersonate() {
        this.userService.getTokenForUser(this.user.id).subscribe(result => {
            this.authenticationService.setImpersonationToken(this.user, result);
        });
    }

    save() {
        this.userService.updateUserProfile(this.user).subscribe(result => {

            const userRole = this.userRoles.filter(x => x.id === result.userRoleId);
            if (userRole.length === 1) {
                this.user.userRoleName = userRole[0].name;
            }

            if (result === false) {
                this.isMessage = true;
                this.messageStatus = 'alert-warning';
                this.updateMessage = this.translateServe.instant('general.errorUpdate');

            } else {
                this.isMessage = true;
                this.messageStatus = 'alert-success';
                this.updateMessage = this.translateServe.instant('general.updateUser');
            }

            this.changeMode(FormMode.read);
        });

        this.onRoleChanged.emit(this.user);
    }

    deleteDevice(): void {
        this.userService.deleteUser(this.user.id).subscribe(result => {
            this.onDeleted.emit(this.user);

            if (result === true) {

                this.router.navigate(['/Users/Overview']);
            } else {
                this.isMessage = true;
                this.messageStatus = 'alert-warning';
                this.updateMessage = this.translateServe.instant('general.errorUpdate');
            }
        }, error => {
            this.hideModal();
            this.error = error;
            this.isMessage = true;
            this.messageStatus = 'alert-warning';
            this.updateMessage = this.translateServe.instant('general.errorUpdate');
        });
    }

    roleChagned() {
        this.onRoleChanged.emit(this.user);
    }

    showModal() {
        this.deleteModal.showModal(null);
    }

    hideModal() {
        this.deleteModal.hideModal();
    }
}
