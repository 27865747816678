import * as moment from 'moment';
import { CalculationSettingsService } from './../../services/calculationsettings/calculationsettings.service';
import { Component, OnInit, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Subject } from 'rxjs/Subject';
import { ActivatedRoute, Router } from '@angular/router';
import { Device } from 'app/models/device.model';
import { tileLayer, latLng, circle, polygon, Map, marker, icon, TileLayer } from 'leaflet';
import { Subscription } from 'rxjs/Subscription';
import { Issue, IssueJson } from 'app/models/issue.model';
import { IssueService } from 'app/services/issues/issues.service';
import { DeviceService } from 'app/services/device/device.service';
import { DeviceTypeService } from 'app/services/devicetypes/devicetypes.service';
import { IssueTypeService } from 'app/services/issuetypes/issuetypes.service';
import { IssueType } from 'app/models/issuetype.model';
import { DeviceType } from 'app/models/devicetype.model';
import { ResolutionTypeService } from '../../services/resolutiontypes/resolutiontypes.service';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { MapService } from '../../services/common/map.service';
import { IssueTypeSettings, CalculationSettings } from '../../models/calculationsettings.model';
import { IssueDetailsChartViewComponent } from './issueDetailsChart.component';
import { DeviceSensorsService } from 'app/services/sensors/deviceSensors.service';
import { DeviceSensorType } from 'app/models/sensor.model';
import { TranslateService } from '@ngx-translate/core';
import { formatViolationThreshold } from 'app/services/common/functions.service';
import { IssueTypeEnums, Uom } from 'app/common/enums';
import { TagObjectType } from 'app/models/tag.model';
import { getMapProviders, createMapOptions } from 'app/common/leafletGlobals';

declare var L;
declare var HeatmapOverlay;
declare var PruneCluster;
declare var PruneClusterForLeaflet;

@Component({
    selector: 'fh-fh-issue-details',
    templateUrl: 'issueDetails.template.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [MapService],
})
export class IssueDetailsViewComponent implements OnInit {
    @ViewChild(IssueDetailsChartViewComponent)
    private chartChild: IssueDetailsChartViewComponent;

    tagType = TagObjectType.Issue.valueOf();

    leafletView: any;
    markers: any;
    googleHybrid;
    googleMaps: TileLayer;
    noDataFound = false;
    mapFullWith = false;
    loadingMapData: boolean;
    geoJson: any;
    defaultMap;
    hasAdvice: any;
    hasSchedule: any;
    issueDescription: string;
    resolutionDescription: string;
    renderDateTime: number;
    issueTypes: IssueType[];
    deviceTypes: DeviceType[];
    issue: Issue;
    device: Device;
    hasIssueTypeCalculationEnabled = true;

    sub: Subscription;
    loading = false;
    options;
    layersControl;
    map: Map;
    nearbyDevices;
    marker;

    deviceType: DeviceType;
    issueType: IssueType;
    issueUoM: string;

    threshold: number;
    outdatedThreshold: boolean;

    mapData = {
        data: []
    };

    heatmapLayer = new HeatmapOverlay({
        radius: 15,
        maxOpacity: 0.8,
        scaleRadius: false,
        useLocalExtrema: true,
        latField: 'lat',
        lngField: 'lng',
        valueField: 'count'
    });

    outdatedUpdate: Date;
    loadingSettings: boolean;
    loadingDevice: boolean;
    duration: moment.Duration;
    thresholdUpper: number;
    thresholdLower: number;
    violationDuration: moment.Duration;
    violationThresholdLower: number;
    violationThresholdUpper: number;
    thresholdHasBounds: any;
    trips: any;
    tripPolyLine: any;
    issuePolyLine: any;
    geoJsonData: any;
    permissions: {};
    setting: IssueTypeSettings;
    hasIssueTypeCalculationSource = 0;
    postSetting: CalculationSettings;
    sensorTypes: DeviceSensorType[];
    maps: { 'mapTiler': any; 'Google': any; 'Hybrid': any; };

    constructor(private cd: ChangeDetectorRef, private theMapService: MapService, private http: HttpClient, private deviceService: DeviceService,
        private issueService: IssueService, private route: ActivatedRoute, private router: Router, private authentication: AuthenticationService,
        private calculationSettingsService: CalculationSettingsService, private translateService: TranslateService) {
        this.issue = null;

        this.initMap();
    }

    // Function
    onEachFeature(feature, layer) {
        const that = this;
        layer.bindPopup('Issue');
        layer.on('click', () => {
            console.log(feature);
        });
    }

    setIssueJsonDetails(issueJson: IssueJson, issue: Issue): any {
        let issueDateDetected = false;
        const that = this;
        if (issueJson.geoJson) {
            this.geoJsonData = issueJson.geoJson;
        }
        // Set on map
        setTimeout(() => {
            if (issueJson.geoJson) {
                this.geoJsonData = issueJson.geoJson;

                if (this.geoJson) {
                    this.map.removeLayer(this.geoJson);
                }
                const pointList = [];
                const issuePointList = [];

                this.geoJson = L.geoJSON(JSON.parse(issueJson.geoJson), {
                    style: function (feature) {
                        if (feature.properties.fill) { return { color: feature.properties.fill }; }
                        if (feature.properties.stroke) { return { stroke: feature.properties.stroke }; }
                    },
                    onEachFeature: this.onEachFeature,
                    pointToLayer: function (feature, latlng) {

                        that.mapData.data.push({
                            lat: latlng.lat,
                            lng: latlng.lng,
                            count: 1
                        });

                        pointList.push(new L.LatLng(latlng.lat, latlng.lng));

                        if (issueDateDetected) {
                            issuePointList.push(new L.LatLng(latlng.lat, latlng.lng));
                            issueDateDetected = false;
                        }
                        if (moment(feature.properties.Timestamp).isSame(issue.issueDate)) {
                            issuePointList.push(new L.LatLng(latlng.lat, latlng.lng));
                            issueDateDetected = true;
                        }

                        const theMarker = new PruneCluster.Marker(latlng.lat, latlng.lng);
                        theMarker.category = Math.ceil(Math.random() * 6).toString();
                        theMarker.data.popup = 'device.name';
                        theMarker.data.title = latlng.lat + ':' + latlng.lng;
                        theMarker.data.device = 'device';
                        that.leafletView.RegisterMarker(theMarker);
                        return false;
                    },
                }).addTo(this.markers);

                this.loadingMapData = false;
                this.noDataFound = false;

                // Trip
                if (this.tripPolyLine) { this.map.removeLayer(this.tripPolyLine); }
                if (this.issuePolyLine) { this.map.removeLayer(this.issuePolyLine); }

                this.tripPolyLine = new L.Polyline(pointList, {
                    color: '#2490EA',
                    weight: 4,
                    opacity: 0.9,
                    smoothFactor: 1
                });

                this.issuePolyLine = new L.Polyline(issuePointList, {
                    color: '#FF0000',
                    weight: 4,
                    opacity: 0.9,
                    smoothFactor: 1
                });
                this.tripPolyLine.addTo(this.trips);

                // Issue types that should have a red plot on the issue date
                const plotIssueTypes = [IssueTypeEnums.OutlierDistance, IssueTypeEnums.OutlierTime, IssueTypeEnums.TooMuchDistancePerMessage];
                if (plotIssueTypes.indexOf(IssueTypeEnums[IssueTypeEnums[issue.issueType]]) !== -1) {
                    this.issuePolyLine.addTo(this.trips);
                }

                const bounds = this.tripPolyLine.getBounds();

                if (bounds.isValid()) {
                    this.map.fitBounds(bounds, { padding: [15, 15] });
                }

                this.heatmapLayer.setData(this.mapData);
                this.cd.markForCheck();
            } else {
                this.loadingMapData = false;
                this.noDataFound = true;
                this.cd.markForCheck();
            }
        }, 100);
        // End set on map
    }

    setIssueDetails(issue: Issue): any {

        if (issue == null) {
            this.router.navigate(['/Issues/Overview'])
        } else {
            this.issue = issue;
            this.deviceService.getDeviceById(issue.deviceId).subscribe(device => {
                this.device = device;
                this.loadingDevice = false;
                if (this.issue.sensorName) {
                    const sensor = device.sensors.find(x => x.name === this.issue.sensorName);
                    if (sensor != null) {
                        this.issueUoM = sensor.metrics;
                    }
                } else if (issue.issueTypeUomDisplay !== 0) {
                    this.issueUoM = issue.issueTypeUomDisplayDescription;
                } else {
                    this.issueUoM = issue.issueTypeUomDescription;
                }

                this.device.sensors.forEach(sensor => {
                    sensor.deviceSensorType = this.translateService.instant('enums.sensorTypes.' + sensor.deviceSensorTypeId);
                });

                this.cd.markForCheck();
            });
        }
    }

    updateSchedule(event) {
        // Update the schedule
        console.log('Update the schedule');
        this.renderDateTime = new Date().getTime();
    }

    changeIssueCalculation(event) {
        this.loadingDevice = true;
        this.device.hasIssueCalculationEnabled = !this.device.hasIssueCalculationEnabled;
        this.deviceService.saveIssueCalculation(this.device).subscribe(result => {
            this.loadingDevice = false;
            this.cd.markForCheck();
        });
    }

    changeIssueTypeCalculation(event) {
        this.loadingDevice = true;

        this.setting.isActive = !this.setting.isActive;

        const postSetting = new CalculationSettings();
        postSetting.level = 5;
        postSetting.issueTypeSettings = [this.setting];

        this.calculationSettingsService.saveCalculationSettings(postSetting, this.device.id).subscribe(result => {
            this.loadingDevice = false;
            this.hasIssueTypeCalculationSource = 5;
            this.cd.markForCheck();
        });
    }

    ngOnInit() {
        this.permissions = this.authentication.permissions;

        this.issue = new Issue;
        this.issue.id = '';
        this.loading = true;
        this.loadingSettings = true;
        this.loadingMapData = true;
        this.loadingDevice = true;

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.issueService.getIssueById(id).subscribe(issue => {
                this.setIssueDetails(issue);

                this.thresholdLower = formatViolationThreshold(this.translateService, issue.issueType, issue.lowerThreshold);
                this.thresholdUpper = (issue.upperThreshold !== 0) ? formatViolationThreshold(this.translateService, issue.issueType, issue.upperThreshold, true) : null;
                this.duration = moment.duration(issue.duration * 1000);
                if (issue.violationDuration) {
                    this.violationDuration = moment.duration(issue.violationDuration * 1000);
                }

                this.violationThresholdLower = formatViolationThreshold(this.translateService, issue.issueType, issue.violationLowerThreshold);
                this.violationThresholdUpper = issue.violationUpperThreshold;

                this.calculationSettingsService.getCalculationSettings(5, this.issue.deviceId).subscribe(result => {
                    if (result.issueTypeSettings) {
                        const setting = result.issueTypeSettings.filter(x => x.issueType === this.issue.issueType);
                        if (setting.length > 0) {
                            this.setting = setting[0];
                            if (setting) {
                                this.thresholdHasBounds = setting[0].upperThreshold !== 0;
                                this.hasIssueTypeCalculationEnabled = setting[0].isActive;
                                this.hasIssueTypeCalculationSource = setting[0].source;

                                if (new Date(setting[0].modifiedDate) > this.issue.issueDate) {
                                    this.outdatedThreshold = true;
                                    this.outdatedUpdate = setting[0].modifiedDate;
                                } else {
                                    this.outdatedThreshold = false;
                                }
                            }
                            this.cd.markForCheck();
                        }
                    }
                    this.loadingSettings = false;
                });
                this.loading = false;
                this.cd.markForCheck();
                this.issueService.getIssueJsonById(id).subscribe(result => {
                    this.setIssueJsonDetails(result, this.issue);
                    this.updatePath();
                    if (this.issue.issueType === IssueTypeEnums.NoCommunication ||
                        this.issue.issueType === IssueTypeEnums.NoCommunicationPowerLost) {
                        this.applyMarker();
                    }
                });
            });
        });
    }

    applyMarker() {
        let theMarker;
        const theIcon = icon({
            iconSize: [25, 41],
            iconAnchor: [13, 41],
            iconUrl: 'assets/marker-icon.png',
            shadowUrl: 'assets/marker-shadow.png'
        });
        if (this.map) {
            const lat = JSON.parse(this.geoJsonData).features[0].geometry.coordinates[1];
            const lon = JSON.parse(this.geoJsonData).features[0].geometry.coordinates[0];
            theMarker = marker([lat, lon], { icon: theIcon }).addTo(this.map);
            this.map.setView([lat, lon], 6);
        }
    }

    // When redrawing the chart on detail change
    updatePath() {
        setTimeout(() => {
            console.log('Forcing path update.. waited 200ms for data to fetch');
            if (this.chartChild) {
                this.chartChild.updateChanges();
                this.chartChild.reflowChart();
                this.cd.markForCheck();
            }
        }, 100);
    }

    // When flipping from full screen mode to normal mode
    reRenderMap() {
        this.loadingMapData = true;

        this.mapFullWith = !this.mapFullWith;

        setTimeout(() => {
            this.map.invalidateSize()
            if (this.chartChild) {
                this.chartChild.reflowChart();
            } else {
                console.log('No child to update chart');
            }
            this.loadingMapData = false;
            this.cd.markForCheck();
        }, 100);
    }

    // Leaflet
    initMap() {
        this.markers = L.featureGroup();
        this.trips = L.featureGroup();
        this.leafletView = new PruneClusterForLeaflet();

        this.maps = getMapProviders(L);

          const initMaps = [];
        initMaps.push(Object.values(this.maps)[0]);
        initMaps.push(this.trips);
        this.options = createMapOptions(L, initMaps);
    }

    onMapReady(map: Map) {
        this.map = map;

        const overlayMaps = {
            'Prunecluster': this.leafletView,
            'Heatmap': this.heatmapLayer,
            'Markers': this.markers,
            'Trips': this.trips
        };

        L.control.layers(this.maps, overlayMaps, { position: 'topleft' }).addTo(map);

        this.map.invalidateSize();
    }


    createIcon() {
        return L.icon({
            iconSize: [25, 41],
            iconAnchor: [13, 41],
            iconUrl: 'assets/marker-icon.png',
            shadowUrl: 'assets/marker-shadow.png'
        });
    }

    setPruneCluster() {

        const that = this;
        this.theMapService.setPruneCluster(this.leafletView);

        this.leafletView.PrepareLeafletMarker = function (theMarker, data, category) {
            // parse data to icon
            theMarker.setIcon(that.createIcon());

            if (theMarker.getPopup()) {
                theMarker.setPopupContent(data.title + ' - ' + category);
            } else {
                theMarker.bindPopup(data.title + ' - ' + category);
            }
        };
    }
}
