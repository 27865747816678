<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li class="active">
                <a href="/#/Schedule">{{'schedule.schedule' | translate }}</a>
            </li>
        </ol>
    </div>
</div>

<div class="wrapper wrapper-content">

    <div class="row">
        <div class="col-lg-12">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-filter"></i> {{'general.filter' | translate }}</h5>
                </div>
                <div class="ibox-content" style="position: relative">
                    <div class="col-md-6 col-lg-3">
                        <label style="padding-top: 8px;" (click)="showItemsInfuture =! showItemsInfuture; fireFilter()">
                            <i style="font-size: 1.2em; color: #777;" class="far fa-fw" [class.fa-check-square]="showItemsInfuture"
                                [class.fa-square]="!showItemsInfuture"></i>
                            {{'schedule.showinfuture' | translate }}</label>
                    </div>
                    <div class="col-md-6 col-lg-3">
                        <label style="padding-top: 8px;" (click)="showOtherOwners =! showOtherOwners; fireFilter()">
                            <i style="font-size: 1.2em; color: #777;" class="far fa-fw" [class.fa-check-square]="showOtherOwners"
                                [class.fa-square]="!showOtherOwners"></i>
                            {{'schedule.showotherowners' | translate }}</label>
                    </div>
                    <div class="col-md-6 col-lg-3">
                        <div class="form-inline">
                            <select name="status" style="width:100%;" id="owner" class="form-control">
                                <option value="">{{'general.allUsers' | translate }}</option>
                                <option *ngFor="let user of users" [value]="user.id">{{ user.name }}
                            </select>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3">
                        <div class="form-inline">
                            <select style="width:100%" class="form-control" [(ngModel)]="dropdown_IssueType" (change)="fireFilter()">
                                <option value=''>{{'general.allIssueTypes' | translate }}</option>
                                <option *ngFor="let issueType of issueTypes" [value]="issueType.id">{{ issueType.name
                                    }}</option>
                            </select>
                        </div>
                    </div>
                    <div style="clear:both"></div>
                </div>
            </div>


            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-calendar-alt"></i> {{'schedule.schedule' | translate }}</h5>
                  <iboxseach [searchTerm]="searchTerm" (seachChanged)="seachChanged($event)"></iboxseach>
                </div>
                <div class="ibox-content slick" style="position: relative" *ngIf="languageLoaded">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <table id="datatable" style="width:100%; min-height:50px;" datatable [dtOptions]="dtOptions" class="table table-hover nowrap margin-bottom-0 dataTable dtr-inline">
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>