<div class="row border-bottom white-bg dashboard-header">
    <div class="col-sm-12" style="padding: 15px">
        <h2>
            <i class="fas fa-fw fa-file-pdf"></i> {{'reporting.header' | translate }}</h2>
        {{'reporting.intro' | translate }}</div>
</div>


<div class="wrapper wrapper-content">
    <div class="row">
        <div class="col-lg-4">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-file-pdf"></i> {{'general.reporting' | translate }}</h5>
                </div>
                <div class="ibox-content">
                    <table class="table table-hover nowrap dtr-inline no-footer" style="width:100%; margin-bottom:0px ">
                        <tbody>
                            <tr *ngFor="let report of reports | orderBy : 'name':true">
                                <td style="width:auto">{{ report.name }}</td>
                                <td style="width:100px">
                                    <a class='btn btn-primary btn-outline btn-grid'>
                                        <i class='fas fa-fw fa-info'></i> {{'general.view' | translate }}</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-lg-8">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-file-pdf"></i> {{'general.reporting' | translate }}</h5>
                </div>
                <div class="ibox-content">
                    Contents
                </div>
            </div>
        </div>
    </div>
</div>