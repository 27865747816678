<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/SensorTypes/Overview">{{'general.sensorTypes' | translate }}</a>
            </li>
            <li>
                <a href="/#/SensorTypes/Index/{{sensorType?.id}}">{{sensorType?.brand}}</a>
            </li>
            <li class="active">
                <strong>{{'general.sensorTypeDetails' | translate }}</strong>
            </li>
        </ol>
    </div>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="" *ngIf="error || success">
        <div class="col-lg-12">
            <fh-error [error]="error" [success]="success"></fh-error>
        </div>
    </div>

    <div class="row" data-equalizer="">
        <form #userForm="ngForm" #formDir="ngForm">
            <div class="col-lg-6">
                <div class="ibox float-e-margins" style="position:relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-rss"></i> {{'general.sensorType' | translate }}</h5>
                    </div>
                    <fh-sensor-type-details [sensorType]="sensorType" [formMode]="formMode">
                    </fh-sensor-type-details>
                    <fh-save-footer [valid]="!userForm.invalid" (onSave)="onSave()" (onDelete)="onDelete()"
                        [allowDelete]="permissions['HasWasl']" (setFormMode)="setFormMode($event)"
                        [formMode]="formMode">
                    </fh-save-footer>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="ibox float-e-margins" style="position:relative">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-calculator"></i> {{'general.calibration' | translate }}</h5>
                    </div>
                    <fh-calibration-details *ngIf="sensorType.conversionFormula == ''"
                        [calibrationPoints]="sensorType.calibrationPoints" [formMode]="formMode">
                    </fh-calibration-details>
                    <div class="ibox-content" style="position:relative" *ngIf="sensorType.conversionFormula != ''">
                        {{'general.usingFormula' | translate }}
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>