import { Location } from './location.model';
import { Device } from './device.model';
import { DeviceType } from './devicetype.model';

export class Issue {

  public id: string;
  public device: Device;
  public deviceType: DeviceType;
  public issueTypeId: string;
  public date: Date;
  public issueDate: any;
  public createdDate: Date;
  public modifiedDate: Date;
  public issueType: any;
  public resolutionType: any;
  public lastPosition: Location;
  public deviceName: any;
  public deviceId: any;
  public issueTypeDescription: any;
  public resolutionDescription: any;
  public deviceTypeName: any;
  public modelName: any;
  public modelId: any;

  public hasTelemetrySummary: any;

  public hasDurationThreshold: any;

  public messagesWithoutGps: number;
  public messageCount: number;
  public ignitionCount: number;
  public hasMotion: any;
  public distanceInMeters: number;
  public distanceInKm: string;

  public position: any;
  public severity: any;
  public issueTypeUom: any;
  public issueTypeUomDescription: any;
  public issueTypeUomDisplay: any;
  public issueTypeUomDisplayDescription: any;
  public issueTypeEditableThresholds: any;
  public status: any;
  public statusDescription: any;
  public closedDate: Date;
  public issueDateFormatted: string;
  public sensorName: any;
  public deviceSensorTypeId: any;

  public duration: number;
  public lowerThreshold: number;
  public upperThreshold: number;
  public violationDuration?: number;
  public violationLowerThreshold?: number;
  public violationUpperThreshold?: number;
  public noGpsFixCount: number;
}

export class IssueJson {
  public id: any;
  public geoJson: any;
}

