import { Component, OnInit, AfterViewInit } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { AuditLogService } from '../../services/auditlog/auditlog.service';
import { AuditLog } from '../../models/auditlog.model';
import { UserService } from 'app/services/users/user.service';
import { AppUser } from 'app/models/user.model';

@Component({
    selector: 'fh-user-log',
    templateUrl: 'userLog.template.html',
    styleUrls: ['userLog.template.css']
})
export class UserLogViewComponent implements OnInit {
    sub;
    user: AppUser;
    userLog: AuditLog[];
    loading = false;


    constructor(private http: HttpClient, private auditLogService: AuditLogService, private route: ActivatedRoute, private router: Router) {
        this.user = null;
        this.userLog = [];
    }

    ngOnInit() {
        this.loading = true;
        this.user = new AppUser;
        this.user.id = '';
        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.auditLogService.getAuditLogByUser(id).subscribe(userLog => {
                this.userLog = userLog;
                this.loading = false;
            });
        });
    }
}
