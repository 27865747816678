<div class="row wrapper border-bottom white-bg page-heading">
        <div class="col-lg-12">
            <ol class="breadcrumb">
                <li>
                    <a href="/#/"><i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="active">
                    <strong>Error 404</strong>
                </li>
            </ol>
        </div>
    </div>
    <div class="wrapper wrapper-content">
    <div class="row">
        <div class="col-lg-12">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-info"></i> Error 404 : Page Not Found</h5>
                </div>
                <div class="ibox-content">
                        We are terribly sorry, but the URL you typed no longer exists. It might have been moved or deleted, or perhaps you mistyped
                        it. We suggest searching the site
                </div>
            </div>
        </div>
    </div>
</div>