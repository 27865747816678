<div class="ibox-footer">
    <ng-container *ngIf="showButtons; else showNotification">

        <button class="btn btn-primary" *ngIf="formMode == 1" (click)="sendFormMode(3)"><i class="fa fa-fw fa-edit"></i>
            {{'general.edit' | translate }}</button>

        <button class="btn btn-danger" *ngIf="formMode == 3" (click)="sendFormMode(1)"><i class="fa fa-fw fa-times"></i>
            {{'general.cancel' | translate }}</button>

        <button class="btn btn-primary" [disabled]="!valid" *ngIf="formMode == 3" (click)="save();"><i
                class="fa fa-fw fa-save"></i>
            {{'general.save' | translate }}</button>

        <button class="btn btn-primary" [disabled]="!valid" *ngIf="formMode == 2" (click)="insert();"><i
                class="fa fa-fw fa-save"></i>
            {{'general.save' | translate }}</button>

        <button class="btn btn-danger" *ngIf="allowDelete && formMode == 1" (click)="showDelete();"><i
                class="fa fa-fw fa-trash-alt"></i>
            {{'general.delete' | translate }}</button>
    </ng-container>

    <ng-template #showNotification>
        <span *ngIf="showButtonsNotification">
            {{showButtonsNotification}}
        </span>
    </ng-template>

    <ng-content></ng-content>
</div>

<fh-delete-modal *ngIf="allowDelete" #deleteModal (onDelete)="delete($event)"></fh-delete-modal>