import { Component, OnInit, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { Asset } from 'app/models/asset.model';
import { ActivatedRoute, Router } from '@angular/router';
import { AssetService } from 'app/services/asset/asset.service';
import { TripService } from 'app/services/trip/trip.service';
import { BsDaterangepickerConfig } from 'ngx-bootstrap';

// Timezone
import * as moment from 'moment-timezone';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import { DeviceService } from 'app/services/device/device.service';
import { Device } from 'app/models/device.model';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
import { getDefaultDpConfig, getGridLanguages } from 'app/common/gridhelper';
window['moment'] = Moment;
mTZ()

@Component({
    selector: 'fh-asset-messages',
    templateUrl: 'messages.template.html'
})
export class AssetMessageViewComponent implements OnInit, OnDestroy {
    loading: boolean;
    asset: any;
    sub: any;
    messages: any;

    // Datepicker
    public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
    to: any;
    from: any;
    daterangepickerModel: any[];
    permissions: {};
    languageLoaded: boolean;
    dtOptions;

    @ViewChild(DataTableDirective)
    datatableElement: DataTableDirective;
    device: Device;
    timezoneIana: string;
    token: string;

    error: any;
    success: any;


    constructor(private deviceService: DeviceService, private translateService: TranslateService, private authenticationService: AuthenticationService, private tripService: TripService, private assetService: AssetService, private route: ActivatedRoute, private router: Router) {

        this.timezoneIana = authenticationService.getTimeZoneIana();
        this.token = authenticationService.getAuthToken();

        this.to = moment().tz(this.authenticationService.getTimeZoneIana()).endOf('day').add(0, 'day').toDate();
        this.from = moment().tz(this.authenticationService.getTimeZoneIana()).startOf('day').add(0 - 7, 'days').toDate();

        this.daterangepickerModel = [this.from, this.to];

        this.dpConfig = getDefaultDpConfig();
    }

    ngOnInit() {
        this.loading = true;
        this.asset = new Asset;
        this.asset.id = ''

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];
            this.assetService.getAssetById(id).subscribe(asset => {
                this.asset = asset;

                this.loadDevice();

                if (this.asset == null) {
                    this.router.navigate(['/Assets/Overview'])
                }
            });
        });

        this.permissions = this.authenticationService.permissions;
    }

    loadDevice() {
        if (this.asset.deviceId != null) {
            const deviceFromService = this.deviceService.getDeviceById(this.asset.deviceId).subscribe(device => {
                this.device = device;

                this.initGrid();

                this.translateService.get('general.date').subscribe(value => {
                    this.languageLoaded = true;
                });
            }, error => {
                this.error = error
            });
        } else {
            this.error = 'No device found';
        }
    }

    editMode(isEditMode) {
        const toolbox = document.getElementById('grid-toolbox');

        console.log(isEditMode);
        if (isEditMode) {
            toolbox.classList.remove('hidden');
        } else {
            toolbox.classList.add('hidden');
        }
    }

    seachChanged(value) {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.search(value).draw();
        });
    }


    fireFilter(): void {
        console.log('fire update');
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {

            // Fire the request
            dtInstance.draw();
        });
    }

    ngOnDestroy(): void {
        // We remove the last function in the global ext search array so we do not add the fn each time the component is drawn
        // /!\ This is not the ideal solution as other components may add other search function in this array, so be careful when
        // handling this global variable
        $.fn['dataTable'].ext.search.pop();

        // Had to reset the array...
        $.fn['dataTable'].ext.search = [];
    }


    checkFilters() {
        this.fireFilter();
    }

    dateChanged(event) {
        const that = this;
        if (event !== null) {

            this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.ajax.url(that.tripService.getMessageUrl(this.device.id, this.daterangepickerModel[0].getTime(), this.daterangepickerModel[1].getTime())).load();
            });

            this.fireFilter();
        }
    }

    initGrid(): void {
        const that = this;

        this.dtOptions = {
            pagingType: 'simple_numbers',
            serverSide: false,
            processing: false,
            searchDelay: 500,
            deferRender: true,
            scrollX: true,
            colReorder: { fixedColumnsLeft: 1 },
            order: [[1, 'desc']],
            ajax: {
                url: that.tripService.getMessageUrl(this.device.id, this.daterangepickerModel[0].getTime(), this.daterangepickerModel[1].getTime()),
                type: 'GET',
                dataSrc: '',
                headers: {
                    'Authorization': 'Bearer ' + that.token
                }
            },
            initComplete: function (settings, json) {
                that.loading = false;
            },
            colVis: {
                restore: this.translateService.instant('general.restore'),
                showAll: this.translateService.instant('general.showAll'),
                showNone: this.translateService.instant('general.hideAll'),
            },
            columns: [
                {
                    name: 'Timestamp',
                    data: 'Timestamp',
                    title: this.translateService.instant('general.timestamp'),
                    render: function (data, type, row) {
                        const date = Moment.utc(data)['tz'](that.timezoneIana);
                        return date.format('lll')
                    },
                },
                {
                    name: 'ServerTimestamp',
                    data: 'ServerTimestamp',
                    title: this.translateService.instant('general.serverTimestamp'),
                    render: function (data, type, row) {
                        const date = Moment.utc(data)['tz'](that.timezoneIana);
                        return date.format('lll')
                    },
                },
                {
                    name: 'HasGpsFix',
                    data: 'HasGpsFix',
                    title: this.translateService.instant('general.hasGpsFix')
                },
                {
                    name: 'HasDriverCodeInformation',
                    data: 'HasDriverCodeInformation',
                    title: this.translateService.instant('general.hasDriverCodeInformation')
                }, {
                    name: 'Course',
                    data: 'Position.Course',
                    title: this.translateService.instant('general.course')
                },
                {
                    name: 'Latitude',
                    data: 'Position.Latitude',
                    title: this.translateService.instant('general.latitude')
                }, {
                    name: 'Longitude',
                    data: 'Position.Longitude',
                    title: this.translateService.instant('general.longitude')
                }, {
                    name: 'Satellites',
                    data: 'Position.Satellites',
                    title: this.translateService.instant('general.satellites')
                }, {
                    name: 'Speed',
                    data: 'Position.Speed',
                    title: this.translateService.instant('general.speed')
                },
                {
                    name: 'MessageType',
                    data: 'MessageType',
                    title: this.translateService.instant('general.messageType')
                },
                {
                    name: 'Lac',
                    data: 'GsmData.Lac',
                    title: this.translateService.instant('general.lac')
                }],
            pageLength: 10,
            lengthMenu: [[10, 25, 50, -1], [10, 25, 50, this.translateService.instant('general.all')]],
            language: getGridLanguages(this.translateService),
        };
    }
}


