<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Asset/Overview">{{'general.asset' | translate }}</a>
            </li>
            <li>
                <a href="/#/AssetDetails/Index/{{asset?.id}}">{{asset?.name}}</a>
            </li>
            <li class="active">
                <strong>{{'general.sensors' | translate }}</strong>
            </li>
        </ol>
    </div>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="">
        <div class="col-lg-12">
            <fh-error [error]="error" [success]="success"></fh-error>

            <form #sensorForm="ngForm" #formDir="ngForm">
                <div class="ibox float-e-margins">
                    <div class="ibox-title">
                        <h5>
                            <i class="fa fa-fw fa-sliders-h"></i> {{'general.sensors' | translate }}
                        </h5>
                    </div>

                    <div class="ibox-content slick" style="position:relative">
                        <ngx-loading [show]="loading"></ngx-loading>
                        <table style="width:100%" class="table table-hover nowrap dtr-inline no-footer">
                            <tbody>
                                <tr>
                                    <td style="width:50%">
                                        {{ 'general.sensorType' | translate}}
                                    </td>
                                    <td style="width:50%">
                                        <select required [disabled]="formMode == 1" [(ngModel)]="weightSensorTypeId"
                                            name="weightSensor" class="form-control table-inline"
                                            #weightSensor="ngModel">
                                            <option [ngValue]="null">{{ 'enums.assetSensorType.0' | translate}}
                                            </option>
                                            <option *ngFor="let sensorType of sensorTypes" [ngValue]="sensorType.id">
                                                {{sensorType.deviceSensorTypeName}} - {{ sensorType.brand }}
                                                - {{ sensorType.model }}
                                            </option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width:50%">
                                        {{ 'general.sensorPosition' | translate}}
                                    </td>
                                    <td style="width:50%">
                                        <select required [disabled]="formMode == 1" [(ngModel)]="weightSensorPositionId"
                                            name="weightSensorPosition" class="form-control table-inline"
                                            #weightSensorPosition="ngModel">
                                            <option [ngValue]="0">{{ 'enums.assetSensorPosition.0'| translate}}
                                            </option>
                                            <option [ngValue]="1">{{ 'enums.assetSensorPosition.1' | translate}}
                                            </option>
                                            <option [ngValue]="2">{{ 'enums.assetSensorPosition.2'| translate}}
                                            </option>
                                        </select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="ibox-footer">
                        <button [disabled]="sensorForm.invalid" class="btn btn-primary"
                            (click)="addSensor(weightSensor,weightSensorPosition)"><i class="fas fa-fw fa-plus"></i>
                            {{'general.add' | translate }}</button>
                    </div>
                </div>
            </form>
        </div>

    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-sliders-h"></i> {{'general.sensors' | translate }}</h5>
                </div>
                <div class="ibox-content slick" style="position: relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <table style="width:100%; min-height:50px;"
                        class="table table-hover nowrap margin-bottom-0 dataTable dtr-inline">
                        <thead>
                            <tr>
                                <th style="width: 33%">{{ 'general.sensorType' | translate}}</th>
                                <th style="width: 33%">{{ 'general.sensorPosition' | translate}}</th>
                                <th style="width: 33%">{{ 'general.formula' | translate}}</th>
                                <th style="width: 0px">{{ 'general.calibration' | translate}}</th>
                                <th style="width: 0px">{{ 'general.overWrite' | translate}}</th>
                                <th style="width: 0px"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngIf="sensorsList.length == 0">
                                <td colspan="6">{{ 'general.noDataFound' | translate}}</td>
                            </tr>
                            <tr *ngFor="let sensor of sensorsList">
                                <td> {{sensor.sensorType.brand}} {{sensor.sensorType.model}}</td>
                                <td> {{('enums.assetSensorPosition.' + sensor.sensorPosition) | translate}}</td>
                                <td> <span
                                        *ngIf="sensor.sensorType.conversionFormula != ''">{{ sensor.sensorType.conversionFormula }}</span>
                                    <span *ngIf="sensor.sensorType.conversionFormula == ''">-</span></td>
                                <td> <span
                                        *ngIf="sensor.sensorType.calibrationPoints && sensor.sensorType.calibrationPoints.length > 0">
                                        <button class="btn btn-default btn-grid"
                                            (click)="openModal(false, template, sensor)">
                                            <i class="fa fa-chart-area"></i></button>
                                    </span>
                                    <span
                                        *ngIf="!(sensor.sensorType.calibrationPoints && sensor.sensorType.calibrationPoints.length > 0)">
                                        -</span></td>
                                <td><span *ngIf="(sensor.calibrationPoints && sensor.calibrationPoints.length > 0)">
                                        <button class="btn btn-default btn-grid"
                                            (click)="openModal(true, template, sensor)">
                                            <i class="fa fa-chart-area"></i></button>
                                        <button class="btn btn-default btn-grid"
                                            (click)="sensor.calibrationPoints = []; onSave(sensor);">
                                            <i class="fa fa-trash-alt"></i> Reset</button>
                                    </span>
                                    <span *ngIf="!(sensor.calibrationPoints && sensor.calibrationPoints.length > 0)">
                                        <button class="btn btn-default btn-grid"
                                            (click)="openModal(true, template,  sensor)">
                                            <i class="fa fa-plus"></i></button></span>
                                </td>
                                <td> <button class="btn btn-danger  btn-grid" (click)="showDeleteModal(sensor)">
                                        <i class="fas fa-fw fa-trash-alt"></i>
                                        {{'general.delete' | translate }}</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #template>

    <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-chart-area"></i> {{'general.sensorValues' | translate }}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form #userForm="ngForm" #formDir="ngForm">
        <fh-error [error]="calibrationError" [success]="calibrationSucces"></fh-error>
        <div class="modal-body" style="padding: 15px" *ngIf="!allowEdit">
            {{'general.configurationBaseSensors' | translate }}
        </div>
        <div class="modal-body" style="padding: 15px" *ngIf="allowEdit">
            {{'general.configurationOverwriteSensors' | translate }}
        </div>
        <div class="modal-body" style="padding: 10px">

            <fh-calibration-details [calibrationPoints]="sensorValues" [formMode]="formModeCalibration">
            </fh-calibration-details>

            <fh-save-footer *ngIf="allowEdit" [valid]="!userForm.invalid" (onSave)="onSave(editSensor)"
                (onDelete)="onDelete(editSensor)" [allowDelete]="0" (setFormMode)="setFormModeCalibration($event)"
                [formMode]="formModeCalibration">
            </fh-save-footer>
        </div>
    </form>
</ng-template>

<fh-delete-modal #deleteModal (onDelete)="deleteSensor($event)"></fh-delete-modal>