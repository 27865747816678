import { PipeTransform, Pipe } from '@angular/core';
import { Asset } from 'app/models/asset.model';
import { AssetType } from 'app/models/assetType.model';

@Pipe({
    name: 'filterAssetType',
    pure: false
})
export class FilterAssetTypePipe implements PipeTransform {
    transform(items: AssetType[], asset: Asset): any {

        return items.filter(item => item.brand && item.brand.toUpperCase().indexOf(asset.brand.toUpperCase()) >= 0 && item.model && item.model.toUpperCase().indexOf(asset.model.toUpperCase()) >= 0);
    }
}
