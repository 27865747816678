import { Component, OnDestroy, OnInit, } from '@angular/core';
import { TagService } from 'app/services/tag/tag.service';
import { TagUsage, TagSearch } from 'app/models/tag.model';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'fh-taglist',
    templateUrl: 'taglist.component.html'
})
export class TagListViewComponent implements OnDestroy, OnInit {

    sub: any;
    searchFor: any;
    tags: TagSearch[] = [];
    loading = false;

    constructor(private tagService: TagService, private router: Router, private route: ActivatedRoute, ) {
    }

    ngOnDestroy(): void {
    }

    ngOnInit(): void {

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];
            this.searchFor = id;
            this.loading = true;
            this.tagService.getObjectsByName(id).subscribe(result => {
                console.log(result);
                this.tags = result;
                this.loading = false;
            })
        });
    }

    goTo(tag) {

        let path = '';

        switch (tag.objectType) {
            case 1:
                path = '/DeviceDetails/Index/';
                break;
            case 2:
                path = '/AssetDetails/Index/';
                break;
            case 3:
                path = '/DriverDetails/Index/';
                break;
            case 4:
                path = '/UserDetails/Index/';
                break;
            case 5:
                path = '/SensorDetails/Index/';
                break;
            case 6:
                path = '/CustomerDetails/Index/';
                break;
            case 10:
                path = '/IssueDetails/Index/';
                break;

            default:
                break;
        }

        const url = path + tag.objectId;
        console.log(url);
        this.router.navigate([url]);
    }
}
