<select [(ngModel)]="timezone" #select id="select" (ngModelChange)="timezoneValueChange.emit(timezone)" style="width: 100%" class="form-control" [disabled]="disabled">
  <ng-container *ngFor="let timezone of allTimezones;">
    <optgroup *ngIf="timezone.zones.length > 1" [label]="iso2country(timezone.iso)">
      <option *ngFor="let zone of timezone.zones" [value]="zone">
        {{ iso2country(timezone.iso) }} - {{ formatTimezoneString(zone) }}
        <span *ngIf="showOffset">{{offsetOfTimezone(zone)}}</span>
      </option>
    </optgroup>

    <option *ngIf="timezone.zones.length === 1" [value]="timezone.zones[0]">
      {{iso2country(timezone.iso)}}
      <span *ngIf="showOffset">{{offsetOfTimezone(timezone.zones[0])}}</span>
    </option>

  </ng-container>
</select>
