<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Devices/Overview">{{'general.devices' | translate }}</a>
            </li>
            <li>
                <a href="/#/DeviceDetails/Index/{{device?.id}}">{{device?.name}}</a>
            </li>
            <li class="active">
                <strong>{{'general.asset' | translate }}</strong>
            </li>
        </ol>
    </div>
</div>



<div class="wrapper wrapper-content">

    <div class="row" data-equalizer="">

        <div class="col-lg-6">

            <div *ngIf="!asset && !loading" class="alert alert-warning shadow">
                {{'general.noAssetLinked' | translate }}
            </div>

            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-car"></i> {{'general.asset' | translate }}</h5>
                    <span class="pull-right" *ngIf="asset">
                        <i [routerLink]="['/AssetDetails/Index/', asset.id ]"
                            style=" font-size: 1.3em; cursor: pointer;"
                            class="fas fa-fw fa-2x fa-arrow-circle-right"></i>
                    </span>
                </div>
                <div style="position: relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <form #userForm="ngForm" #formDir="ngForm">
                        <fh-asset-details [asset]="asset" [formMode]="formMode"></fh-asset-details>
                    </form>
                </div>
            </div>
        </div>

        <div class="col-lg-6">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fa fa-list-alt"></i> {{'general.map' | translate }}
                    </h5>
                </div>
                <div class="ibox-content slick">
                    <div style="height: 300px; width:100%" leaflet [leafletOptions]="options"
                        (leafletMapReady)="onMapReady($event)">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>