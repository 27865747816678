import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AssetService } from '../../services/asset/asset.service';
import { DeviceService } from '../../services/device/device.service';
import { Map, marker, icon } from 'leaflet';
import { TagObjectType } from 'app/models/tag.model';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { FormMode } from 'app/common/enums';
import { WaslService } from 'app/services/wasl/wasl.service';
import { Asset, WaslAsset, VehiclePlate, SlimWaslAsset } from 'app/models/asset.model';
import { getMapProviders, createMapOptions } from 'app/common/leafletGlobals';

declare var L;

@Component({
    selector: 'fh-fh-asset-details',
    templateUrl: 'assetDetails.template.html'
})
export class AssetDetailsViewComponent implements OnInit {

    sub: any;
    asset = new Asset();
    device: any;
    tagType = TagObjectType.Asset.valueOf();

    loadingDevice = false;
    loadingAsset = false;

    loadingWasl = false;

    error: any;

    map: Map;
    defaultMap;

    options;
    marker;

    formMode = FormMode.read;
    formModeWasl = FormMode.read;

    permissions: {};

    waslError: any;
    waslSuccess: any;
    success: { statusText: string; success: string; };
    maps: any;

    constructor(private waslService: WaslService, private authentication: AuthenticationService, private assetService: AssetService, private deviceService: DeviceService, private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.permissions = this.authentication.permissions;

        this.loadingDevice = true;
        this.loadingAsset = true;

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            const assetFromService = this.assetService.getAssetById(id).subscribe(asset => {
                this.asset = asset;
                this.loadingAsset = false;

                if (asset.deviceId == null) {
                    this.loadingDevice = false;
                } else {

                    const deviceFromService = this.deviceService.getDeviceById(this.asset.deviceId).subscribe(device => {
                        this.device = device;

                        this.showOnMap(device);
                        this.loadingDevice = false;
                    }, error => {
                        this.error = error
                        this.loadingDevice = false;
                    });
                }
            });
        });
        this.initMap();
    }

    // Leaflet
    initMap() {
        this.maps = getMapProviders(L);
        const initMaps = [];
        initMaps.push(Object.values(this.maps)[0]);
        this.options = createMapOptions(L, initMaps);
    }

    onMapReady(map: Map) {
        this.map = map;

        const overlayMaps = {};

        L.control.layers(this.maps, overlayMaps, { position: 'topleft' }).addTo(map);

        this.map.invalidateSize();
    }

    showOnMap(asset): void {

        if (this.marker) {
            this.map.removeLayer(this.marker);
        }

        if (asset.lastPosition && asset.lastPosition.latitude) {

            this.marker = marker([asset.lastPosition.latitude, asset.lastPosition.longitude], {
                icon: icon({
                    iconSize: [25, 41],
                    iconAnchor: [13, 41],
                    iconUrl: 'assets/marker-icon.png',
                    shadowUrl: 'assets/marker-shadow.png'
                })
            });

            this.map.addLayer(this.marker);
            this.map.flyTo([asset.lastPosition.latitude, asset.lastPosition.longitude], 10);
        }
    }

    // Form

    onDelete() {
        this.error = 'Deleting is not implemented';
    }

    onSave() {
        this.loadingAsset = true;

        console.log(this.asset);

        this.assetService.updateAsset(this.asset).subscribe(result => {
            this.loadingAsset = false;

            this.success = {
                statusText: 'Success',
                success: 'Asset is successfull updated'
            };

            this.error = null;

            this.getAssetInfo();

        }, error => {
            this.error = error
            this.loadingAsset = false;
        });
    }

    setFormMode(mode) {
        this.formMode = mode;

        if (this.formMode === FormMode.read) {
            this.loadingAsset = true;
            this.getAssetInfo();
        }
    }

    setFormModeWasl(mode) {
        this.formModeWasl = mode;

        if (this.formModeWasl === FormMode.read) {
            this.loadingWasl = true;
            this.getAssetInfo();
        }
    }

    getAssetInfo() {
        if (this.loadingAsset !== true && this.loadingWasl !== true) {
            this.loadingAsset = true;
        }

        const assetFromService = this.assetService.getAssetById(this.asset.id).subscribe(asset => {
            this.asset = asset;
            this.loadingAsset = false;
            this.loadingWasl = false;
        });
    }

    updateAssetType(event) {

        if (!event.id) {
            return;
        }

        this.loadingAsset = true;
        this.asset.assetTypeId = event.id;

        this.assetService.updateAsset(this.asset).subscribe(result => {
            this.getAssetInfo();
        });
    }

    // Wasl

    register() {

        this.loadingWasl = true;

        const waslAsset = new WaslAsset();
        waslAsset.imeiNumber = this.asset.unitId;
        waslAsset.plateType = this.asset.properties.wasl.plateType;
        waslAsset.referenceKey = this.asset.properties.wasl.referenceKey;
        waslAsset.sequenceNumber = this.asset.properties.wasl.sequenceNumber;

        waslAsset.vehiclePlate = new VehiclePlate();
        waslAsset.vehiclePlate.number = this.asset.properties.wasl.vehiclePlateNumber;
        waslAsset.vehiclePlate.leftLetter = this.asset.properties.wasl.vehiclePlateLeftLetter;
        waslAsset.vehiclePlate.middleLetter = this.asset.properties.wasl.vehiclePlateMiddleLetter;
        waslAsset.vehiclePlate.rightLetter = this.asset.properties.wasl.vehiclePlateRightLetter;

        this.waslService.registerAsset(this.asset.id, waslAsset).subscribe(result => {
            console.log(result.result);
            this.loadingWasl = false;
            if (result.success) {
                this.waslSuccess = {
                    statusText: 'Register succeeded.',
                    success: result.resultCode
                };
                this.waslError = null;
                this.asset.properties.wasl.referenceKey = result.result.referenceKey;
            } else {
                this.waslError = {
                    statusText: 'Error',
                    error: result.resultCode
                };
            }
            this.getAssetInfo();
        }, error => {
            console.log(error);
            this.waslError = error
            this.loadingWasl = false;
        });
    }

    unRegister() {

        const waslAsset = new WaslAsset();
        waslAsset.imeiNumber = this.asset.unitId;
        waslAsset.plateType = this.asset.properties.wasl.plateType;
        waslAsset.referenceKey = this.asset.properties.wasl.referenceKey;
        waslAsset.sequenceNumber = this.asset.properties.wasl.sequenceNumber;

        waslAsset.vehiclePlate = new VehiclePlate();
        waslAsset.vehiclePlate.number = this.asset.properties.wasl.vehiclePlateNumber;
        waslAsset.vehiclePlate.leftLetter = this.asset.properties.wasl.vehiclePlateLeftLetter;
        waslAsset.vehiclePlate.middleLetter = this.asset.properties.wasl.vehiclePlateMiddleLetter;
        waslAsset.vehiclePlate.rightLetter = this.asset.properties.wasl.vehiclePlateRightLetter;

        this.waslService.unregisterAsset(this.asset.id, waslAsset).subscribe(result => {
            console.log(result);
            this.loadingWasl = false;
            if (result.success) {
                this.waslSuccess = {
                    statusText: 'Unregister succeeded',
                    success: result.resultCode
                };
                this.waslError = null;
                this.asset.properties.wasl.referenceKey = null;
            } else {
                this.waslError = {
                    statusText: 'Error',
                    error: result.resultCode
                };
            }

            this.getAssetInfo();
        }, error => {
            console.log(error);
            this.waslError = error
            this.loadingWasl = false;
        });
    }

    inquire() {
        this.loadingWasl = true;
        this.waslService.inquireVehicle(this.asset.id).subscribe(result => {
            console.log(result);
            this.loadingWasl = false;

            this.waslSuccess = {
                statusText: 'Result',
                success: result.result,
                json: result.json ? JSON.parse(result.json) : undefined
            };
            this.waslError = null;
        }, error => {
            this.waslError = error
            this.loadingWasl = false;
        });
    }
}
