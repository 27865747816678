import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { throwError } from 'rxjs/internal/observable/throwError';

import { AuthenticationService } from '../authentication/authentication.service';
import { User, UserRole } from '../../models/user.model';
import { LoggingService } from '../logging/logging.service';

@Injectable()
export class UserRoleService {

    token: any;
    url = '';
    timestamp: number;

    constructor(private http: HttpClient, private loggingService: LoggingService, private authenticationService: AuthenticationService) {
        this.url = authenticationService.getWebserviceURL('userrole');
        this.token = authenticationService.getAuthToken();
    }


    getUserRoles(): Observable<UserRole[]> {

        this.url = this.url ? this.url : this.authenticationService.getWebserviceURL('userrole');
        this.token =  this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting users from service : ' + this.url);
        return this.http.get(this.url, { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }


    getUserRoleById(id: any): Observable<UserRole> {
        this.token =  this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting users from service');
        return this.http.get(this.url + id, { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseResponseData(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    parseResponse(json: any): UserRole[] {

        this.loggingService.log(this.constructor.name, 'Retrieved ' + json.length + ' Users.');

        const ident = 1;
        const users: UserRole[] = [];

        json.forEach(item => {
            let user = new UserRole();
            user = this.parseResponseData(item);
            users.push(user);
        });

        this.timestamp = new Date().getTime();
        return users;
    }

    parseResponseData(item: any): UserRole {
        const user = new UserRole();
        user.id = item.id;
        user.name = item.name;
        user.permissions = item.permissions;
        user.userLevel = item.userLevel;
        return user
    }


    private handleError(error: Response) {
        return throwError(error);
    }
}
