export function getMapProviders(L, extraLayers = null) {
     const osm = L.tileLayer('https://api.maptiler.com/maps/streets/{z}/{x}/{y}.png?key=YLfOtVnqZuWr7kkDLbI4', {
          tileSize: 512,
          zoomOffset: -1,
          minZoom: 1,
          attribution: '<a href="https://www.maptiler.com/copyright/" target="_blank">© MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>',
          crossOrigin: true
     });
     const googleMaps = L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
          maxZoom: 20,
          subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
          detectRetina: true
     });
     const googleHybrid = L.tileLayer('http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
          maxZoom: 20,
          subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
          detectRetina: true
     });

     const maps = { 'mapTiler': osm, 'Google': googleMaps, 'Hybrid': googleHybrid };

     return maps;
}

export function createMapOptions(L, maps) {

     const options = {
          layers: maps,
          zoom: 2,
          center: L.latLng(24.577100, 55.019531)
     };

     return options;
}
