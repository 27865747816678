import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

// NGX-Bootstrap
import { ModalModule, TooltipModule, PaginationModule } from 'ngx-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap';

// Forms
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TimezoneSelectorModule } from '../../modules/timezoneselector/timezoneselector.module';

// User Controlls
import { DevicesNearbyComponent } from './usercontrols/devicesNearby.component';
import { ScheduleDetailsComponent } from './usercontrols/scheduleDetails.component';
import { AdviceDetailsComponent } from './usercontrols/adviceDetails.component';
import { GeneralCalculationSettingsComponent } from './usercontrols/calculationSettings.component';
import { UserEditorComponent } from './usercontrols/userEditor.component';

import { TranslateModule } from '@ngx-translate/core';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { WizardModule } from '../../modules/wizard/wizard.module';
import { LangSelectorComponent } from './usercontrols/lang-selector.component';

// Pipes
import { OrderByPipe } from 'app/common/orderBy.pipe';
import { MomentModule } from 'angular2-moment';

// Tags (chips)
import { TagInputModule } from 'ngx-chips';
import { TaggingComponent } from './usercontrols/tagging.component';
import { AssetTypeModuleComponent } from './usercontrols/assetTypeModule.component';
import { AddressDetailsComponent } from './usercontrols/addressDetails.component';
import { SaveFooterComponent } from './usercontrols/saveFooter.component';
import { AssetDetailsComponent } from './usercontrols/assetDetails.component';
import { DeviceDetailsComponent } from './usercontrols/deviceDetails.component';
import { DriverDetailsComponent } from './usercontrols/driverDetails.component';

import { WaslAssetDetailsComponent } from './usercontrols/waslAssetDetails.component';
import { ErrorComponent } from './usercontrols/error.component';
import { UserDetailsComponent } from './usercontrols/userDetails.component';
import { DeleteModalComponent } from './usercontrols/deleteModal.component';
import { AccountPlatformControlComponent } from './usercontrols/accountPlatformControl.component';
import { PrettyJsonModule } from 'app/modules/prettyjson/prettyjson.module';
import { LoggerComponent } from './usercontrols/logger.component';
import { AssetTypeSelectorComponent } from './usercontrols/assetTypeSelector.component';
import { FilterAssetTypePipe } from 'app/common/assettype.pipe';
import { AssetTypeDetailsComponent } from './usercontrols/assetTypeDetails.component';
import { IboxToolsModule } from './iboxtools/iboxtools.module';
import { DataTablesModule } from 'angular-datatables';
import { AssetTypeChooserComponent } from './usercontrols/assetTypeChooser.component';
import { AssetTypePropertiesComponent } from './usercontrols/assetTypePropertyEditor.component';
import { SensorTypeDetailsComponent } from './usercontrols/sensorTypeDetails.component';
import { CalibrationComponent } from './usercontrols/calibrationDetails.component';
import { WaslDriverDetailsComponent } from './usercontrols/waslDriverDetails.component';
import { NumberOnlyDirective } from 'app/common/numbersOnly.directive';
import { AccountDetailsComponent } from './usercontrols/accountDetails.component';
import { DataTables360Module } from 'app/modules/360grid';
import { FilterTogglerComponent } from './usercontrols/filterToggler.component';
import { GridPresetComponent } from './usercontrols/gridPresets.component';

@NgModule({
  declarations: [
    FilterTogglerComponent,
    UserEditorComponent,
    TaggingComponent,
    DevicesNearbyComponent,
    GeneralCalculationSettingsComponent,
    AddressDetailsComponent,
    AccountDetailsComponent,
    GridPresetComponent,
    AssetDetailsComponent,
    AssetTypeDetailsComponent,
    WaslAssetDetailsComponent,
    WaslDriverDetailsComponent,
    AssetTypePropertiesComponent,
    DeleteModalComponent,
    DriverDetailsComponent,
    UserDetailsComponent,
    AccountPlatformControlComponent,
    DeviceDetailsComponent,
    SaveFooterComponent,
    ErrorComponent,
    LoggerComponent,
    ScheduleDetailsComponent,
    AssetTypeModuleComponent,
    AssetTypeSelectorComponent,
    LangSelectorComponent,
    AdviceDetailsComponent,
    SensorTypeDetailsComponent,
    CalibrationComponent,
    OrderByPipe,
    FilterAssetTypePipe,
    AssetTypeChooserComponent,
    NumberOnlyDirective
  ],
  imports: [
    TagInputModule,
    BrowserAnimationsModule,
    FormsModule,
    MomentModule,
    ReactiveFormsModule,
    BrowserModule,
    RouterModule,
    IboxToolsModule,
    DataTablesModule,
    DataTables360Module,
    PrettyJsonModule,
    TimezoneSelectorModule,
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    PaginationModule.forRoot(),
    ModalModule.forRoot(),
    WizardModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.rectangleBounce,
      backdropBackgroundColour: 'rgba(255,255,255,0.7)',
      backdropBorderRadius: '4px',
      primaryColour: '#DC8139',
      secondaryColour: '#DC8139',
      tertiaryColour: '#DC8139'
    }),
    TranslateModule
  ],
  exports: [
    FilterTogglerComponent,
    OrderByPipe,
    FilterAssetTypePipe,
    TaggingComponent,
    UserEditorComponent,
    AddressDetailsComponent,
    AccountDetailsComponent,
    GridPresetComponent,
    AssetDetailsComponent,
    AssetTypeDetailsComponent,
    UserDetailsComponent,
    AccountPlatformControlComponent,
    AssetTypePropertiesComponent,
    DeleteModalComponent,
    ErrorComponent,
    LoggerComponent,
    WaslAssetDetailsComponent,
    WaslDriverDetailsComponent,
    DriverDetailsComponent,
    DeviceDetailsComponent,
    SaveFooterComponent,
    DevicesNearbyComponent,
    AssetTypeModuleComponent,
    AssetTypeSelectorComponent,
    LangSelectorComponent,
    GeneralCalculationSettingsComponent,
    CalibrationComponent,
    SensorTypeDetailsComponent,
    ScheduleDetailsComponent,
    AdviceDetailsComponent,
    AssetTypeChooserComponent,
    NumberOnlyDirective
  ],
})

export class UsercontrolsModule { }
