import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/Observable';
import { Issue } from './../../../models/issue.model';
import { Component, Input, OnInit, OnChanges, SimpleChanges, NgModule, AfterViewInit } from '@angular/core';
import { IssueTypeService } from 'app/services/issuetypes/issuetypes.service';
import { CalculationSettings, IssueTypeSettings, IssueTypesWithPermissions } from '../../../models/calculationsettings.model';
import { CalculationSettingsService } from '../../../services/calculationsettings/calculationsettings.service';
import { SettingsLevel, Severity } from '../../../common/enums';
import { ToastrService } from 'ngx-toastr';
import { TranslatorService } from '../../../services/common/translator.service';

@Component({
    selector: 'fh-general-calculationsettings',
    templateUrl: 'calculationSettings.component.html',
    styleUrls: ['calculationSettings.component.css'],
    providers: [CalculationSettingsService]
})
export class GeneralCalculationSettingsComponent implements OnInit, OnChanges {
    @Input() device;
    @Input() deviceType;
    @Input() platform;
    @Input() account;
    @Input() customer;

    issueTypes = [];
    level: SettingsLevel;
    SettingsLevel: typeof SettingsLevel = SettingsLevel;
    currentId;
    loading: boolean;
    calculationSettings: CalculationSettings;
    issueTypesWithPermissions: IssueTypesWithPermissions[];
    loadingSettings: boolean;
    showSettingsLevels: boolean;
    settingsToDelete: CalculationSettings;
    isSaved: boolean;
    updateMessage: string;
    severities = [];
    updateSuccess: boolean;

    constructor(private issueTypeService: IssueTypeService, private toastr: ToastrService, private calculationSettingsService: CalculationSettingsService, private translateService: TranslateService, private translatorService: TranslatorService) {
        this.issueTypesWithPermissions = [];

        // Map severities from enum
        this.severities = Object.keys(Severity)
            .filter(k => typeof Severity[k] === 'number')
            .map(n => ({ name: n, value: Severity[n] }))
    }

    getData(level, id) {

        console.log('---- dataa');

        this.loadingSettings = true;
        this.calculationSettingsService.getCalculationSettings(level, id).subscribe(result => {
            this.calculationSettings = result;
            this.settingsToDelete = new CalculationSettings();
            this.loadingSettings = false;
            this.issueTypesWithPermissions = [];

            this.issueTypes.forEach(issueType => {
                const issueTypeWithPermissions: IssueTypesWithPermissions = issueType;

                let calculationSetting = this.calculationSettings.issueTypeSettings.find(function (setting) {
                    return setting.issueType === issueType.id;
                });

                if (calculationSetting == null) {
                    calculationSetting = new IssueTypesWithPermissions();
                }

                issueTypeWithPermissions.source = calculationSetting && calculationSetting.source;
                issueTypeWithPermissions.lowerThreshold = calculationSetting && calculationSetting.lowerThreshold;
                issueTypeWithPermissions.upperThreshold = calculationSetting && calculationSetting.upperThreshold;
                issueTypeWithPermissions.deviceSensorTypeId = calculationSetting && calculationSetting.deviceSensorTypeId;
                issueTypeWithPermissions.duration = calculationSetting && calculationSetting.duration;
                issueTypeWithPermissions.severity = calculationSetting && calculationSetting.severity;
                issueTypeWithPermissions.isActive = calculationSetting && calculationSetting.isActive;

                if (calculationSetting != null) {
                    issueTypeWithPermissions.isUsed = (parseInt(calculationSetting.source, null) === this.level);
                } else {
                    issueTypeWithPermissions.isUsed = false;
                    issueTypeWithPermissions.source = 3;
                }

                this.issueTypesWithPermissions.push(issueTypeWithPermissions);
            });
            this.loading = false;
        });

    }

    saveData(): Observable<Object> {
        this.calculationSettings.level = this.level;
        return this.calculationSettingsService.saveCalculationSettings(this.calculationSettings, this.currentId).map(result => {
            return result;
        });
    }

    deleteData(): Observable<Object> {
        this.settingsToDelete.level = this.level;
        return this.calculationSettingsService.deleteCalculationSettings(this.settingsToDelete, this.currentId).map(result => {
            return result;
        });
    }

    ngOnInit(): void {
        console.log('init');
        this.level = SettingsLevel.Account;
        this.currentId = '-';

        // fetch issuetypes
        this.issueTypeService.getIssueTypes().subscribe(issueTypes => {
            this.issueTypes = issueTypes;

            if (this.device || this.platform || this.deviceType || this.account || this.customer) {
                // No data yet... wait for the changes in the model
                this.loadData();
            } else {
                // No device platform or devicetype... get the system data
                this.getData(null, null);
            }
        });

    }

    save() {
        console.log('saving');
        this.loading = true;
        this.loadingSettings = true;
        const tempIssueTypeSettingsToUpdate: IssueTypeSettings[] = [];
        const tempIssueTypeSettingsToDelete: IssueTypeSettings[] = [];

        this.issueTypesWithPermissions.forEach(setting => {
            if (setting.isUsed) {
                tempIssueTypeSettingsToUpdate.push({
                    issueType: setting.id, isActive: setting.isActive, lowerThreshold: setting.lowerThreshold, upperThreshold: setting.upperThreshold, duration: setting.duration,
                    source: setting.source, severity: setting.severity, createdDate: setting.createdDate,
                    modifiedDate: setting.modifiedDate, issuetypeName: setting.id, sourceName: '', deviceSensorTypeId: ''
                })
            } else {
                tempIssueTypeSettingsToDelete.push({
                    issueType: setting.id, isActive: setting.isActive, lowerThreshold: setting.lowerThreshold, upperThreshold: setting.upperThreshold, duration: setting.duration,
                    source: setting.source, severity: setting.severity, createdDate: setting.createdDate,
                    modifiedDate: setting.modifiedDate, issuetypeName: setting.id, sourceName: '', deviceSensorTypeId: ''
                })
            }
        });

        this.calculationSettings.issueTypeSettings = tempIssueTypeSettingsToUpdate;
        this.settingsToDelete.issueTypeSettings = tempIssueTypeSettingsToDelete;

        Observable.forkJoin([this.saveData(), this.deleteData()])
            .subscribe((response) => {
                this.loading = false;
                this.loadingSettings = false;
                this.getData(this.level, this.currentId);
                this.isSaved = true;
                this.updateSuccess = true;
                this.updateMessage = this.translateService.instant('general.updateMessage').replace('{0}', response[0]).replace('{1}', response[1]);
            }, (error) => {
                console.log('err:' + error);
                this.loading = false;
                this.loadingSettings = false;
                this.isSaved = true;
                this.updateSuccess = false;
                this.updateMessage = this.translateService.instant('general.errorUpdate');
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        this.loadData();
    }

    loadData() {
        this.loading = true;

        if (this.issueTypesWithPermissions.length > 0) { return; }

        if (this.device && this.device.id !== '') {
            this.level = SettingsLevel.Device;
            this.currentId = this.device.id;

            // get data
            this.getData(this.level, this.currentId);
            this.loading = false;
        }
        if (this.deviceType && this.deviceType.id !== '') {
            this.level = SettingsLevel.DeviceType;
            this.currentId = this.deviceType.id;
            // get data
            this.getData(this.level, this.currentId);
            this.loading = false;
        }
        if (this.platform && this.platform.id !== '') {

            this.level = SettingsLevel.Platform;
            this.currentId = this.platform.id;

            // get data
            this.getData(this.level, this.currentId);
            this.loading = false;
        }
        if (this.customer && this.customer.id !== '') {

            this.level = SettingsLevel.Customer;
            this.currentId = this.customer.id;

            // get data
            this.getData(this.level, this.currentId);
            this.loading = false;
        }
        if (this.account && this.account.id !== '') {
            this.level = SettingsLevel.Account;
            this.currentId = this.account.id;

            // get data
            this.getData(this.level, this.currentId);
            this.loading = false;
        }
    }
}


