import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DriverService } from '../../services/driver/driver.service';
import { tileLayer, latLng, circle, polygon, Map, marker, icon } from 'leaflet';
import { TagObjectType } from 'app/models/tag.model';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { Driver } from 'app/models/driver.model';
import { FormMode } from 'app/common/enums';
import { WaslService } from 'app/services/wasl/wasl.service';
import { BsDaterangepickerConfig } from 'ngx-bootstrap';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
window['moment'] = Moment;
mTZ()

declare var L;

@Component({
    selector: 'fh-fh-driver-details',
    templateUrl: 'driverDetails.template.html'
})
export class DriverDetailsViewComponent implements OnInit {
    sub: any;
    driver: Driver = new Driver();
    device: any;
    tagType = TagObjectType.Driver.valueOf();
    formMode = FormMode.read;
    formModeWasl = FormMode.read;

    loadingDriver = false;
    loadingDriverWasl = false;

    error: any;

    map: Map;
   defaultMap;

    options;
    marker;

    waslStatus = {
        reference: 'ed3c444f-a9f7-4dc7-8e89-e1aae81b12db',
        sequence: '452818110',
        plateType: 'PubblbicTransport',
        plateNo: '3003',
        lmr: 'ع'
    };
    permissions: {};
    success: string;
    waslSuccess: { statusText: string; success: any; };
    waslError: any;

    constructor(private waslService: WaslService, private authentication: AuthenticationService, private driverService: DriverService, private route: ActivatedRoute) {
        this.permissions = this.authentication.permissions;
    }

    ngOnInit() {

        this.loadingDriver = true;

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.driverService.getDriverById(id).subscribe(driver => {
                this.driver = driver;
                this.loadingDriver = false;
            });
        });
    }


    // Form

    onDelete() {
        this.error = 'Deleting is not implemented';
    }

    onSave() {
        this.loadingDriver = true;

        this.driverService.updateDriver(this.driver).subscribe(result => {
            console.log(result);
            this.error = null;
            this.success = 'Succes';

            this.driverService.resetCache().subscribe(res => {
                console.log('Drivers are cleared');
            });

            this.getDriverInfo();
        }, error => {
            this.error = error
            this.getDriverInfo();
        });
    }

    setFormMode(mode) {
        this.formMode = mode;

        if (this.formMode === FormMode.read) {
            this.loadingDriver = true;
            this.getDriverInfo();
        }
    }

    setFormModeWasl(mode) {
        this.formModeWasl = mode;

        if (this.formModeWasl === FormMode.read) {
            this.loadingDriverWasl = true;
            this.getDriverInfo();
        }
    }

    getDriverInfo() {
        if (this.loadingDriver !== true && this.loadingDriverWasl !== true) {
            this.loadingDriver = true;
        }

        this.driverService.getDriverById(this.driver.id).subscribe(driver => {
            this.driver = driver;
            this.loadingDriver = false;
            this.loadingDriverWasl = false;
        });
    }

    // Wasl

    register() {
        this.loadingDriverWasl = true;

        this.driver.properties.wasl.dateOfBirthGregorian = Moment(this.driver.dateOfBirth).format('YYYY-MM-DD');

        this.waslService.registerDriver(this.driver.id, this.driver.properties.wasl).subscribe(registerResult => {
            console.log(registerResult);
            this.loadingDriverWasl = false;
            if (registerResult.success) {
                this.waslSuccess = {
                    statusText: 'Success',
                    success: registerResult.resultCode
                };
                this.driver.properties.wasl.referenceKey = registerResult.result.referenceKey;
                this.waslError = null;
            } else {
                this.waslError = {
                    statusText: 'Error',
                    error: registerResult.resultCode
                };
            }
            this.getDriverInfo();
        }, error => {
            console.log(error);
            this.waslError = error
            this.loadingDriverWasl = false;
        });
    }

    unRegister() {
        this.loadingDriverWasl = true;

        this.driver.properties.wasl.dateOfBirthGregorian = Moment(this.driver.dateOfBirth).format('YYYY-MM-DD');

        this.waslService.unregisterDriver(this.driver.id, this.driver.properties.wasl).subscribe(unregisterResult => {
            console.log(unregisterResult);
            this.loadingDriverWasl = false;
            if (unregisterResult.success) {
                this.waslSuccess = {
                    statusText: 'Success',
                    success: unregisterResult.resultCode
                };
                this.driver.properties.wasl.referenceKey = null;
                this.waslError = null;
            } else {
                this.waslError = {
                    statusText: 'Error',
                    error: unregisterResult.resultCode
                };
            }
            this.getDriverInfo();
        }, error => {
            console.log(error);
            this.waslError = error
            this.loadingDriverWasl = false;
        });
    }
}
