import { Component, OnInit, AfterViewInit } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { PlatformService } from '../../services/platform/platform.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'fh-calculation-settings',
    templateUrl: 'calculationSettings.template.html'
})
export class CalculationSettingsViewComponent {
    sub;

    constructor() {
    }
}
