import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BsDropdownModule } from 'ngx-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';


import { IboxToolsComponent } from './iboxtools.component';
import { IboxSearchComponent } from './iboxsearch.component';

@NgModule({
  declarations: [IboxToolsComponent, IboxSearchComponent],
  imports: [FormsModule, TranslateModule, BrowserModule, BsDropdownModule.forRoot()],
  exports: [IboxToolsComponent, IboxSearchComponent],
})

export class IboxToolsModule { }
