import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Subject } from 'rxjs/Subject';
import { DeviceService } from '../../services/device/device.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceType } from 'app/models/devicetype.model';
import { IssueService } from 'app/services/issues/issues.service';
import { Device } from 'app/models/device.model';
import { CustomerService } from 'app/services/customers/customers.service';
import { IssueTypeService } from 'app/services/issuetypes/issuetypes.service';
import { IssueType } from 'app/models/issuetype.model';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import { Customer } from '../../models/customer.model';
import { AssetService } from 'app/services/asset/asset.service';
import { DriverService } from 'app/services/driver/driver.service';
import { Driver } from 'app/models/driver.model';
import { Asset } from 'app/models/asset.model';


@Component({
    selector: 'fh-driver-assets',
    templateUrl: 'driverAssets.template.html'
})
export class DriverAssetsViewComponent {
    @ViewChild(DataTableDirective)
    datatableElement: DataTableDirective;

    loading = false;

    issueTypes: IssueType[];
    deviceTypes: DeviceType[];

    sub;
    dtOptions = {};
    devices: Device[];
    customer: Customer;
    languageLoaded: boolean;
    driver: Driver;
    assets: Asset[] = [];

    constructor(private translateService: TranslateService, private http: HttpClient, private assetService: AssetService, private issueTypeService: IssueTypeService, private driverService: DriverService, private route: ActivatedRoute, private router: Router) {

        issueTypeService.getIssueTypes().subscribe(issueTypes => {
            this.issueTypes = issueTypes;
        });

        this.loading = true;

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];
            this.driverService.getDriverById(id).subscribe(driver => {
                this.driver = driver;
            });

            this.assetService.getAssetsByDriverId(id).subscribe(resp => {
                this.assets = resp;
                this.loading = false;
            });
        });
    }
}
