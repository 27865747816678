import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

import * as moment from 'moment';

import { AuthenticationService } from '../authentication/authentication.service';
import { Workflow, Action, WorkflowAction, WorkflowActionActions } from '../../models/workflow.model';
import { Location } from '../../models/location.model';
import { LoggingService } from '../logging/logging.service';
import { Device } from '../../models/device.model';
import { throwError } from 'rxjs/internal/observable/throwError';

@Injectable()
export class WorkflowService {
    token: string;
    timestamp;
    url = '';
    WorkflowActionDescriptions: string[] = [];

    constructor(private http: HttpClient, private loggingService: LoggingService, private authenticationService: AuthenticationService) {
        this.url = authenticationService.getWebserviceURL('workflow');
        this.token = authenticationService.getAuthToken();
    }


    getWorkflows(actions: Action[]): Observable<Workflow[]> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting workflows from service');
        return this.http.get(this.url, { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data, actions);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getWorkflowsById(id: number, actions: Action[]): Observable<Workflow> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('Fetch workflow by id ' + id);
        return this.http.get(this.url + id, { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseReponseDetails(data, actions);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getActions(): Observable<Action[]> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        return this.http.get(this.url + 'Actions', { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseActionResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    private handleError(error: Response) {
        return throwError(error);
    }


    parseResponse(json: any, actions: Action[]): Workflow[] {

        this.loggingService.log(this.constructor.name, 'Retrieved ' + json.length + ' Workflows.');

        const ident = 1;
        const workflows: Workflow[] = [];

        // this.loggingService.log(json);
        json.forEach(item => {
            //   this.loggingService.log(this.constructor().name + " - New", item);
            const workflow = this.parseReponseDetails(item, actions);
            workflows.push(workflow);
        });

        this.timestamp = new Date().getTime();
        return workflows;
    }

    parseReponseDetails(item, actions: Action[]): Workflow {

        const workflow = new Workflow();
        workflow.id = item.id;
        workflow.name = item.name;
        workflow.days = item.days;

        // Actions in workflow
        if (item.workflowActions) {
            workflow.workflowActions = [];
            item.workflowActions.forEach(workflowAction => {
                const wfa = new WorkflowAction();
                wfa.action = actions.filter(x => x.id === workflowAction.actionId)[0];
                wfa.name = wfa.action.name;
                wfa.id = wfa.action.id;
                wfa.days = workflowAction.days;
                wfa.isStartAction = workflowAction.isStartAction;
                wfa.isEndAction = workflowAction.isEndAction;
                wfa.hasCommunication = workflowAction.hasCommunication;
                wfa.allowAutoProcess = workflowAction.allowAutoProcess;
                wfa.nextActions = [];
                workflow.workflowActions.push(wfa);
            });
        }

        // Actions to actions in workflow
        if (item.workflowActionActions) {
            workflow.workflowActionActions = [];
            item.workflowActionActions.forEach(workflowActionActions => {
                const wfaa = new WorkflowActionActions();
                wfaa.fromActionId = workflowActionActions.fromActionId;
                wfaa.toActionId = workflowActionActions.toActionId;

                const from = workflow.workflowActions.filter(x => x.id === workflowActionActions.fromActionId)[0];
                const to = workflowActionActions.toActionId;
                if (from != null && to != null) {
                    from.nextActions.push(to);
                }

                workflow.workflowActionActions.push(wfaa);
            });
        }
        console.log(workflow);
        return workflow;
    }



    parseActionResponse(json: any): Action[] {

        this.loggingService.log(this.constructor.name, 'Retrieved ' + json.length + ' Workflows.');

        const ident = 1;
        const actions: Action[] = [];

        // this.loggingService.log(json);
        json.forEach(item => {
            //   this.loggingService.log(this.constructor().name + " - New", item);
            const action = this.parseActionReponseDetails(item);
            actions.push(action);
        });

        this.timestamp = new Date().getTime();
        return actions;
    }

    parseActionReponseDetails(item): Action {

        const action = new Action();
        action.id = item.id;
        action.name = item.name;
        action.isCustom = item.accountId != null;

        return action;
    }
}
