import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { BsDropdownModule } from 'ngx-bootstrap';

import { BasicLayoutComponent } from './layouts/basicLayout.component';
import { BlankLayoutComponent } from './layouts/blankLayout.component';

import { NavigationComponent } from './navigation/navigation.component';
import { FooterComponent } from './footer/footer.component';
import { TopNavbarComponent } from './topnavbar/topnavbar.component';

import { TranslateModule } from '@ngx-translate/core';
import { NotificationsModule } from '../../modules/notification'

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { UsercontrolsModule } from './usercontrols.module';

@NgModule({
  declarations: [
    FooterComponent,
    BasicLayoutComponent,
    BlankLayoutComponent,
    NavigationComponent,
    TopNavbarComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    UsercontrolsModule,
    TypeaheadModule.forRoot(),
    BsDropdownModule.forRoot(),
    TranslateModule,
    NotificationsModule
  ],
  exports: [
    FooterComponent,
    BasicLayoutComponent,
    BlankLayoutComponent,
    NavigationComponent,
    TopNavbarComponent,
    TranslateModule
  ],
})

export class SharedLayoutsModule { }
