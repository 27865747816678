import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Subject } from 'rxjs/Subject';
import { DeviceService } from '../../services/device/device.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Device } from 'app/models/device.model';
import { FhChartService } from '../../services/charts/charts.service';
import { DeviceSensorsService } from 'app/services/sensors/deviceSensors.service';
import { DeviceSensorType } from 'app/models/sensor.model';
import { TranslatorService } from 'app/services/common/translator.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'fh-device-sensors',
    templateUrl: 'sensorDetails.template.html',
    providers: [FhChartService]
})

export class DeviceSensorsViewComponent implements OnInit, OnDestroy {

    timeoutHandler;
    chartRpm: any;
    chartSpeed: any;
    sensors;
    sub;
    device: Device;
    loading = false;
    loadingSensors = false;
    sensorTypes: DeviceSensorType[];

    constructor(private chartService: FhChartService, private translateService: TranslateService, private http: HttpClient, private deviceService: DeviceService, private route: ActivatedRoute, private router: Router) {
        this.device = null;
        this.sensors = [];
    }

    ngOnInit() {
        this.device = new Device;
        this.loading = true;
        this.device.id = ''

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];
            this.deviceService.getDeviceById(id).subscribe(device => {
                this.device = device;
                console.log(this.device.customFields);
                this.device.customFields = JSON.stringify(this.device.customFields);
                this.sensors = this.device.sensors;

                this.sensors.forEach(sensor => {
                    sensor.deviceSensorType = this.translateService.instant('enums.sensorTypes.' + sensor.deviceSensorTypeId);
                });

                this.loading = false;

                if (this.device == null) {
                    this.router.navigate(['/Devices/Overview'])
                }
            });
        });
    }

    ngOnDestroy(): void {
        clearTimeout(this.timeoutHandler);
    }
}
