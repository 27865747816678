import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { of } from 'rxjs/observable/of';
import { throwError } from 'rxjs/internal/observable/throwError';
import * as moment from 'moment';
import 'rxjs/add/operator/map'

import { AuthenticationService } from '../authentication/authentication.service';
import { DeviceType } from '../../models/devicetype.model';
import { LoggingService } from '../logging/logging.service';
import { TranslatorService } from '../common/translator.service';
import { Customer } from '../../models/customer.model';

@Injectable()
export class CustomerService {

    token: string;
    timestamp;
    url = '';
    customers: Customer[] = [];


    constructor(private http: HttpClient, private translatorService: TranslatorService, private loggingService: LoggingService, private authenticationService: AuthenticationService) {
        this.url = authenticationService.getWebserviceURL('customer');
        this.token = authenticationService.getAuthToken();
    }

    getCustomers(setDefinate = true): Observable<Customer[]> {

        if (setDefinate && this.customers.length !== 0) {
            return of(this.customers);
        }

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting customers from service');
        return this.http.get(this.url, { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                this.customers = parsedResponse;
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getCustomerById(id: string): Observable<Customer> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting customer from service');
        return this.http.get(this.url + id, { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseResponseData(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getCustomerDashboard(id: string, start, end): Observable<any> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting dashboard from service per devicetype');
        return this.http.get(this.url + id + '/dashboard?start=' + Math.round(start / 1000) + '&end=' + Math.round(end / 1000), { headers: headers })
            .map((data: any) => {
                return data;
            })
            .catch(this.handleError);
    }

    private handleError(error: Response) {
        return throwError(error);
    }

    parseResponse(json: any): Customer[] {

        const customers: Customer[] = [];

        json.forEach(item => {

            const customer = this.parseResponseData(item);
            customers.push(customer);
        });

        this.timestamp = new Date().getTime();
        return customers;
    }

    getIssuesPerIssueType(id: string, start, end): Observable<any> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting dashboard from service per issuetype');
        return this.http.get(this.url + id + '/IssuesPerIssueType?start=' + Math.round(start / 1000) + '&end=' + Math.round(end / 1000), { headers: headers })
            .map((data: any) => {
                return Object.keys(data).map(k => [this.translatorService.translate(k, 'enums.issuetypes'), data[k]]);
            })
            .catch(this.handleError);
    }

    parseResponseData(item) {
        const customer = new Customer();
        customer.id = item.id;
        customer.name = item.name;
        customer.count = item.count;
        customer.platformId = item.platformId;
        customer.platformName = item.platformName;
        customer.accountPlatformId = item.accountPlatformId;
        customer.accountPlatformName = item.accountPlatformName;
        customer.createdDate = item.createdDate ? moment(item.createdDate.replace(' ', '')).toDate() : null;
        customer.modifiedDate = item.modifiedDate ? moment(item.modifiedDate.replace(' ', '')).toDate() : null;
        customer.referenceId = item.referenceId;
        return customer;
    }
}
