
export class RegisterOperatingCompanyRequest {
  public identityNumber: string;
  public commercialRecordNumber: string;
  public commercialRecordIssueDateHijri: string;
  public phoneNumber: string;
  public extensionNumber: string;
  public emailAddress: string;
  public managerName: string;
  public managerPhoneNumber: string;
  public managerMobileNumber: string;
  public dateOfBirthGregorian: string;
  public referenceKey: string;
}
