<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/">{{'general.settings' | translate }}</a>
            </li>
            <li class="active">
                <strong>{{'menu.settingslanguage' | translate}}</strong>
            </li>
        </ol>
    </div>
</div>

<div class="wrapper wrapper-content">
    <div class="row">
        <div class="col-lg-12">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-flag"></i> {{'menu.settingslanguage' | translate}}</h5>
                </div>
                <div class="ibox-content">
                    <div class="form-group">
                        <div class="col-md-2 control-label" for="instagram">{{'settings.chooselanguage' | translate}}</div>
                        <div class="col-md-4">
                            <select class="form-control table-inline" #lang style="width:150px" (change)="onChange($event, lang.value)">
                                <option selected="{{currentLang === language.name ? 'selected' : ''}}" *ngFor="let language of languages" value={{language.path}}>
                                    {{language.name}}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-2 control-label" for="instagram">{{'settings.langtagmode' | translate}}</div>
                        <div class="col-md-4">
                            <input id="langTagMode" icheck iCheckClass="icheckbox_minimal-grey" (ifChecked)="setLangtagMode()" name="langTagMode" type="checkbox">
                        </div>
                    </div>
                </div>
                <div style="clear:both"></div>
            </div>

            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-flag"></i> {{'menu.settingslanguage' | translate }}</h5>
                    <iboxseach (seachChanged)="seachChanged($event)" (editMode)="false"></iboxseach>
                </div>
                <div class="ibox-content slick" style="position: relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <table id="datatable" style="width:100%; min-height:50px;" datatable [dtOptions]="dtOptions" class="table table-hover nowrap margin-bottom-0 dataTable dtr-inline">
                        <thead>
                            <tr>
                                <th style="width:500px">{{'settings.tag' | translate}}</th>
                                <th style="width:auto">{{'settings.text' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let langtag of langData">
                                <td style="width:500px">{{ langtag.tag }}</td>
                                <td style="width:auto">{{ langtag.text }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>