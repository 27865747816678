<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Assets/Overview">{{'general.asset' | translate }}</a>
            </li>
            <li>
                <a href="/#/AssetDetails/Index/{{asset?.id}}">{{asset?.name}}</a>
            </li>
            <li class="active">
                <strong>{{'general.assetDetails' | translate }}</strong>
            </li>
        </ol>
    </div>
</div>

<div class="wrapper wrapper-content">

    <div class="row" data-equalizer="" *ngIf="error || success">
        <div class="col-lg-12">
            <fh-error [error]="error" [success]="success"></fh-error>
        </div>
    </div>

    <div class="row" data-equalizer="">
        <div class="col-lg-6">

            <form #userForm="ngForm" #formDir="ngForm">
                <div class="ibox float-e-margins" style="position:relative">
                    <ngx-loading [show]="loadingAsset"></ngx-loading>
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-car"></i> {{'general.asset' | translate }}</h5>
                    </div>
                    <fh-asset-details [asset]="asset" [formMode]="formMode"></fh-asset-details>
                    <fh-save-footer [valid]="!userForm.invalid" (onSave)="onSave()" (onDelete)="onDelete()"
                        [allowDelete]="permissions['HasWasl']" (setFormMode)="setFormMode($event)"
                        [formMode]="formMode">
                    </fh-save-footer>
                </div>
            </form>

            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fa fa-fw fa-list-alt"></i> {{'general.map' | translate }}
                    </h5>
                </div>
                <div class="ibox-content slick">
                    <div style="height: 300px; width:100%" leaflet [leafletOptions]="options"
                        (leafletMapReady)="onMapReady($event)">
                    </div>
                </div>
            </div>

        </div>
        <div class="col-lg-6">

            <fh-error [autoRemove]="false" [error]="waslError" [success]="waslSuccess"></fh-error>
            <form #waslForm="ngForm" #formDir="ngForm">

                <div *ngIf="permissions['HasWasl'] && !loadingDevice && !loadingAsset && (!device || waslForm.invalid)"
                    class="alert alert-warning shadow">
                    <p *ngIf="!device" [routerLink]="['/AssetDetails/Device/', asset.id ]">
                        {{'general.noRegistrationWhenNoDevice' | translate }}</p>
                    <p *ngIf="waslForm.invalid">{{'general.waslFormInvalid' | translate }}</p>
                </div>

                <div *ngIf="permissions['HasWasl']" class="ibox float-e-margins">

                    <div class="ibox-title">
                        <h5>
                            <i class="fa fa-user-shield"></i> {{'general.wasl' | translate }}
                        </h5>
                    </div>

                    <div class="ibox-content slick" style="position:relative">
                        <ngx-loading [show]="loadingWasl || loadingAsset"></ngx-loading>

                        <fh-wasl-asset-details [asset]="asset" [formMode]="formModeWasl"></fh-wasl-asset-details>
                    </div>
                    <fh-save-footer [valid]="!waslForm.invalid" [allowDelete]="false" (onSave)="onSave()"
                        (setFormMode)="setFormModeWasl($event)" [formMode]="formModeWasl"
                        [showButtons]="!asset.properties.wasl.referenceKey">
                        <button class="btn btn-primary" [disabled]="waslForm.invalid || formModeWasl != 1 || !device"
                            *ngIf="!asset.properties.wasl.referenceKey" (click)="register()"><i
                                class="fa fa-fw fa-edit"></i>
                            {{'general.register' | translate }}</button>

                        <button class="btn btn-danger" [disabled]="waslForm.invalid  || formModeWasl != 1"
                            *ngIf="asset.properties.wasl.referenceKey" (click)="unRegister()"><i
                                class="fa fa-fw fa-trash-alt"></i>
                            {{'general.unRegister' | translate }}</button>

                        <button *ngIf="asset.properties.wasl.referenceKey" class="btn btn-primary"
                            (click)="inquire()"><i class="fa fa-fw fa-broadcast-tower"></i>
                            {{'general.inquire' | translate }}</button>
                    </fh-save-footer>
                </div>
            </form>

            <fh-assettype-module (onSelect)="updateAssetType($event)" [asset]="asset" [formMode]="formMode"
                [loading]="loadingAsset">
            </fh-assettype-module>

            <fh-tagging [objectId]="asset?.id" [objectType]="tagType">
            </fh-tagging>

        </div>
    </div>
</div>