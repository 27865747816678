import { Component, OnDestroy, OnInit, ChangeDetectorRef, ViewChild, } from '@angular/core';

import { AuthenticationService } from '../../services/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { Asset } from 'app/models/asset.model';
import { AddressDetails } from 'app/models/platform.model';
import { FormMode } from 'app/common/enums';
import { AssetService } from 'app/services/asset/asset.service';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { AssetTypeService } from 'app/services/asset/assetTypes.service';
import { AssetType } from 'app/models/assetType.model';
import { Router } from '@angular/router';
import { groupBy } from 'rxjs/internal/operators/groupBy';
import { mergeMap } from 'rxjs/internal/operators/mergeMap';
import { toArray } from 'rxjs/internal/operators/toArray';

@Component({
  selector: 'fh-assets-add',
  templateUrl: 'add.template.html'
})
export class AssetAddViewComponent {
  token: string;
  addDevice = false;
  asset: Asset;
  formMode = FormMode.add;
  error: any;
  loading = false;
  firstName: any;
  lastName: any;
  email: any;
  password: any;
  language: any;
  form: any;

  assetTypes: AssetType[] = [];
  assetTypesMultiselect: any[];
  selectedAssetType;
  loadingAssetTypes = false;

  dropdownSettingsAssetTypes = {};
  success: { statusText: string; success: string; };
  assetTypeGroups: any;
  permissions: {};

  constructor(private router: Router, private assetService: AssetService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService) {
    this.asset = new Asset();
    this.asset.assetClass = 1;

    this.token = this.authenticationService.getAuthToken();
    this.permissions = this.authenticationService.permissions;
  }

  onInsert() {
    this.loading = true;

    this.assetService.saveAsset(this.asset).subscribe(result => {

      this.success = {
        statusText: 'Success',
        success: 'You will be redirected to the asset'
      };

      setTimeout(() => {
        this.router.navigate(['/AssetDetails/Index/' + result.id]);
      }, 2000);

      this.assetService.resetAssetCache().subscribe(res => {
        console.log('Assets are cleared');
        console.log(res);
      });

      this.loading = false;
    }, error => {
      this.error = error
      this.loading = false;
    });
  }

  updateAssetType(event) {

    console.log(event);

    if (!event.id) {
      return;
    }

    this.asset.assetTypeId = event.id;
    this.asset.assetTypeName = event.name;
    this.asset.brand = event.brand;
    this.asset.model = event.model;
  }
}
