<div class="row border-bottom white-bg dashboard-header">
    <div class="col-sm-12" style="padding: 15px">
        <h2>
            <i class="fas fa-fw fa-user-shield"></i> {{'general.wasl' | translate }}</h2>
        {{'general.introWasl' | translate }}
    </div>
</div>

<form #userForm="ngForm" #formDir="ngForm">
    <div class="wrapper wrapper-content">
        <div class="row" data-equalizer="">
            <div class="col-lg-6">


                <div class="ibox float-e-margins" style="position:relative">
                    <ngx-loading [show]="loadingWasl"></ngx-loading>
                    <div class="ibox-title">
                        <h5>
                            <i class="fa fa-user-shield"></i> {{'general.company' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content slick">
                        <table style="width:100%" class="table table-hover nowrap dtr-inline no-footer">
                            <tbody>
                                <tr>
                                    <td style="width:50%">
                                        {{ 'general.name' | translate}}
                                        <span class="error" *ngIf="name.invalid && formMode != 1">*</span>
                                    </td>
                                    <td style="width:50%">
                                        <input type="text" [readonly]="formMode == 1" name="ac_name"
                                            class="form-control table-inline" [(ngModel)]="account.name" required
                                            #name="ngModel">
                                    </td>
                            </tbody>
                        </table>
                    </div>
                    <fh-save-footer [valid]="!userForm.invalid" (onSave)="onSave()" (onInsert)="onInsert()"
                        (setFormMode)="setFormMode($event)" [formMode]="formMode"></fh-save-footer>
                </div>


                <div class="ibox float-e-margins">
                    <div class="ibox-title">
                        <h5>
                            <i class="fa fa-user-shield"></i> {{'general.waslStatus' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content slick" style="position:relative">
                        <ngx-loading [show]="loadingWasl"></ngx-loading>
                        <table style="width:100%" class="table table-hover nowrap dtr-inline no-footer">
                            <tbody>
                                <tr>
                                    <td style="width:50%">
                                        {{ 'general.connected' | translate}}
                                    </td>
                                    <td style="width:50%; padding-left: 18px">
                                        {{ account.properties.wasl.referenceKey }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>

            <div class="col-lg-6">

                <fh-error [error]="waslError" [success]="waslSuccess"></fh-error>
                <form #waslForm="ngForm" #formDir="ngForm">
                    <div *ngIf="!loadingWasl && waslForm.invalid" class="alert alert-warning shadow">
                        <p *ngIf="waslForm.invalid">- {{'general.waslFormInvalid' | translate }}</p>
                    </div>

                    <div class="ibox float-e-margins">
                        <div class="ibox-title">
                            <h5>
                                <i class="fa fa-user-shield"></i> {{'general.wasl' | translate }}
                            </h5>
                        </div>
                        <div class="ibox-content slick" style="position:relative">
                            <ngx-loading [show]="loadingWasl"></ngx-loading>

                            <table style="width:100%" class="table table-hover nowrap dtr-inline no-footer">
                                <tbody>
                                    <tr>
                                        <td style="width:50%">
                                            {{ 'general.waslReference' | translate}}
                                        </td>
                                        <td style="width:50%; padding-left: 18px">
                                            {{ (account.properties.wasl.referenceKey ? 'general.true' : 'general.false') | translate}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ 'general.waslIdentity' | translate}}
                                            <span class="error" *ngIf="identityNumber.invalid && formMode != 1">*</span>
                                        </td>
                                        <td>
                                            <input type="text" [readonly]="formMode == 1" name="identityNumber"
                                                class="form-control table-inline"
                                                [(ngModel)]="account.properties.wasl.identityNumber" required
                                                #identityNumber="ngModel">
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            {{ 'general.commercialRecordNumber' | translate}}
                                            <span class="error"
                                                *ngIf="commercialRecordNumber.invalid && formMode != 1">*</span>
                                        </td>
                                        <td>
                                            <input type="text" [readonly]="formMode == 1" name="commercialRecordNumber"
                                                class="form-control table-inline"
                                                [(ngModel)]="account.properties.wasl.commercialRecordNumber" required
                                                #commercialRecordNumber="ngModel">
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            {{ 'general.commercialRecordIssueDateHijri' | translate}}
                                            <span class="error"
                                                *ngIf="commercialRecordIssueDateHijri.invalid && formMode != 1">*</span>
                                        </td>
                                        <td>

                                            <input type="text" [readonly]="formMode == 1"
                                                name="commercialRecordIssueDateHijri" class="form-control table-inline"
                                                [(ngModel)]="account.properties.wasl.commercialRecordIssueDateHijri"
                                                required #commercialRecordIssueDateHijri="ngModel">

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ 'general.phoneNumber' | translate}}
                                            <span class="error" *ngIf="phoneNumber.invalid && formMode != 1">*</span>
                                        </td>
                                        <td>
                                            <input type="text" [readonly]="formMode == 1" name="phoneNumber"
                                                class="form-control table-inline"
                                                [(ngModel)]="account.properties.wasl.phoneNumber" required
                                                #phoneNumber="ngModel">
                                        </td>
                                    </tr>

                                    <!-- <tr>
                                    <td>
                                        {{ 'general.extensionNumber' | translate}}
                                        <span class="error" *ngIf="extensionNumber.invalid && formMode != 1">*</span>
                                    </td>
                                    <td>
                                        <input type="text" [readonly]="formMode == 1" name="extensionNumber"
                                            class="form-control table-inline"
                                            [(ngModel)]="account.properties.wasl.extensionNumber" required
                                            #extensionNumber="ngModel">
                                    </td>
                                </tr> -->
                                    <tr>
                                        <td>
                                            {{ 'general.emailAddress' | translate}}
                                            <span class="error" *ngIf="emailAddress.invalid && formMode != 1">*</span>
                                        </td>
                                        <td>
                                            <input type="text" [readonly]="formMode == 1" name="emailAddress"
                                                class="form-control table-inline"
                                                [(ngModel)]="account.properties.wasl.emailAddress" required
                                                #emailAddress="ngModel">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ 'general.managerName' | translate}}
                                            <span class="error" *ngIf="managerName.invalid && formMode != 1">*</span>
                                        </td>
                                        <td>
                                            <input type="text" [readonly]="formMode == 1" name="managerName"
                                                class="form-control table-inline"
                                                [(ngModel)]="account.properties.wasl.managerName" required
                                                #managerName="ngModel">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ 'general.managerPhoneNumber' | translate}}
                                            <span class="error"
                                                *ngIf="managerPhoneNumber.invalid && formMode != 1">*</span>
                                        </td>
                                        <td>
                                            <input type="text" [readonly]="formMode == 1" name="managerPhoneNumber"
                                                class="form-control table-inline"
                                                [(ngModel)]="account.properties.wasl.managerPhoneNumber" required
                                                #managerPhoneNumber="ngModel">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ 'general.managerMobileNumber' | translate}}
                                            <span class="error"
                                                *ngIf="managerMobileNumber.invalid && formMode != 1">*</span>
                                        </td>
                                        <td>
                                            <input type="text" [readonly]="formMode == 1" name="managerMobileNumber"
                                                class="form-control table-inline"
                                                [(ngModel)]="account.properties.wasl.managerMobileNumber" required
                                                #managerMobileNumber="ngModel">
                                        </td>
                                    </tr>
                                    <!-- <tr>
                                    <td>
                                        {{ 'general.dateOfBirthGregorian' | translate}}
                                        <span class="error"
                                            *ngIf="dateOfBirthGregorian.invalid && formMode != 1">*</span>
                                    </td>
                                    <td>
                                        <input type="text" [readonly]="formMode == 1" name="dateOfBirthGregorian"
                                            class="form-control table-inline"
                                            [(ngModel)]="account.properties.wasl.dateOfBirthGregorian" required
                                            #dateOfBirthGregorian="ngModel">
                                    </td>
                                </tr> -->
                                </tbody>
                            </table>
                        </div>
                        <div class="ibox-footer">
                            <button class="btn btn-primary" [disabled]="userForm.invalid || formMode != 1"
                                *ngIf="!account.properties.wasl.referenceKey" 
                                (click)="register()"><i class="fa fa-fw fa-edit"></i>
                                {{'general.register' | translate }}</button>

                            <button class="btn btn-danger" [disabled]="userForm.invalid  || formMode != 1"
                                *ngIf="account.properties.wasl.referenceKey" 
                                (click)="unRegister()"><i class="fa fa-fw fa-times"></i>
                                {{'general.unRegister' | translate }}</button>

                            <button class="btn btn-primary" [disabled]="userForm.invalid  || formMode != 1"
                                *ngIf="account.properties.wasl.referenceKey"
                                (click)="inquire()"><i class="fa fa-fw fa-broadcast-tower"></i>
                                {{'general.inquire' | translate }}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</form>