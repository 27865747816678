import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Subject } from 'rxjs/Subject';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import { JobsService } from 'app/services/jobs/jobs.service';
import * as moment from 'moment-timezone';
import { AuthenticationService } from 'app/services/authentication/authentication.service';

class Person {
  id: number;
  firstName: string;
  lastName: string;
}

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: 'fh-jobs-active',
  templateUrl: 'active.template.html'
})
export class JobsActiveViewComponent implements OnInit, AfterViewInit {
  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;

  dtOptions = {};
  assets: Subject<any> = new Subject();
  persons: Person[];
  loading = false;
  data = 0;
  languageLoaded: boolean;
  timezoneIana: string;
  searchTerm: any;

  constructor(private translateService: TranslateService, private http: HttpClient, private jobservice: JobsService, private authenticationService: AuthenticationService) {
    this.timezoneIana = authenticationService.getTimeZoneIana();
  }

  ngAfterViewInit() {
  }

  seachChanged(value) {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.search(value).draw();
    });
  }



  ngOnInit(): void {
    this.translateService.get('general.date').subscribe(value => {
      this.languageLoaded = true; this.initGrid();
    });
  }

  initGrid(): void {
    const that = this;
    this.loading = true;

    this.dtOptions = {
      pagingType: 'simple_numbers',
      serverSide: false,
      processing: false,
      scrollX: true,
      colReorder: {fixedColumnsLeft: 1},
      stateSave: true,
      stateSaveCallback: function (settings, data) {
        localStorage.setItem('Columns_' + that.constructor.name, JSON.stringify(data));
      },
      stateLoadCallback: function () {
        try {
          const columnSettings = JSON.parse(localStorage.getItem('Columns_' + that.constructor.name));
          that.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
          return columnSettings;
        } catch (e) {
          console.log(e);
        }
      },
      order: [[1, 'desc']],
      ajax: (dataTablesParameters: any, callback) => {
        $('.dataTables_info').html(this.translateService.instant('grid.loadingData'));
        this.jobservice.getActiveJobs().subscribe(resp => {
          callback({
            recordsTotal: resp.length,
            recordsFiltered: resp.length,
            data: resp
          });
        });
      },
      initComplete: function (settings, json) {
        that.loading = false;
      },
      columns: [{
        data: 'isRunning',
        title: this.translateService.instant('general.running'),
        render: function (data, type, row) {
          if (data && data === true) {
            return that.translateService.instant('general.yes') + ' <i class="fa fa-fw fa-cog fa-spin"></i>';
          }
          return that.translateService.instant('general.no');
        }
      }, {
        data: 'lastRuntime',
        title: this.translateService.instant('general.lastRuntime'),
        render: function (data, type, row) {
          const date = moment.utc(data)['tz'](that.timezoneIana);
          return date.format('LLL')
        }
      }, {
        data: 'accountName',
        title: this.translateService.instant('general.account'),
      }, {
        data: 'lastStatus',
        title: this.translateService.instant('general.lastStatus')
      }, {
        data: 'nextRun',
        title: this.translateService.instant('general.nextRun'),
        render: function (data, type, row) {
          if (data) {
            const date = moment.utc(data)['tz'](that.timezoneIana);
            return date.format('LLL')
          }
          return that.translateService.instant('general.never');
        }
      }],
      pageLength: 10,
      language: {
        'lengthMenu': this.translateService.instant('grid.lengthmenu'),
        'zeroRecords': this.translateService.instant('grid.empty'),
        'info': this.translateService.instant('grid.info'),
        'infoEmpty': this.translateService.instant('grid.infoempty'),
        'infoFiltered': this.translateService.instant('grid.infofiltered'),
        'search': this.translateService.instant('grid.search'),
        'infoThousands': '.',
        'loadingRecords': this.translateService.instant('grid.loadingrecords'),
        'paginate': {
          'first': this.translateService.instant('grid.first'),
          'last': this.translateService.instant('grid.last'),
          'next': this.translateService.instant('grid.next'),
          'previous': this.translateService.instant('grid.previous'),
        },
        'aria': {
          'sortAscending': this.translateService.instant('grid.sortasc'),
          'sortDescending': this.translateService.instant('grid.sortdesc'),
        }
      },
    };
  }
}
