import { AppUser } from './user.model';

export class AccountPlatform {
    public id: string;
    public accountId: string;
    public name: string;
    public username: string;
    public password: string;
    public deviceCount: any;
    public platformId: any;
    public platformName: any;
    public platformType: number;
    public token: any;
    public url: any;
    public timezoneIana: any;
}

export class Platform {
    public id: string;
    public name: string;
}

export class AppAccountPlatform {
    public platformName: string;
    public url: string;
    public username: string;
    public password: string;
    public platformType: number;
    public token: string;
}

export class Account {
    public id: string;
    public name: string;
    public createdDate: any
    public identifier: number;
    public limitHistoryInDays: number;
    public userCount: number;
    public deviceCount: number;
    public platformCount: number;
    public status: number;
}

export class AppAccount {
    public userRequest: AppUser;
    public accountPlatformRequest: AppAccountPlatform[];
    public name: string;
    public identifier: number;
    public limitHistoryInDays: number;
    public status = 1;
}

export class AddressDetails {
    public street: string;
    public no: string;
    public country: string;
    public phone: string;
    public ext: string;
    public mobile: string;
    public email: string;
    public zip: string;
}
