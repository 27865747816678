<div class="ibox-content slick" style="position:relative">
    <table style="width:100%" class="table table-hover nowrap dtr-inline no-footer">
        <tbody *ngIf="!account">
            <tr>
                <td colspan="4">
                    {{ 'general.noAccount' | translate}}
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="account">
            <tr>
                <td style="width:50%">
                    {{ 'general.name' | translate}}
                    <span class="error" *ngIf="name.invalid && formMode != 1">*</span>
                </td>
                <td colspan="2" style="width:50%">
                    <input type="text" [readonly]="formMode == 1" name="name" class="form-control table-inline"
                        [(ngModel)]="account.name" required minlength="3" #name="ngModel">
                </td>
            </tr>
            <tr>
                <td style="width:50%">
                    {{ 'general.createdDate' | translate}}
                </td>
                <td colspan="2" style="width:50%">

                    <input type="text" [readonly]="1" name="identifier" class="form-control table-inline"
                        placeholder="{{ account?.createdDate | amDateFormat: 'll' }}">
                </td>
            </tr>
            <tr>
                <td style="width:50%">
                    {{ 'general.identifier' | translate}}
                    <span class="error" *ngIf="identifier.invalid && formMode != 1">*</span>
                </td>
                <td colspan="2" style="width:50%">
                    <input type="text" [readonly]="formMode == 1" name="identifier" class="form-control table-inline"
                        [(ngModel)]="account.identifier" required minlength="1" #identifier="ngModel">
                </td>
            </tr>
            <tr>
                <td style="width:50%">
                    {{ 'general.limitHistoryInDays' | translate}}
                    <span class="error" *ngIf="limitHistoryInDays.invalid && formMode != 1">*</span>
                </td>
                <td colspan="2" style="width:50%">
                    <input type="text" [readonly]="formMode == 1" name="limitHistoryInDays"
                        class="form-control table-inline" [(ngModel)]="account.limitHistoryInDays" required
                        minlength="1" #limitHistoryInDays="ngModel">
                </td>
            </tr>
            <tr>
                <td>
                    {{ 'general.accountType' | translate}}
                </td>
                <td>
                    <select class="form-control table-inline" [disabled]="formMode == 1" name="ac_status" [(ngModel)]="account.status"
                        style="width:100%">
                        <option *ngFor="let typeAccount of accountTypes" [ngValue]="typeAccount.id">
                            {{ 'enums.accountType.' + typeAccount.name | translate }}
                        </option>
                    </select>
                </td>
            </tr>
        </tbody>
    </table>
</div>