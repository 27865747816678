import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { throwError } from 'rxjs/internal/observable/throwError';

import * as moment from 'moment';

import { AuthenticationService } from '../authentication/authentication.service';
import { Schedule } from '../../models/schedule.model';
import { Location } from '../../models/location.model';
import { LoggingService } from '../logging/logging.service';
import { ScheduleActions } from 'app/common/enums';
import { Device } from '../../models/device.model';

@Injectable()
export class ScheduleService {
    token: string;
    timestamp;
    url = '';
    ScheduleActionDescriptions: string[] = [];

    constructor(private http: HttpClient, private loggingService: LoggingService, private authenticationService: AuthenticationService) {
        this.url = authenticationService.getWebserviceURL('schedule');
        this.token = authenticationService.getAuthToken();
    }

    getScheduleById(id: number): Observable<Schedule> {

        this.token =  this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('Fetch schedule by id ' + id);
        return this.http.get(this.url + id, { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseReponseDetails(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getSchedulesForDevice(id: string): Observable<Schedule[]> {

        this.token =  this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        return this.http.get(this.url + 'device/' + id, { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getSchedulesForIssue(id: string): Observable<Schedule[]> {

        this.token =  this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        return this.http.get(this.url + 'issue/' + id, { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    processSchedulesForIssue(id: number, schedule: Schedule): Observable<any> {

        this.token =  this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        return this.http.delete(this.url + id, { headers: headers })
            .map((data) => { console.log(data); })
            .catch(this.handleError);
    }

    getSchedules(): Observable<Schedule[]> {

        this.token =  this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting schedule from service');
        return this.http.get(this.url, { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    private handleError(error: Response) {
        return throwError(error);
    }

    addSchedule(schedule: Schedule): Observable<any> {

        this.token =  this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        return this.http.post(this.url, schedule, { headers: headers })
            .catch(this.handleError);
    }

    updateSchedule(schedule: Schedule): Observable<any> {

        this.token =  this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        return this.http.put(this.url, schedule, { headers: headers })
            .catch(this.handleError);
    }


    parseResponse(json: any): Schedule[] {

        this.loggingService.log(this.constructor.name, 'Retrieved ' + json.length + ' schedules.');

        const ident = 1;
        const schedules: Schedule[] = [];

        // this.loggingService.log(json);
        json.forEach(item => {
            //   this.loggingService.log(this.constructor().name + " - New", item);
            const schedule = this.parseReponseDetails(item);
            schedules.push(schedule);
        });

        this.timestamp = new Date().getTime();
        return schedules;
    }

    parseReponseDetails(item): Schedule {

        const schedule = new Schedule();
        schedule.id = item.id;
        schedule.date = moment(item.date.replace(' ', '')).toDate();
        schedule.actionId = item.actionId;
        schedule.actionName = item.actionName;
        schedule.deviceId = item.deviceId;
        schedule.deviceName = item.deviceName;
        schedule.workflowId = item.workflowId;
        schedule.workflowName = item.workflowName;
        schedule.userId = item.userId;
        schedule.userName = item.userName;
        schedule.issueId = item.issueId;

        if (item.device) {
            const device = new Device();
            device.id = item.device.id;
            schedule.deviceId = item.device.id;
            device.createdDate = moment(item.device.createdDate.replace(' ', '')).toDate();
            device.name = item.device.name;
            schedule.deviceName = item.device.name;
            device.lastCommunication = moment(item.device.lastCommunication.replace(' ', '')).toDate();
            device.modelId = item.device.model;
            device.modifiedDate = moment(item.device.modifiedDate.replace(' ', '')).toDate();
            device.phone = item.device.phone;
            device.referenceId = item.device.referenceId;
            device.unitId = item.device.unitId;
            device.unitId2 = item.device.unitId2;
            schedule.device = device;
        }

        if (item.user) {
            schedule.userId = item.user.id;
        }
        return schedule;
    }
}
