import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DeviceService } from '../../services/device/device.service';
import { TagObjectType } from 'app/models/tag.model';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { AssetTypeService } from 'app/services/asset/assetTypes.service';
import { FormMode } from 'app/common/enums';
import { AssetType } from 'app/models/assetType.model';

@Component({
    selector: 'fh-fh-assettype-details',
    templateUrl: 'assetTypeDetails.template.html'
})
export class AssetTypeDetailsViewComponent implements OnInit {
    sub: any;
    assetType: any;

    tagType = TagObjectType.AssetType.valueOf();
    formMode = FormMode.read;
    notEditableNotification = 'Note! Only custom created assettypes can be updated';

    loadingAssetType = false;
    error: any;

    options;

    permissions: {};

    assetTypeGroups = [];
    objectKeys = Object.keys;
    success: { statusText: string; success: string; };
    isImpersonated: boolean;

    constructor(private authentication: AuthenticationService, private assetTypeService: AssetTypeService, private deviceService: DeviceService, private route: ActivatedRoute) {
        this.assetType = new AssetType();
    }

    groupBy = function (xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, []);
    };

    ngOnInit() {
        this.permissions = this.authentication.permissions;
        this.isImpersonated = this.authentication.getIsImpersonated();

        this.loadingAssetType = true;

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            const assetFromService = this.assetTypeService.getAssetTypeById(id).subscribe(assetType => {
                this.assetType = assetType;
                if (this.assetType.properties) {
                    this.assetTypeGroups = this.groupBy(this.assetType.properties, 'Type');
                    this.loadingAssetType = false;
                } else {
                    this.loadingAssetType = false;
                }
            });
        });
    }

    getAssetType() {
        const assetFromService = this.assetTypeService.getAssetTypeById(this.assetType.id).subscribe(assetType => {
            this.assetType = assetType;
            if (this.assetType.properties) {
                this.assetTypeGroups = this.groupBy(this.assetType.properties, 'Type');
                this.loadingAssetType = false;
            } else {
                this.loadingAssetType = false;
            }
        });
    }

    // Form

    onDelete() {
        this.error = 'Deleting is not implemented';
    }

    onSave() {
        this.loadingAssetType = true;

        const temp = [];
        this.assetTypeGroups.forEach(mainCat => {
            mainCat.forEach(subcat => {
                temp.push(subcat);
            });
        });
        this.assetType.properties = JSON.stringify(temp);

        this.assetTypeService.updateAssetType(this.assetType).subscribe(result => {
            this.loadingAssetType = false;

            this.success = {
                statusText: 'Success',
                success: 'Asset is successfull updated'
            };

            this.error = null;

            this.getAssetType();

        }, error => {
            this.error = error
            this.loadingAssetType = false;
        });
    }

    setFormMode(mode) {
        this.formMode = mode;

        if (this.formMode === FormMode.read) {
            this.loadingAssetType = true;
            this.getAssetType();
        }
    }

}

