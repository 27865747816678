import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AssetService } from '../../services/asset/asset.service';
import { DeviceService } from '../../services/device/device.service';
import { tileLayer, latLng, circle, polygon, Map, marker, icon } from 'leaflet';
import { Asset } from 'app/models/asset.model';

declare var L;

@Component({
    selector: 'fh-fh-trailer-assets',
    templateUrl: 'assets.template.html'
})
export class TrailerAssetsViewComponent implements OnInit {
    sub: any;
    asset: any;
    device: any;

    loadingAsset = false;
    loadingTrailers = false

    error: any;

    map: Map;
   defaultMap;

    options;
    marker;
    assets: Asset[] = [];

    constructor(private assetService: AssetService, private deviceService: DeviceService, private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.loadingAsset = true;
        this.loadingTrailers = true;

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            const assetFromService = this.assetService.getAssetById(id).subscribe(asset => {
                this.asset = asset;
                this.loadingAsset = false;
            });

            const assetsFromService = this.assetService.getAssetsByChildId(id, 1).subscribe(assets => {
                this.assets = assets;
                this.loadingTrailers = false;
            }, error => {
                this.error = error
                this.loadingTrailers = false;
            });
        });
    }
}
