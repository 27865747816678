import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { of } from 'rxjs/observable/of';
import { throwError } from 'rxjs/internal/observable/throwError';

import * as moment from 'moment';

import { AuthenticationService } from '../authentication/authentication.service';
import { ResolutionType } from '../../models/resolutiontype.model';
import { Location } from '../../models/location.model';
import { LoggingService } from '../logging/logging.service';

@Injectable()
export class ResolutionTypeService {
    token: string;
    timestamp;
    url = '';
    public ResolutionTypes: ResolutionType[] = [];

    constructor(private http: HttpClient, private loggingService: LoggingService, private authenticationService: AuthenticationService) {
        this.url = authenticationService.getWebserviceURL('resolutiontype');
        this.token = authenticationService.getAuthToken();
    }

    getResolutionTypes(setDefinate = true): Observable<ResolutionType[]> {

        if (this.ResolutionTypes.length !== 0) {
            return of(this.ResolutionTypes);
        }

        this.token =  this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting resolutiontypes from service');
        return this.http.get(this.url, { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                if (setDefinate) {
                    console.log('setting ResolutionTypes');
                    this.ResolutionTypes = parsedResponse;
                }
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    private handleError(error: Response) {
        console.log(error);
        return throwError(error);
    }

    parseResponse(json: any): ResolutionType[] {
        // this.loggingService.log(this.constructor.name, 'Retrieved ' + json.length + ' ResolutionTypes.');

        const resolutionTypes: ResolutionType[] = [];

        json.forEach(item => {
            //   this.loggingService.log(this.constructor().name + " - New", item);
            const resolutionType = new ResolutionType();
            resolutionType.id = item.id;
            resolutionType.name = item.name;
            resolutionType.nameTranslated = item.name;
            resolutionType.count = 0;

            resolutionTypes.push(resolutionType);
        });

        this.timestamp = new Date().getTime();
        return resolutionTypes;
    }
}
