import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs/Subject';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Observable } from 'rxjs/Observable';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import { IssueTypeService } from '../../services/issuetypes/issuetypes.service';
import { languages } from '../../common/enums';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
import { BsLocaleService } from 'ngx-bootstrap';
window['moment'] = Moment;
mTZ()

@Component({
  selector: 'fh-language-settings',
  templateUrl: 'language.template.html'
})
export class LanguageSettingsComponent implements OnInit {
  loading: boolean;
  dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;

  languages;
  langData
  dtTrigger: Subject<any> = new Subject();
  langDataArray = [];
  currentLang: any;

  constructor(private localeService: BsLocaleService, private http: HttpClient, private translate: TranslateService, private issueTypeService: IssueTypeService) {
  }

  onChange($event, lang) {
    this.loading = true;
    const that = this;

    this.http.get(lang)
      .map(this.extractData)
      .subscribe(langData => {
        this.langData = langData;
        this.loading = false;
      });

    if (lang) {
      lang = lang.replace('./assets/i18n/', '');
      lang = lang.replace('.json', '');
      this.translate.use(lang);
      Moment.locale(lang);
      this.localeService.use(lang);
    }

    this.issueTypeService.IssueTypes = [];
  }

  setLangtagMode() {
    console.log('Setting langtag mode');
  }



  seachChanged(value) {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.search(value).draw();
    });
  }

  ngOnInit(): void {

    // https://stackoverflow.com/questions/47606201/how-could-you-implement-a-database-approach-to-angular-internationalization

    this.currentLang = this.translate.currentLang;
    this.onChange(null, './assets/i18n/' + this.currentLang + '.json');

    this.languages = Object.keys(languages)
      .filter(k => typeof languages[k] === 'string')
      .map(n => ({ name: n, path: languages[n] }));


    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
    };
  }

  private extractData(res: Response) {
    this.langDataArray = [];
    // tslint:disable-next-line:forin
    for (const key in res) {
      const value = res[key];
      const object = { tag: key, text: value };
      this.langDataArray.push(object);
    }
    return this.langDataArray || {};
  }

}
