<div class="row border-bottom white-bg dashboard-header">
    <div class="col-sm-12" style="padding: 15px">
        <h2>
            <i class="fas fa-fw fa-question-circle"></i> {{'search.header' | translate }}</h2>
        {{'search.intro' | translate }}
    </div>
</div>

<div class="wrapper wrapper-content">
    <div class="row">
        <div class="col-lg-12">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-search"></i> {{'search.search' | translate }}</h5>
                </div>
                <div class="ibox-content">
                    <div style="float: left; width: calc(100% - 40px); ">
                        <input type="text" [(ngModel)]='searchString' placeholder="item..."
                            class="typeahead_1 form-control" />
                    </div>
                    <div style="float: left; width: 30px;">
                        <button type="button" style="text-align: left; margin-left: 2px;"
                            class="btn btn-outline btn-primary">
                            <i class="fas fa-fw fa-search"></i>
                        </button>
                    </div>
                    <div style="clear: both;"></div>
                </div>
            </div>
        </div>
    </div>
</div>