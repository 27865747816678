
export enum SettingsLevel {
    General = 0,
    Account = 1,
    Platform = 2,
    Customer = 3,
    DeviceType = 4,
    Device = 5,
    None = 6
}

export enum SidebarOptions {
    City = 0,
    Brand = 1,
    Model = 2,
    Driver = 3,
    PlateNumber = 4,
    ShowTimeStamp = 5,
    ShowLastStateChange = 6,
    GroupByState = 7,
    Geofence = 8
}
export enum languages {
    nl = './assets/i18n/nl.json',
    en = './assets/i18n/en.json',
    ar = './assets/i18n/ar.json',
    ru = './assets/i18n/ru.json',
    de = './assets/i18n/de.json',
    ug = './assets/i18n/ug.json',
    fr = './assets/i18n/fr.json',
    zh = './assets/i18n/zh.json',
    pt = './assets/i18n/pt.json',
    zw = './assets/i18n/en-zw.json',
    es = './assets/i18n/es.json',
    lt = './assets/i18n/lt.json'
}

export enum whitelabels {
    l360 = 'Default (360)',
    ls = 'Blue (LS)',
    blue = 'Blue',
    ginger = 'Ginger',
    lightblue = 'Light blue',
    modern = 'Modern',
    // wasl = 'WASL',
}

export enum PermissionsBitmask {
    HasUserAdministration = 2,
    HasAccountAdministration = 4, //
    IsAdministrator = 8,
    HasDrivers = 16,
    HasCalculationSettings = 32,
    HasScheduleView = 64, //
    HasReporting = 128,
    HasCustomersViewOwn = 256,
    HasCustomersViewAll = 512,
    HasPlatforms = 1024,
    HasScheduleProcessOwn = 2048,
    HasScheduleProcessAll = 4096, //
    HasWorkflow = 8192,
    HasDeviceTypes = 16384,
    HasJobs = 32768,
    HasDevices = 65536,
    HasIssues = 131072,
    HasAssets = 262144,
    HasWasl = 524288
}

export enum IssueTypeEnums {
    Unknown = 0,
    TooManyLocations = 1,
    FarTooManyMessages = 2,
    NoCommunication = 3,
    TooManyPowerlossEvents = 4,
    NoIgnitionSignal = 5,
    LooseConnectionOrIgnitionWire = 6,
    BadGpsReception = 7,
    TooFewLocations = 9,
    TooMuchDistance = 13,
    TooMuchDistancePerMessage = 14,
    SensorStuck = 15,
    SensorOutOfBounds = 16,
    SensorNoData = 17,
    FuelPercentageSensorStuck = 18,
    FuelPercentageSensorOutOfBounds = 19,
    FuelPercentageSensorNoData = 20,
    TemperatureSensorStuck = 21,
    TemperatureOutOfBounds = 22,
    TemperatureNoData = 23,
    OdoSensorStuck = 24,
    OdoOutOfBounds = 25,
    OdoNoData = 26,
    HighDataConsumptionMinimumMotion = 27,
    BatteryPowerTooLow = 28,
    OutlierTime = 29,
    OutlierDistance = 30,
    NoCommunicationPowerLost = 31,
    BackupBatteryTooLow = 32,
    MissingDriver = 33,
    GpsDrift = 34,
    DelayedMessages = 35,
    GpsStuck = 36,
    FuelLevelSensorStuck = 37,
    FuelLevelSensorOutOfBounds = 38,
    FuelLevelSensorNoData = 39
}

export enum Uom {
    None = 0,
    Messages = 1,
    Meters = 2,
    Count = 3,
    FuelPercentage = 4,
    TemperatureInCelcius = 5,
    Minutes = 6,
    Voltage = 7,
    Seconds = 8,
    FuelLevel = 9
}

export enum DistanceUnits {
    Kilometers = 0,
    Miles = 1
}

export enum VolumeUnits {
    Liters = 0,
    Imperial_Gallon = 1,
    US_Liquid_Gallon = 2
}

export enum MapType {
    Wiki = 1,
    OpenStreetMapsVector = 2,
    OpenStreetMapsVectorBright = 3,
    OpenStreetMap = 4,
    OpenStreetMapLocationSolutions = 5,
    OpenStreetMapCarto = 6,
    GoogleMaps = 7,
    GoogleMapsHybrid = 19,
    GoogleMapsSatellite = 8,
    BingMaps = 9,
    BingMapsSatellite = 10,
    Esri = 11,
    CartoDB = 12,
    MapboxVector = 13,
    MapboxStreets = 14,
    MapboxOutdoors = 15,
    MapboxSatellite = 16,
    MapboxSatelliteStreets = 17,
    ArcGIS = 18
}

export enum FormMode {
    read = 1,
    add = 2,
    edit = 3
}

export enum ScheduleActions {
    'Unknown' = 0,
    'Fix wiring' = 1,
    'Check device' = 2,
    'Replace device' = 3,
    'Update device firmware' = 4,
    'New roadtest' = 5
}

export enum Severity {
    Unknown = 0,
    low = 1,
    Medium = 2,
    High = 3,
    Top = 4,
    Critical = 5
}

export enum AccountType {
    Unknown = 0,
    Trial = 1,
    Normal = 2
}

export enum NotificationCode {
    None = 0,
    ApiError = 1,
}
