import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/switchMap';
import { of } from 'rxjs/observable/of';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { LoggingService } from '../logging/logging.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { throwError } from 'rxjs/internal/observable/throwError';

@Injectable()
export class SearchService {
    token: string;
    url: string;

    constructor(private translateService: TranslateService, private http: HttpClient, private loggingService: LoggingService, private authenticationService: AuthenticationService) {
        this.url = authenticationService.getWebserviceURL('search');
        this.token = authenticationService.getAuthToken();
    }

    searchEntries(term): Observable<any> {

        if (term) {

            console.log('Search for ' + term);

            this.token = this.authenticationService.getAuthToken();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

            return this.http.get(this.url + term, { headers: headers })
                .map((data) => {
                    const parsedResponse = this.parseResponse(data);
                    console.log('Return list ' + parsedResponse.length);
                    return parsedResponse;
                })
                .catch((error: any) => throwError(error || 'Server error'));
        } else {
            return of([]);
        }
    }

    parseResponse(data) {
        const responseArray = [];
        data.forEach(item => {
            const response = { id: item.id, name: item.name, group: item.searchResultType, groupname: this.translateService.instant('enums.searchresulttypes.' + item.searchResultType) };
            responseArray.push(response);
        });
        return responseArray;
    }
}
