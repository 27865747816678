<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
        <ol class="breadcrumb">
            <li>
                <a href="/#/"><i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Accounts/Overview">{{'general.accounts' | translate }}</a>
            </li>
            <li>
                <a href="/#/AccountDetails/Index/{{account?.id}}">{{account?.name}}</a>
            </li>
            <li class="active">
                <strong>{{'menu.calculationsettings' | translate }}</strong>
            </li>
        </ol>
    </div>
</div>

<fh-general-calculationsettings [account]="account"></fh-general-calculationsettings>