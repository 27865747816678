<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Users/Overview">{{'general.users' | translate }}</a>
            </li>
            <li class="active">
                <strong>{{'menu.add' | translate }}</strong>
            </li>
        </ol>
    </div>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="">
        <div class="col-lg-12">
            <fh-error [autoRemove]="false" [error]="error"></fh-error>
            <div class="ibox float-e-margins" style="position:relative">
                <ngx-loading [show]="loading"></ngx-loading>
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-users"></i> {{'menu.addUser' | translate }}
                    </h5>
                </div>
                <form #userForm="ngForm" #formDir="ngForm">
                    <fh-user-details [accounts]="accounts" [user]="user" [formMode]="formMode"></fh-user-details>
                    <fh-save-footer [valid]="!userForm.invalid" (onInsert)="onInsert()" [formMode]="formMode">
                    </fh-save-footer>
                </form>
            </div>
        </div>
    </div>
</div>