<!-- Saving and loading grid state -->
<fh-error [error]="error" [success]="success" autoRemove=false></fh-error>

<div class="ibox float-e-margins">
    <div class="ibox-title">
        <h5>
            <i class="fas fa-fw fa-tasks"></i> {{'general.presets' | translate }}</h5>
    </div>
    <div class="ibox-content slick" style="position: relative">
        <div style="float:left; width: 50%; padding-right: 2px">
            <input type="text" name="preset_name" [(ngModel)]="preset_name" class="form-control" style="width: 100%">
        </div>
        <div style="float:left; width: 50%; padding-left: 2px">
            <button id="submit " name="submit " class="btn btn-primary " style="width: 100%"
                [disabled]="preset_name == ''" (click)="savePreset()">
                <i class="fas fa-fw fa-save "></i> {{ 'general.saveState' | translate }}</button>
        </div>
        <div style="clear:both"></div>
    </div>
    <div class="ibox-content slick" style="position: relative; padding:0px">
        <ul class="list-group" style="margin:0">
            <li class="list-group-item" (click)="clearGridPreset()"> <i class="fas fa-fw fa-times-circle "></i>
                {{ 'general.clearSettings' | translate }}
            </li>
            <li *ngFor="let preset of presetList | orderBy: 'name'" class="list-group-item"
                (click)="loadGridPreset(preset)">
                <i class="fas fa-fw fa-receipt "></i> {{ preset.name }}
                <i class="fas fa-fw fa-times " style="float: right;" (click)="deletePreset(preset.name)"></i>
            </li>
        </ul>
    </div>
</div>