<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
        <ol class="breadcrumb">
            <li>
                <a href="/#/"><i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Users/Overview">{{'general.users' | translate }}</a>
            </li>
            <li class="active">
                <strong>{{'menu.log' | translate }}</strong>
            </li>
        </ol>
    </div>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="">
        <fh-logger [logItems]="userLog" [loading]="loading"></fh-logger>
    </div>
</div>