<div class="ibox float-e-margins">
    <div class="ibox-title">
        <h5>
            <i class="fa fa-fw fa-object-group"></i> {{'general.assetType' | translate }}
        </h5>
        <span class="pull-right" *ngIf="asset && formMode == 1">
            <i [routerLink]="['/AssetTypeDetails/Index/', asset.assetTypeId ]"
                style=" font-size: 1.3em; cursor: pointer;" class="fas fa-fw fa-2x fa-arrow-circle-right"></i>
        </span>
    </div>
    <div class="ibox-content slick" style="position:relative">
        <ngx-loading [show]="loading"></ngx-loading>
        <table style="width:100%" class="table table-hover nowrap dtr-inline no-footer">
            <tbody>
                <tr>
                    <td style="width:50%">
                        {{ 'general.name' | translate}}
                        <span class="error" *ngIf="!asset.assetTypeId && formMode != 1">*</span>
                    </td>
                    <td style="width:50%">
                        {{ asset?.assetTypeName}}
                    </td>
                </tr>
                <tr>
                    <td style="width:50%">
                        {{ 'general.class' | translate}}
                    </td>
                    <td style="width:50%">
                        {{ ('enums.assetClass.' + asset?.assetClass) | translate }}
                    </td>
                </tr>
                <tr>
                    <td style="width:50%">
                        {{ 'general.brand' | translate}}
                    </td>
                    <td style="width:50%">
                        {{asset?.brand}}
                    </td>
                </tr>
                <tr>
                    <td style="width:50%">
                        {{ 'general.model' | translate}}
                    </td>
                    <td style="width:50%">
                        {{asset?.model}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="ibox-footer">
        <button *ngIf="formMode == 1" class="btn btn-primary" (click)="showChooser()">
            <i class="fa fa-fw fa-exchange-alt"></i>
            {{'general.edit' | translate }}
        </button>
        <button *ngIf="formMode == 2" class="btn btn-primary" (click)="showChooser()">
            <i class="fa fa-fw fa-exchange-alt"></i>
            {{'general.choose' | translate }}
        </button>
    </div>
</div>

<fh-assettype-chooser #assetTypeChooser [asset]="asset" (onSelect)="updateAssetType($event)"></fh-assettype-chooser>