import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Subject } from 'rxjs/Subject';
import { DeviceService } from '../../services/device/device.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceType } from 'app/models/devicetype.model';
import { IssueService } from 'app/services/issues/issues.service';
import { Device } from 'app/models/device.model';
import { DeviceTypeService } from 'app/services/devicetypes/devicetypes.service';
import { IssueTypeService } from 'app/services/issuetypes/issuetypes.service';
import { IssueType } from 'app/models/issuetype.model';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import { AccountPlatformService } from '../../services/platform/accountPlatform.service';
import { Platform } from '../../models/platform.model';
import { AuthenticationService } from 'app/services/authentication/authentication.service';


import { AssetService } from 'app/services/asset/asset.service';
import { DriverService } from 'app/services/driver/driver.service';
import { Asset } from 'app/models/asset.model';
import { Driver } from 'app/models/driver.model';
import { DeleteModalComponent } from '../shared/usercontrols/deleteModal.component';
import { DriverChooserComponent } from './driverChooser.component';
import { BsDaterangepickerConfig, BsDatepickerConfig } from 'ngx-bootstrap';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
window['moment'] = Moment;
mTZ()

@Component({
    selector: 'fh-asset-drivers',
    templateUrl: 'assetDrivers.template.html'
})
export class AssetDriversViewComponent implements OnInit {
    @ViewChild(DataTableDirective)
    datatableElement: DataTableDirective;

    @ViewChild('deleteModal') deleteModal: DeleteModalComponent;
    @ViewChild('driverChooser') driverChooser: DriverChooserComponent;

    loading = false;
    loadingDrivers = false;

    issueTypes: IssueType[];
    deviceTypes: DeviceType[];

    sub;
    dtOptions = {};
    devices: Device[];
    deviceType: DeviceType;
    platform: Platform;
    languageLoaded: boolean;
    timezoneIana: string;
    asset: Asset;
    drivers: Driver[];

    showPopup: any = { show: false };

    fromTime = new Date();
    toTime = undefined;

    linkItem: any = { id: null, to: null, from: new Date() };
    error: any;
    success: any;
    assetId: any;

    // Datepicker
    public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();


    constructor(private authenticationService: AuthenticationService, private translateService: TranslateService, private assetService: AssetService, private http: HttpClient, private deviceService: DeviceService, private issueTypeService: IssueTypeService, private driverService: DriverService, private route: ActivatedRoute, private router: Router) {
        this.deviceType = null;
        this.timezoneIana = this.authenticationService.getTimeZoneIana();

        this.dpConfig.containerClass = 'theme-default'; // or whatever color
        this.dpConfig.dateInputFormat = 'll';
        this.dpConfig.isAnimated = true;

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];
            this.assetId = id;

            this.assetService.getAssetById(id).subscribe(asset => {
                this.asset = asset;
            });

            this.loadDrivers();
        });
    }

    loadDrivers() {
        this.loadingDrivers = true;
        this.driverService.getDriversByAssetId(this.assetId).subscribe(resp => {
            this.drivers = resp;
            this.loadingDrivers = false;
        });
    }

    onSelect(event) {
        this.linkItem.id = event.id;
        this.linkItem.name = event.name;
    }

    editMode(isEditMode) {
        const toolbox = document.getElementById('grid-toolbox');

        console.log(isEditMode);
        if (isEditMode) {
            toolbox.classList.remove('hidden');
        } else {
            toolbox.classList.add('hidden');
        }
    }

    seachChanged(value) {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.search(value).draw();
        });
    }

    ngOnInit(): void {
    }

    showAddPopup() {
        this.driverChooser.showModal();
    }

    onToDate(event) {
        console.log(event, 'todateset');

        if (this.toTime == null && event !== null) {
            this.toTime = new Date();
        }
    }

    resetToDate() {
        this.linkItem.to = null;
        this.toTime = null;
    }

    saveNew() {
        console.log('new save');

        const fromTime = Moment.utc(this.linkItem.from)
            .hours(Moment.utc(this.fromTime)['tz'](this.timezoneIana).hours())
            .minutes(Moment.utc(this.fromTime)['tz'](this.timezoneIana).minutes())
            .seconds(0)
            .valueOf();

        let toTime = null;
        if (this.linkItem.to) {
            toTime = Moment.utc(this.linkItem.to)['tz'](this.timezoneIana).valueOf();

            toTime = Moment.utc(this.linkItem.to)
                .hours(Moment.utc(this.toTime)['tz'](this.timezoneIana).hours())
                .minutes(Moment.utc(this.toTime)['tz'](this.timezoneIana).minutes())
                .seconds(0).valueOf();
        }

        console.log(fromTime, toTime);

        this.loadingDrivers = true;
        this.assetService.linkDriver(this.asset.id, this.linkItem.id, fromTime, toTime).subscribe(result => {
            this.driverChooser.hideModal();
            this.success = 'Driver is linked';
            this.error = null;
            this.loadingDrivers = false;
            this.loadDrivers();
            this.linkItem = { id: null, to: new Date(), from: new Date() };
        }, error => {
            this.driverChooser.hideModal();
            this.error = error
            this.loadingDrivers = false;
            this.loadDrivers();
        });
    }

    deleteAssetDriver(event) {

        this.loadingDrivers = true;
        this.assetService.unLinkDriver(event.id, event.permanent).subscribe(result => {
            this.deleteModal.hideModal();
            this.success = 'Driver is unlinked';
            this.error = null;
            this.loadingDrivers = false;
            this.loadDrivers();
        }, error => {
            this.deleteModal.hideModal();
            this.error = error
            this.loadingDrivers = false;
            this.loadDrivers();
        });
    }

    // Modal

    showModal(id, permanent) {
        this.deleteModal.showModal({ id, permanent });
    }

    hideModal() {
        this.deleteModal.hideModal();
    }
}
