import { Component, Input, OnInit } from '@angular/core';
import { FormMode } from 'app/common/enums';
import { Driver } from 'app/models/driver.model';
import { ControlContainer, NgForm } from '@angular/forms';
import { AccountPlatformService } from 'app/services/platform/accountPlatform.service';
import { BsDatepickerConfig } from 'ngx-bootstrap';

@Component({
    selector: 'fh-driver-details',
    templateUrl: 'driverDetails.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class DriverDetailsComponent implements OnInit {

    @Input() driver: Driver;
    @Input() formMode: FormMode = FormMode.read;

    // Datepicker
    public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

    accountPlatforms = [];

    constructor(private accountPlatform: AccountPlatformService) {
    }

    ngOnInit(): void {

        // Datepicker
        this.dpConfig.containerClass = 'theme-default'; // or whatever color
        this.dpConfig.dateInputFormat = 'll';
        this.dpConfig.isAnimated = true;

        this.accountPlatform.getAccountPlatforms().subscribe((accountPlatformsResult) => {
            if (this.formMode === 2) {
                this.driver.accountPlatformId = accountPlatformsResult[0].id;
            }

            this.accountPlatforms = accountPlatformsResult;
        })
    }
}

