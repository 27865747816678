<div class="modal-header" style="padding: 5px; overflow: hidden;position: relative;">
    <div class="f1-progress">
        <div class="f1-progress-line" data-now-value="15" data-number-of-steps="4" [style.width]="progressWidth"></div>
    </div>
    <div class="f1-steps">
        <div class="f1-step" *ngFor="let stepItem of steps" [ngClass]="stepItem.id < step ? 'activated' : stepItem.id == step ? 'active' : '' ">
            <div class="f1-step-icon">
                <i class="fa" [ngClass]="stepItem.icon"></i>
            </div>
            <p>{{stepItem.title}}</p>
        </div>
    </div>
</div>

<div class="modal-content" style="padding:15px; border:0; box-shadow: none">
    <ng-content>
    </ng-content>
</div>

<div class="modal-footer">
    <div class="my-wizard__footer">
        <button class="btn btn-primary" style="min-width:120px" [style.visibility]="isOnFirstStep() ? 'hidden' : 'visible'" (click)="handlePrevious()">
            <i class="fa fa-chevron-left"></i> {{ 'grid.previous' | translate }}
        </button>
        {{step}} / {{stepCount}}
        <button class="btn btn-primary" style="min-width:120px" *ngIf="!isOnFinalStep()" [disabled]="checkDisabled()" (click)="handleNext()">
            {{ 'grid.next' | translate }}
            <i class="fa fa-chevron-right"></i>
        </button>
        <button class="btn btn-primary" style="min-width:120px" *ngIf="isOnFinalStep()" [disabled]="checkDisabled()" (click)="handleFinish()">
            <i class="fa fa-save"></i> {{ 'grid.finish' | translate }}
        </button>
    </div>
</div>