import { Component, OnInit, AfterViewInit } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { DeviceService } from '../../services/device/device.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Device } from 'app/models/device.model';
import { AuditLogService } from '../../services/auditlog/auditlog.service';
import { AuditLog } from '../../models/auditlog.model';
import { DriverService } from 'app/services/driver/driver.service';
import { Driver } from 'app/models/driver.model';
import { AccountService } from 'app/services/accounts/accounts.service';
import { InventoryAccount } from 'app/models/account.model';

@Component({
    selector: 'fh-account-log',
    templateUrl: 'accountLog.template.html'
})
export class AccountLogViewComponent implements OnInit {
    accountLog = [];
    sub;
    loading = false;
    account: InventoryAccount;

    constructor(private http: HttpClient, private auditLogService: AuditLogService, private accountService: AccountService, private route: ActivatedRoute, private router: Router) {
        this.account = null;
        this.accountLog = [];
    }

    ngOnInit() {
        this.loading = true;
        this.account = new InventoryAccount;
        this.account.id = ''

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];
            this.accountService.getAccountById(id).subscribe(account => {
                this.account = account;

                if (this.account == null) {
                    this.router.navigate(['/Accounts/Overview'])
                }

                this.auditLogService.getAuditLogByAccount(id).subscribe(accountLog => {
                    console.log('loading done');
                    this.accountLog = accountLog;
                    this.loading = false;
                });

            });
        });
    }
}
