import { Component, OnInit, AfterViewInit } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Subject } from 'rxjs/Subject';
import { DeviceService } from '../../services/device/device.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Device } from 'app/models/device.model';
import { ErrorLog } from '../../models/errorlog.model';
import { ErrorLogService } from '../../services/logging/error-log.service';

@Component({
    selector: 'fh-error-log',
    templateUrl: 'errorlog.component.html'
})
export class ErrorLogComponent implements OnInit {
    errorLog: ErrorLog[];
    sub;
    loading = false;


    constructor(private http: HttpClient, private errorLogService: ErrorLogService, private deviceService: DeviceService, private route: ActivatedRoute, private router: Router) {
        this.errorLog = [];
    }

    ngOnInit() {
        this.loading = true;
        this.errorLogService.getErrorLogs().subscribe(errorLog => {
            this.errorLog = errorLog;
            this.loading = false;
        });
    }
}
