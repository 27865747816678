import { Component, OnDestroy, OnInit, ChangeDetectorRef, ViewChild, } from '@angular/core';

import { AuthenticationService } from '../../services/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { Driver } from 'app/models/driver.model';
import { FormMode } from 'app/common/enums';
import { DriverService } from 'app/services/driver/driver.service';
import { Router } from '@angular/router';

@Component({
  selector: 'fh-drivers-add',
  templateUrl: 'add.template.html'
})
export class DriversAddViewComponent {
  token: string;
  loading = false;
  driver: Driver = new Driver();

  formMode: FormMode = FormMode.add;
  error: any;
  permissions: {};
  success: { statusText: string; success: string; };

  constructor(private driverService: DriverService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private router: Router) {
    this.permissions = this.authenticationService.permissions;
    this.token = this.authenticationService.getAuthToken();
  }

  onInsert() {
    this.loading = true;

    this.driverService.saveDriver(this.driver).subscribe(result => {

      this.success = {
        statusText: 'Success',
        success: 'You will be redirected to the driver'
      };

      setTimeout(() => {
        this.router.navigate(['/DriverDetails/Index/' + result.id]);
      }, 2000);

      this.driverService.resetCache().subscribe(res => {
        console.log('drivers are cleared');
        console.log(res);
      });

      this.loading = false;
    }, error => {
      this.error = error
      this.loading = false;
    });
  }

  log(event) {
    console.log(event);
  }
}
