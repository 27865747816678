import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Subject } from 'rxjs/Subject';
import { DeviceService } from '../../services/device/device.service';
import { tileLayer, latLng, circle, polygon, Map, marker, icon, Marker } from 'leaflet';
import { ActivatedRoute, Router } from '@angular/router';
import { Device } from 'app/models/device.model';
import { trigger, style, animate, transition } from '@angular/animations';
import { getMapProviders, createMapOptions } from 'app/common/leafletGlobals';
import * as L from 'leaflet';

@Component({
    selector: 'fh-device-locations',
    templateUrl: 'deviceLocations.template.html',
    animations: [
        trigger(
            'enterAnimation', [
            transition(':enter', [
                // css styles at start of transition
                style({ opacity: 0 }),

                // animation and styles at end of transition
                animate('0.5s', style({ opacity: 1 }))
            ]),
        ]
        )
    ],
})
export class DeviceLocationsViewComponent implements OnInit, OnDestroy {

    map: any;
    marker: Marker;
    options;
    timeoutHandler;
    locations: any[];
    sub;
    device: Device;
    layersControl;

    // Pagination
    totalItems = 0;
    currentPage = 1;
    smallnumPages = 0;
    maps: { 'mapTiler': any; 'Google': any; 'Hybrid': any; };

    constructor(private http: HttpClient, private deviceService: DeviceService, private route: ActivatedRoute, private router: Router) {
        this.device = null;
        this.locations = [];

        this.initMap();
    }

    ngOnInit() {
        this.device = new Device;
        this.device.id = ''

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];
            this.deviceService.getDeviceById(id).subscribe(device => {
                this.device = device;

                if (this.device == null) {
                    this.router.navigate(['/Devices/Overview'])
                }
            });
        });

        // Fill initial
        this.generateRandomLocation(1);

        this.timeoutHandler = setInterval(() => {
            this.generateRandomLocation(1);
            this.totalItems = this.locations.length;
        }, 5000);
    }

    ngOnDestroy() {
        if (this.timeoutHandler) {
            console.log('Distroy timeouthandler');
            clearInterval(this.timeoutHandler);
        }
    }

    generateRandomLocation(value: number) {
        this.totalItems = value;

        function getRandomColor() {
            const colors = ['009900', 'CC0000', 'FF8000', '606060', 'FFF', 'FFF', 'FFF'];
            const randomVar = Math.floor(Math.random() * (colors.length));
            return '#' + colors[randomVar];
        }

        for (let i = 1; i <= value; i++) {
            const location = this.getRandomLocation(25.2048, 55.2708, 1000000);
            const bgColor = getRandomColor();
            const iconColor = (bgColor === '#FFF' ? '#514d6a' : '#FFF')
            this.locations.push({ iconBackGround: bgColor, iconColor: iconColor, date: new Date(), location: location });
            this.drawLocation(location);
        }
    }

    // Map

    // Leaflet
    initMap() {
        this.maps = getMapProviders(L);
          const initMaps = [];
        initMaps.push(Object.values(this.maps)[0]);
        this.options = createMapOptions(L, initMaps);
    }

    onMapReady(map: Map) {
        this.map = map;

        const overlayMaps = {};

        L.control.layers(this.maps, overlayMaps, { position: 'topleft' }).addTo(map);

        this.map.invalidateSize();
    }

    drawLocation(location) {
        setTimeout(() => {
            if (location) {

                if (this.marker) {
                    this.map.removeLayer(this.marker);
                }

                this.marker = marker([location.latitude, location.longitude], {
                    icon: icon({
                        iconSize: [25, 41],
                        iconAnchor: [13, 41],
                        iconUrl: 'assets/marker-icon.png',
                        shadowUrl: 'assets/marker-shadow.png'
                    })
                });

                this.map.addLayer(this.marker);
                this.map.flyTo([location.latitude, location.longitude], 6);
            }
        }, 100);
    }

    getRandomLocation(latitude, longitude, radiusInMeters) {

        const getRandomCoordinates = function (radius, uniform) {
            // Generate two random numbers
            let a = Math.random(),
                b = Math.random();

            // Flip for more uniformity.
            if (uniform) {
                if (b < a) {
                    const c = b;
                    b = a;
                    a = c;
                }
            }

            // It's all triangles.
            return [
                b * radius * Math.cos(2 * Math.PI * a / b),
                b * radius * Math.sin(2 * Math.PI * a / b)
            ];
        };

        const randomCoordinates = getRandomCoordinates(radiusInMeters, true);

        // Earths radius in meters via WGS 84 model.
        const earth = 6378137;

        // Offsets in meters.
        const northOffset = randomCoordinates[0],
            eastOffset = randomCoordinates[1];

        // Offset coordinates in radians.
        const offsetLatitude = northOffset / earth,
            offsetLongitude = eastOffset / (earth * Math.cos(Math.PI * (latitude / 180)));

        // Offset position in decimal degrees.
        return {
            latitude: latitude + (offsetLatitude * (180 / Math.PI)),
            longitude: longitude + (offsetLongitude * (180 / Math.PI))
        }
    }

    // Pagination

    setPage(pageNo: number): void {
        this.currentPage = pageNo;
    }

    pageChanged(event: any): void {
        console.log('Page changed to: ' + event.page);
        console.log('Number items per page: ' + event.itemsPerPage);
    }
}
