import { PermissionsBitmask, IssueTypeEnums } from 'app/common/enums';
import * as moment from 'moment-timezone';

/* tslint:disable */
export function arrayFromMask(nMask, permissions) {
    // nMask must be between -2147483648 and 2147483647
    if (nMask > 0x7fffffff || nMask < -0x80000000) {
        throw new TypeError('arrayFromMask - out of range');
    }

    permissions = Object.keys(PermissionsBitmask)
        .filter(k => typeof PermissionsBitmask[k] === 'number')
        .map(n => ({ name: n, value: PermissionsBitmask[n], isEnabled: false }))

    permissions.forEach(permission => {
        permission.isEnabled = ((nMask & permission.value) == permission.value);
    });

    return permissions;
}

export function fixDate(d: Date): Date {
    if (d == null){
        return null;
    }
    d.setHours(d.getHours() - d.getTimezoneOffset() / 60);
    return d;
  }

export function createMask(thePermissionArray) {

    let mask = 0;

    thePermissionArray.forEach(permission => {
        if (permission.isEnabled == true) {
            mask = mask | permission.value;
        }
    });

    return mask;
}
    /* tslint:enable */

export function formatViolationThreshold(translateService, issueType, threshold, isUpperThreshold = false) {
    let violationThreshold = threshold;

    if (threshold == null) {
        return threshold;
    }

    if (issueType === IssueTypeEnums.OutlierDistance ||
        issueType === IssueTypeEnums.TooMuchDistancePerMessage ||
        issueType === IssueTypeEnums.TooMuchDistance) {
        // format km
        violationThreshold = Math.round(threshold / 1000) + ' ' + translateService.instant('abbreviation.kiloMeters');
    } else if (
        issueType === IssueTypeEnums.HighDataConsumptionMinimumMotion ||
        issueType === IssueTypeEnums.TooFewLocations ||
        issueType === IssueTypeEnums.TooManyLocations ||
        issueType === IssueTypeEnums.FarTooManyMessages) {
        // format # messages
        violationThreshold = threshold + ' ' + translateService.instant('general.messages');
    } else if (
        issueType === IssueTypeEnums.BackupBatteryTooLow ||
        issueType === IssueTypeEnums.BatteryPowerTooLow) {
        // format volt
        violationThreshold = threshold + ' ' + translateService.instant('general.volt');
    } else if (
        issueType === IssueTypeEnums.LooseConnectionOrIgnitionWire ||
        issueType === IssueTypeEnums.TooManyPowerlossEvents) {
        // format # flips
        violationThreshold = threshold + ' ' + translateService.instant('general.flips');
    } else if (issueType === IssueTypeEnums.BadGpsReception && isUpperThreshold) {
        violationThreshold = threshold + ' ' + translateService.instant('chart.hdop');
    } else if (
        issueType === IssueTypeEnums.BadGpsReception ||
        issueType === IssueTypeEnums.GpsDrift ||
        issueType === IssueTypeEnums.MissingDriver ||
        issueType === IssueTypeEnums.DelayedMessages ) {
        // format % of messages
        violationThreshold = threshold + ' ' + translateService.instant('general.percentageOfMessages');
    }

    return violationThreshold;
}

export function humanizeTimespan(translateService, value) {
    let result = '';
    let originalValue = value;
    const hourDuration = (60 * 60);
    const minuteDuration = 60;
    let hourValue = 0;
    let minuteValue = 0;
    let secondValue = 0;

    while (originalValue >= hourDuration) {
        hourValue += 1;
        originalValue = originalValue - hourDuration;
    }
    while (originalValue >= minuteDuration) {
        minuteValue += 1;
        originalValue = originalValue - minuteDuration;
    }
    secondValue += Math.round(originalValue);

    if (hourValue > 0) {
        result += hourValue + translateService.instant('abbreviation.hours');
    }
    if (minuteValue > 0) {
        result += minuteValue + translateService.instant('abbreviation.minutes');
    }
    if (secondValue > 0) {
        result += secondValue + translateService.instant('abbreviation.seconds');
    }

    return result;
}

export function getUTCStartOfDayDateTimeFromTimezone(date: Date, timezone: string) {
    return moment.utc(moment(date).clone().tz(timezone).startOf('day').utc().format('YYYY-MM-DD HH:mm:SS')).toDate();
}
