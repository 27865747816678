<div class="modal">
    <div class="ibox float-e-margins" *ngIf="!sensorDevices || !sensorDevices.length">
        <div class="ibox-title">
            <h5>
                <i class="fas fa-sliders-h"></i> {{ 'chart.sensorDefaultName' | translate }}: {{ sensor.name }}{{ sensor.parameter ? ' (' + sensor.parameter + ')' : '' }}
            </h5>
            <i class="fas fa-fw fa-times pull-right" style="cursor: pointer;" (click)="close()"></i>
        </div>
        <div class="ibox-content slick" style="position: relative">
            <ngx-loading [show]="loading"></ngx-loading>
            <table style="width:100%; table-layout:fixed" class="table table-hover nowrap dtr-inline no-footer">
                <thead>
                    <tr>
                        <th>
                            <div style="cursor: pointer; width: fit-content">
                                {{ 'general.customer' | translate}}
                            </div>
                        </th>
                        <th>
                            <div style="cursor: pointer; width: fit-content">
                                {{ 'general.manufacturer' | translate}}
                            </div>
                        </th>
                        <th>
                            <div style="cursor: pointer; width: fit-content">
                                {{ 'general.devicetype' | translate}}
                            </div>
                        </th>
                        <th>
                            <div style="cursor: pointer; width: fit-content">
                                {{ 'general.count' | translate}}
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let sensorUser of sensorUsers | slice:((currentPage - 1) * 10): ((currentPage - 1) * 10) + 10">
                        <td>
                            {{ sensorUser.customerName }}
                        </td>
                        <td>
                            {{ sensorUser.manufacturer }}
                        </td>
                        <td>
                            {{ sensorUser.deviceType }}
                        </td>
                        <td (click)="getSensorDevices(sensorUser)" style="cursor: pointer;" class="secondary link_bolder">
                            {{ sensorUser.sensorCount }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="ibox-footer" *ngIf="sensorUsers && sensorUsers.length">

            <pagination [totalItems]="sensorUsers.length" [(ngModel)]="currentPage" (numPages)="smallnumPages = $event"
                [maxSize]="7" [boundaryLinks]="true"></pagination>

            <div style="clear:both"></div>
        </div>
    </div>

    <fh-sensor-devices [sensor]="sensor" [sensorDevices]="sensorDevices" *ngIf="sensorDevices && sensorDevices.length"></fh-sensor-devices>
</div>