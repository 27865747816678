import { Component, Input, OnInit, Output, EventEmitter, OnChanges, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormMode } from 'app/common/enums';
import { DeleteModalComponent } from './deleteModal.component';


@Component({
    selector: 'fh-save-footer',
    templateUrl: 'saveFooter.component.html',
})
export class SaveFooterComponent implements OnInit {
    @ViewChild('deleteModal') deleteModal: DeleteModalComponent;

    @Input() formMode: FormMode = FormMode.read;
    @Output() onSave = new EventEmitter();
    @Output() onInsert = new EventEmitter();
    @Output() onDelete = new EventEmitter();
    @Output() setFormMode = new EventEmitter();
    @Input() valid = false;
    @Input() allowDelete = false;
    @Input() showButtons = true;
    @Input() showButtonsNotification = ''

    constructor(private cd: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        console.log(this.valid);
    }

    insert() {
        this.onInsert.emit(true);
        this.setFormMode.emit(1);
    }

    save() {
        this.onSave.emit(true);
        this.setFormMode.emit(1);
    }

    sendFormMode(mode) {
        this.setFormMode.emit(mode);
    }

    delete() {
        this.onDelete.emit();
        this.deleteModal.hideModal();
    }

    showDelete() {
        this.deleteModal.showModal(null);
    }
}

