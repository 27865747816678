import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DeviceService } from '../../services/device/device.service';
import { TagObjectType } from 'app/models/tag.model';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { AssetTypeService } from 'app/services/asset/assetTypes.service';
import { FormMode } from 'app/common/enums';

declare var L;

@Component({
    selector: 'fh-fh-assettype-calibration',
    templateUrl: 'calibration.template.html'
})
export class AssetTypeCalibrationViewComponent implements OnInit {
    sub: any;
    assetType: any;
    device: any;
    tagType = TagObjectType.Asset.valueOf();

    loadingCalculation = false;
    loadingAssetType = false;
    error: any;

    options;
    marker;

    permissions: {};

    testValue = 0;
    loadingTest = false;
    errorTest;
    successTest;
    calibrationPoints = [];

    formMode = FormMode.read;
    success: { statusText: string; success: string; };

    constructor(private authentication: AuthenticationService, private assetTypeService: AssetTypeService, private deviceService: DeviceService, private route: ActivatedRoute) {
        this.calibrationPoints = [
            { rawValue: 0, actualValue: 0 },
            { rawValue: 1, actualValue: 2000 },
            { rawValue: 2, actualValue: 2500 },
            { rawValue: 3, actualValue: 3000 },
            { rawValue: 4, actualValue: 6000 },
            { rawValue: 5, actualValue: 20000 },
        ];
    }

    testCalibration() {
        this.loadingTest = true;
        // this.errorTest = 'error';
        setTimeout(result => {
            this.loadingTest = false;
            this.successTest = 'Calibration returns 0';
        }, 1000);
    }

    ngOnInit() {
        this.permissions = this.authentication.permissions;

        this.loadingCalculation = true;
        this.loadingAssetType = true;

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            const assetFromService = this.assetTypeService.getAssetTypeById(id).subscribe(asset => {
                this.assetType = asset;
                this.loadingCalculation = false;
                this.loadingAssetType = false;
            });
        });
    }

    getAssetTypeDetails() {
        const assetFromService = this.assetTypeService.getAssetTypeById(this.assetType.id).subscribe(asset => {
            this.assetType = asset;
            this.loadingCalculation = false;
            this.loadingAssetType = false;
        });
    }

    setFormMode(mode) {
        this.formMode = mode;

        if (this.formMode === FormMode.read) {
            this.loadingAssetType = true;

            this.getAssetTypeDetails();
        }
    }

    onSave() {
        this.loadingAssetType = true;

        console.log(this.assetType);

        this.assetTypeService.updateAssetType(this.assetType).subscribe(result => {
            this.loadingAssetType = false;

            this.success = {
                statusText: 'Success',
                success: 'Asset is successfull updated'
            };

            this.error = null;

            this.getAssetTypeDetails();

        }, error => {
            this.error = error
            this.loadingAssetType = false;
        });
    }

}
