import { Component, OnDestroy, OnInit, } from '@angular/core';
import { appDate, appVersion } from '../../app.version'
import { InfoService } from 'app/services/info/info.service';
import { environment } from 'app/../environments/environment';

@Component({
    selector: 'fh-about',
    templateUrl: 'about.component.html'
})
export class AboutViewComponent implements OnDestroy, OnInit {

    appDate: string;
    appVersion: string;
    applications = [];
    endPoint: string;
    database: string;

    constructor(private infoService: InfoService) {
        infoService.getInfo().subscribe(result => {
           this.applications = result;
        })

        this.endPoint = infoService.getEndPoint();
    }

    ngOnDestroy(): void {
    }

    ngOnInit(): void {

        this.appVersion = environment.version;
        this.appDate = appDate;
    }
}
