import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { throwError } from 'rxjs/internal/observable/throwError';
import * as moment from 'moment-timezone';
import 'rxjs/add/operator/map'

import { AuthenticationService } from '../authentication/authentication.service';
import { LoggingService } from '../logging/logging.service';
import { TranslatorService } from '../common/translator.service';
import { Job, JobHistoryLog } from 'app/models/jobs.model';

@Injectable()
export class JobsService {

    token: string;
    timestamp;
    jobUrl = '';
    jobHistoryUrl = '';


    constructor(private http: HttpClient, private translatorService: TranslatorService, private loggingService: LoggingService, private authenticationService: AuthenticationService) {
        this.jobUrl = authenticationService.getWebserviceURL('job');
        this.jobHistoryUrl = authenticationService.getWebserviceURL('jobHistoryLog');
        this.token = authenticationService.getAuthToken();
    }

    getActiveJobs(): Observable<Job[]> {
        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting customers from service');
        return this.http.get(this.jobUrl, { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getHistoryJobs(): Observable<JobHistoryLog[]> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting customers from service');
        return this.http.get(this.jobHistoryUrl, { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseHistoryResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }


    private handleError(error: Response) {
        return throwError(error);
    }

    parseResponse(json: any): Job[] {

        const jobs: Job[] = [];

        json.forEach(item => {

            const job = this.parseResponseData(item);
            jobs.push(job);
        });

        this.timestamp = new Date().getTime();
        return jobs;
    }


    parseHistoryResponse(json: any): JobHistoryLog[] {

        const jobs: JobHistoryLog[] = [];

        json.forEach(item => {

            const job = this.parseHistoryResponseData(item);
            jobs.push(job);
        });

        this.timestamp = new Date().getTime();
        return jobs;
    }

    parseResponseData(item) {
        const job = new Job();

        job.isRunning = item.isRunning;
        job.lastRuntime = item.lastRuntime;
        job.accountId = item.accountId;
        job.accountName = item.accountName;
        job.nextRun = item.nextRun;
        job.lastStatus = item.lastStatus;

        return job;
    }

    parseHistoryResponseData(item) {
        const job = new JobHistoryLog();

        job.accountId = item.accountId;
        job.accountName = item.accountName;
        job.endJob = item.endJob;
        job.totalDeviceCount = item.totalDeviceCount;
        job.devicesErrorCount = item.devicesErrorCount;
        job.devicesProcessedCount = item.devicesProcessedCount;
        job.devicesSkippedCount = item.devicesSkippedCount;
        job.fromDate = item.fromDate;
        job.issues = item.issues;
        job.jobId = item.jobId;
        job.messages = item.messages;
        job.startJob = item.startJob;
        job.status = item.status;
        job.toDate = item.toDate;
        job.duration = moment(item.endJob).diff(moment(item.startJob));

        return job;
    }
}
