import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { WizardComponent } from './wizard';

@Component({
    selector: 'fh-wizard-step',
    // tslint:disable-next-line:use-host-property-decorator
    host: {
        '[style.display]': 'isCurrent ? "flex" : "none"',
    },
    template: `
    <ng-content>
    </ng-content>
  `,
})
export class WizardStepComponent implements OnInit {
    private isCurrent;
    private step;

    @Input() title = '';
    @Input() icon = 'fa-memory';
    @Input() disabled = false;
    @Input() validate = false;

    @Output() open = new EventEmitter();

    constructor(private parent: WizardComponent) {
    }

    ngOnInit() {
        this.step = this.parent.addStep(this.title, this.icon, this.disabled, this.validate);
        this.isCurrent = this.step === this.parent.step;

        if (this.isCurrent) {
            this.open.emit();
        }

        this.parent.stepChange.subscribe(step => {
            this.isCurrent = this.step === step;
            if (this.isCurrent) {
                this.open.emit();
            }
        });
    }
}
