import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Notification } from '../models';

const TYPE_INFO = 'info';
const TYPE_ERROR = 'error';
const TYPE_WARNING = 'warning';

@Injectable()
export class NotificationsService {
    public notifications: Subject<Notification>;

    constructor() {
        this.notifications = new Subject();
    }

    private pushNotification(notification) {
        this.notifications.next(notification);
    }

    public addInfo(header: string, text: string) {
        const notification = new Notification();

        notification.header = header;
        notification.message = text;
        notification.type = TYPE_INFO;

        this.pushNotification(notification);
    }

    public addError(header: string, text: string) {
        const notification = new Notification();

        notification.header = header;
        notification.message = text;
        notification.type = TYPE_ERROR;

        this.pushNotification(notification);
    }

    public addWarning(header: string, text: string) {
        const notification = new Notification();

        notification.header = header;
        notification.message = text;
        notification.type = TYPE_WARNING;

        this.pushNotification(notification);
    }
}
