<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Devices/Overview">{{'general.devices' | translate }}</a>
            </li>
            <li class="active">
                <a href="/#/Devices/Overview">{{'menu.deviceoverview' | translate }}</a>
            </li>
        </ol>

        <fh-filter-toggler style="margin-left: auto;" [constructorName]="constructorName"
            (filterChanged)="showFilter = $event"></fh-filter-toggler>
    </div>
</div>

<div class="wrapper wrapper-content">

    <!--
        <div class="row">
            <div class="col-lg-12">
                <div class="alert alert-danger shadow">
                    <strong>Danger!</strong> Indicates a dangerous or potentially negative action.
                </div>
            </div>
        </div>
    -->
    <div class="row">
        <div class="col-lg-10">
            <!-- Custom filters -->
            <div class="ibox float-e-margins" [hidden]="!showFilter">
                <!--  [@slideInOut] -->
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-filter"></i> {{'general.filter' | translate }}</h5>
                </div>
                <div class="ibox-content" style="position: relative;">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <div id="dynamicFilters"></div>
                    <div style="clear:both"></div>
                    <div class="col-sm-6">
                        <div class="form-group input-group" style="margin-bottom: 5px;">
                            <span class="input-group-addon">
                                <i class="fa fa-fw fa-memory"></i>
                            </span>
                            <angular2-multiselect [data]="deviceTypesMultiselect"
                                [settings]="dropdownSettingsDeviceTypes" [(ngModel)]="selectedDeviceTypes"
                                (onGroupSelect)="fireFilter($event)" (onGroupDeSelect)="fireFilter($event)"
                                (onSelect)="fireFilter($event)" (onDeSelect)="fireFilter($event)"
                                (onSelectAll)="fireFilter($event)" (onDeSelectAll)="fireFilter($event)">
                            </angular2-multiselect>
                            <span class="input-group-addon" *ngIf="selectedDeviceTypes.length > 0"
                                (click)="selectedDeviceTypes = []; fireFilter(1);"> <i class="fas fa-fw fa-ban"></i>
                            </span>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group input-group" style="margin-bottom: 5px;">
                            <span class="input-group-addon">
                                <i class="fas fa-fw fa-user-tie"></i>
                            </span>
                            <angular2-multiselect [data]="customersMultiselect"
                                [settings]="dropdown_CustomerNameSettings" [(ngModel)]="selectedCustomers"
                                (onGroupSelect)="fireFilter($event)" (onGroupDeSelect)="fireFilter($event)"
                                (onSelect)="fireFilter($event)" (onDeSelect)="fireFilter($event)"
                                (onSelectAll)="fireFilter($event)" (onDeSelectAll)="fireFilter($event)">
                            </angular2-multiselect>
                            <span class="input-group-addon" *ngIf="selectedCustomers.length > 0"
                                (click)="selectedCustomers = []; fireFilter(1);"> <i class="fas fa-fw fa-ban"></i>
                            </span>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label style="padding-top: 8px;"
                            (click)="showOnlyFlagged =! showOnlyFlagged; fireFilter($event)">
                            <i style="font-size: 1.2em; color: #777;" class="far fa-fw"
                                [class.fa-check-square]="showOnlyFlagged" [class.fa-square]="!showOnlyFlagged"></i>
                            {{'device.showOnlyFlagged' | translate }}</label>
                    </div>
                    <div class="col-sm-6">
                        <label style="padding-top: 8px;" (click)="showOnlyActive =! showOnlyActive; fireFilter($event)">
                            <i style="font-size: 1.2em; color: #777;" class="far fa-fw"
                                [class.fa-check-square]="showOnlyActive" [class.fa-square]="!showOnlyActive"></i>
                            {{'device.showOnlyActive' | translate }}</label>
                    </div>
                    <div style="clear:both"></div>
                </div>
            </div>
            <!-- End default dynamic filters -->

            <!-- Create the grid -->
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-memory"></i> {{'general.devices' | translate }}</h5>
                    <iboxseach [searchTerm]="searchTerm" (seachChanged)="seachChanged($event)" [showColumns]="true"
                        (columnsChanged)="drawFilterRow()">
                    </iboxseach>
                </div>
                <div class="ibox-content slick" style="position: relative" *ngIf="languageLoaded">
                    <table id='dataTable' style="width:100%; min-height:50px;" fh-datatable [dtTrigger]="dtTrigger"
                        [dtInstance]="dtInstanceCallback" [dtOptions]="dtOptions"
                        class="table table-hover nowrap margin-bottom-0 dataTable dtr-inline">
                    </table>
                </div>
            </div>
            <!-- End create the grid -->
        </div> 

        <div class="col-lg-2">
            <fh-grid-presets *ngIf="enablePresets" [constructorName]="constructorName"
                (presetLoaded)="onPresetLoaded($event)"></fh-grid-presets>
        </div>
    </div>
</div>