import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DeviceSensor, SensorUser, SensorDevice } from 'app/models/sensor.model';
import { Router } from '@angular/router';

@Component({
  selector: 'fh-sensor-devices',
  templateUrl: 'sensorDevices.template.html',
  providers: []
})
export class SensorDevicesComponent implements OnChanges {
  @Input() sensor: DeviceSensor;
  @Input() sensorDevices: SensorDevice[];

  filteredSensors: DeviceSensor[] = [];
  loading: boolean;
  hideIrrelevant = true;
  // Pagination
  currentPage = 1;
  smallnumPages = 0;
  ascending = false;
  sortingFor = '';

  constructor(private router: Router) { }

  ngOnChanges(changes: SimpleChanges) {
    if (Object.keys(changes).indexOf('showSensorDevices') !== -1) {
      this.setPage(1);
      setTimeout(() => {
        $('.modal .ibox').css('min-height', $('.modal .ibox').height() + 14);
      }, 10);
    }
  }

  close(): void {
    this.sensorDevices.splice(0, this.sensorDevices.length);
  }

  goToDeviceDetails(deviceId: string): void {
    this.router.navigate(['/DeviceDetails/Index/' + deviceId]);
  }

  setPage(pageNo: number): void {
    this.currentPage = pageNo;
  }
}
