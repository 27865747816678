<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
        </ol>

        <fh-filter-toggler *ngIf="!permissions['IsAdministrator']" style="margin-left: auto;"
            [constructorName]="constructorName" (filterChanged)="showFilter = $event"></fh-filter-toggler>
    </div>
</div>

<div class="wrapper wrapper-content" *ngIf="!permissions['IsAdministrator'] && permissions['HasIssues']">
    <!-- Dashboard for normal users-->
    <div class="row">
        <div class="col-lg-12">
            <div class="alert shadow" *ngFor="let notification of notifications" [ngClass]="{
            'alert-success':  notification.type === 3,
            'alert-danger':  notification.type === 2, 
            'alert-warning': notification.type === 1, 
            'alert-info':    notification.type === 0}">
                <strong>{{'general.warning' | translate }}:</strong>&nbsp;{{notification.message}}
                <a class="secondary link_bolder" *ngIf="notification.data2 && notification.type === 2"
                    href="/#/PlatformDetails/Index/{{notification.data2}}">Click
                    here to update the token.</a>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="showFilter" [@slideInOut]>
        <div class="col-lg-12">
            <div class="ibox float-e-margins">

                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-filter"></i> {{'general.filter' | translate }}
                    </h5>
                </div>
                <div class="ibox-content" style="position: relative">
                    <div class="col-sm-6">
                        <div class="form-group input-group" style="margin-bottom: 5px;">
                            <span class="input-group-addon">
                                <i class="fa fa-fw fa-calendar"></i>
                            </span>
                            <input placeholder="Daterangepicker" style="width:100%" placeholder="Datepicker" type="text"
                                [bsConfig]="dpConfig" class="form-control" bsDaterangepicker [minDate]="minDate"
                                (onHidden)="dateChanged($event)" [(ngModel)]="daterangepickerModel">
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group input-group" style="margin-bottom: 5px;">
                            <span class="input-group-addon">
                                <i class="fas fa-fw fa-user-tie"></i>
                            </span>
                            <angular2-multiselect [data]="customers" [settings]="dropdown_CustomerNameSettings"
                                [(ngModel)]="selectedCustomers" (onGroupSelect)="fireFilter($event)"
                                (onGroupDeSelect)="fireFilter($event)" (onSelect)="fireFilter($event)"
                                (onDeSelect)="fireFilter($event)" (onSelectAll)="fireFilter($event)"
                                (onDeSelectAll)="fireFilter($event)">
                            </angular2-multiselect>
                            <span class="input-group-addon hand" *ngIf="selectedCustomers.length > 0"
                                (click)="clearSelectedCustomers()">
                                <i class="fas fa-fw fa-ban"></i>
                            </span>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="form-group input-group" style="margin-bottom: 5px;">
                            <span class="input-group-addon">
                                <i class="fas fa-fw fa-clipboard-list"></i>
                            </span>
                            <angular2-multiselect [data]="accountPlatforms"
                                [settings]="dropdown_AccountPlatformsSettings" [(ngModel)]="selectedAccountPlatforms"
                                (onSelect)="fireFilter($event)" (onGroupSelect)="fireFilter($event)"
                                (onGroupDeSelect)="fireFilter($event)" (onDeSelect)="fireFilter($event)"
                                (onSelectAll)="fireFilter($event)" (onDeSelectAll)="fireFilter($event)">
                            </angular2-multiselect>
                            <span class="input-group-addon hand" *ngIf="selectedAccountPlatforms.length > 0"
                                (click)="clearSelectedAccountPlatforms()">
                                <i class="fas fa-fw fa-ban"></i>
                            </span>
                            <span class="input-group-addon" (click)="fireFilter()">
                                <i class="fas fa-fw fa-search"></i>
                            </span>
                        </div>
                    </div>
                    <div style="clear:both"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-3 col-md-6">
            <div class="ibox float-e-margins hand" [routerLink]="['/Devices/Overview/']">
                <div class="ibox-title">
                    <span class="label pull-right"
                        title="{{'dashboard.lastweeksum' | translate }} {{ endPrevious | amDateFormat:'LL' }}: {{ deviceCountHistory }}"
                        [ngClass]="{'label-success': deviceCountHistoryPercentage > 0, 'label-danger': deviceCountHistoryPercentage < 0}">
                        {{ checkPositive(deviceCountHistoryPercentage) }}
                    </span>
                    <h5>
                        <i class="fas fa-fw fa-memory"></i> {{'dashboard.devicecount' | translate }}
                    </h5>
                </div>
                <div class="ibox-content" style="position: relative; min-height:72px">
                    <ngx-loading [show]="loadingDevices"></ngx-loading>
                    <h1 class="no-margins font-bold" title="{{ today | amDateFormat: 'LL' }}">{{
                        deviceCount?.toLocaleString()
                        }} / {{
                        allDeviceCount?.toLocaleString()
                        }}
                    </h1>
                    {{'dates.today' | translate }}
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-md-6">
            <div class="ibox float-e-margins hand" [routerLink]="['/DeviceTypes/Overview/']">
                <div class="ibox-title">
                    <span class="label pull-right"
                        title="{{'dashboard.lastweeksum' | translate }} {{ endPrevious | amDateFormat:'LL' }}: {{ deviceTypesCountHistory }}"
                        [ngClass]="{'label-success': deviceTypesCountHistoryPercentage > 0, 'label-danger': deviceTypesCountHistoryPercentage < 0}">
                        {{ checkPositive(deviceTypesCountHistoryPercentage) }}
                    </span>
                    <h5>
                        <i class="fas fa-fw fa-satellite-dish"></i> {{'general.devicetypes' | translate }}
                    </h5>
                </div>
                <div class="ibox-content" style="position: relative; min-height:72px">
                    <ngx-loading [show]="loadingIssues"></ngx-loading>
                    <h1 class="no-margins font-bold" title="{{ today | amDateFormat: 'LL' }} ">{{
                        deviceTypesCount?.toLocaleString()
                        }}
                    </h1>
                    {{'dates.today' | translate }}
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-md-6">
            <div class="ibox float-e-margins hand" [routerLink]="['/Devices/Issues/']">
                <div class="ibox-title">
                    <span class="label pull-right"
                        title="{{'dashboard.lastweeksum' | translate }} {{ endPrevious | amDateFormat:'LL' }}: {{ deviceWithIssuesHistory }}"
                        [ngClass]="{'label-success': deviceWithIssuesHistoryPercentage < 0, 'label-danger': deviceWithIssuesHistoryPercentage > 0}">
                        {{ checkPositive(deviceWithIssuesHistoryPercentage) }}
                    </span>
                    <h5>
                        <i class="fas fa-fw fa-stethoscope"></i> {{'dashboard.deviceWithIssues' | translate }}
                    </h5>
                </div>
                <div class="ibox-content" style="position: relative; min-height:72px">
                    <ngx-loading [show]="loadingIssues"></ngx-loading>
                    <h1 class="no-margins font-bold"
                        title="{{ start | amDateFormat: 'LL' }} - {{ today | amDateFormat: 'LL' }}">{{
                        deviceWithIssues }} <span *ngIf="deviceWithIssuesMax > 0"
                            style="font-size: 15px; color: red">({{
                            deviceWithIssuesMax }} {{'dashboard.critical' | translate }})</span></h1>
                    {{ dateDisplay }}
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-md-6">
            <div class="ibox float-e-margins hand" [routerLink]="['/Issues/Overview']"
                [queryParams]="{DateStart:start, DateEnd:today}">
                <div class="ibox-title">
                    <span class="label pull-right"
                        title="{{'dashboard.lastweeksum' | translate }} {{ endPrevious | amDateFormat:'LL' }}: {{ issueCountHistory }}"
                        [ngClass]="{'label-danger': issueCountHistoryPercentage > 0, 'label-success': issueCountHistoryPercentage < 0}">
                        {{ checkPositive(issueCountHistoryPercentage) }}
                    </span>
                    <h5>
                        <i class="fas fa-fw fa-bolt"></i> {{'dashboard.issuecount' | translate }}
                    </h5>
                </div>
                <div class="ibox-content" style="position: relative; min-height:72px">
                    <ngx-loading [show]="loadingIssues"></ngx-loading>
                    <h1 class="no-margins font-bold"
                        title="{{ start | amDateFormat: 'LL' }} - {{ today | amDateFormat: 'LL' }} ">{{
                        issueCount?.toLocaleString() }}</h1>
                    {{ dateDisplay }}
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-6">
            <div class="row7">
                <div class="col-lg-6 col-md-12">
                    <div class="ibox float-e-margins">
                        <div class="ibox-title">
                            <h5>
                                <i class="fas fa-fw fa-chart-bar"></i> {{'dashboard.healthy' | translate }}
                            </h5>
                            <span class="pull-right" style="padding-left:10px">
                                <i *ngIf="healthyShowPie" title="Show as bar chart"
                                    (click)="healthyShowPie = !healthyShowPie; reDrawHealthy()"
                                    class="fas fa-fw fa-chart-bar"></i>
                                <i *ngIf="!healthyShowPie" title="Show as pie chart"
                                    (click)="healthyShowPie = !healthyShowPie; reDrawHealthy()"
                                    class="fas fa-fw fa-chart-pie"></i>
                            </span>
                            <span class="pull-right">
                                {{ dateDisplay }}
                            </span>
                        </div>
                        <div class="ibox-content" style="position: relative; min-height:72px">
                            <ngx-loading [show]="loadingIssues"></ngx-loading>
                            <div style="height:194px" [chart]="chartHealthy">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="ibox float-e-margins">
                        <div class="ibox-title">
                            <h5>
                                <i class="fas fa-fw fa-chart-bar"></i> {{'dashboard.topissuetypes' | translate }}
                            </h5>
                            <span class="pull-right" style="padding-left:10px">
                                <i *ngIf="issueTypesShowPie" title="Show as bar chart"
                                    (click)="issueTypesShowPie = !issueTypesShowPie; reDrawIssueTypes()"
                                    class="fas fa-fw fa-chart-bar"></i>
                                <i *ngIf="!issueTypesShowPie" title="Show as pie chart"
                                    (click)="issueTypesShowPie = !issueTypesShowPie; reDrawIssueTypes()"
                                    class="fas fa-fw fa-chart-pie"></i>
                            </span>
                            <span class="pull-right">
                                {{ dateDisplay }}
                            </span>
                        </div>
                        <div class="ibox-content" style="position: relative; min-height:72px">
                            <ngx-loading [show]="loadingIssuesPerType"></ngx-loading>
                            <div style="height:194px" [chart]="chartPieIssueTypes">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-memory"></i> {{'dashboard.worstdevices' | translate }}
                    </h5>
                    <span class="pull-right"> {{ dateDisplay }}</span>
                </div>
                <div class="ibox-content slick" style="min-height: 300px;position: relative ">
                    <ngx-loading [show]="loadingIssues"></ngx-loading>
                    <table class="table table-hover nowrap dtr-inline no-footer" style="width:100%; margin-bottom:0px ">
                        <thead>
                            <tr>
                                <th style="width:100%">{{'general.device' | translate }}</th>
                                <th>{{'general.severity' | translate }}</th>
                                <th style="width:80px; text-align: right">{{'general.issues' | translate }}</th>
                                <th style="width:0px"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let worstDevice of worstDevices | orderBy : 'issueCount':true | slice:0:6; let i = index ">
                                <td style="width:100%">{{ worstDevice.name }}</td>
                                <td style="width:80px; text-align: right">{{ ('enums.severity.' +
                                    worstDevice.maxSeverity) | translate }}</td>
                                <td style="width:80px; text-align: right">{{ worstDevice.issueCount?.toLocaleString()
                                    }}</td>
                                <td style="width:0px">
                                    <a class='btn btn-primary btn-outline btn-grid'
                                        href='/#/DeviceDetails/Index/{{worstDevice.id}}'>
                                        <i class='fas fa-fw fa-info'></i>
                                        <span class="hidden-sm hidden-xs">{{'general.details' | translate }}</span>
                                    </a>
                                </td>
                            </tr>
                            <tr *ngIf="worstDevices.length === 0">
                                <td colspan="3">
                                    {{'general.nodatafound' | translate }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="ibox-content">
                    <a class="btn btn-primary btn-outline" style="width:100%" href="/#/Issues/Devices">
                        <i class="fas fa-fw fa-car-crash"></i> {{'dashboard.showalldeviceissues' | translate }}</a>
                </div>
            </div>

            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-car-crash"></i> {{'dashboard.issues' | translate }}
                    </h5>
                    <span class="pull-right"> {{ dateDisplay }}</span>
                </div>
                <div class="ibox-content slick" style="min-height:300px;position: relative ">
                    <ngx-loading [show]="loadingIssuesPerType"></ngx-loading>
                    <table class="table table-hover nowrap dtr-inline no-footer" style="width:100%; margin-bottom:0px ">
                        <thead>
                            <tr>
                                <th style="width:100%">{{'general.issue' | translate }}</th>
                                <th style="width:80px; text-align: right">{{'general.count' | translate }}</th>
                                <th style="width:0px"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let issueType of issueTypesResult | orderBy : 'issueCount':true | slice:0:6; let i = index ">
                                <td style="width:100%">{{ issueType[0] }}</td>
                                <td style="width:80px; text-align: right">{{ issueType[1].issueCount?.toLocaleString()
                                    }}</td>
                                <td style="width:0px">
                                    <a class='btn btn-primary btn-outline btn-grid'
                                        href='/#/Issues/Overview?issueType={{issueType[1].issueId}}'>
                                        <i class='fas fa-fw fa-info'></i>
                                        <span class="hidden-sm hidden-xs">{{'general.details' | translate }}</span>
                                    </a>
                                </td>
                            </tr>
                            <tr *ngIf="issueTypesResult.length === 0">
                                <td colspan="3">
                                    {{'general.nodatafound' | translate }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="ibox-content">
                    <a class="btn btn-primary btn-outline" style="width:100%" href="/#/Issues/Overview">
                        <i class="fas fa-fw fa-car-crash"></i> {{'dashboard.showallissues' | translate }}</a>
                </div>
            </div>
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-user-tie"></i> {{'general.customers' | translate }}
                    </h5>
                    <span class="pull-right"> {{ dateDisplay }}</span>
                </div>
                <div class="ibox-content slick" style="min-height:300px;position: relative ">
                    <ngx-loading [show]="loadingCustomers"></ngx-loading>
                    <table class="table table-hover nowrap dtr-inline no-footer" style="width:100%; margin-bottom:0px ">
                        <thead>
                            <tr>
                                <th style="width:100%">{{'general.customer' | translate }}</th>
                                <th style="width:80px; text-align: right">{{'general.issues' | translate }}</th>
                                <th style="width:0px"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let customer of customerResult | orderBy : 'issueCount':true | slice:0:6; let i = index ">
                                <td style="width:100%">{{ customer[0] }}</td>
                                <td style="width:80px; text-align: right">{{ customer[1].issueCount?.toLocaleString()
                                    }}</td>
                                <td style="width:0px">
                                    <a class='btn btn-primary btn-outline btn-grid'
                                        href='/#/CustomerDetails/Index/{{customer[1].customerId}}'>
                                        <i class='fas fa-fw fa-info'></i>
                                        <span class="hidden-sm hidden-xs">{{'general.details' | translate }}</span>
                                    </a>
                                </td>
                            </tr>
                            <tr *ngIf="issueTypesResult.length === 0">
                                <td colspan="3">
                                    {{'general.nodatafound' | translate }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-lg-6 ">

            <div style="position: relative">
                <ngx-loading [show]="loadingIssueCount"></ngx-loading>
                <tabset [justified]="true">
                    <tab>
                        <ng-template tabHeading>
                            <i class="fas fa-fw fa-chart-bar"></i> {{'dashboard.issuecount' | translate }}
                        </ng-template>
                        <div class="ibox-content" style="height:214px; border:0px" [chart]="chart4"></div>
                    </tab>
                    <tab>
                        <ng-template tabHeading>
                            <i class="fas fa-fw fa-chart-bar"></i> {{'general.messages' | translate }}
                        </ng-template>
                        <div class="ibox-content" style="height:214px; border:0px" [chart]="chart3"></div>
                    </tab>
                    <tab>
                        <ng-template tabHeading>
                            <i class="fas fa-fw fa-chart-bar"></i> {{'general.distance' | translate }}
                        </ng-template>
                        <div class="ibox-content" style="height:214px; border:0px" [chart]="chartDistance"></div>
                    </tab>
                </tabset>
            </div>

            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-chart-bar"></i> {{'dashboard.issuesperissuetype' | translate }}
                    </h5>
                    <span class="pull-right">
                        {{ dateDisplay }}
                    </span>
                </div>
                <div class="ibox-content slick" style="position: relative">
                    <ngx-loading [show]="loadingPerIssueChart"></ngx-loading>
                    <div style="height:360px" [chart]="chart1">
                    </div>
                </div>
            </div>

            <div class="ibox float-e-margins" *ngIf="permissions['HasDeviceTypes']">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-chart-bar"></i> {{'dashboard.issuesperdevicetype' | translate }}
                    </h5>
                    <span class="pull-right">
                        {{ dateDisplay }}
                    </span>
                </div>
                <div class="ibox-content slick" style="position: relative">
                    <ngx-loading [show]="loadingPerDeviceChart"></ngx-loading>
                    <div style="height:360px" [chart]="chart2">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="wrapper wrapper-content" *ngIf="permissions['IsAdministrator']">
    <!-- Dashboard for admins -->
    <div class="row">
        <div class="col-lg-3 col-md-6">
            <div class="ibox float-e-margins hand" [routerLink]="['/Accounts/Overview/']">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-database"></i> {{'general.accounts' | translate }}
                    </h5>
                </div>
                <div class="ibox-content" style="position: relative;">
                    <ngx-loading [show]="loadingDevices"></ngx-loading>
                    <h1 class="no-margins font-bold" title="{{ today | amDateFormat: 'll' }}">{{adminActiveAccounts}} /
                        {{adminAccountsWithoutIssues}}
                    </h1>
                </div>
            </div>
        </div>

        <div class="col-lg-3 col-md-6">
            <div class="ibox float-e-margins hand" [routerLink]="['/Users/Overview/']">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-users"></i> {{'general.users' | translate }}
                    </h5>
                </div>
                <div class="ibox-content" style="position: relative;">
                    <ngx-loading [show]="loadingDevices"></ngx-loading>
                    <h1 class="no-margins font-bold" title="{{ today | amDateFormat: 'll' }}">{{adminActiveUsers}}
                    </h1>
                </div>
            </div>
        </div>

        <div class="col-lg-3 col-md-6">
            <div class="ibox float-e-margins hand" [routerLink]="['/Jobs/Active/']">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-sync"></i> {{'menu.jobs' | translate }}
                    </h5>
                </div>
                <div class="ibox-content" style="position: relative;">
                    <ngx-loading [show]="loadingDevices"></ngx-loading>
                    <h1 class="no-margins font-bold" title="{{ today | amDateFormat: 'll' }}">{{adminActiveJobCount}} /
                        {{adminHistoryJobCount}}
                    </h1>
                </div>
            </div>
        </div>

        <div class="col-lg-3 col-md-6">
            <div class="ibox float-e-margins hand" [routerLink]="['/Users/Overview/']">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-car-crash"></i> {{'general.issues' | translate }}
                    </h5>
                </div>
                <div class="ibox-content" style="position: relative;">
                    <ngx-loading [show]="loadingDevices"></ngx-loading>
                    <h1 class="no-margins font-bold" title="{{ today | amDateFormat: 'll' }}">{{adminIssueCount}}
                    </h1>
                </div>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="!permissions['HasWasl']">
        <div class="col-lg-12">
            <div class="ibox float-e-margins hand">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-database"></i> {{'general.accounts' | translate }}
                    </h5>
                </div>
                <div class="ibox-content" style="position: relative;">
                    <ngx-loading [show]="loadingAccounts"></ngx-loading>
                    <div style="height:300px" [chart]="chartAccountUse"></div>
                </div>
            </div>

            <div class="ibox float-e-margins hand">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-database"></i> {{'general.accountsWithoutIssues' | translate }}
                    </h5>
                </div>
                <div class="ibox-content" style="position: relative;">
                    <ngx-loading [show]="loadingAccounts"></ngx-loading>
                    <div style="height:300px" [chart]="chartAccountWithoutIssues"></div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="wrapper wrapper-content" *ngIf="!permissions['IsAdministrator'] && permissions['HasWasl']">
    <!-- Dashboard for admins -->
    <div class="row">
        <div class="col-lg-4 col-md-6">
            <div class="ibox float-e-margins hand" [routerLink]="['/Devices/Overview/']">
                <div class="ibox-title">
                    <span class="label pull-right"
                        title="{{'dashboard.lastweeksum' | translate }} {{ endPrevious | amDateFormat:'LL' }}: {{ deviceCountHistory }}"
                        [ngClass]="{'label-success': deviceCountHistoryPercentage > 0, 'label-danger': deviceCountHistoryPercentage < 0}">
                        {{ checkPositive(deviceCountHistoryPercentage) }}
                    </span>
                    <h5>
                        <i class="fas fa-fw fa-memory"></i> {{'dashboard.devicecount' | translate }}
                    </h5>
                </div>
                <div class="ibox-content" style="position: relative; min-height:72px">
                    <ngx-loading [show]="loadingDevices"></ngx-loading>
                    <h1 class="no-margins font-bold" title="{{ today | amDateFormat: 'LL' }}">
                        {{ deviceCount?.toLocaleString() }} / {{ allDeviceCount?.toLocaleString() }}
                    </h1>
                </div>
            </div>
        </div>

        <div class="col-lg-4 col-md-6">
            <div class="ibox float-e-margins hand" [routerLink]="['/Assets/Overview/']">
                <div class="ibox-title">
                    <span class="label pull-right"
                        title="{{'dashboard.lastweeksum' | translate }} {{ endPrevious | amDateFormat:'LL' }}: {{ deviceCountHistory }}"
                        [ngClass]="{'label-success': deviceCountHistoryPercentage > 0, 'label-danger': deviceCountHistoryPercentage < 0}">
                        {{ checkPositive(deviceCountHistoryPercentage) }}
                    </span>
                    <h5>
                        <i class="fas fa-fw fa-car"></i> {{'dashboard.assetcount' | translate }}
                    </h5>
                </div>
                <div class="ibox-content" style="position: relative; min-height:72px">
                    <ngx-loading [show]="loadingDevices"></ngx-loading>
                    <h1 class="no-margins font-bold" title="{{ today | amDateFormat: 'LL' }}">
                        {{ assetCount?.toLocaleString() }}
                    </h1>
                </div>
            </div>
        </div>

        <div class="col-lg-4 col-md-6">
            <div class="ibox float-e-margins hand" [routerLink]="['/Trailers/Overview/']">
                <div class="ibox-title">
                    <span class="label pull-right"
                        title="{{'dashboard.lastweeksum' | translate }} {{ endPrevious | amDateFormat:'LL' }}: {{ deviceCountHistory }}"
                        [ngClass]="{'label-success': deviceCountHistoryPercentage > 0, 'label-danger': deviceCountHistoryPercentage < 0}">
                        {{ checkPositive(deviceCountHistoryPercentage) }}
                    </span>
                    <h5>
                        <i class="fas fa-fw fa-truck-loading"></i> {{'dashboard.trailercount' | translate }}
                    </h5>
                </div>
                <div class=" ibox-content" style="position: relative; min-height:72px">
                    <ngx-loading [show]="loadingDevices"></ngx-loading>
                    <h1 class="no-margins font-bold" title="{{ today | amDateFormat: 'LL' }}">
                        {{ trailerCount?.toLocaleString() }}
                    </h1>
                </div>
            </div>
        </div>

        <div class="col-lg-4 col-md-6">
            <div class="ibox float-e-margins hand">
                <div class="ibox-title" [routerLink]="['/Tags']">
                    <h5>
                        <i class="fas fa-fw fa-truck-loading"></i> {{'general.tags' | translate }}
                    </h5>
                </div>
                <div class=" ibox-content" style="position: relative; min-height:72px">
                    <ngx-loading [show]="loadingDevices"></ngx-loading>
                    <div style="height:194px" [chart]="chartTags">
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-4 col-md-6">
            <div class="ibox float-e-margins hand" [routerLink]="['/Drivers/Overview']">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-address-card"></i> {{'general.drivers' | translate }}
                    </h5>
                </div>
                <div class=" ibox-content" style="position: relative; min-height:72px">
                    <ngx-loading [show]="loadingDevices"></ngx-loading>
                    <h1 class="no-margins font-bold">
                        {{ driverCount?.toLocaleString() }}
                    </h1>
                </div>
            </div>
        </div>

        <div class="col-lg-4 col-md-6">
            <div class="ibox float-e-margins hand" [routerLink]="['/AssetTypes/Overview']">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-object-group"></i> {{'general.assetTypes' | translate }}
                    </h5>
                </div>
                <div class=" ibox-content" style="position: relative; min-height:72px">
                    <ngx-loading [show]="loadingDevices"></ngx-loading>
                    <h1 class="no-margins font-bold">
                        {{ assetTypesUsedCount?.toLocaleString() }} / {{ assetTypeCount?.toLocaleString() }}
                    </h1>
                </div>
            </div>
        </div>
    </div>
</div>