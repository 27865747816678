import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch';

import { AuthenticationService } from '../authentication/authentication.service';
import { LoggingService } from '../logging/logging.service';
import { throwError } from 'rxjs/internal/observable/throwError';
import { WaslAsset } from 'app/models/asset.model';
import { RegisterOperatingCompanyRequest } from 'app/models/wasl.model';
import { WaslDriver } from 'app/models/driver.model';

@Injectable()
export class WaslService {
  token: string;
  timestamp;
  url = '';

  constructor(private http: HttpClient, private loggingService: LoggingService, private authenticationService: AuthenticationService) {
    this.url = this.authenticationService.getInventoryURL('');
    this.token = this.authenticationService.getAuthToken();
  }

  inquireVehicle(assetId: string): Observable<any> {

    this.token = this.authenticationService.getAuthToken();
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

    console.log('Fetch driver by id ' + assetId);
    return this.http.get(this.url + 'Asset/' + assetId + '/InquireVehicle', { headers: headers })
      .map((data) => {
        const parsedResponse = (data);
        return parsedResponse;
      })
      .catch(this.handleError);
  }

  registerAsset(assetId: string, waslAsset: WaslAsset): Observable<any> {

    this.token = this.authenticationService.getAuthToken();
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

    console.log('register asset');

    return this.http.post(this.url + 'Asset/' + assetId + '/Vehicle_Register', waslAsset, { headers: headers })
      .catch(this.handleError);
  }

  unregisterAsset(assetId: string, waslAsset: WaslAsset): Observable<any> {

    this.token = this.authenticationService.getAuthToken();
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

    console.log('unregister asset');

    return this.http.post(this.url + 'Asset/' + assetId + '/Vehicle_Unregister', waslAsset, { headers: headers })
      .catch(this.handleError);
  }


  registerCompany(accountId: string, waslCompany: RegisterOperatingCompanyRequest): Observable<any> {

    this.token = this.authenticationService.getAuthToken();
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

    console.log('register asset');

    return this.http.post(this.url + 'Account/' + accountId + '/Company_Register', waslCompany, { headers: headers })
      .catch(this.handleError);
  }

  unregisterCompany(accountId: string): Observable<any> {

    this.token = this.authenticationService.getAuthToken();
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

    console.log('unregister asset');

    return this.http.post(this.url + 'Account/' + accountId + '/Company_Unregister', null, { headers: headers })
      .catch(this.handleError);
  }



  registerDriver(driverId: string, waslDriver: WaslDriver): Observable<any> {

    this.token = this.authenticationService.getAuthToken();
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

    console.log('register driver');

    return this.http.post(this.url + 'Driver/' + driverId + '/Driver_Register', waslDriver, { headers: headers })
      .catch(this.handleError);
  }

  unregisterDriver(driverId: string, waslDriver: WaslDriver): Observable<any> {

    this.token = this.authenticationService.getAuthToken();
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

    console.log('unregister driver');

    return this.http.post(this.url + 'Driver/' + driverId + '/Driver_Unregister', waslDriver, { headers: headers })
      .catch(this.handleError);
  }

  inquireCompany(accountId: string): Observable<any> {

    this.token = this.authenticationService.getAuthToken();
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

    console.log('Inquire account by id ' + accountId);
    return this.http.get(this.url + 'Account/' + accountId + '/Inquire', { headers: headers })
      .map((data) => {
        const parsedResponse = (data);
        return parsedResponse;
      })
      .catch(this.handleError);
  }

  private handleError(error: Response) {
    return throwError(error);
  }
}
