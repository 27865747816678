<div *ngIf="isModalShown" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal"
    class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left"><i class="fas fa-sliders-h"></i> {{'general.trailers' | translate }}
                </h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
                    <span aria-hidden="true">&times;</span>
                </button>

                <div class="padding:10px; pull-right">
                    <input type="text" class="form-control inline-search" style="margin-top: -8px; margin-right: 10px; margin-bottom: -10px;
                    margin-right: 10px;" placeholder="{{'general.search' | translate }}" [(ngModel)]="searchVar"
                        (ngModelChange)="search$.next($event)">
                </div>
            </div>
            <div class="modal-body">
                <table style="width:100%; min-height:50px;" datatable [dtOptions]="dtOptions"
                    class="table table-hover nowrap margin-bottom-0 dataTable dtr-inline">
                </table>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary" [disabled]="!selected" (click)="select()"><i
                        class="fas fa-fw fa-check"></i>
                    {{'general.select' | translate }}</button>

                <button class="btn btn-danger" (click)="close()"><i class="fas fa-fw fa-times"></i>
                    {{'general.cancel' | translate }}</button>
            </div>
        </div>
    </div>
</div>