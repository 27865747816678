import { TranslatorService } from 'app/services/common/translator.service';
import { Severity, SettingsLevel } from './../../common/enums';
import { IssueTypeSettings } from './../../models/calculationsettings.model';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { CalculationSettingsService } from './../../services/calculationsettings/calculationsettings.service';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { PlatformService } from '../../services/platform/platform.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IssueTypeService } from '../../services/issuetypes/issuetypes.service';
import { IssueType } from '../../models/issuetype.model';

@Component({
  selector: 'fh-calculation-settings-overview',
  templateUrl: 'calculationSettingsOverview.template.html'
})
export class CalculationSettingsOverViewComponent implements OnInit {
  dtOptions = {};
  token;
  settings: any[] = [];
  accountSettings: any[] = [];
  generalSettings: any[] = [];
  platformSettings: any[] = [];
  deviceTypeSettings: any[] = [];
  customerSettings: any[] = [];
  deviceSettings: any[] = [];
  issueTypes: IssueType[];
  loading: boolean;

  constructor(private calculationSettingsService: CalculationSettingsService, private translateService: TranslateService,
    private translatorService: TranslatorService,
    private authenticationService: AuthenticationService, private translaterService: TranslatorService,
    private issueTypeService: IssueTypeService) {
    this.settings = [];
    this.accountSettings = [];
    this.generalSettings = [];
    this.platformSettings = [];
    this.deviceTypeSettings = [];
    this.deviceSettings = [];
  }

  ngOnInit() {
    this.loading = true;

    this.token = this.authenticationService.getAuthToken();

    this.issueTypeService.getIssueTypes().subscribe(issueTypes => {
      this.issueTypes = issueTypes;
    });
    this.calculationSettingsService.getAllCalculationSettings().subscribe(result => {
      if (result != null) {
        this.settings = result;
      }

      this.settings.forEach(setting => {

        setting.name = this.translatorService.translate(setting.issuetypeName, 'enums.issuetypes');

        setting.duration = Math.round((setting.duration / 60) * 100) / 100;

        const settingIssueType = this.issueTypes.find(issueType => issueType.id === setting.issueType);
        if (settingIssueType) {
          setting.description = settingIssueType.uomDescription;
          setting.uom = settingIssueType.uom;
        }
        switch (SettingsLevel[setting.source]) {
          case 'General':
            this.generalSettings.push(setting);
            break;
          case 'Account':
            this.accountSettings.push(setting);
            break;
          case 'Platform':
            this.platformSettings.push(setting);
            break;
          case 'DeviceType':
            this.deviceTypeSettings.push(setting);
            break;
          case 'Customer':
            this.customerSettings.push(setting);
            break;
          case 'Device':
            this.deviceSettings.push(setting);
            break;
        }
      });

      this.loading = false;
    });
  }
}
