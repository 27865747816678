import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/catch';

import { AuthenticationService } from '../authentication/authentication.service';
import { Issue, IssueJson } from '../../models/issue.model';
import { Location } from '../../models/location.model';
import { LoggingService } from '../logging/logging.service';
import { Device } from '../../models/device.model';
import { DeviceType } from '../../models/devicetype.model';
import { Manufacturer } from '../../models/manufacturer.model';
import { TranslatorService } from '../common/translator.service';
import * as moment from 'moment-timezone';
import { throwError } from 'rxjs/internal/observable/throwError';

@Injectable()
export class IssueService {
  token: string;
  timestamp;
  url = '';

  constructor(private translatorService: TranslatorService, private http: HttpClient, private loggingService: LoggingService, private authenticationService: AuthenticationService) {
    this.url = authenticationService.getWebserviceURL('issue');
    this.token = authenticationService.getAuthToken();
  }

  getPagingUrl() {
    return this.url + 'PagedIssues';
  }

  getIssueById(id: string): Observable<Issue> {

    this.token = this.authenticationService.getAuthToken();
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

    console.log('Fetch issue by id ' + id);
    return this.http.get(this.url + id, { headers: headers })
      .map((data) => {
        const parsedResponse = this.parseReponseDetails(data);
        return parsedResponse;
      })
      .catch(this.handleError);
  }

  getIssueJsonById(id: string): Observable<IssueJson> {

    this.token = this.authenticationService.getAuthToken();
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

    console.log('Fetch issue geojson by id ' + id);
    return this.http.get(this.url + 'GeoJson/' + id, { headers: headers })
      .map((data: any) => {
        const issue = new IssueJson();

        issue.id = data.id;
        issue.geoJson = data.json;
        return issue;
      })
      .catch(this.handleError);
  }

  resetIssueCache(): Observable<boolean> {

    this.token = this.authenticationService.getAuthToken();
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

    return this.http.get(this.url + 'ResetCache', { headers: headers })
      .map((data: any) => {
        return data;
      })
      .catch(this.handleError);
  }


  getIssues(): Observable<Issue[]> {

    this.token = this.authenticationService.getAuthToken();
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

    console.log('getting issues from service');
    return this.http.get(this.url, { headers: headers })
      .map((data) => {
        const parsedResponse = this.parseResponse(data);
        return parsedResponse;
      })
      .catch(this.handleError);
  }


  getIssuesWithLocation(): Observable<Issue[]> {

    this.token = this.authenticationService.getAuthToken();
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

    console.log('getting issues from service');
    return this.http.get(this.url + 'locations', { headers: headers })
      .map((data) => {
        const parsedResponse = this.parseResponse(data);
        return parsedResponse;
      })
      .catch(this.handleError);
  }
  getIssuesByDevice(id: string, start, end): Observable<Issue[]> {

    this.token = this.authenticationService.getAuthToken();
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

    console.log('getting issues from service');
    return this.http.get(this.url + 'device/' + id + '/?start=' + Math.round(start / 1000) + '&end=' + Math.round(end / 1000), { headers: headers })
      .map((data) => {
        const parsedResponse = this.parseResponse(data);
        return parsedResponse;
      })
      .catch(this.handleError);
  }

  getIssuesByDeviceType(id: string, start, end): Observable<Issue[]> {

    this.token = this.authenticationService.getAuthToken();
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

    console.log('getting issues from service');
    return this.http.get(this.url + 'devicetype/' + id + '/?start=' + Math.round(start / 1000) + '&end=' + Math.round(end / 1000), { headers: headers })
      .map((data) => {
        const parsedResponse = this.parseResponse(data);
        return parsedResponse;
      })
      .catch(this.handleError);
  }

  private handleError(error: Response) {
    return throwError(error);
  }


  parseResponse(json: any): Issue[] {

    this.loggingService.log(this.constructor.name, 'Retrieved ' + json.length + ' issues.');

    const ident = 1;
    const issues: Issue[] = [];

    // this.loggingService.log(json);
    json.forEach(item => {
      const issue = this.parseReponseDetails(item);
      issues.push(issue);
    });

    this.timestamp = new Date().getTime();
    return issues;
  }

  parseReponseDetails(item) {
    const issue = new Issue();

    issue.id = item.id;

    issue.date = moment.utc(item.issueDate).toDate();
    issue.issueDate = item.issueDate ? moment.utc(item.issueDate).tz(this.authenticationService.getTimeZoneIana()) : null;
    issue.issueDateFormatted = item.issueDate ? moment.utc(item.issueDate).tz(this.authenticationService.getTimeZoneIana()).format('LLLL Z') : '';
    issue.issueType = item.issueType;
    issue.issueTypeId = item.issueTypeId;
    issue.deviceTypeName = item.deviceTypeName;
    issue.issueTypeDescription = this.translatorService.translate(item.issueTypeDescription, 'enums.issuetypes');
    issue.issueTypeUom = item.issueTypeUom ? item.issueTypeUom : 0;
    issue.issueTypeUomDescription = this.translatorService.translate(issue.issueTypeUom.toString(), 'enums.issuetypesuom');
    issue.issueTypeUomDisplay = item.issueTypeUomDisplay ? item.issueTypeUomDisplay : 0;
    issue.issueTypeUomDisplayDescription = this.translatorService.translate(issue.issueTypeUomDisplay.toString(), 'enums.issuetypesuom');
    issue.issueTypeEditableThresholds = item.issueTypeEditableThresholds;
    issue.closedDate = item.closedDate ? moment.utc(item.closedDate.replace(' ', '')).tz(this.authenticationService.getTimeZoneIana()).toDate() : null;
    issue.status = item.status ? item.status : 0;
    issue.statusDescription = this.translatorService.translate(issue.status.toString(), 'enums.issuestatus');
    issue.resolutionDescription = this.translatorService.translate(item.issueTypeDescription, 'enums.issuetypes.resolution');
    issue.resolutionType = item.resolutionType;
    issue.deviceName = item.deviceName;
    issue.deviceId = item.deviceId;
    issue.hasTelemetrySummary = item.hasTelemetrySummary;
    issue.hasDurationThreshold = item.hasDurationThreshold;

    issue.violationDuration = item.violationDuration;
    issue.violationLowerThreshold = item.violationLowerThreshold;
    issue.violationUpperThreshold = item.violationUpperThreshold;

    issue.noGpsFixCount = item.noGpsFixCount

    issue.sensorName = item.sensorName;
    issue.deviceSensorTypeId = item.deviceSensorTypeId;

    if (item.position && item.position.indexOf(',') > -1 && item.position.indexOf('geometry') === -1) {
      const latlngStr = item.position.split(',', 2);
      const lat = parseFloat(latlngStr[0]);
      const lng = parseFloat(latlngStr[1]);

      issue.position = item.position ? new Location(latlngStr[1], latlngStr[0]) : null // new Location({ });
    }

    issue.severity = item.severity;
    issue.hasMotion = item.hasMotion;
    issue.ignitionCount = item.ignitionCount;
    issue.messageCount = item.messageCount
    issue.distanceInMeters = item.distanceInMeters;
    issue.distanceInKm = item.distanceInMeters > 0 ? (item.distanceInMeters / 1000).toFixed(1) : '0';

    if (item.device) {
      const device = new Device();
      device.id = item.device.id;
      device.createdDate = moment(item.device.createdDate.replace(' ', '')).toDate();
      device.name = item.device.name;
      issue.deviceName = item.device.name;
      issue.lastPosition = item.device.lastPosition;
      issue.device = device;
    }

    if (item.model) {
      const deviceType = new DeviceType();
      deviceType.id = item.model.id;
      deviceType.name = item.model.name;
      deviceType.code = item.model.code;
      deviceType.createdDate = moment(item.model.createdDate.replace(' ', '')).toDate();
      deviceType.modifiedDate = moment(item.model.modifiedDate.replace(' ', '')).toDate();

      if (item.model.manufacturer) {
        const manufacturer = new Manufacturer();
        manufacturer.id = item.model.manufacturer.id
        manufacturer.name = item.model.manufacturer.name
        manufacturer.createdDate = moment(item.model.manufacturer.createdDate.replace(' ', '')).toDate();
        manufacturer.modifiedDate = moment(item.model.manufacturer.modifiedDate.replace(' ', '')).toDate();
        deviceType.manufacturer = manufacturer;
      }
      issue.deviceType = deviceType;
    }

    issue.duration = item.duration;
    issue.lowerThreshold = item.lowerThreshold;
    issue.upperThreshold = item.upperThreshold;

    return issue;
  }
}
