import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Platform, AccountPlatform } from '../../models/platform.model';
import { AccountPlatformService } from '../../services/platform/accountPlatform.service';
import { ActivatedRoute } from '@angular/router';
import { IssueTypeService } from '../../services/issuetypes/issuetypes.service';
import { IssueType } from '../../models/issuetype.model';
import { TranslateService } from '@ngx-translate/core';
import { FormMode } from 'app/common/enums';


@Component({
    selector: 'fh-fh-platform-details',
    templateUrl: 'platformDetails.template.html'
})
export class PlatformDetailsViewComponent {

    issueTypes: IssueType[];
    sub: any;
    accountPlatform: AccountPlatform;

    token_repeat = '';
    token = '';
    isMessage: boolean;
    messageStatus = 0;
    updateMessage: any;

    loadingCurrentToken = false;
    tokenIsFalse: boolean;
    newTokenTested: boolean;
    loadingNewToken: boolean;

    error: any;
    success: any;

    formMode = FormMode.read;

    constructor(private translateService: TranslateService, private issueTypeService: IssueTypeService, private accountPlatformService: AccountPlatformService, private route: ActivatedRoute) {
        this.accountPlatform = new AccountPlatform;
        this.loadingCurrentToken = false;

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];
            this.accountPlatformService.getAccountPlatformById(id).subscribe(platform => {
                this.accountPlatform = platform;
            });
        });

        // fetch issuetypes
        this.issueTypeService.getIssueTypes().subscribe(issueTypes => {
            this.issueTypes = issueTypes;
        });
    }

    saveToken() {
        this.accountPlatformService.updateToken(this.accountPlatform.id, this.token).subscribe(result => {
            if (result === true) {
                this.isMessage = true;
                this.messageStatus = 3;
                this.updateMessage = this.translateService.instant('general.updateUser');
            } else {
                this.isMessage = true;
                this.messageStatus = 1;
                this.updateMessage = this.translateService.instant('general.errorUpdate');
            }
        });
    }

    testCurrentToken() {
        this.loadingCurrentToken = true;

        this.accountPlatformService.checkTokenForAccountPlatform(this.accountPlatform.id).subscribe(result => {

            this.loadingCurrentToken = false;

            if (result === true) {
                this.tokenIsFalse = false;
                this.success = this.translateService.instant('general.tokenWorks');
            } else {
                this.tokenIsFalse = true;
                this.error = this.translateService.instant('general.tokenFails');
            }
        })

    }

    testNewToken() {
        console.log('Testing token', this.accountPlatform);
        this.loadingNewToken = true;

        this.accountPlatformService.checkToken(this.accountPlatform.platformType, this.token, this.accountPlatform.url).subscribe(result => {

            this.loadingNewToken = false;

            if (result === true) {
                this.newTokenTested = true;
                this.success = this.translateService.instant('general.tokenWorks');
            } else {
                this.newTokenTested = false;
                this.error = this.translateService.instant('general.tokenFails');
            }
        }, error => {
            this.loadingNewToken = false;
            this.newTokenTested = false;
            this.error = this.translateService.instant('general.tokenFails');
        });

    }

    saveNewToken() {
        this.loadingNewToken = true;

        this.accountPlatformService.saveNewToken(this.accountPlatform.id, this.token, this.accountPlatform.url, this.accountPlatform.name).subscribe(result => {

            this.loadingNewToken = false;

            this.tokenIsFalse = false;
            this.success = this.translateService.instant('general.tokenSaved');

        }, error => {
            this.loadingNewToken = false;
            this.tokenIsFalse = true;

            this.error = error;
            this.error.statusText = this.translateService.instant('general.errorSavingToken');
        });

    }
}
