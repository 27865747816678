<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Platforms/Overview">{{'general.platforms' | translate }}</a>
            </li>
            <li>
                <a href="/#/PlatformDetails/Index/{{platform?.id}}">{{platform?.name}}</a>
            </li>
            <li class="active">
                <strong>{{'menu.issueoverview' | translate }}</strong>
            </li>
        </ol>
    </div>
</div>

<div class="wrapper wrapper-content">
    <div class="row">
        <div class="col-lg-12">

            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-filter"></i> {{'general.filter' | translate }}</h5>
                </div>
                <div class="ibox-content" style="position: relative">
                    <div class="col-sm-6">
                        <div class="form-group input-group" style="margin-bottom: 5px;">
                            <span class="input-group-addon">
                                <i class="fa fa-fw fa-calendar"></i>
                            </span>
                            <input placeholder="Daterangepicker" style="width:100%" placeholder="Datepicker" type="text"
                                [bsConfig]="dpConfig" class="form-control" bsDaterangepicker [minDate]="minDate"
                                [(ngModel)]="daterangepickerModel" (onHidden)="dateChanged($event)">
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group input-group" style="margin-bottom: 5px;">
                            <span class="input-group-addon">
                                <i class="fa fa-fw fa-car-crash"></i>
                            </span>
                            <angular2-multiselect [data]="issueTypesMultiselect" [(ngModel)]="selectedIssueTypes"
                                [settings]="dropdownIssueTypesSettings" (onSelect)="fireFilter($event)"
                                (onDeSelect)="fireFilter($event)" (onSelectAll)="fireFilter($event)"
                                (onGroupSelect)="fireFilter($event)" (onGroupDeSelect)="fireFilter($event)"
                                (onDeSelectAll)="fireFilter($event)">
                                <c-item>
                                    <ng-template let-item="item">
                                        <label style="color: #333;min-width: 150px;">{{item.itemName}}</label>
                                    </ng-template>
                                </c-item>
                            </angular2-multiselect>
                            <span class="input-group-addon hand" *ngIf="selectedIssueTypes.length > 0"
                                (click)="clearSelectedIssueTypes()">
                                <i class="fas fa-fw fa-ban"></i>
                            </span>
                        </div>
                    </div>
                    <div id="export-assets"></div>
                    <div style="clear:both"></div>
                </div>
            </div>

            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-car-crash"></i> {{'menu.issueoverview' | translate }}</h5>
                  <iboxseach [searchTerm]="searchTerm" (seachChanged)="seachChanged($event)"></iboxseach>
                </div>
                <div class="ibox-content slick" *ngIf="languageLoaded">
                    <table style="width:100%; min-height:50px;" datatable [dtOptions]="dtOptions" class="table table-hover nowrap margin-bottom-0 dataTable dtr-inline">
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>