import { Component, OnDestroy, OnInit, ChangeDetectorRef, ViewChild, NgZone, } from '@angular/core';
import { DeviceService } from '../../services/device/device.service';
import { IssueService } from '../../services/issues/issues.service';
import { Issue } from 'app/models/issue.model';
import * as moment from 'moment';
import { Subject } from 'rxjs/Subject';

import { Chart } from 'angular-highcharts';

import { DeviceTypeService } from 'app/services/devicetypes/devicetypes.service';
import { IssueTypeService } from 'app/services/issuetypes/issuetypes.service';
import { DeviceType } from 'app/models/devicetype.model';
import { IssueType } from 'app/models/issuetype.model';
import { DataTableDirective } from 'angular-datatables';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { HttpHeaders } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { TranslatorService } from '../../services/common/translator.service';
import { ActivatedRoute } from '@angular/router';

import { BsDatepickerConfig, BsLocaleService, BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import { Severity } from '../../common/enums';
import { UserService } from '../../services/users/user.service';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
import { UserRoleService } from 'app/services/users/userRole.service';
window['moment'] = Moment;
mTZ()

@Component({
  selector: 'fh-user-roles',
  templateUrl: 'userRoles.template.html',
  styleUrls: ['userRoles.template.css']
})
export class UserRolesViewComponent implements OnInit, OnDestroy {
  token: string;
  countrySelect = false;

  dtOptions = {};
  loading = false;

  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  dropdown_IssueType = '';
  dropdown_DeviceType = '';
  dropdown_Severity = '';
  timezoneIana: string;
  permissions: {};
  languageLoaded: boolean;
  searchTerm: any;

  constructor(private translateService: TranslateService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private userRoleService: UserRoleService) {
    const that = this;
    this.permissions = this.authenticationService.permissions;
    this.timezoneIana = authenticationService.getTimeZoneIana();
    this.token = this.authenticationService.getAuthToken();
  }



  seachChanged(value) {
    console.log('Search changed');
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.search(value).draw();
    });
  }


  ngOnDestroy(): void {
    // We remove the last function in the global ext search array so we do not add the fn each time the component is drawn
    // /!\ This is not the ideal solution as other components may add other search function in this array, so be careful when
    // handling this global variable
    $.fn['dataTable'].ext.search.pop();

    // Had to reset the array...
    $.fn['dataTable'].ext.search = [];
  }



  ngOnInit(): void {
    this.translateService.get('general.date').subscribe(value => {
      this.languageLoaded = true; this.initGrid();
    });
  }

  initGrid(): void {

    const that = this;

    $.fn['dataTable'].ext.search.pop();

    this.loading = true;

    this.dtOptions = {
      pagingType: 'simple_numbers',
      serverSide: false,
      processing: false,
      scrollX: true,
      colReorder: {fixedColumnsLeft: 1},
      order: [[1, 'asc']],
      stateSave: true,
      stateSaveCallback: function (settings, data) {
        localStorage.setItem('Columns_' + that.constructor.name, JSON.stringify(data));
      },
      stateLoadCallback: function () {
        try {
          const columnSettings = JSON.parse(localStorage.getItem('Columns_' + that.constructor.name));
          that.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
          return columnSettings;
        } catch (e) {
          console.log(e);
        }
      },
      ajax: (dataTablesParameters: any, callback) => {
        $('.dataTables_info').html(this.translateService.instant('grid.loadingData'));

        this.userRoleService.getUserRoles().subscribe(resp => {
          callback({
            recordsTotal: resp.length,
            recordsFiltered: resp.length,
            data: resp
          });
        });
      },
      initComplete: function (settings, json) {
        that.loading = false;
      },
      colVis: {
        restore: this.translateService.instant('general.restore'),
        showAll: this.translateService.instant('general.showAll'),
        showNone: this.translateService.instant('general.hideAll'),
        exclude: ['id']
      },
      columns: [{
        data: 'id',
        orderable: false,
        title: '',
        width: '20',
        render: function (data, type, row) {
          return '<a class=\'btn btn-primary btn-outline btn-grid\' href=\'/#/UserRoleDetails/Index/' + data + '\'><i class=\'fas fa-fw fa-info\'></i> <span class="hidden-sm hidden-xs">Details</span></a>';
        }
      },
      {
        data: 'name',
        title: this.translateService.instant('general.userName')
      },
      {
        data: 'createdDate',
        title: this.translateService.instant('general.created'),
        render: function (data, type, row) {
          const date = Moment.utc(data)['tz'](that.timezoneIana);
          return date.format('ll')
        }
      }],
      pageLength: 10,
      language: {
        'lengthMenu': this.translateService.instant('grid.lengthmenu'),
        'zeroRecords': this.translateService.instant('grid.empty'),
        'info': this.translateService.instant('grid.info'),
        'infoEmpty': this.translateService.instant('grid.infoempty'),
        'infoFiltered': this.translateService.instant('grid.infofiltered'),
        'search': this.translateService.instant('grid.search'),
        'infoThousands': '.',
        'loadingRecords': this.translateService.instant('grid.loadingrecords'),
        'paginate': {
          'first': this.translateService.instant('grid.first'),
          'last': this.translateService.instant('grid.last'),
          'next': this.translateService.instant('grid.next'),
          'previous': this.translateService.instant('grid.previous'),
        },
        'aria': {
          'sortAscending': this.translateService.instant('grid.sortasc'),
          'sortDescending': this.translateService.instant('grid.sortdesc'),
        }
      },
    };
  }
}
