export class Customer {

    public id: string;
    public createdDate: Date;
    public modifiedDate: Date;
    public name: string;
    public referenceId: string;
    public count: any;
    public platformName: any;
    public platformId: any;
    public accountPlatformId: any;
    public accountPlatformName: any;
}
