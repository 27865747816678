import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '../authentication/authentication.service';
import { Config } from '../../models/config.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import * as moment from 'moment';
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch';
import { ErrorLog } from '../../models/errorlog.model';
import { throwError } from 'rxjs/internal/observable/throwError';

@Injectable()
export class ErrorLogService {

    url: string;
    token: string;
    public config: Config;

    // I initialize the service.
    constructor(private http: HttpClient) {
        this.http = http;
    }

    // I log the given error to various aggregation and tracking services.
    public logError(error: any): void {
        // Internal tracking.
        this.sendToConsole(error);
        this.sendToServer(error);
    }

    public logErrorString(caller: any, error: any): void {
        this.logError(new CustomError(caller + ' , ' + error));
    }

    // I send the error the browser console (safely, if it exists).
    private sendToConsole(error: any): void {
        if (console && console.group && console.error) {
            console.group('Error Log Service');
            console.error(error);
            console.error(error.message);
            console.error(error.stack);
            console.groupEnd();
        }
    }

    getErrorLogs(): Observable<ErrorLog[]> {

        this.token = AuthenticationService.getStaticToken();
        this.url = AuthenticationService.getStaticClusterUrl();

        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting auditlog from service');
        return this.http.get(this.url + 'errorlog', { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    parseResponse(json: any): ErrorLog[] {
        const ident = 1;
        const auditlogs: ErrorLog[] = [];

        json.forEach(item => {

            const auditlog = new ErrorLog();
            auditlog.id = item.id;
            auditlog.description = item.description;
            auditlog.stack = item.stack;
            auditlog.location = item.location;
            auditlog.username = item.userName;
            auditlog.message = item.message;
            auditlog.createddate = item.createdDate;

            auditlogs.push(auditlog);
        });

        return auditlogs;
    }

    private handleError(error: Response) {
        console.log(error);
        return throwError(error);
    }

    // I send the error to the server-side error tracking end-point.
    private sendToServer(error: any): void {

        console.log('Error send to server');


        this.token = AuthenticationService.getStaticToken();
        this.url = AuthenticationService.getStaticClusterUrl();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });


        this.http.post(this.url + 'ErrorLog',
            {
                Location: window.location.href,
                Name: error.name,
                Message: error.message,
                Stack: error.stack
            }, { headers: headers }
        ).subscribe(
            (httpResponse: Response): void => { },
            (httpError: any): void => { });

    }
}

export function CustomError(message) {
    this.name = 'Custom Error';
    this.message = message || 'Default Message';
    this.stack = (new Error()).stack;
}
