<div class="col-lg-12">
    <div class="ibox float-e-margins">
        <div class="ibox-title">
            <h5>
                <i class="fa fa-fw fa-file-alt"></i> {{'general.log' | translate }}
            </h5>
            <div class="pull-right">
                {{'general.page' | translate }}: {{currentPage}} / {{numPages}}
            </div>
        </div>
        <div class="ibox-content" style="position: relative">
            <ngx-loading [show]="loading"></ngx-loading>
            <div class="activity-stream">
                <div class="activity-stream"
                    *ngFor="let log of logItems | orderBy : 'timestamp':true | slice:((currentPage - 1) * itemsPerPage): ((currentPage - 1) * itemsPerPage) + itemsPerPage">
                    <div class="stream">
                        <div class="stream-badge">
                            <i class="fa fa-location-arrow logtype" [ngClass]="'logtype_' + log.logtype"></i>
                            <!-- [style.color]="item.iconColor" [style.background]="item.iconBackGround" -->
                        </div>
                        <div class="stream-panel">
                            <div class="stream-info">
                                <span *ngIf="log.user">{{ log.user }}</span>
                                <span class="date" *ngIf="log.timestamp">&nbsp;{{ 'general.on' | translate }}
                                    {{ log.timestamp | amDateFormat: 'lll' }}</span>
                                <span class="date" *ngIf="log.createddate">&nbsp;{{ 'general.on' | translate }}
                                    {{ log.createddate | amDateFormat: 'lll' }}</span>
                            </div>
                            <div class="stream-info" *ngIf="log.message !== undefined">
                                {{ 'general.message' | translate }} : {{ log.message }}
                            </div>
                            <div class="stream-info" *ngIf="log.location !== undefined">
                                {{ 'general.location' | translate }} : {{ log.location }}
                            </div>
                            <div class="stream-info" *ngIf="log.issue !== undefined">
                                <span>
                                    <a href="/#/IssueDetails/Index/{{ log.issue ? log.issue.id : '' }}">{{ 'general.issue'
                                        | translate }} {{ log.issue ? log.issue.issueType : ''}}</a>
                                </span>
                            </div>
                            <div class="stream-info" *ngIf="log.description !== undefined">
                                <span class="info">{{ log.description }}</span>
                            </div>
                            <!-- <div class="stream-info" *ngIf="log.delta !== undefined">
                                <span class="info">
                                    <prettyjson [obj]="log.delta"></prettyjson>
                                </span>
                            </div> -->
                            <div class="stream-info" *ngIf="log.deltaItems != null && log.deltaItems.length > 0">
                                <span class="info slick">
                                    <table style="width:100%" class="table table-hover nowrap dtr-inline no-footer">
                                        <thead>
                                            <tr>
                                                <th style="width:33%">
                                                    {{ 'general.item' | translate}}
                                                </th>
                                                <th style="width:33%">
                                                    {{ 'general.from' | translate}}
                                                </th>
                                                <th style="width:33%">
                                                    {{ 'general.to' | translate}}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of log.deltaItems; let i = index">
                                                <td style="width:33%">
                                                    {{ item.Item}}
                                                </td>
                                                <td style="width:33%">
                                                    {{ item.From}}
                                                </td>
                                                <td style="width:33%">
                                                    {{ item.To}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </span>
                            </div>
                            <div class="stream-info" *ngIf="log.stack !== undefined">
                                <span class="info">{{ log.stack }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="logItems.length === 0">
                {{'general.nodatafound' | translate }}
            </div>
        </div>
        <div class="ibox-footer">
            <pagination [totalItems]="totalItems" [(ngModel)]="currentPage" (pageChanged)="pageChanged($event)"
                (numPages)="updatePageNumber($event)" [boundaryLinks]="true" [rotate]="false" [maxSize]="maxSize"
                [itemsPerPage]="itemsPerPage" [firstText]="'grid.first' | translate"
                [previousText]="'grid.previous' | translate" [nextText]="'grid.next' | translate"
                [lastText]="'grid.last' | translate"></pagination>
        </div>
    </div>
</div>