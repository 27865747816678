import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { DeviceSensorsService } from 'app/services/sensors/deviceSensors.service';
import { DeviceSensor, DeviceSensorType, SensorUser } from 'app/models/sensor.model';
import { TextFilterPipe } from 'app/common/textFilter.pipe';

@Component({
  selector: 'fh-sensor-settings',
  templateUrl: 'sensorSettings.template.html',
  providers: [TextFilterPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SensorSettingsComponent implements OnInit {
  sensors: DeviceSensor[] = [];
  filteredSensors: DeviceSensor[] = [];
  loading: boolean;
  sensorTypes: DeviceSensorType[] = [];
  hideIrrelevant = true;
  sensorUsers: SensorUser[] = [];
  activeSensor: DeviceSensor;
  showSensorUsers: any = { show: false };

  // Pagination
  totalItems = 0;
  currentPage = 1;
  smallnumPages = 0;
  sensorsChanged: DeviceSensor[] = [];
  searchTerm = '';
  ascending = false;
  sortingFor = '';
  error: any;
  success: string;

  constructor(private cd: ChangeDetectorRef, private sensorService: DeviceSensorsService, private textFilterPipe: TextFilterPipe) {
    this.loading = true;

    sensorService.getDeviceSensorTypes().subscribe(result => {
      this.sensorTypes = result;
    });

    sensorService.getSensors().subscribe(result => {
      this.sensors = result;
      this.filteredSensors = textFilterPipe.transform(this.sensors, this.searchTerm);
      this.totalItems = this.filteredSensors.length;
      this.loading = false;
      this.cd.markForCheck();
    });

    this.cd.markForCheck();
  }

  ngOnInit() {
  }

  changeSensorsChanged(sensor) {
    sensor.isChanged = true;
    sensor.isCustomDeviceSensorTypeId = true;

    this.sensorsChanged = this.sensors.filter(x => x.isChanged === true);
  }

  save() {
    this.loading = true;
    this.sensorService.saveSensorSettings(this.sensorsChanged).subscribe(result => {
      this.loading = false;
      this.success = 'Sensors saved successfully';
      if (result === true) {
        this.sensorsChanged = [];
      }
      this.cd.markForCheck();
    },
      err => {
        this.loading = false;
        this.error = err;
        this.cd.markForCheck();
        console.error(err)
      }
    );
  }

  newSearchTerm() {
    this.filteredSensors = this.textFilterPipe.transform(this.sensors, this.searchTerm);
    this.totalItems = this.filteredSensors.length;
  }

  // Pagination
  setPage(pageNo: number): void {
    this.currentPage = pageNo;
  }

  updatePageNumber(value): void {
    this.smallnumPages = value;
    this.cd.detectChanges();
  }

  pageChanged(event: any): void {
    console.log('Page changed to: ' + event.page);
    console.log('Number items per page: ' + event.itemsPerPage);
  }

  showSensor(sensor: DeviceSensor) {
    this.activeSensor = sensor;
    this.loading = true;
    this.sensorService.getSensorUsers(sensor.name, sensor.parameter, sensor.deviceSensorTypeId, sensor.isCustomDeviceSensorTypeId).subscribe(result => {
      this.sensorUsers = result;
      this.loading = false;
      this.showSensorUsers.show = true;
      this.cd.detectChanges();
    });
  }

  sortBy(settingProperty: string) {
    if (!(this.sortingFor === settingProperty)) {
      this.ascending = false;
    }
    this.sortingFor = settingProperty
    const sortA = this.ascending ? -1 : 1;
    const sortB = this.ascending ? 1 : -1;

    switch (settingProperty) {
      case 'name':
        this.filteredSensors.sort((a, b) => { return (a.name > b.name ? sortA : sortB) });
        break;
      case 'metrics':
        this.filteredSensors.sort((a, b) => { return (a.metrics > b.metrics ? sortA : sortB) });
        break;
      case 'parameter':
        this.filteredSensors.sort((a, b) => { return (a.parameter > b.parameter ? sortA : sortB) });
        break;
      case 'sensorCount':
        this.filteredSensors.sort((a, b) => { return (a.sensorCount > b.sensorCount ? sortA : sortB) });
        break;
      case 'sensorType':
        this.filteredSensors.sort((a, b) => { return (this.sensorTypes.find(x => x.id === a.deviceSensorTypeId).name > this.sensorTypes.find(x => x.id === b.deviceSensorTypeId).name ? sortA : sortB) });
        break;
      case 'save':
        this.filteredSensors.sort((a, b) => { return (a.isChanged === b.isChanged ? 0 : this.ascending ? sortA : sortB) });
        break;
    }

    this.ascending = !this.ascending;
    this.filteredSensors = Object.assign([], this.filteredSensors);
  }
}
