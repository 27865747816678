import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { Router } from '@angular/router';
import { appVersion } from 'app/app.version';
import { environment } from 'environments/environment';

@Component({
  selector: 'fh-app-endsession-callback',
  templateUrl: './sso-endsession-callback.component.html',
  styleUrls: ['../appviews/login.template.css']
})
export class EndsessionCallbackComponent implements OnInit {

  appVersion: string;
  errorMessage: string;
  error = false;
  loading = false;
  ssoEnabled = false;

  constructor(private authService: AuthenticationService, private router: Router ) {
    this.appVersion = appVersion;
    this.ssoEnabled = environment.SSOEnabled;
  }

  ngOnInit() {
    this.loading = true;
    this.authService.completeSignout().then(response => {
      this.router.navigate(['/Login']);
      this.loading = false;
    });
  }

  onMouseMove(event) {

    const e = event;
    // Mouse Move 3d Effect

    const perX = (e.clientX / $(window).width()) - 0.5;
    const perY = (e.clientY / $(window).height()) - 0.5;
    // TweenMax.to('.effect-3d-element', 0.4, { rotationY: 5 * perX, rotationX: 5 * perY, transformPerspective: 1000, transformOrigin: 'center' })
  }

}
