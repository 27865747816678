import { CalculationSettingsOverViewComponent } from './views/settings/calculationSettingsOverview.component';
import { Routes } from '@angular/router';

import { DevicesViewComponent } from './views/devices/devices.component';
import { DevicesMapViewComponent } from './views/devices/devicesMap.component';
import { DevicesMap2ViewComponent } from 'app/views/devices/devicesMap2.component';

import { DeviceDetailsViewComponent } from './views/devices/deviceDetails.component';
import { DeviceAssetViewComponent } from 'app/views/devices/assetDetails.component';
import { DeviceFuelViewComponent } from './views/devices/deviceFuel.component';
import { DeviceSettingsViewComponent } from 'app/views/devices/deviceSettings.component';
import { DeviceLocationsViewComponent } from 'app/views/devices/deviceLocations.component';
import { DeviceTripsViewComponent } from './views/devices/trips.component';
import { DeviceIssuesViewComponent } from './views/devices/deviceIssues.component';
import { DeviceLogViewComponent } from './views/devices/deviceLog.component';

import { IssuesViewComponent } from './views/issues/issues.component';
import { IssuesMapViewComponent } from './views/issues/issuesMap.component';

import { ReportingViewComponent } from './views/reporting/reporting.component';

import { Error404Component } from './views/appviews/error404.component';

import { DashboardComponent } from './views/appviews/dashboard.component';
import { SearchViewComponent } from './views/appviews/search.component';
import { ScheduleViewComponent } from './views/schedule/schedule.component';
import { CalendarViewComponent } from './views/schedule/calendar.component';

// Settings
import { SettingsComponent } from './views/settings/settings.component';
import { ThemeSettingsComponent } from './views/settings/theme.component';
import { LanguageSettingsComponent } from './views/settings/language.component';
import { WorkflowSettingsComponent } from './views/settings/workflow.component';
import { ErrorLogComponent } from './views/settings/errorlog.component';
import { CalculationSettingsViewComponent } from './views/settings/calculationSettings.component';

// Components
import { LoginComponent } from './views/appviews/login.component';
import { RegisterComponent } from './views/appviews/register.component';

import { BlankLayoutComponent } from './views/shared/layouts/blankLayout.component';
import { BasicLayoutComponent } from './views/shared/layouts/basicLayout.component';
import { AuthGuard } from './auth-guard';

import { UnsubscribeComponent } from './views/appviews/unsubscribe.component';

// Issues
import { GroupedIssuesViewComponent } from './views/issues/groupedIssues.component';
import { IssueDetailsViewComponent } from './views/issues/issueDetails.component';
import { IssueLogViewComponent } from './views/issues/issueLog.component';
import { DevicesIssuesViewComponent } from './views/issues/deviceIssues.component';

// Device types
import { DevicesTypeViewComponent } from './views/devicetypes/deviceTypes.component';
import { DeviceTypeDetailsViewComponent } from './views/devicetypes/deviceTypeDetails.component';
import { DeviceTypeIssuesViewComponent } from './views/devicetypes/deviceTypeIssues.component';
import { DeviceTypeDevicesViewComponent } from './views/devicetypes/deviceTypeDevices.component';
import { IssuesChartViewComponent } from './views/issues/charts.component';
import { DeviceTypeCalculationSettingsViewComponent } from './views/devicetypes/deviceTypeCalculationSettings.component';

import { AboutViewComponent } from './views/appviews/about.component';
import { HelpViewComponent } from './views/appviews/help.component';

// Drivers
import { PlatformDetailsViewComponent } from './views/platforms/platformDetails.component';
import { PlatformViewComponent } from './views/platforms/platforms.component';
import { PlatformDevicesViewComponent } from './views/platforms/platformDevices.component';
import { DeviceCalculationSettingsViewComponent } from './views/devices/deviceCalculationSettings.component';
import { PlatformCalculationSettingsViewComponent } from './views/platforms/platformCalculationSettings.component';

// Customers
import { CustomersViewComponent } from './views/customers/customers.component';
import { CustomerDetailsViewComponent } from './views/customers/customerDetails.component';
import { CustomerDevicesViewComponent } from './views/customers/customerDevices.component';
import { CustomerCalculationSettingsViewComponent } from './views/customers/customerCalculationSettings.component';
import { CustomerIssuesViewComponent } from './views/customers/customerIssues.component';

// Users
import { UsersViewComponent } from './views/users/users.component';
import { UserDetailsViewComponent } from './views/users/userDetails.component';
import { UserAddViewComponent } from './views/users/userAdd.component';
import { AccountDetailsViewComponent } from './views/accounts/accountDetails.component';
import { AccountViewComponent } from './views/accounts/accounts.component';
import { AccountCalculationSettingsViewComponent } from './views/accounts/accountCalculationSettings.component';
import { UserRoleDetailsViewComponent } from './views/users/userRoleDetails.component';
import { ProfileComponent } from './views/settings/profile.component';
import { ForgotComponent } from './views/appviews/forgotPassword.component';
import { AccountAddViewComponent } from './views/accounts/accountAdd.component';
import { SensorSettingsComponent } from './views/settings/sensorSettings.component';
import { SettingsGeneralComponent } from './views/settings/general.component';

// Authentication (SSO)
import { AuthCallbackComponent } from './views/appviews/sso-auth-callback.component';
import { EndsessionCallbackComponent } from './views/appviews/sso-endsession-callback.component';
import { SsoLoginComponent } from './views/appviews/sso-login.component';
import { JobsHistoryViewComponent } from './views/jobs/history.component';
import { JobsActiveViewComponent } from './views/jobs/active.component';
import { AccountWithUseViewComponent } from './views/accounts/accountsWithUse.component';
import { UserRolesViewComponent } from './views/users/userRoles.component';
import { UserLogViewComponent } from './views/users/userLog.component';
import { PlatformIssuesViewComponent } from './views/platforms/platformIssues.component';
import { TagsViewComponent } from './views/appviews/tags.component';
import { TagListViewComponent } from './views/appviews/taglist.component';
import { WaslViewComponent } from './views/wasl/wasl.component';
import { DeviceSensorsViewComponent } from './views/devices/sensorDetails.component';
import { AssetViewComponent } from './views/assets/assets.component';
import { AssetDetailsViewComponent } from './views/assets/assetDetails.component';
import { AssetLogViewComponent } from './views/assets/assetLog.component';
import { AssetDeviceDetailsViewComponent } from './views/assets/assetDeviceDetails.component';
import { AssetTrailersViewComponent } from './views/assets/trailers.component';
import { AssetAddViewComponent } from './views/assets/add.component';
import { DriversAddViewComponent } from './views/drivers/add.component';
import { DriversViewComponent } from './views/drivers/drivers.component';
import { AssetDriversViewComponent } from './views/assets/assetDrivers.component';
import { DriverDetailsViewComponent } from './views/drivers/driverDetails.component';
import { DriverAssetsViewComponent } from './views/drivers/driverAssets.component';
import { DriverDevicesViewComponent } from './views/drivers/driverDevices.component';
import { TrailerDetailsViewComponent } from './views/trailers/trailerDetails.component';
import { TrailerViewComponent } from './views/trailers/trailers.component';
import { TrailerAddViewComponent } from './views/trailers/add.component';
import { AssetTypeViewComponent } from './views/assettypes/assetTypes.component';
import { AssetTypeAddViewComponent } from './views/assettypes/add.component';
import { AssetTypeDetailsViewComponent } from './views/assettypes/assetTypeDetails.component';
import { AssetTypeCalibrationViewComponent } from './views/assettypes/calibration.component';
import { TrailerAssetsViewComponent } from './views/trailers/assets.component';
import { DeviceAddViewComponent } from './views/devices/add.component';
import { AssetTypeAssetsViewComponent } from './views/assettypes/assets.component';
import { DriverLogViewComponent } from './views/drivers/driverLog.component';
import { AssetMessageViewComponent } from './views/assets/messages.component';
import { AssetTripsViewComponent } from './views/assets/trips.component';
import { AccountLogViewComponent } from './views/accounts/accountLog.component';
import { AssetTypeLogViewComponent } from './views/assettypes/assetTypeLog.component';
import { AssetsMapViewComponent } from './views/assets/map.component';
import { SensorTypeViewComponent } from './views/sensortypes/sensorTypes.component';
import { SensorTypeDetailsViewComponent } from './views/sensortypes/sensorTypesDetails.component';
import { SensorTypeAddViewComponent } from './views/sensortypes/add.component';
import { AssetCalibrationViewComponent } from './views/assets/sensors.component';
import { WaslRegistrationStatusViewComponent } from './views/wasl/status.component';

export const ROUTES: Routes = [
  // Main redirect
  { path: '', redirectTo: 'Dashboard/Day', pathMatch: 'full' },

  // App views
  {
    path: '', component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: 'Search', component: SearchViewComponent },
      { path: 'Tags', component: TagsViewComponent },
      { path: 'Tags/:id', component: TagListViewComponent },
      { path: 'Schedule', component: ScheduleViewComponent },
      { path: 'Schedule/Overview', component: ScheduleViewComponent },
      { path: 'Schedule/Calendar', component: CalendarViewComponent },
      { path: 'Issues', component: IssuesViewComponent },
      { path: 'Issues/Overview', component: IssuesViewComponent },
      { path: 'Issues/Grouped', component: GroupedIssuesViewComponent },
      { path: 'Issues/Charts', component: IssuesChartViewComponent },
      { path: 'Platforms', component: PlatformViewComponent },
      { path: 'Platforms/Overview', component: PlatformViewComponent },
      { path: 'Customers', component: CustomersViewComponent },
      { path: 'Customers/Overview', component: CustomersViewComponent },
      { path: 'Assets', component: AssetViewComponent },
      { path: 'Assets/Add', component: AssetAddViewComponent },
      { path: 'Assets/Map', component: AssetsMapViewComponent },
      { path: 'Assets/Overview', component: AssetViewComponent },
      { path: 'Drivers', component: DriversViewComponent },
      { path: 'Drivers/Add', component: DriversAddViewComponent },
      { path: 'Drivers/Overview', component: DriversViewComponent },
      { path: 'Trailers', component: TrailerViewComponent },
      { path: 'Trailers/Overview', component: TrailerViewComponent },
      { path: 'Trailers/Add', component: TrailerAddViewComponent },
      { path: 'AssetTypes', component: AssetTypeViewComponent },
      { path: 'AssetTypes/Overview', component: AssetTypeViewComponent },
      { path: 'AssetTypes/Add', component: AssetTypeAddViewComponent },
      { path: 'Devices', component: DevicesViewComponent },
      { path: 'Devices/Add', component: DeviceAddViewComponent },
      { path: 'Devices/Issues', component: DevicesIssuesViewComponent },
      { path: 'Devices/Overview', component: DevicesViewComponent },
      { path: 'Devices/Map', component: DevicesMapViewComponent },
      { path: 'DeviceTypes', component: DevicesTypeViewComponent },
      { path: 'DeviceTypes/Overview', component: DevicesTypeViewComponent },
      { path: 'Dashboard', component: DashboardComponent },
      { path: 'Dashboard/Default', component: DashboardComponent },
      { path: 'Dashboard/Today', component: DashboardComponent },
      { path: 'Dashboard/Yesterday', component: DashboardComponent },
      { path: 'Dashboard/Week', component: DashboardComponent },
      { path: 'Dashboard/Day', component: DashboardComponent },
      { path: 'Dashboard/Month', component: DashboardComponent },
      { path: 'Reporting', component: ReportingViewComponent },
      { path: 'Users', component: UsersViewComponent },
      { path: 'Users/Overview', component: UsersViewComponent },
      { path: 'UserRoles', component: UserRolesViewComponent },
      { path: 'UserRoles/Overview', component: UserRolesViewComponent },
      { path: 'Accounts', component: AccountViewComponent },
      { path: 'Accounts/Overview', component: AccountWithUseViewComponent },
      { path: 'Jobs/Active', component: JobsActiveViewComponent },
      { path: 'Jobs/History', component: JobsHistoryViewComponent },
      { path: 'DeviceTypes', component: DevicesTypeViewComponent },
      { path: 'SensorTypes', component: SensorTypeViewComponent },
      { path: 'SensorTypes/Overview', component: SensorTypeViewComponent },
      { path: 'SensorTypes/Add', component: SensorTypeAddViewComponent },
      { path: 'Settings', component: SettingsComponent },
      { path: 'Settings/Profile', component: ProfileComponent },
      { path: 'Settings/System', component: SettingsComponent },
      { path: 'Settings/Language', component: LanguageSettingsComponent },
      { path: 'Settings/Theme', component: ThemeSettingsComponent },
      { path: 'Settings/Errorlog', component: ErrorLogComponent },
      { path: 'Settings/Workflow', component: WorkflowSettingsComponent },
      { path: 'Settings/CalculationSettings', component: CalculationSettingsViewComponent },
      { path: 'Settings/OverviewCalculationSettings', component: CalculationSettingsOverViewComponent },
      { path: 'Settings/SensorSettings', component: SensorSettingsComponent },
      { path: 'Settings/General', component: SettingsGeneralComponent },
    ]
  },
  {
    path: '', component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: 'Devices/Map2', component: DevicesMap2ViewComponent },
      { path: 'Issues/Map', component: IssuesMapViewComponent },
    ]
  },
  {
    path: '', component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: 'DeviceDetails/Index/:id', component: DeviceDetailsViewComponent },
      { path: 'DeviceDetails/Asset/:id', component: DeviceAssetViewComponent },
      { path: 'DeviceDetails/Sensors/:id', component: DeviceSensorsViewComponent },
      { path: 'DeviceDetails/Settings/:id', component: DeviceSettingsViewComponent },
      { path: 'DeviceDetails/Locations/:id', component: DeviceLocationsViewComponent },
      { path: 'DeviceDetails/CalculationSettings/:id', component: DeviceCalculationSettingsViewComponent },
      { path: 'DeviceDetails/Fuel/:id', component: DeviceFuelViewComponent },
      { path: 'DeviceDetails/Trips/:id', component: DeviceTripsViewComponent },
      { path: 'DeviceDetails/Issues/:id', component: DeviceIssuesViewComponent },
      { path: 'DeviceDetails/Log/:id', component: DeviceLogViewComponent }]
  },
  {
    path: '', component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: 'AssetDetails/Index/:id', component: AssetDetailsViewComponent },
      { path: 'AssetDetails/Device/:id', component: AssetDeviceDetailsViewComponent },
      { path: 'AssetDetails/Sensors/:id', component: AssetCalibrationViewComponent },
      { path: 'AssetDetails/Drivers/:id', component: AssetDriversViewComponent },
      { path: 'AssetDetails/Trailers/:id', component: AssetTrailersViewComponent },
      { path: 'AssetDetails/Trips/:id', component: AssetTripsViewComponent },
      { path: 'AssetDetails/Messages/:id', component: AssetMessageViewComponent },
      { path: 'AssetDetails/Log/:id', component: AssetLogViewComponent }]
  },
  {
    path: '', component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: 'TrailerDetails/Index/:id', component: TrailerDetailsViewComponent },
      { path: 'TrailerDetails/Assets/:id', component: TrailerAssetsViewComponent },
      { path: 'TrailerDetails/Log/:id', component: AssetLogViewComponent }]
  },
  {
    path: '', component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: 'AssetTypeDetails/Index/:id', component: AssetTypeDetailsViewComponent },
      { path: 'AssetTypeDetails/Assets/:id', component: AssetTypeAssetsViewComponent },
      { path: 'AssetTypeDetails/Calibration/:id', component: AssetTypeCalibrationViewComponent },
      { path: 'AssetTypeDetails/Log/:id', component: AssetTypeLogViewComponent }]
  },
  {
    path: '', component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: 'DriverDetails/Index/:id', component: DriverDetailsViewComponent },
      { path: 'DriverDetails/Devices/:id', component: DriverDevicesViewComponent },
      { path: 'DriverDetails/Assets/:id', component: DriverAssetsViewComponent },
      { path: 'DriverDetails/Log/:id', component: DriverLogViewComponent }]
  },
  {
    path: '', component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: 'PlatformDetails/Index/:id', component: PlatformDetailsViewComponent },
      { path: 'PlatformDetails/CalculationSettings/:id', component: PlatformCalculationSettingsViewComponent },
      { path: 'PlatformDetails/Devices/:id', component: PlatformDevicesViewComponent },
      { path: 'PlatformDetails/Issues/:id', component: PlatformIssuesViewComponent },
    ]
  },
  {
    path: '', component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: 'DeviceTypeDetails/Index/:id', component: DeviceTypeDetailsViewComponent },
      { path: 'DeviceTypeDetails/CalculationSettings/:id', component: DeviceTypeCalculationSettingsViewComponent },
      { path: 'DeviceTypeDetails/Issues/:id', component: DeviceTypeIssuesViewComponent },
      { path: 'DeviceTypeDetails/Devices/:id', component: DeviceTypeDevicesViewComponent }]
  },
  {
    path: '', component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: 'CustomerDetails/Index/:id', component: CustomerDetailsViewComponent },
      { path: 'CustomerDetails/Devices/:id', component: CustomerDevicesViewComponent },
      { path: 'CustomerDetails/Issues/:id', component: CustomerIssuesViewComponent },
      { path: 'CustomerDetails/CalculationSettings/:id', component: CustomerCalculationSettingsViewComponent }
    ]
  },
  {
    path: '', component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: 'IssueDetails/Index/:id', component: IssueDetailsViewComponent },
      { path: 'IssueDetails/Log/:id', component: IssueLogViewComponent }]
  },
  {
    path: '', component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: 'Users/Add', component: UserAddViewComponent },
      { path: 'UserDetails/Index/:id', component: UserDetailsViewComponent },
      { path: 'UserDetails/Log/:id', component: UserLogViewComponent },
      { path: 'UserRoleDetails/Index/:id', component: UserRoleDetailsViewComponent },
    ]
  },
  {
    path: '', component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: 'Accounts/Add', component: AccountAddViewComponent },
      { path: 'AccountDetails/Index/:id', component: AccountDetailsViewComponent },
      { path: 'AccountDetails/CalculationSettings/:id', component: AccountCalculationSettingsViewComponent },
      { path: 'AccountDetails/Log/:id', component: AccountLogViewComponent },
    ]
  },
  {
    path: '', component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: 'SensorTypeDetails/Index/:id', component: SensorTypeDetailsViewComponent },
    ]
  },
  {
    path: '', component: BlankLayoutComponent,
    children: [
      { path: 'Login', component: LoginComponent },
      { path: 'Register', component: RegisterComponent },
      { path: 'ForgotPassword', component: ForgotComponent },
      { path: 'Unsubscribe/:id', component: UnsubscribeComponent }
    ]
  }, {
    path: '', component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: 'Error404', component: Error404Component },
    ]
  }, {
    path: '', component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: 'About', component: AboutViewComponent },
    ]
  }, {
    path: '', component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: 'Help', component: HelpViewComponent },
    ]
  }, {
    path: '', component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: 'Wasl', component: WaslViewComponent },
      { path: 'Wasl/Company', component: WaslViewComponent },
      { path: 'Wasl/RegistrationStatus', component: WaslRegistrationStatusViewComponent },
      { path: 'Wasl/RegistrationStatus/:id', component: WaslRegistrationStatusViewComponent },
    ]
  },
  {
    path: '', component: BlankLayoutComponent,
    children: [
      { path: 'Auth-callback', component: AuthCallbackComponent },
      { path: 'Endsession-callback', component: EndsessionCallbackComponent },
      { path: 'SSOLogin', component: SsoLoginComponent },
    ],
  },
  // Handle all other routes
  { path: '**', redirectTo: 'Error404' }
];
