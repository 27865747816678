import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AssetService } from '../../services/asset/asset.service';
import { DeviceService } from '../../services/device/device.service';
import { tileLayer, latLng, circle, polygon, Map, marker, icon } from 'leaflet';
import { Asset } from 'app/models/asset.model';
import { DeleteModalComponent } from '../shared/usercontrols/deleteModal.component';
import { TrailerChooserComponent } from './trailerChooser.component';

declare var L;

@Component({
    selector: 'fh-fh-asset-trailers',
    templateUrl: 'trailers.template.html'
})
export class AssetTrailersViewComponent implements OnInit {

    @ViewChild('deleteModal') deleteModal: DeleteModalComponent;
    @ViewChild('trailerChooser') trailerChooser: TrailerChooserComponent;

    sub: any;
    asset: any;
    device: any;

    loadingAsset = false;
    loadingTrailers = false

    linkItem: any = { id: null, to: null, from: null };

    error: any;
    success: any;

    map: Map;
   defaultMap;

    options;
    marker;
    trailers: Asset[];
    assetId: any;



    constructor(private assetService: AssetService, private deviceService: DeviceService, private route: ActivatedRoute) {
    }

    onSelect(event) {
        this.linkItem.id = event.id;
        this.linkItem.name = event.name;
    }

    ngOnInit() {
        this.loadingAsset = true;
        this.loadingTrailers = true;

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];
            this.assetId = id;

            const assetFromService = this.assetService.getAssetById(id).subscribe(asset => {
                this.asset = asset;
                this.loadingAsset = false;
            });

            this.loadTrailers();
        });
    }


    loadTrailers() {
        this.loadingTrailers = true;
        const trailersFromService = this.assetService.getAssetsByParentId(this.assetId, 2).subscribe(trailers => {
            this.trailers = trailers;
            this.loadingTrailers = false;
        }, error => {
            this.error = error
            this.loadingTrailers = false;
        });
    }

    showAddPopup() {
        this.trailerChooser.showModal();
    }

    saveNew() {
        console.log('new save');
        this.loadingTrailers = true;
        this.assetService.linkAsset(this.asset.id, this.linkItem.id, this.linkItem.startDate, this.linkItem.EndDate).subscribe(result => {
            this.trailerChooser.hideModal();
            console.log('Trailer is linked', result);
            this.success = 'Trailer is linked';
            this.error = null;
            this.loadingTrailers = false;
            this.loadTrailers();

            this.linkItem = { id: null, to: null, from: null };
        }, error => {
            this.trailerChooser.hideModal();
            this.error = error
            this.loadingTrailers = false;
            this.loadTrailers();
        });
    }

    deleteAssetTrailer(id) {
        this.loadingTrailers = true;
        this.assetService.unLinkAsset(id).subscribe(result => {
            this.deleteModal.hideModal();
            this.success = 'Trailer is deleted';
            this.error = null;
            this.loadingTrailers = false;
            this.loadTrailers();
        }, error => {
            this.deleteModal.hideModal();
            this.error = error
            this.loadingTrailers = false;
            this.loadTrailers();
        });
    }

    // Modal

    showModal(id) {
        this.deleteModal.showModal(id);
    }

    hideModal() {
        this.deleteModal.hideModal();
    }
}
