import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch';

import { AuthenticationService } from '../authentication/authentication.service';
import { Asset } from '../../models/asset.model';
import { LoggingService } from '../logging/logging.service';
import { AssetType } from 'app/models/assetType.model';

@Injectable()
export class AssetTypeService {

    token: string;
    timestamp;
    url = '';
    Assets: Asset[] = [];

    constructor(private http: HttpClient, private loggingService: LoggingService, private authenticationService: AuthenticationService) {
        this.url = this.authenticationService.getInventoryURL('assetType');
        this.token = this.authenticationService.getAuthToken();
    }

    getPagingUrl(assetClass?: number) {
        return this.url + 'PagedAssetTypes' + (assetClass ? `?assetClass=${assetClass}` : '');
    }

    getAssetTypes(assetClass?: number): Observable<AssetType[]> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting assetTypes from service');
        return this.http.get(this.url + (assetClass ? `?assetClass=${assetClass}` : ''), { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getAssetTypeById(id: string): Observable<AssetType> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('Fetch AssetType by id ' + id);
        return this.http.get(this.url + id, { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseReponseDetails(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    resetAssetTypesCache(): Observable<boolean> {
        return this.http.get(this.url + 'ResetCache', { headers: this.authenticationService.headers })
            .map((data: any) => {
                return data;
            })
            .catch(this.handleError);
    }

    saveAssetType(assetType: AssetType): Observable<any> {
        console.log('save assetType');
        return this.http.post(this.url, assetType, { headers: this.authenticationService.headers })
            .catch(this.handleError);
    }


    updateAssetType(assetType: AssetType): Observable<any> {
        console.log('save assetType');
        return this.http.put(this.url, assetType, { headers: this.authenticationService.headers })
            .catch(this.handleError);
    }

    parseResponse(json: any): AssetType[] {

        this.loggingService.log(this.constructor.name, 'Retrieved ' + json.length + ' AssetTypes.');

        const ident = 1;
        const assets: AssetType[] = [];

        json.forEach(item => {

            const asset = this.parseReponseDetails(item);
            assets.push(asset);
        });

        this.timestamp = new Date().getTime();
        return assets;
    }

    parseReponseDetails(item) {
        //   this.loggingService.log(this.constructor().name + " - New", item);
        const asset = new AssetType();
        asset.id = item.id;
        asset.name = item.name;
        asset.year = item.year;
        asset.referenceId = item.referenceId;
        asset.brand = item.brand;
        asset.model = item.model;
        asset.serie = item.serie;
        asset.trim = item.trim;
        asset.category = item.category;
        asset.isEditable = item.isEditable;
        if (item.properties) {
            asset.properties = JSON.parse(item.properties);
        }
        asset.assetClass = item.assetClass;
        asset.assetCount = item.assetCount;

        return asset;
    }

    private handleError(error: Response) {
        console.log(error);
        return Observable.throw(error);
    }

}
