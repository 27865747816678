import { Component, Input, OnChanges, Output, ViewChild, EventEmitter, OnInit } from '@angular/core';
import { FormMode } from 'app/common/enums';
import { DeviceService } from 'app/services/device/device.service';
import { Device } from 'app/models/device.model';
import * as moment from 'moment';
import { FormControl, Validators, FormGroup } from '@angular/forms';
// Schedule
import { Schedule } from 'app/models/schedule.model';
import { ScheduleService } from 'app/services/schedule/schedule.service';
import { BsModalService, BsModalRef, ModalDirective, BsDatepickerConfig } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { IssueTypeService } from 'app/services/issuetypes/issuetypes.service';
import { IssueType } from 'app/models/issuetype.model';
import { UserService } from '../../../services/users/user.service';
import { WorkflowService } from '../../../services/workflows/workflows.service';
import { Workflow } from '../../../models/workflow.model';

class Advice {
    constructor(
        public workflow: string = '',
        public date: Date = moment().toDate(),
        public user: string = '') {
    }
}

@Component({
    selector: 'fh-advice-details',
    templateUrl: 'adviceDetails.component.html',
})
export class AdviceDetailsComponent implements OnInit, OnChanges {

    model: Advice = new Advice();

    issueTypes: IssueType[];

    insertAdviseSchedule: boolean;
    loading: boolean;
    schedule: Schedule[];

    // Datepicker
    minDate = new Date(2017, 5, 10);
    maxDate = new Date(2018, 9, 15);

    bsValue: Date = new Date();
    bsRangeValue: any = [new Date(2017, 7, 4), new Date(2017, 7, 20)];

    public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
    @ViewChild('f') form: any;

    @Input() device;
    @Input() issue;

    @Output() onUpdated = new EventEmitter();

    @ViewChild('autoShownModal') autoShownModal: ModalDirective;
    modalRef: BsModalRef;
    isModalShown = false;

    mode: FormMode;
    users: any;
    actions: any;
    workflows: Workflow[];

    constructor(private toastr: ToastrService, private userService: UserService, private modalService: BsModalService, private deviceService: DeviceService, private scheduleService: ScheduleService, private issueTypeService: IssueTypeService, private workflowService: WorkflowService) {
        this.schedule = [];
        this.loading = false;
        this.dpConfig.containerClass = 'theme-default'; // or whatever color
    }

    ngOnInit(): void {
        this.userService.getUsersByAccountId('00000000-0000-0000-0000-000000000000').subscribe(result => {
            this.users = result;
        });

        this.workflowService.getActions().subscribe(actions => {
            this.actions = actions;

            this.workflowService.getWorkflows(this.actions).subscribe(workflows => {
                this.workflows = workflows;
            });
        });
    }

    addSchedules() {

        if (this.form.valid) {
            console.log('Form Submitted!');
            this.form.reset();

            this.loading = true;

            this.insertAdviseSchedule = true;

            const schedule: Schedule = new Schedule;
            schedule.date = this.bsValue;
            schedule.id = null;
            schedule.actionId = 1;

            const deviceFromService = this.scheduleService.addSchedule(schedule).subscribe(response => {
                this.insertAdviseSchedule = false;

                this.toastr.success('Schedule added for device ' + schedule.date, 'Added', {
                    progressBar: true
                });

                this.loading = false;

                this.onUpdated.emit(schedule);
            });
        }
    }

    ngOnChanges(): void {
        this.issueTypeService.getIssueTypes().subscribe(issueTypes => {
            this.issueTypes = issueTypes;
        });
    }
}

