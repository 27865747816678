import { Component, OnDestroy, OnInit, ChangeDetectorRef, ViewChild, } from '@angular/core';

import { AuthenticationService } from '../../services/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { Asset } from 'app/models/asset.model';
import { FormMode } from 'app/common/enums';
import { AssetService } from 'app/services/asset/asset.service';
import { AssetTypeService } from 'app/services/asset/assetTypes.service';
import { AssetType } from 'app/models/assetType.model';
import { Router } from '@angular/router';
import { InventorySensorType } from 'app/models/sensorType.model';
import { SensorsTypesService } from 'app/services/sensors/sensorTypes.service';

@Component({
  selector: 'fh-sensor-type-add',
  templateUrl: 'add.template.html'
})
export class SensorTypeAddViewComponent {
  token: string;
  addDevice = false;
  asset: Asset;
  formMode = FormMode.add;
  loading = false;
  assetTypes: AssetType[] = [];
  error: any;
  success: { statusText: string; success: string; };
  sensorType: InventorySensorType;

  constructor(private router: Router, private sensorTypeService: SensorsTypesService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService) {
    this.sensorType = new InventorySensorType();

    this.token = this.authenticationService.getAuthToken();
  }

  onInsert() {
    this.loading = true;

    this.sensorTypeService.saveSensorType(this.sensorType).subscribe(result => {
      this.success = {
        statusText: 'Success',
        success: 'You will be redirected to the sensorType'
      };

      setTimeout(() => {
        this.router.navigate(['/SensorTypeDetails/Index/' + result.id]);
      }, 2000);

      this.loading = false;
    }, error => {
      this.error = error
      this.loading = false;
    });
  }

}
