import { AssetDriver } from './driver.model';

export class SlimWaslAsset {
    public sequenceNumber: string;
    public plateType: string;
    public referenceKey: string;
    public vehiclePlateNumber: any;
    public vehiclePlateLeftLetter: any;
    public vehiclePlateMiddleLetter: any;
    public vehiclePlateRightLetter: any;
}

export class Properties {
    public wasl = new SlimWaslAsset();
}

export class VehiclePlate {
    public number: string;
    public leftLetter: string;
    public middleLetter: string;
    public rightLetter: string;
}

export class WaslAsset {
    public vehiclePlate: VehiclePlate = new VehiclePlate();
    public sequenceNumber: string;
    public plateType: string;
    public imeiNumber: string;
    public referenceKey: string;
}

export class Asset {

    public id: string;
    public name: string;
    public description: string;
    public geoJson: string;
    public year: any;
    public vehicleType: any;
    public lastPosition: any;
    public vin: any;
    public referenceId: any;
    public plateNumber: any;
    public color: any;
    public code: any;
    public brand: any;
    public model: any;
    public deviceId: any;
    public assetDriver: AssetDriver;
    public assetClass: any;
    public assetTypeId: any;
    public assetTypeName: any;
    public identificationTag: string;

    public createNewAssetType = false;

    public properties = new Properties();
    public assetLinkId: any;
    public unitId: any;
    public assetLinkStartDate: any;
    public assetLinkEndDate: any;
    public isActive: boolean;
    public assetLinkType: any;

    public humiditySensorPosition: any;
    public humiditySensorTypeId: any;
    public temperatureSensorPosition: any;
    public temperatureSensorTypeId: any;
    public weightSensorPosition: any;
    public weightSensorTypeId: any;
}

export class AssetDevice {
    public deviceId: string;
    public assetId: string;
    public startDate: string;
    public endDate: string;
    public deviceName: any;
    isActive: boolean;
}
