<div *ngIf="isModalShown" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal"
    class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left"><i class="fa fa-trash-alt"></i> {{'general.areyousure' | translate }}
                </h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                {{ message | translate }}
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="delete()"><i class="fa fa-check"></i> {{'general.yes' | translate
                        }}</button>
                <button type="button" class="btn btn-danger" (click)="decline()"><i class="fa fa-times"></i> {{'general.cancel' | translate
                        }}</button>
            </div>
        </div>
    </div>
</div>