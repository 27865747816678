import { Component, Input, OnInit } from '@angular/core';
import { FormMode } from 'app/common/enums';
import { Asset } from 'app/models/asset.model';
import { ControlContainer, NgForm } from '@angular/forms';


@Component({
    selector: 'fh-asset-details',
    templateUrl: 'assetDetails.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class AssetDetailsComponent implements OnInit {

    @Input() asset: Asset;
    @Input() formMode: FormMode = FormMode.read;

    constructor() {
    }

    ngOnInit(): void {
    }

}

