import { Component, OnDestroy, OnInit, ChangeDetectorRef, ViewChild, } from '@angular/core';

import { ScheduleService } from 'app/services/schedule/schedule.service';
import { DataTableDirective } from 'angular-datatables';
import { IssueTypeService } from '../../services/issuetypes/issuetypes.service';
import { TranslateService } from '@ngx-translate/core';
import { parseDate } from 'ngx-bootstrap/chronos';
import { UserService } from '../../services/users/user.service';

@Component({
    selector: 'fh-schedule',
    templateUrl: 'schedule.template.html'
})
export class ScheduleViewComponent implements OnInit, OnDestroy {

    instance: DataTables.Api;
    @ViewChild(DataTableDirective)
    datatableElement: DataTableDirective;

    issueTypes: any;
    dtOptions = {};
    loading = false;

    dropdown_IssueType = '';
    showItemsInfuture = false;
    showOtherOwners = true;
    users;
    languageLoaded: boolean;
    searchTerm: any;

    constructor(private translateService: TranslateService, private userService: UserService, private issueTypeService: IssueTypeService, private cd: ChangeDetectorRef, private scheduleService: ScheduleService) {
        this.issueTypeService.getIssueTypes().subscribe(issueTypes => {
            this.issueTypes = issueTypes;
        });

        this.userService.getUsersByAccountId('00000000-0000-0000-0000-000000000000').subscribe(result => {
            this.users = result;
        });
    }

    editMode(isEditMode) {
        const toolbox = document.getElementById('grid-toolbox');

        console.log(isEditMode);
        if (isEditMode) {
            toolbox.classList.remove('hidden');
        } else {
            toolbox.classList.add('hidden');
        }
    }

    seachChanged(value) {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.search(value).draw();
        });
    }

    fireFilter(event = null): void {
        console.log('fire filter');
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.draw();
        });
    }

    ngOnDestroy(): void {
        // We remove the last function in the global ext search array so we do not add the fn each time the component is drawn
        // /!\ This is not the ideal solution as other components may add other search function in this array, so be careful when
        // handling this global variable
        $.fn['dataTable'].ext.search.pop();

        // Had to reset the array...
        $.fn['dataTable'].ext.search = [];
    }

    ngOnInit(): void {
        this.translateService.get('general.date').subscribe(value => {
            this.languageLoaded = true; this.initGrid();
        });
    }

    initGrid(): void {
        const that = this;
        this.loading = true;

        console.log($.fn['dataTable'].ext.search);

        // Init all filters

        // IssueType filter
        $.fn['dataTable'].ext.search.push((settings, data, dataIndex) => {
            const id = parseFloat(data[6]) || 0; // use data for the id column
            if (that.dropdown_IssueType === '' || that.dropdown_IssueType === '0' || id === parseFloat(that.dropdown_IssueType)) {
                return true;
            }
            return false;
        });

        // Date filter
        // const compareDate = Date.now();
        const compareDate = new Date();
        $.fn['dataTable'].ext.search.push((settings, data, dataIndex) => {
            const date = parseDate(data[3]);
            if (that.showItemsInfuture || date.getTime() <= compareDate.getTime()) {
                return true;
            }
            return false;
        });

        this.dtOptions = {
            pagingType: 'simple_numbers',
            serverSide: false,
            processing: false,
            deferRender: true,
            scrollX: true,
      colReorder: {fixedColumnsLeft: 1},
            order: [[2, 'asc']],
            stateSave: true,
            stateSaveCallback: function (settings, data) {
                localStorage.setItem('Columns_' + that.constructor.name, JSON.stringify(data));
            },
            stateLoadCallback: function () {
                try {
                    const columnSettings = JSON.parse(localStorage.getItem('Columns_' + that.constructor.name));
                    that.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
                    return columnSettings;
                } catch (e) {
                    console.log(e);
                }
            },
            ajax: (dataTablesParameters: any, callback) => {
                $('.dataTables_info').html(this.translateService.instant('grid.loadingData'));
                this.scheduleService.getSchedules().subscribe(resp => {
                    callback({
                        recordsTotal: resp.length,
                        recordsFiltered: resp.length,
                        data: resp
                    });
                });
            },
            initComplete: function (settings, json) {
                that.loading = false;
            },
            colVis: {
                restore: this.translateService.instant('general.restore'),
                showAll: this.translateService.instant('general.showAll'),
                showNone: this.translateService.instant('general.hideAll'),
                exclude: ['id']
            },
            columns: [{
                data: 'deviceId',
                width: '20',
                orderable: false,
                title: '',
                render: function (data, type, row) {
                    return data ? '<a class=\'btn btn-primary btn-outline btn-grid\' href=\'/#/DeviceDetails/Index/' + data + '\'><i class=\'fas fa-fw fa-info\'></i> <span class="hidden-sm hidden-xs">Details</span></a>' : '-';
                }
            },
            {
                data: 'id',
                width: '80',
                title: this.translateService.instant('general.id'),
                visible: false
            },
            {
                data: 'date',
                width: '80',
                title: this.translateService.instant('general.date'),
                render: function (data, type, row) {
                    return new Date(data).toLocaleDateString();
                }
            },
            {
                data: 'date',
                visible: false,
                title: this.translateService.instant('general.date'),
                render: function (data, type, row) {
                    return new Date(data).toJSON();
                }
            },
            {
                data: 'deviceName',
                title: this.translateService.instant('general.device'),
                width: '120',
                render: function (data, type, row) {
                    return data ? data : '-';
                }
            },
            {
                data: 'actionName',
                title: this.translateService.instant('general.action')
            },
            {
                data: 'workflowName',
                title: this.translateService.instant('general.workflow')
            },
            {
                data: 'userName',
                title: this.translateService.instant('general.user'),
                render: function (data, type, row) {
                    return data ? data : '-';
                }
            }],
            columnDefs: [
                { width: '80', targets: 0 },
                { width: '80', targets: 0 },
                { width: '80', targets: 0 },
                { width: '200', targets: 0 }
            ],
            pageLength: 10,
            language: {
                'lengthMenu': this.translateService.instant('grid.lengthmenu'),
                'zeroRecords': this.translateService.instant('grid.empty'),
                'info': this.translateService.instant('grid.info'),
                'infoEmpty': this.translateService.instant('grid.infoempty'),
                'infoFiltered': this.translateService.instant('grid.infofiltered'),
                'search': this.translateService.instant('grid.search'),
                'infoThousands': '.',
                'loadingRecords': this.translateService.instant('grid.loadingrecords'),
                'paginate': {
                    'first': this.translateService.instant('grid.first'),
                    'last': this.translateService.instant('grid.last'),
                    'next': this.translateService.instant('grid.next'),
                    'previous': this.translateService.instant('grid.previous'),
                },
                'aria': {
                    'sortAscending': this.translateService.instant('grid.sortasc'),
                    'sortDescending': this.translateService.instant('grid.sortdesc'),
                }
            },
            buttons: [
                {
                    extend: 'colvis',
                    text: '<i class="fa fa-files-o"></i> colvis',
                    titleAttr: 'Colvis'
                },
                {
                    extend: 'copyHtml5',
                    text: '<i class="fa fa-files-o"></i> Copy',
                    titleAttr: 'Copy'
                },
                {
                    extend: 'excelHtml5',
                    text: '<i class="fa fa-file-excel-o"></i> Xls',
                    titleAttr: 'Excel'
                },
                {
                    extend: 'csvHtml5',
                    text: '<i class="fa fa-file-text-o"></i> Csv',
                    titleAttr: 'CSV'
                },
                {
                    extend: 'pdfHtml5',
                    text: '<i class="fa fa-file-pdf-o"></i> Pdf',
                    titleAttr: 'PDF'
                }
            ]
        };
    }
}
