import { AddressDetails } from './platform.model';

/**
 * User info result object.
 */
export class User {
    public Id: string;
    public TimeZone: string;
    public TimeZoneIana: string;

    public Culture: string;
    public MapProvider: string;
    public ResellerId: number;

    public WhiteLabel: string;
    public Email: string;

    public DistanceUnit: string;
}

export class AppUser {
    referenceId: any;
    name: any;
    id: any;
    createdDate: any;
    accountId = '00000000-0000-0000-0000-000000000000'
    accountName: any;
    userRoleId: any;
    userRoleName: any;
    firstName: any;
    lastName: any;
    fullName: string;
    language: any;
    password = '';
    whitelabel: any;
    timezoneIana = 'Europe/Amsterdam';
    countryIsoCode: any;
    cronTabPattern: string;
    email: string;
}

export class UpdatePasswordRequest {
    id: any;
    userName: string;
    oldPassword: string;
    newPassword: string;
}

export class UserRole {
    name: any;
    id: any;
    permissions: any;
    userLevel: any;
}
