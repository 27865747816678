<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Assets/Overview">{{'general.asset' | translate }}</a>
            </li>
            <li>
                <a href="/#/AssetDetails/Index/{{asset?.id}}">{{asset?.name}}</a>
            </li>
            <li class="active">
                <strong>{{'general.device' | translate }}</strong>
            </li>
        </ol>
    </div>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="" *ngIf="error || success">
        <div class="col-lg-12">
            <fh-error [error]="error" [success]="success"></fh-error>
        </div>
    </div>

    <div class="row" data-equalizer="">
        <div class="col-lg-12">
            <div *ngIf="asset && !loadingDevice && !device" class="alert alert-warning shadow">
                {{'general.noDeviceLinked' | translate }}
            </div>

            <div *ngIf="asset && asset.properties.wasl.referenceKey" class="alert alert-warning shadow">
                {{'general.noUnlinkWhenWasl' | translate }}
            </div>

            <div class="ibox float-e-margins" style="position:relative">
                <ngx-loading [show]="loadingDevice"></ngx-loading>
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-memory"></i> {{'general.device' | translate }}</h5>
                    <span class="pull-right" *ngIf="device">
                        <i [routerLink]="['/DeviceDetails/Index/', device.id ]"
                            style=" font-size: 1.3em; cursor: pointer;"
                            class="fas fa-fw fa-2x fa-arrow-circle-right"></i>
                    </span>
                </div>
                <form #userForm="ngForm" #formDir="ngForm">
                    <fh-device-details *ngIf="device" [device]="device" [formMode]="formMode"></fh-device-details>
                </form>
                <div class="ibox-content slick" *ngIf="!device && linkItem.id">
                    <table style="width:100%" class="table table-hover nowrap dtr-inline no-footer">
                        <tbody>
                            <tr>
                                <td style="width:50%">
                                    {{ 'general.name' | translate}}
                                </td>
                                <td style="width:50%">
                                    {{linkItem.name}}
                                </td>
                            </tr>
                            <tr>
                                <td style="width:50%">
                                    {{ 'general.from' | translate}}
                                </td>
                                <td style="width:25%">
                                    <input [(ngModel)]="linkItem.from" name="from" #from="ngModel" type="text"
                                        autocomplete="off" class="form-control table-inline" [bsConfig]="dpConfig"
                                        bsDatepicker>
                                </td>
                                <td style="width:25%" style="padding: 6px;">
                                    <timepicker class="table-inline" [(ngModel)]="fromTime" name="fromTime"
                                        [showSpinners]="false" [showMeridian]="ismeridian">
                                    </timepicker>
                                </td>
                            </tr>
                            <tr>
                                <td style="width:50%">
                                    {{ 'general.to' | translate}}
                                </td>
                                <td style="width:25%">
                                    <input [(ngModel)]="linkItem.to" name="to" #to="ngModel" type="text"
                                        autocomplete="off" class="form-control table-inline" [bsConfig]="dpConfig"
                                        (bsValueChange)="onToDate($event)" bsDatepicker>

                                </td>
                                <td style="display: flex; padding: 6px;">
                                    <timepicker class="table-inline" [(ngModel)]="toTime" name="toTime"
                                        [showSpinners]="false" [showMeridian]="ismeridian">
                                    </timepicker>
                                    <i *ngIf="toTime" (click)="resetToDate()" title="{{'general.clear' | translate }}"
                                        style="display: flex; align-items: center; padding-left: 15px;"
                                        class="fas fa-fw fa-times"></i>

                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="ibox-footer" *ngIf="!device">
                    <button class="btn btn-primary" (click)="showAddPopup()" *ngIf="!device && !linkItem.id"><i
                            class="fa fa-fw fa-search"></i>
                        {{'general.search' | translate }}</button>
                    <button class="btn btn-primary" *ngIf="linkItem.id" (click)="saveNew()"><i
                            class="fas fa-fw fa-save"></i>
                        {{'general.assign' | translate }}</button>

                    <button class="btn btn-danger" *ngIf="linkItem.id" (click)="linkItem.id = null"><i
                            class="fas fa-fw fa-times"></i>
                        {{'general.cancel' | translate }}</button>
                </div>
            </div>
        </div>

    </div>

    <div class="row" data-equalizer="">
        <div class="col-lg-6" *ngFor="let assetDevice of assetDeviceHistory | orderBy : 'startDate':true">
            <div class="ibox float-e-margins" [class.inActive]="!assetDevice.isActive">
                <div class="ibox-title">
                    <h5>
                        <i class="fa fa-address-card"></i> {{ assetDevice?.deviceName }}
                        <!-- /#/DriverDetails/Index/{{driver?.id}} -->
                    </h5>
                    <span class="pull-right">
                        <i [routerLink]="['/DeviceDetails/Index/', assetDevice?.deviceId ]"
                            style=" font-size: 1.3em; cursor: pointer;"
                            class="fas fa-fw fa-2x fa-arrow-circle-right"></i>
                    </span>
                </div>
                <div class="ibox-content slick" style="position:relative">
                    <table style="width:100%" class="table table-hover nowrap dtr-inline no-footer">
                        <tbody>
                            <tr>
                                <td>
                                    {{ 'general.startDate' | translate}}
                                </td>
                                <td>
                                    {{ assetDevice.startDate | amDateFormat: 'LLL'  }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'general.endDate' | translate}}
                                </td>
                                <td>
                                    {{ assetDevice.endDate  | amDateFormat: 'LLL' }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="ibox-footer">
                    <button class="btn btn-danger" [disabled]="!assetDevice.isActive"
                        (click)="unlinkDevice(assetDevice.id)"><i class="fas fa-fw fa-trash-alt"></i>
                        {{'general.unlink' | translate }}</button>
                </div>
            </div>
        </div>
    </div>
</div>

<fh-device-chooser #deviceChooser (onSelect)="onSelect($event)"></fh-device-chooser>

<fh-delete-modal #deleteModal (onDelete)="onDeviceUnlink($event)"></fh-delete-modal>