<div class="ibox float-e-margins">
    <div class="ibox-title">
        <h5>
            <!-- SENSOR -->
            <i class="fas fa-sliders-h"></i> {{ 'chart.sensorDefaultName' | translate }}:
            {{ sensor.name }}{{ sensor.parameter ? ' (' + sensor.parameter + ')' : '' }}
        </h5>
        <i class="fas fa-fw fa-times pull-right" style="cursor: pointer;" (click)="close()"></i>
    </div>
    <div class="ibox-content slick" style="position: relative">
        <table style="width:100%; table-layout:fixed" class="table table-hover nowrap dtr-inline no-footer">
            <thead>
                <tr>
                    <th>
                        <div style="cursor: pointer; width: fit-content">
                            {{ 'general.sensorName' | translate }}
                        </div>
                    </th>
                    <th>
                        <div style="cursor: pointer; width: fit-content">
                            {{ 'general.name' | translate}}
                        </div>
                    </th>
                    <th>
                        <div style="cursor: pointer; width: fit-content">
                            {{ 'general.unitId' | translate }}
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let sensorDevice of sensorDevices | slice:((currentPage - 1) * 10): ((currentPage - 1) * 10) + 10">
                    <td>
                        {{ sensorDevice.sensorName }}{{sensorDevice.parameter ? ' (' + sensorDevice.parameter + ')' : ''}}
                    </td>
                    <td (click)="goToDeviceDetails(sensorDevice.id)" style="cursor: pointer;" class="secondary link_bolder">
                        {{ sensorDevice.deviceName }}
                    </td>
                    <td>
                        {{ sensorDevice.unitId }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="ibox-footer" *ngIf="sensorDevices && sensorDevices.length">

        <pagination [totalItems]="sensorDevices.length" [(ngModel)]="currentPage" (numPages)="smallnumPages = $event"
            [maxSize]="7" [boundaryLinks]="true"></pagination>

        <div style="clear:both"></div>
    </div>
</div>