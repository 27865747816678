import { Component, Input, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';


@Component({
    selector: 'fh-logger',
    templateUrl: 'logger.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoggerComponent implements OnInit {


    @Input() logItems = [];
    @Input() loading = false;

    // Pagination
    totalItems = 0;
    currentPage = 1;
    numPages = 0;
    maxSize = 10;
    itemsPerPage = 10;

    constructor(private cd: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.totalItems = this.logItems.length
    }

    updatePageNumber(value): void {
        this.numPages = value;
        this.cd.detectChanges();
    }

    // Pagination
    setPage(pageNo: number): void {
        this.currentPage = pageNo;
    }

    pageChanged(event: any): void {
        console.log('Page changed to: ' + event.page);
        console.log('Number items per page: ' + event.itemsPerPage);
    }
}

