<div class="row wrapper border-bottom white-bg page-heading">
        <div class="col-lg-12">
            <ol class="breadcrumb">
                <li>
                    <a href="/#/">
                        <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
                </li>
                <li class="active">
                    <strong>{{'about.header' | translate }}</strong>
                </li>
            </ol>
        </div>
    </div>

<div class="wrapper wrapper-content">
    
    <div class="row">
        <div class="col-lg-12">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-info"></i> {{'about.header' | translate }}</h5>
                </div>
                <div class="ibox-content">
                    <!-- Introduction Row -->
                    <p>{{'about.intro' | translate }}</p>
                </div>
            </div>
        </div>
    </div>

    <div class="row" data-equalizer>
        <div class="col-sm-4">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5 style="font-weight:bold">
                        <i class="fas fa-fw fa-memory"></i> {{'about.header' | translate }}
                    </h5>
                </div>
                <div class="ibox-content slick">
                    <div>
                        <table style="width:100%" class="table table-hover nowrap dtr-inline no-footer table-fixed">
                            <tbody>
                                <tr>
                                    <td style="width:40%" class="elipses_overflow">
                                        {{'about.build' | translate }}
                                    </td>
                                    <td style="width:60%">
                                        {{ appVersion }}
                                    </td>
                                </tr>
                                <!-- <tr>
                                    <td style="width:40%" class="elipses_overflow">
                                        {{'about.builddate' | translate }}
                                    </td>
                                    <td style="width:60%">
                                        {{ appDate  | amDateFormat: 'LLL' }}
                                    </td>
                                </tr> -->
                                <tr>
                                    <td style="width:40%" class="elipses_overflow">
                                        Endpoint
                                    </td>
                                    <td style="width:60%">
                                        {{ endPoint }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <span *ngFor="let application of applications">
            <div class="col-sm-4">
                <div class="ibox float-e-margins">
                    <div class="ibox-title">
                        <h5 style="font-weight:bold">
                            <i class="fas fa-fw fa-memory"></i> {{ application.application}}
                        </h5>
                    </div>
                    <div class="ibox-content slick">
                        <div>
                            <table style="width:100%" class="table table-hover nowrap dtr-inline no-footer table-fixed">
                                <tbody>
                                    <tr>
                                        <td style="width:40%" class="elipses_overflow">
                                            {{'about.build' | translate }}
                                        </td>
                                        <td style="width:60%">
                                            {{ application.version}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="width:40%" class="elipses_overflow">
                                            {{'about.builddate' | translate }}
                                        </td>
                                        <td style="width:60%">
                                            {{ application.date | amDateFormat: 'LLL' }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </span>
    </div>

    <div class="row">

        <div class="col-lg-3 col-sm-6 text-center mb-4">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw user-circle"></i>Wouter de Korte
                    </h5>
                    <span class="label pull-right"><small>Head of Development</small></span>
                </div>
                <div class="ibox-content">
                    <!-- Introduction Row -->
                    <img class="d-block" style="width:100%" src="/assets/images/employees/wouter-1024x1024.png" alt="">
                </div>
            </div>
        </div>

        <div class="col-lg-3 col-sm-6 text-center mb-4">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw user-circle"></i>Jelle de Vos
                    </h5>
                    <span class="label pull-right"><small>Senior developer</small></span>
                </div>
                <div class="ibox-content">
                    <!-- Introduction Row -->
                    <img class="d-block" style="width:100%" src="/assets/images/employees/jelle-1024x1024.png" alt="">
                </div>
            </div>
        </div>

        <div class="col-lg-3 col-sm-6 text-center mb-4">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw user-circle"></i>Paul Redert
                    </h5>
                    <span class="label pull-right"><small>Developer</small></span>
                </div>
                <div class="ibox-content">
                    <!-- Introduction Row -->
                    <img class="d-block" style="width:100%" src="/assets/images/employees/paul-1024x1024.png" alt="">
                </div>
            </div>
        </div>


        <div class="col-lg-3 col-sm-6 text-center mb-4">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw user-circle"></i>Volha Smirnova
                    </h5>
                    <span class="label pull-right"><small>Head of Brand Development</small></span>
                </div>
                <div class="ibox-content">
                    <!-- Introduction Row -->
                    <img class="d-block" style="width:100%" src="/assets/images/employees/volha-1024x1024.png" alt="">
                </div>
            </div>
        </div>

        <div class="col-lg-3 col-sm-6 text-center mb-4">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw user-circle"></i>Öteken Pekince
                    </h5>
                    <span class="label pull-right"><small>Junior developer</small></span>
                </div>
                <div class="ibox-content">
                    <!-- Introduction Row -->
                    <img class="d-block" style="width:100%" src="/assets/images/employees/oteken-1024x1024.png" alt="">
                </div>
            </div>
        </div>

        <div class="col-lg-3 col-sm-6 text-center mb-4">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw user-circle"></i>Damien Rietdijk
                    </h5>
                    <span class="label pull-right"><small>Junior developer</small></span>
                </div>
                <div class="ibox-content">
                    <!-- Introduction Row -->
                    <img class="d-block" style="width:100%" src="/assets/images/employees/damien-1024x1024.jpg" alt="">
                </div>
            </div>
        </div>

        <div class="col-lg-3 col-sm-6 text-center mb-4">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw user-circle"></i>Willem Romijn
                    </h5>
                    <span class="label pull-right"><small>Senior developer</small></span>
                </div>
                <div class="ibox-content">
                    <!-- Introduction Row -->
                    <img class="d-block" style="width:100%" src="/assets/images/employees/Willem-1024x1024.jpg" alt="">
                </div>
            </div>
        </div>

        <div class="col-lg-3 col-sm-6 text-center mb-4">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw user-circle"></i>Maurice Weijgand
                    </h5>
                    <span class="label pull-right"><small>Founder</small></span>
                </div>
                <div class="ibox-content">
                    <!-- Introduction Row -->
                    <img class="d-block" style="width:100%" src="/assets/images/employees/Maurice-1024x1024.jpg" alt="">
                </div>
            </div>
        </div>
        <div style="clear:both"></div>
    </div>
</div>