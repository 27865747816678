import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { Router } from '@angular/router';
import { appVersion } from 'app/app.version';

@Component({
  selector: 'fh-app-sso-login',
  templateUrl: './sso-login.component.html',
  styleUrls: ['./login.template.css']
})
export class SsoLoginComponent implements OnInit {

  appVersion: string;
  error = false;
  errorMessage: string;
  loading = false;

  constructor(private authService: AuthenticationService, private router: Router) {
    this.appVersion = appVersion;
  }

  ngOnInit() {
    this.authService.startAuthentication().then().catch(error => {this.error = true; });
  }

  failed(): boolean {
    return this.error;
  }

  redirectToLogin() {
    setTimeout(timeout => {this.router.navigate(['/Login']); }, 3000)
  }

  onMouseMove(event) {

    const e = event;
    // Mouse Move 3d Effect

    const perX = (e.clientX / $(window).width()) - 0.5;
    const perY = (e.clientY / $(window).height()) - 0.5;
    // TweenMax.to('.effect-3d-element', 0.4, { rotationY: 5 * perX, rotationX: 5 * perY, transformPerspective: 1000, transformOrigin: 'center' })
}
}
