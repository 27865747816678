export function createSearch(dtInstance, dynamicFilterContainer, excluding = [], createSelectBoxes = false): void {

     dynamicFilterContainer.html('');

     dtInstance.columns().every(function () {
          const that = this;


          if (that.visible() && that.header().textContent !== '' && excluding.indexOf(that.header().textContent) === -1) {

               const divColumn = $('<div/>', {
                    id: 'search' + that.index(),
                    'class': 'col-sm-6',
                    title: ''
               });

               const divColumnInner = $('<div/>', {
                    id: 'search' + that.index(),
                    'class': 'form-group input-group',
                    'style': 'margin-bottom: 5px;',
                    title: that.header().textContent
               });

               const icon = $('<i/>', {
                    id: 'search' + that.index(),
                    'class': 'fa fa-fw fa-pen',
                    title: that.header().textContent
               });

               const iconSpan = $('<span/>', {
                    id: 'search' + that.index(),
                    'class': 'input-group-addon',
                    title: that.header().textContent
               });

               icon.appendTo(iconSpan);

               iconSpan.appendTo(divColumnInner);

               if (1) {

                    // console.log(that.search());
                    // console.log(that.index(), that['column'](that.index()).search());

                    if (createSelectBoxes) {
                         const select = $('<select class="form-control"><option value=""></option></select>')
                              .on('change', function () {
                                   const val = $(this).val();

                                   that.column(that.index())
                                        .search(val)
                                        .draw();
                              });

                         const statusItems = [];

                         that.column(that.index()).data().unique().sort().each(function (d, j) {
                              select.append('<option value="' + d + '">' + d + '</option>')
                         });

                         select.appendTo(divColumnInner);
                    } else {
                         const textBox = $('<input/>', {
                              name: 'text',
                              id: 'text' + that.index(),
                              style: 'width:100%',
                              placeholder: that.header().textContent,
                              value: that['column'](that.index()).search(),
                              class: 'form-control'
                         }).on('keyup change', function () {
                              if (that.search() !== this['value']) {
                                   that
                                        .search(this['value'])
                                        .draw();
                              }
                         });

                         textBox.appendTo(divColumnInner);
                    }
               }

               divColumnInner.appendTo(divColumn);

               divColumn.appendTo(dynamicFilterContainer);
          }
     });
}

export function getGridLanguages(translateService) {
     return {
          'lengthMenu': translateService.instant('grid.lengthmenu'),
          'zeroRecords': translateService.instant('grid.empty'),
          'info': translateService.instant('grid.info'),
          'infoEmpty': translateService.instant('grid.infoempty'),
          'infoFiltered': translateService.instant('grid.infofiltered'),
          'search': translateService.instant('grid.search'),
          'infoThousands': '.',
          'processing': '<div class="sk-spinner sk-spinner-wave"><div class="sk-rect1"></div><div class="sk-rect2"></div><div class="sk-rect3"></div><div class="sk-rect4"></div><div class="sk-rect5"></div></div>',
          'loadingRecords': translateService.instant('grid.loadingrecords'),
          'paginate': {
               'first': translateService.instant('grid.first'),
               'last': translateService.instant('grid.last'),
               'next': translateService.instant('grid.next'),
               'previous': translateService.instant('grid.previous'),
          },
          'aria': {
               'sortAscending': translateService.instant('grid.sortasc'),
               'sortDescending': translateService.instant('grid.sortdesc'),
          }
     };
}

export function getGridButtons(commonExportOptions, fileName, header) {
     return [{
          extend: 'copy',
          text: 'Copy',
          exportOptions: commonExportOptions
     }, {
          extend: 'print',
          text: 'Print',
          exportOptions: commonExportOptions,
          messageTop: header
     }, {
          extend: 'csv',
          text: 'CSV',
          exportOptions: commonExportOptions,
          filename: fileName
     }, {
          extend: 'excel',
          text: 'XLS',
          exportOptions: commonExportOptions,
          filename: fileName
     }, {
          extend: 'pdf',
          text: 'PDF',
          exportOptions: commonExportOptions,
          orientation: 'landscape',
          filename: fileName,
          messageTop: header
     }];
}

export function getDropdownSettings(groupBy = true, badgeShowLimit = 3, showCheckbox = true) {
     return {
          classes: 'form-control multiselector_fix',
          enableSearchFilter: true,
          lazyLoading: false,
          badgeShowLimit: badgeShowLimit,
          showCheckbox: showCheckbox,
          groupBy: groupBy ? 'category' : ''
     };
}

export function getTranslatedDropdownSettings(translateService, name, groupBy = true, badgeShowLimit = 3, showCheckbox = true) {
     return {
          singleSelection: false,
          text: translateService.instant(('general.' + name)),
          selectAllText: translateService.instant('general.selectAll'),
          unSelectAllText: translateService.instant('general.deSelectAll'),
          searchPlaceholderText: translateService.instant('general.search'),
          noDataLabel: translateService.instant('general.noDataFound'),
          classes: 'form-control multiselector_fix',
          enableSearchFilter: true,
          lazyLoading: false,
          badgeShowLimit: badgeShowLimit,
          showCheckbox: showCheckbox,
          groupBy: groupBy ? 'category' : ''
     };
}

export function getDefaultDpConfig() {
     return {
          containerClass: 'theme-default',
          dateInputFormat: 'll',
          rangeSeparator: ' - ',
          rangeInputFormat: 'll'
     };
}

export const mapMultiDropdown = ((keys) => keys.map(key => ({
     'id': key.id, 'itemName': key.name
})));

export function setTableStars(table) {
     if (table.tableIds) {
          table.tableIds.forEach(id => {
               const star = $('#star_' + id);
               star.click(() => {
                    if (star.hasClass('fas')) {
                         star.removeClass('fas').addClass('far');
                    } else {
                         star.removeClass('far').addClass('fas');
                    }
                    table.changeFlag(id, star.hasClass('fas'));
               });
          });
     };
}

export function setSelection(table) {
     if (table.tableIdsSelection) {
          table.tableIdsSelection.forEach(id => {
               const checkbox = $('#selection_' + id);
               const parentRow = $(checkbox).closest('tr');

               checkbox.click(() => {
                    if (checkbox.hasClass('fa-square')) {
                         table.gridSelction.push(id);
                         checkbox.removeClass('fa-square').addClass('fa-check-square');
                         parentRow.addClass('selected');
                    } else {
                         const index = table.gridSelction.indexOf(id, 0);
                         if (index > -1) {
                              table.gridSelction.splice(index, 1);
                         }
                         checkbox.removeClass('fa-check-square').addClass('fa-square');
                         parentRow.removeClass('selected');
                    }
               });
          });
     };
}

export function setSelectionRows(table, row, data, gridSelection) {
     if ($.inArray(data.id, gridSelection) !== -1) {
          $(row).addClass('selected');
     }
}

