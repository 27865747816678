<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Devices/Overview">{{'general.devices' | translate }}</a>
            </li>
            <li>
                <a href="/#/DeviceDetails/Index/{{device?.id}}">{{device?.name}}</a>
            </li>
            <li class="active">
                <strong>{{'menu.sensors' | translate }}</strong>
            </li>
        </ol>
    </div>
</div>



<div class="wrapper wrapper-content">

    <div class="row" data-equalizer="">

        <div class="col-lg-12">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-rss"></i> {{'menu.sensors' | translate }}
                    </h5>
                </div>
                <div class="ibox-content slick" style="position:relative">
                    <ngx-loading [show]="loadingSensors"></ngx-loading>
                    <table style="width:100%" class="table table-hover nowrap dtr-inline no-footer">
                        <thead>
                            <tr>
                                <th>
                                    {{ 'general.name' | translate }}
                                </th>
                                <th>
                                    {{'general.param' | translate }}
                                </th>
                                <th>
                                    {{'general.uoM' | translate }}
                                </th>
                                <th>
                                    {{'general.sensorType' | translate }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let sensor of sensors | orderBy : 'name':false">
                                <td title="{{sensor.id}}">{{sensor.name}}</td>
                                <td>{{sensor.parameter}}</td>
                                <td>{{sensor.metrics}}</td>
                                <td>{{sensor.deviceSensorType}}</td>
                            </tr>
                            <tr *ngIf="sensors.length === 0">
                                <td colspan="3">
                                    {{'general.nodatafound' | translate }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            
        </div>
    </div>
</div>