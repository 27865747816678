import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { tileLayer, latLng, circle, polygon, Map, marker, icon, TileLayer } from 'leaflet';
import { DeviceService } from '../../services/device/device.service';
import { Device } from 'app/models/device.model';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MapService } from '../../services/common/map.service';
import * as L from 'leaflet';
import 'leaflet-easybutton';
import { getMapProviders, createMapOptions } from 'app/common/leafletGlobals';

declare var HeatmapOverlay;
declare var PruneCluster;
declare var PruneClusterForLeaflet;

@Component({
    selector: 'fh-assets-map',
    templateUrl: 'map.template.html',
    styleUrls: ['map.template.css'],
    providers: [MapService],
    animations: [
        trigger('slideInOut', [
            state('in', style({
                transform: 'translate3d(0, 0, 0)'
            })),
            state('out', style({
                transform: 'translate3d(calc(100% - 20px), 0, 0)'
            })),
            transition('in => out', animate('400ms ease-in-out')),
            transition('out => in', animate('400ms ease-in-out'))
        ]),
    ]
})
export class AssetsMapViewComponent implements OnInit, OnDestroy {

    leafletView: any;
    selectedDevice: Device;
    deviceId: string;
    loadingSidebar = false;
    googleMaps: TileLayer;
    googleHybrid: TileLayer;
    loading = false;
    markers: any;
    streets: any;
    osm: any;
    cities: any;
    map: Map;
    options;
    devices: Device[];
    menuState = 'out';

    mapData = {
        data: []
    };
    lookupDate = 0;
    updater;
    maps: { 'mapTiler': any; 'Google': any; 'Hybrid': any; };

    constructor(private theMapService: MapService, private zone: NgZone, private http: HttpClient, private deviceService: DeviceService) {
        this.initMap();
    }

    toggleMenu() {
        // 1-line if statement that toggles the value:
        this.menuState = this.menuState === 'out' ? 'in' : 'out';
    }

    ngOnInit() {
        console.log('getting locations');
        this.updater = setInterval(() => {
            this.getRefreshedLocations();
        }, 30000);
    }

    ngOnDestroy(): void {
        clearInterval(this.updater);
    }

    getRefreshedLocations() {
        console.log('getting locations 1');
        this.deviceService.getDeviceLocations(this.lookupDate).subscribe(result => {

            this.lookupDate = new Date().getTime() - 5000;
            result.Locations.forEach(location => {

                const device = this.devices.filter(x => x.assetId === location.AssetId)[0];
                if (device) {

                    if (device.theMarker == null) {
                        device.theMarker = new PruneCluster.Marker(location.Latitude, location.Longitude, { title: device.name });

                        this.leafletView.RegisterMarker(device.theMarker);
                    }

                    device.theMarker.category = location.DeviceState;
                    device.theMarker.Move(location.Latitude, location.Longitude);
                    device.lastCommunication = location.RtcBasedTimestamp;
                    device.speed = location.SpeedInKph;
                    device.hasGpsFix = location.HasGpsFix;
                } else {
                    console.log('no move ' + location.AssetId);
                }
            });

            this.leafletView.ProcessView();
            this.leafletView.RedrawIcons();
        });
    }

    // Leaflet

    initMap() {

        this.markers = L.featureGroup();
        this.leafletView = new PruneClusterForLeaflet();

        this.maps = getMapProviders(L);
        const initMaps = [];
        initMaps.push(Object.values(this.maps)[0]);
        initMaps.push(this.markers);
        initMaps.push(this.leafletView);
        this.options = createMapOptions(L, initMaps);

        const that = this;

        this.loading = true;
        setTimeout(function () {
            that.setPruneCluster(); // Marker clluster version
            that.fillDevices();
            this.loading = false;
        }, 100);
    }


    onMapReady(map: Map) {
        this.map = map;

        const overlayMaps = {};

        L.control.layers(this.maps, overlayMaps, { position: 'topleft' }).addTo(map);

        this.map.on('click', () => {
            this.zone.run(() => {
                this.menuState = 'out';
            });
        });

        const that = this;

        // Easybutton
        L.easyButton({
            id: 'fit map button',
            states: [{
                stateName: 'add-markers',
                icon: 'fa-expand-arrows-alt',
                title: 'Fit map',
                onClick: function (control) {
                    that.centerMap();
                }
            }]
        }).addTo(this.map);
    }


    createIcon() {
        return L.icon({
            iconSize: [25, 41],
            iconAnchor: [13, 41],
            iconUrl: 'assets/marker-icon.png',
            shadowUrl: 'assets/marker-shadow.png'
        });
    }

    setPruneCluster() {

        const that = this;
        this.theMapService.setPruneCluster(this.leafletView);

        this.leafletView.PrepareLeafletMarker = function (theMarker, data, category) {
            // parse data to icon
            theMarker.setIcon(that.createIcon());

            if (theMarker.getPopup()) {
                theMarker.setPopupContent(data.title + ' - ' + category);
            } else {
                theMarker.bindPopup(data.title + ' - ' + category);
            }

            theMarker.on('click', () => {
                that.zone.run(() => {
                    that.menuState = 'in';
                    that.selectedDevice = data.device;
                });

            });
        };

        this.leafletView.BuildLeafletClusterIcon = function (cluster) {
            const e = new L.Icon['MarkerCluster']({ className: cluster.hasAlert ? 'prunecluster leaflet-markercluster-icon leaflet-marker-pulse' : 'prunecluster leaflet-markercluster-icon' });
            e.stats = cluster.stats;
            e.population = cluster.population;

            return e;
        };

        const colors = ['#676767', '#008E43', '#971C24', '#EE9234', '#206EB4', '#000000', '#FFE99B'],
            pi2 = Math.PI * 2;

        L.Icon['MarkerCluster'] = L.Icon.extend({
            options: {
                iconSize: new L.Point(44, 44),
                className: 'prunecluster leaflet-markercluster-icon'
            },

            createIcon: function () {
                // based on L.Icon.Canvas from shramov/leaflet-plugins (BSD licence)
                const e = document.createElement('canvas');
                this._setIconStyles(e, 'icon');
                const s = this.options.iconSize;
                e.width = s.x;
                e.height = s.y;
                this.draw(e.getContext('2d'), s.x, s.y);
                return e;
            },

            createShadow: function () {
                return null;
            },

            draw: function (canvas, width, height) {

                const lol = 0;

                let start = 0;
                for (let i = 0, l = colors.length; i < l; ++i) {

                    const size = this.stats[i] / this.population;


                    if (size > 0) {
                        canvas.beginPath();
                        canvas.moveTo(22, 22);
                        canvas.fillStyle = colors[i];
                        let from = start + 0.14;
                        const to = start + size * pi2;

                        if (to < from) {
                            from = start;
                        }
                        canvas.arc(22, 22, 22, from, to);

                        start = start + size * pi2;
                        canvas.lineTo(22, 22);
                        canvas.fill();
                        canvas.closePath();
                    }
                }

                canvas.beginPath();
                canvas.fillStyle = 'white';
                canvas.arc(22, 22, 18, 0, Math.PI * 2);
                canvas.fill();
                canvas.closePath();

                canvas.fillStyle = '#555';
                canvas.textAlign = 'center';
                canvas.textBaseline = 'middle';
                canvas.font = 'bold 12px sans-serif';

                canvas.fillText(this.population, 22, 22, 40);
            }
        });
    }

    fillDevices() {
        const that = this;
        const markers = [];
        let size = 0;

        this.deviceService.getDevices().subscribe(devices => {
            this.devices = devices;
            this.devices.forEach(device => {
                if (device.lastPosition && Math.abs(device.lastPosition.latitude) > 0.1) {

                    // Prune cluster

                    device.theMarker = new PruneCluster.Marker(device.lastPosition.latitude, device.lastPosition.longitude, { title: device.name });

                    device.theMarker.category = 5;
                    device.theMarker.data.popup = device.name;
                    device.theMarker.data.device = device;

                    markers.push(device.theMarker);
                    size = size + 1;
                    this.leafletView.RegisterMarker(device.theMarker);
                }
            });
            this.loading = false;

            this.centerMap();

            this.getRefreshedLocations();

        });
    }

    centerMap() {
        const bounds = this.leafletView.Cluster.ComputeGlobalBounds();
        if (bounds) {
            this.map.fitBounds(new L.LatLngBounds(new L.LatLng(bounds.minLat, bounds.maxLng), new L.LatLng(bounds.maxLat, bounds.minLng)), { padding: [50, 50] });
        }
    }
}
