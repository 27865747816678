<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Tags">{{'general.tags' | translate }}</a>
            </li>
        </ol>
    </div>
</div>

<div class="wrapper wrapper-content">
    <div class="row">
        <div class="col-lg-12">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-tags"></i> {{'search.tags' | translate }}</h5>
                </div>
                <div class="ibox-content" style="position:relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <table style="width:100%" class="table table-hover nowrap dtr-inline no-footer">
                        <thead>
                            <tr>
                                <th>
                                    {{ 'general.tag' | translate}}
                                </th>
                                <th>
                                    {{ 'general.name' | translate}}
                                </th>
                                <th>
                                    {{ 'general.type' | translate}}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let tag of tags; index as i" (click)="goTo(tag)">
                                <td title="{{ tag.objectId }}">
                                    <a>{{tag.name}}</a>
                                </td>
                                <td>
                                    <a><b>{{tag.objectName}}</b></a>
                                </td>
                                <td>
                                    <a>{{('enums.objectType.'+ tag.objectType) | translate }}</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div style=" clear: both;">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>