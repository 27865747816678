<div class="wrapper wrapper-content">

    <div class="row" *ngIf="isSaved" (click)="isSaved = undefined">
        <div class="col-lg-12">
            <div ([ngModel])="updateMessage" class="alert alert-success shadow" [class.alert-success]="updateSuccess"
                [class.alert-danger]="!updateSuccess">
                {{ updateMessage }}
            </div>
        </div>
    </div>

    <div class="row" data-equalizer="">
        <div class="col-xl-8 col-lg-12">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fa fa-fw fa-cog"></i> {{'general.issuetypes' | translate }}
                    </h5>
                    <span class="pull-right" style="margin-top: -10px">
                        <button class="btn btn-primary" (click)="save()">
                            <i class="fa fa-fw fa-save"></i> {{'general.save' | translate }}</button>
                    </span>
                </div>
                <div class="ibox-content slick" style="padding:7px;position:relative">
                    <ngx-loading [show]="loadingSettings"></ngx-loading>
                    <table style="width:100%" class="table table-hover nowrap dtr-inline no-footer">
                        <thead>
                            <tr>
                                <th>
                                    {{ 'general.override' | translate}}
                                </th>
                                <th>
                                    {{ 'general.type' | translate}}
                                </th>
                                <th>
                                    {{ 'general.sensorTypeSmall' | translate}}
                                </th>
                                <th>
                                    {{ 'general.source' | translate}}
                                </th>
                                <th style="width:20px;">
                                    {{ 'general.uoM' | translate}}
                                </th>
                                <th>
                                    {{ 'general.threshold' | translate}}
                                </th>
                                <th>
                                    {{ 'general.duration' | translate}}
                                </th>
                                <th>
                                    {{ 'general.severity' | translate}}
                                </th>
                                <th>
                                    {{ 'general.active' | translate}}
                                </th>
                            </tr>
                        </thead>
                        <tbody #issueSettings>
                            <tr *ngFor="let issueType of issueTypesWithPermissions | orderBy : ['name']:false; let i = index"
                                [class.active]="issueType.isUsed">
                                <td>
                                    <i (click)="issueType.isUsed = !issueType.isUsed" class="fas"
                                        [class.fa-toggle-on]="issueType.isUsed"
                                        [class.fa-toggle-off]="!issueType.used"></i>
                                </td>
                                <td>{{ issueType.name }}

                                    <span [ngSwitch]="issueType?.id">
                                        <a *ngSwitchCase="29"
                                            href="https://360locate.com/docs/fleet-health/issue-detection/issue-type-time-outlier/"
                                            target="_blank"><i class="fa fa-question"></i></a>
                                        <a *ngSwitchCase="7"
                                            href="https://360locate.com/docs/fleet-health/issue-detection/issue-type-poor-gps-reception/"
                                            target="_blank"><i class="fa fa-question"></i></a>
                                        <a *ngSwitchCase="28"
                                            href="https://360locate.com/docs/fleet-health/issue-detection/issue-type-battery-power-too-low/"
                                            target="_blank"><i class="fa fa-question"></i></a>
                                        <a *ngSwitchCase="32"
                                            href="https://360locate.com/docs/fleet-health/issue-detection/issue-type-backup-battery-power-too-low/"
                                            target="_blank"><i class="fa fa-question"></i></a>
                                        <a *ngSwitchCase="4"
                                            href="https://360locate.com/docs/fleet-health/issue-detection/issue-type-too-many-power-loss-events/"
                                            target="_blank"><i class="fa fa-question"></i></a>
                                        <a *ngSwitchCase="6"
                                            href="https://360locate.com/docs/fleet-health/issue-detection/issue-type-loose-connection-or-ignition-wire/"
                                            target="_blank"><i class="fa fa-question"></i></a>
                                        <a *ngSwitchCase="33"
                                            href="https://360locate.com/docs/fleet-health/issue-detection/issue-type-missing-driver/"
                                            target="_blank"><i class="fa fa-question"></i></a>
                                        <a *ngSwitchCase="34"
                                            href="https://360locate.com/docs/fleet-health/issue-detection/issue-type-gps-drift/"
                                            target="_blank"><i class="fa fa-question"></i></a>
                                        <a *ngSwitchCase="35"
                                            href="https://360locate.com/docs/fleet-health/issue-detection/issue-type-delayed-messages/"
                                            target="_blank"><i class="fa fa-question"></i></a>
                                        <!--default case when there are no matches -->

                                    </span>
                                </td>
                                <td>
                                    {{ issueType.deviceSensorTypeId }}
                                </td>
                                <td>
                                    {{ ('enums.settingsLevel.' + issueType.source) | translate }}
                                </td>
                                <td style="width:20px;">
                                    <i *ngIf="issueType.editableThresholds != false" class="fa fa-fw fa-info" tooltip="{{ ('enums.issuetypesuom.' +
                                issueType.uom) | translate }}"></i>
                                </td>
                                <td style="width:170px" *ngIf="issueType.thresholdHasBounds == false">
                                    <form #f="ngForm" [class.has-error]="issueType.isUsed && !f.valid">
                                        <input required pattern="[+-]?([0-9]*[.])?[0-9]+" name="thresh-{{issueType.id}}"
                                            *ngIf="issueType.editableThresholds != false" type="text"
                                            class="form-control table-inline" [(ngModel)]="issueType.lowerThreshold"
                                            [disabled]="!issueType.isUsed" placeholder="Value"
                                            [class.text-danger]="issueType.isUsed && !f.valid">
                                        <div style="margin-top: 10px; margin-left: 5px;"
                                            *ngIf="issueType.isUsed && !f.valid">
                                            <small *ngIf="!f.valid" class="text-danger">
                                                {{ 'settings.fieldRequired' | translate }}
                                            </small>
                                            <small *ngIf="!f.valid" class="text-danger">
                                                {{ 'settings.fieldNumerical' | translate }}
                                            </small>
                                        </div>
                                    </form>
                                </td>
                                <td style="width:170px" *ngIf="issueType.thresholdHasBounds == true">
                                    <form #f1="ngForm" [class.has-error]="issueType.isUsed && !f1.valid">
                                        <input required pattern="[+-]?([0-9]*[.])?[0-9]+" name="thresh-{{issueType.id}}"
                                            style="width:50%;float: left;" *ngIf="issueType.editableThresholds != false"
                                            type="text" class="form-control table-inline"
                                            [(ngModel)]="issueType.lowerThreshold" [disabled]="!issueType.isUsed"
                                            placeholder="Lower bounds"
                                            [class.text-danger]="issueType.isUsed && !f1.valid">
                                    </form>
                                    <form #f="ngForm" [class.has-error]="issueType.isUsed && !f.valid">
                                        <input required pattern="[+-]?([0-9]*[.])?[0-9]+" style="width:50%;float: left;"
                                            *ngIf="issueType.editableThresholds != false && issueType.thresholdHasBounds == true"
                                            type="text" class="form-control table-inline" name="thresh-{{issueType.id}}"
                                            [(ngModel)]="issueType.upperThreshold" [disabled]="!issueType.isUsed"
                                            placeholder="Upper bounds"
                                            [class.text-danger]="issueType.isUsed && !f.valid">
                                    </form>
                                    <div style="margin-top: 30px; margin-left: 5px;"
                                        *ngIf="issueType.isUsed && (!f.valid || !f1.valid)">
                                        <small *ngIf="!f.valid || !f1.valid" class="text-danger">
                                            {{ 'settings.fieldRequired' | translate }}
                                        </small>
                                        <small *ngIf="!f.valid || !f1.valid" class="text-danger">
                                            {{ 'settings.fieldNumerical' | translate }}
                                        </small>
                                    </div>
                                </td>
                                <td style="width:90px">
                                    <form #f="ngForm" [class.has-error]="issueType.isUsed && !f.valid">
                                        <input type="text" value="Daily" [disabled]="true"
                                            class="form-control table-inline" *ngIf="issueType.hasDuration == 0">
                                        <input required pattern="^-?[0-9]*$" *ngIf="issueType.hasDuration != 0"
                                            type="text" class="form-control table-inline" name="dur-{{issueType.id}}"
                                            [(ngModel)]="issueType.duration" [disabled]="!issueType.isUsed"
                                            placeholder="Min" [class.text-danger]="issueType.isUsed && !f.valid">
                                        <div style="margin-top: 10px; margin-left: 5px;"
                                            *ngIf="issueType.isUsed && !f.valid">
                                            <small *ngIf="!f.valid" class="text-danger">
                                                {{ 'settings.fieldRequired' | translate }}
                                            </small>
                                            <small *ngIf="!f.valid" class="text-danger">
                                                {{ 'settings.fieldNumerical' | translate }}
                                            </small>
                                        </div>
                                    </form>
                                </td>
                                <td style="width:170px">
                                    <select name="status" id="severitySelectBox" [disabled]="!issueType.isUsed"
                                        style="width:100%" class="form-control table-inline" name="sev-{{issueType.id}}"
                                        [(ngModel)]="issueType.severity">
                                        <option value=''>{{'general.allSeverities' | translate }}</option>
                                        <option *ngFor="let severity of severities" [value]="severity.value">{{('enums.severity.'
                                            + severity.value) | translate }}
                                        </option>
                                    </select>
                                </td>
                                <td>
                                    <input name="chck-{{issueType.id}}" type="checkbox" [(ngModel)]="issueType.isActive"
                                        class="i-checks" [ngClass]="{'readonly': level !== issueType.source}"
                                        [disabled]="!issueType.isUsed" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="ibox-footer">
                    <button class="btn btn-primary" (click)="save()">
                        <i class="fa fa-fw fa-save"></i> {{'general.save' | translate }}</button>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-12">
            <!-- <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fa fa-fw fa-cog"></i> {{'menu.calculationsettings' | translate }}
                    </h5>
                </div>
                <div class="ibox-content slick" style="padding:7px;position:relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <table style="width:100%" class="table table-hover nowrap dtr-inline no-footer">
                        <tbody>
                            <tr>
                                <td>
                                    {{ 'general.level' | translate}}
                                </td>
                                <td>
                                    {{ ('enums.settingsLevel.' + level) | translate }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'general.id' | translate}}
                                </td>
                                <td>
                                    {{ currentId }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div> -->

            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fa fa-fw fa-code-branch"></i> {{'general.displayFlow' | translate }}
                    </h5>
                </div>
                <div class="ibox-content slick" style="padding:7px;position:relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <table style="width:100%" class="table table-hover nowrap dtr-inline no-footer">
                        <tbody>
                            <tr [class.activeRow]="level == 0">
                                <td>
                                    {{ ('enums.settingsLevel.0') | translate }}
                                </td>
                            </tr>
                            <tr [class.activeRow]="level == 1">
                                <td style="padding-left:30px">
                                    <i class="fa fa-fw fa-level-up-alt fa-rotate-90"></i> {{ ('enums.settingsLevel.1')
                                    | translate }}
                                </td>
                            </tr>
                            <tr [class.activeRow]="level == 2">
                                <td style="padding-left:60px">
                                    <i class="fa fa-fw fa-level-up-alt fa-rotate-90"></i> {{ ('enums.settingsLevel.2')
                                    | translate }}
                                </td>
                            </tr>
                            <tr [class.activeRow]="level == 3">
                                <td style="padding-left:60px">
                                    <i class="fa fa-fw fa-level-up-alt fa-rotate-90"></i> {{ ('enums.settingsLevel.3')
                                    | translate }}
                                </td>
                            </tr>
                            <tr [class.activeRow]="level == 4">
                                <td style="padding-left:90px">
                                    <i class="fa fa-fw fa-level-up-alt fa-rotate-90"></i> {{ ('enums.settingsLevel.4')
                                    | translate }}
                                </td>
                            </tr>
                            <tr [class.activeRow]="level == 5">
                                <td style="padding-left:120px">
                                    <i class="fa fa-fw fa-level-up-alt fa-rotate-90"></i> {{ ('enums.settingsLevel.5')
                                    | translate }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="ibox-footer">
                    <button class="btn btn-primary" [routerLink]="['/Settings/OverviewCalculationSettings']">
                        <i class="fa fa-fw fa-list-ul"></i> {{'menu.calculationSettingsOverview' | translate }}</button>
                </div>
            </div>
        </div>
    </div>
</div>