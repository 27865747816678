<div *ngIf="isModalShown" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal"
    class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-truck-loading"></i> {{'general.assetTypes' | translate }}
                    </h5>
                  <iboxseach [searchTerm]="searchTerm" (seachChanged)="seachChanged($event)"></iboxseach>
                </div>
                <div class="ibox-content slick" style="position: relative">
                    <table style="width:100%; min-height:50px;" datatable [dtOptions]="dtOptions"
                        class="table table-hover nowrap margin-bottom-0 dataTable dtr-inline">
                    </table>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary" [disabled]="!selected" (click)="select()"><i
                        class="fas fa-fw fa-check"></i>
                    {{'general.select' | translate }}</button>

                <button class="btn btn-danger" (click)="close()"><i class="fas fa-fw fa-times"></i>
                    {{'general.cancel' | translate }}</button>
            </div>
        </div>
    </div>
</div>