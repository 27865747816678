<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Assets/Overview">{{'general.asset' | translate }}</a>
            </li>
            <li class="active">
                <strong>{{'general.assetDetails' | translate }}</strong>
            </li>
        </ol>
    </div>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="">

        <div class="col-lg-12">
            <div class="ibox float-e-margins" style="position:relative">
                <ngx-loading [show]="loadingTrailers"></ngx-loading>
                <div class="ibox-title">
                    <h5>
                        <i class="fa fa-car"></i> {{'general.assets' | translate }}
                    </h5>
                </div>
                <div class="ibox-content" *ngIf="assets.length == 0">
                    {{ 'general.noAssets' | translate }}
                </div>
                <div class="ibox-footer">
                    {{ 'general.toAddAssetStory' | translate }}
                </div>
            </div>
        </div>

        <div class="col-lg-6" *ngFor="let asset of assets">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fa fa-car"></i> {{ asset.name }}
                    </h5>
                    <span class="pull-right" *ngIf="asset">
                        <i [routerLink]="['/AssetDetails/Index/', asset.id ]"
                            style=" font-size: 1.3em; cursor: pointer;"
                            class="fas fa-fw fa-2x fa-arrow-circle-right"></i>
                    </span>
                </div>
                <div class="ibox-content slick" style="position:relative">
                    <table style="width:100%" class="table table-hover nowrap dtr-inline no-footer">
                        <tbody>
                            <tr>
                                <td>
                                    {{ 'general.type' | translate}}
                                </td>
                                <td>
                                    {{ asset.type }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'general.brand' | translate}}
                                </td>
                                <td>
                                    {{ asset.brand }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'general.model' | translate}}
                                </td>
                                <td>
                                    {{ asset.model }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>