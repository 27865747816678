/**
 * Authentication request object.
 */
export class AuthenticateRequest {

    constructor(public Username: string, public Password: string, public Token: string) { }
}



