import { Component, OnInit, AfterViewInit } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from '../../services/customers/customers.service';
import { Customer } from '../../models/customer.model';

@Component({
    selector: 'fh-customer-calculation-settings',
    templateUrl: 'customerCalculationSettings.template.html'
})
export class CustomerCalculationSettingsViewComponent implements OnInit {
    sub;
    customer: Customer;

    constructor(private http: HttpClient, private customerService: CustomerService, private route: ActivatedRoute, private router: Router) {
        this.customer = null;
    }

    ngOnInit() {
        this.customer = new Customer;
        this.customer.id = ''

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];
            this.customerService.getCustomerById(id).subscribe(customer => {
                this.customer = customer;

                if (this.customer == null) {
                    this.router.navigate(['/Customers/Overview'])
                }
            });
        });
    }
}
