<div *ngIf="!(data === undefined || data === '')">

    <div class="ibox float-e-margins">
        <div class="ibox-title">
            <h5>
                <i class="fas fa-fw fa-chart-area"></i> {{'general.chart' | translate }}</h5>
            <span class="pull-right">

                <i *ngIf="loading" class="fa fa-spinner fa-spin fa-fw"></i>
            </span>

            <span class="pull-right">
                <i (click)="flipPlotIssueBand()" style=" font-size: 1.3em;padding-right: 25px" tooltip="{{'general.issue' | translate }}"
                    class="fas fa-2x" [ngClass]="{'fa-times-circle': plotIssueBands, 'fa-chart-area': !plotIssueBands}"></i>
                <i (click)="showSensors = !showSensors" style=" font-size: 1.3em;padding-right: 25px" tooltip="{{'menu.sensors' | translate }}"
                    class="fas fa-2x" [ngClass]="{'fa-times-circle': showSensors, 'fa-magic': !showSensors}"></i>
                <i (click)="flipDebugMode()" style=" font-size: 1.3em;" class="fas fa-2x" tooltip="{{'general.debug' | translate }}"
                    [ngClass]="{'fa-times-circle': isDebugMode, 'fa-bug': !isDebugMode}"></i>
            </span>
        </div>
        <div class="ibox-content" style="position: relative; min-height:72px">
            <ngx-loading [show]="loadingJson"></ngx-loading>
            <div style="height:300px" [chart]="chart">
            </div>
        </div>
    </div>
</div>

<div *ngIf="(data === undefined || data === '')">
    <div class="ibox float-e-margins">
        <div class="ibox-title">
            <h5>
                <i class="fas fa-fw fa-chart-area"></i> {{'general.chart' | translate }}</h5>
        </div>
        <div class="ibox-content" style="position: relative; min-height:72px">
            {{ 'general.noGeoJsonData' | translate }}
        </div>
    </div>
</div>

<div [hidden]="isDebugMode == false">
    <div class="ibox float-e-margins">
        <div class="ibox-title">
            <h5>
                <i class="fa fa-fw fa-bug"></i> {{'general.debug' | translate }}</h5>
        </div>
        <div class="ibox-content" style="position: relative; min-height:72px">
            <span *ngIf="isDebugMode == true">
                <prettyjson [obj]="data"></prettyjson>
            </span>
        </div>
    </div>
</div>

<div *ngIf="showSensors == true">
    <div class="ibox float-e-margins">
        <div class="ibox-title">
            <h5>
                <i class="fas fa-fw fa-clock"></i> {{'menu.sensors' | translate }}
            </h5>
        </div>
        <div class="ibox-content slick" style="position:relative">
            <ngx-loading [show]="loadingSensors"></ngx-loading>
            <table style="width:100%" class="table table-hover nowrap dtr-inline no-footer">
                <thead>
                    <tr>
                        <th>
                            {{ 'general.name' | translate }}
                        </th>
                        <th>
                            {{'general.param' | translate }}
                        </th>
                        <th>
                            {{'general.uoM' | translate }}
                        </th>
                        <th>
                            {{'general.sensorType' | translate }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let sensor of deviceSensors | orderBy : 'name':false">
                        <td>{{sensor.name}}</td>
                        <td>{{sensor.parameter}}</td>
                        <td>{{sensor.metrics}}</td>
                        <td>{{sensor.deviceSensorType}}</td>
                    </tr>
                    <tr *ngIf="deviceSensors.length === 0">
                        <td colspan="3">
                            {{'general.nodatafound' | translate }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>