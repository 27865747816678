import { Component, Input, OnInit } from '@angular/core';
import { FormMode, languages, whitelabels } from 'app/common/enums';
import { ControlContainer, NgForm } from '@angular/forms';
import { AppUser, UserRole } from 'app/models/user.model';
import { UserRoleService } from 'app/services/users/userRole.service';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { InventoryAccount } from 'app/models/account.model';


@Component({
    selector: 'fh-user-details',
    templateUrl: 'userDetails.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class UserDetailsComponent implements OnInit {

    @Input() user: AppUser = new AppUser();
    @Input() accounts: InventoryAccount[] = [];
    @Input() formMode: FormMode = FormMode.read;
    passwordConfirmation = '';
    userRoles: UserRole[];
    languages: { name: string; path: any; }[];
    permissions: {};
    whitelabels: { value: string; name: any; }[];
    loadingUserRoles = false;

    constructor(private authenticationService: AuthenticationService, private userRoleService: UserRoleService) {
        this.permissions = this.authenticationService.permissions;

        this.loadingUserRoles = true;
        this.userRoleService.getUserRoles().subscribe(userRoles => {
            this.loadingUserRoles = false;

            this.userRoles = userRoles;

            if (this.formMode === FormMode.add) {
                this.user.userRoleId = userRoles.sort((a, b) => b.userLevel - a.userLevel)[0].id;
            }
        });

        this.languages = Object.keys(languages)
            .filter(k => typeof languages[k] === 'string')
            .map(n => ({ name: n, path: languages[n] }));

        this.whitelabels = Object.keys(whitelabels)
            .filter(k => typeof whitelabels[k] === 'string')
            .map(n => ({ value: n, name: whitelabels[n] }));


    }

    ngOnInit(): void {
        if (this.formMode === FormMode.add) {
            if (this.user.whitelabel == null) {
                this.user.whitelabel = this.whitelabels[0].value;
            }

            this.user.language = 'en';
        }
    }
}

