<div class="ibox float-e-margins">
    <div class="ibox-title">
        <h5>
            <i class="fas fa-fw fa-calendar-alt"></i> {{'schedule.schedule' | translate }}</h5>
    </div>
    <div style="position:relative">
        <ngx-loading [show]="loading"></ngx-loading>
        <div class="ibox-content slick" style="min-height:50px;">

            <table style="width:100%;" class="table table-hover table-striped nowrap dtr-inline no-footer">
                <tbody>
                    <tr *ngFor="let scheduleItem of schedule">
                        <td style="width:30px; padding-left:10px">
                            <input type="radio" name="radiogroup" [value]="scheduleItem.id" (change)="onSelectionChange(scheduleItem)">
                        </td>
                        <td style="width:93px">{{ scheduleItem.date | amDateFormat: 'll'}}</td>
                        <td>{{ scheduleItem.userName }}</td>
                        <td>{{ scheduleItem.actionName }}</td>
                        <td>{{ scheduleItem.workflowName }}</td>
                    </tr>
                    <tr *ngIf="schedule.length === 0">
                        <td>{{'schedule.noschedulefound' | translate }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="ibox-footer">
            <div class="col-md-12" style="padding-left: 0">
                <button class="btn btn-primary" (click)="startProcessSchedule()" [disabled]="!selectedSchedule">
                    <i class="fas fa-fw fa-calendar-check"></i> {{'schedule.processschedule' | translate }}</button>
                <button class="btn btn-primary" [disabled]="!selectedSchedule">
                    <i class="fas fa-fw fa-calendar-alt"></i> {{'schedule.changedate' | translate }}</button>
                <button class="btn btn-primary" [disabled]="!selectedSchedule">
                    <i class="fas fa-fw fa-calendar-times"></i> {{'schedule.skip' | translate }}</button>
            </div>
            <div class="col-md-12">
                <label style="padding-top: 6px;padding-left: 12px;">
                    <input type="checkbox" name="checkboxInFuture" checked=""> {{'schedule.showinfuture' | translate }}</label>
                <label style="padding-top: 6px;padding-left: 12px;">
                    <input type="checkbox" name="checkboxInFuture" checked=""> {{'schedule.showinfuture' | translate }}</label>
            </div>
            <div style="clear:both"></div>
        </div>
    </div>
</div>

<div *ngIf="isModalShown" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">
                    <i class="fas fa-fw fa-calendar"></i> {{'schedule.schedule' | translate }}</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <fh-wizard [(step)]="step" (finish)="onScheduleFinish()">
                <fh-wizard-step title='Current action' icon='fa-eye'>
                    <table style="width:100%" class="table nowrap dtr-inline no-footer">
                        <tbody>
                            <tr>
                                <td>
                                    {{ 'general.issue' | translate}}
                                </td>
                                <td>
                                    {{ scheduleForProcessing?.issueId }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'general.date' | translate}}
                                </td>
                                <td>
                                    {{ scheduleForProcessing?.date | amDateFormat: 'll' }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'general.owner' | translate}}
                                </td>
                                <td>
                                    {{ scheduleForProcessing?.ownerId }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'general.action' | translate}}
                                </td>
                                <td>
                                    {{ scheduleForProcessing?.actionDescription }}
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    Lorem ipsum. L Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.
                                    Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
                                    Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat
                                    massa quis enim
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </fh-wizard-step>
                <fh-wizard-step title='Description & Log work' icon='fa-bullhorn'>
                    <table style="width:100%" class="table nowrap dtr-inline no-footer">
                        <tbody>
                            <tr>
                                <td>
                                    {{ 'general.action' | translate}} (Processed.. skipped.. reschedule)
                                </td>
                                <td>
                                    <input id="action" class="form-control" required>
                                    <!-- formControlName="name" -->
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'general.owner' | translate}}
                                </td>
                                <td>
                                    <input id="owner" class="form-control" required>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" style="padding:0">
                                    <textarea class="form-control noresize stickypad" style="height:100px; width:100%; resize: none" id="encCss"></textarea>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </fh-wizard-step>
                <fh-wizard-step title='Next action' icon='fa-file-pdf' disabled="false" [validate]="myform" (open)="initForm()">
                    <form novalidate [formGroup]="myform" style="width:100%">

                        <fieldset formGroupName="name">
                            <div class="form-group" [ngClass]="{
                          'has-error': firstName.invalid && (firstName.dirty || firstName.touched),'has-success': firstName.valid && (firstName.dirty || firstName.touched)}">
                                <div class="col-md-6">First Name</div>
                                <div class="col-md-6">
                                    <input type="text" class="form-control" formControlName="firstName" required>
                                </div>
                            </div>

                            <div class="form-group " [ngClass]="{ 'has-error': lastName.invalid && (lastName.dirty || lastName.touched),'has-success': lastName.valid && (lastName.dirty || lastName.touched) } ">
                                <div class="col-md-6">Last Name</div>
                                <div class="col-md-6">
                                    <input type="text " class="form-control " formControlName="lastName" required>
                                </div>
                            </div>
                        </fieldset>

                        <div class="form-group " [ngClass]="{ 'has-error': email.invalid && (email.dirty || email.touched),'has-success': email.valid && (email.dirty || email.touched) } ">
                            <div class="col-md-6">Email</div>
                            <div class="col-md-6">
                                <input type="email " class="form-control " formControlName="email" required>
                            </div>
                            <div class="col-md-12 error" *ngIf="email.errors && (email.dirty || email.touched) ">
                                <p *ngIf="email.errors.required ">Email is required</p>
                                <p *ngIf="email.errors.pattern ">The email address must contain at least the @ character</p>
                            </div>
                        </div>

                        <div class="form-group " [ngClass]="{ 'has-error': password.invalid && (password.dirty || password.touched),'has-success': password.valid && (password.dirty || password.touched) } ">
                            <div class="col-md-6">Password</div>
                            <div class="col-md-6">
                                <input type="password " class="form-control " formControlName="password" required>
                            </div>
                            <div class="col-md-12 error" *ngIf="password.errors && (password.dirty || password.touched) ">
                                <p *ngIf="password.errors.required ">Password is required</p>
                                <p *ngIf="password.errors.minlength ">Password must be 8 characters long, we need another {{password.errors.minlength.requiredLength
                                    - password.errors.minlength.actualLength}} characters </p>
                            </div>
                        </div>
                        <div class="form-group " [ngClass]="{ 'has-danger': language.invalid && (language.dirty || language.touched),'has-success': language.valid && (language.dirty || language.touched) } ">
                            <div class="col-md-6">Language</div>
                            <div class="col-md-6">
                                <select class="form-control" formControlName="language" required>
                                    <option value="">Please select a language</option>
                                    <option *ngFor="let lang of langs " [value]="lang">{{lang}}</option>
                                </select>
                            </div>
                        </div>

                        <pre>{{myform.value | json}}</pre>
                    </form>
                </fh-wizard-step>
                <fh-wizard-step title='Validate & Finish' icon='fa-save'>
                    Finish step
                </fh-wizard-step>
            </fh-wizard>
        </div>
    </div>
</div>