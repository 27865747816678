<div class="ibox-content slick" style="position:relative">
    <ngx-loading [show]="loadingUserRoles"></ngx-loading>
    <table style="width:100%" class="table table-hover nowrap dtr-inline no-footer">
        <tbody *ngIf="!user">
            <tr>
                <td colspan="4">
                    {{ 'general.noUser' | translate}}
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="user && formMode == 1">
            <tr>
                <td style="width:50%">
                    {{ 'general.userNameEmail' | translate}}
                </td>
                <td style="width:50%">
                    {{ user?.name }}
                </td>
            </tr>
            <tr>
                <td style="width:50%">
                    {{ 'general.firstName' | translate}}
                </td>
                <td style="width:50%">
                    {{ user?.firstName }}
                </td>
            </tr>
            <tr>
                <td style="width:50%">
                    {{ 'general.lastName' | translate}}
                </td>
                <td style="width:50%">
                    {{ user?.lastName }}
                </td>
            </tr>
            <tr>
                <td>
                    {{ 'general.account' | translate}}
                </td>
                <td>
                    <a *ngIf="permissions['IsAdministrator']" class="secondary link_bolder"
                        href="/#/AccountDetails/Index/{{user?.accountId}}">{{
                            user?.accountName }}</a>
                    <span *ngIf="!permissions['IsAdministrator']">{{
                            user?.accountName }}</span>
                </td>
            </tr>
            <tr>
                <td>
                    {{ 'general.createdDate' | translate}}
                </td>
                <td>
                    {{ user?.createdDate | amDateFormat: 'll' }}
                </td>
            </tr>
            <tr>
                <td>
                    {{ 'general.language' | translate}}
                </td>
                <td>
                    {{user?.language}}
                </td>
            </tr>
            <tr>
                <td>
                    {{ 'dates.timezone' | translate}}
                </td>
                <td>
                    {{user?.timezoneIana}}
                </td>
            </tr>
            <tr>
                <td>
                    {{ 'general.userRole' | translate}}
                </td>
                <td>
                    <a *ngIf="permissions['IsAdministrator']" class="secondary link_bolder"
                        href="/#/UserRoleDetails/Index/{{user?.userRoleId}}">{{
                            user?.userRoleName }}</a>

                    <span *ngIf="!permissions['IsAdministrator']">{{
                            user?.userRoleName }}</span>
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="user && formMode != 1">
            <tr>
                <td style="width:50%">
                    {{ 'general.userNameEmail' | translate}}
                    <span class="error" *ngIf="name.invalid && formMode != 1">*</span>
                </td>
                <td colspan="2" style="width:50%">
                    <input type="text" [readonly]="formMode == 1" name="name" class="form-control table-inline"
                        [(ngModel)]="user.name" required minlength="4" #name="ngModel">
                </td>
            </tr>
            <tr>
                <td style="width:50%">
                    {{ 'general.password' | translate}}
                    <span class="error" *ngIf="password.invalid && formMode != 1">*</span>
                </td>
                <td colspan="2" style="width:50%">
                    <input type="password" [readonly]="formMode == 1" name="password" class="form-control table-inline"
                        [(ngModel)]="user.password" required minlength="4" #password="ngModel">
                </td>
            </tr>
            <tr>
                <td style="width:50%">
                    {{ 'general.repeatPassword' | translate}}
                    <span class="error" *ngIf="password2.invalid && formMode != 1">*</span>
                </td>
                <td colspan="2" style="width:50%">
                    <input type="password" [readonly]="formMode == 1" name="password2" class="form-control table-inline"
                        [(ngModel)]="passwordConfirmation" required minlength="4" #password2="ngModel">
                </td>
            </tr>
            <tr class="alert alert-danger"
                *ngIf="password.value?.length > 0 && password2.value?.length > 0  && password.value !== password2.value || (password?.invalid && (password?.dirty || password?.touched))">
                <td colspan="2">
                    <div *ngIf="password.value !== password2.value">
                        * Passwords do not match
                    </div>
                    <div *ngIf="password?.errors?.minlength">
                        * Password must be at least 4 characters long.
                    </div>
                    <div *ngIf="password?.errors?.required">
                        * Password is required
                    </div>
                </td>
            </tr>
            <tr>
                <td style="width:50%">
                    {{ 'general.firstName' | translate}}
                    <span class="error" *ngIf="firstName.invalid && formMode != 1">*</span>
                </td>
                <td colspan="2" style="width:50%">
                    <input type="text" [readonly]="formMode == 1" required name="firstName"
                        class="form-control table-inline" [(ngModel)]="user.firstName" #firstName="ngModel">
                </td>
            </tr>
            <tr>
                <td style="width:50%">
                    {{ 'general.lastName' | translate}}
                    <span class="error" *ngIf="lastName.invalid && formMode != 1">*</span>
                </td>
                <td colspan="2" style="width:50%">
                    <input type="text" [readonly]="formMode == 1" name="plate" class="form-control table-inline"
                        [(ngModel)]="user.lastName" required #lastName="ngModel">
                </td>
            </tr>


            <tr>
                <td style="width:50%">
                    {{ 'general.language' | translate}}
                    <span class="error" *ngIf="language.invalid && formMode != 1">*</span>
                </td>
                <td colspan="2" style="width:50%">
                    <select name="language" [disabled]="formMode == 1" [(ngModel)]="user.language"
                        class="form-control table-inline" required #language="ngModel" style="width:100%">
                        <option *ngFor="let language of languages" value={{language.name}}>
                            {{language.name}}
                        </option>
                    </select>
                </td>
            </tr>
            <ng-container *ngIf="accounts.length > 0 && formMode == 2">
                <tr>
                    <td>
                        {{ 'general.account' | translate}}
                        <span class="error" *ngIf="account.invalid && formMode != 1">*</span>
                    </td>
                    <td>
                        <select name="account" [(ngModel)]="user.accountId" class="form-control table-inline"
                            #account="ngModel" style="width:100%">
                            <option *ngFor="let account of accounts" value={{account.id}}>
                                {{account.name}}
                            </option>
                        </select>
                    </td>
                </tr>
            </ng-container>
            <tr>
                <td>
                    {{ 'general.whitelabel' | translate}}
                    <span class="error" *ngIf="whitelabel.invalid && formMode != 1">*</span>
                </td>
                <td>
                    <select name="whitelabel" [(ngModel)]="user.whitelabel" class="form-control table-inline"
                        #whitelabel="ngModel" style="width:100%">
                        <option *ngFor="let whitelabel of whitelabels" value={{whitelabel.value}}>
                            {{whitelabel.name}}
                        </option>
                    </select>
                </td>
            </tr>
            <tr>
                <td style="width:50%">
                    {{ 'general.userRole' | translate}}
                    <span class="error" *ngIf="userRole.invalid && formMode != 1">*</span>
                </td>
                <td colspan="2" style="width:50%">
                    <select name="userRole" [disabled]="formMode == 1" [(ngModel)]="user.userRoleId"
                        class="form-control table-inline" required #userRole="ngModel" style="width:100%">
                        <option *ngFor="let userRole of userRoles" [value]="userRole.id">
                            {{ userRole.name }}</option>
                    </select>
                </td>
            </tr>
            <tr>
                <td>
                    {{ 'dates.timezone' | translate}}
                </td>
                <td>
                    <ng2-timezone-picker-v1 [(timezone)]="user.timezoneIana" (timezoneValueChange)="user.timezoneIana = $event"></ng2-timezone-picker-v1>
                </td>
            </tr>
        </tbody>
    </table>
</div>