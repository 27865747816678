<nav class="navbar-default navbar-static-side" role="navigation">
  <div class="sidebar-collapse">
    <ul class="nav metismenu" id="side-menu">
      <li class="nav-header">
        <div class="dropdown profile-element" [routerLink]="['/']">
          <div class="site_logo"></div>
        </div>
        <div class="logo-element" [routerLink]="['/']">
          <div class="fas"><img width="75%" height="75%" src='/assets/images/360fav-200x200.png' alt='Logo'></div>
        </div>
      </li>
      <li [ngClass]="{'mm-active': activeRoute('Dashboard')}">
        <a href="#">
          <i class="fas fa-fw fa-th-large"></i>
          <span class="nav-label">{{ 'menu.dashboard' | translate }}</span>
          <span class="fa arrow"></span>
        </a>
        <ul class="nav nav-second-level collapse" [ngClass]="{in: activeRoute('Dashboard')}">
          <li *ngIf="permissions['IsAdministrator']" [ngClass]="{'mm-active': activeRoute('Dashboard/Day')}">
            <!-- Admin dashboard -->
            <a [routerLink]="['/Dashboard/Day/']">
              <i class="fas fa-fw fa-calendar-day"></i>
              <span class="nav-label">{{ 'menu.dashboard' | translate }}</span>
            </a>
          </li>
          <li *ngIf="!permissions['IsAdministrator'] && permissions['HasWasl']" [ngClass]=" {active:
            activeRoute('Dashboard/Day')}"> <a [routerLink]="['/Dashboard/Day/']">
              <i class="fas fa-fw fa-calendar-day"></i>
              <span class="nav-label">{{ 'menu.dashboard' | translate }}</span>
            </a>
          </li>
          <li *ngIf="!permissions['IsAdministrator'] && permissions['HasIssues']" [ngClass]=" {active:
            activeRoute('Dashboard/Day')}"> <a [routerLink]="['/Dashboard/Day/']">
              <i class="fas fa-fw fa-calendar-day"></i>
              <span class="nav-label">{{ 'menu.lastDay' | translate }}</span>
            </a>
          </li>
          <li *ngIf="!permissions['IsAdministrator']  && permissions['HasIssues']"
            [ngClass]="{active: activeRoute('Dashboard/Week')}">
            <a [routerLink]="['/Dashboard/Week/']">
              <i class="fas fa-fw fa-calendar-week"></i>
              <span class="nav-label">{{ 'menu.lastWeek' | translate }}</span>
            </a>
          </li>
          <li *ngIf="!permissions['IsAdministrator']  && permissions['HasIssues']"
            [ngClass]="{active: activeRoute('Dashboard/Month')}">
            <a [routerLink]="['/Dashboard/Month/']">
              <i class="fas fa-fw fa-calendar-alt"></i>
              <span class="nav-label">{{ 'menu.lastMonth' | translate }}</span>
            </a>
          </li>
        </ul>
      </li>
      <!-- <li [ngClass]="{active: activeRoute('Search')}">
        <a [routerLink]="['/Search']">
          <i class="fas fa-fw fa-tags"></i>
          <span class="nav-label">{{ 'general.search' | translate }}</span>
        </a>
      </li> -->

      <li *ngIf="permissions['HasIssues']" [ngClass]="{active: activeRoute('Issues')}">
        <a href="#">
          <i class="fas fa-fw fa-car-crash"></i>
          <span class="nav-label">{{ 'general.issues' | translate }}</span>
          <span class="fa arrow"></span>
        </a>
        <ul class="nav nav-second-level collapse" [ngClass]="{in: activeRoute('Issues')}">

          <li [ngClass]="{active: activeRoute('Issues/Grouped')}">
            <a [routerLink]="['/Issues/Grouped/']">
              <i class="fas fa-fw fa-list-alt"></i>
              <span class="nav-label">{{ 'menu.overview' | translate }}</span>
            </a>
          </li>
          <li [ngClass]="{active: activeRoute('Issues/Overview')}">
            <a [routerLink]="['/Issues/Overview/']">
              <i class="fas fa-fw fa-list-alt"></i>
              <span class="nav-label">{{ 'menu.issues' | translate }}</span>
            </a>
          </li>
          <li [ngClass]="{active: activeRoute('Issues/Charts')}">
            <a [routerLink]="['/Issues/Charts/']">
              <i class="fas fa-fw fa-chart-area"></i>
              <span class="nav-label">{{ 'menu.charts' | translate }}</span>
            </a>
          </li>

          <!-- <li [ngClass]="{active: activeRoute('Issues/Map')}">
            <a [routerLink]="['/Issues/Map/']">
              <i class="fas fa-fw fa-map"></i>
              <span class="nav-label">{{ 'menu.map' | translate }}</span>
            </a>
          </li> -->
        </ul>
      </li>
      <li [ngClass]="{active: activeRoute('IssueDetails')}" *ngIf="activeRoute('IssueDetails')">
        <a href="#">
          <i class="fas fa-fw fa-car-crash"></i>
          <span class="nav-label">{{ 'menu.issuedetails' | translate }}</span>
          <span class="fa arrow"></span>
        </a>
        <ul class="nav nav-second-level collapse" [ngClass]="{in: activeRoute('IssueDetails')}">
          <li [ngClass]="{active: activeRoute('IssueDetails/Index')}">
            <a [routerLink]="['/IssueDetails/Index/', id]">
              <i class="fas fa-fw fa-th-list"></i>
              <span class="nav-label">{{ 'menu.details' | translate }}</span>
            </a>
          </li>
          <li [ngClass]="{active: activeRoute('IssueDetails/Log')}">
            <a [routerLink]="['/IssueDetails/Log/', id]">
              <i class="fas fa-fw fa-file-alt"></i>
              <span class="nav-label">{{ 'menu.log' | translate }}</span>
            </a>
          </li>
        </ul>
      </li>
      <li *ngIf="permissions['HasCustomersViewOwn'] || permissions['HasCustomersViewAll']"
        [ngClass]="{active: activeRoute('Customers')}">
        <a href="#">
          <i class="fas fa-fw fa-user-tie"></i>
          <span class="nav-label">{{ 'general.customers' | translate }}</span>
          <span class="fa arrow"></span>
        </a>
        <ul class="nav nav-second-level collapse" [ngClass]="{in: activeRoute('Customers')}">
          <li [ngClass]="{active: activeRoute('Customers/Overview')}">
            <a [routerLink]="['/Customers/Overview/']">
              <i class="fas fa-fw fa-list-alt"></i>
              <span class="nav-label">{{ 'menu.overview' | translate }}</span>
            </a>
          </li>
        </ul>
      </li>
      <li [ngClass]="{active: activeRoute('CustomerDetails')}" *ngIf="activeRoute('CustomerDetails')">
        <a href="#">
          <i class="fas fa-fw fa-user-tie"></i>
          <span class="nav-label">{{ 'menu.customerdetails' | translate }}</span>
          <span class="fa arrow"></span>
        </a>
        <ul class="nav nav-second-level collapse" [ngClass]="{in: activeRoute('CustomerDetails')}">
          <li [ngClass]="{active: activeRoute('CustomerDetails/Index')}">
            <a [routerLink]="['/CustomerDetails/Index/', id]">
              <i class="fas fa-fw fa-th-list"></i>
              <span class="nav-label">{{ 'menu.details' | translate }}</span>
            </a>
          </li>
          <li *ngIf="permissions['HasCalculationSettings']"
            [ngClass]="{active: activeRoute('CustomerDetails/CalculationSettings')}">
            <a [routerLink]="['/CustomerDetails/CalculationSettings/', id]">
              <i class="fas fa-fw fa-cog"></i>
              <span class="nav-label">{{ 'menu.calculationsettings' | translate }}</span>
            </a>
          </li>
          <li [ngClass]="{active: activeRoute('CustomerDetails/Devices')}">
            <a [routerLink]="['/CustomerDetails/Devices/', id]">
              <i class="fas fa-fw fa-memory"></i>
              <span class="nav-label">{{ 'menu.devices' | translate }}</span>
            </a>
          </li>
          <li [ngClass]="{active: activeRoute('CustomerDetails/Assets')}" *ngIf="permissions['HasAssets']">
            <a [routerLink]="['/CustomerDetails/Assets/', id]">
              <i class="fas fa-fw fa-car"></i>
              <span class="nav-label">{{ 'general.assets' | translate }}</span>
            </a>
          </li>
          <li [ngClass]="{active: activeRoute('CustomerDetails/Issues')}" *ngIf="permissions['HasIssues']">
            <a [routerLink]="['/CustomerDetails/Issues/', id]">
              <i class="fas fa-fw fa-car-crash"></i>
              <span class="nav-label">{{ 'menu.issues' | translate }}</span>
            </a>
          </li>
        </ul>
      </li>
      <li *ngIf="permissions['HasAssets']" [ngClass]="{active: activeRoute('Assets')}">
        <a href="#">
          <i class="fas fa-fw fa-car"></i>
          <span class="nav-label">{{ 'general.assets' | translate }}</span>
          <span class="fa arrow"></span>
        </a>
        <ul class="nav nav-second-level collapse" [ngClass]="{in: activeRoute('Assets')}">
          <li [ngClass]="{active: activeRoute('Assets/Overview')}">
            <a [routerLink]="['/Assets/Overview/']">
              <i class="fas fa-fw fa-list-alt"></i>
              <span class="nav-label">{{ 'menu.overview' | translate }}</span>
            </a>
          </li>
          <li [ngClass]="{active: activeRoute('Assets/Map')}">
            <a [routerLink]="['/Assets/Map/']">
              <i class="fas fa-fw fa-map"></i>
              <span class="nav-label">{{ 'menu.map' | translate }}</span>
            </a>
          </li>
          <li [ngClass]="{active: activeRoute('Assets/Add')}">
            <a [routerLink]="['/Assets/Add/']">
              <i class="fas fa-fw fa-plus"></i>
              <span class="nav-label">{{ 'menu.add' | translate }}</span>
            </a>
          </li>
        </ul>
      </li>
      <li [ngClass]="{active: activeRoute('AssetDetails')}" *ngIf="activeRoute('AssetDetails')">
        <a href="#">
          <i class="fas fa-fw fa-car"></i>
          <span class="nav-label">{{ 'menu.assetdetails' | translate }}</span>
          <span class="fa arrow"></span>
        </a>
        <ul class="nav nav-second-level collapse" [ngClass]="{in: activeRoute('AssetDetails')}">
          <li [ngClass]="{active: activeRoute('AssetDetails/Index')}">
            <a [routerLink]="['/AssetDetails/Index/', id]">
              <i class="fas fa-fw fa-th-list"></i>
              <span class="nav-label">{{ 'menu.details' | translate }}</span>
            </a>
          </li>
          <li [ngClass]="{active: activeRoute('AssetDetails/Device')}">
            <a [routerLink]="['/AssetDetails/Device/', id]">
              <i class="fas fa-fw fa-memory"></i>
              <span class="nav-label">{{ 'general.device' | translate }}</span>
            </a>
          </li>
          <li [ngClass]="{active: activeRoute('AssetDetails/Sensors')}">
            <a [routerLink]="['/AssetDetails/Sensors/', id]">
              <i class="fa fa-fw fa-rss"></i>
              <span class="nav-label">{{ 'general.sensors' | translate }}</span>
            </a>
          </li>
          <li [ngClass]="{active: activeRoute('AssetDetails/Trailers')}">
            <a [routerLink]="['/AssetDetails/Trailers/', id]">
              <i class="fas fa-fw fa-truck-loading"></i>
              <span class="nav-label">{{ 'general.trailers' | translate }}</span>
            </a>
          </li>
          <li *ngIf="permissions['HasDrivers']" [ngClass]="{active: activeRoute('AssetDetails/Drivers')}">
            <a [routerLink]="['/AssetDetails/Drivers/', id]">
              <i class="fas fa-fw fa-address-card"></i>
              <span class="nav-label">{{ 'general.drivers' | translate }}</span>
            </a>
          </li>
          <li [ngClass]="{active: activeRoute('AssetDetails/Trips')}">
            <a [routerLink]="['/AssetDetails/Trips/', id]">
              <i class="fas fa-fw fa-cloud"></i>
              <span class="nav-label">{{ 'menu.trips' | translate }}</span>
            </a>
          </li>
          <li [ngClass]="{active: activeRoute('AssetDetails/Messages')}">
            <a [routerLink]="['/AssetDetails/Messages/', id]">
              <i class="fas fa-fw fa-list-ul"></i>
              <span class="nav-label">{{ 'general.messages' | translate }}</span>
            </a>
          </li>
          <li [ngClass]="{active: activeRoute('AssetDetails/Log')}">
            <a [routerLink]="['/AssetDetails/Log/', id]">
              <i class="fas fa-fw fa-file-alt"></i>
              <span class="nav-label">{{ 'menu.log' | translate }}</span>
            </a>
          </li>
        </ul>
      </li>
      <li [ngClass]="{active: activeRoute('Trailers')}" *ngIf="permissions['HasAssets'] && permissions['HasWasl']">
        <a href="#">
          <i class="fas fa-fw fa-truck-loading"></i>
          <span class="nav-label">{{ 'general.trailers' | translate }}</span>
          <span class="fa arrow"></span>
        </a>
        <ul class="nav nav-second-level collapse" [ngClass]="{in: activeRoute('Trailers')}">
          <li [ngClass]="{active: activeRoute('Trailers/Overview')}">
            <a [routerLink]="['/Trailers/Overview/']">
              <i class="fas fa-fw fa-list-alt"></i>
              <span class="nav-label">{{ 'menu.overview' | translate }}</span>
            </a>
          </li>
          <li [ngClass]="{active: activeRoute('Trailers/Add')}">
            <a [routerLink]="['/Trailers/Add/']">
              <i class="fas fa-fw fa-plus"></i>
              <span class="nav-label">{{ 'menu.add' | translate }}</span>
            </a>
          </li>
        </ul>
      </li>
      <li [ngClass]="{active: activeRoute('TrailerDetails')}" *ngIf="activeRoute('TrailerDetails')">
        <a href="#">
          <i class="fas fa-fw fa-truck-loading"></i>
          <span class="nav-label">{{ 'general.trailerdetails' | translate }}</span>
          <span class="fa arrow"></span>
        </a>
        <ul class="nav nav-second-level collapse" [ngClass]="{in: activeRoute('TrailerDetails')}">
          <li [ngClass]="{active: activeRoute('TrailerDetails/Index')}">
            <a [routerLink]="['/TrailerDetails/Index/', id]">
              <i class="fas fa-fw fa-th-list"></i>
              <span class="nav-label">{{ 'menu.details' | translate }}</span>
            </a>
          </li>
          <li [ngClass]="{active: activeRoute('TrailerDetails/Assets')}">
            <a [routerLink]="['/TrailerDetails/Assets/', id]">
              <i class="fas fa-fw fa-car"></i>
              <span class="nav-label">{{ 'general.assets' | translate }}</span>
            </a>
          </li>
          <li [ngClass]="{active: activeRoute('TrailerDetails/Log')}">
            <a [routerLink]="['/TrailerDetails/Log/', id]">
              <i class="fas fa-fw fa-file-alt"></i>
              <span class="nav-label">{{ 'menu.log' | translate }}</span>
            </a>
          </li>
        </ul>
      </li>
      <li [ngClass]="{active: activeRoute('AssetTypes')}" *ngIf="permissions['HasAssets'] && permissions['HasWasl']">
        <a href="#">
          <i class="fas fa-fw fa-object-group"></i>
          <span class="nav-label">{{ 'general.assetTypes' | translate }}</span>
          <span class="fa arrow"></span>
        </a>
        <ul class="nav nav-second-level collapse" [ngClass]="{in: activeRoute('AssetTypes')}">
          <li [ngClass]="{active: activeRoute('AssetTypes/Overview')}">
            <a [routerLink]="['/AssetTypes/Overview/']">
              <i class="fas fa-fw fa-list-alt"></i>
              <span class="nav-label">{{ 'menu.overview' | translate }}</span>
            </a>
          </li>
          <li [ngClass]="{active: activeRoute('AssetTypes/Add')}">
            <a [routerLink]="['/AssetTypes/Add/']">
              <i class="fas fa-fw fa-plus"></i>
              <span class="nav-label">{{ 'menu.add' | translate }}</span>
            </a>
          </li>
        </ul>
      </li>
      <li [ngClass]="{active: activeRoute('AssetTypeDetails')}" *ngIf="activeRoute('AssetTypeDetails')">
        <a href="#">
          <i class="fas fa-fw fa-object-group"></i>
          <span class="nav-label">{{ 'general.assetTypeDetails' | translate }}</span>
          <span class="fa arrow"></span>
        </a>
        <ul class="nav nav-second-level collapse" [ngClass]="{in: activeRoute('AssetTypeDetails')}">
          <li [ngClass]="{active: activeRoute('AssetTypeDetails/Index')}">
            <a [routerLink]="['/AssetTypeDetails/Index/', id]">
              <i class="fas fa-fw fa-th-list"></i>
              <span class="nav-label">{{ 'menu.details' | translate }}</span>
            </a>
          </li>
          <li [ngClass]="{active: activeRoute('AssetTypeDetails/Assets')}">
            <a [routerLink]="['/AssetTypeDetails/Assets/', id]">
              <i class="fas fa-fw fa-car"></i>
              <span class="nav-label">{{ 'general.assets' | translate }}</span>
            </a>
          </li>
          <li [ngClass]="{active: activeRoute('AssetTypeDetails/Calibration')}">
            <a [routerLink]="['/AssetTypeDetails/Calibration/', id]">
              <i class="fa fa-fw fa-calculator"></i>
              <span class="nav-label">{{ 'general.calibration' | translate }}</span>
            </a>
          </li>
          <li [ngClass]="{active: activeRoute('AssetTypeDetails/Log')}">
            <a [routerLink]="['/AssetTypeDetails/Log/', id]">
              <i class="fas fa-fw fa-file-alt"></i>
              <span class="nav-label">{{ 'menu.log' | translate }}</span>
            </a>
          </li>
        </ul>
      </li>
      <li *ngIf="permissions['HasDrivers']" [ngClass]="{active: activeRoute('Drivers')}">
        <a href="#">
          <i class="fas fa-fw fa-address-card"></i>
          <span class="nav-label">{{ 'general.drivers' | translate }}</span>
          <span class="fa arrow"></span>
        </a>
        <ul class="nav nav-second-level collapse" [ngClass]="{in: activeRoute('Drivers')}">
          <li [ngClass]="{active: activeRoute('Drivers/Overview')}">
            <a [routerLink]="['/Drivers/Overview/']">
              <i class="fas fa-fw fa-list-alt"></i>
              <span class="nav-label">{{ 'menu.overview' | translate }}</span>
            </a>
          </li>
          <li [ngClass]="{active: activeRoute('Drivers/Add')}">
            <a [routerLink]="['/Drivers/Add/']">
              <i class="fas fa-fw fa-plus"></i>
              <span class="nav-label">{{ 'menu.add' | translate }}</span>
            </a>
          </li>
        </ul>
      </li>
      <li [ngClass]="{active: activeRoute('DriverDetails')}" *ngIf="activeRoute('DriverDetails')">
        <a href="#">
          <i class="fas fa-fw fa-address-card"></i>
          <span class="nav-label">{{ 'menu.driverdetails' | translate }}</span>
          <span class="fa arrow"></span>
        </a>
        <ul class="nav nav-second-level collapse" [ngClass]="{in: activeRoute('DriverDetails')}">
          <li [ngClass]="{active: activeRoute('DriverDetails/Index')}">
            <a [routerLink]="['/DriverDetails/Index/', id]">
              <i class="fas fa-fw fa-th-list"></i>
              <span class="nav-label">{{ 'menu.details' | translate }}</span>
            </a>
          </li>
          <li [ngClass]="{active: activeRoute('DriverDetails/Assets')}">
            <a [routerLink]="['/DriverDetails/Assets/', id]">
              <i class="fas fa-fw fa-car"></i>
              <span class="nav-label">{{ 'general.assets' | translate }}</span>
            </a>
          </li>
          <li [ngClass]="{active: activeRoute('DriverDetails/Log')}">
            <a [routerLink]="['/DriverDetails/Log/', id]">
              <i class="fas fa-fw fa-file-alt"></i>
              <span class="nav-label">{{ 'menu.log' | translate }}</span>
            </a>
          </li>
        </ul>
      </li>
      <li *ngIf="permissions['HasDevices']" [ngClass]="{active: activeRoute('Devices')}">
        <a href="#">
          <i class="fas fa-fw fa-memory"></i>
          <span class="nav-label">{{ 'general.devices' | translate }}</span>
          <span class="fa arrow"></span>
        </a>
        <ul class="nav nav-second-level collapse" [ngClass]="{in: activeRoute('Devices')}">
          <li [ngClass]="{active: activeRoute('Devices/Overview')}">
            <a [routerLink]="['/Devices/Overview/']">
              <i class="fas fa-fw fa-list-alt"></i>
              <span class="nav-label">{{ 'menu.overview' | translate }}</span>
            </a>
          </li>
          <li *ngIf="permissions['HasIssues']" [ngClass]="{active: activeRoute('Devices/Issues')}">
            <a [routerLink]="['/Devices/Issues']">
              <i class="fas fa-fw fa-memory"></i>
              <span class="nav-label">{{ 'menu.issuesoverview' | translate }}</span>
            </a>
          </li>
          <li [ngClass]="{active: activeRoute('Devices/Map2')}">
            <a [routerLink]="['/Devices/Map2/']">
              <i class="fas fa-fw fa-map"></i>
              <span class="nav-label">{{ 'menu.map' | translate }}</span>
            </a>
          </li>
          <li [ngClass]="{active: activeRoute('Devices/Add')}" *ngIf="permissions['HasWasl']">
            <a [routerLink]="['/Devices/Add/']">
              <i class="fas fa-fw fa-plus"></i>
              <span class="nav-label">{{ 'menu.add' | translate }}</span>
            </a>
          </li>
        </ul>
      </li>
      <li [ngClass]="{active: activeRoute('DeviceDetails')}" *ngIf="activeRoute('DeviceDetails')">
        <a href="#">
          <i class="fas fa-fw fa-memory"></i>
          <span class="nav-label">{{ 'menu.devicedetails' | translate }}</span>
          <span class="fa arrow"></span>
        </a>
        <ul class="nav nav-second-level collapse" [ngClass]="{in: activeRoute('DeviceDetails')}">
          <li [ngClass]="{active: activeRoute('DeviceDetails/Index')}">
            <a [routerLink]="['/DeviceDetails/Index/', id]">
              <i class="fas fa-fw fa-th-list"></i>
              <span class="nav-label">{{ 'menu.details' | translate }}</span>
            </a>
          </li>
          <li *ngIf="permissions['HasAssets']" [ngClass]="{active: activeRoute('DeviceDetails/Asset')}">
            <a [routerLink]="['/DeviceDetails/Asset/', id]">
              <i class="fas fa-fw fa-car"></i>
              <span class="nav-label">{{ 'general.asset' | translate }}</span>
            </a>
          </li>
          <li [ngClass]="{active: activeRoute('DeviceDetails/Sensors')}">
            <a [routerLink]="['/DeviceDetails/Sensors/', id]">
              <i class="fas fa-fw fa-rss"></i>
              <span class="nav-label">{{ 'menu.sensors' | translate }}</span>
            </a>
          </li>
          <li *ngIf="permissions['HasCalculationSettings']"
            [ngClass]="{active: activeRoute('DeviceDetails/CalculationSettings')}">
            <a [routerLink]="['/DeviceDetails/CalculationSettings/', id]">
              <i class="fas fa-fw fa-cog"></i>
              <span class="nav-label">{{ 'menu.calculationsettings' | translate }}</span>
            </a>
          </li>
          <li [ngClass]="{active: activeRoute('DeviceDetails/Settings')}">
            <a [routerLink]="['/DeviceDetails/Settings/', id]">
              <i class="fas fa-fw fa-cog"></i>
              <span class="nav-label">{{ 'general.settings' | translate }}</span>
            </a>
          </li>
          <!-- <li [ngClass]="{active: activeRoute('DeviceDetails/Locations')}">
            <a [routerLink]="['/DeviceDetails/Locations/', id]">
              <i class="fas fa-fw fa-location-arrow"></i>
              <span class="nav-label">{{ 'menu.devicelocations' | translate }}</span>
            </a>
          </li> -->
          <!-- <li [ngClass]="{active: activeRoute('DeviceDetails/Fuel')}">
            <a [routerLink]="['/DeviceDetails/Fuel/', id]">
              <i class="fas fa-fw fa-tint"></i>
              <span class="nav-label">{{ 'menu.fuel' | translate }}</span>
            </a>
          </li>
          <li [ngClass]="{active: activeRoute('DeviceDetails/Trips')}">
            <a [routerLink]="['/DeviceDetails/Trips/', id]">
              <i class="fas fa-fw fa-cloud"></i>
              <span class="nav-label">{{ 'menu.trips' | translate }}</span>
            </a>
          </li> -->
          <li *ngIf="permissions['HasIssues']" [ngClass]="{active: activeRoute('DeviceDetails/Issues')}">
            <a [routerLink]="['/DeviceDetails/Issues/', id]">
              <i class="fas fa-fw fa-car-crash"></i>
              <span class="nav-label">{{ 'menu.issues' | translate }}</span>
            </a>
          </li>
          <li [ngClass]="{active: activeRoute('DeviceDetails/Log')}">
            <a [routerLink]="['/DeviceDetails/Log/', id]">
              <i class="fas fa-fw fa-file-alt"></i>
              <span class="nav-label">{{ 'menu.log' | translate }}</span>
            </a>
          </li>
        </ul>
      </li>
      <li *ngIf="permissions['HasPlatforms']" [ngClass]="{active: activeRoute('Platforms')}">
        <a href="#">
          <i class="fas fa-fw fa-clipboard-list"></i>
          <span class="nav-label">{{ 'general.platforms' | translate }}</span>
          <span class="fa arrow"></span>
        </a>
        <ul class="nav nav-second-level collapse" [ngClass]="{in: activeRoute('Platforms')}">
          <li [ngClass]="{active: activeRoute('Platforms/Overview')}">
            <a [routerLink]="['/Platforms/Overview/']">
              <i class="fas fa-fw fa-list-alt"></i>
              <span class="nav-label">{{ 'menu.overview' | translate }}</span>
            </a>
          </li>
        </ul>
      </li>
      <li [ngClass]="{active: activeRoute('PlatformDetails')}" *ngIf="activeRoute('PlatformDetails')">
        <a href="#">
          <i class="fas fa-fw fa-clipboard-list"></i>
          <span class="nav-label">{{ 'general.platformdetails' | translate }}</span>
          <span class="fa arrow"></span>
        </a>
        <ul class="nav nav-second-level collapse" [ngClass]="{in: activeRoute('PlatformDetails')}">
          <li [ngClass]="{active: activeRoute('PlatformDetails/Index')}">
            <a [routerLink]="['/PlatformDetails/Index/', id]">
              <i class="fas fa-fw fa-th-list"></i>
              <span class="nav-label">{{ 'menu.details' | translate }}</span>
            </a>
          </li>
          <li *ngIf="permissions['HasCalculationSettings']"
            [ngClass]="{active: activeRoute('PlatformDetails/CalculationSettings')}">
            <a [routerLink]="['/PlatformDetails/CalculationSettings/', id]">
              <i class="fas fa-fw fa-cog"></i>
              <span class="nav-label">{{ 'menu.calculationsettings' | translate }}</span>
            </a>
          </li>
          <li [ngClass]="{active: activeRoute('PlatformDetails/Devices')}">
            <a [routerLink]="['/PlatformDetails/Devices/', id]">
              <i class="fas fa-fw fa-memory"></i>
              <span class="nav-label">{{ 'menu.devices' | translate }}</span>
            </a>
          </li>
          <li [ngClass]="{active: activeRoute('PlatformDetails/Issues')}">
            <a [routerLink]="['/PlatformDetails/Issues/', id]">
              <i class="fas fa-fw fa-car-crash"></i>
              <span class="nav-label">{{ 'menu.issues' | translate }}</span>
            </a>
          </li>
        </ul>
      </li>
      <li *ngIf="permissions['HasDeviceTypes']" [ngClass]="{active: activeRoute('DeviceTypes')}">
        <a href="#">
          <i class="fas fa-fw fa-satellite-dish"></i>
          <span class="nav-label">{{ 'general.devicetypes' | translate }}</span>
          <span class="fa arrow"></span>
        </a>
        <ul class="nav nav-second-level collapse" [ngClass]="{in: activeRoute('DeviceTypes')}">
          <li [ngClass]="{active: activeRoute('DeviceTypes/Overview')}">
            <a [routerLink]="['/DeviceTypes/Overview/']">
              <i class="fas fa-fw fa-list-alt"></i>
              <span class="nav-label">{{ 'menu.overview' | translate }}</span>
            </a>
          </li>
        </ul>
      </li>
      <li [ngClass]="{active: activeRoute('DeviceTypeDetails')}" *ngIf="activeRoute('DeviceTypeDetails')">
        <a href="#">
          <i class="fas fa-fw fa-satellite-dish"></i>
          <span class="nav-label">{{ 'menu.devicetypedetails' | translate }}</span>
          <span class="fa arrow"></span>
        </a>
        <ul class="nav nav-second-level collapse" [ngClass]="{in: activeRoute('DeviceTypeDetails')}">
          <li [ngClass]="{active: activeRoute('DeviceTypeDetails/Index')}">
            <a [routerLink]="['/DeviceTypeDetails/Index/', id]">
              <i class="fas fa-fw fa-th-list"></i>
              <span class="nav-label">{{ 'menu.details' | translate }}</span>
            </a>
          </li>
          <li *ngIf="permissions['HasCalculationSettings']"
            [ngClass]="{active: activeRoute('DeviceTypeDetails/CalculationSettings')}">
            <a [routerLink]="['/DeviceTypeDetails/CalculationSettings/', id]">
              <i class="fas fa-fw fa-cog"></i>
              <span class="nav-label">{{ 'menu.calculationsettings' | translate }}</span>
            </a>
          </li>
          <li [ngClass]="{active: activeRoute('DeviceTypeDetails/Devices')}">
            <a [routerLink]="['/DeviceTypeDetails/Devices/', id]">
              <i class="fas fa-fw fa-memory"></i>
              <span class="nav-label">{{ 'menu.devices' | translate }}</span>
            </a>
          </li>
          <li [ngClass]="{active: activeRoute('DeviceTypeDetails/Issues')}">
            <a [routerLink]="['/DeviceTypeDetails/Issues/', id]">
              <i class="fas fa-fw fa-car-crash"></i>
              <span class="nav-label">{{ 'menu.issues' | translate }}</span>
            </a>
          </li>
        </ul>
      </li>
      <li *ngIf="permissions['HasReporting']" [ngClass]="{active: activeRoute('Reporting')}">
        <a [routerLink]="['/Reporting']">
          <i class="fas fa-fw fa-file-pdf"></i>
          <span class="nav-label">{{ 'general.reporting' | translate }}</span>
        </a>
      </li>
      <li *ngIf="permissions['HasJobs'] || isImpersonated" [ngClass]="{active: activeRoute('Jobs')}">
        <a href="#" [style.color]="isImpersonated ? '#7F0000' : ''">
          <i class="fas fa-fw fa-sync"></i>
          <span class="nav-label">{{ 'menu.jobs' | translate }}</span>
          <span class="fa arrow"></span>
        </a>
        <ul class="nav nav-second-level collapse" [ngClass]="{in: activeRoute('Jobs')}">
          <li [ngClass]="{active: activeRoute('Jobs/Active')}">
            <a [routerLink]="['/Jobs/Active/']" [style.color]="isImpersonated ? '#7F0000' : ''">
              <i class="fas fa-fw fa-list-alt"></i>
              <span class="nav-label">{{ 'menu.active' | translate }}</span>
            </a>
          </li>
          <li [ngClass]="{active: activeRoute('Jobs/History')}">
            <a [routerLink]="['/Jobs/History/']" [style.color]="isImpersonated ? '#7F0000' : ''">
              <i class="fas fa-fw fa-list-alt"></i>
              <span class="nav-label">{{ 'menu.history' | translate }}</span>
            </a>
          </li>
        </ul>
      </li>
      <li *ngIf="permissions['HasUserAdministration']" [ngClass]="{active: activeRoute('Users')}">
        <a href="#">
          <i class="fas fa-fw fa-users"></i>
          <span class="nav-label">{{ 'general.users' | translate }}</span>
          <span class="fa arrow"></span>
        </a>
        <ul class="nav nav-second-level collapse" [ngClass]="{in: activeRoute('Users')}">
          <li [ngClass]="{active: activeRoute('Users/Overview')}">
            <a [routerLink]="['/Users/Overview/']">
              <i class="fas fa-fw fa-list-alt"></i>
              <span class="nav-label">{{ 'menu.overview' | translate }}</span>
            </a>
          </li>
          <li [ngClass]="{active: activeRoute('Users/Add')}">
            <a [routerLink]="['/Users/Add/']">
              <i class="fas fa-fw fa-plus"></i>
              <span class="nav-label">{{ 'menu.addUser' | translate }}</span>
            </a>
          </li>
        </ul>
      </li>
      <li [ngClass]="{active: activeRoute('UserDetails')}" *ngIf="activeRoute('UserDetails')">
        <a href="#">
          <i class="fas fa-fw fa-car-crash"></i>
          <span class="nav-label">{{ 'menu.userdetails' | translate }}</span>
          <span class="fa arrow"></span>
        </a>
        <ul class="nav nav-second-level collapse" [ngClass]="{in: activeRoute('UserDetails')}">
          <li [ngClass]="{active: activeRoute('UserDetails/Index')}">
            <a [routerLink]="['/UserDetails/Index/', id]">
              <i class="fas fa-fw fa-th-list"></i>
              <span class="nav-label">{{ 'menu.details' | translate }}</span>
            </a>
          </li>
          <li [ngClass]="{active: activeRoute('UserDetails/Log')}">
            <a [routerLink]="['/UserDetails/Log/', id]">
              <i class="fas fa-fw fa-file-alt"></i>
              <span class="nav-label">{{ 'menu.log' | translate }}</span>
            </a>
          </li>
        </ul>
      </li>
      <li *ngIf="permissions['IsAdministrator']" [ngClass]="{active: activeRoute('UserRole')}">
        <a href="#">
          <i class="fas fa-fw fa-user-tag"></i>
          <span class="nav-label">{{ 'general.userRoles' | translate }}</span>
          <span class="fa arrow"></span>
        </a>
        <ul class="nav nav-second-level collapse" [ngClass]="{in: activeRoute('UserRole')}">
          <li [ngClass]="{active: activeRoute('UserRoles/Overview')}">
            <a [routerLink]="['/UserRoles/Overview/']">
              <i class="fas fa-fw fa-list-alt"></i>
              <span class="nav-label">{{ 'menu.overview' | translate }}</span>
            </a>
          </li>
        </ul>
      </li>
      <li *ngIf="permissions['HasAccountAdministration']" [ngClass]="{active: activeRoute('Accounts')}">
        <a href="#">
          <i class="fas fa-fw fa-database"></i>
          <span class="nav-label">{{ 'general.accounts' | translate }}</span>
          <span class="fa arrow"></span>
        </a>
        <ul class="nav nav-second-level collapse" [ngClass]="{in: activeRoute('Accounts')}">
          <li [ngClass]="{active: activeRoute('Accounts/Overview')}">
            <a [routerLink]="['/Accounts/Overview/']">
              <i class="fas fa-fw fa-list-alt"></i>
              <span class="nav-label">{{ 'menu.overview' | translate }}</span>
            </a>
          </li>
          <li [ngClass]="{active: activeRoute('Accounts/Add')}">
            <a [routerLink]="['/Accounts/Add/']">
              <i class="fas fa-fw fa-plus"></i>
              <span class="nav-label">{{ 'menu.addAccount' | translate }}</span>
            </a>
          </li>
        </ul>
      </li>
      <li [ngClass]="{active: activeRoute('AccountDetails')}" *ngIf="activeRoute('AccountDetails')">
        <a href="#">
          <i class="fas fa-fw fa-database"></i>
          <span class="nav-label">{{ 'menu.accountdetails' | translate }}</span>
          <span class="fa arrow"></span>
        </a>
        <ul class="nav nav-second-level collapse" [ngClass]="{in: activeRoute('AccountDetails')}">
          <li [ngClass]="{active: activeRoute('AccountDetails/Index')}">
            <a [routerLink]="['/AccountDetails/Index/', id]">
              <i class="fas fa-fw fa-th-list"></i>
              <span class="nav-label">{{ 'menu.details' | translate }}</span>
            </a>
          </li>
          <li *ngIf="permissions['HasCalculationSettings']"
            [ngClass]="{active: activeRoute('AccountDetails/CalculationSettings')}">
            <a [routerLink]="['/AccountDetails/CalculationSettings/', id]">
              <i class="fas fa-fw fa-cog"></i>
              <span class="nav-label">{{ 'menu.calculationsettings' | translate }}</span>
            </a>
          </li>
          <li [ngClass]="{active: activeRoute('AccountDetails/Log')}">
            <a [routerLink]="['/AccountDetails/Log/', id]">
              <i class="fas fa-fw fa-file-alt"></i>
              <span class="nav-label">{{ 'menu.log' | translate }}</span>
            </a>
          </li>
        </ul>
      </li>
      <li *ngIf="permissions['IsAdministrator'] && permissions['HasScheduleView']"
        [ngClass]="{active: activeRoute('Schedule')}">
        <a href="#">
          <i class="fas fa-fw fa-calendar-alt"></i>
          <span class="nav-label">{{ 'menu.schedule' | translate }}</span>
          <span class="fa arrow"></span>
        </a>
        <ul class="nav nav-second-level collapse" [ngClass]="{in: activeRoute('Schedule')}">
          <li [ngClass]="{active: activeRoute('Schedule/Overview')}">
            <a [routerLink]="['/Schedule/Overview/']">
              <i class="fas fa-fw fa-calendar-alt"></i>
              <span class="nav-label">{{ 'menu.overview' | translate }}</span>
            </a>
          </li>
          <li [ngClass]="{active: activeRoute('Schedule/Calendar')}">
            <a [routerLink]="['/Schedule/Calendar/']">
              <i class="fas fa-fw fa-calendar"></i>
              <span class="nav-label">{{ 'menu.calendar' | translate }}</span>
            </a>
          </li>
        </ul>
      </li>
      <li [ngClass]="{active: activeRoute('Tags')}">
        <a [routerLink]="['/Tags']">
          <i class="fas fa-fw fa-tags"></i>
          <span class="nav-label">{{ 'general.tags' | translate }}</span>
        </a>
      </li>
      <li *ngIf="permissions['HasWasl']" [ngClass]="{active: activeRoute('Wasl')}">
        <a href="#">
          <i class="fas fa-fw fa-user-shield"></i>
          <span class="nav-label">{{ 'general.wasl' | translate }}</span>
          <span class="fa arrow"></span>
        </a>
        <ul class="nav nav-second-level collapse" [ngClass]="{in: activeRoute('Wasl')}">
          <li [ngClass]="{active: activeRoute('Wasl/Company')}">
            <a [routerLink]="['/Wasl/Company']">
              <i class="fas fa-fw fa-list-alt"></i>
              <span class="nav-label">{{ 'menu.companyStatus' | translate }}</span>
            </a>
          </li>
          <li [ngClass]="{active: activeRoute('Wasl/RegistrationStatus')}">
            <a [routerLink]="['/Wasl/RegistrationStatus/']">
              <i class="fas fa-fw fa-list-alt"></i>
              <span class="nav-label">{{ 'menu.registrationStatus' | translate }}</span>
            </a>
          </li>
        </ul>
      </li>
      <li *ngIf="permissions['HasSensorTypes']" [ngClass]="{active: activeRoute('SensorTypes')}">
        <a href="#">
          <i class="fas fa-fw fa-rss"></i>
          <span class="nav-label">{{ 'general.sensorTypes' | translate }}</span>
          <span class="fa arrow"></span>
        </a>
        <ul class="nav nav-second-level collapse" [ngClass]="{in: activeRoute('SensorTypes')}">
          <li [ngClass]="{active: activeRoute('SensorTypes/Overview')}">
            <a [routerLink]="['/SensorTypes/Overview/']">
              <i class="fas fa-fw fa-list-alt"></i>
              <span class="nav-label">{{ 'menu.overview' | translate }}</span>
            </a>
          </li>
          <li [ngClass]="{active: activeRoute('SensorTypes/Add')}">
            <a [routerLink]="['/SensorTypes/Add/']">
              <i class="fas fa-fw fa-plus"></i>
              <span class="nav-label">{{ 'menu.add' | translate }}</span>
            </a>
          </li>
        </ul>
      </li>
      <li [ngClass]="{active: activeRoute('SensorTypeDetails')}" *ngIf="activeRoute('SensorTypeDetails')">
        <a href="#">
          <i class="fas fa-fw fa-clipboard-list"></i>
          <span class="nav-label">{{ 'general.sensorTypeDetails' | translate }}</span>
          <span class="fa arrow"></span>
        </a>
        <ul class="nav nav-second-level collapse" [ngClass]="{in: activeRoute('SensorTypeDetails')}">
          <li [ngClass]="{active: activeRoute('SensorTypeDetails/Index')}">
            <a [routerLink]="['/SensorTypeDetails/Index/', id]">
              <i class="fas fa-fw fa-th-list"></i>
              <span class="nav-label">{{ 'menu.details' | translate }}</span>
            </a>
          </li>
        </ul>
      </li>
      <li *ngIf="permissions['HasCalculationSettings'] || permissions['IsAdministrator']"
        [ngClass]="{active: activeRoute('Settings')}">
        <a href="#">
          <i class="fas fa-fw fa-cog"></i>
          <span class="nav-label">{{ 'general.settings' | translate }}</span>
          <span class="fa arrow"></span>
        </a>
        <ul class="nav nav-second-level collapse" [ngClass]="{in: activeRoute('Settings')}">
          <!-- <li [ngClass]="{active: activeRoute('Settings/Profile')}">
            <a [routerLink]="['/Settings/Profile']">
              <span class="nav-label">{{ 'general.profile' | translate }}</span>
            </a>
          </li> -->
          <li *ngIf="permissions['IsAdministrator'] || isImpersonated"
            [ngClass]="{active: activeRoute('Settings/Language')}">
            <a [routerLink]="['/Settings/Language']" [style.color]="isImpersonated ? '#7F0000' : ''">
              <span class="nav-label">{{ 'menu.settingslanguage' | translate }}</span>
            </a>
          </li>
          <li *ngIf="permissions['IsAdministrator'] && permissions['HasWorkflow']"
            [ngClass]="{active: activeRoute('Settings/General')}">
            <a [routerLink]="['/Settings/General']">
              <span class="nav-label">{{ 'general.settings' | translate }}</span>
            </a>
          </li>
          <li *ngIf="permissions['HasCalculationSettings']"
            [ngClass]="{active: activeRoute('Settings/CalculationSettings')}">
            <a [routerLink]="['/Settings/CalculationSettings']">
              <span class="nav-label">{{ 'menu.calculationsettings' | translate }}</span>
            </a>
          </li>
          <li *ngIf="(permissions['IsAdministrator'] && permissions['HasCalculationSettings']) || isImpersonated"
            [ngClass]="{active: activeRoute('Settings/OverviewCalculationSettings')}">
            <a [routerLink]="['/Settings/OverviewCalculationSettings']" [style.color]="isImpersonated ? '#7F0000' : ''">
              <span class="nav-label">{{ 'menu.calculationSettingsOverview' | translate }}</span>
            </a>
          </li>
          <li *ngIf="permissions['HasCalculationSettings']"
            [ngClass]="{active: activeRoute('Settings/SensorSettings')}">
            <a [routerLink]="['/Settings/SensorSettings']">
              <span class="nav-label">{{ 'menu.sensorSettings' | translate }}</span>
            </a>
          </li>
          <li *ngIf="permissions['HasThemeAdmin']" [ngClass]="{active: activeRoute('Settings/Theme')}">
            <a [routerLink]="['/Settings/Theme']">
              <span class="nav-label">{{ 'menu.settingstheme' | translate }}</span>
            </a>
          </li>
          <li *ngIf="permissions['IsAdministrator']" [ngClass]="{active: activeRoute('Settings/ErrorLog')}">
            <a [routerLink]="['/Settings/Errorlog']">
              <span class="nav-label">{{ 'menu.errorlog' | translate }}</span>
            </a>
          </li>
          <li *ngIf="permissions['IsAdministrator'] && permissions['HasWorkflow'] && permissions['HasScheduleView']"
            [ngClass]="{active: activeRoute('Settings/Workflow')}">
            <a [routerLink]="['/Settings/Workflow']">
              <span class="nav-label">{{ 'menu.settingsworkflow' | translate }}</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</nav>