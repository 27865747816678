<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
        <ol class="breadcrumb">
            <li>
                <a href="/#/"><i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/">{{'general.settings' | translate }}</a>
            </li>
            <li class="active">
                <strong>{{'menu.calculationsettings' | translate }}</strong>
            </li>

            <span class="pull-right"><a href="https://360locate.com/docs/fleet-health/calculation-settings/" target="_blank">
                <i class="fa fa-question"></i></a></span>
        </ol>
    </div>
</div>

<fh-general-calculationsettings></fh-general-calculationsettings>
