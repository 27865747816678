<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Devices/Overview">{{'general.devices' | translate }}</a>
            </li>
            <li>
                <a href="/#/DeviceDetails/Index/{{device?.id}}">{{device?.name}}</a>
            </li>
            <li class="active">
                <strong>{{'menu.fuel' | translate }}</strong>
            </li>
        </ol>
    </div>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="">
        <div class="col-lg-3">

            <div class="row7">
                <div class="col-lg-12">
                    <div class="alert alert-danger shadow">
                        <strong>Warning!</strong> This fuel data is fictional.
                    </div>
                </div>
            </div>

            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-tint"></i> {{'menu.trips' | translate }}
                    </h5>
                </div>
                <div class="ibox-content slick" style="position:relative">
                    <table class="table table-hover nowrap dtr-inline no-footer" style="width:100%; margin-bottom:0px ">
                        <thead>
                            <tr>
                                <th style="width: 80px">{{'general.date' | translate }}</th>
                                <th>{{'general.name' | translate }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let trip of theTrips" [class.active]="trip == selectedRow" (click)="selectFuelTrip(trip);">
                                <td style="width: 80px">{{ trip.date | amDateFormat: 'll' }}</td>
                                <td>{{ trip.name }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-lg-9">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-tint"></i> {{'menu.fuel' | translate }}</h5>
                    <span class="pull-right">
                        <i class="fa fa-spinner fa-spin fa-fw"></i>
                    </span>
                </div>
                <div class="ibox-content" style="position: relative; min-height:72px">
                    <div style="height:350px" [chart]="chartFuel">
                    </div>
                </div>
            </div>
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-tint"></i> {{'menu.map' | translate }}</h5>
                </div>
                <div class="ibox-content slick">
                    <div style="height: 250px;" leaflet [leafletOptions]="options" [leafletLayersControl]="layersControl" (leafletMapReady)="onMapReady($event)">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>