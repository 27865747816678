import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Subject } from 'rxjs/Subject';
import { DeviceService } from '../../services/device/device.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Device } from 'app/models/device.model';
import { FhChartService } from '../../services/charts/charts.service';
import { tileLayer, latLng, circle, polygon, Map, marker, icon } from 'leaflet';
import { getMapProviders, createMapOptions } from 'app/common/leafletGlobals';

declare var L;

@Component({
    selector: 'fh-device-fuel',
    templateUrl: 'deviceFuel.template.html',
    providers: [FhChartService]
})

export class DeviceFuelViewComponent implements OnInit, OnDestroy {

    map: Map;
    options;
    layersControl;
    selectedRow: any;
    selected: any;
    theTrips = [];
    timeoutHandler;
    chartFuel: any;
    sensors;
    sub;
    device: Device;
    loading = false;
    loadingSensors = false;
    maps: any;

    constructor(private chartService: FhChartService, private http: HttpClient, private deviceService: DeviceService, private route: ActivatedRoute, private router: Router) {
        this.device = null;
        this.sensors = [];
        this.theTrips = [];
    }

    ngOnInit() {

        this.initMap();

        function generateRandomLatLon() {
            return [(24.577100 + Math.random() * 1).toFixed(3), (55.019531 + Math.random() * 1).toFixed(3)];
        }

        const data1 = [[new Date('2016-10-01 10:40').getTime(), 1044.922, 96, 1, null, null, generateRandomLatLon()],
        [new Date('2016-10-01 10:50').getTime(), 1044.987, 95.59999847, 2, null, null, generateRandomLatLon()],
        [new Date('2016-10-03 16:47').getTime(), 1081.972, 89.59999847, 5, null, null, generateRandomLatLon()],
        [new Date('2016-10-03 20:56').getTime(), 1142.389, 82, 2, null, null, generateRandomLatLon()],
        [new Date('2016-10-04 21:07').getTime(), 1144.046, 79.59999847, 5, 1144.046, null, generateRandomLatLon()],
        [new Date('2016-10-05 00:53').getTime(), 1263.924, 64.80000305, 5, 1263.924, null, generateRandomLatLon()],
        [new Date('2016-10-05 08:26').getTime(), 1380.844, 57.20000076, 5, 1380.844, null, generateRandomLatLon()],
        [new Date('2016-10-05 09:05').getTime(), 1380.865, 57.20000076, 5, null, null, generateRandomLatLon()],
        [new Date('2016-10-05 20:26').getTime(), 1381.629, 96, 5, null, 96, generateRandomLatLon()],
        [new Date('2016-10-05 20:35').getTime(), 1382.262, 95.59999847, 2, null, null, generateRandomLatLon()],
        [new Date('2016-10-05 21:12').getTime(), 1382.787, 95.59999847, 5, 1382.787, null, generateRandomLatLon()],
        [new Date('2016-10-06 00:30').getTime(), 1502.462, 82.80000305, 5, 1502.462, null, generateRandomLatLon()],
        [new Date('2016-10-06 03:00').getTime(), 1503.226, 83.59999847, 2, 1503.226, null, generateRandomLatLon()],
        [new Date('2016-10-06 04:57').getTime(), 1503.336, 83.59999847, 5, null, null, generateRandomLatLon()],
        [new Date('2016-10-06 07:49').getTime(), 1619.320, 74.80000305, 5, null, null, generateRandomLatLon()],
        [new Date('2016-10-07 18:16').getTime(), 1620.759, 72, 2, null, null, generateRandomLatLon()],
        [new Date('2016-10-07 18:34').getTime(), 1620.759, 72, 2, null, null, generateRandomLatLon()],
        [new Date('2016-10-07 21:45').getTime(), 1621.308, 72, 2, null, null, generateRandomLatLon()],
        [new Date('2016-10-08 02:49').getTime(), 1742.521, 59.59999847, 10, null, null, generateRandomLatLon()],
        [new Date('2016-10-08 08:44').getTime(), 1857.882, 50.40000153, 10, null, null, generateRandomLatLon()],
        [new Date('2016-10-08 09:09').getTime(), 1857.945, 80, 10, null, 80, generateRandomLatLon()],
        [new Date('2016-10-08 09:10').getTime(), 1857.945, 50, 10, null, null, generateRandomLatLon()],
        [new Date('2016-10-08 11:23').getTime(), 1857.988, 49.19999695, 5, null, null, generateRandomLatLon()],
        [new Date('2016-10-08 11:24').getTime(), 1857.988, 28.80000305, 4, null, null, generateRandomLatLon()],
        [new Date('2016-10-09 08:10').getTime(), 2040.411, 24.80000305, 6, null, null, generateRandomLatLon()],
        [new Date('2016-10-09 11:25').getTime(), 2067.941, 23.80000305, 5, null, null, generateRandomLatLon()]];

        const data2 = [[new Date('2017-10-01 10:40').getTime(), 0.922, 96, 1],
        [new Date('2017-10-01 10:50').getTime(), 100.987, 95.59999847, 2],
        [new Date('2017-10-03 16:47').getTime(), 200.972, 89.59999847, 5],
        [new Date('2017-10-03 20:56').getTime(), 300.389, 82, 2],
        [new Date('2017-10-04 21:07').getTime(), 400.046, 79.59999847, 5],
        [new Date('2017-10-05 00:53').getTime(), 1263.924, 70.80000305, 5],
        [new Date('2017-10-05 08:26').getTime(), 1380.844, 70.20000076, 5],
        [new Date('2017-10-05 09:05').getTime(), 1380.865, 70.20000076, 5]];

        const data3 = [[new Date('2018-10-01 10:40').getTime(), 100, 96, 1],
        [new Date('2018-10-01 10:50').getTime(), 101.987, 95.59999847, 2],
        [new Date('2018-10-03 16:47').getTime(), 105.972, 89.59999847, 5],
        [new Date('2018-10-03 20:56').getTime(), 110.389, 82, 2],
        [new Date('2018-10-04 21:07').getTime(), 120.046, 79.59999847, 5],
        [new Date('2018-10-05 00:53').getTime(), 150.924, 70.80000305, 5],
        [new Date('2018-10-06 08:26').getTime(), 170.844, 70.20000076, 5],
        [new Date('2018-10-07 09:05').getTime(), 180.865, 70.20000076, 5]];

        this.theTrips = [
            { name: 'test', date: new Date('2016-10-01 10:40').getTime(), data: data1 },
            { name: 'test 2', date: new Date('2017-10-01 10:40').getTime(), data: data2 },
            { name: 'test 3', date: new Date('2018-10-01 10:40').getTime(), data: data3 }
        ];

        this.device = new Device;
        this.loading = true;
        this.device.id = ''

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];
            this.deviceService.getDeviceById(id).subscribe(device => {
                this.device = device;
                this.device.customFields = JSON.parse(this.device.customFields);
                this.loading = false;

                if (this.device == null) {
                    this.router.navigate(['/Devices/Overview'])
                }

                this.selectFuelTrip(this.theTrips[0]);

            });
        });


    }

    ngOnDestroy(): void {
        clearTimeout(this.timeoutHandler);
    }

    selectFuelTrip(theTrip) {
        this.selectedRow = theTrip;
        this.drawGauges(theTrip.data);
    }

    drawGauges(theChartData) {
        // The speed gauge
        // data, km, fuel percentage, deviation, symbol

        const theChartDataDistance = [];
        const theChartDataLevel = [];
        const theChartDataRange = [];
        const theChartDataEvents = [];
        const theChartDataIgnition = [];


        $.each(theChartData, function (index, value) {
            theChartDataDistance.push({ x: value[0], y: value[1], latlon: value[6] });
            theChartDataLevel.push([value[0], value[2]]);
            theChartDataRange.push([value[0], value[2] - value[3], value[2] + value[3]]);
            theChartDataEvents.push([value[0], value[5]]);
            theChartDataIgnition.push([value[0], value[4]]);
        });

        let theData = [];

        theData = [{
            name: 'Distance',
            type: 'area',
            threshold: null,
            fillOpacity: 0.6,
            data: theChartDataDistance
        }, {
            name: 'Ignition',
            type: 'line',
            color: '#5AB867',
            enableMouseTracking: false,
            marker: {
                enabled: true,
                lineWidth: 2,
                symbol: 'square'
            },
            zIndex: 3,
            data: theChartDataIgnition
        }, {
            name: 'Fuel data event',
            type: 'scatter',
            color: '#5A3E67',
            yAxis: 1,
            zIndex: 4,
            marker: {
                enabled: true,
                lineWidth: 2,
                radius: 5,
                symbol: 'square'
            },
            data: theChartDataEvents
        }, {
            name: 'Fuel percentage',
            type: 'line',
            marker: {
                enabled: true,
                lineWidth: 2,
            },
            yAxis: 1,
            data: theChartDataLevel
        }, {
            name: 'Range',
            data: theChartDataRange,
            type: 'arearange',
            lineWidth: 0,
            linkedTo: ':previous',
            fillOpacity: 0.3,
            zIndex: 0,
            color: '#ccc',
            tooltip: false,
            enableMouseTracking: false,
            yAxis: 1,
            marker: {
                enabled: false
            }
        }];

        // Plotlines
        const filteredEvents = theChartDataEvents.filter(x => x[1] != null);

        const plotLines = []
        filteredEvents.forEach(event => {
            plotLines.push({
                color: '#ddd',
                dashStyle: 'dash',
                width: 2,
                value: event[0],
            });
        });

        this.chartFuel = this.chartService.generateFuelChart(theData, plotLines, this.map);
    }

    // Leaflet
    initMap() {
        this.maps = getMapProviders(L);
          const initMaps = [];
        initMaps.push(Object.values(this.maps)[0]);
        this.options = createMapOptions(L, initMaps);
    }

    onMapReady(map: Map) {
        this.map = map;

        const overlayMaps = {};

        L.control.layers(this.maps, overlayMaps, { position: 'topleft' }).addTo(map);

        this.map.invalidateSize();
    }
}
