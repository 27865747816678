<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Drivers/Overview">{{'general.drivers' | translate }}</a>
            </li>
            <li class="active">
                <a href="/#/Drivers/Overview">{{'menu.add' | translate }}</a>
            </li>
        </ol>
    </div>
</div>

<div class="wrapper wrapper-content">
    <div class="row">
        <div class="col-lg-12">
            <fh-error [success]="success" [error]="error"></fh-error>
        </div>
    </div>
    <div class="row">
        <form #userForm="ngForm" #formDir="ngForm">
            <div [ngClass]="{'col-lg-6': permissions['HasWasl'], 'col-md-12': !permissions['HasWasl']}">

                <div class="ibox float-e-margins" style="position:relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-address-card"></i> {{'general.driver' | translate }}</h5>
                    </div>
                    <fh-driver-details [driver]="driver" [formMode]="formMode"></fh-driver-details>
                    <fh-save-footer [valid]="!userForm.invalid" (onInsert)="onInsert()" [formMode]="formMode">
                    </fh-save-footer>
                </div>

            </div>
            <div class="col-lg-6" *ngIf="permissions['HasWasl']">

                <div class=" ibox float-e-margins" style="position:relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <div class="ibox-title">
                        <h5>
                            <i class="fa fa-user-shield"></i> {{'general.wasl' | translate }}</h5>
                    </div>
                    <fh-driver-wasl-details [driver]="driver" [formMode]="formMode"></fh-driver-wasl-details>
                </div>
            </div>
        </form>
    </div>
</div>