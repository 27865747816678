import { Component, OnDestroy, OnInit, ChangeDetectorRef, ViewChild, } from '@angular/core';

import { IssueType } from 'app/models/issuetype.model';
import { DataTableDirective } from 'angular-datatables';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { SensorsTypesService } from 'app/services/sensors/sensorTypes.service';
import { getGridLanguages } from 'app/common/gridhelper';

@Component({
  selector: 'fh-sensor-types',
  templateUrl: 'sensorTypes.template.html'
})
export class SensorTypeViewComponent implements OnInit, OnDestroy {
  token: string;
  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  dropdown_IssueType = '';

  dtOptions = {};

  min: number;
  max: number;

  issueTypes: IssueType[];
  loading = false;
  languageLoaded: boolean;
  permissions: {};
  searchTerm: any;

  constructor(private translateService: TranslateService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private sensorTypeService: SensorsTypesService) {

    this.token = this.authenticationService.getAuthToken();
  }

  editMode(isEditMode) {
    const toolbox = document.getElementById('grid-toolbox');

    console.log(isEditMode);
    if (isEditMode) {
      toolbox.classList.remove('hidden');
    } else {
      toolbox.classList.add('hidden');
    }
  }

  seachChanged(value) {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.search(value).draw();
    });
  }


  fireFilter(): void {
    console.log('fire update');
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {

      // Search for the devicetype

      // Search for the issuetype
      if (this.dropdown_IssueType !== '') {
        dtInstance.column(6).search(this.dropdown_IssueType);
      }

      // Fire the request
      dtInstance.draw();
    });
  }

  ngOnDestroy(): void {
    // We remove the last function in the global ext search array so we do not add the fn each time the component is drawn
    // /!\ This is not the ideal solution as other components may add other search function in this array, so be careful when
    // handling this global variable
    $.fn['dataTable'].ext.search.pop();

    // Had to reset the array...
    $.fn['dataTable'].ext.search = [];
  }


  ngOnInit(): void {
    this.permissions = this.authenticationService.permissions;

    this.translateService.get('general.date').subscribe(value => {
      this.languageLoaded = true;
      this.initGrid();
    });
  }

  checkFilters() {
    this.fireFilter();
  }

  initGrid(): void {
    const that = this;

    $.fn['dataTable'].ext.search.pop();

    this.loading = true;

    const commonExportOptions = {
      modifier: {
        page: 'all',
        search: 'none'
      },
      columns: ':visible[tabindex]'
    };
    const fileName = 'device_overview';
    const header = this.translateService.instant('menu.deviceoverview');

    this.dtOptions = {
      pagingType: 'simple_numbers',
      serverSide: false,
      processing: false,
      scrollX: true,
      colReorder: { fixedColumnsLeft: 1 },
      order: [[1, 'asc']],
      stateSave: true,
      stateSaveCallback: function (settings, data) {
        localStorage.setItem('Columns_' + that.constructor.name, JSON.stringify(data));
      },
      stateLoadCallback: function () {
        try {
          const columnSettings = JSON.parse(localStorage.getItem('Columns_' + that.constructor.name));
          that.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
          return columnSettings;
        } catch (e) {
          console.log(e);
        }
      },
      ajax: (dataTablesParameters: any, callback) => {
        $('.dataTables_info').html(this.translateService.instant('grid.loadingData'));
        this.sensorTypeService.getSensorTypes().subscribe(resp => {
          callback({
            recordsTotal: resp.length,
            recordsFiltered: resp.length,
            data: resp
          });
        });
      },
      initComplete: function (settings, json) {
        that.loading = false;
      },
      colVis: {
        restore: this.translateService.instant('general.restore'),
        showAll: this.translateService.instant('general.showAll'),
        showNone: this.translateService.instant('general.hideAll'),
      },
      columns: [
        {
          name: 'id',
          data: 'id',
          orderable: false,
          title: '',
          width: '20',
          render: function (data, type, row) {
            return '<a class=\'btn btn-primary btn-outline btn-grid\' href=\'/#/SensorTypeDetails/Index/' + data + '\'><i class=\'fas fa-fw fa-info\'></i> <span class="hidden-sm hidden-xs">Details</span></a>';
          }
        },
        {
          name: 'brand',
          data: 'brand',
          title: this.translateService.instant('general.brand'),
        },
        {
          name: 'model',
          data: 'model',
          title: this.translateService.instant('general.model'),
        },
        {
          name: 'deviceSensorTypeName',
          data: 'deviceSensorTypeName',
          title: this.translateService.instant('general.deviceSensorTypeName'),
        },
      ],
      pageLength: 10,
      lengthMenu: [[10, 25, 50, -1], [10, 25, 50, this.translateService.instant('general.all')]],
      language: getGridLanguages(this.translateService),
    };
  }
}
