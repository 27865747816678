import { Component, ChangeDetectorRef, ViewChild, OnDestroy, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { DataTableDirective } from 'angular-datatables';
import { AssetService } from 'app/services/asset/asset.service';
import { BsModalRef, ModalDirective } from 'ngx-bootstrap';
import { DeviceService } from 'app/services/device/device.service';
import { AssetTypeService } from 'app/services/asset/assetTypes.service';
import { Asset } from 'app/models/asset.model';
import { getGridLanguages } from 'app/common/gridhelper';

@Component({
  selector: 'fh-assettype-chooser',
  templateUrl: 'assetTypeChooser.template.html',
  styleUrls: ['assetTypeChooser.template.css'],
  providers: []
})
export class AssetTypeChooserComponent implements OnInit, OnDestroy {

  @ViewChild('autoShownModal') autoShownModal: ModalDirective;

  @Input() asset: Asset;
  modalRef: BsModalRef;
  isModalShown = false;

  activeId;

  token: string;
  @Output() onSelect = new EventEmitter();

  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  dropdown_IssueType = '';

  selected;

  dtOptions = {};

  min: number;
  max: number;

  loading = false;
  languageLoaded: boolean;
  permissions: {};
  dataTable: DataTables.JQueryDataTables;
  searchTerm: any;

  constructor(private translateService: TranslateService, private assetTypeService: AssetTypeService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private deviceService: DeviceService) {

    this.token = this.authenticationService.getAuthToken();
  }

  close() {
    this.hideModal();
  }

  onHidden() {
    this.hideModal();
  }

  select() {
    this.hideModal();

    this.onSelect.emit({ id: this.selected.id, name: this.selected.name, brand: this.selected.brand, model: this.selected.model });
  }

  clickHandler(info: any): void {
    this.selected = info;
  }

  editMode(isEditMode) {
    const toolbox = document.getElementById('grid-toolbox');

    console.log(isEditMode);
    if (isEditMode) {
      toolbox.classList.remove('hidden');
    } else {
      toolbox.classList.add('hidden');
    }
  }

  seachChanged(value) {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.search(value).draw();
    });
  }

  fireFilter(): void {
    console.log('fire update');
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {

      // Search for the devicetype

      // Search for the issuetype
      if (this.dropdown_IssueType !== '') {
        dtInstance.column(6).search(this.dropdown_IssueType);
      }

      // Fire the request
      dtInstance.draw();
    });
  }

  ngOnDestroy(): void {
    // We remove the last function in the global ext search array so we do not add the fn each time the component is drawn
    // /!\ This is not the ideal solution as other components may add other search function in this array, so be careful when
    // handling this global variable
    $.fn['dataTable'].ext.search.pop();

    // Had to reset the array...
    $.fn['dataTable'].ext.search = [];
  }

  ngOnInit(): void {
    this.permissions = this.authenticationService.permissions;

    this.translateService.get('general.date').subscribe(value => {
      this.languageLoaded = true;
      this.initGrid();
    });
  }

  checkFilters() {
    this.fireFilter();
  }

  initGrid(): void {
    const that = this;


    $.fn['dataTable'].ext.search.pop();

    this.loading = true;

    this.dtOptions = {
      pagingType: 'simple_numbers',
      serverSide: true,
      processing: false,
      searchDelay: 500,
      deferRender: true,
      scrollX: true,
      colReorder: { fixedColumnsLeft: 1 },
      deferLoading: 0,
      stateSave: true,
      stateSaveCallback: function (settings, data) {
        localStorage.setItem('Columns_' + that.constructor.name, JSON.stringify(data));
      },
      stateLoadCallback: function () {
        try {
          const columnSettings = JSON.parse(localStorage.getItem('Columns_' + that.constructor.name));
          that.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
          return columnSettings;
        } catch (e) {
          console.log(e);
        }
      },
      order: [[1, 'asc']],
      ajax: {
        beforeSend: () => {
          $('.dataTables_info').html(this.translateService.instant('grid.loadingData'));
        },
        url: that.assetTypeService.getPagingUrl(this.asset.assetClass),
        type: 'POST',
        headers: {
          'Authorization': 'Bearer ' + that.token
        }
      },
      initComplete: function (settings, json) {
        console.log('init complete');
        that.checkFilters();
        that.loading = false;
      },
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        const self = this;
        // Unbind first in order to avoid any duplicate handler
        // (see https://github.com/l-lin/angular-datatables/issues/87)
        $('td', row).unbind('click');
        $('td', row).bind('click', () => {
          self.clickHandler(data);
        });
        return row;
      },
      colVis: {
        restore: this.translateService.instant('general.restore'),
        showAll: this.translateService.instant('general.showAll'),
        showNone: this.translateService.instant('general.hideAll'),
      },
      columns: [
        {
          name: 'name',
          data: 'name',
          title: this.translateService.instant('general.name')
        },
        {
          name: 'brand',
          data: 'brand',
          title: this.translateService.instant('general.brand')
        },
        {
          name: 'model',
          data: 'model',
          title: this.translateService.instant('general.model')
        }
      ],
      pageLength: 10,
      lengthMenu: [[10, 25, 50, -1], [10, 25, 50, this.translateService.instant('general.all')]],
      language: getGridLanguages(this.translateService),
    };
  }

  // Model
  public showModal(): void {
    console.log('show modal');
    this.isModalShown = true;
  }

  public hideModal(): void {
    this.isModalShown = false;
  }
}
