import { Component, Input, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';


@Component({
    selector: 'fh-filter-toggler',
    templateUrl: 'filterToggler.component.html',
    viewProviders: [],
})
export class FilterTogglerComponent implements OnInit, OnChanges {

    showFilter = false;
    @Input() constructorName: any;
    @Output() filterChanged = new EventEmitter();

    constructor() {
    }

    ngOnChanges(): void {
        if ('ShowFilter_' + this.constructorName) {
            console.log('ShowFilter_' + this.constructorName);
            this.loadShowFilter();
        }
    }

    ngOnInit(): void {
    }

    // Hide or show filters
    loadShowFilter() {
        this.showFilter = localStorage.getItem('ShowFilter_' + this.constructorName) !== 'false';

        this.filterChanged.emit(this.showFilter);
    }

    changeShowfilter(filterValue) {
        this.showFilter = filterValue;
        localStorage.setItem('ShowFilter_' + this.constructorName, filterValue)

        this.filterChanged.emit(this.showFilter);
    }
}

