import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { appVersion } from 'app/app.version';
import { environment } from 'environments/environment';

@Component({
  selector: 'fh-app-auth-callback',
  templateUrl: './sso-auth-callback.component.html',
  styleUrls: ['../appviews/login.template.css']
})
export class AuthCallbackComponent implements OnInit {

  appVersion: string;
  error = false;
  errorMessage: string;
  notRecognized = false;
  ssoUrl: string;

  constructor(private authService: AuthenticationService, private router: Router, private activatedRoute: ActivatedRoute) {
    this.appVersion = appVersion;
    this.ssoUrl = environment.SSOAuthenticationUrl;
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['id_token']) {
        this.authService.signInWithSSO(params['id_token']).subscribe((result) => {
          if (result !== null) {
            this.router.navigate(['/']);
          } else {
            this.error = true;
            this.errorMessage = 'Error while logging in';
          }
        }, (error) => {
          this.error = true;
          if (error.status === 401) {
            this.errorMessage = 'Error while logging in';
          } else if (error.status === 404 && error.error === 'no user') {
            this.errorMessage = localStorage.getItem(AuthenticationService.ID) + ' is not recognized. Please login with a different account.';
            this.notRecognized = true;
          } else {
            this.errorMessage = 'Error while logging in';
          }
        }
        )
      } else if (params['error']) {
        console.log('Error while trying to login ');
        this.router.navigate(['/Login'])
      } else {
        console.log('no parameters found');
        this.router.navigate(['/Login'])
      }
    });
  }
  failed(): boolean {
    return this.error;
  }
  onMouseMove(event) {

    const e = event;
    // Mouse Move 3d Effect

    const perX = (e.clientX / $(window).width()) - 0.5;
    const perY = (e.clientY / $(window).height()) - 0.5;
    // TweenMax.to('.effect-3d-element', 0.4, { rotationY: 5 * perX, rotationX: 5 * perY, transformPerspective: 1000, transformOrigin: 'center' })
  }
}
