<div class="ibox float-e-margins" style="position:relative">
    <div class="ibox-title">
        <h5>
            <i class="fas fa-fw fa-object-group"></i> {{'general.assetType' | translate }}</h5>
    </div>

    <div class="ibox-content slick" style="position:relative">
        <ngx-loading [show]="loadingAssetTypes"></ngx-loading>
        <table style="width:100%" class="table table-hover nowrap dtr-inline no-footer">
            <tbody>
                <tr>
                    <td style="width:50%">
                        {{ 'general.brand' | translate}}
                        <span class="error" *ngIf="brand.invalid && formMode != 1">*</span>
                    </td>
                    <td colspan="2" style="width:50%">
                        <input type="text" [readonly]="formMode == 1" name="brand" class="form-control table-inline"
                            [(ngModel)]="asset.brand" required #brand="ngModel">
                    </td>
                </tr>
                <tr>
                    <td style="width:50%">
                        {{ 'general.model' | translate}}
                        <span class="error" *ngIf="model.invalid && formMode != 1">*</span>
                    </td>
                    <td colspan="2" style="width:50%">
                        <input type="text" [readonly]="formMode == 1" name="model" class="form-control table-inline"
                            [(ngModel)]="asset.model" required #model="ngModel">
                    </td>
                </tr>
                <tr *ngIf="brand.valid && model.valid">
                    <td style="width:50%">
                        {{ 'general.assetType' | translate}}
                        <span class="error" *ngIf="assetTypeId.invalid && formMode != 1">*</span>
                    </td>
                    <td colspan="2" style="width:50%">
                        <select (change)="selectAssetType(assetTypeId)" class="form-control table-inline"
                            name="assetTypeId" #assetTypeId="ngModel" required [(ngModel)]="asset.assetTypeId"
                            style="width:100%">
                            <option [ngValue]="undefined"> {{ 'general.none' | translate}}</option>
                            <option *ngFor="let assetType of assetTypes | filterAssetType:asset" [value]="assetType.id">
                                {{ assetType.brand }} - {{ assetType.model }} - {{ assetType.serie }} -
                                {{ assetType.trim }}
                            </option>
                        </select>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="ibox float-e-margins" style="position:relative" *ngIf="selectedAssetType">
    <div class="ibox-title">
        <h5>
            <i class="fas fa-fw fa-object-group"></i> {{'general.assetType' | translate }}</h5>
    </div>

    <div class="ibox-content slick" style="position:relative">
        <table style="width:100%" class="table table-hover nowrap dtr-inline no-footer">
            <tbody>
                <tr>
                    <td style="width:50%">
                        {{ 'general.serie' | translate}}
                        <!-- <span class="error" *ngIf="brand.invalid && formMode != 1">*</span> -->
                    </td>
                    <td colspan="2" style="width:50%">
                        {{ selectedAssetType?.serie }}
                    </td>
                </tr>
                <tr>
                    <td style="width:50%">
                        {{ 'general.trim' | translate}}
                        <!-- <span class="error" *ngIf="brand.invalid && formMode != 1">*</span> -->
                    </td>
                    <td colspan="2" style="width:50%">
                        {{ selectedAssetType?.trim }}
                    </td>
                </tr>
                <ng-container *ngFor="let assetTypeGroupKey of objectKeys(assetTypeGroups)">
                    <tr>
                        <td colspan="3">
                            <b>{{ ('enums.spec.' + assetTypeGroupKey) | translate}}</b>
                        </td>
                    </tr>
                    <tr *ngFor="let property of assetTypeGroups[assetTypeGroupKey]">
                        <td>
                            {{ ('enums.spec.' + property.Id) | translate}}
                        </td>
                        <td colspan="2">
                            {{property.Value}}
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>