import { Component, Input, TemplateRef, ViewChild, Output, OnChanges, OnInit } from '@angular/core';
import { FormMode } from 'app/common/enums';
import { trigger, style, animate, transition } from '@angular/animations';
import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap';
import { EventEmitter } from '@angular/core';
import { DeviceService } from '../../../services/device/device.service';
import { Device } from '../../../models/device.model';
import { AuthenticationService } from 'app/services/authentication/authentication.service';

import 'rxjs/add/observable/of';
import { ControlContainer, NgForm } from '@angular/forms';
import { DeviceTypeService } from 'app/services/devicetypes/devicetypes.service';
import { DeviceType } from 'app/models/devicetype.model';
import { AccountPlatformService } from 'app/services/platform/accountPlatform.service';
import { AccountPlatform } from 'app/models/platform.model';

@Component({
    selector: 'fh-device-details',
    templateUrl: 'deviceDetails.template.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    animations: [
        trigger(
            'enterAnimation', [
            transition(':enter', [
                // css styles at start of transition
                style({ opacity: 0 }),

                // animation and styles at end of transition
                animate('0.5s', style({ opacity: 1 }))
            ]),
        ]
        )
    ],
})
export class DeviceDetailsComponent implements OnChanges, OnInit {

    @Input() device: Device;
    @Input() formMode: FormMode = FormMode.read;

    permissions: {};
    deviceTypes: DeviceType[];
    accountPlatforms: AccountPlatform[];
    loadingDeviceTypes = false;

    constructor(private accountplatformService: AccountPlatformService, private deviceTypeService: DeviceTypeService, private modalService: BsModalService, private deviceService: DeviceService, private authentication: AuthenticationService) {
    }

    ngOnInit(): void {
        this.permissions = this.authentication.permissions;
        this.loadingDeviceTypes = true;

        this.deviceTypeService.getDeviceTypes(false, false, false).subscribe(deviceTypes => {
            this.loadingDeviceTypes = false;
            this.deviceTypes = deviceTypes;

            if (this.formMode === 2) {
                this.device.deviceTypeId = deviceTypes[0].id;
            }
        });

        this.accountplatformService.getAccountPlatforms().subscribe(accountPlatforms => {
            this.accountPlatforms = accountPlatforms;

            if (this.formMode === 2) {
                this.device.accountPlatformId = this.accountPlatforms[0].id;
            }
        });
    }

    changeIssueCalculation(event) {
        this.device.hasIssueCalculationEnabled = !this.device.hasIssueCalculationEnabled;
        this.deviceService.saveIssueCalculation(this.device).subscribe(result => {
            console.log(result);
        });
    }

    ngOnChanges(): void {
    }


}
