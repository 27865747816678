import { Component, OnDestroy, OnInit, ChangeDetectorRef, ViewChild, } from '@angular/core';

import { AuthenticationService } from '../../services/authentication/authentication.service';
import { Asset } from 'app/models/asset.model';
import { FormMode } from 'app/common/enums';
import { AssetService } from 'app/services/asset/asset.service';
import { AssetType } from 'app/models/assetType.model';
import { Router } from '@angular/router';
import { AssetTypeService } from 'app/services/asset/assetTypes.service';


@Component({
  selector: 'fh-assettype-add',
  templateUrl: 'add.template.html'
})
export class AssetTypeAddViewComponent {
  token: string;
  addDevice = false;
  formMode = FormMode.add;
  error: any;
  loading = false;

  success: { statusText: string; success: string; };
  assetType: AssetType;
  objectKeys = Object.keys;

  assetTypeGroups = [];
  isImpersonated: boolean;
  permissions: {};

  constructor(private router: Router, private assetTypeService: AssetTypeService, private cd: ChangeDetectorRef, private authentication: AuthenticationService) {

    this.isImpersonated = this.authentication.getIsImpersonated();
    this.token = this.authentication.getAuthToken();
    this.permissions = this.authentication.permissions;

    this.assetTypeGroups = [];

    this.assetType = new AssetType();
  }

  onInsert() {
    this.loading = true;

    const temp = [];
    this.assetTypeGroups.forEach(mainCat => {
      mainCat.forEach(subcat => {
        temp.push(subcat);
      });
    });
    this.assetType.properties = JSON.stringify(temp);

    this.assetTypeService.saveAssetType(this.assetType).subscribe(result => {

      this.success = {
        statusText: 'Success',
        success: 'You will be redirected to the assettype'
      };

      setTimeout(() => {
        this.router.navigate(['/AssetTypeDetails/Index/' + result.id]);
      }, 2000);

      this.assetTypeService.resetAssetTypesCache().subscribe(res => {
        console.log('AssetTypes are cleared');
        console.log(res);
      });

      this.loading = false;
    }, error => {
      this.error = error
      this.loading = false;
    });
  }

}
