
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, AfterViewInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Subject } from 'rxjs/Subject';
import { DeviceService } from '../../services/device/device.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Device } from 'app/models/device.model';
import { FhChartService } from '../../services/charts/charts.service';
import { tileLayer, latLng, circle, polygon, Map, marker, icon } from 'leaflet';
import { Input } from '@angular/core';
import { IssueService } from '../../services/issues/issues.service';
import { FormattedChartDataObject } from '../../models/formatChartData.model';
import { Issue } from '../../models/issue.model';
import { IssueTypeEnums } from 'app/common/enums';
import * as moment from 'moment-timezone';

declare var L;

@Component({
    selector: 'fh-fh-issue-details-chart',
    templateUrl: 'issueDetailsChart.template.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [FhChartService]
})
export class IssueDetailsChartViewComponent implements OnInit, OnChanges {
    @Input() data: any;
    @Input() map: Map;
    @Input() deviceSensors: any;
    @Input() issue: Issue;

    geoJsonData;
    chart: any;
    loading = true;
    isDebugMode = false;
    showSensors = false;
    loadingJson: boolean;
    sensors = [];
    chartDataTitleAndUnitsOfMeasure = {};
    chartDataYAxes = [];
    plotIssueBands = true;
    formattedData: FormattedChartDataObject[];


    constructor(private cd: ChangeDetectorRef, private chartService: FhChartService, private http: HttpClient, private deviceService: DeviceService,
        private route: ActivatedRoute, private router: Router, private translateService: TranslateService) {
    }

    ngOnChanges(changes: SimpleChanges) {
        console.log('NgOnChanges !');
    }

    updateChanges() {
        console.log('Update changes !');

        this.chart = undefined;
        this.geoJsonData = undefined;
        this.sensors = [];
        this.chartDataTitleAndUnitsOfMeasure = {};
        this.chartDataYAxes = [];
        this.formattedData = [];
        this.renderChart();
    }

    flipDebugMode() {
        this.loadingJson = true;
        this.cd.markForCheck();
        setTimeout(() => {
            this.isDebugMode = !this.isDebugMode
            this.loadingJson = false;
            this.cd.markForCheck();
        }, 100);
    }

    flipPlotIssueBand() {
        this.plotIssueBands = !this.plotIssueBands;
        this.updateChartData();
    }

    renderChart() {
        console.log('rerender chart');
        if (!(this.data === undefined || this.data === '')) {
            this.geoJsonData = JSON.parse(this.data);
            const formattedData: FormattedChartDataObject[] = [];

            if (this.geoJsonData.features) {
                this.geoJsonData.features.forEach((dataPoint: any) => {
                    let tempExternalPower = dataPoint.properties.ExternalPower;
                    let tempIgnition = dataPoint.properties.Ignition;
                    // TODO: Old name of GPSFix was GPSLocation. Had to stay in for backwards compatability with geojsons of old issues.
                    // Alter to only take properties.GPSFix after issues before 31/01/2019 have become redundant.
                    let tempGPSFix = (dataPoint.properties.GPSFix !== undefined) ? dataPoint.properties.GPSFix : dataPoint.properties.GPSLocation

                    let tempDriverInfo = dataPoint.properties.DriverInfo;


                    if (tempExternalPower === true || tempExternalPower === 'True' || tempExternalPower === '1') {
                        tempExternalPower = 1;
                    } else if (tempExternalPower === false || tempExternalPower === 'False' || tempExternalPower === '0') {
                        tempExternalPower = 0;
                    } else {
                        tempExternalPower = null;
                    }

                    if (tempIgnition === true || tempIgnition === 'True' || tempIgnition === '1') {
                        tempIgnition = 1;
                    } else if (tempIgnition === false || tempIgnition === 'False' || tempIgnition === '0') {
                        tempIgnition = 0;
                    } else {
                        tempIgnition = null;
                    }

                    if (tempGPSFix === true || tempGPSFix === 'True' || tempGPSFix === '1') {
                        tempGPSFix = 1;
                    } else if (tempGPSFix === false || tempGPSFix === 'False' || tempGPSFix === '0') {
                        tempGPSFix = 0;
                    } else {
                        tempGPSFix = null;
                    }

                    if (tempDriverInfo === true || tempDriverInfo === 'True' || tempDriverInfo === '1') {
                        tempDriverInfo = 1;
                    } else if (tempDriverInfo === false || tempDriverInfo === 'False' || tempDriverInfo === '0') {
                        tempDriverInfo = 0;
                    } else {
                        tempDriverInfo = null;
                    }

                    if (dataPoint.geometry && dataPoint.properties) {
                        const point: FormattedChartDataObject = {
                            date: moment.utc(dataPoint.properties.Timestamp).toDate().getTime(),
                            lat: dataPoint.geometry.coordinates[0],
                            lon: dataPoint.geometry.coordinates[1],
                            speed: dataPoint.properties.Speed,
                            exPower: tempExternalPower,
                            ignition: tempIgnition,
                            odo: parseFloat(dataPoint.properties.Odometer),
                            sensors: dataPoint.properties.Sensors,
                            sattelites: dataPoint.properties.Sattelites,
                            gsmStrength: dataPoint.properties.Gsm != null ? dataPoint.properties.Gsm.SignalStrength : null,
                            driverInfo: tempDriverInfo,
                            cumulativeDistance: dataPoint.properties.CumulativeDistance,
                            invervalMessage: dataPoint.properties.IntervalMessage,
                            gpsFix: tempGPSFix,
                            hdop: dataPoint.properties.Hdop,
                            delayInMessage: dataPoint.properties.DelayInMessage,
                            messageInViolation: dataPoint.properties.MessageInViolation,
                            driverId: dataPoint.properties.DriverId
                        };
                        point.cumulativeDistance = point.cumulativeDistance / 1000;
                        point.cumulativeDistance = parseFloat(point.cumulativeDistance.toFixed(2));
                        formattedData.push(point);
                    }
                });

                this.formattedData = formattedData;
                this.updateChartData();
            }
        }
        this.loading = false;
    }

    ngOnInit() {
        this.updateChanges();
    }

    getPlotLine(date: Date) {
        return {
            color: '#FF0000',
            dashStyle: 'dash',
            width: 1,
            value: date,
            zIndex: 10,
        };
    }

    getPlotBand(date1: Date, date2: Date) {
        return {
            color: 'rgba(255, 0, 0, 0.35)',
            backgroundColor: null,
            from: date1,
            to: date2,
            zIndex: 5,
            fillOpacity: 1
        };
    }

    updateChartData() {

        console.log('Update chartdata');

        const plotBands = [];
        const plotLines = []

        if (this.plotIssueBands) {
            if (this.issue.issueType === IssueTypeEnums.OutlierTime) {
                const issueDates: Date[] = [];
                issueDates.push(this.issue.issueDate);
                let next = false;

                this.formattedData.forEach(dataPoint => {
                    if (next && !(this.issue.issueDate.valueOf() === dataPoint.date)) {
                        issueDates.push(new Date(dataPoint.date))
                        next = false;
                    }
                    if (this.issue.issueDate.valueOf() === dataPoint.date) {
                        next = true;
                    }
                });
                plotBands.push(this.getPlotBand(issueDates[0], issueDates[1]));
                plotLines.push(this.getPlotLine(issueDates[0]));
                plotLines.push(this.getPlotLine(issueDates[1]));

            } else if (this.issue.issueType === IssueTypeEnums.GpsDrift ||
                this.issue.issueType === IssueTypeEnums.MissingDriver ||
                this.issue.issueType === IssueTypeEnums.DelayedMessages ||
                this.issue.issueType === IssueTypeEnums.BadGpsReception) {
                let startIssueDate = null;
                for (let index = 0; index < this.formattedData.length; index++) {
                    const element = this.formattedData[index];
                    if (element.messageInViolation && startIssueDate == null) {
                        startIssueDate = new Date(element.date);
                    }
                    if (!element.messageInViolation && startIssueDate !== null) {
                        plotBands.push(this.getPlotBand(new Date(startIssueDate), new Date(element.date)));
                        startIssueDate = null;
                    }
                    if (index === (this.formattedData.length - 1) && startIssueDate !== null) {
                        plotBands.push(this.getPlotBand(new Date(startIssueDate), new Date(element.date)));
                        startIssueDate = null;
                    }
                }
            } else {
                plotLines.push(this.getPlotLine(this.issue.issueDate));
            }
        }

        const chartData = this.filterChartData(this.formattedData);

        console.log('Update chart');
        this.chart = this.chartService.generateMultiChart(chartData, this.map, plotLines, plotBands, this.chartDataTitleAndUnitsOfMeasure, this.chartDataYAxes, this.translateService);
        this.cd.markForCheck();
    }

    reflowChart() {
        console.log('reflow chart');
        if (this.chart && this.chart.ref) {
            this.chart.ref.update({ /* options object */ }, true);
            this.chart.ref.reflow();
        }
    }

    filterChartData(formattedData: FormattedChartDataObject[]): any {
        const chartSpeedData = [];
        const chartOdoData = [];
        const chartSatteliteData = [];
        const chartGsmStrengthData = [];
        const chartExternalPowerData = [];
        const chartIgnitionData = [];
        const chartCumulativeData = [];
        const chartIntervalMessage = [];
        const chartDriverInfo = [];
        const chartGPSFix = [];
        const chartHdop = [];
        const chartDelayInMessages = [];
        const allSensorData = [];
        let lastData = null;

        for (let index = 0; index < formattedData.length; index++) {
            const data = formattedData[index];
            const coordinate = [data.lon, data.lat];
            const dateTime = data.date;

            chartSpeedData.push({ x: dateTime, y: data.speed, latlon: coordinate });
            chartOdoData.push({ x: dateTime, y: data.odo, latlon: coordinate });
            chartSatteliteData.push({ x: dateTime, y: data.sattelites, latlon: coordinate });
            chartHdop.push({ x: dateTime, y: data.hdop, latlon: coordinate });
            chartGsmStrengthData.push({ x: dateTime, y: data.gsmStrength, latlon: coordinate });
            chartDriverInfo.push({ x: dateTime, y: data.driverInfo, n: data.driverId, latlon: coordinate });
            chartCumulativeData.push({ x: dateTime, y: data.cumulativeDistance, latlon: coordinate });
            chartIntervalMessage.push({ x: dateTime, y: data.invervalMessage, latlon: coordinate });
            chartDelayInMessages.push({ x: dateTime, y: data.delayInMessage, latlon: coordinate });
            allSensorData.push([]);
            chartExternalPowerData.push({ x: dateTime, y: data.exPower, latlon: coordinate });
            chartIgnitionData.push({ x: dateTime, y: data.ignition, latlon: coordinate });
            chartGPSFix.push({ x: dateTime, y: data.gpsFix, latlon: coordinate });

            for (const sensor in data.sensors) {
                if (data.sensors.hasOwnProperty(sensor)) {
                    let element = data.sensors[sensor];
                    if (data.sensors[sensor] === 'True') {
                        element = 1;
                    } else if (data.sensors[sensor] === 'False') {
                        element = 0;
                    }
                    allSensorData[index].push({ x: dateTime, y: element, latlon: coordinate, sensorName: sensor });
                }
            }
            lastData = data;
        }
        const chartSensorData = [];

        allSensorData.forEach(sensorsData => {
            let index = 0;
            for (const sensorData in sensorsData) {
                if (sensorsData.hasOwnProperty(sensorData)) {
                    while (chartSensorData[index] == null) {
                        chartSensorData.push([]);
                    }
                    const element = sensorsData[sensorData];
                    element.y = parseFloat(element.y);
                    chartSensorData[index].push(element);
                    index++;
                }
            }
        });
        const chartData = [];
        let show = false;

        if (chartExternalPowerData.some(x => x.y)) {
            chartData.push(this.getExternalPowerChart(chartExternalPowerData));
        }
        if (chartIgnitionData.some(x => x.y)) {
            chartData.push(this.getIgnitionChart(chartIgnitionData));
        }
        if (chartSpeedData.some(x => x.y)) {
            chartData.push(this.getSpeedChart(chartSpeedData));
        }
        if (chartOdoData.some(x => x.y)) {
            show = (this.issue.issueType === IssueTypeEnums.OdoSensorStuck ||
                    this.issue.issueType === IssueTypeEnums.OdoOutOfBounds ||
                    this.issue.issueType === IssueTypeEnums.GpsStuck);
            chartData.push(this.getOdoChart(chartOdoData, show));
        }
        if (chartSatteliteData.some(x => x.y)) {
            chartData.push(this.getSatteliteChart(chartSatteliteData));
        }
        if (chartGsmStrengthData.some(x => x.y)) {
            chartData.push(this.getGsmStrengthChart(chartGsmStrengthData));
        }

        if (chartDriverInfo.find(x => x.y != null)) {
            chartData.push(this.getDriverInfoChart(chartDriverInfo));
        }
        if (chartCumulativeData.some(x => x.y)) {
            chartData.push(this.getCumulativeDistanceChart(chartCumulativeData));
        }
        if (chartIntervalMessage.some(x => x.y)) {
            chartData.push(this.getIntervalMessageChart(chartIntervalMessage));
        }
        if (chartDelayInMessages.some(x => x.y)) {
            chartData.push(this.getDelayInMessageChart(chartDelayInMessages));
        }
        if (chartGPSFix.find(x => x.y != null)) {
            chartData.push(this.getGPSFixChart(chartGPSFix));
        }
        if (chartHdop.some(x => x.y)) {
            show = (this.issue.issueType === IssueTypeEnums.GpsDrift ||
                    this.issue.issueType === IssueTypeEnums.BadGpsReception);
            chartData.push(this.getHdopChart(chartHdop, show));
        }
        let i = 1;
        chartSensorData.forEach(sensorData => {
            let sensor;
            this.deviceSensors.forEach(deviceSensor => {
                if (deviceSensor.id === sensorData[0].sensorName) {
                    sensor = deviceSensor;
                }
            });
            if (sensor) {
                const title = sensor.name ? sensor.name : this.translateService.instant('chart.sensorDefaultName') + ' ' + i;
                const unitOfMeasure = sensor.metrics;
                const yAxis = {
                    visible: false,
                    showEmpty: false,
                    step: 'left',
                    labels: {
                        format: '{value}'
                    },
                    title: {
                        text: title
                    }
                }
                this.chartDataTitleAndUnitsOfMeasure[title] = unitOfMeasure;
                this.chartDataYAxes.push(yAxis);
                const axisIndex = this.chartDataYAxes.indexOf(yAxis);
                const visibility = (this.issue.sensorName === sensor.name || (sensor.deviceSensorTypeId === 11 && this.issue.issueType === IssueTypeEnums.GpsStuck))
                chartData.push(
                    {
                        name: title,
                        type: 'line',
                        step: 'left',
                        zIndex: 0,
                        yAxis: axisIndex,
                        data: sensorData,
                        lineWidth: 1,
                        visible: visibility,
                    }
                );
                i++;
            } else {
                console.log('Sensor ' + sensorData[0].sensorName + ' could not be found');
                const title = this.translateService.instant('chart.sensorDefaultName') + ' ' + i;
                const unitOfMeasure = '';
                const yAxis = {
                    visible: false,
                    showEmpty: false,
                    step: 'left',
                    labels: {
                        format: '{value}'
                    },
                    title: {
                        text: title
                    }
                }
                this.chartDataTitleAndUnitsOfMeasure[title] = unitOfMeasure;
                this.chartDataYAxes.push(yAxis);
                const axisIndex = this.chartDataYAxes.indexOf(yAxis);
                const visibility = false;
                chartData.push(
                    {
                        name: title,
                        type: 'line',
                        step: 'left',
                        zIndex: 0,
                        yAxis: axisIndex,
                        data: sensorData,
                        lineWidth: 1,
                        visible: visibility,
                    }
                );
                i++;
            }
        });

        return chartData;
    }

    getExternalPowerChart(data: any) {
        const title = this.translateService.instant('chart.externalPower');
        const unitOfMeasure = this.translateService.instant('chart.onOff');
        const yAxis = {
            allowDecimals: false,
            visible: false,
            min: -2,
            max: 11,
            tickInterval: 1,
            endOnTick: false,
            title: {
                text: title
            },
            categories: ['OFF', 'ON', ''],
            labels: {
                formatter: function () {
                    return this.value;
                }
            },
            opposite: true
        }
        this.chartDataTitleAndUnitsOfMeasure[title] = unitOfMeasure;
        this.chartDataYAxes.push(yAxis);
        const axisIndex = this.chartDataYAxes.indexOf(yAxis);
        return {
            name: title,
            type: 'line',
            color: '#5A3E67',
            zIndex: 1,
            step: 'left',
            marker: {
                enabled: false,
                lineWidth: 1,
                radius: 1,
                fillColor: '#5A3E67',
                symbol: 'square'
            },
            yAxis: axisIndex,
            data: data
        }
    }

    getIgnitionChart(data: any) {
        const title = this.translateService.instant('chart.ignition');
        const unitOfMeasure = this.translateService.instant('chart.onOff');
        const yAxis = {
            allowDecimals: false,
            visible: false,
            min: 0,
            max: 10,
            tickInterval: 1,
            endOnTick: false,
            title: {
                text: title
            },
            categories: ['OFF', 'ON', ''],
            labels: {
                formatter: function () {
                    return this.value;
                }
            },
            opposite: true
        }
        this.chartDataTitleAndUnitsOfMeasure[title] = unitOfMeasure;
        this.chartDataYAxes.push(yAxis);
        const axisIndex = this.chartDataYAxes.indexOf(yAxis);
        return {
            name: title,
            type: 'line',
            color: '#ff9933',
            zIndex: 3,
            step: 'left',
            marker: {
                enabled: false,
                lineWidth: 1,
                radius: 1,
                fillColor: '#ff9933',
                symbol: 'square'
            },
            yAxis: axisIndex,
            data: data
        }
    }

    getSpeedChart(data: any) {
        const title = this.translateService.instant('chart.speed');
        const unitOfMeasure = this.translateService.instant('abbreviation.kilometerPerHour');
        const yAxis = {
            visible: true,
            showEmpty: false,
            labels: {
                format: '{value}'
            },
            title: {
                text: title
            }
        }
        this.chartDataTitleAndUnitsOfMeasure[title] = unitOfMeasure;
        this.chartDataYAxes.push(yAxis);
        const axisIndex = this.chartDataYAxes.indexOf(yAxis);
        return {
            name: title,
            type: 'area',
            zIndex: 0,
            yAxis: axisIndex,
            color: '#D6A282',
            data: data,
            lineWidth: 1,
            visible: true
        }
    }

    getOdoChart(data: any, show: boolean) {
        const title = this.translateService.instant('chart.Odometer');
        let unitOfMeasure = '';
        if ([24, 25, 26].find(x => x === this.issue.issueType)) {
            unitOfMeasure = this.deviceSensors.find(x => x.name === this.issue.sensorName).metrics;
        } else {
            unitOfMeasure = this.translateService.instant('abbreviation.meters');
        }
        const yAxis = {
            visible: false,
            allowDecimals: false,
            tickInterval: 1,
            endOnTick: false,
            gridLineWidth: 0,
            title: {
                text: title
            },
            labels: {
                format: '{value}'
            },
            opposite: true
        }
        this.chartDataTitleAndUnitsOfMeasure[title] = unitOfMeasure;
        this.chartDataYAxes.push(yAxis);
        const axisIndex = this.chartDataYAxes.indexOf(yAxis);
        return {
            name: title,
            type: 'line',
            zIndex: 0,
            yAxis: axisIndex,
            color: '#186F7A',
            data: data,
            visible: show
        }
    }

    getSatteliteChart(data: any) {
        const title = this.translateService.instant('chart.sattelites');
        const unitOfMeasure = '';
        const yAxis = {
            visible: false,
            allowDecimals: true,
            labels: {
                format: '{point.y:.2f}'
            },
            title: {
                text: title
            }
        }
        this.chartDataTitleAndUnitsOfMeasure[title] = unitOfMeasure;
        this.chartDataYAxes.push(yAxis);
        const axisIndex = this.chartDataYAxes.indexOf(yAxis);
        return {
            name: title,
            type: 'line',
            zIndex: 0,
            yAxis: axisIndex,
            color: '#30187A',
            data: data,
            lineWidth: 1,
            visible: false
        }
    }

    getHdopChart(data: any, show: boolean) {
        const title = this.translateService.instant('chart.hdop');
        const unitOfMeasure = '';
        const yAxis = {
            visible: false,
            allowDecimals: true,
            labels: {
                format: '{point.y:.2f}'
            },
            title: {
                text: title
            }
        }
        this.chartDataTitleAndUnitsOfMeasure[title] = unitOfMeasure;
        this.chartDataYAxes.push(yAxis);
        const axisIndex = this.chartDataYAxes.indexOf(yAxis);
        return {
            name: title,
            type: 'line',
            zIndex: 0,
            yAxis: axisIndex,
            color: '#30187A',
            data: data,
            lineWidth: 1,
            visible: show
        }
    }

    getGsmStrengthChart(data: any) {
        const title = this.translateService.instant('chart.gsmStrength');
        const unitOfMeasure = '';
        const yAxis = {
            visible: false,
            allowDecimals: true,
            labels: {
                format: '{point.y:.2f}'
            },
            title: {
                text: title
            }
        }
        this.chartDataTitleAndUnitsOfMeasure[title] = unitOfMeasure;
        this.chartDataYAxes.push(yAxis);
        const axisIndex = this.chartDataYAxes.indexOf(yAxis);
        return {
            name: title,
            type: 'line',
            zIndex: 0,
            yAxis: axisIndex,
            color: '#187A4C',
            data: data,
            lineWidth: 1,
            visible: false
        }
    }

    getDriverInfoChart(data: any) {
        const title = this.translateService.instant('chart.driverInfo');
        const unitOfMeasure = '';
        const yAxis = {
            allowDecimals: false,
            visible: false,
            min: -6,
            max: 5,
            tickInterval: 1,
            endOnTick: false,
            title: {
                text: title
            },
            categories: ['OFF', 'ON', ''],
            labels: {
                formatter: function () {
                    return this.value;
                }
            },
            opposite: true
        }
        this.chartDataTitleAndUnitsOfMeasure[title] = unitOfMeasure;
        this.chartDataYAxes.push(yAxis);
        const axisIndex = this.chartDataYAxes.indexOf(yAxis);
        return {
            name: title,
            type: 'line',
            color: '#D88CFF',
            zIndex: 3,
            step: 'left',
            marker: {
                enabled: false,
                lineWidth: 1,
                radius: 1,
                fillColor: '#0119EC',
                symbol: 'square'
            },
            yAxis: axisIndex,
            data: data
        }
    }

    getCumulativeDistanceChart(data: any) {
        const title = this.translateService.instant('chart.cumulativeDistance');
        const unitOfMeasure = this.translateService.instant('abbreviation.kiloMeters');
        const yAxis = {
            visible: true,
            opposite: true,
            allowDecimals: true,
            showEmpty: false,
            labels: {
                format: '{value}'
            },
            title: {
                text: title
            }
        }
        this.chartDataTitleAndUnitsOfMeasure[title] = unitOfMeasure;
        this.chartDataYAxes.push(yAxis);
        const axisIndex = this.chartDataYAxes.indexOf(yAxis);
        return {
            name: title,
            type: 'areaspline',
            zIndex: 5,
            yAxis: axisIndex,
            color: '#187A4C',
            data: data,
            lineWidth: 1,
            visible: true
        }
    }

    getIntervalMessageChart(data: any) {
        const title = this.translateService.instant('chart.intervalMessage');
        const unitOfMeasure = '';
        const yAxis = {
            visible: false,
            opposite: true,
            allowDecimals: true,
            showEmpty: false,
            labels: {
                format: '{value}'
            },
            title: {
                text: title
            }
        }
        this.chartDataTitleAndUnitsOfMeasure[title] = unitOfMeasure;
        this.chartDataYAxes.push(yAxis);
        const axisIndex = this.chartDataYAxes.indexOf(yAxis);
        return {
            name: title,
            type: 'column',
            zIndex: 0,
            yAxis: axisIndex,
            color: 'rgba(128, 128, 128, 0.80)',
            data: data,
            visible: true,
            pointWidth: 3,
        }
    }

    getDelayInMessageChart(data: any) {
        const title = this.translateService.instant('chart.delayInMessage');
        const unitOfMeasure = '';
        const yAxis = {
            visible: false,
            opposite: true,
            allowDecimals: true,
            showEmpty: false,
            labels: {
                format: '{value}'
            },
            title: {
                text: title
            }
        }
        this.chartDataTitleAndUnitsOfMeasure[title] = unitOfMeasure;
        this.chartDataYAxes.push(yAxis);
        const axisIndex = this.chartDataYAxes.indexOf(yAxis);
        return {
            name: title,
            type: 'column',
            zIndex: 0,
            yAxis: axisIndex,
            color: 'rgba(128, 128, 128, 0.80)',
            data: data,
            visible: true,
            pointWidth: 3,
        }
    }

    getGPSFixChart(data: any) {

        const title = this.translateService.instant('chart.GPSFix');
        const unitOfMeasure = this.translateService.instant('chart.onOff');
        const yAxis = {
            allowDecimals: false,
            visible: false,
            min: -4,
            max: 11,
            tickInterval: 1,
            endOnTick: false,
            title: {
                text: title
            },
            categories: ['OFF', 'ON', ''],
            labels: {
                formatter: function () {
                    return this.value;
                }
            },
            opposite: true
        }
        this.chartDataTitleAndUnitsOfMeasure[title] = unitOfMeasure;
        this.chartDataYAxes.push(yAxis);
        const axisIndex = this.chartDataYAxes.indexOf(yAxis);
        return {
            name: title,
            type: 'line',
            color: '#0119EC',
            zIndex: 3,
            step: 'left',
            marker: {
                enabled: false,
                lineWidth: 1,
                radius: 1,
                fillColor: '#0119EC',
                symbol: 'square'
            },
            yAxis: axisIndex,
            data: data
        }
    }
}
