import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';

import { AuthenticationService } from '../authentication/authentication.service';
import { LoggingService } from '../logging/logging.service';
import { TranslatorService } from '../common/translator.service';
import { throwError } from 'rxjs/internal/observable/throwError';


@Injectable()
export class InfoService {
  token: string;
  timestamp;
  url = '';

  constructor(private translatorService: TranslatorService, private http: HttpClient, private loggingService: LoggingService, private authenticationService: AuthenticationService) {
    this.url = authenticationService.getWebserviceURL('info');
    this.token = authenticationService.getAuthToken();
  }

  getEndPoint() {
    return this.authenticationService.getWebserviceURL('');
  }

  getInfo(): Observable<any> {

    this.token = this.authenticationService.getAuthToken();
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

    console.log('Fetch info');
    return this.http.get(this.url, { headers: headers })
      .map((data) => {
        return data;
      })
      .catch(this.handleError);
  }

  private handleError(error: Response) {
    return throwError(error);
  }

}
