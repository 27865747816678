import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';

import { DeviceService } from '../../services/device/device.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Device } from 'app/models/device.model';
import { Map } from 'leaflet';
import { UserService } from '../../services/users/user.service';
import { User, AppUser } from '../../models/user.model';
import { PlatformService } from '../../services/platform/platform.service';
import { AccountService } from '../../services/accounts/accounts.service';
import { InventoryAccount, AccountPlatform } from '../../models/account.model';
import { AccountPlatformService } from '../../services/platform/accountPlatform.service';
import { DeleteModalComponent } from '../shared/usercontrols/deleteModal.component';
import { AppAccountPlatform } from 'app/models/platform.model';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { FormMode } from 'app/common/enums';

@Component({
    selector: 'fh-account-details-view',
    templateUrl: 'accountDetails.template.html'
})

export class AccountDetailsViewComponent implements OnInit {

    @ViewChild('deleteModal') deleteModal: DeleteModalComponent;

    formMode = FormMode.read;

    map: Map;
    options;
    layersControl;
    selectedRow: any;
    selected: any;
    theTrips = [];
    timeoutHandler;
    chartFuel: any;
    sensors;
    sub;
    account: InventoryAccount;
    loading = false;
    loadingSensors = false;
    platforms: any;
    users: AppUser[];
    accountPlatforms = [];
    loadingUsers: boolean;
    loadingAccountPlatforms: boolean;

    hasError = [];
    hasSuccess = [];

    showAccountPlatforms = false;
    error: any;
    success: string;

    formModeAccountPlatforms = FormMode.read;

    constructor(private cd: ChangeDetectorRef, private deviceService: DeviceService, private accountPlatformService: AccountPlatformService, private route: ActivatedRoute, private router: Router, private userService: UserService, private accountService: AccountService, private platformService: PlatformService) {
        this.account = null;
        this.sensors = [];
        this.theTrips = [];
        this.accountPlatforms = [];

    }

    ngOnInit() {

        this.account = new InventoryAccount;
        this.loading = true;
        this.loadingUsers = true;
        this.loadingAccountPlatforms = true;

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];
            this.accountService.getAccountById(id).subscribe(account => {
                this.account = account;
                this.loading = false;
                this.cd.markForCheck();
                if (this.account == null) {
                    this.router.navigate(['/Accounts/Overview'])
                }
            });

            this.userService.getUsersByAccountId(id).subscribe(result => {
                this.loadingUsers = false;
                this.users = result;
                this.cd.markForCheck();
            });

            this.accountPlatformService.getAccountPlatformByAccountId(id).subscribe(result => {
                this.accountPlatforms = result;
                this.loadingAccountPlatforms = false;
                this.cd.markForCheck();
            });
        });
    }

    getAccountIfo() {
        this.loading = true;

        this.accountService.getAccountById(this.account.id).subscribe(account => {
            this.account = account;
            this.loading = false;
        });
    }

    onSave() {
        this.loading = true;

        this.accountService.updateAccount(this.account).subscribe(result => {
            console.log(result);
            this.error = null;
            this.success = 'Succes';

            this.getAccountIfo();
        }, error => {
            this.error = error
            this.getAccountIfo();
        });
    }

    setFormMode(mode) {
        this.formMode = mode;

        if (this.formMode === FormMode.read) {
            this.loading = true;
            this.getAccountIfo();
        }
    }

    deleteAccount() {
        this.deleteModal.showModal(0);
    }

    onAccountDelete(event) {
        this.deleteModal.hideModal();

        this.accountService.deleteAccount(this.account.id).subscribe(appUser => {
            // todo: refresh grid
            this.router.navigate(['Accounts/Overview']);
        });
        console.log(event);
    }

    togglePlatform(platform) {
        platform.isUsed = !platform.isUsed;
    }

    updateAccountPlatforms(event) {
        this.loadingAccountPlatforms = true;
        this.cd.markForCheck();

        this.accountPlatformService.getAccountPlatformByAccountId(this.account.id).subscribe(result => {
            this.accountPlatforms = result;
            this.loadingAccountPlatforms = false;
            this.cd.markForCheck();
        });
    }

    createAccountPlatforms(accountPlatforms: AppAccountPlatform[]) {

        const createAccountPlatformCall = ((request: AppAccountPlatform) =>
            this.accountPlatformService.createNewAccountPlatform(
                this.account.id,
                request.platformName,
                request.username,
                request.password,
                request.platformType,
                request.token,
                request.url
            )
        );

        forkJoin(accountPlatforms
            .filter(ap => ap.platformType)
            .map(createAccountPlatformCall)).subscribe(createResult => {

                this.loadingAccountPlatforms = true;
                this.hasError = [];
                this.hasSuccess = [];

                createResult.forEach(accountPlatform => {
                    if (accountPlatform.completed) {
                        this.hasSuccess.push(accountPlatform.data.message);
                    } else {
                        this.hasError.push(accountPlatform.data);
                    }
                });

                this.accountPlatformService.getAccountPlatformByAccountId(this.account.id).subscribe(accountPlatformResult => {
                    this.accountPlatforms = accountPlatformResult;
                    this.loadingAccountPlatforms = false;

                    console.log('Set formmode');
                    this.formModeAccountPlatforms = FormMode.read;
                    this.cd.markForCheck();
                });
            });

    }
}
