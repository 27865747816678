import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';

import { DeviceService } from '../../services/device/device.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Device } from 'app/models/device.model';
import { FhChartService } from '../../services/charts/charts.service';
import { Map } from 'leaflet';
import { UserRoleService } from '../../services/users/userRole.service';
import { User, UserRole } from '../../models/user.model';
import { PermissionsBitmask } from '../../common/enums';
import { createMask, arrayFromMask } from 'app/services/common/functions.service';
import { getMapProviders, createMapOptions } from 'app/common/leafletGlobals';

declare var L;

@Component({
    selector: 'fh-user-role-details',
    templateUrl: 'userRoleDetails.template.html'
})

export class UserRoleDetailsViewComponent implements OnInit {

    map: Map;
    options;
    layersControl;
    selectedRow: any;
    selected: any;
    theTrips = [];
    permissionEnum = [];
    timeoutHandler;
    chartFuel: any;
    sensors;
    sub;
    userRole: UserRole;
    loading = false;
    loadingSensors = false;
    permissions: { name: string; value: any; isEnabled: boolean; }[];
    permissionBitmask = [];
    theSaveBitmask: number;
    maps: { 'mapTiler': any; 'Google': any; 'Hybrid': any; };

    constructor(private route: ActivatedRoute, private router: Router, private userRoleService: UserRoleService) {
        this.userRole = null;
        this.sensors = [];
        this.theTrips = [];
    }

    save() {
        const theSaveBitmask = createMask(this.permissions);
        this.theSaveBitmask = theSaveBitmask;
    }

    ngOnInit() {

        this.permissions = Object.keys(PermissionsBitmask)
            .filter(k => typeof PermissionsBitmask[k] === 'number')
            .map(n => ({ name: n, value: PermissionsBitmask[n], isEnabled: false }))

        this.userRole = new UserRole;
        this.loading = true;

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];
            this.userRoleService.getUserRoleById(id).subscribe(userRole => {
                this.userRole = userRole;
                this.loading = false;

                this.permissions = arrayFromMask(userRole.permissions, this.permissions);

                if (this.userRole == null) {
                    this.router.navigate(['/Users/Overview'])
                }
            });
        });

        this.initMap();
    }

    // Leaflet
    initMap() {
        this.maps = getMapProviders(L);
          const initMaps = [];
        initMaps.push(Object.values(this.maps)[0]);
        this.options = createMapOptions(L, initMaps);
    }

    onMapReady(map: Map) {
        this.map = map;

        const overlayMaps = {};

        L.control.layers(this.maps, overlayMaps, { position: 'topleft' }).addTo(map);

        this.map.invalidateSize();
    }

}
