import { Component, OnInit, AfterViewInit } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { DeviceTypeService } from '../../services/devicetypes/devicetypes.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceType } from 'app/models/devicetype.model';

@Component({
    selector: 'fh-device-type-calculation-settings',
    templateUrl: 'deviceTypeCalculationSettings.template.html'
})
export class DeviceTypeCalculationSettingsViewComponent implements OnInit {
    sub;
    deviceType: DeviceType;

    constructor(private http: HttpClient, private deviceTypeService: DeviceTypeService, private route: ActivatedRoute, private router: Router) {
        this.deviceType = null;
    }

    ngOnInit() {
        this.deviceType = new DeviceType;
        this.deviceType.id = ''

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];
            this.deviceTypeService.getDeviceTypeById(id).subscribe(deviceType => {
                this.deviceType = deviceType;

                if (this.deviceType == null) {
                    this.router.navigate(['/DeviceTypes/Overview'])
                }
            });
        });
    }
}
