<div class="ibox-content slick" style="position:relative">
    <table style="width:100%" class="table table-hover nowrap dtr-inline no-footer">
        <tbody *ngIf="!asset">
            <tr>
                <td colspan="4">
                    {{ 'general.noWaslAsset' | translate}}
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="asset">
            <tr>
                <td style="width:50%">
                    {{ 'general.waslReference' | translate}}
                </td>
                <td colspan="3" style="width:50%">
                    <input type="text" readonly class="form-control table-inline"
                        [value]="(asset.properties.wasl.referenceKey ? 'general.true' : 'general.false') | translate">
                </td>
            </tr>
            <tr>
                <td style="width:50%">
                    {{ 'general.plateType' | translate}}
                    <span class="error" *ngIf="formMode != 1 && !asset.properties.wasl.plateType">*</span>
                </td>
                <td colspan="3" style="width:50%">
                    <ng-container *ngIf="asset.properties.wasl.referenceKey || formMode == 1; else editPlate">
                        <input type="text" readonly class="form-control table-inline"
                            [value]="getPlateType(asset.properties.wasl.plateType) | translate">
                    </ng-container>
                    <ng-template #editPlate>
                        <select required class="form-control table-inline" name="plateType" #plateType="ngModel"
                            [(ngModel)]="asset.properties.wasl.plateType" style="width:100%">
                            <option [ngValue]="undefined"> {{ 'enums.plateType.0' | translate}}</option>
                            <option [ngValue]="1"> {{ 'enums.plateType.1' | translate}}</option>
                            <option [ngValue]="2"> {{ 'enums.plateType.2' | translate}}</option>
                            <option [ngValue]="3"> {{ 'enums.plateType.3' | translate}}</option>
                            <option [ngValue]="4"> {{ 'enums.plateType.4' | translate}}</option>
                            <option [ngValue]="5"> {{ 'enums.plateType.5' | translate}}</option>
                            <option [ngValue]="6"> {{ 'enums.plateType.6' | translate}}</option>
                            <option [ngValue]="7"> {{ 'enums.plateType.7' | translate}}</option>
                            <option [ngValue]="8"> {{ 'enums.plateType.8' | translate}}</option>
                            <option [ngValue]="9"> {{ 'enums.plateType.9' | translate}}</option>
                            <option [ngValue]="10"> {{ 'enums.plateType.10' | translate}}</option>
                            <option [ngValue]="11"> {{ 'enums.plateType.11' | translate}}</option>
                        </select>
                    </ng-template>
                </td>
            </tr>
            <tr>
                <td style="width:50%">
                    {{ 'general.sequenceNumber' | translate}}
                    <span class="error" *ngIf="sequenceNumber.invalid && formMode != 1">*</span>
                </td>
                <td colspan="3" style="width:50%">
                    <input type="text" [readonly]="asset.properties.wasl.referenceKey || formMode == 1"
                        name="sequenceNumber" class="form-control table-inline"
                        [(ngModel)]="asset.properties.wasl.sequenceNumber" required #sequenceNumber="ngModel">
                </td>
            </tr>
            <tr>
                <td style="width:50%">
                    {{ 'general.plateNo' | translate}}
                    <span class="error" *ngIf="plateNo.invalid && formMode != 1">*</span>
                </td>
                <td colspan="3" style="width:50%">
                    <input type="text" [readonly]="asset.properties.wasl.referenceKey || formMode == 1" name="plateNo"
                        class="form-control table-inline" [(ngModel)]="asset.properties.wasl.vehiclePlateNumber"
                        required #plateNo="ngModel">
                </td>
            </tr>
            <tr>
                <td style="width:50%">
                    {{ 'general.plate' | translate}}
                    <span class="error" *ngIf="formMode != 1 && (!asset.properties.wasl.vehiclePlateLeftLetter || !asset.properties.wasl.vehiclePlateMiddleLetter || !asset.properties.wasl.vehiclePlateRightLetter)">*</span>
                </td>
                <td colspan="1" style="width:16%">
                    <ng-container *ngIf="asset.properties.wasl.referenceKey || formMode == 1; else editLeftLetter">
                        <input type="text" readonly class="form-control table-inline"
                            [value]="asset.properties.wasl.vehiclePlateLeftLetter || ''">
                    </ng-container>
                    <ng-template #editLeftLetter>
                        <select class="form-control table-inline" name="leftLetter" #leftLetter="ngModel" required
                            [(ngModel)]="asset.properties.wasl.vehiclePlateLeftLetter" style="width:100%">
                            <option [ngValue]="undefined">-</option>
                            <option value="أ">أ (A)</option>
                            <option value="ب">ب (B)</option>
                            <option value="ح">ح (J)</option>
                            <option value="د">د (D)</option>
                            <option value="ر">ر (R)</option>
                            <option value="س">س (S)</option>
                            <option value="ص">ص (X)</option>
                            <option value="ط">ط (T)</option>
                            <option value="ع">ع (E)</option>
                            <option value="ق">ق (G)</option>
                            <option value="ك">ك (K)</option>
                            <option value="ل">ل (L)</option>
                            <option value="م">م (Z)</option>
                            <option value="ن">ن (N)</option>
                            <option value="ه">ه (H)</option>
                            <option value="و">و (U)</option>
                            <option value="ى">ى (V)</option>
                        </select>
                    </ng-template>
                </td>
                <td colspan="1" style="width:16%">
                    <ng-container *ngIf="asset.properties.wasl.referenceKey || formMode == 1; else editMiddleLetter">
                        <input type="text" readonly class="form-control table-inline"
                            [value]="asset.properties.wasl.vehiclePlateMiddleLetter || ''">
                    </ng-container>
                    <ng-template #editMiddleLetter>
                        <select class="form-control table-inline" name="middleLetter" #middleLetter="ngModel" required
                            [(ngModel)]="asset.properties.wasl.vehiclePlateMiddleLetter" style="width:100%">
                            <option [ngValue]="undefined">-</option>
                            <option value="أ">أ (A)</option>
                            <option value="ب">ب (B)</option>
                            <option value="ح">ح (J)</option>
                            <option value="د">د (D)</option>
                            <option value="ر">ر (R)</option>
                            <option value="س">س (S)</option>
                            <option value="ص">ص (X)</option>
                            <option value="ط">ط (T)</option>
                            <option value="ع">ع (E)</option>
                            <option value="ق">ق (G)</option>
                            <option value="ك">ك (K)</option>
                            <option value="ل">ل (L)</option>
                            <option value="م">م (Z)</option>
                            <option value="ن">ن (N)</option>
                            <option value="ه">ه (H)</option>
                            <option value="و">و (U)</option>
                            <option value="ى">ى (V)</option>
                        </select>
                    </ng-template>
                </td>
                <td colspan="1" style="width:16%">
                    <ng-container *ngIf="asset.properties.wasl.referenceKey || formMode == 1; else editRightLetter">
                        <input type="text" readonly class="form-control table-inline"
                            [value]="asset.properties.wasl.vehiclePlateRightLetter || ''">
                    </ng-container>
                    <ng-template #editRightLetter>
                        <select class="form-control table-inline" name="rightLetter" #rightLetter="ngModel" required
                            [(ngModel)]="asset.properties.wasl.vehiclePlateRightLetter" style="width:100%">
                            <option [ngValue]="undefined">-</option>
                            <option value="أ">أ (A)</option>
                            <option value="ب">ب (B)</option>
                            <option value="ح">ح (J)</option>
                            <option value="د">د (D)</option>
                            <option value="ر">ر (R)</option>
                            <option value="س">س (S)</option>
                            <option value="ص">ص (X)</option>
                            <option value="ط">ط (T)</option>
                            <option value="ع">ع (E)</option>
                            <option value="ق">ق (G)</option>
                            <option value="ك">ك (K)</option>
                            <option value="ل">ل (L)</option>
                            <option value="م">م (Z)</option>
                            <option value="ن">ن (N)</option>
                            <option value="ه">ه (H)</option>
                            <option value="و">و (U)</option>
                            <option value="ى">ى (V)</option>
                        </select>
                    </ng-template>
                </td>
            </tr>
        </tbody>
    </table>
</div>