import { Component, Input, OnInit } from '@angular/core';
import { FormMode } from 'app/common/enums';
import { ControlContainer, NgForm } from '@angular/forms';


@Component({
    selector: 'fh-calibration-details',
    templateUrl: 'calibrationDetails.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class CalibrationComponent implements OnInit {

    @Input() calibrationPoints = [];
    @Input() formMode: FormMode = FormMode.read;

    rawValue;
    actualValue;

    constructor() {
    }

    addRow(rawValue, actualValue) {
        console.log(rawValue, actualValue);
        this.calibrationPoints.push({ rawValue: +rawValue.value, actualValue: +actualValue.value });
    }

    removeRow(data) {
        const index: number = this.calibrationPoints.indexOf(data);
        if (index !== -1) {
            this.calibrationPoints.splice(index, 1);
        }
    }

    ngOnInit(): void {
    }

}

