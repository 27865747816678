import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Subject } from 'rxjs/Subject';
import { DeviceService } from '../../services/device/device.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Device } from 'app/models/device.model';
import { FhChartService } from '../../services/charts/charts.service';
import { DeviceSensorsService } from 'app/services/sensors/deviceSensors.service';
import { DeviceSensorType } from 'app/models/sensor.model';
import { TranslatorService } from 'app/services/common/translator.service';
import { TranslateService } from '@ngx-translate/core';
import { Asset } from 'app/models/asset.model';
import { AssetService } from 'app/services/asset/asset.service';
declare var L;
import { tileLayer, latLng, circle, polygon, Map, marker, icon } from 'leaflet';
import { FormMode } from 'app/common/enums';
import { getMapProviders, createMapOptions } from 'app/common/leafletGlobals';

@Component({
    selector: 'fh-device-asset',
    templateUrl: 'assetDetails.template.html',
    providers: [FhChartService]
})

export class DeviceAssetViewComponent implements OnInit, OnDestroy {

    timeoutHandler;
    chartRpm: any;
    chartSpeed: any;
    sensors;
    sub;
    asset: Asset;
    loading = false;
    sensorTypes: DeviceSensorType[];
    osm: any;
    map: any;
    options: {};
    marker: any;
    device: Device;

    formMode = FormMode.read;
    maps: { 'mapTiler': any; 'Google': any; 'Hybrid': any; };

    constructor(private chartService: FhChartService, private deviceService: DeviceService, private http: HttpClient, private assetService: AssetService, private route: ActivatedRoute, private router: Router) {
        this.asset = null;
        this.asset = null;
        this.sensors = [];
    }

    ngOnInit() {
        this.loading = true;

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.deviceService.getDeviceById(id).subscribe(device => {
                this.device = device;
                // this.showOnMap(device);
            });

            this.assetService.getAssetByDeviceId(id).subscribe(asset => {
                this.asset = asset;
                this.loading = false;
            });
        });

        this.initMap();
    }

    ngOnDestroy(): void {
        clearTimeout(this.timeoutHandler);
    }

    // Leaflet
    initMap() {
        this.maps = getMapProviders(L);
          const initMaps = [];
        initMaps.push(Object.values(this.maps)[0]);
        this.options = createMapOptions(L, initMaps);
    }

    onMapReady(map: Map) {
        this.map = map;

        const overlayMaps = {};

        L.control.layers(this.maps, overlayMaps, { position: 'topleft' }).addTo(map);

        this.map.invalidateSize();
    }

    showOnMap(asset): void {

        if (this.marker) {
            this.map.removeLayer(this.marker);
        }

        this.marker = marker([asset.lastPosition.latitude, asset.lastPosition.longitude], {
            icon: icon({
                iconSize: [25, 41],
                iconAnchor: [13, 41],
                iconUrl: 'assets/marker-icon.png',
                shadowUrl: 'assets/marker-shadow.png'
            })
        });

        this.map.addLayer(this.marker);
        this.map.flyTo([asset.lastPosition.latitude, asset.lastPosition.longitude], 10);
    }
}
