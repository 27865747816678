<div class="wrapper wrapper-content">
    <div class="row">
        <div class="col-lg-12">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-info"></i> Unsubscribe
                    </h5>
                </div>
                <div class="ibox-content slick alert-success" style="position: relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <div *ngIf="unsubscribeSuccess">
                        {{ user.email }} has successfully been removed from our mailinglist.
                    </div>
                    <div *ngIf="unsubscribeSuccess === false">
                        Something went wrong while unsubscribing. Please try again...
                    </div>
                    <div *ngIf="!unsubscribeSuccess && unsubscribeSuccess !== false">Unsubscribing...</div>
                </div>
            </div>
        </div>
    </div>
</div>