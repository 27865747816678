
import { Location } from './location.model';
import { Issue } from './issue.model';
import { DeviceType } from './devicetype.model';

export class Device {

    public id: string;
    public createdDate: Date;
    public customFields: any;
    public sensors: any;
    public lastCommunication: Date;
    public modifiedDate: Date;
    public name: string;
    public phone: string;
    public referenceId: string;
    public referenceLink: string;
    public unitId: string;
    public unitId2: string;
    public distance: number;
    public deviceType: DeviceType;
    public issues: Issue[] = [];
    public lastPosition: Location;
    public manufacturer: any;
    public manufacturerName: any;
    public deviceName: any;
    public modelName: any;
    public modelId: string;
    public issueCount: any;

    public note: any;
    public isNoteWarning: false;
    public isFlagged: boolean;

    public platformId: string;
    public platformName: string;
    public customerId: any;
    public customerName: any;
    public modelThumb: any;
    public endDate: Date;
    public isEnded: boolean;
    public accountPlatformId: any;
    public accountPlatformName: any;

    public hasIssueCalculationEnabled: boolean;
    public firmware: string;
    public deviceTypeId: string;
    public assetId: any;
    public theMarker: any;
    public speed: any;
    public hasGpsFix: any;
}

export class IntermediateDevice {
    public id: string;
    public name: string;
    public phone: string;
    public unitId: string;
    public accountPlatformId: string;
    public assetId: any;
    public deviceType: any;
}

export class DeviceDashboard {
    issuesLastMonthCount: number;
    issuesLastMonthCountOld: number;
    issuesLastWeekCount: number;
    issuesLastWeekCountOld: number;
    locationsLastMonthCount: number;
    locationsLastMonthCountOld: number;
    locationsLastWeekCount: number;
    locationsLastWeekCountOld: number;
    issuesThisWeekCount: number;
    locationsYesterdayCount: number;
    locationsYesterdayCountOld: number;
}
