<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Asset/Overview">{{'general.asset' | translate }}</a>
            </li>
            <li>
                <a href="/#/AssetDetails/Index/{{asset?.id}}">{{asset?.name}}</a>
            </li>
            <li class="active">
                <strong>{{'general.drivers' | translate }}</strong>
            </li>
        </ol>
    </div>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="">

        <div class="col-lg-12">
            <fh-error [error]="error" [success]="success"></fh-error>

            <div class="ibox float-e-margins" style="position:relative">
                <ngx-loading [show]="loadingDrivers"></ngx-loading>
                <div class="ibox-title">
                    <h5>
                        <i class="fa fa-address-card"></i> {{'general.drivers' | translate }}
                    </h5>
                </div>
                <form #userForm="ngForm" #formDir="ngForm">
                    <div class="ibox-content slick" style="position:relative" *ngIf="linkItem.id">
                        <table style="width:100%" class="table table-hover nowrap dtr-inline no-footer">
                            <tbody>
                                <tr>
                                    <td style="width:50%">
                                        {{ 'general.driver' | translate}}
                                    </td>
                                    <td colspan="3" style="width:50%">
                                        {{linkItem.name}}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width:50%">
                                        {{ 'general.from' | translate}}
                                    </td>
                                    <td style="width:25%">
                                        <input [(ngModel)]="linkItem.from" name="from" #from="ngModel" type="text"
                                            autocomplete="off" class="form-control table-inline" [bsConfig]="dpConfig"
                                            bsDatepicker>
                                    </td>
                                    <td style="width:25%" style="padding: 6px;">
                                        <timepicker class="table-inline" [(ngModel)]="fromTime" name="fromTime"
                                            [showSpinners]="false" [showMeridian]="ismeridian">
                                        </timepicker>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width:50%">
                                        {{ 'general.to' | translate}}
                                    </td>
                                    <td style="width:25%">
                                        <input [(ngModel)]="linkItem.to" name="to" #to="ngModel" type="text"
                                            autocomplete="off" class="form-control table-inline" [bsConfig]="dpConfig"
                                            (bsValueChange)="onToDate($event)" bsDatepicker>

                                    </td>
                                    <td style="display: flex; padding: 6px;">
                                        <timepicker class="table-inline" [(ngModel)]="toTime" name="toTime"
                                            [showSpinners]="false" [showMeridian]="ismeridian">
                                        </timepicker>
                                        <i *ngIf="toTime" (click)="resetToDate()"
                                            title="{{'general.clear' | translate }}"
                                            style="display: flex; align-items: center; padding-left: 15px;"
                                            class="fas fa-fw fa-times"></i>

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="ibox-footer">
                        <button class="btn btn-primary" *ngIf="!linkItem.id" (click)="showAddPopup()"><i
                                class="fas fa-fw fa-search"></i>
                            {{'general.search' | translate }}</button>

                        <button class="btn btn-primary" *ngIf="linkItem.id" (click)="saveNew()"><i
                                class="fas fa-fw fa-save"></i>
                            {{'general.assign' | translate }}</button>

                        <button class="btn btn-danger" *ngIf="linkItem.id" (click)="linkItem.id = null"><i
                                class="fas fa-fw fa-times"></i>
                            {{'general.cancel' | translate }}</button>
                    </div>
                </form>
            </div>
        </div>

        <div class="col-lg-6" *ngFor="let driver of drivers | orderBy : 'startDate':true">
            <div class="ibox float-e-margins" [class.inActive]="!driver.isActive">
                <div class="ibox-title">
                    <h5>
                        <i class="fa fa-address-card"></i> {{ driver.name }}
                        <!-- /#/DriverDetails/Index/{{driver?.id}} -->
                    </h5>
                    <span class="pull-right" *ngIf="driver">
                        <i [routerLink]="['/DriverDetails/Index/', driver.id ]"
                            style=" font-size: 1.3em; cursor: pointer;"
                            class="fas fa-fw fa-2x fa-arrow-circle-right"></i>
                    </span>
                </div>
                <div class="ibox-content slick" style="position:relative">
                    <table style="width:100%" class="table table-hover nowrap dtr-inline no-footer">
                        <tbody>
                            <tr>
                                <td>
                                    {{ 'general.phone' | translate}}
                                </td>
                                <td>
                                    {{ driver.phone }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'general.dateOfBirth' | translate}}
                                </td>
                                <td>
                                    {{ driver.dateOfBirth  | amDateFormat: 'LL'    }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'general.referenceId' | translate}}
                                </td>
                                <td>
                                    {{ driver.referenceId }}
                                </td>
                            </tr>

                            <tr *ngIf="driver.assetDriver">
                                <td>
                                    {{ 'general.startDate' | translate}}
                                </td>
                                <td>
                                    {{ driver.assetDriver?.startDate | amDateFormat: 'LLL'  }}
                                </td>
                            </tr>
                            <tr *ngIf="driver.assetDriver">
                                <td>
                                    {{ 'general.endDate' | translate}}
                                </td>
                                <td>
                                    {{ driver.assetDriver?.endDate  | amDateFormat: 'LLL' }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="ibox-footer">
                    <button class="btn btn-danger" [disabled]="!driver.isActive"
                        (click)="showModal(driver.assetDriver.id, false)"><i class="fas fa-fw fa-trash-alt"></i>
                        {{'general.unlink' | translate }}</button>

                    <button class="btn btn-danger" *ngIf="!driver.isActive"
                        (click)="showModal(driver.assetDriver.id, true)"><i class="fas fa-fw fa-trash-alt"></i>
                        {{'general.delete' | translate }}</button> 
                </div>
            </div>
        </div>
    </div>
</div>

<fh-driver-chooser #driverChooser (onSelect)="onSelect($event)"></fh-driver-chooser>

<fh-delete-modal #deleteModal (onDelete)="deleteAssetDriver($event)"></fh-delete-modal>