<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/SensorTypes/Overview">{{'general.sensorTypes' | translate }}</a>
            </li>
            <li class="active">
                <a href="/#/SensorTypes/Overview">{{'menu.add' | translate }}</a>
            </li>
        </ol>
    </div>
</div>


<form #userForm="ngForm" #formDir="ngForm">
    <div class="wrapper wrapper-content">
        <div class="row">
            <div class="col-lg-6">
                <fh-error [error]="error" [success]="success"></fh-error>

                <div class="ibox float-e-margins" style="position:relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-rss"></i> {{'general.sensorTypes' | translate }}</h5>
                    </div>
                    <fh-sensor-type-details [sensorType]="sensorType" [formMode]="formMode"></fh-sensor-type-details>
                    <fh-save-footer [valid]="!userForm.invalid" (onInsert)="onInsert()" [formMode]="formMode">
                    </fh-save-footer>
                </div>

            </div>

            <div class="col-lg-6">
                <div class="ibox float-e-margins" style="position:relative">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-calculator"></i> {{'general.calibration' | translate }}</h5>
                    </div>
                    <fh-calibration-details [calibrationPoints]="sensorType.calibrationPoints" [formMode]="formMode">
                    </fh-calibration-details>
                </div>
            </div>
        </div>
    </div>
</form>