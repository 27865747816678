import { Manufacturer } from './manufacturer.model';

export class DeviceType {

    public id: string;
    public code: string;
    public createdDate: Date;
    public modifiedDate: Date;
    public name: string;
    public referenceId: string;
    public deviceCount: number;
    public issueCount: number;
    public manufacturer: Manufacturer;
    public manufacturerName: any;
    public image: any;
    public thumbnail: any;
}
