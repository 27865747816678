import { Device } from './device.model';
import { User } from './user.model';

export class Workflow {
    public id: number;
    public name: string;
    public days: number;
    public workflowActions: WorkflowAction[];
    public workflowActionActions: WorkflowActionActions[];
}

export class Action {
    public id: any;
    public name: string;
    public isCustom: boolean;
}

export class WorkflowAction {
    public id: number;
    public action: Action;
    public days: Number = 0;
    public isStartAction: Boolean;
    public isEndAction: Boolean;
    public hasCommunication: Boolean;
    public allowAutoProcess: Boolean;
    public name: any;
    public nextActions: Action[] = [];
    public isSelected: boolean;
}

export class WorkflowActionActions {
    public id;
    public fromActionId;
    public toActionId;
}
