import { SettingsLevel } from './../common/enums';


export class CalculationSettings {
    public level: SettingsLevel;
    public id: string;
    public issueTypeSettings: IssueTypeSettings[] = [];
}

export class IssueTypeSettings {
    public issueType: number;
    public isActive: boolean;
    public source;
    public severity = 3;
    public createdDate: Date;
    public modifiedDate: Date;
    public issuetypeName: string;
    public sourceName: string;
    public deviceSensorTypeId: string;
    public lowerThreshold = 0;
    public upperThreshold = 0;
    public duration = 0;
}

export class IssueTypesWithPermissions {
    public issueType: number;
    public isActive: boolean;
    public source;
    public severity = 3;
    public createdDate: Date;
    public modifiedDate: Date;
    public issuetypeName: string;
    public sourceName: string;
    public deviceSensorTypeId: string;
    public lowerThreshold = 0;
    public upperThreshold = 0;
    public duration = 0;
    public isUsed: boolean;
    public id: any;
}






