import { Component, OnInit, AfterViewInit } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { DeviceService } from '../../services/device/device.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Device } from 'app/models/device.model';
import { AuditLogService } from '../../services/auditlog/auditlog.service';
import { AuditLog } from '../../models/auditlog.model';
import { DriverService } from 'app/services/driver/driver.service';
import { Driver } from 'app/models/driver.model';

@Component({
    selector: 'fh-driver-log',
    templateUrl: 'driverLog.template.html'
})
export class DriverLogViewComponent implements OnInit {
    driverLog: AuditLog[];
    sub;
    driver: Driver;
    loading = false;

    constructor(private http: HttpClient, private auditLogService: AuditLogService, private driverService: DriverService, private route: ActivatedRoute, private router: Router) {
        this.driver = null;
        this.driverLog = [];
    }

    ngOnInit() {
        this.loading = true;
        this.driver = new Driver;
        this.driver.id = ''

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];
            this.driverService.getDriverById(id).subscribe(driver => {
                this.driver = driver;

                if (this.driver == null) {
                    this.router.navigate(['/Drivers/Overview'])
                }

                this.auditLogService.getAuditLogByDriver(id).subscribe(deviceLog => {
                    console.log('loading done');
                    this.driverLog = deviceLog;
                    this.loading = false;
                });

            });
        });
    }
}
