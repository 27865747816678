<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Platform/Overview">{{'general.platform' | translate }}</a>
            </li>
            <li>
                <a href="/#/PlatformDetails/Index/{{accountPlatform?.id}}">{{accountPlatform?.name}}</a>
            </li>
            <li class="active">
                <strong>{{'general.platformdetails' | translate }}</strong>
            </li>
        </ol>
    </div>
</div>


<div class="wrapper wrapper-content">

    <div class="row" data-equalizer="">

        <div class="col-lg-6">
            <fh-error [error]="error" [success]="success"></fh-error>

            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fa fa-fw fa-clipboard-list"></i> {{'general.platformdetails' | translate }}
                    </h5>
                </div>
                <div class="ibox-content slick" style="padding:7px;position:relative">
                    <ngx-loading [show]="loadingCurrentToken"></ngx-loading>
                    <table style="width:100%" class="table table-hover nowrap dtr-inline no-footer">
                        <tbody>
                            <tr>
                                <td>
                                    {{ 'general.name' | translate}}
                                </td>
                                <td>
                                    {{ accountPlatform?.name }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'general.platform' | translate}}
                                </td>
                                <td>
                                    {{ accountPlatform?.platformName }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'general.url' | translate}}
                                </td>
                                <td>
                                    {{ accountPlatform?.url }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'dates.timezone' | translate}}
                                </td>
                                <td>
                                    {{ accountPlatform?.timezoneIana }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'general.token' | translate}}
                                </td>
                                <td>
                                    xxxxxxxxxxxxxx
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="ibox-footer">
                    <button class="btn btn-primary" (click)="testCurrentToken()">
                        <i class="fa fa-fw fa-bolt"></i> {{'general.testToken' | translate }}</button>
                    <div style="clear:both"></div>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fa fa-fw fa-cog"></i> {{'general.updateToken' | translate }}
                    </h5>
                </div>
                <form #userForm="ngForm" #formDir="ngForm">
                    <div class="ibox-content slick" style="padding:0px;position:relative" *ngIf="accountPlatform">
                        <ngx-loading [show]="loadingNewToken"></ngx-loading>

                        <div style="padding: 10px 10px 4px 10px;" *ngIf="!tokenIsFalse">{{'general.testTokenFirst' |
                        translate }}</div>

                        <table style="width:100%" *ngIf="tokenIsFalse" class="table table-hover nowrap dtr-inline no-footer">
                            <tbody>
                                <tr>
                                    <td>
                                        {{'general.url' | translate }} <span class="error"
                                            *ngIf="urlVar.invalid && formMode != 1">*</span>
                                    </td>
                                    <td>

                                        <input name="url" required type="text" class="form-control table-inline"
                                            minlength="4" [(ngModel)]="accountPlatform.url" #urlVar="ngModel" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {{'general.token' | translate }} <span class="error"
                                            *ngIf="tokenVar.invalid && formMode != 1">*</span>
                                    </td>
                                    <td>

                                        <textarea style="height:100px; width:100%" name="token" required type="text"
                                            class="form-control table-inline" minlength="4" [(ngModel)]="token"
                                            #tokenVar="ngModel"></textarea>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {{'general.repeatToken' | translate }} <span class="error"
                                            *ngIf="token_repeatVar.invalid && formMode != 1">*</span>
                                    </td>
                                    <td>
                                        <textarea style="height:100px; width:100%" name="token_repeat" required
                                            type="text" class="form-control table-inline" minlength="4"
                                            [(ngModel)]="token_repeat" #token_repeatVar="ngModel">
                                        </textarea>
                                    </td>
                                </tr>
                                <tr class="alert alert-danger"
                                    *ngIf="tokenVar.value !== token_repeatVar.value || (tokenVar.invalid && (tokenVar.dirty || tokenVar.touched))">
                                    <td colspan="2">
                                        <div *ngIf="tokenVar.value !== token_repeatVar.value">
                                            * Tokens do not match
                                        </div>
                                        <div *ngIf="tokenVar?.errors?.minlength">
                                            * Tokens must be at least 4 characters long.
                                        </div>
                                        <div *ngIf="tokenVar?.errors?.required">
                                            * Tokens is required.
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="ibox-footer" *ngIf="tokenIsFalse">
                        <button class="btn btn-primary" [disabled]="userForm.invalid" (click)="saveNewToken()"
                            *ngIf="newTokenTested">
                            <i class="fa fa-fw fa-save"></i> {{'general.save' | translate }}</button>
                        <button class="btn btn-primary" [disabled]="userForm.invalid" (click)="testNewToken()">
                            <i class="fa fa-fw fa-bolt"></i> {{'general.testNewToken' | translate }}</button>
                        <div style="clear:both"></div>
                    </div>
                </form>
            </div>

            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fa fa-fw fa-cog"></i> {{'general.platformoptions' | translate }}
                    </h5>
                </div>
                <div class="ibox-content slick" style="padding:0px;position:relative">
                    <table style="width:100%" class="table table-hover nowrap dtr-inline no-footer">
                        <thead>
                            <tr>
                                <th style="width:50%">
                                    Type
                                </th>
                                <th style="width:50%">
                                    Enabled
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    Enable device analytics
                                </td>
                                <td>
                                    <input type="checkbox" value="" name="" class="i-checks" checked="checked" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="ibox-footer">
                    <button class="btn btn-primary" [disabled]="true">
                        <i class="fa fa-fw fa-save"></i> {{'general.save' | translate }}</button>
                    <div style="clear:both"></div>
                </div>
            </div>
            <!-- <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-memory"></i> {{'general.kpis' | translate }}</h5>
                </div>
                <div class="ibox-content" style="min-height:100px;position:relative">
                    <ngx-loading [show]="loadingKpis"></ngx-loading>
                    <div style="width:50%; float:left; padding: 18px">
                        <h1 class="no-margins font-bold">{{ issuesThisWeek?.toLocaleString() }}</h1>
                        <small>{{'general.issues' | translate }} {{'dates.thisweek' | translate }}</small>
                    </div>
                    <div style="width:50%; float:left; padding: 18px">
                        <h1 class="no-margins font-bold">{{ issueCount?.toLocaleString() }}</h1>
                        <small>{{'general.issues' | translate }} {{'dates.thismonth' | translate }}</small>
                    </div>
                    <div style="width:50%; float:left; padding: 18px">
                        <h1 class="no-margins font-bold">{{ devicesWithIssues?.toLocaleString() }}</h1>
                        <small>{{'general.deviceswithissues' | translate }} {{'dates.thismonth' | translate }}</small>
                    </div>
                    <div style="width:50%; float:left; padding: 18px">
                        <h1 class="no-margins font-bold">{{ deviceCount?.toLocaleString() }}</h1>
                        <small>{{'general.devices' | translate }}</small>
                    </div>

                    <div style="clear:both"></div>
                </div>
            </div> -->
        </div>
    </div>
</div>