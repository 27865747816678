import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Subject } from 'rxjs/Subject';
import { DeviceService } from '../../services/device/device.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceType } from 'app/models/devicetype.model';
import { IssueService } from 'app/services/issues/issues.service';
import { Issue } from 'app/models/issue.model';
import { DeviceTypeService } from 'app/services/devicetypes/devicetypes.service';
import { IssueTypeService } from 'app/services/issuetypes/issuetypes.service';
import { IssueType } from 'app/models/issuetype.model';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import { BsLocaleService, BsDaterangepickerConfig } from 'ngx-bootstrap';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { TranslatorService } from '../../services/common/translator.service';
import { CustomerService } from 'app/services/customers/customers.service';
import { Customer } from 'app/models/customer.model';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
import { getTranslatedDropdownSettings, getDropdownSettings, getDefaultDpConfig, getGridLanguages } from 'app/common/gridhelper';
window['moment'] = Moment;
mTZ()

@Component({
    selector: 'fh-customer-issues',
    templateUrl: 'customerIssues.template.html'
})
export class CustomerIssuesViewComponent implements OnInit {
    customerId = '0';
    token: any;
    @ViewChild(DataTableDirective)
    datatableElement: DataTableDirective;
    dropdown_IssueType = '';
    loading: boolean;
    issueTypes: IssueType[];
    deviceTypes: DeviceType[];

    sub;
    issues: Issue[];
    customer: Customer;
    timezoneIana;
    selectedIssueTypes: { 'id': any; 'itemName': any; }[] = [];

    // Daterange
    public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
    today = new Date();
    daterangepickerModel: Date[];
    maxDate = new Date();

    // Grid
    dtOptions = {};
    minDate: Date;
    limitHistoryInDays: number;
    languageLoaded: boolean;
    dropdownIssueTypesSettings: any;
    issueTypesMultiselect: any[] = [];
    searchTerm: any;


    constructor(private localeService: BsLocaleService, private translatorService: TranslatorService, private authenticationService: AuthenticationService, private translateService: TranslateService, private http: HttpClient, private deviceService: DeviceService, private issueTypeService: IssueTypeService, private customerService: CustomerService, private issueService: IssueService, private route: ActivatedRoute, private router: Router) {
        this.customer = null;
        this.timezoneIana = authenticationService.getTimeZoneIana();
        this.customerId = '00000000-0000-0000-0000-000000000001';

        this.token = this.authenticationService.getAuthToken();

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];
            this.customerId = id;
            this.customerService.getCustomerById(id).subscribe(customer => {
                this.customer = customer;
                this.customerId = customer.id;

                if (this.customer == null) {
                    this.router.navigate(['/DeviceTypes/Overview'])
                }
            });
        });

        // Buuild issuetypes dropdown
        this.dropdownIssueTypesSettings = getDropdownSettings();
        this.translateService.get('general.date').subscribe(value => {
            this.dropdownIssueTypesSettings = getTranslatedDropdownSettings(this.translateService, 'allIssueTypes', true);
        });


        // Filtering
        const that = this;

        this.minDate = new Date();
        this.limitHistoryInDays = +this.authenticationService.getLimitHistoryInDays();
        this.minDate.setDate(this.minDate.getDate() - this.limitHistoryInDays);


        this.maxDate.setDate(this.today.getDate() - 31);
        this.daterangepickerModel = [this.maxDate, this.today];

        this.dpConfig = getDefaultDpConfig();

        // fetch issuetypes
        this.issueTypeService.getIssueTypes().subscribe(issueTypes => {
            this.issueTypes = issueTypes;

            this.issueTypesMultiselect = [];
            this.issueTypes.forEach(issueType => {
                this.issueTypesMultiselect.push({ 'id': issueType.id, 'itemName': issueType.name, 'category': issueType.category });
            });
        });
    }

    dateChanged(event) {
        const that = this;
        if (event !== null) {
            this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.ajax.url(that.issueService.getPagingUrl() + '?customerId=' + this.customerId + '&startDate=' + Math.round(this.daterangepickerModel[0].getTime() / 1000) + '&endDate=' + Math.round(this.daterangepickerModel[1].getTime() / 1000)).load();
            });

            this.fireFilter();
        }
    }

    fireFilter(event = null): void {

        console.log('fire update');
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {

            // Search for the issuetype
            if (this.selectedIssueTypes !== []) {
                const selectedIssueTypesString = this.selectedIssueTypes.map((i) => i.id, []).join(',');
                dtInstance.column('issueTypeId:name').search(selectedIssueTypesString);

            } else {
                dtInstance.column('issueTypeId:name').search('');
            }

            // Fire the request
            dtInstance.draw();
        });
    }

    editMode(isEditMode) {
        const toolbox = document.getElementById('grid-toolbox');

        console.log(isEditMode);
        if (isEditMode) {
            toolbox.classList.remove('hidden');
        } else {
            toolbox.classList.add('hidden');
        }
    }

    seachChanged(value) {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.search(value).draw();
        });
    }

    checkFilters() {
        // Set issuetype from get
        this.route.queryParams.subscribe(params => {
            const issueType = params['IssueType'];
            if (issueType) {
                this.selectedIssueTypes = [{ id: issueType, itemName: 'filter' }];
                this.fireFilter();
            } else {
                this.fireFilter();
            }

            const singleday = params['Singleday'];
            if (singleday) {
                this.daterangepickerModel = [new Date(singleday / 1), new Date(singleday / 1)];
                this.dateChanged(1);
            }

            const dateStart = params['DateStart'];
            const dateEnd = params['DateEnd'];
            if (dateStart && dateEnd) {
                this.daterangepickerModel = [new Date(dateStart / 1), new Date(dateEnd / 1)];
                this.dateChanged(1);
            }
        });
    }

    ngOnInit(): void {
        this.translateService.get('general.date').subscribe(value => {
            this.languageLoaded = true;
            this.initGrid();
        });
    }

    initGrid(): void {
        const that = this;

        $.fn['dataTable'].ext.search.pop();

        this.loading = true;

        console.log('get from grid');

        this.dtOptions = {
            pagingType: 'simple_numbers',
            serverSide: true,
            processing: false,
            searchDelay: 500,
            deferRender: true,
            scrollX: true,
            colReorder: { fixedColumnsLeft: 1 },
            deferLoading: 0,
            stateSave: true,
            stateSaveCallback: function (settings, data) {
                localStorage.setItem('Columns_' + that.constructor.name, JSON.stringify(data));
            },
            stateLoadCallback: function () {
                try {
                    const columnSettings = JSON.parse(localStorage.getItem('Columns_' + that.constructor.name));
                    that.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
                    return columnSettings;
                } catch (e) {
                    console.log(e);
                }
            },
            order: [[1, 'desc'], [9, 'desc']],
            ajax: {
                beforeSend: () => {
                    $('.dataTables_info').html(this.translateService.instant('grid.loadingData'));
                },
                url: that.issueService.getPagingUrl() + '?customerId=' + this.customerId + '&startDate=' + Math.round(this.daterangepickerModel[0].getTime() / 1000) + '&endDate=' + Math.round(this.daterangepickerModel[1].getTime() / 1000),
                type: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + that.token
                }
            },
            initComplete: function (settings, json) {
                that.checkFilters();
                that.loading = false;
            },
            colVis: {
                restore: this.translateService.instant('general.restore'),
                showAll: this.translateService.instant('general.showAll'),
                showNone: this.translateService.instant('general.hideAll'),
                exclude: ['id']
            },
            columns: [{
                data: 'id',
                width: '20',
                orderable: false,
                title: '',
                render: function (data, type, row) {
                    return '<a class=\'btn btn-primary btn-outline btn-grid\' href=\'/#/IssueDetails/Index/' + data + '\'><i class=\'fas fa-fw fa-info\'></i> <span class="hidden-sm hidden-xs">Details</span></a>';
                }
            },
            {
                data: 'issueDate',
                width: '80',
                title: this.translateService.instant('general.date'),
                render: function (data, type, row) {
                    const date = Moment.utc(data)['tz'](that.timezoneIana);
                    return date.format('YYYY-MM-DD')
                }
            },
            {
                data: 'timestamp',
                width: '80',
                title: this.translateService.instant('general.date'),
                render: function (data, type, row) {
                    return new Date(data).toLocaleDateString();
                },
                visible: false
            },
            {
                data: 'deviceName',
                title: this.translateService.instant('general.device'),
                width: '80',
                render: function (data, type, row) {
                    return data ? data : '';
                }
            },
            {
                data: 'deviceTypeName',
                title: this.translateService.instant('general.devicetype'),
                width: '80',
            },
            {
                data: 'deviceTypeName',
                title: this.translateService.instant('general.devicetype'),
                visible: false
            },
            {
                data: 'deviceTypeId',
                title: this.translateService.instant('general.issuetypeid'),
                visible: false
            },
            {
                data: 'issueType',
                title: this.translateService.instant('general.issue'),
                render: function (data, type, row) {
                    return data ? that.translatorService.translate(data, 'enums.issuetypes') : '';
                }
            },
            {
                name: 'issueTypeId',
                data: 'issueTypeId',
                title: this.translateService.instant('general.issuetypeid'),
                visible: false
            }, {
                data: 'severity',
                title: this.translateService.instant('general.severity'),
                render: function (data, type, row) {
                    return data ? that.translatorService.translate(data.toString(), 'enums.severity') : '-';
                }
            }],
            pageLength: 10,
            lengthMenu: [[10, 25, 50, -1], [10, 25, 50, this.translateService.instant('general.all')]],
            language: getGridLanguages(this.translateService),
        };
    }
}
