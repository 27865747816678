import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { tileLayer, latLng, circle, polygon, Map, marker, icon } from 'leaflet';
import { DeviceService } from '../../services/device/device.service';
import { Device } from 'app/models/device.model';
import { IssueService } from '../../services/issues/issues.service';
import { getMapProviders, createMapOptions } from 'app/common/leafletGlobals';

declare var L;
declare var HeatmapOverlay;

class Person {
    id: number;
    firstName: string;
    lastName: string;
}

class DataTablesResponse {
    data: any[];
    draw: number;
    recordsFiltered: number;
    recordsTotal: number;
}

@Component({
    selector: 'fh-devices-map',
    templateUrl: 'devicesMap.template.html'
})
export class DevicesMapViewComponent implements OnInit {
    markers: any;
    defaultMap: any;
    map: Map;
    options;
    layersControl;
    devices: Device[];

    mapData = {
        data: []
    };

    heatmapLayer = new HeatmapOverlay({
        radius: 15,
        maxOpacity: 0.8,
        scaleRadius: false,
        useLocalExtrema: false,
        latField: 'lat',
        lngField: 'lng',
        valueField: 'count'
    });
    maps: { 'mapTiler': any; 'Google': any; 'Hybrid': any; };

    constructor(private deviceService: DeviceService) {
        this.initMap();
    }


    ngOnInit() {
    }

    // Leaflet
    initMap() {
        this.maps = getMapProviders(L);
        const initMaps = [];
        initMaps.push(Object.values(this.maps)[0]);
        this.options = createMapOptions(L, initMaps);
    }

    onMapReady(map: Map) {
        this.map = map;

        const overlayMaps = {
            'Heatmap': this.heatmapLayer,
            'Markers': this.markers
        };

        L.control.layers(this.maps, overlayMaps, { position: 'topleft' }).addTo(map);

        this.map.invalidateSize();
    }

    getDevices() {
        this.deviceService.getDevices().subscribe(devices => {
            this.devices = devices;
            this.devices.forEach(device => {
                if (device.lastPosition && device.lastPosition.latitude !== 0) {

                    this.mapData.data.push({
                        lat: device.lastPosition.latitude,
                        lng: device.lastPosition.longitude,
                        count: 1
                    });

                    const theMarker = marker([device.lastPosition.latitude, device.lastPosition.longitude], {
                        icon: icon({
                            iconSize: [25, 41],
                            iconAnchor: [13, 41],
                            iconUrl: 'assets/marker-icon.png',
                            shadowUrl: 'assets/marker-shadow.png'
                        })
                    }).bindPopup(device.name);

                    this.markers.addLayer(theMarker);
                }
            });

            this.heatmapLayer.setData(this.mapData);

            const bounds = this.markers.getBounds();
            if (bounds.isValid()) {
                this.map.fitBounds(bounds);
            }
        });
    }
}
