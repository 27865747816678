import { Component, Input, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';
import { GridService } from 'app/services/grid/grid.service';


@Component({
    selector: 'fh-grid-presets',
    templateUrl: 'gridPresets.component.html',
    viewProviders: [],
})
export class GridPresetComponent implements OnInit, OnChanges {

    @Input() constructorName: any;

    presetList = [];
    preset_name = '';
    gridPresets: string;
    success: { statusText: string; success: string; };
    error: { statusText: string; error: string; };
    @Output() presetLoaded = new EventEmitter();

    constructor(private gridService: GridService) {
    }

    ngOnChanges(): void {
        if (this.constructorName) {
            console.log(this.constructorName);
            this.presetList = [];
            this.initPresets();
        }
    }

    ngOnInit(): void {
        this.initPresets();
    }

    // Saving and loading presets
    initPresets() {
        const that = this;
        this.presetList = [];
        const presets = this.gridService.getGridPresets(this.constructorName).subscribe(result => {
            that.presetList.push.apply(that.presetList, result);
        });
    }

    savePreset() {
        const that = this;
        const presetJson = localStorage.getItem('Columns_' + this.constructorName);

        this.presetList = this.presetList.filter(function (el) { return el.name !== that.preset_name; });
        this.presetList.push({ name: this.preset_name, json: presetJson });

        const presets = this.gridService.saveGridPresets(this.constructorName, this.presetList).subscribe(result => {
            that.success = {
                statusText: 'Success',
                success: `Preset ${that.preset_name} saved`
            };
        });

    }

    deletePreset(preset_name) {
        const that = this;
        this.presetList = this.presetList.filter(function (el) { return el.name !== preset_name; });
        const presets = this.gridService.saveGridPresets(this.constructorName, this.presetList).subscribe(result => {
            this.success = {
                statusText: 'Success',
                success: `Preset ${preset_name} deleted`
            };
        });
    }

    clearGridPreset() {
        this.loadGridPreset({ name: '', json: null });

        this.success = {
            statusText: 'Success',
            success: 'Grid cleared to default'
        };
    }

    loadGridPreset(preset) {
        this.preset_name = preset.name;
        this.presetLoaded.next(preset);
    }
    // End saving and loading presets
}

