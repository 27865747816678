import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Subject } from 'rxjs/Subject';
import { DeviceService } from '../../services/device/device.service';
import { tileLayer, latLng, circle, polygon, Map, marker, icon, Marker } from 'leaflet';
import { ActivatedRoute, Router } from '@angular/router';
import { Device } from 'app/models/device.model';
import { trigger, style, animate, transition } from '@angular/animations';
import { TripService } from '../../services/trip/trip.service';
import * as L from 'leaflet';
import { AssetService } from 'app/services/asset/asset.service';
import { Asset } from 'app/models/asset.model';
import { BsDaterangepickerConfig } from 'ngx-bootstrap';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { getMapProviders, createMapOptions } from 'app/common/leafletGlobals';

// Timezone
import * as moment from 'moment-timezone';
import { getDefaultDpConfig } from 'app/common/gridhelper';

@Component({
    selector: 'fh-asset-trips',
    templateUrl: 'trips.template.html',
    animations: [
        trigger(
            'enterAnimation', [
            transition(':enter', [
                // css styles at start of transition
                style({ opacity: 0 }),

                // animation and styles at end of transition
                animate('0.5s', style({ opacity: 1 }))
            ]),
        ]
        )
    ],
})
export class AssetTripsViewComponent implements OnInit, OnDestroy {
    loading: boolean;
    tripPolyLine;
    selectedTrip: any;
    osm: L.TileLayer;
    googleMaps: L.TileLayer;
    googleHybrid: L.TileLayer;
    leafletView: any;
    heatmapLayer: any;
    markers: any;
    zone: any;
    menuState: string;
    map: any;
    marker: Marker;
    options;
    timeoutHandler;
    trips: any[];
    sub;
    device: Device;
    layersControl;

    // Pagination
    totalItems = 0;
    currentPage = 1;
    smallnumPages = 0;
    assetId: any;
    asset: Asset;
    loadingTrip = false;

    // Datepicker
    public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
    to: any;
    from: any;
    daterangepickerModel: any[];
    timezoneIana: string;
    token: string;
    today: any;
    start: any;
    permissions: {};
    translateService: any;
    languageLoaded: boolean;
    error: any;
    success: any;
    maps: { 'mapTiler': any; 'Google': any; 'Hybrid': any; };
    constructor(private authenticationService: AuthenticationService, private tripService: TripService, private http: HttpClient, private assetService: AssetService, private deviceService: DeviceService, private route: ActivatedRoute, private router: Router) {

        this.timezoneIana = authenticationService.getTimeZoneIana();
        this.token = authenticationService.getAuthToken();

        this.to = moment().tz(this.authenticationService.getTimeZoneIana()).endOf('day').add(0, 'day').toDate();
        this.from = moment().tz(this.authenticationService.getTimeZoneIana()).startOf('day').add(0 - 7, 'days').toDate();

        this.daterangepickerModel = [this.from, this.to];

        this.dpConfig = getDefaultDpConfig();

        this.initMap();
    }


    dateChanged(event) {
        console.log(event);

        this.loading = true;
        this.fetchTrips();
    }



    ngOnInit() {
        this.loading = true;
        this.asset = new Asset;
        this.asset.id = ''

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];
            this.assetService.getAssetById(id).subscribe(asset => {
                this.asset = asset;

                this.fetchTrips();

                if (this.asset == null) {
                    this.router.navigate(['/Assets/Overview'])
                }
            });
        });

        this.permissions = this.authenticationService.permissions;
    }


    fetchTrips() {
        this.tripService.getTripsForAsset(this.asset.id, this.daterangepickerModel[0].getTime(), this.daterangepickerModel[1].getTime()).subscribe(trips => {
            this.trips = trips;
            this.totalItems = this.trips.length;
            this.loading = false;
        }, error => {
            this.error = 'Unable to fetch trip data';
            this.loading = false;
        })
    }

    ngOnDestroy() {
        if (this.timeoutHandler) {
            console.log('Distroy timeouthandler');
            clearInterval(this.timeoutHandler);
        }
    }
    // Map

    // Leaflet
    initMap() {
        this.maps = getMapProviders(L);
          const initMaps = [];
        initMaps.push(Object.values(this.maps)[0]);
        this.options = createMapOptions(L, initMaps);
    }

    onMapReady(map: Map) {
        this.map = map;
        const that = this;

        const overlayMaps = {};

        this.map.on('click', () => {
            this.zone.run(() => {
                this.menuState = 'out';
            });
        });

        L.control.layers(this.maps, overlayMaps, { position: 'topleft' }).addTo(map);

        // Easybuttona

        L.easyButton({
            id: 'fit map button',
            states: [{
                stateName: 'add-markers',
                icon: 'fa-expand-arrows-alt',
                title: 'Fit map',
                onClick: function (control) {
                    that.centerMap();
                }
            }]
        }).addTo(this.map);

        this.map.invalidateSize();
    }


    centerMap() {
        // do nothing
    }

    getTripDetails(trip) {

        this.loadingTrip = true;
        this.tripService.getTripDetails(trip.id, this.asset.id, new Date(trip.beginDateTime).getTime(), new Date(trip.endDateTime).getTime()).subscribe(result => {
            console.log(result);
            this.drawTrip(result);
            this.loadingTrip = false;
        });
    }

    drawTrip(trip) {
        console.log(trip);
        this.selectedTrip = trip;
        const pointList = [];

        if (this.tripPolyLine) { this.map.removeLayer(this.tripPolyLine); }

        this.selectedTrip.locations.forEach(location => {
            pointList.push(new L.LatLng(location.Latitude, location.Longitude));
        });

        this.tripPolyLine = new L.Polyline(pointList, {
            color: '#2490EA',
            weight: 4,
            opacity: 0.9,
            smoothFactor: 1
        });
        this.tripPolyLine.addTo(this.map);

        const bounds = this.tripPolyLine.getBounds();

        if (bounds.isValid()) {
            this.map.fitBounds(bounds, { padding: [15, 15] });
        }
    }


    drawLocation(location) {
        setTimeout(() => {
            if (location) {

                if (this.marker) {
                    this.map.removeLayer(this.marker);
                }

                this.marker = marker([location.latitude, location.longitude], {
                    icon: icon({
                        iconSize: [25, 41],
                        iconAnchor: [13, 41],
                        iconUrl: 'assets/marker-icon.png',
                        shadowUrl: 'assets/marker-shadow.png'
                    })
                });

                this.map.addLayer(this.marker);
                this.map.flyTo([location.latitude, location.longitude], 6);
            }
        }, 100);
    }

    // Pagination
    setPage(pageNo: number): void {
        this.currentPage = pageNo;
    }

    pageChanged(event: any): void {
        console.log('Page changed to: ' + event.page);
        console.log('Number items per page: ' + event.itemsPerPage);
    }
}
