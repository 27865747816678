
export class IssueType {

    public id: number;
    public name: string;
    public count: number;
    public description: string;
    public uom: any;
    public uomDescription: any;
    public thresholdHasBounds: any;
    public hasDuration: any;
    public category: any;
    public displayUom: string;
    public editableThresholds: boolean;
}
