<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Issues/Overview">{{'general.issues' | translate }}</a>
            </li>
            <li class="active">
                <a href="/#/Issues/Map">{{'menu.map' | translate }}</a>
            </li>
        </ol>
    </div>
</div>

<div id="mapFullContainer" class="shadow-inner">
    <ngx-loading [show]="loading"></ngx-loading>
    <div id="map" style="height: 100%; width:100%" leaflet [leafletOptions]="options" (leafletMapReady)="onMapReady($event)">
    </div>

    <div [@slideInOut]="menuState" class="slideMenu">
        <div (click)="toggleMenu()" class="slideMenuControl">
        </div>
        <div class="slideMenuDetails" style="position:relative">
            <ngx-loading [show]="loadingSidebar"></ngx-loading>
            <table style="width:100%" class="table table-hover nowrap dtr-inline no-footer">
                <tbody>
                    <tr>
                        <td>
                            {{ 'general.name' | translate}}
                        </td>
                        <td>
                            <a href="/#/IssueDetails/Index/{{selectedIssue?.id}}">{{ selectedIssue?.id }}</a>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {{ 'general.createdDate' | translate}}
                        </td>
                        <td>
                            {{ selectedIssue?.createdDate | amDateFormat: 'll' }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>