import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { AccountService } from '../../services/accounts/accounts.service';
import { UserService } from '../../services/users/user.service';
import { InventoryAccount } from '../../models/account.model';
import { AppUser, UpdatePasswordRequest } from '../../models/user.model';
import { TranslateService } from '@ngx-translate/core';
import { languages, whitelabels } from '../../common/enums';
import { IssueTypeService } from 'app/services/issuetypes/issuetypes.service';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
import { BsLocaleService } from 'ngx-bootstrap';
window['moment'] = Moment;
mTZ()

@Component({
  selector: 'fh-profile',
  templateUrl: 'profile.template.html'
})
export class ProfileComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  account: InventoryAccount;
  loading: boolean;
  user: AppUser;
  users: AppUser[];
  languages: { name: string; path: any; }[];
  currentLang;
  updateMessage: string;
  isSaved: boolean;

  oldPassword: string;
  confirmPassword: string;
  newPassword: string;
  whitelabels: { value: string; name: any; }[];

  periods: string[] = [];
  daysInTheWeek: string[] = [];
  days: number[] = [];
  quarters: string[] = [];

  chosenPeriod: string;
  chosenDayOfTheWeek: string;
  chosenDayOfTheMonth: number;
  chosenQuarter: string;

  constructor(private localeService: BsLocaleService, private translate: TranslateService, private authenticationService: AuthenticationService, private issueTypeService: IssueTypeService, private accountService: AccountService, private userService: UserService) {

    this.user = new AppUser();
    this.user.timezoneIana = 'Europe/Amsterdam'

    const userId = authenticationService.getUserId();
    this.currentLang = authenticationService.getLanguage();

    this.loading = true;

    this.userService.getUserById(userId).subscribe(user => {
      this.user = user;
      if (user.cronTabPattern) {
        this.readCronTabPattern(user.cronTabPattern);
      };
    });

    this.accountService.getAccountById('00000000-0000-0000-0000-000000000000').subscribe(account => {
      this.account = account;
      this.loading = false;

      this.userService.getUsersByAccountId(this.account.id).subscribe(result => {
        this.users = result;
      });
    });
  }

  ngOnInit(): void {

    while (this.days.length < 31) {
      this.days.push(this.days.length + 1);
    }

    this.translate.get('general.date').subscribe(value => {
      this.periods.push(this.translate.instant('general.none'));
      this.periods.push(this.translate.instant('report.weekly'));
      this.periods.push(this.translate.instant('report.monthly'));
      this.periods.push(this.translate.instant('report.quarterly'));

      this.chosenPeriod = this.periods[0];

      this.daysInTheWeek.push(this.translate.instant('dates.monday'));
      this.daysInTheWeek.push(this.translate.instant('dates.tuesday'));
      this.daysInTheWeek.push(this.translate.instant('dates.wednesday'));
      this.daysInTheWeek.push(this.translate.instant('dates.thursday'));
      this.daysInTheWeek.push(this.translate.instant('dates.friday'));
      this.daysInTheWeek.push(this.translate.instant('dates.saturday'));
      this.daysInTheWeek.push(this.translate.instant('dates.sunday'));

      this.quarters.push(this.translate.instant('report.quarter1'));
      this.quarters.push(this.translate.instant('report.quarter2'));
      this.quarters.push(this.translate.instant('report.quarter3'));
    });

    this.languages = Object.keys(languages)
      .filter(k => typeof languages[k] === 'string')
      .map(n => ({ name: n, path: languages[n] }));

    this.whitelabels = Object.keys(whitelabels)
      .filter(k => typeof whitelabels[k] === 'string')
      .map(n => ({ value: n, name: whitelabels[n] }));

  }

  readCronTabPattern(pattern: string): void {
    const splitPattern = pattern.split(' ');

    if (splitPattern.length === 3) {
      const dayOfWeekPattern = parseInt(splitPattern[0], 10);
      const dayOfMonthPattern = parseInt(splitPattern[1], 10);
      const monthPattern = splitPattern[2];

      if (!isNaN(dayOfWeekPattern) && monthPattern === '*') {
        if (dayOfWeekPattern > 0 && dayOfWeekPattern <= 7) {
          this.chosenPeriod = this.periods[1];
          this.chosenDayOfTheWeek = this.daysInTheWeek[dayOfWeekPattern - 1].toString();
        }
      } else if (!isNaN(dayOfMonthPattern) && monthPattern === '*') {
        this.chosenPeriod = this.periods[2];
        this.chosenDayOfTheMonth = dayOfMonthPattern;
      } else if (!isNaN(dayOfMonthPattern) && monthPattern !== '-' && monthPattern !== '*') {
        const quarters = monthPattern.split(',');
        const notAllNumbers = quarters.find(x => isNaN(parseInt(x, 10)));
        if (!notAllNumbers) {
          this.chosenPeriod = this.periods[3];
          this.chosenDayOfTheMonth = dayOfMonthPattern;
          this.chosenQuarter = this.quarters[parseInt(monthPattern.split(',')[0], 10) - 1];
        }
      }
    } else {
      this.chosenPeriod = this.periods[0];
    }
  }

  createCronTab(period: string): void {
    let cronTabPattern = '';
    if (period === this.periods[1]) {
      if (!this.chosenDayOfTheWeek) {
        this.chosenDayOfTheWeek = this.daysInTheWeek[0];
      }
      cronTabPattern += (this.daysInTheWeek.indexOf(this.chosenDayOfTheWeek) + 1) + ' - *';
    } else if (period === this.periods[2]) {
      if (!this.chosenDayOfTheMonth) {
        this.chosenDayOfTheMonth = this.days[0];
      }
      cronTabPattern += '- ' + this.chosenDayOfTheMonth + ' *';
    } else if (period === this.periods[3]) {
      if (!this.chosenDayOfTheMonth || !this.chosenQuarter) {
        this.chosenDayOfTheMonth = this.chosenDayOfTheMonth ? this.chosenDayOfTheMonth : this.days[0];
        this.chosenQuarter = this.chosenQuarter ? this.chosenQuarter : this.quarters[0];
      }
      cronTabPattern += '- ' + this.chosenDayOfTheMonth + ' ' + (this.quarters.indexOf(this.chosenQuarter) === 0 ? '1,4,7,10' : this.quarters.indexOf(this.chosenQuarter) === 1 ? '2,5,8,11' : '3,6,9,12');
    }
    this.user.cronTabPattern = cronTabPattern ? cronTabPattern : null;
  }

  save() {
    this.loading = true;
    this.userService.updateUserProfile(this.user).subscribe(result => {
      this.loading = false;

      this.updateMessage = this.translate.instant('general.profileUpdated');
      this.isSaved = true;
    });
  }

  savePassword() {
    if (this.newPassword === this.confirmPassword) {
      const request = new UpdatePasswordRequest();
      request.id = this.user.id;
      request.userName = this.user.name;
      request.oldPassword = this.oldPassword;
      request.newPassword = this.newPassword;
      this.userService.updateUserPassword(request).subscribe(result => {
        this.updateMessage = this.translate.instant('general.passwordUpdated');
        this.isSaved = true;
      });
    }
  }

  onChange($event, lang) {
    this.isSaved = false;

    this.user.language = lang;
    if (lang !== this.translate.currentLang) {
      this.translate.use(lang);
      Moment.locale(lang);
      this.localeService.use(lang);
    }

    this.issueTypeService.IssueTypes = [];

    // Set language to localStorage
    this.authenticationService.setLanguage(lang);
  }
}
