import { Component, OnInit } from '@angular/core';
import { AccountService } from 'app/services/accounts/accounts.service';
import { FormMode } from 'app/common/enums';
import { InventoryAccount } from 'app/models/account.model';
import { RegisterOperatingCompanyRequest } from 'app/models/wasl.model';
import { WaslService } from 'app/services/wasl/wasl.service';

@Component({
  selector: 'fh-wasl',
  templateUrl: 'wasl.template.html'
})
export class WaslViewComponent implements OnInit {

  account: InventoryAccount;

  loadingWasl = false;
  loading: boolean;
  formMode = FormMode.read;
  waslSuccess: any;
  waslError: any;

  constructor(private waslService: WaslService, private accountService: AccountService) {

    this.account = new InventoryAccount();
    this.loadingWasl = true;

    this.getAccount();
  }

  getAccount() {
    this.accountService.getAccount().subscribe(account => {
      this.account = account;
      this.loading = false;
      this.loadingWasl = false;
    });
  }

  ngOnInit(): void {
  }

  // Form

  onSave() {
    this.loadingWasl = true;

    this.accountService.updateAccount(this.account).subscribe(result => {
      this.loadingWasl = false;

      this.waslSuccess = {
        statusText: 'Success',
        success: 'Account is successfully updated'
      };

      this.waslError = null;
    }, error => {
      this.waslError = error
      this.loadingWasl = false;
    });
  }

  onInsert() {
    this.loadingWasl = true;

    setTimeout(() => {
      this.loadingWasl = false;
    }, 2000);
  }

  setFormMode(mode) {
    this.formMode = mode;
  }

  // Wasl

  register() {

    this.loadingWasl = true;

    this.waslService.registerCompany(this.account.id, this.account.properties.wasl).subscribe(result => {
      console.log(result);
      this.loadingWasl = false;
      if (result.success) {
        this.waslSuccess = {
          statusText: 'Success',
          success: result.resultCode
        };
        this.waslError = null;
        this.account.properties.wasl.referenceKey = result.result.referenceKey;

      } else {
        this.waslError = {
          statusText: 'Error',
          error: result.resultCode
        };
      }

      this.getAccount();
    }, error => {
      console.log(error);
      this.waslError = error
      this.loadingWasl = false;
    });
  }

  unRegister() {
    this.waslService.unregisterCompany(this.account.id).subscribe(result => {
      console.log(result);
      this.loadingWasl = false;
      if (result.success) {
        this.waslSuccess = {
          statusText: 'Success',
          success: result.resultCode
        };
        this.waslError = null;
        this.account.properties.wasl.referenceKey = null;
      } else {
        this.waslError = {
          statusText: 'Error',
          error: result.resultCode
        };
      }
      this.getAccount();
    }, error => {
      console.log(error);
      this.waslError = error
      this.loadingWasl = false;
    });
  }

  inquire() {
    this.loadingWasl = true;
    this.waslService.inquireCompany(this.account.id).subscribe(result => {
        console.log(result);
        this.loadingWasl = false;

        this.waslSuccess = {
            statusText: 'Result',
            success: result.result,
            json: result.json ? JSON.parse(result.json) : undefined
        };
        this.waslError = null;
    }, error => {
        this.waslError = error
        this.loadingWasl = false;
    });
}
}
