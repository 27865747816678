import { Component, OnInit, AfterViewInit } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { AuditLogService } from '../../services/auditlog/auditlog.service';
import { AuditLog } from '../../models/auditlog.model';
import { AssetService } from 'app/services/asset/asset.service';
import { AssetType } from 'app/models/assetType.model';
import { AssetTypeService } from 'app/services/asset/assetTypes.service';

@Component({
    selector: 'fh-assettype-log',
    templateUrl: 'assetTypeLog.template.html'
})
export class AssetTypeLogViewComponent implements OnInit {
    assetTypeLog: AuditLog[];
    sub;
    assetType;
    loading = false;

    constructor(private http: HttpClient, private auditLogService: AuditLogService, private assetTypeService: AssetTypeService, private route: ActivatedRoute, private router: Router) {
        this.assetType = null;
        this.assetTypeLog = [];
    }

    ngOnInit() {
        this.loading = true;
        this.assetType = new AssetType;
        this.assetType.id = ''

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];
            this.assetTypeService.getAssetTypeById(id).subscribe(assetType => {
                this.assetType = assetType;

                if (this.assetType == null) {
                    this.router.navigate(['/AssetTypes/Overview'])
                }

                this.auditLogService.getAuditLogByAssetType(id).subscribe(assetLog => {
                    console.log('loading done');

                    this.assetTypeLog = assetLog;
                    this.loading = false;
                });
            });
        });
    }
}
