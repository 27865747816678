import { Component, Input, OnInit } from '@angular/core';
import { FormMode } from 'app/common/enums';
import { ControlContainer, NgForm } from '@angular/forms';
import { InventoryAccount } from 'app/models/account.model';
import { AccountService } from 'app/services/accounts/accounts.service';


@Component({
    selector: 'fh-account-details',
    templateUrl: 'accountDetails.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class AccountDetailsComponent implements OnInit {

    @Input() account: InventoryAccount;
    @Input() formMode: FormMode = FormMode.read;
    loadingAccountTypes: boolean;
    accountTypes: any;

    constructor(private accountService: AccountService) {
        this.loadingAccountTypes = true;
        this.accountService.getAccountTypes().subscribe(data => {
            this.loadingAccountTypes = false;
            this.accountTypes = data.filter(d => d.name !== 'Unknown');
        });
    }

    ngOnInit(): void {
    }

}

