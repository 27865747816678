<div class="ibox float-e-margins" *ngIf="formMode == 1">
    <div class="ibox-title">
        <h5>
            <i class="fa fa-object-group"></i> {{'general.properties' | translate }}
        </h5>
    </div>
    <div class="ibox-content slick" style="position:relative">
        <ngx-loading [show]="loadingAssetType"></ngx-loading>
        <table style="width:100%" class="table table-hover nowrap dtr-inline no-footer">
            <tbody *ngIf="!assetTypeGroups">
                <tr>
                    <td colspan="4">
                        {{ 'general.noProperties' | translate}}
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="assetTypeGroups">
                <ng-container *ngFor="let assetTypeGroupKey of objectKeys(assetTypeGroups)">
                    <tr>
                        <td colspan="2">
                            <b>{{ ('enums.spec.' + assetTypeGroupKey) | translate}}</b>
                        </td>
                    </tr>
                    <tr *ngFor="let property of assetTypeGroups[assetTypeGroupKey]">
                        <td style="width:50%">
                            {{ ('enums.spec.' + property.Id) | translate}}
                        </td>
                        <td style="width:50%">
                            {{property.Value}}
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>

<div class="ibox float-e-margins" *ngIf="formMode != 1">
    <div class="ibox-title">
        <h5>
            <i class="fa fa-object-group"></i> {{'general.properties' | translate }}
        </h5>
    </div>
    <div class="ibox-content slick" style="position:relative">
        <table style="width:100%" class="table table-hover nowrap dtr-inline no-footer">
            <tbody>
                <tr style="background-color: #f5f5f5;">
                    <td colspan="2" style="width:auto;">
                        <select [(ngModel)]="insertId" class="form-control table-inline"
                            [ngModelOptions]="{standalone: true}">
                            <option [ngValue]="property.value.id" *ngFor="let property of assetTypeOptions | keyvalue">
                                {{ ('enums.spec.' + property.value.id ) | translate}}
                            </option>
                        </select>
                    </td>
                    <td style="width:120px;padding-top: 18px;"><button class="btn btn-primary pull-right btn-grid"
                            [disabled]="insertId == 0" (click)="addMain(insertId)"><i class="fa fa-plus"></i></button>
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="assetTypeGroups">
                <ng-container *ngFor="let assetTypeGroupKey of objectKeys(assetTypeGroups)">
                    <tr>
                        <td colspan="2">
                            <b>{{ ('enums.spec.' + assetTypeGroupKey) | translate}}</b>
                        </td>
                        <td style="width:120px;padding-top: 18px;">
                            <button class="btn btn-primary pull-right btn-grid"
                                [disabled]="assetTypeGroups[assetTypeGroupKey].length != 0"
                                (click)="removeMain(assetTypeGroupKey)"><i class="fa fa-trash"></i></button>
                            <button class="btn btn-primary pull-right btn-grid" [disabled]="assetTypeGroupKey == 0"
                                (click)="addSub(assetTypeGroupKey)"><i class="fa fa-plus"></i></button>
                        </td>
                    </tr>
                    <tr *ngFor="let property of assetTypeGroups[assetTypeGroupKey]">
                        <td style="width:auto;">
                            <select [(ngModel)]="property.Id" class="form-control table-inline"
                                [ngModelOptions]="{standalone: true}">
                                <option [ngValue]="0"> {{ ('enums.spec.0') | translate}}</option>
                                <option [ngValue]="property.id"
                                    *ngFor="let property of getOptionsOnValue(assetTypeGroupKey)">
                                    {{ ('enums.spec.' + property.id ) | translate}}</option>
                            </select> </td>
                        <td style="width:auto">
                            <input type="text" class="form-control table-inline" [ngModelOptions]="{standalone: true}"
                                [(ngModel)]="property.Value">

                        </td>
                        <td style="width:120px; padding-top: 18px;">
                            <button class="btn btn-primary pull-right btn-grid"
                                (click)="removeSub(assetTypeGroupKey, property)"><i class="fa fa-trash"></i></button>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>