<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/DeviceTypes/Overview">{{'general.devicetypes' | translate }}</a>
            </li>
            <li class="active">
                <a href="/#/DeviceTypes/Overview">{{'menu.devicetypeoverview' | translate }}</a>
            </li>
        </ol>
    </div>
</div>

<div class="wrapper wrapper-content">
    <div class="row">
        <div class="col-lg-12">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-satellite-dish"></i> {{'menu.devicetypeoverview' | translate }}</h5>
                  <iboxseach [searchTerm]="searchTerm" (seachChanged)="seachChanged($event)"></iboxseach>
                </div>
                <div class="ibox-content slick" style="position: relative" *ngIf="languageLoaded">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <table style="width:100%; min-height:50px; padding-bottom: 0px;" datatable [dtOptions]="dtOptions"
                        class="table table-hover nowrap margin-bottom-0 dataTable dtr-inline">
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>