import { Component, OnDestroy, OnInit, ChangeDetectorRef, ViewChild, } from '@angular/core';
import { DeviceService } from '../../services/device/device.service';
import { IssueService } from '../../services/issues/issues.service';
import { Issue } from 'app/models/issue.model';
import { Subject } from 'rxjs/Subject';

import { Chart } from 'angular-highcharts';

import { DeviceTypeService } from 'app/services/devicetypes/devicetypes.service';
import { IssueTypeService } from 'app/services/issuetypes/issuetypes.service';
import { DeviceType } from 'app/models/devicetype.model';
import { IssueType } from 'app/models/issuetype.model';
import { DataTableDirective } from 'angular-datatables';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { HttpHeaders } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { TranslatorService } from '../../services/common/translator.service';
import { ActivatedRoute } from '@angular/router';

import { BsDatepickerConfig, BsLocaleService, BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import { Severity } from '../../common/enums';
import { CustomerService } from 'app/services/customers/customers.service';
import { Customer } from 'app/models/customer.model';
import { Device } from 'app/models/device.model';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
import { formatViolationThreshold, getUTCStartOfDayDateTimeFromTimezone } from 'app/services/common/functions.service';
import { trigger, transition, animate, style, state, group } from '@angular/animations'
import { getGridLanguages, getGridButtons, getDropdownSettings, getTranslatedDropdownSettings, getDefaultDpConfig, mapMultiDropdown } from 'app/common/gridhelper';
import { Observable } from 'rxjs/Observable';
window['moment'] = Moment;
mTZ()

@Component({
  selector: 'fh-issues',
  templateUrl: 'issues.template.html',
  styleUrls: ['issues.template.css'],
  animations: [
    trigger('slideInOut', [
      state('in', style({ height: '*', opacity: 0.5 })),
      transition(':leave', [
        style({ height: '*', opacity: 1 }),
        group([
          animate(300, style({ height: 0 })),
          animate('200ms ease-in-out', style({ 'opacity': '0.5' })),
          animate('200ms ease-in', style({ transform: 'translateY(-100%)' }))
        ])
      ]),
      transition(':enter', [
        style({ height: '0', opacity: 0.5 }),
        group([
          animate(300, style({ height: '*' })),
          style({ transform: 'translateY(-100%)' }),
          animate('400ms ease-in-out', style({ 'opacity': '1' })),
          animate('200ms ease-in', style({ transform: 'translateY(0%)' }))
        ])
      ])
    ])
  ]
})
export class IssuesViewComponent implements OnInit, OnDestroy {
  tableIds: any[];
  token: string;
  countrySelect = false;
  languageLoaded = false;

  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;

  dropdown_CustomerName = '';
  dropdown_IssueType = '';
  dropdown_DeviceType = '';
  dropdown_Severity = '';
  dropdown_Device = '';

  // Daterange
  public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
  today = new Date();
  daterangepickerModel: Date[];
  maxDate = new Date();

  severities = [];

  dtOptions = {};
  loading = false;

  min: number;
  max: number;

  issueTypes: IssueType[];
  deviceTypes: DeviceType[];
  limitHistoryInDays: number;
  issueTypesWithCategories: { category: string, types: IssueType[] }[];

  minDate: Date;
  customersMultiselect: any[] = [];
  timezoneIana: string;
  devices: Device[] = [];
  dropdown_DeviceName: any;
  dropdownSettings: any;
  devicesMultiselect: any[] = [];
  selectedDeviceItems: { 'id': any; 'itemName': any; }[] = [];
  selectedIssueTypes: { 'id': any; 'itemName': any; }[] = [];
  dropdownIssueTypesSettings: any;
  issueTypesMultiselect = [];
  dropdown_CustomerNameSettings: any;
  selectedCustomers = [];

  dropdownSettingsDeviceTypes: any;
  selectedDeviceTypes: any[] = [];
  deviceTypesMultiselect: any[] = [];
  searchTerm: any;

  showFilter = true;
  constructorName = this.constructor.name;

  constructor(private localeService: BsLocaleService, private customerService: CustomerService, private route: ActivatedRoute, private translatorService: TranslatorService, private translateService: TranslateService, private cd: ChangeDetectorRef, private issueTypeService: IssueTypeService, private authenticationService: AuthenticationService, private issueService: IssueService, private deviceService: DeviceService, private deviceTypeService: DeviceTypeService) {
    const that = this;

    this.timezoneIana = authenticationService.getTimeZoneIana();

    this.token = this.authenticationService.getAuthToken();

    this.minDate = new Date();
    this.limitHistoryInDays = +this.authenticationService.getLimitHistoryInDays();
    this.minDate.setDate(this.minDate.getDate() - this.limitHistoryInDays);
    this.maxDate.setDate(this.today.getDate() - 14);
    this.daterangepickerModel = [this.maxDate, this.today];

    this.dpConfig = getDefaultDpConfig();

    // Map severities from enum
    this.severities = Object.keys(Severity)
      .filter(k => typeof Severity[k] === 'number')
      .map(n => ({ name: n, translated: this.translatorService.translate(Severity[n].toString(), 'enums.severity'), value: Severity[n] }));


    // fetch data
    Observable.forkJoin(
      this.issueTypeService.getIssueTypes(),
      this.deviceTypeService.getDeviceTypes(),
      this.customerService.getCustomers(),
      this.deviceService.getDevices()
    ).subscribe(
      data => {
        this.issueTypes = data[0];
        this.deviceTypes = data[1];
        this.devices = data[3];

        this.issueTypesWithCategories = [];
        this.issueTypes.forEach(type => {
          if (this.issueTypesWithCategories.find(x => x.category === type.category)) {
            this.issueTypesWithCategories.find(x => x.category === type.category).types.push(type)
          } else {
            this.issueTypesWithCategories.push({ category: type.category, types: [type] })
          }

          this.issueTypesMultiselect = [];
          this.issueTypes.forEach(issueType => {
            this.issueTypesMultiselect.push({ 'id': issueType.id, 'itemName': issueType.name, 'category': issueType.category });
          });
        });

        this.deviceTypesMultiselect = [];
        this.deviceTypes.forEach(deviceType => {
          this.deviceTypesMultiselect.push({ 'id': deviceType.id, 'itemName': deviceType.name, 'category': deviceType.manufacturerName });
        });

        this.customersMultiselect = mapMultiDropdown(data[2].filter(x => x.count > 0));

        this.devices.forEach(device => {
          this.devicesMultiselect.push({ 'id': device.id, 'itemName': device.name });
        });

        this.languageLoaded = true;
        this.initGrid();
      },
      err => console.error(err)
    );
  }

  checkFilters() {
    console.log('Checking filters');

    // Set custom filters from grid save
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {

      console.log(dtInstance.column('customerName:name').search());

      const selectedCustomers = dtInstance.column('customerName:name').search().split(',');
      this.selectedCustomers = this.customersMultiselect.filter(x => selectedCustomers.indexOf(x.itemName.toString()) !== -1);

      const selectedDeviceTypes = dtInstance.column('deviceTypeId:name').search().split(',');
      this.selectedDeviceTypes = this.deviceTypesMultiselect.filter(x => selectedDeviceTypes.indexOf(x.id.toString()) !== -1);

      const selectedIssueTypes = dtInstance.column('issueTypeId:name').search().split(',');
      this.selectedIssueTypes = this.issueTypesMultiselect.filter(x => selectedIssueTypes.indexOf(x.id.toString()) !== -1);


      const selectedDeviceId = dtInstance.column('deviceId:name').search().split(',');
      this.selectedDeviceItems = this.devicesMultiselect.filter(x => selectedDeviceId.indexOf(x.id.toString()) !== -1);
      // make sure all is updated
      this.fireFilter(1);
    });
  }

  checkRoute() {
    // Set issuetype from get
    this.route.queryParams.subscribe(params => {
      const issueType = params['issueType'];
      if (issueType) {
        this.selectedIssueTypes = [{ id: issueType, itemName: 'filter' }];
        this.fireFilter(1);
      } else {
        this.fireFilter(1);
      }

      const customer = params['customer'];
      if (customer) {
        this.selectedCustomers = [customer];
        this.fireFilter(1);
      } else {
        this.fireFilter(1);
      }

      const deviceName = params['deviceName'];
      const deviceId = params['deviceId'];

      if (deviceId) {
        this.selectedDeviceItems = [{ 'id': deviceId, 'itemName': deviceName }];
      } else {
        this.selectedDeviceItems = [];
      }

      if (deviceId && this.selectedDeviceItems[0]) {
        this.dropdown_Device = this.selectedDeviceItems[0].id;
        console.log(this.dropdown_Device);
        this.fireFilter(1);
      } else {
        this.fireFilter(1);
      }

      const singleday = params['Singleday'];
      if (singleday) {
        this.daterangepickerModel = [new Date(singleday / 1), new Date(singleday / 1)];
        this.dateChanged(1);
      }

      const dateStart = params['DateStart'];
      const dateEnd = params['DateEnd'];
      if (dateStart && dateEnd) {

        this.daterangepickerModel = [new Date(dateStart / 1), new Date(dateEnd / 1)];
        this.dateChanged(1);
      }
    });
  }

  clearSelectedDevice() {
    this.selectedDeviceItems = [];
    this.fireFilter(1);
  }

  columnChooseMode(isColumnChooseMode) {
    const toolbox = document.getElementById('grid-column-chooser');

    console.log(isColumnChooseMode);
    if (isColumnChooseMode) {
      toolbox.classList.remove('hidden');
    } else {
      toolbox.classList.add('hidden');
    }
  }

  seachChanged(value) {
    console.log('Search changed');
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.search(value).draw();
    });
  }

  fireFilter(event): void {

    if (event != null) {
      console.log('Fire update');
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {

        // Search for the devicetype
        if (this.selectedDeviceTypes !== []) {
          const selectedDeviceTypesString = this.selectedDeviceTypes.map((i) => i.id, []).join(',');
          dtInstance.column('deviceTypeId:name').search(selectedDeviceTypesString);
        } else {
          dtInstance.column('deviceTypeId:name').search('');
        }

        // Search for the issuetype
        if (this.selectedIssueTypes !== []) {
          const selectedIssueTypesString = this.selectedIssueTypes.map((i) => i.id, []).join(',');
          dtInstance.column('issueTypeId:name').search(selectedIssueTypesString);

        } else {
          dtInstance.column('issueTypeId:name').search('');
        }

        // Search for the customer
        if (this.selectedCustomers !== []) {
          const selectedCustomersString = this.selectedCustomers.map((i) => i.itemName, []).join(',');
          dtInstance.column('customerName:name').search(selectedCustomersString);
        } else {
          dtInstance.column('customerName:name').search('');
        }

        // Search for the severity
        if (this.dropdown_Severity !== '') {
          dtInstance.column('severity:name').search(this.dropdown_Severity);
        } else {
          dtInstance.column('severity:name').search('');
        }


        // Search for the severity
        if (this.selectedDeviceItems.length > 0) {
          dtInstance.column('deviceId:name').search(this.selectedDeviceItems[0].id);
        } else {
          dtInstance.column('deviceId:name').search('');
        }

        // Fire the request
        dtInstance.draw();
        // dtInstance.page( 4 ).draw( 'page' );
      });
    }
  }

  ngOnDestroy(): void {
    // We remove the last function in the global ext search array so we do not add the fn each time the component is drawn
    // /!\ This is not the ideal solution as other components may add other search function in this array, so be careful when
    // handling this global variable
    $.fn['dataTable'].ext.search.pop();

    // Had to reset the array...
    $.fn['dataTable'].ext.search = [];
  }

  dateChanged(event) {

    const that = this;
    if (event !== null && this.datatableElement.dtInstance !== null) {

      console.log('Changed date... new database query');
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.url(that.issueService.getPagingUrl() + '?startDate=' + Math.round(this.daterangepickerModel[0].getTime() / 1000) + '&endDate=' + Math.round(this.daterangepickerModel[1].getTime() / 1000)).load();
      });

      this.fireFilter(null);
    }
  }

  clearSelectedCustomers() {
    this.selectedCustomers = [];
    this.fireFilter(1);
  }

  clearSelectedDeviceTypes() {
    this.selectedDeviceTypes = [];
    this.fireFilter(1);
  }

  clearSelectedIssueTypes() {
    this.selectedIssueTypes = [];
    this.fireFilter(1);
  }

  ngOnInit(): void {

    this.dropdownSettingsDeviceTypes = getDropdownSettings();
    this.dropdown_CustomerNameSettings = getDropdownSettings();
    this.dropdownSettings = getDropdownSettings(false);
    this.dropdownIssueTypesSettings = getDropdownSettings();

    this.translateService.get('general.date').subscribe(value => {

      this.dropdownSettingsDeviceTypes = getTranslatedDropdownSettings(this.translateService, 'allDeviceTypes', true);
      this.dropdown_CustomerNameSettings = getTranslatedDropdownSettings(this.translateService, 'allCustomers', false);
      this.dropdownIssueTypesSettings = getTranslatedDropdownSettings(this.translateService, 'allIssueTypes', true);

      this.dropdownSettings = {
        singleSelection: true,
        text: this.translateService.instant('general.allDevices'),
        selectAllText: this.translateService.instant('general.selectAll'),
        unSelectAllText: this.translateService.instant('general.deSelectAll'),
        searchPlaceholderText: this.translateService.instant('general.search'),
        noDataLabel: this.translateService.instant('general.noDataFound'),
        classes: 'form-control multiselector_fix',
        enableSearchFilter: true,
        lazyLoading: true,
        badgeShowLimit: 20,
        showCheckbox: false,
      };
    });
  };

  changeFlag(id: string, isFlagged: boolean): void {
    const device = this.devices.find((d) => { return d.id === id });
    device.isFlagged = isFlagged;

    this.deviceService.saveFlagged(device).subscribe(() => {
      this.issueService.resetIssueCache().subscribe(() => { });
    });
  }

  initGrid() {

    const that = this;

    $.fn['dataTable'].ext.search.pop();

    this.loading = true;

    const commonExportOptions = {
      modifier: {
        page: 'all',
        search: 'none'
      },
      columns: ':visible[tabindex]'
    };

    this.dtOptions = {
      buttons: getGridButtons(commonExportOptions, 'issue_overview', this.translateService.instant('menu.issueoverview')),
      pagingType: 'simple_numbers',
      serverSide: true,
      processing: false,
      searchDelay: 500,
      deferRender: true,
      scrollX: true,
      colReorder: { fixedColumnsLeft: 1 },
      deferLoading: 0,
      stateSave: true,
      stateSaveCallback: function (settings, data) {
        localStorage.setItem('Columns_' + that.constructor.name, JSON.stringify(data));
      },
      stateLoadCallback: function () {
        try {
          const columnSettings = JSON.parse(localStorage.getItem('Columns_' + that.constructor.name));
          that.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
          return columnSettings;
        } catch (e) {
          console.log(e);
        }
      },
      order: [[1, 'desc'], [10, 'desc']],
      ajax: {
        beforeSend: () => {
          $('.dataTables_info').html(this.translateService.instant('grid.loadingData'));
        },
        url: that.issueService.getPagingUrl() +
          '?startDate=' +
          Math.round(getUTCStartOfDayDateTimeFromTimezone(this.daterangepickerModel[0], this.authenticationService.getTimeZoneIana()).getTime() / 1000) +
          '&endDate=' +
          Math.round(getUTCStartOfDayDateTimeFromTimezone(this.daterangepickerModel[1], this.authenticationService.getTimeZoneIana()).getTime() / 1000),
        type: 'POST',
        headers: {
          'Authorization': 'Bearer ' + that.token
        }
      },
      initComplete: function (settings, json) {
        that.tableIds = [];
        that.checkFilters();
        that.checkRoute();
        that.loading = false;
      },
      colVis: {
        restore: this.translateService.instant('general.restore'),
        showAll: this.translateService.instant('general.showAll'),
        showNone: this.translateService.instant('general.hideAll'),
        exclude: ['id', 'issueDate', 'timestamp', 'deviceName', 'deviceId', 'deviceTypeId', 'issueTypeId']
      },
      columns: [{
        name: 'id',
        data: 'id',
        width: '20',
        orderable: false,
        title: '',
        render: function (data, type, row) {
          return '<a class=\'btn btn-primary btn-outline btn-grid\' href=\'/#/IssueDetails/Index/' + data + '\'><i class=\'fas fa-fw fa-info\'></i> <span class="hidden-sm hidden-xs">Details</span></a>';
        },
      }, {
        name: 'issueDate',
        data: 'issueDate',
        width: '80',
        title: this.translateService.instant('general.date'),
        render: function (data, type, row) {
          const date = Moment.utc(data)['tz'](that.timezoneIana);
          return date.format('lll')
        },

      }, {
        name: 'timestamp',
        data: 'timestamp',
        width: '80',
        title: this.translateService.instant('general.date'),
        render: function (data, type, row) {
          return new Date(data).toLocaleDateString();
        },
        visible: false,
      }, {
        name: 'deviceName',
        data: 'deviceName',
        title: this.translateService.instant('general.device'),
        width: '80',
        render: function (data, type, row) {
          return data ? data : '';
        }
      }, {
        name: 'deviceId',
        data: 'deviceId',
        title: this.translateService.instant('general.device'),
        visible: false,
      }, {
        name: 'deviceTypeName',
        data: 'deviceTypeName',
        title: this.translateService.instant('general.devicetype'),
        width: '80',
      }, {
        name: 'deviceTypeId',
        data: 'deviceTypeId',
        title: this.translateService.instant('general.issuetypeid'),
        visible: false,

      }, {
        name: 'issueType',
        data: 'issueType',
        title: this.translateService.instant('general.issue'),
        render: function (data, type, row) {
          return data ? that.translatorService.translate(data, 'enums.issuetypes') : '';
        }
      }, {
        name: 'issueTypeId',
        data: 'issueTypeId',
        title: this.translateService.instant('general.issuetypeid'),
        visible: false,

      }, {
        name: 'customerName',
        data: 'customerName',
        title: this.translateService.instant('general.customer'),
        render: function (data, type, row) {
          return data ? data.toString() : '-';
        }
      }, {
        name: 'severity',
        data: 'severity',
        title: this.translateService.instant('general.severity'),
        render: function (data, type, row) {
          return data ? that.translatorService.translate(data.toString(), 'enums.severity') : '-';
        }
      }, {
        name: 'violationLowerThreshold',
        data: 'violationLowerThreshold',
        title: this.translateService.instant('general.violationThresholds'),
        render: function (data, type, row) {
          return formatViolationThreshold(that.translateService, row.issueTypeId, data);
        }
      }, {
        name: 'violationDuration',
        data: 'violationDuration',
        title: this.translateService.instant('general.duration'),
        render: function (data, type, row) {
          return data ? Moment.duration(data * 1000, 'milliseconds').humanize() : '-';
        }
      },
      {
        name: 'isDeviceFlagged',
        data: 'isDeviceFlagged',
        title: this.translateService.instant('general.isFlagged'),
        visible: true,
        width: '40',
        responsivePriority: 1,
        render: function (data, type, row) {
          if (!that.tableIds || (that.tableIds.length === 10)) {
            that.tableIds = [];
          }

          that.tableIds.push({ id: row.id, deviceId: row.deviceId });

          return '<i id="' + row.id + '-' + row.deviceId + '" class="fa-fw fa-2x fa-star ' + (data === true ? 'fas' : 'far') + '" style="cursor: pointer; font-size: 1.3em;"></i>';
        }
      }],
      pageLength: 10,
      lengthMenu: [[10, 25, 50, -1], [10, 25, 50, this.translateService.instant('general.all')]],
      language: getGridLanguages(this.translateService),
      drawCallback: () => {
        if (that.tableIds) {
          that.tableIds.forEach(row => {
            $('#' + row.id + '-' + row.deviceId).click(() => {
              const equalDevices = that.tableIds.filter(tableRow => tableRow.deviceId === row.deviceId);
              equalDevices.forEach(device => {
                const star = $('#' + device.id + '-' + device.deviceId);
                if (star.hasClass('fas')) {
                  star.removeClass('fas').addClass('far');
                } else {
                  star.removeClass('far').addClass('fas');
                }
              });
              that.changeFlag(row.deviceId, $('#' + row.id + '-' + row.deviceId).hasClass('fas'));
            });
          });
        }
      }
    };
  }
}
