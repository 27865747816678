import { Component, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { Subject } from 'rxjs/Subject';
declare var jQuery: any;

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'iboxseach',
    templateUrl: 'iboxsearch.template.html'
})
export class IboxSearchComponent implements OnChanges {

    @Input() searchTerm: string;
    @Input() showColumns: Boolean = true;
    @Output() public seachChanged: EventEmitter<any> = new EventEmitter();
    @Output() public columnsChanged: EventEmitter<any> = new EventEmitter();

    searchVar: string;
    isEditMode = false;
    search$ = new Subject<string>();

    showFiltersEnabled = true;
    showColumnsEnabled = false;

    constructor() {
        this.searchVar = '';

        this.search$
            .debounceTime(700)
            .distinctUntilChanged()
            .subscribe(search => {
                this.seachChanged.emit(search);
            });
    }

    ngOnChanges() {
        if (this.searchTerm != null) {
            this.searchVar = this.searchTerm;
        }
    }

    sumbitSearch() {
        this.seachChanged.emit(this.searchVar);
    }

    switchEditMode() {
        if (this.isEditMode === false) {
            this.isEditMode = true;
            const toolbox = $('#grid-toolbox');

            if (this.isEditMode) {
                toolbox.removeClass('hidden');

                // reset trey
                $(document).mouseup((e: any) => {
                    if (!toolbox.is(e.target) && toolbox.has(e.target).length === 0) {

                        $(this).unbind(e);

                        toolbox.addClass('hidden');
                        this.isEditMode = false;
                    }
                });
            }
        }
    }

    switchColumnChooser() {
        if (this.showColumnsEnabled === false) {
            this.showColumnsEnabled = true;
            const chooser = $('#grid-column-chooser');

            if (this.showColumnsEnabled) {
                chooser.removeClass('hidden');

                // reset trey
                $(document).mouseup((e: any) => {
                    if (!chooser.is(e.target) && chooser.has(e.target).length === 0) {

                        $(this).unbind(e);

                        chooser.addClass('hidden');
                        this.showColumnsEnabled = false;
                        this.columnsChanged.next();
                    }
                });
            }
        }
    }
}
