<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Users/Overview">{{'general.users' | translate }}</a>
            </li>
            <li>
                <a href="/#/UserDetails/Index/{{user?.id}}">{{user?.name}}</a>
            </li>
            <li class="active">
                <strong>{{'menu.details' | translate }}</strong>
            </li>
        </ol>
    </div>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="">
        <div class="col-lg-6">
            <fh-user-details-editor [user]="user" [loading]="loading" (onRoleChanged)="roleChanged($event)"></fh-user-details-editor>
        </div>
        <div class="col-lg-6">
            <div class="ibox float-e-margins" *ngIf="displayPermissions['IsAdministrator'] || isImpersonated">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-key"></i> {{'menu.permissions' | translate }}</h5>
                </div>
                <div class="ibox-content" *ngIf="theSaveBitmask">
                    <strong>Bitmask!</strong> {{ theSaveBitmask }}
                </div>
                <div class="ibox-content slick" style="position:relative">
                    <ngx-loading [show]="loadingRoles"></ngx-loading>
                    <table class="table table-hover nowrap dtr-inline no-footer" style="width:100%; margin-bottom:0px ">
                        <thead>
                            <tr>
                                <th>{{'general.permission' | translate }}</th>
                                <th>{{'general.active' | translate }}</th>
                                <!-- <th>{{'general.block' | translate }}</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let permission of filteredPermissions">
                                <td style="width:50%">
                                    {{ permission.name }}
                                </td>
                                <td style="width:25%">
                                    <input type="checkbox" value="" [disabled]="true"
                                        [(ngModel)]="permission.isEnabled">
                                </td>
                                <!-- <td style="width:25%">
                                    <input type="checkbox" value="">
                                </td> -->
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- <div class="ibox-footer">
                    <button class="btn btn-primary" (click)="save()">
                        <i class="fa fa-fw fa-save"></i> {{'general.save' | translate }}</button>
                </div> -->
            </div>

            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-tint"></i> {{'menu.map' | translate }}</h5>
                </div>
                <div class="ibox-content slick">
                    <div style="height: 250px;" leaflet [leafletOptions]="options"
                        [leafletLayersControl]="layersControl" (leafletMapReady)="onMapReady($event)">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>