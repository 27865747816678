
export class Dashboard {
  public deviceCount: number;
  public issueCount: number;
  public deviceWithIssues: number;
  public healthyCount: number;
  public deviceTypesCount: number;
  public assetTypeCount: number;
  public driverCount: number;

  public deviceCountLastPeriod: number;
  public issueCountLastPeriod: number;
  public deviceWithIssuesLastPeriod: number;
  public deviceWithIssuesMax: number;
  public healthyCountLastPeriod: number;
  public deviceTypesCountLastPeriod: number;

  public worstDevices: WorstDevices[];
  public assetCount: number;
  public trailerCount: number;
  public assetTypesUsedCount: any;
  public allDeviceCount: any;
}

export class AdminDashboard {
  public issueCount: number;
  public accountsWithoutIssues: number;
  public activeAccounts: number;
  public activeJobCount: number;
  public activeUsers: number;
  public historyJobCount: number;
}


export class WorstDevices {
  issueCount: number;
  issueTypesCount: number;
  issuesRatio: number;
  issuesWeight: number;
  maxSeverity: number;
  unit: any;
}


export class LocationCount {
  date: number;
  count: any;
}
