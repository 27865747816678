import { Component, OnDestroy, OnInit, ChangeDetectorRef, ViewChild, } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { FormMode } from 'app/common/enums';
import { Device } from 'app/models/device.model';
import { DeviceService } from 'app/services/device/device.service';

@Component({
  selector: 'fh-devices-add',
  templateUrl: 'add.template.html'
})
export class DeviceAddViewComponent {
  token: string;
  addDevice = false;
  device: Device;
  formMode = FormMode.add;
  error: any;
  loading = false;
  form: any;
  success: { statusText: string; success: any; };

  constructor(private deviceService: DeviceService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private router: Router) {
    this.device = new Device();

    this.token = this.authenticationService.getAuthToken();

  }

  onInsert() {
    this.loading = true;

    this.deviceService.createDevice(this.device).subscribe(result => {
      this.loading = false;

      this.success = {
        statusText: 'Success',
        success: 'You will be redirected to the device'
      };

      setTimeout(() => {
        this.router.navigate(['/DeviceDetails/Index/' + result.id]);
      }, 2000);

      this.deviceService.resetDeviceCache().subscribe(res => {
        console.log('Devices are cleared');
        console.log(res);
      });

    }, error => {
      this.error = error
      this.loading = false;
    });
  }
}
