import { Component, OnInit, AfterViewInit } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { DeviceService } from '../../services/device/device.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Device } from 'app/models/device.model';
import { AuditLogService } from '../../services/auditlog/auditlog.service';
import { AuditLog } from '../../models/auditlog.model';

@Component({
    selector: 'fh-device-log',
    templateUrl: 'deviceLog.template.html'
})
export class DeviceLogViewComponent implements OnInit {
    deviceLog: AuditLog[];
    sub;
    device: Device;
    loading = false;

    constructor(private http: HttpClient, private auditLogService: AuditLogService, private deviceService: DeviceService, private route: ActivatedRoute, private router: Router) {
        this.device = null;
        this.deviceLog = [];
    }

    ngOnInit() {
        this.loading = true;
        this.device = new Device;
        this.device.id = ''

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];
            this.deviceService.getDeviceById(id).subscribe(device => {
                this.device = device;

                if (this.device == null) {
                    this.router.navigate(['/Devices/Overview'])
                }

                this.auditLogService.getAuditLogByDevice(id).subscribe(deviceLog => {
                    console.log('loading done');
                    this.deviceLog = deviceLog;
                    this.loading = false;
                });

            });
        });
    }
}
