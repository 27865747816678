import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';


@Injectable()
export class GridService {

    constructor() {
    }

    getGridPresets(constructorName): Observable<any[]> {
        return of(JSON.parse(localStorage.getItem('Presets_' + constructorName)));
    }

    saveGridPresets(constructorName, presetList): Observable<any> {
        localStorage.setItem('Presets_' + constructorName, JSON.stringify(presetList));
        return of(true);
    }
}
