<div class="ibox-content slick" style="position:relative">
    <table style="width:100%" class="table table-hover nowrap dtr-inline no-footer">
        <tbody *ngIf="!driver">
            <tr>
                <td colspan="4">
                    {{ 'general.noDriver' | translate}}
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="driver">
            <tr>
                <td style="width:50%">
                    {{ 'general.name' | translate}}
                    <span class="error" *ngIf="name.invalid && formMode != 1">*</span>
                </td>
                <td colspan="2" style="width:50%">
                    <input type="text" [readonly]="formMode == 1" name="name" class="form-control table-inline"
                        [(ngModel)]="driver.name" required minlength="4" #name="ngModel">
                </td>
            </tr>
            <tr class="alert alert-danger" *ngIf="name.invalid && (name.dirty || name.touched)">
                <td colspan="3">
                    <div *ngIf="name.errors.minlength">
                        * name must be at least 4 characters long.
                    </div>
                    <div *ngIf="name.errors.required">
                        * name is required
                    </div>
                </td>
            </tr>
            <tr>
                <td style="width:50%">
                    {{ 'general.accountPlatform' | translate}}
                    <span class="error" *ngIf="(!driver.accountPlatformId) && formMode != 1">*</span>
                </td>
                <td colspan="2" style="width:50%">
                    <select [disabled]="formMode == 1" [(ngModel)]="driver.accountPlatformId" name="accountPlatformId"
                        class="form-control table-inline">
                        <option *ngFor="let platform of accountPlatforms" [ngValue]="platform.id">
                            {{platform.name}}
                        </option>
                    </select>
                </td>
            </tr>
            <tr>
                <td style="width:50%">
                    {{ 'general.dateOfBirth' | translate}}
                    <span class="error" *ngIf="(!driver.dateOfBirth) && formMode != 1">*</span>
                </td>
                <td colspan="2" style="width:50%">
                    <input [disabled]="formMode === 1" [(ngModel)]="driver.dateOfBirth" name="dateOfBirth" type="text"
                        class="form-control table-inline" autocomplete="off" [bsConfig]="dpConfig" bsDatepicker>
                </td>
            </tr>
            <tr>
                <td style="width:50%">
                    {{ 'general.phone' | translate}}
                    <span class="error" *ngIf="phone.invalid && formMode != 1">*</span>
                </td>
                <td colspan="2" style="width:50%">
                    <input type="text" [readonly]="formMode == 1" name="phone" class="form-control table-inline"
                        [(ngModel)]="driver.phone" required #phone="ngModel">
                </td>
            </tr>
            <tr *ngIf="formMode == 1">
                <td style="width:50%">
                    {{ 'general.referenceId' | translate}}
                    <span class="error" *ngIf="reference.invalid && formMode != 1">*</span>
                </td>
                <td colspan="2" style="width:50%">
                    <input type="text" [readonly]="formMode == 1" name="referenceId" class="form-control table-inline"
                        [(ngModel)]="driver.referenceId" required #reference="ngModel">
                </td>
            </tr>
        </tbody>

    </table>
</div>