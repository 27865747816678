import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import * as moment from 'moment';
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch';
import { of } from 'rxjs/observable/of';
import { throwError } from 'rxjs/internal/observable/throwError';

import { AuthenticationService } from '../authentication/authentication.service';
import { LoggingService } from '../logging/logging.service';
import { Platform } from '../../models/platform.model';

@Injectable()
export class PlatformService {

    token: string;
    timestamp;
    url = '';
    Platforms: Platform[] = [];
    urlInventory: string;

    constructor(private http: HttpClient, private loggingService: LoggingService, private authenticationService: AuthenticationService) {
        this.url = authenticationService.getWebserviceURL('platform');
        this.urlInventory = authenticationService.getInventoryURL('platform');
        this.token = authenticationService.getAuthToken();
    }


    getPlatforms(setDefinate = true, onlyFromMemmory = false): Observable<Platform[]> {

        if (this.Platforms.length !== 0) {
            return of(this.Platforms);
        }

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting devices from service');
        return this.http.get(this.urlInventory + 'PlatformTypes', { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                if (setDefinate) {
                    console.log('setting Platforms');
                    this.Platforms = parsedResponse;
                }
                return parsedResponse;
            })
            .catch(this.handleError);
    }


    private handleError(error: Response) {
        console.log(error);
        return throwError(error);
    }

    parseResponse(json: any): Platform[] {

        this.loggingService.log(this.constructor.name, 'Retrieved ' + json.length + ' Platforms.');

        const ident = 1;
        const platforms: Platform[] = [];

        json.forEach(item => {

            const platform = this.parseReponseDetails(item);

            platforms.push(platform);
        });

        this.timestamp = new Date().getTime();
        return platforms;
    }

    parseReponseDetails(item) {
        //   this.loggingService.log(this.constructor().name + " - New", item);
        const platform = new Platform();
        platform.id = item.id;
        platform.name = item.name;

        return platform;
    }
}
