import { NgModule } from '@angular/core';
import { TimezoneSelectorComponent } from './timezoneselector.component';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';

@NgModule({
    imports: [CommonModule, FormsModule],
    declarations: [ TimezoneSelectorComponent ],
    exports: [ TimezoneSelectorComponent ]
})
export class TimezoneSelectorModule {}
