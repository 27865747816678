import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { AuthenticationService } from '../authentication/authentication.service';
import { LoggingService } from '../logging/logging.service';
import { InventoryAccount, AccountType } from '../../models/account.model';
import { AppAccount, Account, AddressDetails } from '../../models/platform.model';
import { ResultModel } from '../../common/globals';
import { RegisterOperatingCompanyRequest } from '../../models/wasl.model';
import { of } from 'rxjs/observable/of';
import { throwError } from 'rxjs/internal/observable/throwError';

@Injectable()
export class AccountService {

    url = '';
    urlInventory = '';

    constructor(private http: HttpClient, private loggingService: LoggingService, private authenticationService: AuthenticationService) {
        this.url = authenticationService.getWebserviceURL('Account');
        this.urlInventory = authenticationService.getInventoryURL('Account');
    }

    getAccounts(): Observable<InventoryAccount[]> {
        this.url = this.authenticationService.getWebserviceURL('Account');
        this.urlInventory = this.authenticationService.getInventoryURL('Account');

        console.log('getting accounts from service');
        return this.http.get(this.url, { headers: this.authenticationService.headers })
            .map((data) => {
                return this.parseResponse(data);
            })
            .catch(this.handleError);
    }

    createAccount(appAccount: AppAccount): Observable<ResultModel<Account>> {
        return this.http.post<Account>(this.urlInventory, appAccount, { headers: this.authenticationService.headers })
            .map((data) => ({
                completed: true,
                data: data
            })
            )
            .catch((error) => of({
                completed: false,
                data: error
            }));
    }

    deleteAccount(id: any): Observable<any> {
        return this.http.delete(this.urlInventory + id, { headers: this.authenticationService.headers })
            .catch(this.handleError);
    }

    getAccountsWithUse(): Observable<InventoryAccount[]> {
        console.log('getting accounts from service');
        return this.http.get(this.url + 'GetAccountsWithUse', { headers: this.authenticationService.headers })
            .map((data) => {
                return this.parseResponse(data);
            })
            .catch(this.handleError);
    }

    getAccountsWithUseWasl(): Observable<InventoryAccount[]> {
        console.log('getting accounts from service');
        this.url = this.authenticationService.getWebserviceURL('Account');
        this.urlInventory = this.authenticationService.getInventoryURL('Account');

        return this.http.get(this.urlInventory + 'Wasl', { headers: this.authenticationService.headers })
            .map((data) => {
                return this.parseResponse(data);
            })
            .catch(this.handleError);
    }

    getAccountsWithUseHistory(): Observable<InventoryAccount[]> {
        console.log('getting accounts from service');
        this.url = this.authenticationService.getWebserviceURL('Account');
        this.urlInventory = this.authenticationService.getInventoryURL('Account');

        return this.http.get(this.url + 'getAccountsWithUseHistory', { headers: this.authenticationService.headers })
            .map((data) => {
                return this.parseResponse(data);
            })
            .catch(this.handleError);
    }

    getAccountTypes(): Observable<AccountType[]> {
        const urlExtra = 'AccountTypes';
        console.log('getting account types from service');
        return this.http.get(this.url + urlExtra, { headers: this.authenticationService.headers })
            .map((data) => {
                return (data as AccountType[]);
            })
            .catch(this.handleError);
    }

    getAccount(): Observable<InventoryAccount> {
        console.log('getting accounts from service');
        return this.http.get(this.url, { headers: this.authenticationService.headers })
            .map((data) => {
                return this.parseResponseData(data[0]);
            })
            .catch(this.handleError);
    }

    getAccountById(id: any): Observable<InventoryAccount> {
        console.log('getting accounts by id from service');
        return this.http.get(this.url + id, { headers: this.authenticationService.headers })
            .map((data) => {
                return this.parseResponseData(data);
            })
            .catch(this.handleError);
    }

    updateAccount(account: InventoryAccount): Observable<any> {
        console.log('update account');

        account.identifier = +account.identifier;
        account.limitHistoryInDays = +account.limitHistoryInDays;

        return this.http.put(this.urlInventory + account.id, account, { headers: this.authenticationService.headers })
            .catch(this.handleError);
    }

    parseResponse(json: any): InventoryAccount[] {
        this.loggingService.log(this.constructor.name, 'Retrieved ' + json.length + ' Accounts.');

        const accounts: InventoryAccount[] = [];

        json.forEach(item => {
            let account = new InventoryAccount();
            account = this.parseResponseData(item);
            accounts.push(account);
        });

        return accounts;
    }

    parseResponseData(item: any): InventoryAccount {
        const account = new InventoryAccount();
        account.id = item.id;
        account.name = item.name;
        account.status = item.status;
        account.isActive = item.isActive;

        account.date = item.date;
        account.issueCount = item.issueCount;
        account.jobCount = item.jobCount;

        account.userCount = item.userCount;
        account.platformCount = item.platformCount;
        account.deviceCount = item.deviceCount;

        account.createdDate = item.createdDate;
        account.identifier = item.identifier;
        account.limitHistoryInDays = item.limitHistoryInDays;


        account.assetCount = item.assetCount;
        account.driverCount = item.driverCount;

        account.waslAssetCount = item.waslAssetCount;
        account.waslDriverCount = item.waslDriverCount;

        account.waslSuccessCount = item.waslSuccessCount;
        account.waslFailedCount = item.waslFailedCount;

        account.address = new AddressDetails();

        account.properties.wasl = new RegisterOperatingCompanyRequest();

        if (item.properties) {

            if (item.properties.wasl) {
                const wasl = new RegisterOperatingCompanyRequest();
                wasl.commercialRecordIssueDateHijri = item.properties.wasl.commercialRecordIssueDateHijri;
                wasl.commercialRecordNumber = item.properties.wasl.commercialRecordNumber;
                wasl.dateOfBirthGregorian = item.properties.wasl.dateOfBirthGregorian;
                wasl.emailAddress = item.properties.wasl.emailAddress;
                wasl.extensionNumber = item.properties.wasl.extensionNumber;
                wasl.identityNumber = item.properties.wasl.identityNumber;
                wasl.managerMobileNumber = item.properties.wasl.managerMobileNumber;
                wasl.managerName = item.properties.wasl.managerName;
                wasl.managerPhoneNumber = item.properties.wasl.managerPhoneNumber;
                wasl.phoneNumber = item.properties.wasl.phoneNumber;
                wasl.referenceKey = item.properties.wasl.referenceKey;
                account.properties.wasl = wasl;
            }
        }

        return account
    }

    private handleError(error: Response) {
        return throwError(error);
    }
}
