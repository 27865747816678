<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Assets/Overview">{{'general.assets' | translate }}</a>
            </li>
            <li class="active">
                <a href="/#/Assets/Overview">{{'menu.add' | translate }}</a>
            </li>
        </ol>
    </div>
</div>

<form #userForm="ngForm" #formDir="ngForm">
    <div class="wrapper wrapper-content">
        <div class="row">
            <div class="col-lg-6">
                <fh-error [error]="error" [success]="success"></fh-error>

                <div class="ibox float-e-margins" style="position:relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-car"></i> {{'general.asset' | translate }}</h5>
                    </div>
                    <fh-asset-details [asset]="asset" [formMode]="formMode"></fh-asset-details>
                    <fh-save-footer [valid]="!userForm.invalid && asset.assetTypeId != null" (onInsert)="onInsert()" [formMode]="formMode">
                    </fh-save-footer>
                </div>

            </div>
            <div class="col-lg-6">
                <div *ngIf="permissions['HasWasl']" class="ibox float-e-margins">
                    <div class="ibox-title">
                        <h5>
                            <i class="fa fa-user-shield"></i> {{'general.wasl' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content slick" style="position:relative">
                        <fh-wasl-asset-details [asset]="asset" [formMode]="formMode"></fh-wasl-asset-details>
                    </div>
                </div>

                <fh-assettype-module (onSelect)="updateAssetType($event)" [asset]="asset" [formMode]="formMode"
                    [loading]="loading">
                </fh-assettype-module>
            </div>
        </div>
    </div>
</form>