import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch';

import { AuthenticationService } from '../authentication/authentication.service';
import { Asset, SlimWaslAsset, AssetDevice } from '../../models/asset.model';
import { LoggingService } from '../logging/logging.service';
import { AssetDriver } from '../../models/driver.model';
import { fixDate } from '../common/functions.service';

@Injectable()
export class AssetService {
    Assets: Asset[] = [];
    url = '';
    inventory_url = '';
    inventory_url_base = '';

    constructor(private http: HttpClient, private loggingService: LoggingService, private authenticationService: AuthenticationService) {
        this.url = this.authenticationService.getWebserviceURL('asset');
        this.inventory_url = this.authenticationService.getInventoryURL('asset');
        this.inventory_url_base = this.authenticationService.getInventoryURL('');
    }

    getPagingUrl() {
        return this.url + 'PagedAssets';
    }

    getTruckPagingUrl() {
        return this.url + 'PagedAssets?assetClass=1';
    }

    getTrailersPagingUrl() {
        return this.url + 'PagedAssets?assetClass=2';
    }

    getAssets(): Observable<Asset[]> {
        console.log('getting assets from service');
        return this.http.get(this.url, { headers: this.authenticationService.headers })
            .map((data) => {
                return this.parseResponse(data);
            })
            .catch(this.handleError);
    }

    getAssetsByCustomerId(id: string): Observable<Asset[]> {
        console.log('getting assets from service');
        return this.http.get(this.url + 'Customer/' + id, { headers: this.authenticationService.headers })
            .map((data) => {
                return this.parseResponse(data);
            })
            .catch(this.handleError);
    }

    getAssetsByAssetTypeId(id: string): Observable<Asset[]> {
        console.log('getting assets from service');
        return this.http.get(this.url + 'AssetType/' + id, { headers: this.authenticationService.headers })
            .map((data) => {
                return this.parseResponse(data);
            })
            .catch(this.handleError);
    }

    getAssetsByParentId(id: string, assetClass: number): Observable<Asset[]> {
        console.log('getting assets from service');
        return this.http.get(this.url + 'Parent/' + id + '?assetClass=' + assetClass, { headers: this.authenticationService.headers })
            .map((data) => {
                return this.parseResponse(data);
            })
            .catch(this.handleError);
    }

    getAssetsByChildId(id: string, assetClass: number): Observable<Asset[]> {
        console.log('getting assets from service');
        return this.http.get(this.url + 'Child/' + id + '?assetClass=' + assetClass, { headers: this.authenticationService.headers })
            .map((data) => {
                return this.parseResponse(data);
            })
            .catch(this.handleError);
    }
    getAssetsByDriverId(id: string): Observable<Asset[]> {
        console.log('getting assets from service');
        return this.http.get(this.url + 'Driver/' + id, { headers: this.authenticationService.headers })
            .map((data) => {
                return this.parseResponse(data);
            })
            .catch(this.handleError);
    }

    getAssetById(id: string): Observable<Asset> {
        console.log('Fetch asset by id ' + id);
        return this.http.get(this.url + id, { headers: this.authenticationService.headers })
            .map((data) => {
                return this.parseReponseDetails(data);
            })
            .catch(this.handleError);
    }

    saveAsset(asset: Asset): Observable<any> {
        console.log('save asset');

        if (asset.assetTypeId === -1) {
            asset.assetTypeId = null;
            asset.createNewAssetType = true;
        }

        return this.http.post(this.inventory_url, asset, { headers: this.authenticationService.headers })
            .catch(this.handleError);
    }

    resetAssetCache(): Observable<boolean> {
        return this.http.get(this.url + 'ResetCache', { headers: this.authenticationService.headers })
            .map((data: any) => {
                return data;
            })
            .catch(this.handleError);
    }

    updateAsset(asset: Asset): Observable<any> {
        console.log('save asset');
        return this.http.put(this.inventory_url, asset, { headers: this.authenticationService.headers })
            .catch(this.handleError);
    }

    getAssetByDeviceId(id: string): Observable<Asset> {
        console.log('Fetch asset by id ' + id);
        return this.http.get(this.url + 'Device/' + id, { headers: this.authenticationService.headers })
            .map((data) => {
                return this.parseReponseDetails(data);
            })
            .catch(this.handleError);
    }

    getWaslAssetStatus(): Observable<any> {
        console.log('Fetch assets for wasl status');
        return this.http.get(this.inventory_url_base + 'Assets/Wasl', { headers: this.authenticationService.headers })
            .map((data) => {
                return (data);
            })
            .catch(this.handleError);
    }

    private handleError(error: Response) {
        return Observable.throw(error);
    }

    linkAsset(parentAssetId, childAssetId, startDate, endDate): Observable<any> {
        console.log('link asset');

        const body = {
            'parentAssetId': parentAssetId,
            'childAssetId': childAssetId,
            'assetLinkType': 1 // Manual
        }

        return this.http.post(this.inventory_url_base + 'AssetLink', body, { headers: this.authenticationService.headers })
            .catch(this.handleError);
    }

    unLinkAsset(assetLinkId: string): Observable<any> {
        console.log('unlink asset');
        return this.http.delete(this.inventory_url_base + 'AssetLink/' + assetLinkId, { headers: this.authenticationService.headers })
            .catch(this.handleError);
    }

    linkDriver(assetId, driverId, startDate, endDate): Observable<any> {
        console.log('link assetDriver', startDate, endDate);

        startDate = Math.round(startDate / 1000);
        if (endDate) {
            endDate = Math.round(endDate / 1000);
        }

        const body = {
            'AssetId': assetId,
            'DriverId': driverId,
            'startDate': startDate,
            'endDate': endDate,
            'type': 1
        }

        return this.http.post(this.inventory_url_base + 'AssetDriver', body, { headers: this.authenticationService.headers })
            .catch(this.handleError);
    }

    unLinkDriver(assetDriverId: string, permanent = false): Observable<any> {
        console.log('unlink assetDriver');
        return this.http.delete(this.inventory_url_base + 'AssetDriver/' + assetDriverId + '/' + permanent, { headers: this.authenticationService.headers })
            .catch(this.handleError);
    }


    getAssetDevicesById(id: string): Observable<AssetDevice[]> {
        console.log('Fetch asset by id ' + id);
        return this.http.get(this.inventory_url_base + 'AssetDevice/Asset/' + id, { headers: this.authenticationService.headers })
            .map((data: any) => {

                const assetDevices: AssetDevice[] = [];

                data.forEach(item => {
                    const assetDevice = new AssetDevice();
                    assetDevice.assetId = item.assetId;
                    assetDevice.deviceId = item.deviceId;
                    assetDevice.startDate = item.startDate;
                    assetDevice.endDate = item.endDate;
                    assetDevice.deviceId = item.deviceId;
                    if (item.device != null) {
                        assetDevice.deviceName = item.device.name;
                    }

                    assetDevice.isActive = (assetDevice.endDate == null || new Date(assetDevice.endDate) > new Date());

                    assetDevices.push(assetDevice);
                });

                return assetDevices;
            })
            .catch(this.handleError);
    }

    linkDevice(assetId, deviceId, startDate, endDate): Observable<any> {
        console.log('link assetDevice', startDate, endDate);

        const body = {
            'deviceId': deviceId,
            'assetId': assetId,
            'startDate': startDate,
            'endDate': endDate
        }

        return this.http.post(this.inventory_url_base + 'AssetDevice', body, { headers: this.authenticationService.headers })
            .catch(this.handleError);
    }

    unLinkDevice(assetId: string): Observable<any> {
        console.log('unlink assetId');
        return this.http.delete(this.inventory_url_base + 'AssetDevice/Asset/' + assetId, { headers: this.authenticationService.headers })
            .catch(this.handleError);
    }

    unLinkDeviceOnAssetLink(assetLinkId: string): Observable<any> {
        console.log('unlink assetLinkId');
        return this.http.delete(this.inventory_url_base + 'AssetDevice/' + assetLinkId, { headers: this.authenticationService.headers })
            .catch(this.handleError);
    }


    getAssetMessagesById(id: string, start, end): Observable<any> {
        console.log('Fetch asset by id ' + id);
        return this.http.get(this.url + id + '/Messages?start=' + start + '&end=' + end, { headers: this.authenticationService.headers })
            .map((data) => {
                return data;
            })
            .catch(this.handleError);
    }

    parseResponse(json: any): Asset[] {

        this.loggingService.log(this.constructor.name, 'Retrieved ' + json.length + ' Assets.');

        const ident = 1;
        const assets: Asset[] = [];

        json.forEach(item => {

            const asset = this.parseReponseDetails(item);
            assets.push(asset);
        });

        return assets;
    }

    parseReponseDetails(item) {

        if (item === null) {
            return null;
        }

        const asset = new Asset();
        asset.id = item.id;
        asset.deviceId = item.deviceId;
        asset.unitId = item.unitId;
        asset.name = item.name;
        asset.year = item.year;
        asset.vehicleType = item.vehicleType;
        asset.vin = item.vin;
        asset.referenceId = item.referenceId;
        asset.plateNumber = item.plateNumber;
        asset.color = item.color;
        asset.code = item.code;
        asset.brand = item.brand;
        asset.model = item.model;

        asset.assetClass = item.assetClass;
        asset.assetTypeId = item.assetTypeId;
        asset.assetTypeName = item.assetTypeName;

        asset.identificationTag = item.identificationTag;

        asset.humiditySensorPosition = item.humiditySensorPosition;
        asset.humiditySensorTypeId = item.humiditySensorTypeId;
        asset.temperatureSensorPosition = item.temperatureSensorPosition;
        asset.temperatureSensorTypeId = item.temperatureSensorTypeId;
        asset.weightSensorPosition = item.weightSensorPosition;
        asset.weightSensorTypeId = item.weightSensorTypeId;

        asset.assetLinkId = item.assetLinkId;

        if (item.assetDriverDto != null) {
            const assetDriver = item.assetDriverDto;
            asset.assetDriver = new AssetDriver();
            asset.assetDriver.startDate = assetDriver.startDate;
            asset.assetDriver.endDate = assetDriver.endDate;
            asset.assetDriver.type = assetDriver.type;
        }

        if (item.assetLinkStartDate != null) {
            asset.assetLinkStartDate = item.assetLinkStartDate;
            asset.assetLinkType = item.assetLinkType;
        }

        if (item.assetLinkEndDate != null) {
            asset.assetLinkEndDate = item.assetLinkEndDate;
        }

        asset.isActive = (asset.assetLinkEndDate == null || new Date(asset.assetLinkEndDate) > new Date());

        if (item.properties) {
            if (item.properties.wasl) {
                const wasl = new SlimWaslAsset();
                wasl.referenceKey = item.properties.wasl.referenceKey;
                wasl.plateType = item.properties.wasl.plateType;
                wasl.sequenceNumber = item.properties.wasl.sequenceNumber;

                wasl.vehiclePlateNumber = item.properties.wasl.vehiclePlateNumber;
                wasl.vehiclePlateLeftLetter = item.properties.wasl.vehiclePlateLeftLetter;
                wasl.vehiclePlateMiddleLetter = item.properties.wasl.vehiclePlateMiddleLetter;
                wasl.vehiclePlateRightLetter = item.properties.wasl.vehiclePlateRightLetter;

                asset.properties.wasl = wasl;
            }
        }

        return asset;
    }
}
