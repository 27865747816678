<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/AssetTypes/Overview">{{'general.assetTypes' | translate }}</a>
            </li>
            <li class="active">
                <a href="/#/AssetTypes/Overview">{{'menu.add' | translate }}</a>
            </li>
        </ol>
    </div>
</div>

<form #userForm="ngForm" #formDir="ngForm">
    <div class="wrapper wrapper-content">
        <div class="row">
            <div class="col-lg-6">
                <fh-error [error]="error" [success]="success"></fh-error>

                <div class="ibox float-e-margins" style="position:relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-object-group"></i> {{'general.assetType' | translate }}</h5>
                    </div>
                    <fh-asset-type-details [assetType]="assetType" [formMode]="formMode"></fh-asset-type-details>
                    <fh-save-footer [valid]="!userForm.invalid" (onInsert)="onInsert()" [formMode]="formMode">
                        <label *ngIf="isImpersonated" style="padding-top: 6px;padding-left: 12px;">
                            <input type="checkbox" name="isGeneral" [(ngModel)]="assetType.isGeneral"> {{'general.isGeneral'
                                    | translate }}</label>
                    </fh-save-footer>
                </div>

            </div>
            <div class="col-lg-6">
                <fh-asset-type-property-editor [formMode]="formMode" [assetTypeGroups]="assetTypeGroups">
                </fh-asset-type-property-editor>
            </div>
        </div>
    </div>
</form>