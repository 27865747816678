import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Subject } from 'rxjs/Subject';
import { DeviceTypeService } from '../../services/devicetypes/devicetypes.service';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import { DeviceType } from '../../models/devicetype.model';
import { ActivatedRoute, Router } from '@angular/router';

class Person {
  id: number;
  firstName: string;
  lastName: string;
}

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: 'fh-device-types',
  templateUrl: 'deviceTypes.template.html',
  styleUrls: ['deviceTypes.template.css']
})
export class DevicesTypeViewComponent implements OnInit, AfterViewInit {
  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;

  dtOptions = {};
  assets: Subject<any> = new Subject();
  persons: Person[];
  loading = false;
  data = 0;
  sub: any;
  languageLoaded: boolean;
  searchTerm: any;

  constructor(private translateService: TranslateService, private http: HttpClient, private deviceTypeService: DeviceTypeService, private route: ActivatedRoute, private router: Router) {
  }

  ngAfterViewInit() {
  }

  seachChanged(value) {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.search(value).draw();
    });
  }

  ngOnInit(): void {
    this.translateService.get('general.date').subscribe(value => {
      this.languageLoaded = true; this.initGrid();
    });
  }

  initGrid(): void {
    const that = this;
    this.loading = true;

    this.dtOptions = {
      pagingType: 'simple_numbers',
      serverSide: false,
      processing: false,
      scrollX: true,
      colReorder: { fixedColumnsLeft: 1 },
      order: [[4, 'desc']],
      stateSave: true,
      stateSaveCallback: function (settings, data) {
        localStorage.setItem('Columns_' + that.constructor.name, JSON.stringify(data));
      },
      stateLoadCallback: function () {
        try {
          const columnSettings = JSON.parse(localStorage.getItem('Columns_' + that.constructor.name));
          that.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
          return columnSettings;
        } catch (e) {
          console.log(e);
        }
      },
      ajax: (dataTablesParameters: any, callback) => {
        $('.dataTables_info').html(this.translateService.instant('grid.loadingData'));

        this.deviceTypeService.getDeviceTypesWithCount().subscribe(resp => {
          callback({
            recordsTotal: resp.length,
            recordsFiltered: resp.length,
            data: resp
          });
        });
      },
      initComplete: function (settings, json) {
        that.loading = false;
      },
      colVis: {
        restore: this.translateService.instant('general.restore'),
        showAll: this.translateService.instant('general.showAll'),
        showNone: this.translateService.instant('general.hideAll'),
        exclude: ['id']
      },
      columns: [{
        name: 'id',
        data: 'id',
        orderable: false,
        title: '',
        width: '20',
        render: function (data, type, row) {
          return '<a class=\'btn btn-primary btn-outline btn-grid\' href=\'/#/DeviceTypeDetails/Index/' + data + '\'><i class=\'fas fa-fw fa-info\'></i> <span class="hidden-sm hidden-xs">Details</span></a>';
        }
      },
      { data: 'name', title: this.translateService.instant('general.name') },
      {
        data: 'manufacturerName',
        title: this.translateService.instant('general.manufacturer'),
        render: function (data, type, row) {
          return data ? data : '-';
        }
      }, {
        data: 'thumbnail',
        title: this.translateService.instant('general.thumbnail'),
        render: function (data, type, row) {
          return data ? '<img style="margin-top: -8px;margin-bottom: -8px; height:30px;width: 30px " src="data:image/jpg;base64,' + data + '"/>' : '-';
        }
      },
      { data: 'deviceCount', width: '80', title: 'Devices' }],
      pageLength: 10,
      language: {
        'lengthMenu': this.translateService.instant('grid.lengthmenu'),
        'zeroRecords': this.translateService.instant('grid.empty'),
        'info': this.translateService.instant('grid.info'),
        'infoEmpty': this.translateService.instant('grid.infoempty'),
        'infoFiltered': this.translateService.instant('grid.infofiltered'),
        'search': this.translateService.instant('grid.search'),
        'infoThousands': '.',
        'loadingRecords': this.translateService.instant('grid.loadingrecords'),
        'paginate': {
          'first': this.translateService.instant('grid.first'),
          'last': this.translateService.instant('grid.last'),
          'next': this.translateService.instant('grid.next'),
          'previous': this.translateService.instant('grid.previous'),
        },
        'aria': {
          'sortAscending': this.translateService.instant('grid.sortasc'),
          'sortDescending': this.translateService.instant('grid.sortdesc'),
        }
      },
    };
  }
}
