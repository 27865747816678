import { ControlContainer, NgForm } from '@angular/forms';
import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormMode } from 'app/common/enums';
import { AppAccountPlatform } from 'app/models/platform.model';
import { PlatformService } from 'app/services/platform/platform.service';
import { AccountPlatformService } from 'app/services/platform/accountPlatform.service';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { of } from 'rxjs/observable/of';
import { DeleteModalComponent } from './deleteModal.component';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
    selector: 'fh-accountplatform-control',
    templateUrl: 'accountPlatformControl.template.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class AccountPlatformControlComponent implements OnInit {
    @ViewChild('deleteModal') deleteModal: DeleteModalComponent;
    @ViewChild('editModal') editModal: ModalDirective;

    @Input() formMode: FormMode = FormMode.read;

    @Input() accountPlatformRequest: AppAccountPlatform[] = [new AppAccountPlatform()];
    @Input() accountPlatforms: AppAccountPlatform[] = [];

    @Input() loading = false;
    editAccountPlatform;

    @Input() insertOnly = false;

    @Output() onSubmit = new EventEmitter<AppAccountPlatform[]>();
    @Output() onUpdate = new EventEmitter();

    platforms = [];

    testingEdit = false;
    testing = false;
    isEditModalShown = false;

    success;
    error;

    showingPasswordColumn = false;

    validatedAccountsPlatforms = {
        'flespiwasl': true
    };

    placeholderUrls = ((accountType) => (
        (result = {
            1: 'https://hst-api.wialon.com/wialon/ajax.html',
            2: 'https://services.gpscockpit.com/api360',
            3: 'https://api.fm-track.com',
            5: 'https://flespi.io/'
        }[accountType]) => result ? result : ''
    )());


    tokenGuard = ((platforms: AppAccountPlatform[]) => ({

        hasValidAccountPlatforms: (() => {
            return Boolean(platforms.find(
                (platform) => this.validatedAccountsPlatforms[this.serializeValues(platform)])
            );
        }),

        validate: ((checkedFunction) => {

            const returnSuccess = of(true);

            const createTokenCall = ((request: AppAccountPlatform) =>
                checkedFunction(request)
                    ? returnSuccess
                    : this.accountPlatformService.checkToken(request.platformType, request.token, request.url)
            );

            return ((callback) =>
                forkJoin(platforms.map(createTokenCall)).subscribe(result => callback(result, platforms))
            );

        }),

        check_format: (() => {

            if (!(platforms.length && !isNaN(platforms[0].platformType))) {
                return false;
            }

            const base_validate = ((request: AppAccountPlatform) => (
                (request.platformName && request.platformName.length > 0)
                && (request.token && request.token.length > 0)
                && (request.url && request.url.length > 0)
                && (!isNaN(request.platformType))
            ));

            const flespiWasl = (_ => true);

            return Boolean(platforms[0].platformType === 6 ? flespiWasl(platforms[0]) : base_validate(platforms[0]));
        })

    }));

    platformValidation = {
        'Valid': 1,
        'Invalid': 2
    }

    constructor(
        private platformService: PlatformService,
        private accountPlatformService: AccountPlatformService) {

        this.platformService.getPlatforms(true).subscribe(result => {
            this.platforms = result.filter(r => r.name !== 'Unknown');
            this.setInitial();
        });

    }

    ngOnInit(): void {
        this.setInitial();
    }

    setInitial() {
        if (this.formMode === 2 && this.platforms && this.platforms.length > 0) {
            this.accountPlatformRequest[0].platformType = this.platforms[0].id;
        }
    }

    addAccountPlatform() {
        this.accountPlatformRequest.push(new AppAccountPlatform());
    }

    removeAccountPlatform(i) {
        const removedPlatform = this.accountPlatformRequest.splice(i, 1);

        const is360Platform = (platform) => platform.platformType === 2;

        if (is360Platform(removedPlatform[0])) {
            this.showingPasswordColumn = Boolean(this.accountPlatformRequest.find(is360Platform));
        }

    }

    serializeValues(platform): string {

        if (isNaN(platform.platformType)) {
            return '';
        }

        if (platform.platformType === 6) {
            return 'flespiwasl';
        }

        return btoa([platform.platformType, platform.token, platform.url].join('-'));
    }

    anyValidated() {
        const guard = this.tokenGuard(this.accountPlatformRequest);
        return guard.hasValidAccountPlatforms();
    }

    isValidated = (accountPlatform) =>
        this.isBoolean(this.validatedAccountsPlatforms[this.serializeValues(accountPlatform)]);

    isBoolean = (result) => (typeof (result) === 'boolean' && (result
        ? this.platformValidation.Valid
        : this.platformValidation.Invalid)
    );

    checkTokenValidation(accountPlatformRequest: AppAccountPlatform[]) {

        const guard = this.tokenGuard(accountPlatformRequest);
        const validParameters = guard.check_format();

        return validParameters;
    }

    hasValidPlatform(): boolean {
        for (const platform of this.accountPlatformRequest) {
            if (this.validatedAccountsPlatforms[this.serializeValues(platform)]) {
                return true;
            }
        }
        return false;
    }

    testTokens() {

        this.testing = true;

        const guard = this.tokenGuard(this.accountPlatformRequest);
        const isChecked = () => false;  // (platform) => this.isValidated(platform);

        guard.validate(isChecked.bind(this))((result: Boolean[], platforms: AppAccountPlatform[]) => {
            result.forEach((value, idx) => {
                this.testing = false;
                this.setValidatedPlatform(platforms[idx], value);
            });
        });

    }

    setPlatformUrl(platformId, platformRow) {

        if (!this.showingPasswordColumn) {
            this.showingPasswordColumn = platformId === 2;
        }

        this.accountPlatformRequest[platformRow].url = this.placeholderUrls(platformId);
    }

    setValidatedPlatform(request: AppAccountPlatform, result: Boolean) {
        this.validatedAccountsPlatforms[this.serializeValues(request)] = result;
    }

    createAccountPlatforms() {
        this.onSubmit.emit(this.accountPlatformRequest);
    }

    // Deleting
    deleteAccountPlatform(accountPlatformKey) {
        this.deleteModal.showModal(accountPlatformKey);
    }

    onAccountPlatformDelete(event) {

        this.deleteModal.hideModal();

        this.loading = true;

        this.accountPlatformService.deleteAccountPlatform(event).subscribe(result => {

            this.loading = false;
            this.success = 'Succes';
            // todo: refresh grid
            this.onUpdate.emit();
        }, (error) => {

            this.loading = false;
            this.error = error;
            console.log('err:' + error);
        });
    }

    // Editing
    testEditToken(accountPlatform) {

        this.testing = true;
        this.testingEdit = true;

        const guard = this.tokenGuard([accountPlatform]);
        const isChecked = () => false;  // (platform) => this.isValidated(platform);

        guard.validate(isChecked.bind(this))((result: Boolean[], platforms: AppAccountPlatform[]) => {
            this.testingEdit = false;

            result.forEach((value, idx) => {
                this.testing = false;
                this.setValidatedPlatform(platforms[idx], value);
            });
        });

    }

    OpenEditAccountPlatform(accountPlatform) {
        // this.editModal.show();

        this.editAccountPlatform = accountPlatform;
        this.isEditModalShown = true;
    }

    hideEditModal() {
        this.isEditModalShown = false;
    }

    declineEdit() {
        this.isEditModalShown = false;
    }

    updateAccountPlatform(accountPlatform) {
        this.loading = true;
        this.isEditModalShown = false;

        this.accountPlatformService.updateAccountPlatform(accountPlatform).subscribe(result => {

            this.loading = false;
            this.success = 'Succes';
            // todo: refresh grid
            this.onUpdate.emit();
        }, (error) => {

            this.loading = false;
            this.error = error;
            console.log('err:' + error);
        });
    }
}
