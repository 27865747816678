import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { of } from 'rxjs/observable/of';
import * as moment from 'moment';

import { AuthenticationService } from '../authentication/authentication.service';
import { IssueType } from '../../models/issuetype.model';
import { Location } from '../../models/location.model';
import { LoggingService } from '../logging/logging.service';
import { TranslatorService } from '../common/translator.service';
import { throwError } from 'rxjs/internal/observable/throwError';

@Injectable()
export class IssueTypeService {
    token: string;
    timestamp;
    url = '';
    public IssueTypes: IssueType[] = [];

    constructor(private translatorService: TranslatorService, private http: HttpClient, private loggingService: LoggingService, private authenticationService: AuthenticationService) {
        this.url = authenticationService.getWebserviceURL('issuetype');
        this.token = authenticationService.getAuthToken();
    }

    getIssueTypes(setDefinate = true): Observable<IssueType[]> {

        if (this.IssueTypes.length !== 0) {
            return of(this.IssueTypes);
        }

        this.token =  this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting issuetypes from service');
        return this.http.get(this.url, { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                if (setDefinate) {
                    console.log('setting IssueTypes');
                    this.IssueTypes = parsedResponse;
                }
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    private handleError(error: Response) {
        return throwError(error);
    }

    parseResponse(json: any): IssueType[] {

        this.loggingService.log(this.constructor.name, 'Retrieved ' + json.length + ' IssueTypes.');

        const ident = 1;
        const issueTypes: IssueType[] = [];

        json.forEach(item => {

            //   this.loggingService.log(this.constructor().name + " - New", item);

            const issueType = this.parseResponseDetails(item);
            issueTypes.push(issueType);
        });

        this.timestamp = new Date().getTime();
        return issueTypes;
    }

    parseResponseDetails(item) {
        const issueType = new IssueType();
        issueType.id = item.id;
        issueType.name = this.translatorService.translate(item.name, 'enums.issuetypes');
        issueType.uom = item.uom;
        issueType.uomDescription = this.translatorService.translate(item.uom.toString(), 'enums.issuetypesuom');
        issueType.thresholdHasBounds = item.thresholdHasBounds;
        issueType.hasDuration = item.hasDuration;
        issueType.count = 0;
        issueType.category = item.category;
        issueType.displayUom = item.displayUom;
        issueType.editableThresholds = item.editableThresholds;
        return issueType;
    }
}
