import { Component, OnInit } from '@angular/core';
import { environment } from 'app/../environments/environment';

@Component({
  selector: 'fh-footer',
  templateUrl: 'footer.template.html'
})
export class FooterComponent implements OnInit {
  appVersion: string;
  appName: string;
  ngOnInit(): void {
    this.appVersion = environment.version;
    this.appName = environment.ApplicationName;
  }
}
