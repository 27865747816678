<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Customers/Overview">{{'general.customers' | translate }}</a>
            </li>
            <li>
                <a href="/#/CustomerDetails/Index/{{customer?.id}}">{{customer?.name}}</a>
            </li>
            <li class="active">
                <strong>{{'menu.details' | translate }}</strong>
            </li>
        </ol>
    </div>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="">
        <div class="col-lg-6">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5 style="font-weight:bold">
                        <i class="fa fa-user-tie"></i> {{ customer?.name }}
                    </h5>
                </div>
                <div class="ibox-content slick" style="min-height:50px;position:relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <table style="width:100%" class="table table-hover nowrap dtr-inline no-footer">
                        <tbody>
                            <tr>
                                <td style="width:50%">
                                    {{'general.name' | translate }}
                                </td>
                                <td style="width:50%">
                                    {{ customer?.name }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{'general.reference' | translate }}
                                </td>
                                <td>
                                    {{ customer?.referenceId }}
                                </td>
                            </tr>
                            <tr>
                                <td style="width:40%">
                                    {{ 'general.platform' | translate}}
                                </td>
                                <td style="width:60%">
                                    {{ customer?.platformName }}
                                </td>
                            </tr>
                            <tr>
                                <td style="width:40%">
                                    {{ 'general.accountPlatform' | translate}}
                                </td>
                                <td style="width:60%">
                                    <a *ngIf="permissions['HasPlatforms']" class="secondary link_bolder"
                                        href="/#/PlatformDetails/Index/{{customer?.accountPlatformId}}">{{
                                        customer?.accountPlatformName }}
                                    </a>
                                    <span
                                        *ngIf="!permissions['HasPlatforms']">{{ customer?.accountPlatformName }}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-memory"></i> {{'general.map' | translate }}</h5>
                </div>
                <div class="ibox-content slick" style="height:278px;;position:relative">
                    <ngx-loading [show]="loadingMapData"></ngx-loading>
                    <div style="height: 100%;" leaflet [leafletOptions]="options"
                        (leafletMapReady)="onMapReady($event)">
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="ibox float-e-margins" *ngIf="permissions['HasIssues']">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-chart-pie"></i> {{'general.kpis' | translate }}</h5>
                </div>
                <div class="ibox-content slick" style="min-height:100px;position:relative">
                    <ngx-loading [show]="loadingKpis"></ngx-loading>
                    <div class="hand"
                        style="width:50%; float:left; padding: 18px; margin:0px; background-color: #f9f9f9; border: 2px solid #fff"
                        [routerLink]="['/CustomerDetails/Issues/',customer?.id]"
                        [queryParams]="{DateStart: dateStartWeek, DateEnd: today}">
                        <h1 class="no-margins font-bold">{{ issuesThisWeek?.toLocaleString() }}</h1>
                        <small>{{'general.issues' | translate }} {{'dates.thisweek' | translate }}</small>
                    </div>
                    <div class="hand"
                        style="width:50%; float:left; padding: 18px; margin:0px; background-color: #f9f9f9; border: 2px solid #fff"
                        [routerLink]="['/CustomerDetails/Issues/', customer?.id]"
                        [queryParams]="{DateStart: dateStartMonth, DateEnd: today}">
                        <h1 class="no-margins font-bold">{{ issueCount?.toLocaleString() }}</h1>
                        <small>{{'general.issues' | translate }} {{'dates.thismonth' | translate }}</small>
                    </div>
                    <div class="hand"
                        style="width:50%; float:left; padding: 18px; margin:0px; background-color: #f9f9f9; border: 2px solid #fff"
                        [routerLink]="['/Issues/Devices']"
                        [queryParams]="{customer: customer?.name, DateStart: dateStartMonth, DateEnd: today}">
                        <h1 class="no-margins font-bold">{{ devicesWithIssues?.toLocaleString() }}</h1>
                        <small>{{'general.deviceswithissues' | translate }} {{'dates.thismonth' | translate }}</small>
                    </div>
                    <div class="hand"
                        style="width:50%; float:left; padding: 18px; margin:0px; background-color: #f9f9f9; border: 2px solid #fff"
                        [routerLink]="['/CustomerDetails/Devices/', customer?.id]">
                        <h1 class="no-margins font-bold">{{ deviceCount?.toLocaleString() }}</h1>
                        <small>{{'general.devices' | translate }}</small>
                    </div>

                    <div style="clear:both"></div>
                </div>
            </div>

            <fh-tagging [objectId]="customer?.id" [objectType]="tagType">
            </fh-tagging>

            <div class="ibox float-e-margins" *ngIf="permissions['HasIssues']">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-exclamation-circle"></i> {{'dashboard.issues' | translate }}</h5>
                    <span class="small pull-right"> {{'dates.lastmonth' | translate }}</span>
                </div>
                <div class="ibox-content slick" style="min-height:86px;position:relative">
                    <ngx-loading [show]="loadingIssueTypes"></ngx-loading>
                    <table class="table table-hover nowrap dtr-inline no-footer" style="width:100% ">
                        <thead>
                            <tr>
                                <th style="width:auto">{{'general.issue' | translate }}</th>
                                <th style="width:80px; text-align: right">{{'general.count' | translate }}</th>
                                <th style="width:0px"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let issueType of issueTypesResult | orderBy : 'count':true | slice:0:4; let i = index ">
                                <td style="width:100%">{{ issueType[0] }}</td>
                                <td style="width:80px; text-align: right">{{ issueType[1].issueCount?.toLocaleString()
                                    }}</td>
                                <td style="width:0px">
                                    <a class='btn btn-primary btn-outline btn-grid'
                                        href='/#/CustomerDetails/Issues/{{ customer?.id }}?IssueType={{issueType[1].issueId}}'>
                                        <i class='fas fa-fw fa-info'></i>
                                        <span class="hidden-sm hidden-xs">{{'general.details' | translate }}</span>
                                    </a>
                                </td>
                            </tr>
                            <tr *ngIf="issueTypesResult.length === 0">
                                <td colspan="3">
                                    {{'general.nodatafound' | translate }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>