import { Component, OnInit, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Subject } from 'rxjs/Subject';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceType } from 'app/models/devicetype.model';
import { tileLayer, latLng, circle, polygon, Map, marker, icon } from 'leaflet';
import { Subscription } from 'rxjs/Subscription';
import { Issue } from 'app/models/issue.model';
import { IssueService } from 'app/services/issues/issues.service';
import { DeviceService } from 'app/services/device/device.service';
import { CustomerService } from 'app/services/customers/customers.service';
import { IssueTypeService } from 'app/services/issuetypes/issuetypes.service';
import { IssueType } from 'app/models/issuetype.model';
import { MapService } from '../../services/common/map.service';
import { Customer } from '../../models/customer.model';

import * as moment from 'moment-timezone';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { TagObjectType } from 'app/models/tag.model';
import { getMapProviders, createMapOptions } from 'app/common/leafletGlobals';

declare var L;
declare var HeatmapOverlay;
declare var PruneCluster;
declare var PruneClusterForLeaflet;

@Component({
    selector: 'fh-customer-details',
    templateUrl: 'customerDetails.template.html',
    providers: [MapService],
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['customerDetails.template.css']
})
export class CustomerDetailsViewComponent implements OnInit {
    tagType = TagObjectType.Customer.valueOf();

    devices: any;
    markers: any;
    defaultMap: any;
    issueTypesResult: any;
    loadingIssueTypes: boolean;
    loadingKpis: boolean;
    issuesThisWeek: any;
    devicesWithIssues: any;
    deviceCount;
    issueCount;

    issues: Issue[];
    sub: Subscription;
    loading = false;
    loadingMapData = false;
    customer: Customer;

    options;
    layersControl;
    map: Map;
    nearbyDevices;
    marker;

    mapData = {
        data: []
    };

    heatmapLayer = new HeatmapOverlay({
        radius: 15,
        maxOpacity: 0.8,
        scaleRadius: false,
        useLocalExtrema: false,
        latField: 'lat',
        lngField: 'lng',
        valueField: 'count'
    });


    issueTypes: IssueType[];
    issueTypesFiltered: IssueType[];
    googleMaps: any;
    googleHybrid: any;
    leafletView: any;

    dateStartMonth: number;
    dateStartWeek: number;
    today: any;
    permissions: {};
    maps: { 'mapTiler': any; 'Google': any; 'Hybrid': any; };

    constructor(private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private theMapService: MapService, private http: HttpClient, private issueTypeService: IssueTypeService, private customerService: CustomerService, private deviceService: DeviceService, private issueService: IssueService, private route: ActivatedRoute, private router: Router) {
        this.customer = null;
        this.permissions = this.authenticationService.permissions;
        this.issueTypesFiltered = [];
        this.issueTypesResult = [];

        issueTypeService.getIssueTypes().subscribe(issueTypes => {
            this.issueTypes = issueTypes;
        });

        this.today = moment().tz(this.authenticationService.getTimeZoneIana()).endOf('day').add(-1, 'day').toDate().getTime();
        this.dateStartWeek = moment().tz(this.authenticationService.getTimeZoneIana()).endOf('day').add(-7, 'day').toDate().getTime();
        this.dateStartMonth = moment().tz(this.authenticationService.getTimeZoneIana()).endOf('day').add(-1, 'month').toDate().getTime();

        this.initMap();
    }

    setDeviceTypeDetails(customer: Customer): any {
        if (customer == null) {
            this.router.navigate(['/Customers/Overview'])
        } else {
            this.customer = customer;

            this.customerService.getCustomerDashboard(this.customer.id, this.dateStartMonth, this.today).subscribe(dashboard => {
                this.loadingKpis = false;
                this.issueCount = dashboard.issueCount;
                this.deviceCount = dashboard.deviceCount;
                this.devicesWithIssues = dashboard.deviceWithIssuesCount;
                this.issuesThisWeek = dashboard.issueThisWeekCount;

                this.cd.markForCheck();
            });

            // https://github.com/Asymmetrik/ngx-leaflet
        }
    }

    orderBy(array: any[], prop: string) {
        return array.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
    }

    orderByIssueCount(array: any[]) {
        return array.sort((a, b) => a[1].issueCount > b[1].issueCount ? 1 : a[1].issueCount === b[1].issueCount ? 0 : -1);
    }

    ngOnInit() {
        this.permissions = this.authenticationService.permissions;

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.loading = true;
            this.loadingIssueTypes = true;
            this.loadingKpis = true;

            const customerServiceService = this.customerService.getCustomerById(id).subscribe(deviceType => {
                this.loading = false;
                this.setDeviceTypeDetails(deviceType);
                this.cd.markForCheck();
            });

            this.customerService.getIssuesPerIssueType(id, this.dateStartMonth, this.today).subscribe(result => {
                this.issueTypesResult = this.orderByIssueCount(result);
                this.loadingIssueTypes = false;
                this.cd.markForCheck();
            });

            this.fillDevices(id);
        });
    }

    // Leaflet
    initMap() {


        this.markers = L.featureGroup();
        this.leafletView = new PruneClusterForLeaflet();

        this.maps = getMapProviders(L);

        const initMaps = [];
        initMaps.push(Object.values(this.maps)[0]);
        initMaps.push(this.leafletView);

        this.options = createMapOptions(L, initMaps);

        const that = this;

        setTimeout(function () {
            that.setPruneCluster(); // Marker clluster version
        }, 100);
    }


    onMapReady(map: Map) {
        this.map = map;
        const that = this;

        const overlayMaps = {
            'Prunecluster': this.leafletView,
            'Heatmap': this.heatmapLayer,
            // 'Markers': this.markers
        };

        L.control.layers(this.maps, overlayMaps, { position: 'topleft' }).addTo(map);

        // Easybuttona

        L.easyButton({
            id: 'fit map button',
            states: [{
                stateName: 'add-markers',
                icon: 'fa-expand-arrows-alt',
                title: 'Fit map',
                onClick: function (control) {
                    that.centerMap();
                }
            }]
        }).addTo(this.map);

        const heatmapToggle = L.easyButton({
            id: 'animated-heatmap-toggle',
            states: [{
                stateName: 'remove-markers',
                title: 'Remove heatmap',
                icon: 'fa-undo',
                onClick: function (control) {
                    that.map.removeLayer(that.heatmapLayer);
                    control.state('add-markers');
                }
            }, {
                stateName: 'add-markers',
                icon: 'fa-map',
                title: 'Show heatmap',
                onClick: function (control) {
                    that.map.addLayer(that.heatmapLayer);
                    control.state('remove-markers');
                }
            }]
        });
        heatmapToggle.addTo(this.map);

        const animatedToggle = L.easyButton({
            id: 'animated-marker-toggle',
            states: [{
                stateName: 'add-markers',
                icon: 'fa-map-marker',
                title: 'Show markers',
                onClick: function (control) {
                    that.map.addLayer(that.leafletView);
                    control.state('remove-markers');
                }
            }, {
                stateName: 'remove-markers',
                title: 'Remove markers',
                icon: 'fa-undo',
                onClick: function (control) {
                    that.map.removeLayer(that.leafletView);
                    control.state('add-markers');
                }
            }]
        });
        animatedToggle.addTo(this.map);

        // End easybutton
    }


    createIcon() {
        return L.icon({
            iconSize: [25, 41],
            iconAnchor: [13, 41],
            iconUrl: 'assets/marker-icon.png',
            shadowUrl: 'assets/marker-shadow.png'
        });
    }

    setPruneCluster() {

        const that = this;
        this.theMapService.setPruneCluster(this.leafletView);

        this.leafletView.PrepareLeafletMarker = function (theMarker, data, category) {
            // parse data to icon
            theMarker.setIcon(that.createIcon());

            if (theMarker.getPopup()) {
                theMarker.setPopupContent(data.title + ' - ' + category);
            } else {
                theMarker.bindPopup(data.title + ' - ' + category);
            }
        };
    }



    fillDevices(id) {
        const that = this;
        this.deviceService.getDevicesByCustomer(id).subscribe(devices => {
            this.devices = devices;
            this.devices.forEach(device => {
                if (device.lastPosition) {

                    // Prune cluster

                    const theMarker = new PruneCluster.Marker(device.lastPosition.latitude, device.lastPosition.longitude, { title: device.name });

                    theMarker.category = Math.ceil(Math.random() * 6).toString();
                    theMarker.data.popup = device.name;
                    theMarker.data.device = device;

                    this.leafletView.RegisterMarker(theMarker);

                    // Heatmap
                    this.mapData.data.push({
                        lat: device.lastPosition.latitude,
                        lng: device.lastPosition.longitude,
                        count: 1
                    });

                    // Normal marker
                    const theNormalMarker = marker([device.lastPosition.latitude, device.lastPosition.longitude], {
                        icon: icon({
                            iconSize: [25, 41],
                            iconAnchor: [13, 41],
                            iconUrl: 'assets/marker-icon.png',
                            shadowUrl: 'assets/marker-shadow.png'
                        })
                    });

                    this.markers.addLayer(theNormalMarker);
                }
            });

            this.heatmapLayer.setData(this.mapData);
            this.loading = false;

            this.centerMap();

        });
    }

    centerMap() {
        const bounds = this.leafletView.Cluster.ComputeGlobalBounds();
        if (bounds) {
            this.map.fitBounds(new L.LatLngBounds(new L.LatLng(bounds.minLat, bounds.maxLng), new L.LatLng(bounds.maxLat, bounds.minLng)), { padding: [50, 50] });
        }
    }
}
