import { Component, OnInit } from '@angular/core';
import { smoothlyMenu } from '../../../app.helpers';
import { CookieService } from 'ngx-cookie-service';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { Subject } from 'rxjs/Subject';
import { SearchService } from '../../../services/search/search.service';

declare var jQuery: any;

import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { Http } from '@angular/http';
import { Observer } from 'rxjs/Observer';
import { switchMap, delay, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CacheService } from '../../../services/common/cache.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'fh-topnavbar',
  templateUrl: 'topnavbar.template.html',
  providers: [SearchService]
})
export class TopNavbarComponent implements OnInit {

  asyncSelected: any;
  form: any;
  api_url: string;
  id: string;
  results: Object;
  autoComplete$: Observable<string[]>;
  isImpersonated = false;

  autoCompleteSearchTerm: string;
  typeaheadLoading: boolean;
  typeaheadNoResults: boolean;

  constructor(private router: Router, private http: Http, private cacheService: CacheService, private cookieService: CookieService, private authenticationService: AuthenticationService, private searchService: SearchService) {
  }

  stopImpersonation() {
    this.router.navigate(['/']).then(response => {
      this.authenticationService.stopImpersonation();
    });
  }

  ngOnInit(): void {
    this.isImpersonated = this.authenticationService.getIsImpersonated();

    this.id = this.authenticationService.getId();

    this.autoComplete$ = Observable.create((observer: any) => {
      this.actualSearch(this.asyncSelected).subscribe((result: any) => {
        observer.next(result);
      })
    });
  }

  actualSearch(search) {
    console.log(search);
    return this.searchService.searchEntries(search).debounceTime(300).distinctUntilChanged();
  }

  toggleNavigation(): void {
    jQuery('body').toggleClass('mini-navbar');
    smoothlyMenu();
  }

  logout() {
    // Logout from application ... clearing tokens and redirecting
    this.cookieService.delete('SsoToken', '/');
    this.cacheService.clearCache();
    this.authenticationService.clearToken();
  }

  onSubmit() {
    console.log('Search on');
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  typeaheadOnSelect(e: TypeaheadMatch): void {
    console.log('Selected value: ', e);
    // Device
    if (e.item.group === 1) {
      this.router.navigate(['/DeviceDetails/Index/' + e.item.id])
    }

    // DeviceType
    if (e.item.group === 2) {
      this.router.navigate(['/DeviceTypeDetails/Index/' + e.item.id])
    }

    // Issue
    if (e.item.group === 3) {
      this.router.navigate(['/IssueDetails/Index/' + e.item.id])
    }

    // Customer
    if (e.item.group === 5) {
      this.router.navigate(['/CustomerDetails/Index/' + e.item.id])
    }

    // Asset
    if (e.item.group === 6) {
      this.router.navigate(['/AssetDetails/Index/' + e.item.id])
    }
  }

  goToOverview() {
    window.open(environment.FORedirectUrl + 'Login/?token=' + this.authenticationService.getAuthToken() + '&expired=' + this.authenticationService.getAuthTokenExpired());
  }
}
