import { Component, OnInit, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Subject } from 'rxjs/Subject';
import { DeviceTypeService } from 'app/services/devicetypes/devicetypes.service';
import { IssueTypeService } from 'app/services/issuetypes/issuetypes.service';
import { Chart } from 'angular-highcharts';
import { DeviceService } from 'app/services/device/device.service';
import { IssueService } from 'app/services/issues/issues.service';
import { Issue } from 'app/models/issue.model';
import { DeviceType } from 'app/models/devicetype.model';
import { IssueType } from 'app/models/issuetype.model';
import { ToastrService } from 'ngx-toastr';
import { NotificationsService } from '../../modules/notification';
import { BsLocaleService, BsDaterangepickerConfig } from 'ngx-bootstrap';
import { trigger, transition, animate, style, state, group } from '@angular/animations'
import { getDefaultDpConfig } from 'app/common/gridhelper';

@Component({
    selector: 'fh-device-types-chart',
    templateUrl: 'charts.template.html',
    styleUrls: ['charts.template.css'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('slideInOut', [
            state('in', style({ height: '*', opacity: 0.5 })),
            transition(':leave', [
                style({ height: '*', opacity: 1 }),
                group([
                    animate(300, style({ height: 0 })),
                    animate('200ms ease-in-out', style({ 'opacity': '0.5' })),
                    animate('200ms ease-in', style({ transform: 'translateY(-100%)' }))
                ])
            ]),
            transition(':enter', [
                style({ height: '0', opacity: 0.5 }),
                group([
                    animate(300, style({ height: '*' })),
                    style({ transform: 'translateY(-100%)' }),
                    animate('400ms ease-in-out', style({ 'opacity': '1' })),
                    animate('200ms ease-in', style({ transform: 'translateY(0%)' }))
                ])
            ])
        ])
    ]
})
export class IssuesChartViewComponent implements OnInit, AfterViewInit {
    loading = false;
    dropdown_IssueType = '';
    chart1: Chart;
    chart2: Chart;
    chart3: Chart;
    selectSource;

    deviceTypes: DeviceType[];
    issueTypes: IssueType[];

    public nav: any;
    loadingIssues = false;
    loadingLocationChart = false
    deviceTypeCount;
    issueCount;
    deviceCount;

    private issueCountObservable = new Subject<number>();
    issueCountObservable$ = this.issueCountObservable.asObservable();
    loadingDevices = false;

    // Daterange
    public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
    today = new Date();
    daterangepickerModel: Date[];
    maxDate = new Date();
    chartData: any;

    showFilter = true;
    constructorName = this.constructor.name;



    public constructor(private localeService: BsLocaleService, private deviceTypeService: DeviceTypeService, private issueTypeService: IssueTypeService, private deviceService: DeviceService, private issueService: IssueService, private toastr: ToastrService, protected notificationsService: NotificationsService) {
        this.loadingDevices = true;
        this.loadingIssues = true;
        this.loadingLocationChart = true;

        this.selectSource = 'devicetype'
        // Filtering
        const that = this;

        this.maxDate.setDate(this.today.getDate() - 7);
        this.daterangepickerModel = [this.maxDate, this.today];

        this.dpConfig = getDefaultDpConfig();

        this.dateChanged(this.daterangepickerModel);
        // fetch issuetypes
        this.issueTypeService.getIssueTypes().subscribe(issueTypes => {
            this.issueTypes = issueTypes;
        });
    }

    public ngOnInit(): any {

    }

    fireFilter(event = null): void {
        this.generateIssuePerDeviceTypeCharts(this.chartData);
    }

    getChartData(event) {
        this.loading = true;
        const that = this;

        if (event != null) {
            this.deviceTypeService.getDeviceTypesChart(event[0].getTime(), event[1].getTime()).subscribe(chartData => {
                this.chartData = chartData;
                this.generateIssuePerDeviceTypeCharts(chartData);
                this.loadingDevices = false;
            },
                error => {

                    console.log(error);

                    that.toastr.error(error.statusText
                        , 'Error', {
                        progressBar: true
                    });

                    that.notificationsService.addError(error.statusText, error.message);
                });
        }
    }

    dateChanged(event) {
        if (event != null) {
            const that = this;
            this.getChartData(event);
        }
    }

    generateIssuePerDeviceTypeCharts(chartData) {

        // tslint:disable-next-line:prefer-const
        let theDeviceTypes = [];

        const theTempData2 = [];
        const theData = [];
        const theData2 = [];
        const theData3 = [];
        const that = this;
        const deviceTypeNames = [];
        const index = 0;

        let theManufacturers = [];
        const theTempData3 = [];
        const theData4 = [];
        const theData5 = [];
        const theData6 = [];

        chartData.forEach(aggrigatedListItem => {
            if (theDeviceTypes.indexOf(aggrigatedListItem.deviceTypeName) === -1) {
                theDeviceTypes.push(aggrigatedListItem.deviceTypeName);
                theTempData2[aggrigatedListItem.deviceTypeName] = aggrigatedListItem.issueTypes;
            }
            if (theManufacturers.indexOf(aggrigatedListItem.manufactorer) === -1) {
                theManufacturers.push(aggrigatedListItem.manufactorer);
                theTempData3[aggrigatedListItem.manufactorer] = aggrigatedListItem.issueTypes;
            }
        });

        that.issueTypeService.getIssueTypes().subscribe(issueTypes => {
            issueTypes.forEach(issueType => {

                const theDataIssueName = issueType.name;
                const theSubData = [];
                const theSubData2 = [];
                const theSubData3 = []

                const theSubData4 = [];
                const theSubData5 = [];
                const theSubData6 = [];

                theDeviceTypes = theDeviceTypes.sort(function (a, b) {
                    if (a < b) { return -1; }
                    if (a > b) { return 1; }
                    return 0;
                });

                theDeviceTypes.forEach(deviceType => {
                    const theDeviceIssues = theTempData2[deviceType];
                    const theDataIssueAmount = theDeviceIssues.filter(x => x.issueTypeId === issueType.id)[0];

                    if (theDataIssueAmount) {
                        theSubData.push(theDataIssueAmount.issueCount);
                        theSubData2.push(theDataIssueAmount.issuePercentage);
                        theSubData3.push(theDataIssueAmount.issuePercentageDevice);
                    } else {
                        theSubData.push(0);
                        theSubData2.push(0);
                        theSubData3.push(0);
                    }
                });

                theManufacturers = theManufacturers.sort(function (a, b) {
                    if (a < b) { return -1; }
                    if (a > b) { return 1; }
                    return 0;
                });

                theManufacturers.forEach(manufacturers => {
                    const theDeviceIssues = theTempData3[manufacturers];
                    const theDataIssueAmount = theDeviceIssues.filter(x => x.issueTypeId === issueType.id)[0];

                    if (theDataIssueAmount) {
                        theSubData4.push(theDataIssueAmount.issueCount);
                        theSubData5.push(theDataIssueAmount.issuePercentage);
                        theSubData6.push(theDataIssueAmount.issuePercentageDevice);
                    } else {
                        theSubData4.push(0);
                        theSubData5.push(0);
                        theSubData6.push(0);
                    }
                });

                const theSeries = { name: theDataIssueName, data: theSubData };
                const theSeries2 = { name: theDataIssueName, data: theSubData2 };
                const theSeries3 = { name: theDataIssueName, data: theSubData3 };

                const theSeries4 = { name: theDataIssueName, data: theSubData4 };
                const theSeries5 = { name: theDataIssueName, data: theSubData5 };
                const theSeries6 = { name: theDataIssueName, data: theSubData6 };


                theData.push(theSeries);
                theData2.push(theSeries2);
                theData3.push(theSeries3);

                theData4.push(theSeries4);
                theData5.push(theSeries5);
                theData6.push(theSeries6);
            });

            if (this.selectSource === 'manufacturer') {
                this.chart1 = this.drawChart(theManufacturers, theData4);
                this.chart2 = this.drawChartPercentage(theManufacturers, theData5);
                this.chart3 = this.drawChartPercentage(theManufacturers, theData6);
            } else {
                this.chart1 = this.drawChart(theDeviceTypes, theData);
                this.chart2 = this.drawChartPercentage(theDeviceTypes, theData2);
                this.chart3 = this.drawChartPercentage(theDeviceTypes, theData3);
            }

            this.loading = false;
        });
    }

    drawChart(theDeviceTypes, theData) {

        return new Chart({
            chart: {
                type: 'column'
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            credits: { enabled: false },
            xAxis: {
                categories: theDeviceTypes,
                type: 'category',
                labels: {
                    rotation: 45,
                    style: {
                        fontSize: '10px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Total issues'
                },
                stackLabels: {
                    enabled: false,
                    style: {
                        fontWeight: 'normal',
                        color: 'white'
                    }
                }
            },
            legend: {
                align: 'right',
                verticalAlign: 'middle',
                layout: 'vertical'
            },
            tooltip: {
                headerFormat: '<b>{point.x}</b><br/>',
                pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: false,
                        color: 'white'
                    }
                }
            },
            series: theData,
            colors: ['#CB711D', '#776655', '#995522', '#7F5E3F', '#A85100', '#884411', '#4C443D', '#663300', '#663100', '#2B2B2B', '#331800', '#221100', '#111110', '#EAE6E3', '#DDDAD7', '#DDCCBB', '#DDCCAA', '#D8C2AD', '#F2B379', '#AA9988']
        });
    }

    drawChartPercentage(theDeviceTypes, theData) {

        return new Chart({
            chart: {
                type: 'column'
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            credits: { enabled: false },
            xAxis: {
                categories: theDeviceTypes,
                type: 'category',
                labels: {
                    rotation: 45,
                    style: {
                        fontSize: '10px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                max: 100,
                title: {
                    text: 'Total issues %'
                },
                stackLabels: {
                    enabled: false,
                    style: {
                        fontWeight: 'normal',
                        color: 'white'
                    }
                }
            },
            legend: {
                align: 'right',
                verticalAlign: 'middle',
                layout: 'vertical'
            },
            tooltip: {
                headerFormat: '<b>{point.x}</b><br/>',
                pointFormat: '{series.name}: {point.y} %<br/>Total: {point.stackTotal} %'
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: false,
                        color: 'white'
                    }
                }
            },
            series: theData,
            colors: ['#CB711D', '#776655', '#995522', '#7F5E3F', '#A85100', '#884411', '#4C443D', '#663300', '#663100', '#2B2B2B', '#331800', '#221100', '#111110', '#EAE6E3', '#DDDAD7', '#DDCCBB', '#DDCCAA', '#D8C2AD', '#F2B379', '#AA9988']
        });
    }

    ngAfterViewInit() {
    }
}
