import { Component, OnDestroy, OnInit, ChangeDetectorRef, ViewChild, } from '@angular/core';

import { AuthenticationService } from '../../services/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { Asset } from 'app/models/asset.model';
import { FormMode } from 'app/common/enums';
import { AssetService } from 'app/services/asset/asset.service';
import { AssetTypeService } from 'app/services/asset/assetTypes.service';
import { AssetType } from 'app/models/assetType.model';
import { Router } from '@angular/router';

@Component({
  selector: 'fh-trailers-add',
  templateUrl: 'add.template.html'
})
export class TrailerAddViewComponent {
  token: string;
  addDevice = false;
  asset: Asset;
  formMode = FormMode.add;
  loading = false;
  error: any;
  assetTypes: AssetType[] = [];
  success: { statusText: string; success: string; };

  constructor(private router: Router, private assetTypeService: AssetTypeService, private assetService: AssetService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService) {
    this.asset = new Asset();
    this.asset.assetClass = 2;

    this.token = this.authenticationService.getAuthToken();
  }

  onInsert() {
    this.loading = true;

    this.assetService.saveAsset(this.asset).subscribe(result => {
      this.success = {
        statusText: 'Success',
        success: 'You will be redirected to the trailer'
      };

      setTimeout(() => {
        this.router.navigate(['/TrailerDetails/Index/' + result.id]);
      }, 2000);

      this.assetService.resetAssetCache().subscribe(res => {
        console.log('Assets are cleared');
        console.log(res);
      });

      this.loading = false;
    }, error => {
      this.error = error
      this.loading = false;
    });
  }

  updateAssetType(event) {

    console.log(event);

    if (!event.id) {
      return;
    }

    this.asset.assetTypeId = event.id;
    this.asset.assetTypeName = event.name;
    this.asset.brand = event.brand;
    this.asset.model = event.model;
  }
}
