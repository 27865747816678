<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Drivers/Overview">{{'general.driver' | translate }}</a>
            </li>
            <li class="active">
                <strong>{{'general.driverDetails' | translate }}</strong>
            </li>
        </ol>
    </div>
</div>


<div class="wrapper wrapper-content">

    <div class="row" data-equalizer="" *ngIf="error || success">
        <div class="col-lg-12">
            <fh-error [error]="error" [success]="success"></fh-error>
        </div>
    </div>

    <div class="row" data-equalizer="">
        <div class="col-lg-6">

            <form #userForm="ngForm" #formDir="ngForm">
                <div class="ibox float-e-margins">
                    <div class="ibox-title">
                        <h5>
                            <i class="fa fa-address-card"></i> {{'general.driver' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content slick" style="position:relative">
                        <ngx-loading [show]="loadingDriver"></ngx-loading>
                        <fh-driver-details [driver]="driver" [formMode]="formMode"></fh-driver-details>
                    </div>
                    <fh-save-footer [valid]="!userForm.invalid" (onSave)="onSave()" (onDelete)="onDelete()"
                        [allowDelete]="permissions['HasWasl']" (setFormMode)="setFormMode($event)"
                        [formMode]="formMode">
                    </fh-save-footer>
                </div>
            </form>
        </div>
        <div class="col-lg-6">

            <fh-error [error]="waslError" [success]="waslSuccess"></fh-error>
            <form #waslForm="ngForm" #formDir="ngForm">
                <div *ngIf="permissions['HasWasl'] && !loadingDriver && waslForm.invalid"
                    class="alert alert-warning shadow">
                    <p *ngIf="waslForm.invalid">- {{'general.waslFormInvalid' | translate }}</p>
                </div>

                <div class="ibox float-e-margins" style="position:relative">
                    <ngx-loading [show]="loadingDriver || loadingDriverWasl"></ngx-loading>
                    <div class="ibox-title">
                        <h5>
                            <i class="fa fa-user-shield"></i> {{'general.wasl' | translate }}
                        </h5>
                    </div>
                    <fh-driver-wasl-details [driver]="driver" [formMode]="formModeWasl"></fh-driver-wasl-details>
                    <fh-save-footer [valid]="!waslForm.invalid" [allowDelete]="false" (onSave)="onSave()"
                        (setFormMode)="setFormModeWasl($event)" [formMode]="formModeWasl"
                        [showButtons]="!driver.properties.wasl.referenceKey">
                        <button class="btn btn-primary" [disabled]="waslForm.invalid || formModeWasl != 1"
                            *ngIf="!driver.properties.wasl.referenceKey" (click)="register()"><i
                                class="fa fa-fw fa-edit"></i>
                            {{'general.register' | translate }}</button>

                        <button class="btn btn-danger" [disabled]="waslForm.invalid || formModeWasl != 1"
                            *ngIf="driver.properties.wasl.referenceKey" (click)="unRegister()"><i
                                class="fa fa-fw fa-times"></i>
                            {{'general.unRegister' | translate }}</button>
                    </fh-save-footer>
                </div>
            </form>

            <fh-tagging [objectId]="driver?.id" [objectType]="tagType">
            </fh-tagging>

        </div>
    </div>
</div>