<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Assets/Overview">{{'general.assets' | translate }}</a>
            </li>
            <li>
                <a href="/#/AssetDetails/Index/{{asset?.id}}">{{asset?.name}}</a>
            </li>
            <li class="active">
                <strong>{{'menu.trips' | translate }}</strong>
            </li>
        </ol>
    </div>
</div>



<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="">
        <div class="col-lg-6">
            <fh-error [error]="error" [success]="success"></fh-error>

            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-filter"></i> {{'general.filter' | translate }}</h5>
                </div>
                <div class="ibox-content slick" style="position: relative">
                    <table style="width:100%" class="table table-hover nowrap dtr-inline no-footer">
                        <tbody>
                            <tr>
                                <td style="width:50%">
                                    {{ 'general.date' | translate}}
                                </td>
                                <td colspan="2" style="width:50%">
                                    <input [(ngModel)]="daterangepickerModel" (onHidden)="dateChanged($event)"
                                        name="from" type="text" autocomplete="off" class="form-control table-inline"
                                        [bsConfig]="dpConfig" bsDaterangepicker>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="ibox-footer">
                    <button (click)="dateChanged(null)" class="btn btn-primary">
                        <i class="fa fa-fw fa-sync-alt"></i>
                        {{'general.refresh' | translate }}</button>
                </div>
            </div>

            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <span class="pull-left">
                        <h5>
                            <i class="fas fa-fw fa-cloud"></i> {{'menu.trips' | translate }}
                        </h5>
                    </span>
                    <span class="pull-right">
                        {{'dashboard.pageNumber' | translate:{current: currentPage, max: smallnumPages} }}
                    </span>
                </div>
                <div class="ibox-content" style="position: relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <table class="table table-hover nowrap dtr-inline no-footer" style="width:100%; margin-bottom:0px ">
                        <thead>
                            <tr>
                                <th style="width:125px">{{'general.date' | translate }}</th>
                                <th style="width:auto">{{'general.details' | translate }}</th>
                                <th style="width:20px"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let trip of trips | orderBy : 'beginDateTime':true | slice:((currentPage - 1) * 10): ((currentPage - 1) * 10) + 10">
                                <td style="width:125px">{{ trip.beginDateTime | amDateFormat:'YYYY-MM-DD HH:mm' }}</td>
                                <td style="width:auto">{{ trip.beginCity }} / {{trip.segmentDistanceInKilometers}} km
                                </td>
                                <td style="width:20px">
                                    <a class='btn btn-primary btn-outline btn-grid' (click)="getTripDetails(trip)">
                                        <i class='fas fa-fw fa-info'></i>
                                        <span class="hidden-sm hidden-xs">{{'general.details' | translate }}</span>
                                    </a>
                                </td>
                            </tr>
                            <tr *ngIf="trips && trips.length == 0">
                                <td colspan="4">No trip found in this daterange</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="ibox-footer">
                    <pagination [totalItems]="totalItems" [(ngModel)]="currentPage" (pageChanged)="pageChanged($event)"
                        (numPages)="smallnumPages = $event"></pagination>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="ibox float-e-margins ">
                <div class="ibox-title ">
                    <h5>
                        <i class="fa fa-map "></i> {{'general.location' | translate }}</h5>
                </div>
                <div class="ibox-content slick" style="height: 500px; position: relative">
                    <ngx-loading [show]="loadingTrip"></ngx-loading>
                    <div style="height: 100%; " leaflet [leafletOptions]="options"
                        (leafletMapReady)="onMapReady($event) ">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>