<div class="ibox-content slick" style="position:relative">
    <table *ngIf="driver" style="width:100%" class="table table-hover nowrap dtr-inline no-footer">
        <tbody>
            <tr>
                <td style="width:50%">
                    {{ 'general.waslReference' | translate}}
                </td>
                <td colspan="3" style="width:50%; padding-left: 18px">
                    {{ (driver.properties.wasl.referenceKey ? 'general.true' : 'general.false') | translate}}
                </td>
            </tr>
            <tr>
                <td style="width:50%">
                    {{ 'general.identityNumber' | translate}}
                    <span class="error" *ngIf="identityNumber.invalid && formMode != 1">*</span>
                </td>
                <td colspan="3" style="width:50%">
                    <input type="text" [readonly]="driver.properties.wasl.referenceKey || formMode == 1"
                        name="identityNumber" class="form-control table-inline"
                        [(ngModel)]="driver.properties.wasl.identityNumber" required minlength="4"
                        #identityNumber="ngModel">
                </td>
            </tr>
            <tr>
                <td style="width:50%">
                    {{ 'general.mobileNumber' | translate}}
                    <span class="error" *ngIf="mobileNumber.invalid && formMode != 1">*</span>
                </td>
                <td colspan="3" style="width:50%">
                    <input type="text" [readonly]="driver.properties.wasl.referenceKey || formMode == 1"
                        name="mobileNumber" class="form-control table-inline"
                        [(ngModel)]="driver.properties.wasl.mobileNumber" required minlength="4"
                        #mobileNumber="ngModel">
                </td>
            </tr>
            <tr>
                <td style="width:50%">
                    {{ 'general.dateOfBirthGregorian' | translate}}
                    <span class="error" *ngIf="dateOfBirthGregorian.invalid && formMode != 1">*</span>
                </td>
                <td colspan="3" style="width:50%">
                    <input type="text" [readonly]="driver.properties.wasl.referenceKey || formMode == 1"
                        name="dateOfBirthGregorian" class="form-control table-inline"
                        [(ngModel)]="driver.properties.wasl.dateOfBirthGregorian" required minlength="6"
                        #dateOfBirthGregorian="ngModel">
                </td>
            </tr>
        </tbody>
    </table>
</div>