import { Component, OnInit, AfterViewInit } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { DeviceService } from '../../services/device/device.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Device } from 'app/models/device.model';

@Component({
    selector: 'fh-device-calculation-settings',
    templateUrl: 'deviceCalculationSettings.template.html'
})
export class DeviceCalculationSettingsViewComponent implements OnInit {
    sub;
    device: Device;

    constructor(private http: HttpClient, private deviceService: DeviceService, private route: ActivatedRoute, private router: Router) {
        this.device = null;
    }

    ngOnInit() {
        this.device = new Device;
        this.device.id = ''

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];
            this.deviceService.getDeviceById(id).subscribe(device => {
                this.device = device;

                if (this.device == null) {
                    this.router.navigate(['/Devices/Overview'])
                }
            });
        });
    }
}
