import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { DataTablesModule } from 'angular-datatables';

import { DashboardComponent } from './appviews/dashboard.component';
import { UnsubscribeComponent } from './appviews/unsubscribe.component';

// Platform
import { PlatformViewComponent } from './platforms/platforms.component';
import { PlatformDetailsViewComponent } from './platforms/platformDetails.component';
import { PlatformDevicesViewComponent } from './platforms/platformDevices.component';

// Device
import { DevicesViewComponent } from './devices/devices.component';
import { DeviceDetailsViewComponent } from './devices/deviceDetails.component';
import { DeviceAssetViewComponent } from 'app/views/devices/assetDetails.component';
import { DeviceFuelViewComponent } from 'app/views/devices/deviceFuel.component';
import { DevicesIssuesViewComponent } from './issues/deviceIssues.component';
import { DeviceCalculationSettingsViewComponent } from './devices/deviceCalculationSettings.component';

import { DeviceSettingsViewComponent } from 'app/views/devices/deviceSettings.component';
import { DeviceLocationsViewComponent } from 'app/views/devices/deviceLocations.component';
import { DeviceTripsViewComponent } from 'app/views/devices/trips.component';
import { DevicesMapViewComponent } from './devices/devicesMap.component';
import { DevicesMap2ViewComponent } from 'app/views/devices/devicesMap2.component';
import { DeviceLogViewComponent } from './devices/deviceLog.component';
import { DeviceIssuesViewComponent } from './devices/deviceIssues.component';

// Issues
import { IssuesViewComponent } from './issues/issues.component';
import { IssuesMapViewComponent } from './issues/issuesMap.component';
import { ReportingViewComponent } from './reporting/reporting.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

// Customers
import { CustomersViewComponent } from './customers/customers.component';
import { CustomerDetailsViewComponent } from './customers/customerDetails.component';
import { CustomerDevicesViewComponent } from './customers/customerDevices.component';
import { CustomerCalculationSettingsViewComponent } from './customers/customerCalculationSettings.component';
import { CustomerIssuesViewComponent } from './customers/customerIssues.component';

// Users
import { UsersViewComponent } from './users/users.component';
import { UserDetailsViewComponent } from './users/userDetails.component';
import { UserAddViewComponent } from './users/userAdd.component';

// Settings
import { SettingsComponent } from './settings/settings.component';
import { ThemeSettingsComponent } from './settings/theme.component';
import { LanguageSettingsComponent } from './settings/language.component';
import { WorkflowSettingsComponent } from './settings/workflow.component';
import { ErrorLogComponent } from './settings/errorlog.component';
import { CalculationSettingsOverViewComponent } from './settings/calculationSettingsOverview.component';
import { CalculationSettingsOverViewTableComponent } from './settings/calculationSettingsOverviewTable.component';

// Error
import { Error404Component } from './appviews/error404.component';

// Search
import { SearchViewComponent } from './appviews/search.component';

// Schedule
import { ScheduleViewComponent } from './schedule/schedule.component';
import { CalendarViewComponent } from './schedule/calendar.component';

// Calendar
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

// Forms
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './appviews/login.component';
import { RegisterComponent } from './appviews/register.component';

// Modules
import { ChartModule } from 'angular-highcharts';
import { HttpClientModule } from '@angular/common/http';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { PrettyJsonModule } from '../modules/prettyjson/prettyjson.module';

// Leaflet
import { LeafletModule } from '@asymmetrik/ngx-leaflet';

// Color picker
import { ColorPickerModule } from 'ngx-color-picker';
import { TranslateModule } from '@ngx-translate/core';

// Issues
import { IssueLogViewComponent } from './issues/issueLog.component';
import { IssueDetailsViewComponent } from './issues/issueDetails.component';
import { IssueDetailsChartViewComponent } from './issues/issueDetailsChart.component';

// Device types
import { DevicesTypeViewComponent } from './devicetypes/deviceTypes.component';
import { DeviceTypeDetailsViewComponent } from './devicetypes/deviceTypeDetails.component';
import { DeviceTypeIssuesViewComponent } from './devicetypes/deviceTypeIssues.component';
import { DeviceTypeDevicesViewComponent } from './devicetypes/deviceTypeDevices.component';
import { IssuesChartViewComponent } from './issues/charts.component';

// NGX-Bootstrap
import { ModalModule, TimepickerModule } from 'ngx-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { SortableModule } from 'ngx-bootstrap/sortable';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

// Modules
import { ICheckModule } from '../modules/icheck/icheck.module';
import { WizardModule } from '../modules/wizard/wizard.module';

import { TimezoneSelectorModule } from '../modules/timezoneselector/timezoneselector.module';

import { CountoModule } from '../modules/counto/counto.module';
import { AboutViewComponent } from './appviews/about.component';
import { HelpViewComponent } from './appviews/help.component';

// IboxTools
import { IboxToolsModule } from './shared/iboxtools/iboxtools.module';
import { UsercontrolsModule } from './shared/usercontrols.module';
import { DeviceTypeCalculationSettingsViewComponent } from './devicetypes/deviceTypeCalculationSettings.component';
import { PlatformCalculationSettingsViewComponent } from './platforms/platformCalculationSettings.component';
import { CalculationSettingsViewComponent } from './settings/calculationSettings.component';
import { AccountViewComponent } from './accounts/accounts.component';
import { AccountDetailsViewComponent } from './accounts/accountDetails.component';
import { AccountCalculationSettingsViewComponent } from './accounts/accountCalculationSettings.component';
import { AccountWithUseViewComponent } from './accounts/accountsWithUse.component';
import { UserRoleDetailsViewComponent } from './users/userRoleDetails.component';
import { ProfileComponent } from './settings/profile.component';
import { ForgotComponent } from './appviews/forgotPassword.component';
import { AccountAddViewComponent } from './accounts/accountAdd.component';
import { SensorSettingsComponent } from './settings/sensorSettings.component';
import { SensorDetailsComponent } from './settings/sensorDetails.component';
import { SensorDevicesComponent } from './settings/sensorDevices.component';
import { SettingsGeneralComponent } from './settings/general.component';

// Authentication callback components
import { EndsessionCallbackComponent } from './appviews/sso-endsession-callback.component';
import { AuthCallbackComponent } from './appviews/sso-auth-callback.component';
import { SsoLoginComponent } from './appviews/sso-login.component';
import { GroupedIssuesViewComponent } from './issues/groupedIssues.component';

// Jobs
import { JobsActiveViewComponent } from './jobs/active.component';
import { JobsHistoryViewComponent } from './jobs/history.component';

// Pipes
import { FilterRelevantPipe } from 'app/common/relevant.pipe';
import { TextFilterPipe } from 'app/common/textFilter.pipe';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { MomentModule } from 'angular2-moment';
import { UserRolesViewComponent } from './users/userRoles.component';
import { UserLogViewComponent } from './users/userLog.component';
import { PlatformIssuesViewComponent } from './platforms/platformIssues.component';

// Tags (chips)
import { TagInputModule } from 'ngx-chips';
import { TagsViewComponent } from './appviews/tags.component';
import { TagListViewComponent } from './appviews/taglist.component';
import { WaslViewComponent } from './wasl/wasl.component';
import { DeviceSensorsViewComponent } from './devices/sensorDetails.component';
import { AssetViewComponent } from './assets/assets.component';
import { AssetDetailsViewComponent } from './assets/assetDetails.component';
import { AssetLogViewComponent } from './assets/assetLog.component';
import { AssetDeviceDetailsViewComponent } from './assets/assetDeviceDetails.component';
import { AssetTrailersViewComponent } from './assets/trailers.component';
import { AssetAddViewComponent } from './assets/add.component';
import { CustomerAssetsViewComponent } from './customers/customerAssets.component';
import { DriversViewComponent } from './drivers/drivers.component';
import { DriversAddViewComponent } from './drivers/add.component';
import { AssetDriversViewComponent } from './assets/assetDrivers.component';
import { DriverDetailsViewComponent } from './drivers/driverDetails.component';
import { DriverAssetsViewComponent } from './drivers/driverAssets.component';
import { DriverDevicesViewComponent } from './drivers/driverDevices.component';
import { TrailerViewComponent } from './trailers/trailers.component';
import { TrailerDetailsViewComponent } from './trailers/trailerDetails.component';
import { TrailerAddViewComponent } from './trailers/add.component';
import { AssetTypeViewComponent } from './assettypes/assetTypes.component';
import { AssetTypeDetailsViewComponent } from './assettypes/assetTypeDetails.component';
import { AssetTypeAddViewComponent } from './assettypes/add.component';
import { AssetTypeCalibrationViewComponent } from './assettypes/calibration.component';
import { TrailerAssetsViewComponent } from './trailers/assets.component';
import { DeviceAddViewComponent } from './devices/add.component';
import { AssetTypeAssetsViewComponent } from './assettypes/assets.component';
import { TrailerChooserComponent } from './assets/trailerChooser.component';
import { DriverChooserComponent } from './assets/driverChooser.component';
import { DeviceChooserComponent } from './assets/deviceChooser.component';
import { DriverLogViewComponent } from './drivers/driverLog.component';
import { AssetMessageViewComponent } from './assets/messages.component';
import { AssetTripsViewComponent } from './assets/trips.component';
import { AccountLogViewComponent } from './accounts/accountLog.component';
import { AssetTypeLogViewComponent } from './assettypes/assetTypeLog.component';
import { AssetsMapViewComponent } from './assets/map.component';
import { SensorTypeDetailsViewComponent } from './sensortypes/sensorTypesDetails.component';
import { SensorTypeViewComponent } from './sensortypes/sensorTypes.component';
import { SensorTypeAddViewComponent } from './sensortypes/add.component';
import { AssetCalibrationViewComponent } from './assets/sensors.component';
import { WaslRegistrationStatusViewComponent } from './wasl/status.component';
import { DataTables360Module } from 'app/modules/360grid';

@NgModule({
  declarations: [
    LoginComponent,
    RegisterComponent,
    ForgotComponent,
    AssetViewComponent,
    AssetDetailsViewComponent,
    AssetDeviceDetailsViewComponent,
    AssetCalibrationViewComponent,
    AssetMessageViewComponent,
    AssetDriversViewComponent,
    AssetTrailersViewComponent,
    AssetTypeAssetsViewComponent,
    AssetTripsViewComponent,
    AssetLogViewComponent,
    AssetAddViewComponent,
    AssetsMapViewComponent,
    DashboardComponent,
    UnsubscribeComponent,
    PlatformViewComponent,
    PlatformDetailsViewComponent,
    PlatformDevicesViewComponent,
    PlatformIssuesViewComponent,
    PlatformCalculationSettingsViewComponent,
    CustomersViewComponent,
    CustomerDetailsViewComponent,
    CustomerDevicesViewComponent,
    CustomerCalculationSettingsViewComponent,
    CustomerAssetsViewComponent,
    CustomerIssuesViewComponent,
    DevicesViewComponent,
    DevicesIssuesViewComponent,
    DeviceDetailsViewComponent,
    DeviceAssetViewComponent,
    DeviceFuelViewComponent,
    DeviceSettingsViewComponent,
    DeviceLocationsViewComponent,
    DeviceTripsViewComponent,
    DeviceIssuesViewComponent,
    DeviceCalculationSettingsViewComponent,
    DevicesMapViewComponent,
    DevicesMap2ViewComponent,
    DeviceLogViewComponent,
    DevicesTypeViewComponent,
    DeviceSensorsViewComponent,
    DeviceAddViewComponent,
    DeviceChooserComponent,
    DeviceTypeDetailsViewComponent,
    DeviceTypeIssuesViewComponent,
    DeviceTypeDevicesViewComponent,
    DeviceTypeCalculationSettingsViewComponent,
    TrailerViewComponent,
    TrailerDetailsViewComponent,
    TrailerAddViewComponent,
    TrailerAssetsViewComponent,
    TrailerChooserComponent,
    DriverChooserComponent,
    AssetTypeViewComponent,
    AssetTypeDetailsViewComponent,
    AssetTypeAddViewComponent,
    AssetTypeCalibrationViewComponent,
    AssetTypeLogViewComponent,
    DriversViewComponent,
    DriversAddViewComponent,
    DriverDetailsViewComponent,
    DriverAssetsViewComponent,
    DriverDevicesViewComponent,
    DriverLogViewComponent,
    IssuesChartViewComponent,
    IssuesViewComponent,
    IssuesMapViewComponent,
    JobsActiveViewComponent,
    JobsHistoryViewComponent,
    GroupedIssuesViewComponent,
    Error404Component,
    SearchViewComponent,
    TagsViewComponent,
    TagListViewComponent,
    ScheduleViewComponent,
    CalendarViewComponent,
    ReportingViewComponent,
    SettingsComponent,
    ThemeSettingsComponent,
    LanguageSettingsComponent,
    WorkflowSettingsComponent,
    CalculationSettingsOverViewComponent,
    CalculationSettingsOverViewTableComponent,
    SensorSettingsComponent,
    SensorDetailsComponent,
    SensorDevicesComponent,
    UsersViewComponent,
    UserDetailsViewComponent,
    UserLogViewComponent,
    UserRoleDetailsViewComponent,
    UserRolesViewComponent,
    AccountViewComponent,
    AccountDetailsViewComponent,
    AccountCalculationSettingsViewComponent,
    AccountAddViewComponent,
    AccountWithUseViewComponent,
    AccountLogViewComponent,
    UserAddViewComponent,
    CalculationSettingsViewComponent,
    ErrorLogComponent,
    ProfileComponent,
    SettingsGeneralComponent,
    IssueLogViewComponent,
    IssueDetailsViewComponent,
    IssueDetailsChartViewComponent,
    AboutViewComponent,
    HelpViewComponent,
    EndsessionCallbackComponent,
    SensorTypeDetailsViewComponent,
    SensorTypeViewComponent,
    SensorTypeAddViewComponent,
    WaslViewComponent,
    WaslRegistrationStatusViewComponent,
    AuthCallbackComponent,
    SsoLoginComponent,
    FilterRelevantPipe,
    TextFilterPipe
  ],
  imports: [
    BrowserModule,
    RouterModule,
    AngularMultiSelectModule,
    ChartModule,
    DataTablesModule,
    DataTables360Module,
    HttpClientModule,
    MomentModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    CountoModule,
    IboxToolsModule,
    TimezoneSelectorModule,
    TagInputModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    ICheckModule.forRoot(),
    WizardModule,
    SortableModule.forRoot(),
    CarouselModule.forRoot(),
    TabsModule.forRoot(),
    LeafletModule.forRoot(),
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    PaginationModule.forRoot(),
    ModalModule.forRoot(),
    UsercontrolsModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.rectangleBounce,
      backdropBackgroundColour: 'rgba(255,255,255,0.7)',
      backdropBorderRadius: '4px',
      primaryColour: '#DC8139',
      secondaryColour: '#DC8139',
      tertiaryColour: '#DC8139'
    }),
    ColorPickerModule,
    TranslateModule,
    PrettyJsonModule
  ],
  exports: [
    DashboardComponent,
    LoginComponent,
    TranslateModule
  ],
})

export class AppviewsModule {
}
