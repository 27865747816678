import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';

import { DeviceService } from '../../services/device/device.service';
import { ActivatedRoute, Router } from '@angular/router';
import { languages, FormMode } from 'app/common/enums';
import { Map } from 'leaflet';
import { UserService } from '../../services/users/user.service';
import { User, AppUser, UserRole } from '../../models/user.model';
import { PlatformService } from '../../services/platform/platform.service';
import { AccountPlatform, AppAccount, AppAccountPlatform, AddressDetails } from '../../models/platform.model';
import { UserRoleService } from '../../services/users/userRole.service';
import { AccountService } from '../../services/accounts/accounts.service';
import { BsDatepickerConfig, BsLocaleService, BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { AccountType } from 'app/models/account.model';
import { AccountPlatformService } from 'app/services/platform/accountPlatform.service';
import { AccountPlatformControlComponent } from '../shared/usercontrols/accountPlatformControl.component';

declare var L;

@Component({
    selector: 'fh-account-add',
    templateUrl: 'accountAdd.template.html'
})

export class AccountAddViewComponent implements OnInit {
    @ViewChild('accountplatforms') accountplatforms: AccountPlatformControlComponent;

    map: Map;
    options;
    layersControl;
    selectedRow: any;
    selected: any;
    theTrips = [];
    accountPlatforms: AccountPlatform[] = [];
    timeoutHandler;
    chartFuel: any;
    sensors;
    sub;
    user: AppUser = new AppUser;
    loading = false;
    loadingSensors = false;
    platforms: any;
    issueCalculationEndDate: Date;
    accountSynchronizationEndDate: Date;

    formMode = FormMode.add;

    hasError = undefined;

    accountModel: AppAccount;

    platformValidation = {
        'Valid': 1,
        'Invalid': 2
    }

    passwordConfirmation = '';

    userRoles: UserRole[];
    languages: { name: string; path: any; }[];

    createNew = true;
    optionalAccountPlatform = false;
    accountName;

    constructor(private authenticationService: AuthenticationService, private deviceService: DeviceService, private userRoleService: UserRoleService,
        private route: ActivatedRoute, private accountPlatformService: AccountPlatformService, private router: Router,
        private userService: UserService, private platformService: PlatformService, private accountService: AccountService) {
        // new user
        this.user = new AppUser();

        // add 1 accountplatform
        const newAccountPlatform = new AccountPlatform();
        this.accountPlatforms.push(newAccountPlatform);

        this.sensors = [];
        this.theTrips = [];

        this.accountModel = new AppAccount();
        this.accountModel.userRequest = new AppUser();
        this.accountModel.limitHistoryInDays = 60;
        this.accountModel.identifier = 1;
    }

    ngOnInit() {

        this.accountModel.userRequest.timezoneIana = this.authenticationService.getTimeZoneIana();
        this.accountModel.userRequest.language = this.authenticationService.getLanguage();
        this.accountModel.accountPlatformRequest = [new AppAccountPlatform()];
    }

    togglePlatform(platform) {
        platform.isUsed = !platform.isUsed;
    }

    addAccountPlatform() {
        this.accountModel.accountPlatformRequest.push(new AppAccountPlatform());
    }

    removeAccountPlatform(i) {
        this.accountModel.accountPlatformRequest.splice(i, 1);
    }

    checkTokenValidation(accountPlatformRequest: AppAccountPlatform[]) {
        let isDisabled = true;
        if (accountPlatformRequest.length) {
            isDisabled = !((accountPlatformRequest[0].platformName && accountPlatformRequest[0].platformName.length > 0)
                && (accountPlatformRequest[0].token && accountPlatformRequest[0].token.length > 0)
                && (accountPlatformRequest[0].url && accountPlatformRequest[0].url.length > 0)
                && (!isNaN(accountPlatformRequest[0].platformType))
            );
        }
        return isDisabled;
    }

    serializeValues(platform): string {

        if (isNaN(platform.platformType)) {
            return '';
        }

        if (platform.platformType === 6) {
            return 'flespiwasl';
        }

        return btoa([platform.platformType, platform.token, platform.url].join('-'));
    }

    isBoolean = (result) => (typeof (result) === 'boolean' && (result
        ? this.platformValidation.Valid
        : this.platformValidation.Invalid)
    );

    hasValidPlatform(): boolean {
        console.log('checking latform valid');

        if (!this.accountplatforms) {
            return false;
        }

        return !this.accountModel.accountPlatformRequest.some((platform) => !this.accountplatforms.validatedAccountsPlatforms[this.serializeValues(platform)]);
    }

    createAccount() {

        this.accountModel.limitHistoryInDays = this.accountModel.limitHistoryInDays || 60;
        this.accountModel.status = this.accountModel.status || 1;

        if (!Number(this.accountModel.identifier)) {
            this.accountModel.identifier = 4;
        }

        this.loading = true;

        this.accountService.createAccount(this.accountModel).subscribe((result) => {
            this.loading = false;
            if (result.completed) {
                this.router.navigate(['AccountDetails/Index/' + result.data.id]);
            }
            this.hasError = result.data;
        });
    }
}
