import { Injectable, OnInit } from '@angular/core';
import { Headers, RequestOptions } from '@angular/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch';
import { Location } from '@angular/common';
import * as jwt_decode from 'jwt-decode';

import { Crypto } from '../../common/crypto';
import { AuthenticateRequest } from '../../models/authenticaterequest.model';
import { AuthenticateResult } from '../../models/authenticateresult.model';
import { Config } from '../../models/config.model';
import { environment } from 'app/../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PermissionsBitmask } from '../../common/enums';
import { UserManager, UserManagerSettings } from 'oidc-client';
import { EventService } from '../events/events.service';
import { arrayFromMask } from '../common/functions.service';

@Injectable()
export class AuthenticationService implements OnInit {

    // Constants for our preferences.
    public static get TOKEN(): string { return 'token'; }
    public static get TOKEN_EXPIRED(): string { return 'token_expired'; }

    public static get TOKEN_IMPERSONATE(): string { return 'token_impersonate'; }
    public static get TOKEN_IMPERSONATE_EXPIRED(): string { return 'token_impersonate_expired'; }

    public static get CLUSTER_URL(): string { return 'cluster_url'; }
    public static get INVENTORY_URL(): string { return 'inventory_url'; }
    public static get FLEETOVERVIEW_URL(): string { return 'fleetoverview_url'; }

    public static get USERID(): string { return 'userid'; }
    public static get USERID_IMPERSONATE(): string { return 'userid_impersonate'; }
    public static get PERMISSIONS(): string { return 'permissions'; }
    public static get PERMISSIONS_IMPERSONATE(): string { return 'permissions_impersonate'; }


    public static get ID(): string { return 'id'; }
    public static get ID_IMPERSONATE(): string { return 'id_impersonate'; }

    public static get ACCOUNTID(): string { return 'accountid'; }
    public static get ACCOUNTID_IMPERSONATE(): string { return 'accountid_impersonate'; }

    public static get TIMEZONE(): string { return 'timezone'; }
    public static get TIMEZONEIANA(): string { return 'timezoneiana'; }
    public static get TIMEZONEIANA_IMPERSONATE(): string { return 'timezoneiana_impersonate'; }
    public static get CULTURE(): string { return 'culture'; }
    public static get LIMITDISTORYINDAYS(): string { return 'limithistoryindays'; }
    public static get WHITELABEL(): string { return 'whitelabel'; }
    public static get WHITELABEL_IMPERSONATE(): string { return 'whitelabel_impersonate'; }
    public static get LANGUAGE(): string { return 'language'; }
    public static get MAPPROVIDER(): string { return 'mapprovider'; }
    public static get DISTANCEUNIT(): string { return 'distanceunit'; }
    public static get VOLUMEUNIT(): string { return 'volumeunit'; }
    public static get ISIMPERSONATINGUSER(): string { return 'isimpersonatinguser'; }
    public static get SSOPROVIDER(): string { return 'ssoprovider'; }
    public static get SSOPROVIDERTOKEN(): string { return 'ssoprovidertoken'; }

    public permissions = {};
    public permissionsFetched = false;
    public config: Config;

    public isLoggedInWithSSO = false;
    private manager: UserManager = new UserManager(AuthenticationService.getSSOSettings())

    public static getStaticToken(): string {
        return localStorage.getItem(AuthenticationService.TOKEN_IMPERSONATE) ? localStorage.getItem(AuthenticationService.TOKEN_IMPERSONATE) : localStorage.getItem(AuthenticationService.TOKEN);
    }
    public static getStaticClusterUrl(): string { return localStorage.getItem(AuthenticationService.CLUSTER_URL); }
    public static getStaticInventoryUrl(): string { return localStorage.getItem(AuthenticationService.INVENTORY_URL); }
    public static getStaticFleetOverviewUrl(): string { return localStorage.getItem(AuthenticationService.FLEETOVERVIEW_URL); }
    public static getSSOSettings(): UserManagerSettings {
        return {
            authority: environment.SSOAuthenticationUrl,
            client_id: 'Fleethealth',
            redirect_uri: environment.SSORedirectUrl,
            response_type: 'id_token token',
            scope: 'openid profile Roles',
            post_logout_redirect_uri: environment.SSOPostLogoutRedirectUrl,
            filterProtocolClaims: true,
            loadUserInfo: true
        };
    }

    constructor(private http: HttpClient, private router: Router, private location: Location, private cookieService: CookieService, private eventService: EventService) {
    }

    setPermissions(setting, permisisonsBit) {
        let permissions = [];
        this.permissions = {};
        permissions = arrayFromMask(permisisonsBit, permissions).filter(x => x.isEnabled === true);

        permissions.forEach(permission => {
            this.permissions[permission.name] = true;
        });

        const permissionJson = JSON.stringify(this.permissions);

        if (permissionJson.length > 0) {
            localStorage.setItem(setting, btoa(permissionJson));
        }
    }

    getPermissions() {
        // Try to decode
        const permissions = localStorage.getItem(AuthenticationService.PERMISSIONS_IMPERSONATE) ? localStorage.getItem(AuthenticationService.PERMISSIONS_IMPERSONATE) : localStorage.getItem(AuthenticationService.PERMISSIONS);
        try {
            const permissionJson = JSON.parse(atob(permissions));
            if (this.permissions) {
                this.permissions = permissionJson;

                if (this.permissions['IsAdministrator']) {
                    this.permissions['HasSensorTypes'] = true;
                }
            }
        } catch (err) {
            console.error(err);
        }
    }

    ngOnInit() {
    }

    // The cookie we retrieve our ssoToken from.
    public getSsoCookie(enviroment: string): string {

        let returnVar = 'authGpscockpit';
        if (enviroment.indexOf('clust01') !== -1) {
            returnVar = 'authGpscockpit';
        }
        if (enviroment.indexOf('clust02') !== -1) {
            returnVar = 'authGpscockpitCLUST02';
        }
        if (enviroment.indexOf('staging') !== -1) {
            returnVar = 'authGpscockpit';
        }
        if (enviroment.indexOf('local') !== -1) {
            returnVar = 'authGpscockpit';
        }
        return returnVar;
    }

    // The cookie we retrieve our ssoToken from.
    public getSessionCookie(enviroment: string): string {
        const returnVar = 'sessionToken';
        return returnVar;
    }

    // TODO: will be deprecated, just to make code compile for now
    public getAuthToken(): string {
        const request = localStorage.getItem(AuthenticationService.TOKEN_IMPERSONATE) ? localStorage.getItem(AuthenticationService.TOKEN_IMPERSONATE) : localStorage.getItem(AuthenticationService.TOKEN);
        return (request === 'null') ? '' : request;
    };

    public get headers(): HttpHeaders {
        const token = this.getAuthToken();
        return new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + token });
    }

    public getAuthTokenExpired(): string {
        const request = localStorage.getItem(AuthenticationService.TOKEN_IMPERSONATE_EXPIRED) ? localStorage.getItem(AuthenticationService.TOKEN_IMPERSONATE_EXPIRED) : localStorage.getItem(AuthenticationService.TOKEN_EXPIRED);
        return (request === 'null') ? '' : request;
    };

    public getIsImpersonated(): boolean {
        return localStorage.getItem(AuthenticationService.TOKEN_IMPERSONATE) && localStorage.getItem(AuthenticationService.TOKEN_IMPERSONATE) !== '' ? true : false;
    }

    public setImpersonationToken(user, result) {
        console.log(result.user);

        localStorage.setItem(AuthenticationService.ID_IMPERSONATE, user.name);
        localStorage.setItem(AuthenticationService.USERID_IMPERSONATE, user.id);
        localStorage.setItem(AuthenticationService.ACCOUNTID_IMPERSONATE, user.accountId);
        this.setPermissions(AuthenticationService.PERMISSIONS_IMPERSONATE, result.user.permissions);
        localStorage.setItem(AuthenticationService.TIMEZONEIANA_IMPERSONATE, result.user.timezoneIana);
        localStorage.setItem(AuthenticationService.WHITELABEL_IMPERSONATE, result.user.whitelabel);
        localStorage.setItem(AuthenticationService.TOKEN_IMPERSONATE, result.token);

        this.router.navigate(['/']).then(() => {
            location.reload();
        });
    }

    public stopImpersonation(reloadPage = true) {
        localStorage.setItem(AuthenticationService.TIMEZONEIANA_IMPERSONATE, '');
        localStorage.setItem(AuthenticationService.USERID_IMPERSONATE, '');
        localStorage.setItem(AuthenticationService.ACCOUNTID_IMPERSONATE, '');
        localStorage.setItem(AuthenticationService.ID_IMPERSONATE, '');
        localStorage.setItem(AuthenticationService.PERMISSIONS_IMPERSONATE, '');
        localStorage.setItem(AuthenticationService.WHITELABEL_IMPERSONATE, '');
        localStorage.setItem(AuthenticationService.TOKEN_IMPERSONATE, '');

        if (reloadPage) {
            location.reload();
        }
    }

    public getUserId(): string { return localStorage.getItem(AuthenticationService.USERID_IMPERSONATE) ? localStorage.getItem(AuthenticationService.USERID_IMPERSONATE) : localStorage.getItem(AuthenticationService.USERID); }

    public getLanguage(): string { return localStorage.getItem(AuthenticationService.LANGUAGE); }
    public setLanguage(langTag) { localStorage.setItem(AuthenticationService.LANGUAGE, langTag); }

    public getDistanceUnit(): string { return localStorage.getItem(AuthenticationService.DISTANCEUNIT); }

    public getVolumeUnit(): string { return localStorage.getItem(AuthenticationService.VOLUMEUNIT); }

    public getWhitelabel(): string { return localStorage.getItem(AuthenticationService.WHITELABEL_IMPERSONATE) ? localStorage.getItem(AuthenticationService.WHITELABEL_IMPERSONATE) : localStorage.getItem(AuthenticationService.WHITELABEL); }

    public getCulture(lang: string): string {
        const serverCulture = this.getServerCulture();

        // Try to guess the culture from the language
        switch (lang) {
            case 'nl':
                return 'nl-NL';
            case 'fr':
                return 'fr-FR';
            case 'ar':
                if (serverCulture && serverCulture.startsWith('ar')) {
                    return serverCulture;
                }
                return 'ar-EG';
            case 'en':
                if (serverCulture && serverCulture.startsWith('en')) {
                    return serverCulture;
                }
                return 'en-US';
            case 'de':
                return 'de-DE';
            default:
                console.warn('Could not translate language to culture. Falling back to server culture');
                return serverCulture;
        }
    }

    public getServerCulture(): string {
        return localStorage.getItem(AuthenticationService.CULTURE);
    }

    public getTimeZone(): string { return localStorage.getItem(AuthenticationService.TIMEZONE); }

    public getTimeZoneIana(): string {
        return localStorage.getItem(AuthenticationService.TIMEZONEIANA_IMPERSONATE)
            ? localStorage.getItem(AuthenticationService.TIMEZONEIANA_IMPERSONATE)
            : localStorage.getItem(AuthenticationService.TIMEZONEIANA);
    }

    public getId(): string {
        return localStorage.getItem(AuthenticationService.ID_IMPERSONATE) &&
            localStorage.getItem(AuthenticationService.ID_IMPERSONATE) !== '' ?
            localStorage.getItem(AuthenticationService.ID_IMPERSONATE) :
            localStorage.getItem(AuthenticationService.ID);
    }

    public getAccountId(): string {
        return localStorage.getItem(AuthenticationService.ACCOUNTID_IMPERSONATE) &&
            localStorage.getItem(AuthenticationService.ACCOUNTID_IMPERSONATE) !== '' ?
            localStorage.getItem(AuthenticationService.ACCOUNTID_IMPERSONATE) :
            localStorage.getItem(AuthenticationService.ACCOUNTID);
    }

    public getWebserviceURL(resource: string): string {
        // Checks if the url is set, if not then get new one. Else return the url.
        const baseUrl = localStorage.getItem(AuthenticationService.CLUSTER_URL);
        return (baseUrl === 'null') ? null : baseUrl + ((resource === '') ? '' : resource + '/');
    };

    public getFleetOverviewUrl(resource: string): string {
        // Checks if the url is set, if not then get new one. Else return the url.
        const baseUrl = localStorage.getItem(AuthenticationService.FLEETOVERVIEW_URL);
        return (baseUrl === 'null') ? null : baseUrl + ((resource === '') ? '' : resource + '/');
    };

    public getInventoryURL(resource: string): string {
        // Checks if the url is set, if not then get new one. Else return the url.
        const baseUrl = localStorage.getItem(AuthenticationService.INVENTORY_URL);
        return (baseUrl === 'null') ? null : baseUrl + ((resource === '') ? '' : resource + '/');
    };

    public getLimitHistoryInDays(): string { return localStorage.getItem(AuthenticationService.LIMITDISTORYINDAYS); }

    public IsAuthenticated(_path: string): Observable<boolean> {
        const config = this.getConfig();

        this.clearLocalStorage().then();

        if (this.isImpersonatingUser() || !this.isTokenValid()) {

            if (this.config.Debug) { console.log('Token expired... '); }
            // get ssotoken cookie
            const ssoToken = this.cookieService.get(this.getSessionCookie(config.Environment));
            if (ssoToken != null && ssoToken !== 'null' && ssoToken !== '') {
                if (this.config.Debug) { console.log('Trying to login with ssoToken... ' + ssoToken); }
                return this.performLoginWithSsoToken(ssoToken, config)
                    .map((result) => {

                        if (result === true) {
                            console.log('Is still logged in');

                        }

                        if (this.config.Debug) { console.log('Could not login with ssoToken!'); }
                        this.router.navigate(['/Login'], { queryParams: { redirect: _path } });
                        return false;
                    }).catch((error) => {
                        if (this.config.Debug) { console.log('Could not login with ssoToken!'); }
                        this.router.navigate(['/Login'], { queryParams: { redirect: _path } });
                        return of(false);
                    });
            } else {
                this.router.navigate(['/Login'], { queryParams: { redirect: _path } });
                return of(false);
            }

        } else {
            if (this.config.Debug) { console.log('Token not expired yet.'); }
            console.log('get permissions');
            this.getPermissions();
        }

        if (this.config.Debug) { console.log('AuthenticationService: Done with IsAuthenticated.'); }
        return of(true);
    }

    performLoginWithSsoToken(token: string, config: Config): Observable<any> {

        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + token });

        return this.http.get(config.AuthenticationUrl + 'Authentication/FromToken', { headers: headers })
            .map(res => {
                return res;
            });
    }

    performReset(userName: string) {

        if (!this.config || !this.config.Debug) { console.log('AuthenticateService: Performing reset for user: ' + userName); }

        const config = this.getConfig()

        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json' });
        const request = new AuthenticateRequest(userName, '', '');

        const body = JSON.stringify(request);

        return this.http.post(config.AuthenticationUrl + 'User/resetByUsername', body, { headers: headers });
    }

    performLogin(userName: string, password: string): Observable<AuthenticateResult> {

        if (!this.config || !this.config.Debug) { console.log('AuthenticateService: Performing login for user: ' + userName); }

        const config = this.getConfig()

        const request = new AuthenticateRequest(userName,
            password, null);

        const body = JSON.stringify(request);
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json' });

        return this.http.post(config.AuthenticationUrl + 'Authentication', body, { headers: headers })
            .map(res => {
                console.log(res);
                const result = (res as AuthenticateResult);
                result.environment = config.Environment;
                result.clusterUrl = config.AuthenticationUrl;
                return this.saveToken(result);
            });
    }

    performLoginWithSSO(userName: string, provider: string, token: string): Observable<AuthenticateResult> {
        if (!this.config || !this.config.Debug) { console.log('AuthenticateService: Performing SSO login for user: ' + userName); }

        localStorage.setItem(AuthenticationService.ID, userName);
        localStorage.setItem(AuthenticationService.SSOPROVIDER, provider);
        localStorage.setItem(AuthenticationService.SSOPROVIDERTOKEN, token);

        const config = this.getConfig()

        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json' });
        const body = JSON.stringify(userName);

        return this.http.post(config.AuthenticationUrl + 'Authentication/SSOAuthentication', body, { headers: headers })
            .map(res => {
                console.log(res);
                const result = (res as AuthenticateResult);
                result.environment = config.Environment;
                result.clusterUrl = config.AuthenticationUrl;
                return this.saveToken(result);
            });

    }

    private getConfig(): Config {
        console.log('getConfig: Retrieving configuration...');
        const config = this.config = this.environmentToConfig();
        if (!this.config.Debug) {
            console.log('getConfig: Retrieved configuration, enviroment: ' + config.Environment);
        }
        return config;
    }

    // XXX: replace Config with environment completely
    private environmentToConfig(): Config {
        const cfg = new Config();

        cfg.version = environment.version;
        cfg.AuthenticationUrl = environment.AuthenticationUrl;
        cfg.InventoryUrl = environment.InventoryUrl;
        cfg.FleetOverviewUrl = environment.FleetOverviewUrl;
        cfg.ConsumerToken = environment.ConsumerToken;
        cfg.Debug = environment.Debug;
        cfg.Environment = environment.Environment;
        cfg.SSOAuthenticationUrl = environment.SSOAuthenticationUrl;
        cfg.SSOPostLogoutRedirectUrl = environment.SSOPostLogoutRedirectUrl;
        cfg.SSORedirectUrl = environment.SSORedirectUrl;

        return cfg;
    }


    saveToken(authenticateResult: AuthenticateResult): AuthenticateResult {
        if (authenticateResult) {
            this.config = this.getConfig()
            if (authenticateResult.token && authenticateResult.clusterUrl) {

                this.stopImpersonation(false);

                if (this.config.Debug) {
                    console.log('saving login token: ' + authenticateResult.token + ' expires: ' + authenticateResult.tokenExpires.toString() + ' url: ' + authenticateResult.clusterUrl);
                }

                console.log(authenticateResult.user);

                // this.eventService.setWhitelabel('360');
                localStorage.setItem(AuthenticationService.WHITELABEL, authenticateResult.user.whitelabel);

                localStorage.setItem(AuthenticationService.TOKEN, authenticateResult.token);
                localStorage.setItem(AuthenticationService.TOKEN_EXPIRED, authenticateResult.tokenExpires.toString());
                localStorage.setItem(AuthenticationService.CLUSTER_URL, authenticateResult.clusterUrl);
                localStorage.setItem(AuthenticationService.INVENTORY_URL, this.config.InventoryUrl);
                localStorage.setItem(AuthenticationService.FLEETOVERVIEW_URL, this.config.FleetOverviewUrl);
                localStorage.setItem(AuthenticationService.USERID, authenticateResult.user.id);
                localStorage.setItem(AuthenticationService.LANGUAGE, authenticateResult.user.language);

                localStorage.setItem(AuthenticationService.ID, authenticateResult.user.userName);
                localStorage.setItem(AuthenticationService.ACCOUNTID, authenticateResult.user.accountId);
                localStorage.setItem(AuthenticationService.CULTURE, authenticateResult.user.culture);

                localStorage.setItem(AuthenticationService.TIMEZONE, authenticateResult.user.timeZone);
                localStorage.setItem(AuthenticationService.TIMEZONEIANA, authenticateResult.user.timezoneIana);

                localStorage.setItem(AuthenticationService.LIMITDISTORYINDAYS, authenticateResult.user.limitHistoryInDays);

                this.setPermissions(AuthenticationService.PERMISSIONS, authenticateResult.user.permissions);

                this.cookieService.set(this.getSessionCookie(this.config.Environment), authenticateResult.token);

                return authenticateResult;
            } else {

                return null;
            }
        } else {
            return null;
        }
    }

    clearToken() {
        if (this.config.Debug) { console.log('Logging out and removing tokens'); }

        localStorage.removeItem(AuthenticationService.ACCOUNTID);
        localStorage.removeItem(AuthenticationService.TOKEN);
        localStorage.removeItem(AuthenticationService.TOKEN_EXPIRED);
        localStorage.removeItem(AuthenticationService.CLUSTER_URL);
        localStorage.removeItem(AuthenticationService.INVENTORY_URL);
        localStorage.removeItem(AuthenticationService.USERID);
        localStorage.removeItem(AuthenticationService.TIMEZONE);
        localStorage.removeItem(AuthenticationService.CULTURE);
        localStorage.removeItem(AuthenticationService.DISTANCEUNIT);
        localStorage.removeItem(AuthenticationService.VOLUMEUNIT);
        localStorage.removeItem(AuthenticationService.WHITELABEL);
        localStorage.removeItem(AuthenticationService.MAPPROVIDER);
        localStorage.removeItem(AuthenticationService.ISIMPERSONATINGUSER);
        localStorage.removeItem(AuthenticationService.SSOPROVIDER);
        localStorage.removeItem(AuthenticationService.SSOPROVIDERTOKEN);

        const enviroment = 'clust01';
        const cookie = this.getSessionCookie(enviroment);
        let domain = '';

        if (enviroment.indexOf('staging') !== -1) {
            domain = '.gpscockpit.net';
        } else {
            domain = '.gpscockpit.com';
        }
        if (this.config.Debug) { console.log('Logging out and removing ' + cookie + ' for domain' + domain); }
        this.cookieService.delete(cookie, '/', domain);

        if (this.config.Debug) { console.log('Redirecting'); }

        this.router.navigate(['/Login']);

    }

    private isImpersonatingUser(): boolean {
        const value = localStorage.getItem(AuthenticationService.ISIMPERSONATINGUSER);
        if (value) {
            return JSON.parse(value);
        }
        return false;
    }

    private isTokenValid(): boolean {

        if (!this.config || !this.config.Debug) { console.log('Testing token validity...'); }

        const token = localStorage.getItem(AuthenticationService.TOKEN);
        const tokenExpired = localStorage.getItem(AuthenticationService.TOKEN_EXPIRED);

        if (token != null && tokenExpired != null) {
            if (Date.now() < Date.parse(tokenExpired)) {
                if (!this.config || !this.config.Debug) { console.log('Token ' + token + ' is still valid!'); }
                return true;
            }
        }

        if (!this.config || !this.config.Debug) { console.log('Token is invalid!'); }
        return false;
    }

    startAuthentication(): Promise<void> {
        console.error('SSO has been disabled on 1/12/2020');
        return Promise.reject();
    }

    completeSignout(): Promise<void> {
        return this.manager.signoutRedirectCallback().then(function () {
            if (this.config.Debug) {
                console.log('signoutredirectcallback completed');
            }
            this.authService.clearLocalStorage();
        }).catch(error => {
            console.log(error)
        });
    }

    clearLocalStorage(): Promise<void> {
        return this.manager.clearStaleState().then(result => { if (this.config.Debug) { console.log('state cleared'); } });
    }

    signInWithSSO(idtoken: string): Observable<AuthenticateResult> {
        const userData = jwt_decode(idtoken);
        const nonce = userData['nonce'];
        const userName = userData['Email'];
        if (this.checkNonce(nonce)) {
            return this.performLoginWithSSO(userName, 'sso360', idtoken);
        }
        return Observable.throwError('Something went wrong while trying to login')

    }

    checkNonce(nonce: string): boolean {
        const local = localStorage.getItem('360SSO')
        if (nonce === local) {
            localStorage.removeItem('360SSO');
            return true;
        }
        return false;
    }

    generateNonce(length) {
        const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._~'
        let result = ''

        while (length > 0) {
            const bytes = new Uint8Array(16);
            const random = window.crypto.getRandomValues(bytes);

            random.forEach(function (c) {
                if (length === 0) {
                    return;
                }
                if (c < charset.length) {
                    result += charset[c];
                    length--;
                }
            });
        }
        return result;
    }

}
