<span class="pull-right" style="margin-top: 0px;">
    <div class="input-group grid-search">
        <input type="text" class="form-control inline-search" style="margin-top: -10px;"
            placeholder="{{'general.search' | translate }}" [(ngModel)]="searchVar"
            (ngModelChange)="search$.next($event)">
        <div class="input-group-btn">
            <button class="btn btn-primary btn-outline" (click)="sumbitSearch()" style="margin-top: -10px">
                <i class="fa fa-search"></i>
            </button>
            <button id="toolboxButton" class="btn btn-primary toolboxButton"
                [ngClass]="isEditMode ? 'btn-primary' : 'btn-outline'" (click)="switchEditMode()"
                style="margin-top: -10px;">
                <i class="fa fa-cog"></i>
            </button>
            <button id="columnChooseButton" class="btn btn-primary columnChooseButton" *ngIf="showColumns"
                [ngClass]="showColumnsEnabled ? 'btn-primary' : 'btn-outline'" (click)="switchColumnChooser()"
                style="margin-top: -10px;">
                <i class="fa fa-list"></i>
            </button>

            <ul class="dropdown-menu dropdown-user">
                <li><a href="#">Config option 1</a>
                </li>
                <li><a href="#">Config option 2</a>
                </li>
            </ul>
        </div>
    </div>
</span>