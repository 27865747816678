import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

import * as moment from 'moment';
import 'rxjs/add/operator/map'
import { of } from 'rxjs/observable/of';

import { AuthenticationService } from '../authentication/authentication.service';
import { DeviceType } from '../../models/devicetype.model';
import { Location } from '../../models/location.model';
import { LoggingService } from '../logging/logging.service';
import { Manufacturer } from '../../models/manufacturer.model';
import { IssueType } from '../../models/issuetype.model';
import { TranslatorService } from '../common/translator.service';
import { throwError } from 'rxjs/internal/observable/throwError';

@Injectable()
export class DeviceTypeService {
    token: string;
    timestamp;
    url = '';
    public DeviceTypes: DeviceType[] = [];

    constructor(private http: HttpClient, private translatorService: TranslatorService, private loggingService: LoggingService, private authenticationService: AuthenticationService) {
        this.url = authenticationService.getWebserviceURL('devicetype');
        this.token = authenticationService.getAuthToken();
    }

    getDeviceTypes(setDefinate = true, includeThumbnails = true, excludeUnused = true): Observable<DeviceType[]> {

        if (setDefinate && this.DeviceTypes.length !== 0) {
            return of(this.DeviceTypes);
        }

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting devicetypes from service');
        return this.http.get(this.url + '?includeThumbnails=' + includeThumbnails + '&excludeUnused=' + excludeUnused, { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                if (setDefinate) {
                    console.log('setting DeviceTypes');
                    this.DeviceTypes = parsedResponse;
                }
                return parsedResponse;
            })
            .catch(this.handleError);
    }


    getDeviceTypeById(id: string): Observable<DeviceType> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting devicetypes from service');
        return this.http.get(this.url + id, { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseResponseData(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }


    getDeviceTypeDashboard(id: string): Observable<any> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting dashboard from service per devicetype');
        return this.http.get(this.url + id + '/dashboard/', { headers: headers })
            .map((data: any) => {
                return data;
            })
            .catch(this.handleError);
    }

    getDeviceTypeIssueTypes(id: string): Observable<any> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting dashboard from service per devicetype');
        return this.http.get(this.url + id + '/issuetypes/', { headers: headers })
            .map((data: any) => {
                return data;
            })
            .catch(this.handleError);
    }

    getIssuesPerIssueType(id: string, start, end): Observable<any> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting dashboard from service per issuetype');
        return this.http.get(this.url + id + '/IssuesPerIssueType?start=' + Math.round(start / 1000) + '&end=' + Math.round(end / 1000), { headers: headers })
            .map((data: any) => {
                return Object.keys(data).map(k => [this.translatorService.translate(k, 'enums.issuetypes'), data[k]]);
            })
            .catch(this.handleError);
    }

    getDeviceTypesWithCount(setDefinate = true): Observable<DeviceType[]> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting devicetypes from service');
        return this.http.get(this.url, { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getDeviceTypesChart(start, end): Observable<any> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting devicetypes from service');
        return this.http.get(this.url + 'chart/?start=' + Math.round(start / 1000) + '&end=' + Math.round(end / 1000), { headers: headers })
            .map((data: any) => {
                return data;
            })
            .catch(this.handleError);
    }

    private handleError(error: Response) {
        return throwError(error);
    }


    parseResponse(json: any): DeviceType[] {

        const deviceTypes: DeviceType[] = [];

        json.forEach(item => {

            //   this.loggingService.log(this.constructor().name + " - New", item);

            const deviceType = this.parseResponseData(item);

            deviceTypes.push(deviceType);
        });

        this.timestamp = new Date().getTime();
        return deviceTypes;
    }

    parseResponseData(item) {
        const deviceType = new DeviceType();
        deviceType.id = item.id;
        deviceType.name = item.name;
        deviceType.code = item.code;
        deviceType.image = item.image;
        deviceType.thumbnail = item.thumbnail;
        deviceType.createdDate = item.createdDate ? moment(item.createdDate.replace(' ', '')).toDate() : null;
        deviceType.modifiedDate = item.modifiedDate ? moment(item.modifiedDate.replace(' ', '')).toDate() : null;
        deviceType.deviceCount = item.deviceCount;
        deviceType.manufacturerName = item.manufacturerName;

        deviceType.referenceId = item.referenceId;
        deviceType.issueCount = 0;
        return deviceType;
    }
}
