import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { throwError } from 'rxjs/internal/observable/throwError';

import { AuthenticationService } from '../authentication/authentication.service';
import { AppUser, UpdatePasswordRequest } from '../../models/user.model';
import { LoggingService } from '../logging/logging.service';
import { of } from 'rxjs/internal/observable/of';

@Injectable()
export class UserService {

    url = '';
    urlInventory = '';

    constructor(private http: HttpClient, private loggingService: LoggingService, private authenticationService: AuthenticationService) {
        this.url = authenticationService.getWebserviceURL('user');
        this.urlInventory = authenticationService.getInventoryURL('user');
    }

    get headers(): HttpHeaders {
        const token = this.authenticationService.getAuthToken();
        return new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + token });
    }

    getUsers(): Observable<AppUser[]> {
        this.url = this.authenticationService.getWebserviceURL('user');
        this.urlInventory = this.authenticationService.getInventoryURL('user');

        console.log('getting users from service : ' + this.url);
        return this.http.get(this.url, { headers: this.headers })
            .map((data) => {
                return this.parseResponse(data);
            })
            .catch(this.handleError);
    }

    getUsersByAccountId(id: any): Observable<AppUser[]> {
        console.log('getting users from service : ' + this.url);
        return this.http.get(this.url + 'Account/' + id, { headers: this.headers })
            .map((data) => {
                return this.parseResponse(data);
            })
            .catch(this.handleError);
    }

    getUserById(id: any): Observable<AppUser> {
        console.log('getting users from service');
        return this.http.get(this.url + id, { headers: this.headers })
            .map((data) => {
                return this.parseResponseData(data);
            })
            .catch(this.handleError);
    }

    getTokenForUser(id: any): Observable<any> {
        console.log('getting users from service');
        return this.http.get(this.url + 'impersonateToken/' + id, { headers: this.headers })
            .map((data) => {
                return data as any;
            })
            .catch(this.handleError);
    }

    resetPassword(id: any): Observable<any> {
        return this.http.post(this.url + 'reset/' + id, id, { headers: this.headers })
            .catch(this.handleError);
    }

    deleteUser(id: any): Observable<any> {
        return this.http.delete(this.urlInventory + id, { headers: this.headers })
            .catch(this.handleError);
    }

    updateUserProfile(appUser: AppUser): Observable<any> {
        return this.http.put(this.url, appUser, { headers: this.headers })
            .catch(this.handleError);
    }

    updateUserPassword(request: UpdatePasswordRequest): Observable<any> {
        const urlExtra = 'Password'

        return this.http.put(this.url + urlExtra, request, { headers: this.headers })
            .catch(this.handleError);
    }

    saveUser(appUser: AppUser): Observable<any> {
        return this.http.post(this.urlInventory + 'Create', appUser, { headers: this.headers })
            .map((data) => ({
                completed: true,
                data: data
            })
            )
            .catch((error) => of({
                completed: false,
                data: error
            }));
    }

    getUserRoleById(id: any): Observable<AppUser> {
        console.log('getting users from service');
        return this.http.get(this.url + 'UserRole/' + id, { headers: this.headers })
            .map((data) => {
                return this.parseResponseData(data);
            })
            .catch(this.handleError);
    }

    parseResponse(json: any): AppUser[] {

        this.loggingService.log(this.constructor.name, 'Retrieved ' + json.length + ' Users.');

        const users: AppUser[] = [];

        json.forEach(item => {
            let user = new AppUser();
            user = this.parseResponseData(item);
            users.push(user);
        });

        return users;
    }

    unsubscribeFromEmailReport(userId: string): Observable<any> {
        return this.http.put(this.url + 'Unsubscribe/' + userId, userId, { headers: this.headers })
            .map((result) => {
                return result;
            })
            .catch(this.handleError);
    }

    parseResponseData(item: any): AppUser {

        const user = new AppUser();
        user.id = item.id;
        user.name = item.name;
        user.firstName = item.firstName ? item.firstName : '';
        user.lastName = item.lastName ? item.lastName : '';
        user.fullName = user.firstName + ' ' + user.lastName;
        user.language = item.language;
        user.timezoneIana = item.timezoneIana;
        user.accountId = item.accountId;
        user.accountName = item.accountName;
        user.userRoleId = item.userRoleId;
        user.userRoleName = item.userRoleName;
        user.whitelabel = item.whitelabel
        user.referenceId = item.referenceId;
        user.createdDate = item.createdDate;
        user.email = item.email;
        user.cronTabPattern = item.cronTabPattern;

        return user
    }

    private handleError(error: Response) {
        return throwError(error);
    }
}
