import { AddressDetails } from './platform.model';
import { RegisterOperatingCompanyRequest } from './wasl.model';

export class Properties {
    wasl = new RegisterOperatingCompanyRequest();
}

export class InventoryAccount {
    name: any;
    id: any;
    createdDate: any;
    dateCreated: any;
    identifier: number;
    limitHistoryInDays: number;
    date: any;
    issueCount: any;
    jobCount: any;
    userCount: any;
    platformCount: any;
    deviceCount: any;
    status: any;
    isActive: any;

    isCurrentAccount: boolean;

    assetCount: number;
    driverCount: number;

    waslAssetCount: number;
    waslDriverCount: number;

    waslSuccessCount: number;
    waslFailedCount: number;

    address: AddressDetails;

    properties = new Properties();
}

export class AccountPlatform {
    referenceId: any;
    name: any;
    id: any;
    createdDate: any;
    dateCreated: any;
    identifier: any;
    timezoneIana: any;
}

export class AccountType {
    id: number;
    name: string;
}


