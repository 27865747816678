import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { languages, FormMode } from 'app/common/enums';
import { UserService } from '../../services/users/user.service';
import { User, AppUser, UserRole } from '../../models/user.model';
import { PlatformService } from '../../services/platform/platform.service';
import { UserRoleService } from '../../services/users/userRole.service';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { AccountService } from 'app/services/accounts/accounts.service';
import { InventoryAccount } from 'app/models/account.model';
import { HttpErrorResponse } from '@angular/common/http';

declare var L;

@Component({
    selector: 'fh-user-add',
    templateUrl: 'userAdd.template.html'
})

export class UserAddViewComponent implements OnInit {
    accountPlatforms: any;
    languages: { name: string; path: any; }[] = [];
    loading: boolean;
    user: AppUser = new AppUser();
    userRoles: UserRole[];
    password2;
    formMode = FormMode.add;
    accounts: InventoryAccount[] = [];
    permissions = {};
    error: HttpErrorResponse;
    accountId: string;

    constructor(private authenticationService: AuthenticationService, private accountService: AccountService, private userRoleService: UserRoleService, private route: ActivatedRoute, private router: Router, private userService: UserService) {

        this.permissions = this.authenticationService.permissions;
        this.accountId = this.authenticationService.getAccountId();

        if (this.permissions['IsAdministrator']) {
            this.accountService.getAccounts().subscribe(result => {
                this.accounts = result.sort((a, b) =>  ('' + a.name).localeCompare(b.name));
                this.user.accountId = this.accounts.find(a => a.id === this.accountId).id;
            });
        }

    }

    ngOnInit() {
    }

    onInsert() {
        this.loading = true;

        this.user.timezoneIana = this.authenticationService.getTimeZoneIana();
        this.userService.saveUser(this.user).subscribe(result => {
            this.loading = false;

            if (result.completed) {
                this.router.navigate(['/Users/Overview']);
            } else {
                this.error = result.data.error
            }

        });
    }
}
