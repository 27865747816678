<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/UserRoles/Overview">{{'general.userRoles' | translate }}</a>
            </li>
            <li>
                {{userRole?.name}}
            </li>
            <li class="active">
                <strong>{{'menu.details' | translate }}</strong>
            </li>
        </ol>
    </div>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="">
        <div class="col-lg-6">

            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-users"></i> {{'menu.userroledetails' | translate }}
                    </h5>
                </div>
                <div class="ibox-content slick" style="position:relative">
                    <table class="table table-hover nowrap dtr-inline no-footer" style="width:100%; margin-bottom:0px ">
                        <tbody>
                            <tr>
                                <td style="width:50%">
                                    {{ 'general.name' | translate}}
                                </td>
                                <td style="width:50%">
                                    {{ userRole?.name }}
                                </td>
                            </tr>
                            <tr>
                                <td style="width:50%">
                                    {{ 'general.permissions' | translate}}
                                </td>
                                <td style="width:50%">
                                    {{ userRole?.permissions }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-lg-6">

            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-key"></i> {{'menu.permissions' | translate }}</h5>
                </div>
                <div class="ibox-content" *ngIf="theSaveBitmask">
                    <strong>Bitmask!</strong> {{ theSaveBitmask }}
                </div>
                <div class="ibox-content slick">
                    <table class="table table-hover nowrap dtr-inline no-footer" style="width:100%; margin-bottom:0px ">
                        <thead>
                            <tr>
                                <th>{{'general.permission' | translate }}</th>
                                <th>{{'general.active' | translate }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let permission of permissions">
                                <td style="width:50%">
                                    {{ permission.name }}
                                </td>
                                <td style="width:50%">
                                    <input type="checkbox" value="" [(ngModel)]="permission.isEnabled">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="ibox-footer">
                    <button class="btn btn-primary" (click)="save()">
                        <i class="fa fa-fw fa-save"></i> {{'general.save' | translate }}</button>
                </div>
            </div>
        </div>
    </div>
</div>