import { Component, Input, OnInit, ChangeDetectorRef, OnChanges } from '@angular/core';


@Component({
    selector: 'fh-error',
    templateUrl: 'error.component.html',
})
export class ErrorComponent implements OnInit, OnChanges {

    @Input() error = null;
    @Input() success = null;
    @Input() autoRemove = true;

    constructor(private cd: ChangeDetectorRef) {
    }

    clearError() {
        this.success = null;
        this.error = null;
    }

    ngOnInit(): void {
    }

    ngOnChanges(): void {

        if (this.success != null && this.autoRemove) {
            setTimeout(() => {
                this.success = null;
                this.cd.markForCheck();
            }, 5000);
        }
        if (this.error != null && this.autoRemove) {
            setTimeout(() => {
                this.error = null;
                this.cd.markForCheck();
            }, 10000);
        }
    }
}

