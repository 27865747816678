import { Component, OnInit, AfterViewInit } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Subject } from 'rxjs/Subject';
import { DeviceService } from '../../services/device/device.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuditLogService } from '../../services/auditlog/auditlog.service';
import { AuditLog } from '../../models/auditlog.model';
import { Asset } from 'app/models/asset.model';
import { AssetService } from 'app/services/asset/asset.service';

@Component({
    selector: 'fh-asset-log',
    templateUrl: 'assetLog.template.html'
})
export class AssetLogViewComponent implements OnInit {
    assetLog: AuditLog[];
    sub;
    asset;
    loading = false;

    constructor(private http: HttpClient, private auditLogService: AuditLogService, private assetService: AssetService, private route: ActivatedRoute, private router: Router) {
        this.asset = null;
        this.assetLog = [];
    }

    ngOnInit() {
        this.loading = true;
        this.asset = new Asset;
        this.asset.id = ''

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];
            this.assetService.getAssetById(id).subscribe(asset => {
                this.asset = asset;

                if (this.asset == null) {
                    this.router.navigate(['/Assets/Overview'])
                }

                this.auditLogService.getAuditLogByAsset(id).subscribe(assetLog => {
                    console.log('loading done');

                    this.assetLog = assetLog;
                    this.loading = false;
                });
            });
        });
    }
}
