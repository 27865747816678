import { Component, Input, OnChanges, Output, ViewChild, EventEmitter, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormMode } from 'app/common/enums';
import { DeviceService } from 'app/services/device/device.service';
import { Device } from 'app/models/device.model';

// Schedule
import { Schedule } from 'app/models/schedule.model';
import { ScheduleService } from 'app/services/schedule/schedule.service';
import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap';
import { FormControl, Validators, FormGroup } from '@angular/forms';

@Component({
    selector: 'fh-schedule-details',
    templateUrl: 'scheduleDetails.template.html',
})
export class ScheduleDetailsComponent implements OnChanges, OnInit {
    detailsLoading: boolean;
    scheduleForProcessing: Schedule;
    loading: boolean;
    schedule: Schedule[];
    selectedSchedule: Schedule;

    @Input() device;
    @Input() issue;
    @Input() renderDateTime;

    @Output() onUpdated = new EventEmitter();

    // Form

    // tslint:disable-next-line:member-ordering
    langs: string[] = [
        'English',
        'French',
        'German',
    ];

    myform: FormGroup;
    firstName: FormControl;
    lastName: FormControl;
    // tslint:disable-next-line:member-ordering
    email: FormControl;
    password: FormControl;
    language: FormControl;

    @ViewChild('autoShownModal') autoShownModal: ModalDirective;
    modalRef: BsModalRef;
    isModalShown = false;
    public step = 1;
    mode: FormMode;

    constructor(private cd: ChangeDetectorRef, private modalService: BsModalService, private deviceService: DeviceService, private scheduleService: ScheduleService, ) {
        this.schedule = [];
        this.loading = false;
    }

    ngOnChanges() {
        const that = this;
        this.selectedSchedule = null;
        this.getScheduleDetails();
    }

    getScheduleDetails() {
        this.loading = true;
        if (this.device && this.device.id !== '') {
            this.scheduleService.getSchedulesForDevice(this.device.id).subscribe(schedules => {
                this.schedule = schedules;
                this.loading = false;
                this.cd.markForCheck();
            });
        }
        if (this.issue && this.issue.id !== '') {
            this.scheduleService.getSchedulesForIssue(this.issue.id).subscribe(schedules => {
                this.schedule = schedules;
                this.loading = false;
                this.cd.markForCheck();
            });
        }
    }

    onSelectionChange(entry) {
        this.selectedSchedule = entry;
    }
    // Model

    startProcessSchedule(): void {
        this.detailsLoading = true;

        this.scheduleService.getScheduleById(this.selectedSchedule.id).subscribe(schedule => {
            this.scheduleForProcessing = schedule;
            console.log(this.scheduleForProcessing);
            this.detailsLoading = false;
            this.cd.markForCheck();
        });

        this.isModalShown = true;
    }

    onScheduleFinish() {
        console.log('Schedule finsihed');
        this.autoShownModal.hide();
        this.step = 1;
    }

    ngOnInit() {
        this.createFormControls();
        this.createForm();
    }

    initForm() {
        console.log('init tab');
    }

    createFormControls() {
        this.firstName = new FormControl('', Validators.required);
        this.lastName = new FormControl('', Validators.required);
        this.email = new FormControl('', [
            Validators.required,
            Validators.pattern('[^ @]*@[^ @]*')
        ]);
        this.password = new FormControl('', [
            Validators.required,
            Validators.minLength(8)
        ]);
        this.language = new FormControl('');
    }

    createForm() {
        this.myform = new FormGroup({
            name: new FormGroup({
                firstName: this.firstName,
                lastName: this.lastName,
            }),
            email: this.email,
            password: this.password,
            language: this.language
        });
    }

    processSchedule(): void {
        this.scheduleForProcessing.dateProcessed = new Date();
        this.scheduleService.processSchedulesForIssue(this.selectedSchedule.id, this.scheduleForProcessing).subscribe(schedules => {
            this.scheduleForProcessing = null;
            this.selectedSchedule = null;

            this.onUpdated.emit(this.schedule);
        });

        this.autoShownModal.hide();
    }

    declineSchedule(): void {
        this.autoShownModal.hide();
    }

    hideModal(): void {
        this.autoShownModal.hide();
    }

    onHidden(): void {
        this.isModalShown = false;
    }
}
