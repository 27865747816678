<!-- Overview Table -->
<div *ngIf='settings.length > 0' class="ibox float-e-margins">
    <!-- Title -->
    <div class="ibox-title">
        <h5>
            <i class="fas fa-sliders-h"></i> {{ title | translate }}
        </h5>
    </div>
    <!-- Content -->
    <div class="ibox-content slick" style="position: relative">
        <table style="width:100%; table-layout:fixed" class="table table-hover nowrap dtr-inline no-footer">
            <thead>
                <tr>
                    <th>
                        {{ 'general.type' | translate}}
                    </th>
                    <th>
                        {{ 'general.source' | translate}}
                    </th>
                    <th>
                        {{ 'general.sourceName' | translate}}
                    </th>
                    <th>
                        {{ 'general.threshold' | translate}}
                    </th>
                    <th>
                        {{ 'general.severity' | translate}}
                    </th>
                    <th>
                        {{ 'general.active' | translate}}
                    </th>
                </tr>
            </thead>
            <tbody #issueSettings>
                <tr *ngFor="let setting of settings | orderBy : ['name']:false ; let i = index">
                    <td>
                        {{ 'enums.issuetypes.' + setting.issuetypeName | translate }}
                    </td>
                    <td>
                        {{ 'enums.settingsLevel.' + setting.source | translate }}
                    </td>
                    <td>
                        <span [ngSwitch]="setting.source">
                            <span *ngSwitchCase="2"><a [routerLink]="['/PlatformDetails/Index/', setting.sourceId ]">{{
                                    setting.sourceName }}</a></span>
                            <span *ngSwitchCase="3"><a [routerLink]="['/CustomerDetails/Index/', setting.sourceId ]">{{
                                    setting.sourceName }}</a></span>
                            <span *ngSwitchCase="4"><a [routerLink]="['/DeviceTypeDetails/Index/', setting.sourceId ]">{{
                                    setting.sourceName }}</a></span>
                            <span *ngSwitchCase="5"><a [routerLink]="['/DeviceDetails/Index/', setting.sourceId ]">{{
                                    setting.sourceName }}</a></span>
                            <span *ngSwitchDefault>{{ setting.sourceName }}</span>
                        </span>


                    </td>
                    <td>
                        <span *ngIf="setting.uom != 0">
                            {{ setting.lowerThreshold }}
                            <span *ngIf="setting.upperThreshold != 0"> - {{ setting.upperThreshold }} </span>
                            {{ setting.description}}
                            <span *ngIf="setting.duration != 0"> for {{ setting.duration }} {{ 'general.minutes' |
                                translate }} </span>
                        </span>
                    </td>
                    <td>
                        {{ 'enums.severity.' + setting.severity | translate }}
                    </td>
                    <td>
                        {{ setting.isActive ? ('general.yes' | translate) : ('general.no' | translate) }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>