import { CustomerService } from './../../services/customers/customers.service';
import { Component, OnDestroy, OnInit, ChangeDetectorRef, ViewChild, } from '@angular/core';
import { DeviceService } from '../../services/device/device.service';
import { IssueService } from '../../services/issues/issues.service';
import { Issue } from 'app/models/issue.model';
import * as moment from 'moment';
import { Subject } from 'rxjs/Subject';

import { Chart } from 'angular-highcharts';

import { DeviceTypeService } from 'app/services/devicetypes/devicetypes.service';
import { IssueTypeService } from 'app/services/issuetypes/issuetypes.service';
import { DeviceType } from 'app/models/devicetype.model';
import { IssueType } from 'app/models/issuetype.model';
import { Customer } from 'app/models/customer.model';
import { DataTableDirective } from 'angular-datatables';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { HttpHeaders } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { TranslatorService } from '../../services/common/translator.service';
import { ActivatedRoute } from '@angular/router';

import { BsDatepickerConfig, BsLocaleService, BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import { trigger, transition, animate, style, state, group } from '@angular/animations'
import { IssueTypeEnums } from 'app/common/enums';
import { getGridLanguages, getGridButtons, getDropdownSettings, getTranslatedDropdownSettings, getDefaultDpConfig, mapMultiDropdown } from 'app/common/gridhelper';
import { Observable } from 'rxjs/Observable';

@Component({
  selector: 'fh-deviceissues',
  templateUrl: 'deviceIssues.template.html',
  styleUrls: ['deviceIssues.template.css'],
  animations: [
    trigger('slideInOut', [
      state('in', style({ height: '*', opacity: 0.5 })),
      transition(':leave', [
        style({ height: '*', opacity: 1 }),
        group([
          animate(300, style({ height: 0 })),
          animate('200ms ease-in-out', style({ 'opacity': '0.5' })),
          animate('200ms ease-in', style({ transform: 'translateY(-100%)' }))
        ])
      ]),
      transition(':enter', [
        style({ height: '0', opacity: 0.5 }),
        group([
          animate(300, style({ height: '*' })),
          style({ transform: 'translateY(-100%)' }),
          animate('400ms ease-in-out', style({ 'opacity': '1' })),
          animate('200ms ease-in', style({ transform: 'translateY(0%)' }))
        ])
      ])
    ])
  ]
})
export class DevicesIssuesViewComponent implements OnInit, OnDestroy {
  tableIds: string[];
  token: string;
  countrySelect = false;

  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  dropdown_IssueType = '';
  dropdown_DeviceType = '';

  // Daterange
  public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
  today = new Date();
  daterangepickerModel: Date[];
  maxDate = new Date();

  dtOptions = {};
  loading = false;

  min: number;
  max: number;

  issueTypes: IssueType[] = [];
  deviceTypes: DeviceType[] = [];

  customersMultiselect: any[] = [];

  dropdown_CustomerName = '';

  minDate: Date;
  limitHistoryInDays: number;
  languageLoaded: boolean;
  dropdown_CustomerNameSettings: any;
  selectedCustomers = [];

  issueTypesWithCategories: { category: string, types: IssueType[] }[];
  selectedIssueTypes: { 'id': any; 'itemName': any; }[] = [];
  dropdownIssueTypesSettings: any;
  issueTypesMultiselect = [];

  dropdownSettingsDeviceTypes: any;
  selectedDeviceTypes: any[] = [];
  deviceTypesMultiselect: any[] = [];
  searchTerm: any;

  showFilter = true;
  constructorName = this.constructor.name;

  constructor(private localeService: BsLocaleService, private route: ActivatedRoute, private translatorService: TranslatorService, private translateService: TranslateService, private cd: ChangeDetectorRef, private issueTypeService: IssueTypeService, private authenticationService: AuthenticationService, private deviceService: DeviceService, private deviceTypeService: DeviceTypeService, private customerService: CustomerService) {
    const that = this;

    this.token = this.authenticationService.getAuthToken();

    this.minDate = new Date();
    this.limitHistoryInDays = +this.authenticationService.getLimitHistoryInDays();
    this.minDate.setDate(this.minDate.getDate() - this.limitHistoryInDays);

    this.maxDate.setDate(this.today.getDate() - 7);
    this.daterangepickerModel = [this.maxDate, this.today];

    this.dpConfig = getDefaultDpConfig();


    // Set issuetype from get
    this.route.queryParams.subscribe(params => {
      const issueType = params['IssueType'];
      if (issueType) {
        that.dropdown_IssueType = issueType;
      }
    });

    Observable.forkJoin(
      this.issueTypeService.getIssueTypes(),
      this.deviceTypeService.getDeviceTypes(),
      this.customerService.getCustomers()
    ).subscribe(
      data => {
        this.issueTypes = data[0];
        this.deviceTypes = data[1];

        this.issueTypesWithCategories = [];
        this.issueTypes.forEach(type => {
          if (this.issueTypesWithCategories.find(x => x.category === type.category)) {
            this.issueTypesWithCategories.find(x => x.category === type.category).types.push(type)
          } else {
            this.issueTypesWithCategories.push({ category: type.category, types: [type] })
          }

          this.issueTypesMultiselect = [];
          this.issueTypes.forEach(issueType => {
            this.issueTypesMultiselect.push({ 'id': issueType.id, 'itemName': issueType.name, 'category': issueType.category });
          });

        });

        this.deviceTypesMultiselect = [];
        this.deviceTypes.forEach(deviceType => {
          this.deviceTypesMultiselect.push({ 'id': deviceType.id, 'itemName': deviceType.name, 'category': deviceType.manufacturerName });
        });

        this.customersMultiselect = mapMultiDropdown(data[2].filter(x => x.count > 0));

        this.languageLoaded = true;
        this.initGrid();
      },
      err => console.error(err)
    );

  }

  checkFilters() {
    console.log('Checking filters');

    // Set custom filters from grid save
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {

      console.log(dtInstance.column('customerName:name').search());

      const selectedCustomers = dtInstance.column('customerName:name').search().split(',');
      this.selectedCustomers = this.customersMultiselect.filter(x => selectedCustomers.indexOf(x.itemName.toString()) !== -1);

      const selectedDeviceTypes = dtInstance.column('deviceTypeId:name').search().split(',');
      this.selectedDeviceTypes = this.deviceTypesMultiselect.filter(x => selectedDeviceTypes.indexOf(x.id.toString()) !== -1);

      const selectedIssueTypes = dtInstance.column('issueTypeCSV:name').search().split(',');
      this.selectedIssueTypes = this.issueTypesMultiselect.filter(x => selectedIssueTypes.indexOf(x.id.toString()) !== -1);
      // make sure all is updated
      this.fireFilter(1);
    });
  }

  checkRoute() {
    // get from route
    this.route.queryParams.subscribe(params => {
      const issueType = params['issueType'];
      if (issueType) {
        this.selectedIssueTypes = [{ id: issueType, itemName: 'filter' }];
        this.fireFilter(1);
      } else {
        this.fireFilter(1);
      }

      const customer = params['customer'];
      if (customer) {
        this.dropdown_CustomerName = customer;
        this.fireFilter(1);
      } else {
        this.fireFilter(1);
      }

      const singleday = params['Singleday'];
      if (singleday) {
        this.daterangepickerModel = [new Date(singleday / 1), new Date(singleday / 1)];
        this.dateChanged(1);
      }


      const dateStart = params['DateStart'];
      const dateEnd = params['DateEnd'];
      if (dateStart && dateEnd) {
        this.daterangepickerModel = [new Date(dateStart / 1), new Date(dateEnd / 1)];
        this.dateChanged(1);
      }

      // make sure all is updated
      this.fireFilter(1);
    });
  }

  clearSelectedCustomers() {
    this.selectedCustomers = [];
    this.fireFilter(1);
  }

  clearSelectedDeviceTypes() {
    this.selectedDeviceTypes = [];
    this.fireFilter(1);
  }

  clearSelectedIssueTypes() {
    this.selectedIssueTypes = [];
    this.fireFilter(1);
  }

  seachChanged(value) {
    console.log('Search changed');
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.search(value).draw();
    });
  }

  fireFilter(event): void {

    if (event != null) {
      console.log('Fire update');
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {

        // Search for the devicetype
        if (this.selectedDeviceTypes !== []) {
          const selectedDeviceTypesString = this.selectedDeviceTypes.map((i) => i.id, []).join(',');
          dtInstance.column('deviceTypeId:name').search(selectedDeviceTypesString);
        } else {
          dtInstance.column('deviceTypeId:name').search('');
        }

        // Search for the customer
        if (this.selectedCustomers !== []) {
          const selectedCustomersString = this.selectedCustomers.map((i) => i.itemName, []).join(',');
          dtInstance.column('customerName:name').search(selectedCustomersString);
        } else {
          dtInstance.column('customerName:name').search('');
        }
        // Search for the issuetype
        if (this.selectedIssueTypes !== []) {
          const selectedIssueTypesString = this.selectedIssueTypes.map((i) => i.id, []).join(',');
          dtInstance.column('issueTypeCSV:name').search(selectedIssueTypesString);
        } else {
          dtInstance.column('issueTypeCSV:name').search('');
        }
        // Fire the request
        dtInstance.draw();
      });
    }
  }

  dateChanged(event) {

    const that = this;
    if (event !== null && this.datatableElement.dtInstance !== null) {

      console.log('Changed date... new database query');
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.url(that.deviceService.getPagingWithIssuesUrl() + '?startDate=' + Math.round(this.daterangepickerModel[0].getTime() / 1000) + '&endDate=' + Math.round(this.daterangepickerModel[1].getTime() / 1000)).load();
      });

      this.fireFilter(null);
    }
  }

  ngOnDestroy(): void {
    // We remove the last function in the global ext search array so we do not add the fn each time the component is drawn
    // /!\ This is not the ideal solution as other components may add other search function in this array, so be careful when
    // handling this global variable
    $.fn['dataTable'].ext.search.pop();

    // Had to reset the array...
    $.fn['dataTable'].ext.search = [];
  }

  ngOnInit(): void {

    this.dropdownSettingsDeviceTypes = getDropdownSettings();
    this.dropdown_CustomerNameSettings = getDropdownSettings();
    this.dropdownIssueTypesSettings = getDropdownSettings();

    this.translateService.get('general.date').subscribe(value => {
      this.dropdownSettingsDeviceTypes = getTranslatedDropdownSettings(this.translateService, 'allDeviceTypes', true);
      this.dropdown_CustomerNameSettings = getTranslatedDropdownSettings(this.translateService, 'allCustomers', false);
      this.dropdownIssueTypesSettings = getTranslatedDropdownSettings(this.translateService, 'allIssueTypes', true);
    });
  }

  changeFlag(id: string, isFlagged: boolean): void {
    this.deviceService.getDeviceById(id).subscribe(device => {
      device.isFlagged = isFlagged;
      this.deviceService.saveFlagged(device).subscribe(() => { });
    });
  }

  initGrid(): void {

    const that = this;

    $.fn['dataTable'].ext.search.pop();

    this.loading = true;

    const commonExportOptions = {
      modifier: {
        page: 'all',
        search: 'none'
      },
      columns: ':visible[tabindex]'
    };

    this.dtOptions = {
      buttons: getGridButtons(commonExportOptions, 'deviceissues_overview', this.translateService.instant('menu.issuesoverview')),
      pagingType: 'simple_numbers',
      serverSide: true,
      processing: false,
      searchDelay: 500,
      deferRender: true,
      scrollX: true,
      colReorder: { fixedColumnsLeft: 1 },
      deferLoading: 0,
      stateSave: true,
      stateSaveCallback: function (settings, data) {
        localStorage.setItem('Columns_' + that.constructor.name, JSON.stringify(data));
      },
      stateLoadCallback: function () {
        try {
          const columnSettings = JSON.parse(localStorage.getItem('Columns_' + that.constructor.name));
          that.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
          return columnSettings;
        } catch (e) {
          console.log(e);
        }
      },
      order: [[9, 'desc'], [10, 'desc']],
      ajax: {
        beforeSend: () => {
          $('.dataTables_info').html(this.translateService.instant('grid.loadingData'));
        },
        url: that.deviceService.getPagingWithIssuesUrl() + '?startDate=' + Math.round(this.daterangepickerModel[0].getTime() / 1000) + '&endDate=' + Math.round(this.daterangepickerModel[1].getTime() / 1000),
        type: 'POST',
        headers: {
          'Authorization': 'Bearer ' + that.token
        }
      },
      initComplete: function (settings, json) {
        console.log('init complete');
        that.checkFilters();
        that.checkRoute();
        that.loading = false;
      },
      colVis: {
        restore: this.translateService.instant('general.restore'),
        showAll: this.translateService.instant('general.showAll'),
        showNone: this.translateService.instant('general.hideAll'),
        exclude: ['id', 'deviceTypeId']
      },
      columns: [
        {
          name: 'id',
          data: 'id',
          orderable: false,
          title: '',
          width: '20',
          render: function (data, type, row) {
            return '<a class=\'btn btn-primary btn-outline btn-grid\' href=\'/#/DeviceDetails/Index/' + data + '\'><i class=\'fas fa-fw fa-info\'></i> <span class="hidden-sm hidden-xs">Details</span></a>';
          }
        },
        {
          name: 'name',
          data: 'name',
          title: this.translateService.instant('general.name')
        },
        {
          name: 'unitId',
          data: 'unitId',
          title: this.translateService.instant('general.unitId')
        },
        {
          name: 'lastCommunication',
          data: 'lastCommunication',
          title: this.translateService.instant('general.lastCommunication'),
          render: function (data, type, row) {
            return new Date(data).toLocaleDateString();
          }
        },
        {
          name: 'deviceTypeId',
          data: 'deviceTypeId',
          title: this.translateService.instant('general.devicetype'),
          visible: false
        },
        {
          name: 'brand',
          data: 'brand',
          title: this.translateService.instant('general.assetBrand'),
          visible: false
        },
        {
          name: 'model',
          data: 'model',
          title: this.translateService.instant('general.assetModel'),
          visible: false
        },
        {
          name: 'deviceTypeName',
          data: 'deviceTypeName',
          title: this.translateService.instant('general.devicetype')
        },
        {
          name: 'manufactorerName',
          data: 'manufactorerName',
          title: this.translateService.instant('general.manufacturer')
        },
        {
          name: 'customerName',
          data: 'customerName',
          title: this.translateService.instant('general.customer')
        },
        {
          name: 'issueCount',
          data: 'issueCount',
          title: this.translateService.instant('general.issuecount')
        },
        {
          name: 'maxSeverity',
          data: 'maxSeverity',
          title: this.translateService.instant('general.severity'),
          render: function (data, type, row) {
            return data ? that.translatorService.translate(data.toString(), 'enums.severity') : '-';
          }
        },
        {
          name: 'issueTypesCount',
          data: 'issueTypesCount',
          title: this.translateService.instant('general.issueTypesCount')
        },
        {
          name: 'isDeviceFlagged',
          data: 'isFlagged',
          title: this.translateService.instant('general.isFlagged'),
          visible: true,
          width: '40',
          responsivePriority: 1,
          render: function (data, type, row) {
            if (!that.tableIds || (that.tableIds.length === 10)) {
              that.tableIds = [];
            }

            that.tableIds.push(row.id);

            return '<i id="' + row.id + '" class="fa-fw fa-2x fa-star ' + (data === true ? 'fas' : 'far') + '" style="cursor: pointer; font-size: 1.3em;"></i>';
          }
        },
        {
          name: 'issueTypeCSV',
          data: 'issueTypeCSV',
          title: this.translateService.instant('general.issuetypes'),
          visible: true,
          render: function (data, type, row) {
            const issueTypeIds = data.split(',');
            let issueTypeString = '';
            issueTypeIds.forEach(id => {
              if (id) {
                const issueType = that.issueTypes.find(x => x.id === parseInt(id, 10));
                issueTypeString += !issueTypeString ? issueType.name.trim() : ', ' + issueType.name.trim();
              }
            });
            return issueTypeString;
          }
        },
        {
          name: 'issueTypeId',
          data: 'issueTypeId',
          title: this.translateService.instant('general.mostCommonIssueType'),
          render: function (data, type, row) {
            const issueType = IssueTypeEnums[data]
            return data !== 0 ? that.translateService.instant('enums.issuetypes.' + issueType.toString()) : '';
          }
        },
        {
          name: 'issueTypeId',
          data: 'issueTypeId',
          title: this.translateService.instant('general.mostCommonIssueTypeResolution'),
          render: function (data, type, row) {
            const issueType = IssueTypeEnums[data]
            return data !== 0 ? that.translateService.instant('enums.issuetypes.resolution.' + issueType.toString()) : ''
          },
          visible: false
        }],
      pageLength: 10,
      lengthMenu: [[10, 25, 50, -1], [10, 25, 50, this.translateService.instant('general.all')]],
      language: getGridLanguages(this.translateService),
      drawCallback: () => {
        if (that.tableIds) {
          that.tableIds.forEach(id => {
            const star = $('#' + id);
            star.click(() => {
              if (star.hasClass('fas')) {
                star.removeClass('fas').addClass('far');
              } else {
                star.removeClass('far').addClass('fas');
              }
              that.changeFlag(id, star.hasClass('fas'));
            });
          });
        }
      }
    };
  }
}
