
export class AssetType {

    public id: string;
    public name: string;
    public referenceId: string;
    public year: any;
    public brand: any;
    public model: any;
    public assetClass = 1;
    public assetCount: any;
    public serie: any;
    public trim: any;
    public properties: any;
    public isEditable: any;
    public isGeneral: any;
    public category = 1;
}
