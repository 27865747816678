<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/">{{'general.settings' | translate }}</a>
            </li>
            <li class="active">
                <strong>{{'menu.sensorSettings' | translate }}</strong>
            </li>

            <span class="pull-right"><a href="https://360locate.com/docs/fleet-health/sensor-mapping/" target="_blank">
                    <i class="fa fa-question"></i></a></span>
        </ol>
    </div>
</div>

<div class="wrapper wrapper-content">
    <div class="row">
        <div class="col-lg-12">
            <fh-error [error]="error" [success]="success"></fh-error>
            
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-sliders-h"></i> {{ 'general.sensors' | translate }}
                    </h5>
                    <span class="label pull-right">{{currentPage}}/{{smallnumPages}}</span>
                </div>
                <div class="ibox-content" style="position: relative; padding: 25px;">
                    <label style="padding-top: 8px; float: left; margin: -15px;" (click)="hideIrrelevant =! hideIrrelevant;">
                        <i style="font-size: 1.2em; color: #777;" class="far fa-fw" [class.fa-check-square]="hideIrrelevant"
                            [class.fa-square]="!hideIrrelevant"></i>
                        {{'general.hideIrrelevant' | translate }}</label>
                    <input placeholder="Search" style="margin: -16px; float: right" (keyup)="newSearchTerm()" name="searchTerm"
                        id="searchTerm" [(ngModel)]="searchTerm" type="text" class="form-control inline-search"
                        required #TheSearchTerm="ngModel" />
                </div>
                <div class="ibox-content slick" style="position: relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <table style="width:100%; table-layout:fixed" class="table table-hover nowrap dtr-inline no-footer">
                        <thead>
                            <tr>
                                <th>
                                    <div style="cursor: pointer; width: fit-content" (click)="sortBy('name')">
                                        {{ 'general.name' | translate}}
                                    </div>
                                </th>
                                <th>
                                    <div style="cursor: pointer; width: fit-content" (click)="sortBy('metrics')">
                                        {{ 'general.metrics' | translate}}
                                    </div>
                                </th>
                                <th>
                                    <div style="cursor: pointer; width: fit-content" (click)="sortBy('parameter')">
                                        {{ 'general.parameter' | translate}}
                                    </div>
                                </th>
                                <th>
                                    <div style="cursor: pointer; width: fit-content" (click)="sortBy('sensorCount')">
                                        {{ 'general.sensorCount' | translate}}
                                    </div>
                                </th>
                                <th>
                                    <div style="cursor: pointer; width: fit-content" (click)="sortBy('sensorType')">
                                        {{ 'general.sensorType' | translate}}
                                    </div>
                                </th>
                                <th>
                                    <div style="cursor: pointer; width: fit-content" (click)="sortBy('save')">
                                        {{ 'general.save' | translate}}
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let sensor of filteredSensors | filterRelevant:hideIrrelevant | slice:((currentPage - 1) * 10): ((currentPage - 1) * 10) + 10">
                                <td>
                                    {{ sensor.name }}
                                </td>
                                <td>
                                    {{ sensor.metrics }}
                                </td>
                                <td>
                                    {{ sensor.parameter }}
                                </td>
                                <td (click)="showSensor(sensor);" style="cursor: pointer;" class="secondary link_bolder">
                                    {{ sensor.sensorCount }}
                                </td>
                                <td [style.background-color]="sensor.isCustomDeviceSensorTypeId ? '#d9e0d9' : ''">
                                    <select name="sensorType" (change)="changeSensorsChanged(sensor)" style="width:100%"
                                        class="form-control table-inline" [(ngModel)]="sensor.deviceSensorTypeId">
                                        <option *ngFor="let sensorType of sensorTypes" [ngValue]="sensorType.id">{{
                                            sensorType.name }}</option>
                                    </select>
                                </td>
                                <td>
                                    <input type="checkbox" [(ngModel)]="sensor.isChanged">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="ibox-footer">

                    <pagination [totalItems]="totalItems" [(ngModel)]="currentPage" (pageChanged)="pageChanged($event)"
                        (numPages)="updatePageNumber($event)" [maxSize]="7" [boundaryLinks]="true"></pagination>

                    <span class="pull-right">
                        <button class="btn btn-primary" (click)="save()" [disabled]="sensorsChanged.length == 0">
                            <i class="fa fa-fw fa-save"></i> {{'general.save' | translate }}</button>
                    </span>

                    <div style="clear:both"></div>
                </div>
            </div>
        </div>
    </div>
</div>

<fh-sensor-details [sensor]="activeSensor" [sensorUsers]="sensorUsers" [showSensorUsers]="showSensorUsers" *ngIf="showSensorUsers.show"></fh-sensor-details>