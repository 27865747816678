import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { SortableModule } from 'ngx-bootstrap/sortable';
import { WorkflowService } from '../../services/workflows/workflows.service';
import { Workflow, Action, WorkflowAction, WorkflowActionActions } from '../../models/workflow.model';

@Component({
    selector: 'fh-theme-workflow',
    templateUrl: 'workflow.template.html',
    styleUrls: ['workflow.template.css'],
    encapsulation: ViewEncapsulation.None
})
export class WorkflowSettingsComponent implements OnInit {

    possibleActions: any;
    selectedAction: any;
    addActionMode = false;
    actionName: any;
    actionsInWorkflow;
    @Input() model: { type: string, id: number, columns };
    @Input() list: any[];

    selectedWorkflowId = '0';
    selectedWorkflow: Workflow;
    workflowName = '';

    actions: Action[] = [];

    workflowActions: WorkflowAction[] = []
    actionsToChoose: Action[] = [];
    workflows: Workflow[] = [];
    nextActions: WorkflowAction[];
    loadingNextActions: boolean;

    constructor(private workflowService: WorkflowService) {
        this.selectedWorkflowId = '0';
        this.selectedWorkflow = new Workflow();
        this.selectedWorkflow.workflowActions = [];
        this.selectedAction = null;
        this.workflowName = '';
    }

    newGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            // tslint:disable-next-line:no-bitwise
            const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    addAction() {
        console.log(this.actionsToChoose);

        const action = new Action();
        action.name = this.actionName;
        action.id = this.newGuid();

        this.actions.push(action);
        this.actionsToChoose = this.actions;

        console.log(this.actionsToChoose);
    }

    doesNotContain(a, obj) {
        for (let i = 0, len = a.length; i < len; i++) {
            if (a[i] === obj) {
                return false;
            }
        }
        return true;
    }

    doesNotContainArray(a, obj) {
        for (let p = 0, len = obj.length; p < len; p++) {
            for (let i = 0, len2 = a.length; i < len2; i++) {
                if (a[i] === obj[p]) {
                    return false;
                }
            }
        }
        return true;
    }

    createNewWorkflow() {
        this.selectedWorkflowId = '0';
        this.selectedWorkflow = new Workflow();
        this.selectedWorkflow.workflowActions = [];
        this.selectedAction = null;
        this.workflowName = '';
        this.actionsToChoose = this.actions;
    }

    ngOnInit(): void {

        this.workflowService.getActions().subscribe(actions => {
            this.actions = actions;
            this.actionsToChoose = actions;

            this.workflowService.getWorkflows(this.actions).subscribe(workflows => {
                this.workflows = workflows;
            });
        });
    }

    changeWorkflow(event) {
        if (this.selectedWorkflowId === '0') {
            this.createNewWorkflow()
        } else {
            this.selectedWorkflow = this.workflows.filter(x => x.id.toString() === this.selectedWorkflowId)[0];
            const actionIds = [];
            this.selectedAction = null;

            this.selectedWorkflow.workflowActions.forEach(action => {
                actionIds.push(action.id);
            });

            this.actionsToChoose = this.actions.filter(x => this.doesNotContain(actionIds, x.id))
            this.nextActions = this.selectedWorkflow.workflowActions.slice();
        }
    }


    selectAction(action) {
        this.loadingNextActions = true;
        this.selectedAction = action;
        setTimeout(() => {
            this.nextActions = this.selectedWorkflow.workflowActions.slice();
            const selectOptions = this.selectedWorkflow.workflowActionActions.filter(x => x.fromActionId === this.selectedAction.id).map(x => x.toActionId);
            this.nextActions.forEach(wfa => {
                wfa.isSelected = false;
                if (!this.doesNotContain(selectOptions, wfa.id)) {
                    wfa.isSelected = true;
                }
            });
            this.loadingNextActions = false;
            this.possibleActions = [];
        }, 200);
    }

    saveNextActions(action) {
        this.loadingNextActions = true;
        setTimeout(() => {
            if (action.isSelected) {
                const newAction: WorkflowActionActions = new WorkflowActionActions();
                newAction.fromActionId = this.selectedAction.id;
                newAction.toActionId = action.id;

                this.selectedWorkflow.workflowActionActions.push(newAction);
            } else {
                this.selectedWorkflow.workflowActionActions.splice(this.selectedWorkflow.workflowActionActions.findIndex(x => x.toActionId === action.id), 1);
            }
            this.loadingNextActions = false;
        }, 200);
    }
}

