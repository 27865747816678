import { Component, OnInit, AfterViewInit } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { AccountService } from '../../services/accounts/accounts.service';
import { ActivatedRoute, Router } from '@angular/router';
import { InventoryAccount } from '../../models/account.model';

@Component({
    selector: 'fh-account-calculation-settings',
    templateUrl: 'accountCalculationSettings.template.html'
})
export class AccountCalculationSettingsViewComponent implements OnInit {
    sub;
    account: InventoryAccount;

    constructor(private http: HttpClient, private accountService: AccountService, private route: ActivatedRoute, private router: Router) {
        this.account = null;
    }

    ngOnInit() {
        this.account = new InventoryAccount;
        this.account.id = ''

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];
            this.accountService.getAccountById(id).subscribe(account => {
                this.account = account;

                if (this.account == null) {
                    this.router.navigate(['/Accounts/Overview'])
                }
            });
        });
    }
}
