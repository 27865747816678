<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
        <ol class="breadcrumb">
            <li>
                <a href="/#/"><i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Devices/Overview">{{'general.devices' | translate }}</a>
            </li>
            <li class="active">
                <a href="/#/Devices/Map">{{'menu.devicemap' | translate }}</a>
            </li>
        </ol>
    </div>
</div>

<div class="wrapper wrapper-content">
    <div class="row">
        <div class="col-lg-12">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-map"></i> {{ 'general.map' | translate}}</h5>
                </div>
                <div class="ibox-content slick">
                    <div style="height: 500px;" leaflet [leafletOptions]="options" (leafletMapReady)="onMapReady($event)">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>