<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Accounts/Overview">{{'general.accounts' | translate }}</a>
            </li>
            <li class="active">
                <a href="/#/Accounts/Overview">{{'menu.overview' | translate }}</a>
            </li>
        </ol>
    </div>
</div>

<div class="wrapper wrapper-content">

    <!--
    <div class="row">
        <div class="col-lg-12">
            <div class="alert alert-danger shadow">
                <strong>Danger!</strong> Indicates a dangerous or potentially negative action.
            </div>
        </div>
    </div>
-->
    <div class="row">
        <div class="col-lg-12">

            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-filter"></i> {{'general.filter' | translate }}</h5>
                </div>
                <div class="ibox-content" style="position: relative">
                    <div class="col-sm-6">
                        <label style="padding-top: 8px;" (click)="showOnlyActive =! showOnlyActive; fireFilter($event)">
                            <i style="font-size: 1.2em; color: #777;" class="far fa-fw"
                                [class.fa-check-square]="showOnlyActive" [class.fa-square]="!showOnlyActive"></i>
                            {{'device.showOnlyActive' | translate }}</label>
                    </div>
                    <div style="clear:both"></div>
                </div>
            </div>

            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-database"></i> {{'general.accounts' | translate }}</h5>
                  <iboxseach [searchTerm]="searchTerm" (seachChanged)="seachChanged($event)"></iboxseach>
                </div>
                <div class="ibox-content slick" style="position: relative" *ngIf="languageLoaded">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <table style="width:100%; min-height:50px;" datatable [dtOptions]="dtOptions"
                        class="table table-hover nowrap margin-bottom-0 dataTable dtr-inline">
                    </table>
                </div>
                <div class="ibox-footer">
                    <button class="btn btn-primary" [routerLink]="['/Accounts/Add/']">
                        <i class="fa fa-fw fa-plus"></i> {{'menu.addAccount' | translate }}</button>
                </div>
            </div>
        </div>
    </div>
</div>