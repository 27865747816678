<div class="ibox-content slick" style="position:relative">
    <table style="width:100%" class="table table-hover nowrap dtr-inline no-footer table-fixed">
        <tbody *ngIf="formMode == 1" [@enterAnimation]>
            <tr>
                <td style="width:40%" class="elipses_overflow">
                    {{ 'general.name' | translate}}
                </td>
                <td style="width:60%">
                    {{ device?.name }}
                </td>
            </tr>
            <tr>
                <td style="width:40%" class="elipses_overflow">
                    {{ 'general.createdDate' | translate}}
                </td>
                <td style="width:60%">
                    {{ device?.createdDate | amDateFormat: 'll' }}
                </td>
            </tr>
            <tr *ngIf="device?.isEnded">
                <td [class.text-danger]="device?.isEnded" style="width:40%" class="elipses_overflow">
                    {{ 'general.enddate' | translate}}
                </td>
                <td [class.text-danger]="device?.isEnded" style="width:60%">
                    {{ device?.endDate | amDateFormat: 'll' }}
                </td>
            </tr>
            <tr>
                <td style="width:40%" class="elipses_overflow">
                    {{ 'general.lastCommunication' | translate}}
                </td>
                <td style="width:60%">
                    {{ device?.lastCommunication | amDateFormat: 'll' }}
                </td>
            </tr>
            <tr *ngIf="permissions['HasDeviceTypes']">
                <td style="width:40%">
                    {{ 'general.modelId' | translate}}
                </td>
                <td style="width:60%">
                    <a class="secondary link_bolder" href="/#/DeviceTypeDetails/Index/{{device?.modelId}}">{{
                                device?.modelName }}</a>
                    <img *ngIf="device?.modelThumb"
                        style="margin-left: 10px; margin-top: -8px;margin-bottom: -8px; height:30px;width: 30px "
                        src="data:image/jpg;base64,{{ device?.modelThumb }}" />
                </td>
            </tr>
            <tr *ngIf="permissions['HasDeviceTypes']">
                <td style="width:40%" class="elipses_overflow">
                    {{'general.manufacturer' | translate }}
                </td>
                <td style="width:60%">
                    {{ device?.manufacturerName }}
                </td>
            </tr>
            <tr *ngIf="permissions['HasCustomersViewAll'] || permissions['HasCustomersViewOwn']">
                <td style="width:40%" class="elipses_overflow">
                    {{ 'general.customer' | translate}}
                </td>
                <td style="width:60%">
                    <a class="secondary link_bolder" href="/#/CustomerDetails/Index/{{device?.customerId}}">{{
                                device?.customerName }}</a>
                </td>
            </tr>
            <tr>
                <td style="width:40%" class="elipses_overflow">
                    {{ 'general.phone' | translate}}
                </td>
                <td style="width:60%">
                    {{ device?.phone }}
                </td>
            </tr>
            <tr>
                <td style="width:40%" class="elipses_overflow">
                    {{ 'general.referenceId' | translate}}
                </td>
                <td style="width:60%">
                    <a href="{{device?.referenceLink}}" target="_blank"
                        class="secondary link_bolder">{{ device?.referenceId }}</a>
                </td>
            </tr>
            <tr>
                <td style="width:40%" class="elipses_overflow">
                    {{ 'general.unitId' | translate}}
                </td>
                <td style="width:60%">
                    {{ device?.unitId }}
                </td>
            </tr>
            <tr>
                <td style="width:40%" class="elipses_overflow">
                    {{ 'general.firmware' | translate}}
                </td>
                <td style="width:60%">
                    {{ device?.firmware }}
                </td>
            </tr>
            <tr *ngIf="permissions['HasPlatforms']">
                <td style="width:40%" class="elipses_overflow">
                    {{ 'general.platform' | translate}}
                </td>
                <td style="width:60%">
                    <a class="secondary link_bolder" href="/#/PlatformDetails/Index/{{device?.accountPlatformId}}">
                        {{ device?.platformName }} - {{ device?.accountPlatformName }}</a>
                </td>
            </tr>
            <tr *ngIf="permissions['HasCalculationSettings']">
                <td style="width:40%" class="elipses_overflow">
                    {{ 'general.isActiveCalculating' | translate}}
                </td>
                <td style="width:60%">
                    <i (click)="changeIssueCalculation($event)" style=" font-size: 1.3em;" class="fas fa-fw fa-2x"
                        [class.fa-toggle-on]="device?.hasIssueCalculationEnabled"
                        [class.fa-toggle-off]="!device?.hasIssueCalculationEnabled"></i>
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="formMode == 2 || formMode == 3" [@enterAnimation]>
            <tr>
                <td style="width:50%">
                    {{ 'general.name' | translate}}
                    <span class="error" *ngIf="name.invalid && formMode != 1">*</span>
                </td>
                <td colspan="2" style="width:50%">
                    <input type="text" [readonly]="formMode == 1" name="name" class="form-control table-inline"
                        [(ngModel)]="device.name" required minlength="2" #name="ngModel">
                </td>
            </tr>

            <tr class="alert alert-danger"
                *ngIf="name.invalid && name.value?.length > 0 && (name?.dirty || name?.touched)">
                <td colspan="3">
                    <div *ngIf="name?.errors?.minlength">
                        * Name must be at least 2 characters long.
                    </div>
                    <div *ngIf="name?.errors?.required">
                        * Name is required
                    </div>
                </td>
            </tr>

            <tr>
                <td style="width:50%">
                    {{ 'general.phone' | translate}}
                    <span class="error" *ngIf="phone.invalid && formMode != 1">*</span>
                </td>
                <td colspan="2" style="width:50%">
                    <input type="text" [readonly]="formMode == 1" name="phone" class="form-control table-inline"
                        [(ngModel)]="device.phone" #phone="ngModel">
                </td>
            </tr>
            <tr>
                <td style="width:50%">
                    {{ 'general.unitId' | translate}}
                    <span class="error" *ngIf="unitId.invalid && formMode != 1">*</span>
                </td>
                <td colspan="2" style="width:50%">
                    <input type="text" [readonly]="formMode == 1" name="unitId" class="form-control table-inline"
                        [(ngModel)]="device.unitId" required minlength="15" #unitId="ngModel">
                </td>
            </tr>

            <tr class="alert alert-danger"
                *ngIf="unitId.invalid && unitId.value?.length > 0 && (unitId?.dirty || unitId?.touched)">
                <td colspan="3">
                    <div *ngIf="unitId?.errors?.minlength">
                        * UnitId must be at least 15 characters long.
                    </div>
                    <div *ngIf="unitId?.errors?.required">
                        * UnitId is required
                    </div>
                </td>
            </tr>

            <tr *ngIf="formMode == 2">
                <td style="width:50%">
                    {{ 'general.devicetype' | translate}}
                    <span class="error" *ngIf="deviceType.invalid && formMode != 1">*</span>
                </td>
                <td colspan="2" style="width:50%">
                    <select [disabled]="loadingDeviceTypes" class="form-control table-inline" name="deviceType"
                        required #deviceType="ngModel" [(ngModel)]="device.deviceTypeId" style="width:100%">
                        <option *ngFor="let deviceType of deviceTypes" [value]="deviceType.id">
                            {{ deviceType.name }}
                        </option>
                    </select>
                </td>
            </tr>
            <tr *ngIf="formMode == 2">
                <td style="width:50%">
                    {{ 'general.accountPlatform' | translate}}
                    <span class="error" *ngIf="accountPlatform.invalid && formMode != 1">*</span>
                </td>
                <td colspan="2" style="width:50%">
                    <select class="form-control table-inline" name="accountPlatform" required #accountPlatform="ngModel"
                        [(ngModel)]="device.accountPlatformId" style="width:100%">
                        <option *ngFor="let accountPlatform of accountPlatforms" [value]="accountPlatform.id">
                            {{ accountPlatform.name }}
                        </option>
                    </select>
                </td>
            </tr>
        </tbody>
    </table>
</div>